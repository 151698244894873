import React, {useState, useEffect} from "react";
import "./TeamsManagement.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { setEditableTeamChannel, toggleUpdatedTeamContent, triggerRemoveTeamChannel } from "../teamsReducer";
import ChannelSettings from "./ChannelSettings";
import AddChannel from "./AddChannel";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import { setReadyToAddChannel } from "../teamsReducer";
import WarningModalForm from "../../../../components/Dialogs/GlobalModalForms/WarningModalForm";
// import Dialog from "../../../../components/Dialogs/Dialog";
import { setShowGlobalWarningDialog } from "../../../../components/ContentComponents/reducer";
// import { setChannelTab, setEditableChannel } from "../teamsReducer";

const Channels: React.FC = () => {
    const { editable_team_channel, is_updated_team_content, editable_team, is_ready_to_add_channel, team_channels: channels} = useSelector((state: any) => state?.teamState);
    const dispatch = useDispatch();
    const [isDeleteTeamChannel, setIsDeleteTeamChannel] = useState<any>(null);
    const {
        // open_global_drawer, showEditorFieldDrawer,
        showGlobalWarningDialog,
    }: any = useSelector((state: any) => state?.contentState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    // const [channels, setChannels] = useState<any>([
    //     { channelId: 1, inbox: 'Review Inbox', channelName: "mmhk@gmail.com" },
    //     { channelId: 2, inbox: 'Marketing channel', channelName: "mmhk@gmail.com" },
    //     { channelId: 3, inbox: 'Human Resource', channelName: "mmhk@gmail.com" },
    // ]);

    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);

    const handleCancel = () => {
        dispatch(setReadyToAddChannel(false));
        setIsDeleteTeamChannel(null);
    }

    const deleteTeamChannel = (channel_delete_payload: any) => {
        const { teamId, channelId } = channel_delete_payload || {};
        // console.log({ team_id_for_deleting: teamId, data });
        if (!teamId || !channelId) {
            return;
        }
        dispatch(triggerRemoveTeamChannel(channel_delete_payload));
    }

    const handleDeleteTeamChannel = (channel?: any) => {
        if (!channel) {
            setIsDeleteTeamChannel(null);
            return;
        }
        const clapupUserId = logged_in_user_secret_data?.cuid || null;
        // const workspaceId = logged_in_user_secret_data?.wuid || null;
        // const roleId = logged_in_user_secret_data?.roleId || null;
        // console.log("Came for removing channel...", { clapupUserId, channel });
        const channel_delete_payload = {
            clapupUserId,
            teamId: editable_team?.teamId,
            channelId: channel?.channelId
        }
        const delete_object = {
            api_url:"",
            header_title: "Remove Channel",
            warning_message: "If you click Yes, the channel will be removed. This action is irreversible.",
            left_button_title: "Back",
            right_button_title: "Continue",
            success_message: "Channel Removed successfully",
            channel_delete_payload,
        }
      
        dispatch(setShowGlobalWarningDialog(delete_object));
    }
    
    const handleEditableChannel = (channel: any) => {
        console.log({channel_for_editing: channel})
        dispatch(setEditableTeamChannel(channel));
    }

    const handleRowClick = (channel: any) => {
        handleEditableChannel(channel);
    };

    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_add_channel} 
                setModalVisible={(flag: any) => dispatch(setReadyToAddChannel(flag))}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="add-channel-setting-table-container"
                footer={null}
            >
                <AddChannel handleCancel={handleCancel} />
            </CommonModalCustomWidth>

            {/* Delete Team Member */}
            {
                showGlobalWarningDialog?.header_title === "Remove Channel"
                ?   <WarningModalForm
                        showWarningDialog={showGlobalWarningDialog}
                        data_fields={showGlobalWarningDialog}
                        header_title={showGlobalWarningDialog?.header_title}
                        warning_message={showGlobalWarningDialog?.warning_message}
                        left_button_title={showGlobalWarningDialog?.left_button_title || "Back"}
                        right_button_title={showGlobalWarningDialog?.right_button_title || "Continue"}
                        handleCancel={() => dispatch(setShowGlobalWarningDialog(null))}
                        parentCallback={(data: any) => {
                            console.log({data});
                            deleteTeamChannel(data?.channel_delete_payload);
                        }}
                    />
                : null
            }
            {/* <Dialog
                open={isDeleteTeamChannel}
                parentCallback={(flag: any) => handleDeleteTeamChannel(flag)}
                classes='dialog-container-content'
                title='Remove Channel?'
                warning_dialog_content={`If you click Yes, the channel will be removed. This action is irreversible.` }
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Delete'
            /> */}
            {
                editable_team_channel ?
                    <ChannelSettings/>
                :   channels?.length ?
                    <div className="channel-list-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table-header-row-content">
                                                <div className="channel-header-title-container">Name</div>
                                                <div className="inbox-channel">Inbox</div>
                                                <div>Action</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channels?.map((channel: any, idx: number) => (
                                        <tr key={idx}>
                                            <td>
                                                <div
                                                    className="table-row-content"
                                                    onClick={() => handleRowClick(channel)}
                                                >
                                                    <div className="channel-title-container">
                                                        <img src={getImage("gmail-icon-circle")} alt="gmail" />
                                                        {/* <div className="channel-icon" >
                                                            <img src={getImage("gmail-icon-circle")} alt="gmail" />
                                                        </div> */}
                                                        <div className="channel-title">
                                                            <div>{channel?.channelName}</div>
                                                            <div className="channel-provider">Gmail</div>
                                                        </div>
                                                    </div>
                                                    <div className="inbox-channel">{channel?.inbox || "N/A"}</div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            // setIsDeleteTeamChannel(channel);
                                                            handleDeleteTeamChannel(channel);
                                                        }}
                                                    >
                                                        Remove
                                                    </div>
                                                    {/* <img
                                                        className="delete-icon"
                                                        src={getImage("delete-icon")}
                                                        alt="Delete"
                                                        
                                                    /> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                :   null
            }
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default Channels;