import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";

// const LocationSelectionTable: React.FC<any> = ({handleCancel, data, provider}: any) => {
const LocationSelectionTable: React.FC<any> = ({handleCancel, handleGoogleLocationsContinue}: any) => {
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const {
        // google_locations: data,
        // google_connection_location_data: connected_google_location_data,
        // google_modal_visible,
        // google_connected_mapped_locations
    } = useSelector((state: any) => state?.workspaceManagementState);
    const [data, setData] = useState<any>([
        { id: 1, name: "Sreekanth", locationName: "Marketing Review", isSelected: false },
        { id: 2, name: "MMHK", locationName: "Review Inbox", isSelected: true },
        { id: 3, name: "Daina", locationName: "Dummy Review", isSelected: false },
        { id: 1, name: "Sreekanth", locationName: "Marketing Review", isSelected: false },
        { id: 2, name: "MMHK", locationName: "Review Inbox", isSelected: true },
        { id: 3, name: "Daina", locationName: "Dummy Review", isSelected: false },
        { id: 1, name: "Sreekanth", locationName: "Marketing Review", isSelected: false },
        { id: 2, name: "MMHK", locationName: "Review Inbox", isSelected: true },
        { id: 3, name: "Daina", locationName: "Dummy Review", isSelected: false },
        { id: 1, name: "Sreekanth", locationName: "Marketing Review", isSelected: false },
        { id: 2, name: "MMHK", locationName: "Review Inbox", isSelected: true },
        { id: 3, name: "Daina", locationName: "Dummy Review", isSelected: false },
        { id: 1, name: "Sreekanth", locationName: "Marketing Review", isSelected: false },
        { id: 2, name: "MMHK", locationName: "Review Inbox", isSelected: true },
        { id: 3, name: "Daina", locationName: "Dummy Review", isSelected: false },
    ]);
    
    return (
        <React.Fragment>
            <div className="select-location-setting-table-form">
                <h3>Select Locations</h3>
                {/* <div className="settings-form-item">
                    <input
                        className=""
                        defaultValue={""}
                        placeholder={"Search locations to select"}
                    />
                </div> */}
                <div className="location-table">
                    <div className='table-responsive'>
                        <table className="table m-0">
                            <thead className="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Location Name</th>
                                    <th>
                                        <img
                                                src={getImage(selectedItems?.length === data?.length ? "checked-icon" : "un-checked-icon")}
                                                alt="check-uncheck-icon"
                                                style={{ cursor: 'pointer' }} 
                                                onClick={(e) => {
                                                    // e.stopPropagation(); // Prevents triggering the row click
                                                    // handleDeleteTeamMember(member?.id);
                                                    if (selectedItems?.length === data?.length) {
                                                        setSelectedItems([]);
                                                        return;
                                                    }
                                                    setSelectedItems(data?.map((item: any) => item?.id));
                                                }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody><tr className="d-none"><td></td><td></td><td></td></tr></tbody>
                        </table>
                    </div>
                </div>
                
                <div className="location-table">
                    <div className='table-responsive'>
                        <table className="table m-0">
                            <thead className="d-none"><tr><th></th><th></th><th></th></tr></thead>
                            <tbody>
                                {data?.map((item: any, idx: number) => (
                                <tr key={idx}>
                                    <td>{(idx+1)}</td>
                                    <td>
                                        {/* <img src={getImage("gmail-icon")} alt="user" /> */}
                                        <div>{item?.locationName || item?.pageName}</div>
                                    </td>
                                    <td>
                                        <img
                                            src={getImage(
                                                selectedItems?.find((selectedId: any) => selectedId === item?.id)
                                                ? "checked-icon"
                                                : "un-checked-icon"
                                            )}
                                            alt="check-uncheck-icon"
                                            style={{ cursor: 'pointer' }} 
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents triggering the row click
                                                const selected_item = selectedItems?.find((selectedId: any) => selectedId === item?.id);
                                                if (selected_item) {
                                                    const selected_filtered_items = selectedItems?.filter((selectedId: any) => selectedId !== item?.id);
                                                    setSelectedItems(selected_filtered_items);
                                                    return;
                                                }
                                                setSelectedItems([...selectedItems, item?.id]);
                                            }}
                                        />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleGoogleLocationsContinue(selectedItems)}
                    >
                        Add
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LocationSelectionTable;