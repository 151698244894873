import React from 'react';
import './BlinkingOneDot.scss';
type BlinkingOneDotProps = {
    customStyle?: any;
    isStarted?: boolean;
};
const BlinkingOneDot: React.FC<BlinkingOneDotProps> = ({customStyle, isStarted}: any) => {
    return (
        <div
            className={`${isStarted ? 'blinking-dot-animation' : 'blinking-dot'}`}
            style={{ ...customStyle }}
        >
        </div>
    );
};

export default BlinkingOneDot;