import React, {useState} from 'react';
import './Settings.scss';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import SettingsMenu from '../../components/Layout/global-menu/SettingsMenu';
// import { openMobileDrawer } from '../../profile/reducer';
// import Workspace from '../../components/Layout/header/components/workspace';
import { getImage } from '../../unified/image_service';
import SettingsMenuLoader from '../../components/Layout/global-menu/SettingsMenuLoader';
import IntegrationsSettingsLoader from './Integrations/IntegrationsSettingsLoader';
import SkeletonLoader from '../../components/Loaders/SkelitonLoader/SkeletonLoader';

interface SettingsProps {
    children?: React.ReactNode;
}

const SettingsLoader: React.FC<SettingsProps> = ({children}) => {
    const { is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const { integration_tab } = useSelector((state: any) => state?.integrationState);
    const history = useNavigate();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div className="custom-settings">
                <div className='mobile-dashboard-nav-container'>
                    <div className='mobile-nav'>
                        <img src={getImage("mobile-nav-icon")} alt='hum-burger' />
                        <div><SkeletonLoader borderRadius="50%" width="30px" height="28px" /> </div>
                    </div>
                </div>
                <div className="custom-settings-container">
                    <div className={`settings-menu-bar ${is_show_mobile_settings_nav_bar ? "d-block setting-animation-menu-bar": ""}`}>
                        <SettingsMenuLoader/>
                    </div>
                    <div className="settings-body">
                        <div className={`settings-body-container ${is_show_mobile_settings_nav_bar ? "settings-mobile-body-container" : ""}`}>
                            {children || <IntegrationsSettingsLoader/>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SettingsLoader;