import React, { useEffect, useRef, useState } from 'react';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
// import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import back_btn from '../../../../assets/icons/back.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// // import star_half from '../../../../assets/icons/star-half-icon.svg';
// // import lock_icon from '../../../../assets/icons/lock-icon.svg';
// // import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// // import reply_icon from '../../../../assets/icons/reply-icon.svg';
// // import edit_icon from '../../../../assets/icons/edit-icon.svg';
// // import send_icon from '../../../../assets/icons/send-icon.svg';
// import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
// import { setSuccessMessage } from '../../../../dashboard/reducer';
import { getGeneratedVideoPresignedUrlByContentIdVideoTestimonial } from '../../../../utils/mediaService';
import ConversationHeader from './ConversationHeader';
import ConversationHeaderSD from './ConversationHeaderSD';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import ConversationCommonReply from './ConversationComponents/ConversationCommonReply';
import ConversationCommonBody from './ConversationComponents/ConversationCommonBody';
import ConversationCommonHeader from './ConversationComponents/ConversationCommonHeader';
const VideoConversations = (props: any) => {
  const dispatch = useDispatch();
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const [messages, setMessages] = useState<any[]>([{}, {}]);
  const [isCountDownOn, setIsCountDownOn] = useState(false);
  const { conversation, handleReplaceConversation, getImage, handlePrevPath, sender, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange } = props;
  const { contentId, properties, details, from } = conversation || {};
  const { reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, mentionedbyUserName, mentionedbyUserImageUrl, requestedDate, createdOn, requestorMessage, reviewComment, provider, starRating, ratingStars, likeCount, retweetCount, locationName, pagename, recieverCompany } = conversation || {};
  // console.log({ contentId, video_conversation: conversation });
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  )?.workSpaceName || "";

  const [isForReplyingUser, _] = useState<any>()
  // Automatically scroll to the bottom of the conversation when a new message is added
  const scrollRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState<any>(false);

  // Check if the content is overflowing
  useEffect(() => {
    console.log({ client_height: scrollRef?.current?.clientHeight, scrollHeight: scrollRef.current.scrollHeight });
    if (scrollRef.current) {
      const isContentOverflowing = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
      setIsOverflowing(isContentOverflowing);
      
      // Scroll to the bottom only if content is overflowing
      if (isContentOverflowing) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [messages?.length, scrollRef?.current?.clientHeight]);

  const [videoUrl, setVideoUrl] = React.useState<any>("");
  const get_video_url = async (contentId: any) => {
    const data = await getGeneratedVideoPresignedUrlByContentIdVideoTestimonial(contentId, logged_in_user_secret_data?.access_token);
    console.log({ video_data: data });
    
    if (data?.contentUrl) {
      // setVideoUrl(data?.presignedUrl);
      setVideoUrl(data?.contentUrl);
    } else {
      setVideoUrl("");
    }
  };
  useEffect(() => {
    if (contentId) {
      get_video_url(contentId);
    }
  }, [contentId]);
  // console.log({ videoUrl, conversation });
  
  return (
    <React.Fragment>
      <ConversationCommonHeader
        data_fields={{
          username_1: conversation?.reviewerDisplayName || "User Name",
          username_2: workspaceSettings?.workspaceName,
          user_profile_1: null, // conversation?.reviewerPhoteUrl, 
          user_profile_2: workspaceSettings?.logo,
          // star_ratings, 
          tag_text: ` reviewed ${workspaceSettings?.workspaceName}`,  // optional props fields
          action_buttons: null,
          // action_buttons: [{ text: "Close", },{ text: "Share",},{text: "Move"}], // optional props fields
          provider: conversation?.provider || "",
        }}
        handleParentCallback={(data: any) => console.log({ data })}
      />

      <div className="conversation-data">
        <div
          className="conversation-container"
          ref={scrollRef}
          style={{ justifyContent: isOverflowing || messages?.length > 2 ? "flex-start" : "flex-end" }}
        >
          {
            messages.map((item: any, idx: any) => (
              idx === 0
                ? <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: workspaceSettings?.workspaceName || "WS Name",
                    user_profile: workspaceSettings?.logo || getGenerateApiImageByName(workspaceSettings?.workspaceName),
                    star_ratings: 5, // optional props fields
                    tag_text: null,  // optional props fields
                    date: item?.createdOn || new Date(),
                    message_subject: null, // optional props fields
                    message: item?.reviewComment,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={false}
                />
                : <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: item?.reviewerDisplayName || conversation?.reviewerDisplayName,
                    user_profile: null, // conversation?.reviewerPhoteUrl,
                    star_ratings: conversation?.starRating || conversation?.ratingStars || 3, // optional props fields
                    tag_text: null,  // optional props fields
                    date: item?.createdOn || conversation?.createdOn,
                    message_subject: null, // optional props fields
                    message: item?.reviewComment || conversation?.reviewComment,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={true}
                  handleReply={(data: any) => console.log({ data })}//handleReply
                />
            ))
          }
        </div>
      </div>

      <ConversationCommonReply
        data_fields={null}
        handleParentCallback={(data: any) => console.log({ data })}
        isReply={isReply}
        isCountDownOn={isCountDownOn}
        setReplyText={setReplyText}
        isForReplyingUser={true}
        textRef={textRef}
        sender={sender}
        showingLeftContent={false}
        showFileInputIcon={false}
        showEmojiInputIcon={false}
        showLockIcon={true}
      />

      {/* <div className="conversation-section-container-lg h-100">
        <ConversationHeader
          header_title={" submitted a video for "}
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-sectio conversation-section-video-feedback">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" id="scrollableDiv">
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <img
                          className="user-avatar"
                          src={getGenerateApiImageByName(reviewerDisplayName)}
                          alt="avatar"
                      />
                  }
                  <span className="description">
                    <div
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <div className="user_name me-2 ">
                        {reviewerDisplayName || "User Name"}
                      </div>
                      {
                        ratingStars ? (
                          <div className="rating-social-email">
                            <div>
                              {Array.from(Array(Number(ratingStars)).keys()).map((_: any, index: number) => {
                                return (
                                  <img
                                    className="star"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                            </div>
                            <div className="rating">
                              {ratingStars}/5
                            </div>
                          </div>
                        ) : null
                      }
                    </div>

                    <div className="client-container mb-2">
                      {
                        // new Date(createdOn).toDateString() + ' '
                        // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                        // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                        // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " - " + (recieverCompany || "Company Name")
                      }
                    </div>
                    <p>{reviewComment}</p>
                    <div className="my-1">
                      <div
                        style={{
                          position: "relative",
                          paddingTop: "60.25%",
                          display: videoUrl ? "block" : "none",
                        }}
                      >
                        <iframe
                          // src={`https://iframe.mediadelivery.net/embed/${conversation?.LibraryId}/${conversation?.videoId}?autoplay=false`}
                          loading="lazy"
                          src={videoUrl?.replaceAll('@', "/")}
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            width: "100%",
                            borderRadius: 5,
                          }}
                          allow="accelerometer; gyroscope; autoplay: false; encrypted-media; picture-in-picture;"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </div>
                    
                  </span>
                </div>
                <p className="text-start mt-2 date">
                  {"Video Testimonial"}{" "}
                  -{" "}
                  <span>
                    {
                      // new Date(createdOn).toDateString() + ' '
                      // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                      // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                      // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                      moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                      // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                      // moment(details?.requested_time).format('Y-m-d')
                    }
                  </span>
                </p>
              </div>
              
              <div className='py-5'></div>
            </div>
          </Scrollbars>
        </div>
      </div> */}
      
      {/* <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
         
          <ConversationHeaderSD
            header_title={" submitted a video for "}
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <div className="conversation-body">
            <Scrollbars
              style={{
                paddingRight: 10,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              // style={{ paddingRight: 10 }}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              // autoHeightMin={250}
              // autoHeightMax={250}
              // autoHeightMin={480}
              autoHeightMax={"100%"}
              thumbMinSize={10}
              universal={true}
            >
              <div className="conversation-body" id="scrollableDiv">
                <div className="conversation-person-1">
                  <div className="details">
                    {
                      reviewerPhoteUrl
                      ? <img
                          className="image-logo"
                          src={reviewerPhoteUrl || user_avatar}
                          alt="avatar"
                        />
                      : <img
                            className="user-avatar"
                            src={getGenerateApiImageByName(reviewerDisplayName)}
                            alt="avatar"
                        />
                    }
                    
                    <div className="description">
                      <div
                        className={`d-flex align-items-center user-ratings-container`}
                      >
                        <div className="user_name me-2">
                          {reviewerDisplayName || "User Name"}
                        </div>
                        {
                          ratingStars ? (
                            <div className="rating-social-email">
                              <div>
                                {Array.from(Array(Number(ratingStars)).keys()).map((_: any, index: number) => {
                                  return (
                                    <img
                                      className="star"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                              </div>
                              <div className="rating">
                                {ratingStars}/5
                              </div>
                            </div>
                          ) : null
                        }
                      </div>

                      <div className="client-container mb-2">
                        {
                          // new Date(createdOn).toDateString() + ' '
                          // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                          // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                          // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                          moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A") + " - " + (recieverCompany || "Company Name")
                        }
                      </div>
                      <p>{reviewComment}</p>
                      <div className="my-1">
                        <div
                          style={{
                            position: "relative",
                            paddingTop: "60.25%",
                            display: videoUrl ? "block" : "none",
                          }}
                        >
                          <iframe
                            // src={`https://iframe.mediadelivery.net/embed/${conversation?.LibraryId}/${conversation?.videoId}?autoplay=false`}
                            loading="lazy"
                            src={videoUrl?.replaceAll('@', "/")}
                            style={{
                              border: "none",
                              position: "absolute",
                              top: 0,
                              height: "100%",
                              width: "100%",
                              borderRadius: 5,
                            }}
                            allow="accelerometer; gyroscope; autoplay: false; encrypted-media; picture-in-picture;"
                            allowFullScreen={true}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-start mt-2 date">
                    {"Video Testimonial"}{" - "}
                    <span>
                      {
                        // new Date(createdOn).toDateString() + ' '
                        // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                        // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                        // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                        moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                        // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                        // moment(details?.requested_time).format('Y-m-d')
                      }
                    </span>
                  </p>
                </div>
                <div className='py-5'></div>
              </div>
            </Scrollbars>
          </div>
        )}
      </div> */}
    </React.Fragment>
  )
};

export default VideoConversations;
