import React, { useState } from 'react';
import './Dashboard.scss';
import { setup_cards } from '../../components/Layout/global-menu/menu.config';
import { useDispatch } from "react-redux";
import { getImage } from '../../unified/image_service';
import { openMobileDrawer } from '../../profile/reducer';
import SkeletonLoader from '../../components/Loaders/SkelitonLoader/SkeletonLoader';

interface SignUpDashboardProps {
    // children?: React.ReactNode;
    parentCallback?: any;
}
const LoaderElement = () => { 
    return (
        <div className="dashboard-loader-section">
            <div className="dashboard-loader-container">
                <SkeletonLoader borderRadius='25px' width="150px" height="20px"/>
                <SkeletonLoader margin="28px 0 0 0" borderRadius='25px' width="100%" height="14px"/>
                <SkeletonLoader margin="11px 0 23px 0" borderRadius='25px' width="100%" height="14px" />
                <div className="dashboard-footer-loader">
                    <SkeletonLoader margin="0 0 0 auto" borderRadius='25px' width="197px" height="34px"/>
                </div>
            </div>
        </div>
    );
};

const FeatureLoaderElement = () => { 
    return (
        <div className="dashboard-loader-section">
            <div className="dashboard-loaders-container">
                <SkeletonLoader borderRadius='25px' width="150px" height="20px"/>
                <SkeletonLoader margin="28px 0 0 0" borderRadius='25px' width="100%" height="14px"/>
                <SkeletonLoader margin="11px 0 0 0" borderRadius='25px' width="100%" height="14px"/>
            </div>
        </div>
    );
};

const LoadingDashboard: React.FC<SignUpDashboardProps> = ({}: any) => {
    
    return (
        <React.Fragment>
            <div className="loader-dashboard">
                <div className='mobile-dashboard-nav-container'>
                    <div className='mobile-nav'>
                        <img src={getImage("mobile-nav-icon")} alt='hum-burger' />
                        <div><SkeletonLoader borderRadius="50%" width="30px" height="28px" /></div>
                    </div>
                </div>
                <div className="loader-dashboard-container">
                    <div className="loader-dashboard-header">
                        <SkeletonLoader borderRadius='25px' width="293px" height="30px"/>
                    </div>
                    <div className="loader-dashboard-body">
                        <div className="loader-body-title">
                            <SkeletonLoader borderRadius='25px' width="163px" height="23px" />
                        </div>
                        <div className="loader-dashboard-body-container">
                            <div className="loader-dashboard-card-container">
                                <div className="loader-card-container">
                                    {
                                        setup_cards?.map((card: any, idx: number) => <LoaderElement key={idx} />)
                                    }
                                </div>
                                {/* <div className="participate-card-container">
                                    <div className='title'>Learn and Participate</div>
                                    <div className="card-container">
                                        {
                                            learn_n_participate_cards?.map((card: any, idx: number) => <LoaderElement key={idx} />)
                                        }
                                    </div>
                                </div> */}
                            </div>
                            <div className="loader-dashboard-feature-container">
                                <div className="title">
                                    <SkeletonLoader borderRadius='25px' width="293px" height="24px"/>
                                </div>
                                <div className={"loader-feature-container"}>
                                    <FeatureLoaderElement/>
                                    <FeatureLoaderElement/>
                                    <FeatureLoaderElement/>
                                    <FeatureLoaderElement/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default LoadingDashboard;