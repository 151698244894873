import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import Constants from '../constants';
import axios from "axios";
import { generateAccessToken, getNotification } from './reducer';
// import { setAccessToken } from '../login/loginReducer';
import {
    api_url, api_version,
    // auth_url
} from '../utils/server';
// import Cookies from 'js-cookie';
import { getKeyValue, setKeyValue } from '../utils/indexedDBManagementService';

function* getNotifications(action: any): Generator<any> {

    // console.log("getNotifications action===>", action.payload)
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let getPayload = action.payload || action;
        const { data, status }: any = yield call(axios.post, api_url + `/api/${api_version}/clapup/getNotificationSettings?userId=${getPayload}`, {}, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // const { data }: any = yield call(axios.post, `https://dev.clapup.me/api/${api_version}/clapup/getNotificationSettings?userId=${getPayload}`);
        if (status === 200) {   
            // console.log('getNotification==>', data)
            yield put(getNotification(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* setNotifications(action: any): Generator<any> {

    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        // console.log("setNotifications action===>", action.payload);
        const { data, status }: any = yield call(axios.post, api_url + `/api/${api_version}/clapup/setNotificationPreferences`, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            if (data === "success") {
                yield getNotifications(action.payload.clapupUserId);
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* generateToken(action?: any): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const accessed_user_details = yield call(getKeyValue, "accessed_user_details");

        const token_obj = {
            // refreshToken: localStorage.getIte("refresh_token") || sessionStorage.getIte("refresh_token"),
            email: accessed_user_details?.email,
            clapupUserId: logged_in_user_secret_data?.cuid,
            jwtToken: logged_in_user_secret_data?.access_token,
        };
        const { data, status }: any = yield call(axios.post, api_url + `/api/${api_version}/ClapupIdentity/GenerateJwtToken`,
            token_obj,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // 'Cookie': document.cookie,
                    "Authorization": `refreshToken ${logged_in_user_secret_data?.refresh_token}`,
                },
            // "refreshToken": logged_in_user_secret_data?.refresh_token,
            }
        );
        console.log({token_data: data});
        if (status !== 200 || !data?.jwtToken || !data?.refreshToken) {
            throw new Error('Failed to generate access token');
        }
        const index_db_data: any = {
            ...logged_in_user_secret_data,
            access_token: data?.jwtToken,
            refresh_token: data?.refreshToken,
        }

        yield call(setKeyValue, "logged_in_user_secret_data", index_db_data);
        // localStorage.setItem("access_token", data?.jwtToken);
        // localStorage.setItem("refresh_token", data?.refreshToken);
        // const cookieOptions: any = {
        //     // expires: expirationDate,
        //     httpOnly: false,
        //     secure: true,
        //     sameSite: 'strict'
        // };
        // Cookies.set('refresh_token', data?.refreshToken, cookieOptions);
        delay(2000);
        window.location.reload();
        // yield put(setAccessToken(data));

    } catch (error) {
        console.log('generateAccessToken error', error);
        yield put({ type: "LOGOUT" });
        delay(2000);
        window.location.reload();
    }
}


//watcher saga
function* profileSaga() {
    yield all([
        takeEvery(Constants.TRIGGER_NOTIFICATIONS, getNotifications),
        takeEvery(Constants.TRIGGER_SET_NOTIFICATION, setNotifications),
        takeEvery(Constants.GENERATE_ACCESS_TOKEN, generateToken),
    ]);
}

export default profileSaga;