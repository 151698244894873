import React, { useState } from 'react';
import './ViewDetails.scss';
import moment from 'moment';
// import { convertISODate } from '../../../../../utils/convertingFunction';
import { getImage, getGenerateApiImageByName, testCollaboratorImage } from '../../../../unified/image_service';
import { convertISODate } from '../../../../utils/convertingFunction';
import { Dropdown } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { useSelector } from 'react-redux';

const ViewDetails = (props: any) => {
    const { list_data }: any = props || {};
    const {
        label_1, value_1,
        label_2, value_2,
        label_3, value_3,
        label_4, value_4,
        label_5, value_5,
        label_6, value_6,
        label_7, value_7,
        properties_Keywords,
    }: any = list_data || {};
    const { menuTags: tag_labels } = useSelector((state: any) => state?.workspaceManagementState);
    const [tags, setTags] = useState<any>(tag_labels || []);
    const [tag_data, setTagData] = useState<any>(null);
    const [isOpenTagDropdown, setIsOpenTagDropdown] = useState<any>("");
    
    function getDateDifference(date1: any, date2: any) {
        if(!date1 || !date2) return " --- ";
        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
        console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
        
        // Calculate the number of days
        let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Calculate the remaining hours
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        // Calculate the remaining minutes
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        let res = "";
        let years: any = 0;
        if (days > 365) {
            years = Math.floor(days / 365);
            res += years + " Years, ";
            days -= years * 365;
            res += days + " Days ";
            days = 0;
        }
        if (days > 0) {
            res += days + " Days, ";
        }
        if (!years) {
            res += hours + " Hours, " + minutes + " Minutes";
        }
        return res;
    }

    const getTagIconByHexCode = (hex_code?: any) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="15.99998664855957" height="16" viewBox="0 0 15.99998664855957 16"><g><path d="M0,1.75198C0,1.75198,0,7.22003,0,7.22003C0,7.84182,0.245056,8.438,0.683963,8.87691C0.683963,8.87691,7.12126,15.3142,7.12126,15.3142C8.03565,16.2286,9.51697,16.2286,10.4314,15.3142C10.4314,15.3142,15.3142,10.4314,15.3142,10.4314C16.2286,9.51698,16.2286,8.03567,15.3142,7.12128C15.3142,7.12128,8.87689,0.683976,8.87689,0.683976C8.43799,0.245069,7.8418,0.0000127074,7.22002,0.0000127074C7.22002,0.0000127074,1.75563,0.0000127074,1.75563,0.0000127074C0.786375,-0.00364485,0,0.78273,0,1.75198ZM4.09646,2.9224C4.40688,2.9224,4.70458,3.04571,4.92408,3.26521C5.14357,3.48471,5.26688,3.78241,5.26688,4.09282C5.26688,4.40323,5.14357,4.70093,4.92408,4.92043C4.70458,5.13993,4.40688,5.26324,4.09646,5.26324C3.78605,5.26324,3.48835,5.13993,3.26885,4.92043C3.04936,4.70093,2.92605,4.40323,2.92605,4.09282C2.92605,3.78241,3.04936,3.48471,3.26885,3.26521C3.48835,3.04571,3.78605,2.9224,4.09646,2.9224Z" fill={hex_code || "#1354EC"} fillOpacity="1" /></g></svg>
        )
    };

    const handleSearchTags = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        const query_data = tag_labels;
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setTags(data);
            
        } else{
            const data = query_data?.filter((item: any) => {
                return item?.tagName?.toLowerCase().includes(value?.toLowerCase());
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                setTags(data);
            }else{
                setTags(data);
            }
        }
    }
    const tag_menu = (
        <div className='message-bot-menu-container'>
            <div className="search-container">
                <DebounceInput
                    // value={searchInput}
                    // style={{height: 58}}
                    type="text" 
                    placeholder={"Search tags"}
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(e: any) => handleSearchTags(e.target.value)} 
                />
            </div>
            <div className="menu-items scroll-content">
                {
                    tags?.map((tag: any) => (
                        <div className="item" key={tag?.tagId}
                            onClick={() => {
                                // handleSetTagLabelForDetails(tag);
                            }}
                        >
                            {
                                getTagIconByHexCode(tag?.tagColor)
                            }
                            <div className='item-title'>{ tag?.tagName }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="view-details-table">
                <div className="table-row">
                    <div className="label">{label_1}:</div>
                    <div className="value">
                        <div className="input-value-details">
                            <input type='text' defaultValue={value_1} placeholder='' />
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">{label_2}:</div>
                    <div className="value">
                        <div className="value-details">
                            {value_2 || " --- "}
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">{label_3}:</div>
                    <div className="value">
                        <div className="value-details">
                            {value_3 || " --- "}
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">{label_3}:</div>
                    <div className="value">
                        <div className="value-details">
                            {
                                // "QuestionCreatedOn" in (conversation || {})
                                // ? moment(convertISODate(conversation?.QuestionCreatedOn)).utc().format("ll") + " " + moment(conversation?.QuestionCreatedOn).format("LT")
                                // : 
                                value_4
                                ? moment(convertISODate(value_4)).utc().format("ll") + " " + moment(convertISODate(value_4)).utc().format("LT")
                                : // new Date(MentionedOn).toDateString() + " " + // new Date(MentionedOn).toLocaleTimeString()
                                " --- "
                            }
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">{label_5}:</div>
                    <div className="value">
                        <div className="value-details">
                            {value_5 || " --- "}
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">{label_6}:</div>
                    <div className="value">
                        <div className="value-details">
                            {
                                // "QuestionCreatedOn" in (conversation || {})
                                // ? moment(convertISODate(conversation?.QuestionCreatedOn)).utc().format("ll") + " " + moment(conversation?.QuestionCreatedOn).format("LT")
                                // : 
                                value_6
                                ? moment(convertISODate(value_6)).utc().format("ll") + " " + moment(convertISODate(value_6)).utc().format("LT")
                                : // new Date(MentionedOn).toDateString() + " " + // new Date(MentionedOn).toLocaleTimeString()
                                " --- "
                            }
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">
                        {label_7}:
                    </div>
                    <div className="value">
                        <Dropdown 
                            onOpenChange={() => setIsOpenTagDropdown(!isOpenTagDropdown)}
                            open={isOpenTagDropdown}
                            trigger={['click']} 
                            overlay={tag_menu}
                            // disabled={disable_tag}
                            placement="bottom"
                        >
                            <div className="input-value-details">
                                {/* <img src={getImage("primary-icon")} alt="provider-icon" /> */}
                                {getTagIconByHexCode("#1234f4")}
                                <div>{"No Tags"}</div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div className="view-keywords-collaboration-container">
                <div className='keywords-container'>
                    <p className="keywords-title">Keywords for search</p>
                    <textarea
                        className='keywords-textarea'
                        placeholder='Add multiple keywords separated by comma and a space for better search visibility'
                        onChange={(e: any) => {
                            const value = e.target.value;
                            // dispatch(updateContentBeforeSaving({
                            //     key: "properties_Keywords",
                            //     value,
                            // }))
                        }}
                        defaultValue={properties_Keywords}
                        // value={updatable_content?.properties_Keywords}
                    />
                </div>
                <div className='collaborator-container'>
                    <p className='title'>Collaborators</p>
                    <div className='collaborator-body'>
                        <img src={testCollaboratorImage("man-collaborator")} alt="collaborator" />
                        <img src={testCollaboratorImage("boy-collaborator")} alt="collaborator" />
                        <img src={testCollaboratorImage("woman-collaborator")} alt="collaborator" />
                        <img src={testCollaboratorImage("boy-collaborator")} alt="collaborator" />
                        <img src={testCollaboratorImage("man-collaborator")} alt="collaborator" />
                    </div>
                </div>
                
            </div>
        </React.Fragment>
    );
};

export default ViewDetails;
