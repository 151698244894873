import React, { useEffect } from 'react';
import SeoTab from './ImageFormTabs/SeoTab';
import Versions from './ImageFormTabs/Versions';
import Properties from './ImageFormTabs/Properties';
import BiopageTab from './ImageFormTabs/BiopageTab';
import { useDispatch } from 'react-redux';
import { setShowingSaveButton } from '../../ContentComponents/reducer';

const content_tabs = [
    {
        label: 'Biopage',
        component: <BiopageTab />,
        isShowSaveButton: true,
    },
    {
        label: 'SEO',
        component: <SeoTab/>,
        isShowSaveButton: true,
    },
    {
        label: 'Versions',
        component: <Versions/>,
        isShowSaveButton: false,
    },
    {
        label: 'Properties',
        component: <Properties/>,
        isShowSaveButton: true,
    }
];

export default function ContentSeoImageTabs() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState<any>(content_tabs[0]);
    const path_name = window.location.pathname;
    const query_string = window.location.search;
    // console.log({ query_string , path_name});
    useEffect(() => {
        dispatch(setShowingSaveButton(activeTab?.isShowSaveButton));
    }, [activeTab?.isShowSaveButton]);
    
    useEffect(() => {
        setActiveTab(content_tabs[0]);
    }, [query_string]);

    return (
        <React.Fragment>
            <div className='seo-content-container'>
                <div className='seo-content-tabs'>
                    {
                        content_tabs.map((tab, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`seo-content-tab ${activeTab?.label === tab.label ? 'seo-content-tab-active' : ''}`}
                                    onClick={() => {
                                        setActiveTab(tab);
                                    }}
                                >
                                    {tab.label}
                                </div>
                            );
                        })
                    }
                </div>
                <div className='seo-content-body'>
                    {activeTab?.component}
                </div>
            </div>
        </React.Fragment>
    );
}
