import React, { useEffect, useState } from 'react';
import './LinkManagement.scss';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getImage } from '../../unified/image_service';
import { DebounceInput } from 'react-debounce-input';
// import arrow_down_icon from '../../../../assets/icons/settings/arrow-down.svg';
import {
    // updateBioPageSettings,
    addCustomLinks, getBioPageLinks, removeCustomLinks,
    updateCustomLinks, resetEmbedRecords, manageBioPageAnnouncements
} from '../Settings/BioPageSettings/bioPageReducer';
// import { getProfileInfo } from '../Settings/UserProfile/profileManageReducer';
// import { Spinner } from 'react-bootstrap';
import Dialog from '../../components/Dialogs/Dialog';
import { link_types } from './CustomLinks/custom.link.types';
import NestedParentLinkForm from './CustomLinks/CustomLinkForms/NestedLinks/NestedParentLinkForm';
import NestedChildLinkForm from './CustomLinks/CustomLinkForms/NestedLinks/NestedChildLinkForm';
import { setSuccessMessage } from '../../unified/reducer';
import EmbedVideoForm from './CustomLinks/CustomLinkForms/EmbedVideos/EmbedVideoForm';
import EmbedImageForm from './CustomLinks/CustomLinkForms/EmbedImages/EmbedImageForm';
import EmbedVideoLinkForm from './CustomLinks/CustomLinkForms/EmbedVideoLinks/EmbedVideoLinkForm';
// import NestedLink from './CustomLinks/CustomLinkForms/NestedLinks/NestedLink';
// import EmbedImage from './CustomLinks/CustomLinkForms/EmbedImages/EmbedImage';
// import EmbedVideo from './CustomLinks/CustomLinkForms/EmbedVideos/EmbedVideo';
// import EmbedVideoLink from './CustomLinks/CustomLinkForms/EmbedVideoLinks/EmbedVideoLink';
// import SimpleLink from '../biopage-management/Components/CustomLinks/CustomLinkForms/SimpleLinks/SimpleLink';
import AnnouncementForm from './CustomLinks/CustomLinkForms/Announcement/AnnouncementForm';
import SocialLinkForm from './CustomLinks/CustomLinkForms/SocialLinks/SocialLinkForm';
import Search from '../../components/Search/SearchComponent';
import DropdownModalForm from './DropdownModalForm';
import moment from 'moment';
import SimpleLinkForm from './CustomLinks/CustomLinkForms/SimpleLinks/SimpleLinkForm';

interface LinkManagementProps {
    // children?: React.ReactNode;
}

const LinkManagement: React.FC<LinkManagementProps> = ({}) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {userPermissions} = useSelector((state: any) => state?.login);
    const { all_links } = useSelector((state: any) => state?.bioPageSettingState);
    const [link_list, setLinkList] = useState<any>([]);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    // const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    // const {email}: any = personalInfo || {};    

    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [linkModal, setLinkModal] = useState<any>(null);
    const [showEditLink, setShowEditLink] = useState<any>(null);
    const [showDeleteLink, setShowDeleteLink] = useState<any>(false);
    const [showNestedLink, setShowNestedLink] = useState<any>(null);

    // announcement ===== start
    const handleCreateNewAnnouncement = (data: any, file?: any) => { 
        dispatch(manageBioPageAnnouncements(data, file));
        // dispatch(setSuccessMessage("Added Successfully"));
    };

    const handleUpdateAnnouncement = (data: any, file?: any) => {
        dispatch(manageBioPageAnnouncements(data, file));
        // setShowEditAnnouncement(null);
        // dispatch(setSuccessMessage("Updated Successfully"));
    }

    const handleRemoveAnnouncement = (item: any) => {
        const data = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workSpaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
            id: item?.id,
            contentId: item?.contentId,
            displayText: "",
            linkUrl: "",
            logoUrl: "",
            announcementText: "",
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            // I want to use moment for 12 hours format
            startTime: moment().format('hh:mm:ss A'),
            endTime: moment().format('hh:mm:ss A'),
            mode: "delete",
        }
        // console.log({ data });
        
        dispatch(manageBioPageAnnouncements(data));
        // setShowDeleteAnnouncement(false);
        // dispatch(setSuccessMessage("Deleted Successfully"));
    }

    // announcement ===== and

    const handleCreateNewLink = (linkData: any, file?: any) => { 
        // console.log({ linkData });
        // dispatch(addCustomLinks(linkData));
        if (Number(linkModal?.id) === 6) {
            handleCreateNewAnnouncement(linkData, file);
            setShowEditLink(null);
            setShowNestedLink(null);
            return;
        }
        dispatch(addCustomLinks(linkData, file));
        setLinkModal(null);
        // dispatch(setSuccessMessage("Added Successfully"));
    };

    const handleUpdateLink = (linkData: any, file?: any) => {
        if (Number(linkData?.linkType) === 6) {
            handleUpdateAnnouncement(linkData, file);
            return;
        }
        if (linkData !== "NestedLinks") { 
            dispatch(updateCustomLinks(linkData, file));
        } else {
            const bio_page__object = {
                clapupUserId: logged_in_user_secret_data?.cuid,
                roleId: logged_in_user_secret_data?.roleId,
                workspaceId: logged_in_user_secret_data?.wuid,
            };
            dispatch(getBioPageLinks(bio_page__object));
            setTimeout(() => dispatch(setSuccessMessage("Saved Successfully")), 2000);
        }
        
        // dispatch(setSuccessMessage("Saved Successfully"));
    };

    const handleRemoveLink = (item: any) => {
        if (Number(item?.linkType) === 6) {
            handleRemoveAnnouncement(item);
            return;
        }

        const data = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
            id: item?.id,
            contentId: item?.contentId,
            linkType: item?.linkType,
            videoLink: '',
            mediaType: item?.linkType === 3 ? "videos" : item?.linkType === 5 ? "images" : '',
            contentIds: '',
            mode: "delete",
            linkCategory: "customlinks",
            displayText: '',
            linkUrl: '',
            label: '',
            iconUrl: '',
            iconName: '',
            parentId: 0
        }
        dispatch(removeCustomLinks(data));
        // setShowDeleteLink(false);
        // dispatch(setSuccessMessage("Deleted Successfully"));
    };

    const { success_message } = useSelector((state: any) => state?.unified);
    useEffect(() => {
        if(all_links?.length){
            // dispatch(getBioPageLinks());
            setLinkList(all_links);
            // setShowEditLink(null);
            setShowEditLink(null);
            setShowNestedLink(null);
            setShowDeleteLink(false);
        }
    }, [dispatch, all_links?.length]);
    
    useEffect(() => {
        if(success_message && all_links?.length){
            // dispatch(getBioPageLinks());
            setLinkList(all_links);
            setShowEditLink(null);
            setShowNestedLink(null);
            setShowDeleteLink(false);
        }
    }, [dispatch, success_message]);

    // const [CustomLinkEditModal, setCustomLinkEditModal] = useState<any>(null);
    const menu = (
        <div className='dropdown-custom-link-types'>
            <ul>
                {
                    link_types?.map((item: any, idx: number) => (
                        <li key={idx} onClick={() => {
                            setOpenDropdown(false);
                            setLinkModal(item);
                        }}>
                            {item?.title}
                        </li>
                    ))
                }
            </ul>
        </div>
    );

    useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "customLinks") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [history, userPermissions?.length]);


    const handleSearch = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        if(isEmailCheck){
            const data = all_links?.filter((item: any) => item?.email.includes(value));
            setLinkList(data);
            
        } else{
            const data = all_links?.filter((item: any) => {
                return item?.linkDisplayText?.toLowerCase().includes(value?.toLowerCase());
            })
        
            if(!data?.length){
                const data = all_links?.filter((item: any) => item?.linkLabel.includes(value));
                setLinkList(data);
            }else{
                setLinkList(data);
            }
        }
    }

    const getLinkLabelByLinkType = (linkType: any) => {
        if (Number(linkType) === 1) {
            return "Simple Link";
        }
        else if (Number(linkType) === 2) {
            return "Nested Link";
        }
        else if (Number(linkType) === 3) {
            return "Video Embed";
        }
        else if (Number(linkType) === 4) {
            return "Video Link";
        }
        else if (Number(linkType) === 5) {
            return "Image Embed";
        }
        else if (Number(linkType) === 6) {
            return "Announcement";
        }
        else if (Number(linkType) === 7) {
            return "Social Link";
        } else {
            return "N/A";
        }
    }

    return (
        <React.Fragment>
            {/* Dialog */}
            <>
            {
                linkModal !== null // Add Dialog
                ?   <Dialog
                        open={linkModal}
                        parentCallback={(flag?: any) => setLinkModal(flag)}
                        classes='dialog-container-link-form'
                        header={linkModal?.header_add}
                        footer={false}
                    >
                        <linkModal.form 
                            // {...linkModal}
                            onCancel={() => {
                                setLinkModal(null);
                                dispatch(resetEmbedRecords());
                            }}
                            onParentCallback={(data: any, file?: any) => {
                                handleCreateNewLink(data, file);
                            }}      
                        />
                    </Dialog>
                : showEditLink !== null // Edit Dialog
                ?   <Dialog
                        open={showEditLink}
                        parentCallback={(flag?: any) => {
                            setShowEditLink(flag);
                            dispatch(resetEmbedRecords());
                        }}
                        classes='dialog-container-link-form'
                        header={
                            showEditLink?.linkType === 1
                            ? "Edit Simple Link"
                            : showEditLink?.linkType === 2
                            ? "Edit Nested Link"
                            : showEditLink?.linkType === 3
                            ? "Edit Video Embed"
                            : showEditLink?.linkType === 4
                            ? "Edit Video Link"
                            : showEditLink?.linkType === 5
                            ? "Edit Image Embed"
                            : showEditLink?.linkType === 6
                            ? "Edit Announcement"
                            : showEditLink?.linkType === 7
                            ? "Edit Social Link"
                            : null
                        }
                        footer={false}
                    >
                        {
                            // showEditLink?.linkType?.includes('simple-link')
                            showEditLink?.linkType === 1
                            ? <SimpleLinkForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} url={showEditLink?.linkUrl}
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    handleUpdateLink(data, file);

                                }}
                            />
                            // : showEditLink?.id?.includes('nested')
                            :  showEditLink?.linkType === 2
                            ? <NestedParentLinkForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} url={showEditLink?.linkUrl}
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditLink(null);
                                    handleUpdateLink(data, file);

                                }}
                            />
                            // : showEditLink?.id?.includes('embed-video-')
                            : showEditLink?.linkType === 3
                            ? <EmbedVideoForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} 
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                children={showEditLink?.result ? JSON.parse(showEditLink?.result) : undefined}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditLink(null);
                                    handleUpdateLink(data, file);

                                }}
                            />
                            // : showEditLink?.id?.includes('embed-video-link')
                            : showEditLink?.linkType === 4
                            ? <EmbedVideoLinkForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} url={showEditLink?.linkUrl}
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                children={showEditLink?.videoLink?.split(',').map((link: any, idx: number) => ({
                                    idx, // Adding 1 to make the index 1-based
                                    youtubeLink: `${link.trim()}`,
                                }))}
                                onCancel={() => {
                                    setShowEditLink(null);
                                    dispatch(resetEmbedRecords());
                                }}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditLink(null);
                                    handleUpdateLink(data, file);

                                }}
                            />
                            
                            // : showEditLink?.id?.includes('embed-image-')
                            : showEditLink?.linkType === 5
                            ? <EmbedImageForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} 
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                children={showEditLink?.result ? JSON.parse(showEditLink?.result) : undefined}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditLink(null);
                                    handleUpdateLink(data, file);

                                }}
                            />
                            // : showEditLink?.id?.includes('announcement')
                            : showEditLink?.linkType === 6
                            ? <AnnouncementForm
                                link_item={showEditLink}
                                id={showEditLink?.announcementId}
                                displayTitle={showEditLink?.announcementDisplayTitle || showEditLink?.displayTitle} 
                                linkUrl={showEditLink?.announcementLinkUrl || showEditLink?.linkUrl}
                                logo={showEditLink?.announcementLogoUrl || showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                announcementText={showEditLink?.announcementText}
                                from={showEditLink?.startDate} to={showEditLink?.endDate}
                                {...showEditLink}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data?: any, file?: any) => {
                                    handleUpdateAnnouncement(data, file);

                                }}
                            />
                            : showEditLink?.linkType === 7
                            ? <SocialLinkForm
                                link_item={showEditLink}
                                id={showEditLink?.id} displayText={showEditLink?.linkDisplayText || showEditLink?.displayTitle}
                                label={showEditLink?.linkLabel} url={showEditLink?.linkUrl}
                                logo={showEditLink?.iconUrl} logoName={showEditLink?.iconName}
                                children={showEditLink?.result ? JSON.parse(showEditLink?.result) : undefined}
                                {...showEditLink}
                                onCancel={() => setShowEditLink(null)}
                                onParentCallback={(data: any) => {
                                    // console.log({ data });
                                    // handleCreateNewLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditLink(null);
                                    handleUpdateLink(data);

                                }}
                            />
                            
                            : null
                        }
                    </Dialog>
                :  showDeleteLink // Delete Dialog
                ?   <Dialog
                        open={showDeleteLink}
                        onCancel={() => setShowDeleteLink(false)}
                        parentCallback={(flag?: any) => {
                            handleRemoveLink(flag);
                        }}
                        classes='dialog-container-content'
                        header={showDeleteLink?.linkType === 6 ? 'Delete Announcement?' : 'Delete Link?'}
                        warning_dialog_content={`By clicking delete button, the ${showDeleteLink?.linkType === 6 ? 'announcement' : 'link'} will be deleted, and will not be visible in biopage.`}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                : null
            }

            {/* Nested Custom Form - Dialog */}
            {
                showNestedLink !== null
                ?   <Dialog
                        open={showNestedLink}
                        parentCallback={(flag?: any) => setShowNestedLink(flag)}
                        classes='dialog-container-link-form'
                        header={"Add a Nested item"}
                        footer={false}
                    >
                        <NestedChildLinkForm
                            {...showNestedLink}
                            link_item={showNestedLink}
                            onCancel={() => setShowNestedLink(null)}
                            onParentCallback={(data: any, file?: any) => {
                                // console.log({ data });
                                // handleCreateNewLink(data);
                                // dispatch(updateCustomLinks(data));
                                // setShowNestedLink(null);
                                handleUpdateLink(data, file);
                            }}
                        />
                    </Dialog>
                    : null
            }
            </>

            <div className='link-management'>
                <div className='link-management-container'>
                    <div className="mobile-container-header">
                        <Search
                            parentCallback={handleSearch}
                            parentKey="link-management"
                            search_placeholder={"Search Link Here"}
                        />
                    </div>
                    <div className="container-header">
                        <p className="sm-header-title">All Links</p>
                        <div className="search-wrap">
                            <DebounceInput
                                minLength={0}
                                debounceTimeout={500}
                                onChange={(e: any) => handleSearch(e.target.value)}
                                type="search"
                                placeholder={"Search"}
                            />
                            <img className="search-icon" src={getImage("search-icon")} alt="search" />
                        </div>
                        <DropdownModalForm
                            menu={menu}
                            openDropdown={openDropdown}
                            setOpenDropdown={setOpenDropdown}
                        />
                        
                    </div>
                    <p className="lg-header-title">All Links</p>
                    <div className="container-body">
                        <div className="link-list-table">
                            <div className='table-responsive'>
                                <table className="table">
                                    <thead className="top-table">
                                        <tr>
                                            <th>Name</th>
                                            <th>Link Type</th>
                                            <th>Created By</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {link_list?.map((item: any, idx: number) => (
                                        <tr key={idx}
                                            onClick={() => setShowEditLink(item)}
                                        >
                                            <td>
                                                <div className='title-icon-container'>
                                                    <img src={getImage("link-icon")} alt="link-icon" />
                                                    <p>{item?.displayTitle || item?.linkLabel || item?.announcementDisplayTitle || item?.linkDisplayText}</p>
                                                </div>
                                            </td>
                                            <td>{getLinkLabelByLinkType(item?.linkType)}</td>
                                            <td>{item?.createdBy || "N/A"}</td>
                                            <td>
                                                <div className="edit-delete-container">
                                                    {
                                                        getLinkLabelByLinkType(Number(item?.linkType)) === "Nested Link"
                                                        ?
                                                        <img
                                                            src={getImage("edit-icon")}
                                                            alt="edit"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevents triggering the row click
                                                                setShowNestedLink(item);
                                                            }}
                                                        />
                                                        : null
                                                    }
                                                    <img
                                                        src={getImage("delete-icon")}
                                                        alt="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            setShowDeleteLink(item);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        ))}    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LinkManagement;