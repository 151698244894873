import React from 'react';
import { Input } from 'antd';
import { RootState } from '../../../../store';
import moment from 'moment';
import { updateContentBeforeSaving } from '../../../ContentComponents/reducer';
import { useDispatch, useSelector } from 'react-redux';

export default function Properties() {
  const dispatch = useDispatch();
  const { updatable_content, folder_contents, default_content_idx }: any = useSelector((state: RootState) => state?.contentState);
  const content = folder_contents[default_content_idx];
  return (
    <div className='properties-seo-container scroll-content'>
      <div className='property-content'>
        <div className='keys'>
          <span>File Name:</span>
          <span>File Size:</span>
          <span>Uploaded By:</span>
          <span>Email:</span>
          <span>Uploaded On:</span>
          <span>Last Updated By:</span>
          <span>Last Updated On:</span>
        </div>
        <div className='values'>
          <span>{content?.contentFileName}</span>
          <span>{content?.contentSize}</span>
          <span>{content?.createdBy}</span>
          <span>{content?.email || "N/A"}</span>
          <span>{moment(content?.createdOn).format("hh:mm:ss A, DD MMM YYYY")}</span>
          <span>{content?.updatedBy}</span>
          <span>{moment(content?.updatedOn).format("hh:mm:ss A, DD MMM YYYY")}</span>
        </div>
      </div>
      
      <div className='form-group' style={{marginTop: '0%'}}>
        <label className="form-label">Keywords</label>
        <Input.TextArea
            className='form-textarea property-textarea'
            rows={3}
            placeholder='Add multiple keywords separated by comma and a space for better search visibility'
            onChange={(e: any) => {
              const value = e.target.value;
              dispatch(updateContentBeforeSaving({
                key: "properties_Keywords",
                value,
              }))
            }}
            value={updatable_content?.properties_Keywords}
        />
      </div>
    </div>
  )
}
