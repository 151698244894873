import React, { useEffect } from 'react';
import {Modal, Table} from 'antd';

import './CommonModal.scss';
import { useDispatch } from 'react-redux';
const CommonModal = ({title, okText, cancelText, footer, modal_visible, setModalVisible, children, width, padding, closeIcon = false, centered, border = false, height = null, class_name = ""}: any) => {
    const [visible, setVisible]: any = React.useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        console.log('modal_visible', modal_visible);
        modal_visible ? setVisible(modal_visible) : setVisible(false);
    }, [modal_visible]);

    const handleOk = ({e}: any) => {
        e?.preventDefault();
        console.log({values: e});
        // setModalVisible(false);
    };
    return (
        <React.Fragment>
            <Modal
                title={title || ""}
                centered={centered || false}
                closable={closeIcon}
                closeIcon={closeIcon}
                open={visible}
                // border={border}
                onOk={(e) => {
                    console.log({e: e.target});
                    handleOk(e);
                }}
                okText={okText || "Continue"}
                onCancel={() => {
                    // setVisible(false);
                    setModalVisible(false);
                }}
                cancelText={cancelText || "Cancel"}
                footer={footer}
                width={width || 800}
                // className="custom-modal-body"
                style={{ padding: padding || '20px 24px'}}
                className={`${class_name}`}
                
            >
                {
                    children
                }
            </Modal>
        </React.Fragment>
    );
};

export default CommonModal;