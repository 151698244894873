import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { changePage, setGlobalMessageType } from '../../unified/reducer';
import { useDispatch } from 'react-redux';
import { getImage } from '../../unified/image_service';
import { setCallToActionTemplate, setQuickReplyButtonsTemplate, toggleEditableTemplate, toggleIsAlreadySaved, toggleTemplateEdition } from '../Promotions/reducer';

const RightBottomCommerceTabs = () => {
    const route_path = window.location.pathname;
    const query_string = window.location.search;
    const {isReplyingOn, pageRoute, global_message_type}: any = useSelector((state: RootState) => state?.unified);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const commerce_tabs: any[] = [
        {
            name: "Campaign",
            key: "campaign",
            icon: "right-side-campaign",
        },
        {
            name: "Template",
            key: "template",
            icon: "right-side-template",
        }
    ];

    const current_message_type = sessionStorage.getItem('message_type');
    useEffect(() => {
        if (!global_message_type && !current_message_type) {
            sessionStorage.setItem('message_type', 'campaign');
            // localStorage.setItem('message_type', 'whats-app-messages');
            // sessionStorage.setItem('message_type', 'bot-messages');
            dispatch(setGlobalMessageType("campaign"));
        } else {
            const found_message_type = commerce_tabs.find((tab: any) => (tab.key === global_message_type || tab.key === current_message_type));
            if (!found_message_type) {
                sessionStorage.setItem('message_type', 'campaign');
                // localStorage.setItem('message_type', 'whats-app-messages');
                dispatch(setGlobalMessageType("campaign"));
            } else {
                setGlobalMessageType(global_message_type || current_message_type);
                dispatch(setGlobalMessageType(global_message_type || current_message_type));
            }
        }
    }, [global_message_type, current_message_type]);
    return (
        <React.Fragment>
            <div className="right-tab-messages">
                {
                    commerce_tabs.map((tab: any, index: number) => {
                        return (
                            <div key={index}>
                                <button
                                    key={index}
                                    title={tab?.name}
                                    onClick={() => {
                                        sessionStorage.setItem('message_type', tab.key);
                                        // localStorage.setItem('message_type', tab.key);
                                        dispatch(setGlobalMessageType(tab.key));
                                        // clicking campaign tab should reset the template edition
                                        if (tab?.key === 'campaign') {
                                            dispatch(toggleTemplateEdition(null));
                                            dispatch(setCallToActionTemplate(null));
                                            dispatch(setQuickReplyButtonsTemplate(null));
                                            dispatch(toggleIsAlreadySaved(null));
                                            dispatch(toggleEditableTemplate(false));
                                        }
                                        isReplyingOn
                                        ? dispatch(changePage("/promotions"))
                                        : navigate(`/promotions?${tab.key}=promotions`);
                                    }}
                                    className={`inbox-button${(global_message_type === tab.key) ? "-active" : ""}`}
                                    type="button"
                                >
                                    {/* Image */}
                                    <img src={getImage(tab?.icon)} alt={tab?.name} />
                                </button>
                                <div className='btn-title'>{ tab?.name }</div>
                            </div>
                        );
                    })
                }

            </div>
            {/* Mobile - Tabs */}
            <div className="bottom-tab-messages">
                {
                    commerce_tabs.map((tab: any, index: number) => {
                        return (
                            <div key={index} className='btn-container'>
                                <button
                                    title={tab?.name}
                                    onClick={() => {
                                        sessionStorage.setItem('message_type', tab.key);
                                        // localStorage.setItem('message_type', tab.key);
                                        dispatch(setGlobalMessageType(tab.key));
                                        // clicking campaign tab should reset the template edition
                                        if (tab?.key === 'campaign') {
                                            dispatch(toggleTemplateEdition(null));
                                            dispatch(setCallToActionTemplate(null));
                                            dispatch(setQuickReplyButtonsTemplate(null));
                                            dispatch(toggleIsAlreadySaved(null));
                                            dispatch(toggleEditableTemplate(false));
                                        }
                                        isReplyingOn
                                        ? dispatch(changePage("/promotions"))
                                        : navigate(`/promotions?${tab.key}=promotions`);
                                    }}
                                    className={`inbox-button${(global_message_type === tab.key) ? "-active" : ""}`}
                                    type="button"
                                >
                                    {/* Image */}
                                    <img src={getImage(tab?.icon)} alt={tab?.name} />
                                </button>
                                <div className='btn-title'>{ tab?.name }</div>
                            </div>
                        );
                    })
                }

            </div>
        </React.Fragment>
    );
};

export default RightBottomCommerceTabs;