import React, { useState } from 'react';
import './BotMessagesReviewSummary.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';
// import moment from 'moment';
// import { convertISODate } from '../../../../../utils/convertingFunction';
import { DebounceInput } from 'react-debounce-input';
import { Dropdown } from 'antd';

const BotMessagesSummaryList = (props: any) => {
    const { list_data }: any = props || {};
    const { provider, type, to, ticketNo, createdOn, repliedBy, repliedOn, chatStatus }: any = list_data || {};

    // tag management
    const [tag_data, setTagData] = useState<any>(null);
    const [isOpenTagDropdown, setIsOpenTagDropdown] = useState<any>("");
    const getTagIconByHexCode = (hex_code?: any) =>{
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="15.99998664855957" height="16" viewBox="0 0 15.99998664855957 16"><g><path d="M0,1.75198C0,1.75198,0,7.22003,0,7.22003C0,7.84182,0.245056,8.438,0.683963,8.87691C0.683963,8.87691,7.12126,15.3142,7.12126,15.3142C8.03565,16.2286,9.51697,16.2286,10.4314,15.3142C10.4314,15.3142,15.3142,10.4314,15.3142,10.4314C16.2286,9.51698,16.2286,8.03567,15.3142,7.12128C15.3142,7.12128,8.87689,0.683976,8.87689,0.683976C8.43799,0.245069,7.8418,0.0000127074,7.22002,0.0000127074C7.22002,0.0000127074,1.75563,0.0000127074,1.75563,0.0000127074C0.786375,-0.00364485,0,0.78273,0,1.75198ZM4.09646,2.9224C4.40688,2.9224,4.70458,3.04571,4.92408,3.26521C5.14357,3.48471,5.26688,3.78241,5.26688,4.09282C5.26688,4.40323,5.14357,4.70093,4.92408,4.92043C4.70458,5.13993,4.40688,5.26324,4.09646,5.26324C3.78605,5.26324,3.48835,5.13993,3.26885,4.92043C3.04936,4.70093,2.92605,4.40323,2.92605,4.09282C2.92605,3.78241,3.04936,3.48471,3.26885,3.26521C3.48835,3.04571,3.78605,2.9224,4.09646,2.9224Z" fill={hex_code || "#000000"} fillOpacity="1" /></g></svg>
        )
    }

    const tags = [
        {
            label: "#12B76A",
            value: "Billing",
        },
        {
            label: "#0F43BD",
            value: "Training Request",
        },
        {
            label: "#F79009",
            value: "Payment",
        },
        {
            label: "#F04438",
            value: "Onboarding Issues",
        },
    ];

    const handleSearchTags = (value: any) => {
        console.log({ value });
    }

    const tag_menu = (
        <div className='message-bot-menu-container'>
            <div className="search-container">
                {/* <img className='search-icon' src={getImage("search-icon")} alt="search" /> */}
                <DebounceInput
                    // value={searchInput}
                    // style={{height: 58}}
                    type="text" 
                    placeholder={"Search Labels"}
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(e: any) => handleSearchTags(e.target.value)} 
                />
            </div>
            <div className="menu-items scroll-content">
                {
                    tags?.map((tag: any) => (
                        <div className="item" key={tag?.label}
                            onClick={() => {
                                setTagData(tag);
                                setIsOpenTagDropdown(false);
                            }}
                        >
                            {
                                getTagIconByHexCode(tag?.label)
                            }
                            <div className='item-title'>{ tag?.value }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
    
    // assignee management
    const [assignee_user, setAssigneeUser] = useState<any>(null);
    const [isOpenAssigneeUserDropdown, setIsOpenAssigneeUserDropdown] = useState<any>("");

    const assignee_users = [
        {
            id: "#12B76A",
            username: "Mehedi Hasan",
        },
        {
            id: "#0F43BD",
            username: "Kuber Mannur",
        },
        {
            id: "#F79009",
            username: "Sreekanth PM",
        },
        {
            id: "#F04438",
            username: "MMHK",
        },
    ];
    const handleSearchAssigneeUser = (value: any) => {
        console.log({ value });
    }
    const assignee_menu = (
        <div className='message-bot-menu-container'>
            <div className="search-container">
                {/* <img className='search-icon' src={getImage("search-icon")} alt="search" /> */}
                <DebounceInput
                    // value={searchInput}
                    // style={{height: 58}}
                    type="text" 
                    placeholder={"Search Users"}
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(e: any) => handleSearchAssigneeUser(e.target.value)} 
                />
            </div>
            <div className="menu-items scroll-content">
                {
                    assignee_users?.map((au: any) => (
                        <div className="item" key={au?.id}
                            onClick={() => {
                                setAssigneeUser(au);
                                setIsOpenAssigneeUserDropdown(false);
                            }}
                        >
                            <img src={getGenerateApiImageByName(au?.username)} alt="assignee-name" />
                            <div className='item-title'>{ au?.username }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="review-table">
                <div className="table-row">
                    <div className="label">Email:</div>
                    <div className="value">
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            readOnly={true}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                console.log({ value });
                            }}
                            type="text"
                            placeholder={"Enter email"}
                        />
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Telephone:</div>
                    <div className="value">
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            readOnly={false}
                            style={{ background: "#FFFFFF" }}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                console.log({ value });
                            }}
                            type="text"
                            placeholder={"Enter phone number"}
                        />
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">
                        Tags:
                    </div>
                    <div className="value">
                        <Dropdown 
                            onOpenChange={() => {setIsOpenTagDropdown(!isOpenTagDropdown);}}
                            open={isOpenTagDropdown}
                            trigger={['click']} 
                            overlay={tag_menu} 
                            placement="bottom"
                        >
                            <div className="value-details">
                                {/* <img src={getImage("primary-icon")} alt="provider-icon" /> */}
                                {getTagIconByHexCode(tag_data?.label)}
                                <div>{tag_data?.value || "tag"}</div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Ticket No:</div>
                    <div className="value">
                        <div className="value-details">{ticketNo || "ticketNo"}</div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Channel:</div>
                    <div className="value">
                        <div className="value-details">
                            <img src={getImage(provider)} alt="provider-icon" />
                            <div>{"channel"}</div>
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Assigned:</div>
                    <div className="value">
                        <Dropdown 
                            onOpenChange={() => {setIsOpenAssigneeUserDropdown(!isOpenAssigneeUserDropdown);}}
                            open={isOpenAssigneeUserDropdown}
                            trigger={['click']} 
                            overlay={assignee_menu} 
                            placement="bottom"
                        >
                            <div className="value-details">
                                <img src={getGenerateApiImageByName(assignee_user?.username)} alt="assignee-name" />
                                <div>{assignee_user?.username || "assignedTo"}</div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Status:</div>
                    <div className="value">
                        <div className="value-details">
                            {
                              chatStatus === "closed"
                              ? <img src={getImage("closed-status")} alt="status-icon"/>
                              : chatStatus === "dispatched"
                              ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                              : chatStatus === "toDispatched"
                              ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                              : chatStatus === "In Progress"
                              ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                              : chatStatus === "reportedSpam"
                              ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                              : chatStatus === "open"
                              ? <img src={getImage("opened-status")} alt="status-icon"/>
                              : <img src={getImage("in-progressed-status")} alt="status-icon"/>
                              // : null
                            }
                            <div style={{textTransform: "capitalize"}}>{chatStatus || "chatStatus"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BotMessagesSummaryList;
