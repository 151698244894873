import React from 'react';
import { Dropdown } from 'antd';
import { getImage } from '../../unified/image_service';
// import { useDispatch } from 'react-redux';

interface DropdownModalFormProps {
    setOpenDropdown?: any;
    openDropdown?: any;
    menu?: any;
    disabled?: any;
}

const DropdownForm: React.FC<DropdownModalFormProps> = ({menu, disabled, setOpenDropdown, openDropdown}) => {
    // const dispatch = useDispatch();
    
    return (
        <React.Fragment>
            <Dropdown
                onOpenChange={(e: any) => {
                    setOpenDropdown(!openDropdown);
                }}
                open={openDropdown}
                trigger={["click"]}
                overlay={menu}
                placement="bottomRight"
                disabled={disabled}
            >
                <button
                    type="button"
                    onClick={() => {
                        setOpenDropdown(!openDropdown);
                    }}
                    className="dropdown-add-button"
                >
                    <span>Add New</span> <img src={getImage("add-plus")} alt="arrow-plus-icon" />
                </button>
            </Dropdown>
        </React.Fragment>
    );
};

export default DropdownForm;
