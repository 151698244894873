import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { changePage } from '../../unified/reducer';
import { useDispatch } from 'react-redux';

const MobileBottomTabs = () => {
    const route_path = window.location.pathname;
    const query_string = window.location.search;
    const {isReplyingOn, pageRoute}: any = useSelector((state: RootState) => state?.unified);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            {/* <div className="mobile-bottom-tabs ms-0 flex-row justify-content-around align-items-center mt-2"> */}
            <div className="mobile-bottom-tabs ms-0 mt-2">
                <div>
                  <button
                    onClick={() => {
                      if (!query_string?.includes("conversations")) {
                        dispatch(changePage(`/unified?all=conversations`));
                      }
                    }}
                    className={`inbox-btn${
                      query_string?.includes("conversations") ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-gallery-wall ${
                      query_string?.includes("conversations") && "inbox-gallery-wall-active"
                    } p-0 m-0`}
                  >
                    {/* Inbox */}
                    Conversations
                  </p>
                </div>
                {/* <div className='special-sm-device'>
                  <button
                    onClick={() => {
                      if(!route_path?.includes("gallery")) {
                        dispatch(changePage(`/gallery`));
                      }
                    }}
                    className={`gallery-btn${
                        route_path?.includes("gallery") ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-gallery-wall ${
                      route_path?.includes("gallery") && "inbox-gallery-wall-active"
                    } p-0 m-0`}
                  >
                    Gallery
                  </p>
                </div> */}
                <div>
                  <button
                    onClick={() => {
                      if (!query_string?.includes("wall")) {
                        dispatch(changePage(`/unified?all=wall`));
                      }
                    }}
                    
                    className={`coder-wall-btn${
                      query_string?.includes("wall") ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-gallery-wall ${
                      query_string?.includes("wall") && "inbox-gallery-wall-active"
                    } p-0 m-0`}
                  >
                    Wall
                  </p>
                </div>
              </div>
        </React.Fragment>
    );
};

export default MobileBottomTabs;