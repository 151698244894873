import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./Teams.scss";
import TeamList from "./TeamList";
import TeamManagement from "./TeamManagement";

const Teams: React.FC = () => {
    const { userPermissions } = useSelector((state: any) => state?.login);
    // const dispatch = useDispatch();
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    const { editable_team } = useSelector((state: any) => state?.teamState);
    // console.log({ editable_team });
    return (
        <React.Fragment>
            {
                editable_team
                ? <TeamManagement/>
                : <TeamList/>
            }
        </React.Fragment>
    );
};

export default Teams;