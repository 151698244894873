import Constants from "../../../constants";

const initialState = {
    currentWorkspace: {},
    workspaces: [],
    is_changed_workspace: false,
    roles: [],
    loading: false,
    error: null,
    isLoggedOut: false,
}

export const setLogout = (data: any) => {
    return {
        type: "SET_IS_LOGOUT",
        payload: data
    }
}

// toggle changing workspace mode
export const toggleChangeWorkspace = (data: any) => {
    return {
        type: Constants.TOGGLE_CHANGE_WORKSPACE,
        payload: data
    };
}

// set current workspace
export const setCurrentWorkspace = (data: any) => {
    return {
        type: Constants.SET_CURRENT_WORKSPACE,
        payload: data
    };
}

// get workspaces
export const setWorkspaces = (data?: any) => {
    return {
        type: Constants.SET_WORKSPACES,
        payload: data
    };
}

export const findWorkspaceList = (data?: any) => {
    return {
        type: Constants.FIND_WORKSPACE_LIST,
        payload: data
    };
}

// get roles
export const getRoles = (data?: any) => {
    return {
        type: Constants.GET_ROLES,
        payload: data
    };
}

export const findRoleList = (data?: any) => {
    return {
        type: Constants.FIND_ROLE_LIST,
        payload: data
    };
}


const headerReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case Constants.TOGGLE_CHANGE_WORKSPACE:
            return {
                ...state,
                is_changed_workspace: action.payload
            };
        case Constants.SET_WORKSPACES:
            return {
                ...state,
                workspaces: action.payload,
                is_changed_workspace: true,
            };

        case Constants.FIND_ROLE_LIST:
            return {
                ...state,
                roles: action.payload
            };

        case Constants.SET_CURRENT_WORKSPACE:
            return {
                ...state,
                currentWorkspace: action.payload
            };
        case "SET_IS_LOGOUT":
            return {
                ...state,
                isLoggedOut: false
            };
        case Constants.LOGOUT:
            return {
                ...state,
                currentWorkspace: {},
                workspaces: [],
                roles: [],
                loading: false,
                error: null,
                isLoggedOut: true,
            };
        default:
            return state;
    }
}

export default headerReducer;
