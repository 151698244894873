import React from 'react';

const FeedMessageTabs = ({ activePage, left_top_tabs, handlePageChange }: any) => {
  const query_string = window.location.search;
  return (
      <React.Fragment>
          <div className="unified-btn d-flex justify-content-between align-items-center">
            {
              left_top_tabs?.map((tab: any, idx: number) => (
                <button
                  key={idx}
                  className={`${query_string?.includes(`${tab.key}`) || (!query_string && tab.key === "all") ? "active" : ""}`}
                  onClick={() => {
                    // console.log({ activeTab, current_Key: tab.key });
                    if (!query_string?.includes(`${tab.key}`)) {
                      handlePageChange(`${tab.key}=${activePage}`)
                    }
                  }}
                  type="button"
                >
                  {tab.title}
                </button>
              ))
            }
          </div>
      </React.Fragment>
  );
};

export default FeedMessageTabs;