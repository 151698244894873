import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
// import S3 from 'aws-sdk/clients/s3.js';
// import AWS from 'aws-sdk';
import Constants from '../../constants';
// import { getBioPageAnnouncements, getFolderContents, createFolderContents, setBioPageAnnouncements, setBioPageLinks, setFolderContents, setEmbedImageRecords, setEmbedVideoRecords, setUpdatedBioPageSettingsData } from './mediaReducer';
import { generateAccessToken } from '../../profile/reducer';
import {
    api_url, api_version,
    // aws_access_key, aws_account_id, aws_secret_key
} from '../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../unified/reducer';
import { addNewFolder, uploadContentFileByAwsLink, removeFolders, setFolders, toggleCreateFolderModal, setShowingUploadingProgress, setUploadProgressCount, setIsContentUploadedError, setOneTimeFolderContents, setFolderContent, setFolderContents, setFirstTimeFolders, setUpdateFolderContent, setIsUpdatedContent, setContentDetails, removeContentsFromFolder, setBioPageFolders, setContentVersions, setRevertVersionSuccessfully, setFolderPathsForRedux, setFolderPathContentsPayload, setFolderPathContents, setIsContentComing, setRecentFileContents, setFolderPathContentsFromIndexDB } from './reducer';
import { deleteKeyValue, getKeyValue, setKeyValue } from '../../utils/indexedDBManagementService';
// import { getContentUrl } from '../../utils/mediaService';

// Worker Saga:
function* getBioPageFolderDataForGallery(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/ListSystemFolders`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ folderData: data, status });
        if (status === 200) {
            const folders = data?.contentDetails?.reduce((result: any, item: any) => {
                const contentText = item?.contentText ? JSON.parse(item.contentText) : {};
                if (contentText) {
                    result.push({
                        ...item,
                        folderName: contentText?.folderName,
                        displayName: contentText?.displayName
                    });
                }
                return result;
            }, []);
            
            console.log({folders});
            
            yield put(setBioPageFolders({
                folders: folders,
                folderNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            }));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getFirstTimeFolderData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/ListContent`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ folderData: data, status });
        if (status === 200) {
            const folders = data?.contentDetails?.reduce((result: any, item: any) => {
                const contentText = item?.contentText ? JSON.parse(item.contentText) : {};
                if (contentText) {
                    result.push({
                        ...item,
                        folderName: contentText?.folderName,
                        displayName: contentText?.displayName
                    });
                }
                return result;
            }, []);
            
            yield put(setFirstTimeFolders({
                folders: folders,
                folderNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            }));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getFolderData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/ListContent`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ folderData: data, status });
        if (status === 200) {
            const folders = data?.contentDetails?.reduce((result: any, item: any) => {
                const contentText = item?.contentText ? JSON.parse(item.contentText) : {};
                if (contentText) {
                    result.push({
                        ...item,
                        folderName: contentText?.folderName,
                        displayName: contentText?.displayName
                    });
                }
                return result;
            }, []);
            
            yield put(setFolders({
                folders: folders,
                folderNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            }));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* createFolderData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/CreateFolder`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        console.log({folderData: data});
        
        if (status === 200) {
            const contentText = data?.contentText ? JSON.parse(data.contentText) : {};
            
            yield put(toggleCreateFolderModal(false));
            yield put(setSuccessMessage("Folder created successfully"));
            yield put(addNewFolder({
                ...data,
                folderName: contentText.folderName,
                displayName: contentText.displayName,
                createdOn: data?.createdOn || data?.updatedOn,
                createdBy: data?.createdBy || data?.updatedBy
            }));

        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* createContentData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/CreateContent`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        if (status === 200) {
            yield put(uploadContentFileByAwsLink({
                contentFile: action.contentFile,
                contentPayload: action.payload,
                data
            }));
        } else {
            yield put(setShowingUploadingProgress(false));
            yield put(setIsContentUploadedError(true));
            yield put(setErrorMessage(`Your content is not uploaded`))
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* updateFolderContentDataNewVersion(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/UploadNewVersion`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        if (status === 200) {
            yield put(uploadContentFileByAwsLink({
                contentFile: action.contentFile,
                contentPayload: action.payload,
                data: {
                    ...action.content_data,
                    contentFileName: action.payload.contentFileName,
                    contentSize: action.payload.contentSize,
                    ...data,
                },
                isNewVersion: true
            }));
        } else {
            yield put(setShowingUploadingProgress(false));
            yield put(setIsContentUploadedError(true));
            yield put(setErrorMessage(`Your content is not uploaded`))
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* callAwsPreAssignedLinkWithContentFile(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, contentFile, contentPayload, isNewVersion } = action.payload;
        console.log({ data, contentFile, contentPayload });
        const { id: contentId, folderPath, contentTypeId, workspaceId, url: aws_pre_assigned_url, contentFileName, uniqueNo  } = data;
        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        // const new_image_file_name = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            }
            else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            }
            else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            }
            else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            }
            else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            }
            else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            }
            else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            }
            else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }

        // ======= aws private =====
        
        // const s3_bucket = {
        //   endpoint: `https://${aws_account_id}.r2.cloudflarestorage.com`,
        //   accessKeyId: `${aws_access_key}`,
        //   secretAccessKey: `${aws_secret_key}`,
        //   signatureVersion: 'v4',
        // };
        
        // const s3 = new S3(s3_bucket);
        // const s3_object = {
        //   Bucket: workspaceId,
        //   Key: new_image_file_name,
        //   Expires: 3600
        // };
        // // yield navigator.clipboard.writeText(JSON.stringify(s3_object));
        // const aws_link: any = yield call([s3, s3.getSignedUrlPromise], 'putObject', s3_object);
        // console.log({ aws_link });
        // const { status }: any =  yield call(axios.put, aws_link, contentFile, {
        //     headers: {
        //         'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
        //     },
        // });
        
        // ============= X =============
        
        const { status: aws_status }: any = yield call(axios.put, aws_pre_assigned_url, contentFile, {
        // const { status: aws_status }: any = yield call(axios.put, aws_link, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        });
        // console.log({ status });
        if (aws_status === 200) {
            const cloudFlareR2Path = `${folderPath}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
            const url = api_url + `/api/${api_version}/gallery/${isNewVersion ? "UpdateContentAfterNewVersionUpload" : "UpdateCloudFlareR2UploadStatusAfterContentCreation"}`;
            const { data: cloud_data,  status }: any = yield call(axios.post, url, {workspaceId, contentId, cloudFlareR2Path}, {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            });
            // const status = 200;
            if(status === 200) {
                yield put(setUploadProgressCount(100));
                yield put(setShowingUploadingProgress(false));
                // const promise = getContentUrl(data);
                // const urlValue = yield Promise.resolve(promise);
                const currentContent = {
                    ...data,
                    presignedUrl: cloud_data?.presignedUrl
                };

                // console.log({ currentContent });

                if (!isNewVersion) {
                    yield put(setFolderContent(currentContent));
                } else {
                    yield put(setUpdateFolderContent({
                        ...currentContent,
                        stage: "private",
                        addedToBioPage: false,
                    }));
                    yield delay(1500);
                    yield put(setIsUpdatedContent(true));
                }
                yield put(setSuccessMessage(`${mimetype?.includes("video") ? "Your video" : "Your image"} uploaded successfully`));
            } else {
                yield put(setIsContentUploadedError(true));
                yield put(setErrorMessage(`${mimetype?.includes("video") ? "Your video" : "Your image"} is not uploaded`))
            }
        } else {
            yield put(setIsContentUploadedError(true));
            yield put(setErrorMessage(`${mimetype?.includes("video") ? "Your video" : "Your image"} is not uploaded`))
        }
    } catch (error) {
        const { response }: any = error;
        yield put(setShowingUploadingProgress(false));
        yield put(setIsContentUploadedError(true));
        yield put(setErrorMessage(`Your content is not uploaded`))
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getOneTimeFolderContentData(action: any = {}): Generator<any> {
    // console.log("First TIme Getting Contents");
    
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/ListContent`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ contentData: data, status });
        if (status === 200) {
            // const promises = data?.contentDetails?.map((item: any) => getContentUrl(item));

            // const values: any = yield Promise.all(promises);

            // const newContents = data?.contentDetails?.map((item: any, index: number) => {
            //     const itemObject = {
            //         ...item,
            //     };

            //     const contentUrl = values[index];

            //     if (item?.subCategory === 'GalleryVideos') {
            //         itemObject['videoUrl'] = contentUrl;
            //     } else {
            //         itemObject['imageUrl'] = contentUrl;
            //     }
            //     return itemObject;
            // });

            yield put(setOneTimeFolderContents({
                // contents: newContents,
                contents: data?.contentDetails || [],
                contentNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            }));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getFolderContentData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/ListContent`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ contentData: data, status });
        if (status === 200) {
            // const promises = data?.contentDetails?.map((item: any) => getContentUrl(item));

            // const values: any = yield Promise.all(promises);

            // const newContents = data?.contentDetails?.map((item: any, index: number) => {
            // const itemObject = {
            //     ...item,
            // };

            // const contentUrl = values[index];

            // if (item?.subCategory === 'GalleryVideos') {
            //     itemObject['videoUrl'] = contentUrl;
            // } else {
            //     itemObject['imageUrl'] = contentUrl;
            // }
            //     return itemObject;
            // });
            yield put(setFolderContents({
                // contents: newContents,
                contents: data?.contentDetails || [],
                contentNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            }));
        }
        // if (status === 200) {

        //     // yield put(setFolderContents({
        //     //     contents: data?.contentDetails,
        //     //     contentNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
        //     // }));
        // }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* updateFolderContentData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/UpdateContentStages`;
        const { status, data }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ contentData: data, status });
        if (status === 200) {
            const content_data: any = {
                ...action.data,
            };
            data?.url && (content_data["contentUrl"] = data.url);
            yield put(setUpdateFolderContent(content_data));
            yield put(setIsUpdatedContent(true));
            if (action.payload?.stageName === "biopage") {
                yield put(setSuccessMessage(`${action.payload.stageValue === "1" ? "Added" : "Removed"} successfully`));
            } else if (action.payload?.stageName === "publish") {
                yield put(setSuccessMessage(`State updated successfully`));
            } else {
                yield put(setSuccessMessage(`Updated successfully`));
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* saveContentDetails(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, content_idx } = action.payload;
        const url = api_url + `/api/${api_version}/gallery/UpdateContent`;
        const { status }: any = yield call(axios.post, url, data, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ contentData: data, status });
        if (status === 200) {
            yield put(setContentDetails(data, content_idx));
            yield put(setSuccessMessage(`Saved Successfully`));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* deleteFolderData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { folder_ids, payload_object } = action.payload;
        const url = api_url + `/api/${api_version}/gallery/DeleteContents`;
        const { status }: any = yield call(axios.post, url, payload_object, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ payload_object, content_ids });
        if (status === 200) {
            yield put(removeFolders(folder_ids, `${folder_ids?.length > 1 ? "Folders" : "Folder"} deleted successfully`));
        } else {
            yield put(setErrorMessage(`Failed to delete ${folder_ids?.length > 1 ? "Folders" : "Folder"} | ${status}`));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* deleteContentData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { content_ids, payload_object } = action.payload;
        const url = api_url + `/api/${api_version}/gallery/DeleteContents`;
        const { status }: any = yield call(axios.post, url, payload_object, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ payload_object, content_ids });
        if (status === 200) {
            yield put(removeContentsFromFolder(content_ids, `${content_ids?.length > 1 ? "Files" : "File"} deleted successfully`));
        } else {
            yield put(setErrorMessage(`Failed to delete ${content_ids?.length > 1 ? "files" : "file"} | ${status}`));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getContentVersionData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/gallery/GetAllContentVersions?contentId=${action.payload}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ payload_object, content_ids });
        if (status === 200) {
            yield put(setContentVersions(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// worker saga
function* revertVersionData(action: any = {}): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const payload_object = action.payload;
        const url = api_url + `/api/${api_version}/gallery/RevertToVersion`;
        const { status }: any = yield call(axios.post, url, payload_object, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ payload_object, content_ids });
        if (status === 200) {
            yield put(setRevertVersionSuccessfully(true));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        yield put(setRevertVersionSuccessfully(true));
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}
// ======== new requirements =========
// getAllSenderKeyValues -> we have to get all sender info to insert all  in inbox_sender_state of redux store
export const managingKeyValueData = async (data: any) => {
    const { isForInserting, key, value } = data || {};
    // console.log("KeyData", {key, data });

    try {
        // Construct a unique key for storing data

        if (isForInserting) {
            // Example: Prepare the value for insertion
            // Replace with your insertion logic
            // console.log("Inserting key-value pair:", { key, value });
            const res = await setKeyValue(key, value); // Assume setSenderKeyValue is defined
            return res;
        } else {
            // Replace with your deletion logic
            // console.log("Deleting key:", key);
            const res = await deleteKeyValue(key); // Assume deleteKeyValue is defined
            return res;
        }
    } catch (error) {
        console.error("Error managing sender data:", error);
        return false;
    }
};


// request for changing replying on state
function* requestForManagingContentKeyValueForIndexDB(action?: any): Generator<any, void, any> {
    try {
        // console.log({ key_data: action.payload });
        if ("isForInserting" in action.payload) {
            const result = yield call(managingKeyValueData, action.payload);
            // console.log({result});
            // // Check the result from manageData
            if (result) {
                // Dispatch success if the result is truthy
                if (!action?.payload?.isNotRefreshing) {
                    yield put(setFolderPathsForRedux([]));
                    yield delay(500);
                }
                yield put(setFolderPathContents({contentDetails: [], nextDate: []}));
                yield put(setFolderPathsForRedux(action.payload.value));
            } else {
                console.error('manageData returned false');
                yield put(setErrorMessage("Index DB Error"));
            }
        }
        // Call the manageData function with the necessary arguments
        // // Dispatch a success
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}

// Worker Saga:
function* getFolderPathContentData(action: any = {}): Generator<any> {
    try {
        // const { isForScrolling } = action || {}; // if it for scrolling we will concat contentDetails to data 
        const { category: root, teamInternalName: teamRoot, isForNew, isForScrolling, folderPath } = action.payload;
        const { workspaceId, clapupUserId, teamId, createdOn, isFolder, category} = action.payload;
        let folder_key_name = `${root}${folderPath ? `-${folderPath}` : ""}`;
        let recent_content_files_key_name: any = root + "-recent-files";
        if (teamRoot) {
            recent_content_files_key_name = teamRoot + "-recent-files";
            folder_key_name = `${teamRoot}${folderPath ? `-${folderPath}` : ""}`;
        }

        if (!folder_key_name) { // for shared-with-me this api not called
            yield put(setFolderPathContentsPayload(null));
            return;
        }

        const all_folder_key_content_details: any = yield call(getKeyValue, "all_path_contents");
        const key_content_details: any = all_folder_key_content_details?.find((item: any) => item.hasOwnProperty(folder_key_name)) || null;
        const all_paths_key_recent_file_contents: any = yield call(getKeyValue, "all_path_recent_file_contents");
        const key_recent_file_contents: any = all_paths_key_recent_file_contents?.find((item: any) => item.hasOwnProperty(recent_content_files_key_name)) || null;
        console.log({
            all_folder_key_content_details, key_content_details, folder_key_name,
            all_paths_key_recent_file_contents, key_recent_file_contents, recent_content_files_key_name,
            // isForNew, isForScrolling
        });
        // if (key_content_details && folder_key_name !== "sharedWithMe") {
        if (key_content_details) {
            const folder_key_content_details: any = key_content_details[`${folder_key_name}`] || null;
            if (!isForNew && !isForScrolling) {
                const { contentDetails: stored_content_details } = folder_key_content_details || {};
                const current_stored_folder_key_content: any = {
                    ...folder_key_content_details,
                    not_found_data: stored_content_details?.length ? false : "Not found data",
                    is_data_come: true
                }
                yield put(setFolderPathContentsPayload(null));
                if (key_recent_file_contents) {
                    current_stored_folder_key_content["recentFiles"] = key_recent_file_contents[`${recent_content_files_key_name}`] || []
                }
                // console.log({ current_stored_folder_key_content });
                yield put(setFolderPathContentsFromIndexDB(current_stored_folder_key_content));
                return;
            }
        }

        // // old
        // if (key_content_details && folder_key_name !== "sharedWithMe") {
        //     const folder_key_content_details: any = key_content_details[folder_key_name] || null;
        //     // console.log({all_folder_key_content_details, folder_key_content_details, folder_key_name, isForNew, isForScrolling });
        //     // const folder_key_content_details: any = yield call(getKeyValue, folder_key_name);
        //     // yield call(managingKeyValueData, {isForInserting: false, key: folder_key_name, value: null });
            
        //     // if (folder_key_content_details?.contentDetails?.length && !isForNew && !isForScrolling) {
        //     if (folder_key_name !== "archives") {
        //         if (!isForNew && !isForScrolling) {
        //             yield put(setFolderPathContentsPayload(null));
        //             yield put(setFolderPathContents({...folder_key_content_details}));
        //             return;
        //         }
        //     }
        // }
        // if (folder_key_name !== "sharedWithMe") {
        // }
        yield put(setIsContentComing("from-api"));
        const payload = {
            workspaceId, clapupUserId,
            teamId,
            createdOn,
            isFolder,
            category,
            folderPath
        };
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/content/ListContent`;
        const { data, status }: any = yield call(axios.post, url, payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        
        if (status === 200 || status === 204) {
            // yield call(setKeyValue, folder_key_name, data);
            // ====== it is needed ======
            const { contentDetails, nextDate, recentFiles } = data || {};
            console.log({ contentData: data, status });
            // yield call(managingKeyValueData, {
            //     isForInserting: false, // from this place for api call, all times key-value pair should be removed
            //     key: folder_key_name,
            //     value: {
            //         contentDetails: contentDetails,
            //         nextDate: nextDate?.length ? nextDate[0]?.createdDate : null,
            //         recentFiles: recentFiles
            //     }
            // });

            // ====== it is needed ======
            // if (contentDetails?.length) {
            //     // yield call(managingKeyValueData, {isForInserting: true, key: folder_key_name, value: data});
            // } else {
            //     // yield call(managingKeyValueData, {isForInserting: false, key: folder_key_name, value: data});
            // }
            // console.log({result});
            // yield put(setFolderContents({
            //     // contents: newContents,
            //     contents: data?.contentDetails || [],
            //     contentNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
            // }));
            // yield put(setFolderPathContentsPayload(null));
            yield put(setRecentFileContents(recentFiles?.length ? recentFiles : []));
            delay(500);
            yield put(setFolderPathContents({
                contentDetails: contentDetails || [],
                nextDate: nextDate?.length ? (nextDate[0]?.createdDate || null) : null,
                // recentFiles: recentFiles || [],
                not_found_data: contentDetails?.length ? false : "Not found data",
                is_data_come: true,
            }, isForScrolling));
        }
        yield put(setFolderPathContentsPayload(null));
        // if (status === 200) {

        //     // yield put(setFolderContents({
        //     //     contents: data?.contentDetails,
        //     //     contentNextDate: data?.nextDate?.length ? data?.nextDate[0]?.nextDate : null
        //     // }));
        // }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}


function* mediaManagementSaga() {
    
    yield all([
        // folder
        takeEvery(Constants.GET_FOLDER_PATH_CONTENTS, getFolderPathContentData),
        takeEvery(Constants.SET_UP_FOLDER_PATHS_FOR_INDEX_DB, requestForManagingContentKeyValueForIndexDB),
        takeEvery(Constants.GET_BIO_PAGE_FOLDERS, getBioPageFolderDataForGallery),
        takeEvery(Constants.CREATE_FOLDER, createFolderData),
        takeEvery(Constants.GET_FIRST_TIME_FOLDERS, getFirstTimeFolderData),
        takeEvery(Constants.GET_FOLDERS, getFolderData),
        takeEvery(Constants.DELETE_FOLDERS, deleteFolderData),
        // content
        takeEvery(Constants.CREATE_FOLDER_CONTENT, createContentData),
        takeEvery(Constants.UPDATE_FOLDER_CONTENT_NEW_VERSION, updateFolderContentDataNewVersion),
        takeEvery(Constants.AWS_PRE_ASSIGN_LINK, callAwsPreAssignedLinkWithContentFile),
        takeEvery(Constants.GET_ONE_TIME_FOLDER_CONTENTS, getOneTimeFolderContentData),
        takeEvery(Constants.GET_FOLDER_CONTENTS, getFolderContentData),
        // content table --- inside a particular folder
        takeEvery(Constants.UPDATE_FOLDER_CONTENT, updateFolderContentData),
        takeEvery(Constants.DELETE_FOLDER_CONTENTS, deleteContentData),
        // For view files --- carousel page
        takeEvery(Constants.SAVE_CONTENT_DETAILS, saveContentDetails),
        takeEvery(Constants.GET_CONTENT_VERSIONS, getContentVersionData),
        takeEvery(Constants.REQUEST_TO_REVERT_VERSION, revertVersionData),
    ]);
}

export default mediaManagementSaga;