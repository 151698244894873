import React, {useEffect, useState} from 'react';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../headerReducer';
// import { getUserPermissions, resetForChangeWorkspace } from '../../../../signup-login/loginReducer';
// import clapup_logo from "../../../../assets/icons/ClapUp-Icon.svg";
// import { getWorkspaceSettings } from '../../../../pages/workspace-management/workSpaceReducer';
// import { convertToClapUpUser } from '../../../../pages/user-management/userManagementReducer';
import PermissionCheck from '../../../PermissionCheck';
import Constants from '../../../../constants';
import { changePage } from '../../../../unified/reducer';
import {
  SettingOutlined, UserOutlined, LogoutOutlined,
  // CopyOutlined
} from '@ant-design/icons';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import {
    deleteDatabase,
    // clearSenderKeyValueStoredData, clearStoredAttachmentFileData, clearKeyValueStoredData,
} from '../../../../utils/indexedDBManagementService';


const HeaderDropdown: React.FC<any> = () => {
//   const { workspaceSettings } = useSelector(
//     (state: any) => state?.workspaceManagementState
//   );
    const {
        // logged_in_user_secret_data,
        accessed_user_details } = useSelector((state: any) => state?.login);
    const {isLoggedOut} = useSelector((state: any) => state?.headerState);
  // const { isReplyingOn } = useSelector((state: any) => state?.unified);
  const [path, setPath] = useState('');
  const [open, setOpen] = useState(false);
  const query_string = window.location.search;
  const path_name = window.location.pathname;
  const user_info = accessed_user_details;
  useEffect(() => {
      // const query = query_string.replace('?', '');
      // console.log({ path_name, query, user_info });
      setPath(path_name);
      
  }, [query_string, path_name]);
  
  const history = useNavigate();
  const dispatch = useDispatch()
  
  useEffect(() => { 
    (async () => {
        if (isLoggedOut) {
            
            // await deleteKeyValue("folder_paths");
            // await deleteKeyValue("all_path_contents");
            // await deleteKeyValue("all_child_folders");
            // await deleteKeyValue("logged_in_user_secret_data");
            dispatch(setLogout(false));
            history("/login");
        }
    })()
  }, [isLoggedOut]);
  
  const handleLogout = async() => {
      console.log("Logout");
      await deleteDatabase();
      dispatch(setLogout(true));
      dispatch({ type: Constants.LOGOUT });
      //   setTimeout(() => dispatch(setLogout(true)), 1000);
    //   dispatch(triggerLogout())
    //   return history("/login");
  }

  // let user_name = "Mehedi"?.split(" ")?.slice(0, 2)?.length === 2
  const user_name = user_info?.name?.split(" ")?.slice(0, 2)?.length === 2
      ? user_info?.name?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("")
      : user_info?.name?.substr(0, 2).toUpperCase();
  return (
    <React.Fragment>
      <Space direction="vertical">
          <Space wrap>
              <Dropdown 
                  className=""
                  trigger={['click']} 
                  overlay={<div className="mt-3 py-2 px-1 bg-white rounded shadow">
                      {/* <PermissionCheck permissionName="profile">
                          <div
                              className="border-bottom p-1"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                  if(path !== "/user/personal-info") {
                                      setOpen(!open);
                                      dispatch(changePage("/user/personal-info"));
                                      // (isReplyingOn ? dispatch(changePage("/user/personal-info")) : history("/user/personal-info", { replace: true }));
                                  }
                              }}
                          >
                              <UserOutlined className="mx-1" /> Profile
                          </div>
                      </PermissionCheck>
                      
                      <PermissionCheck permissionName="accountSettings">
                          <div 
                              style={{ cursor: 'pointer' }}
                              className="border-bottom p-1"
                              onClick={() => {
                                  if(path !== "/account/general-settings"){
                                      setOpen(!open);
                                      dispatch(changePage("/account/general-settings"))
                                      // (isReplyingOn ? dispatch(changePage("/account/general-settings")) : history("/account/general-settings", { replace: true }));
                                      // history("/account/general-settings");
                                  }
                              }}
                              >
                              <SettingOutlined className="mx-1"/> Account Settings
                          </div>
                      </PermissionCheck> */}

                      <div 
                          className="p-1"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                              setOpen(!open);
                              handleLogout();
                          }}
                      >
                          <LogoutOutlined className="mx-1" /> Logout
                      </div>
                  </div>} 
                  placement="bottomLeft"
                  open={open}
                  onOpenChange={() => {
                      setOpen(!open);
                  }}
              >
                <img
                    className="main-user-profile-avatar"
                    src={getGenerateApiImageByName(user_name)}
                    alt="avatar"
                />
              </Dropdown>
          </Space>
      </Space>
    </React.Fragment>
  );
};

export default HeaderDropdown;