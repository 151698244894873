import React from 'react';
// import './dashboard.scss';
import Media from '../components/MediaContents/Media';
// import TopTabs from '../components/layout/TopTabs';
import MobileBottomTabs from '../components/Layout/MobileBottomTabs';
import { useSelector } from 'react-redux';
import UnPermission from '../pages/un-permission/UnPermission';

const Gallery = () => {
    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Gallery") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            <MobileBottomTabs/>
            {
                isPermissible
                ? <Media />
                : <UnPermission/>
            }
            
        </React.Fragment>
    )
}

export default Gallery;