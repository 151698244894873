import React from 'react';
import "./ModalForm.scss";
// import { getImage } from '../../../../../../unified/image_service';
// import { inbox_api_url } from '../../../../../../utils/server';
// import { setErrorMessage, setSuccessMessage } from '../../../../../../unified/reducer';
// import { generateAccessToken } from '../../../../../../profile/reducer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_version, inbox_api_url } from '../../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
import { generateAccessToken } from '../../../profile/reducer';
import { getImage } from '../../../unified/image_service';

const CloseModalForm = ({ showCloseForm, data_fields, parentCallback, handleCancel }: any) => {
    const dispatch = useDispatch();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    const handleContinue = async () => {
        try {
            const conversation = data_fields;
            console.log({ conversation });
            const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!clapupUserId || !workspaceId) {
                return;
            }
            const data_object = {
                clapupUserId, workspaceId,
                ticketNo: conversation?.ticketNo,
                teamId: conversation?.teamId,
                channelId: conversation?.channelId,
                messageId: conversation?.messageId,
            }
            const url = inbox_api_url + `/api/${api_version}/teams/CloseEmailCommunication`;
            const { status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                dispatch(setSuccessMessage("Closed Successfully"));
                handleCancel();
            }

        } catch (error) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };
    
    // const handleContinue = async () => {
    //     console.log({ data_fields });
    //     // parentCallback();
    // }
    
    return (
        <React.Fragment>
            <div className={` ${showCloseForm ? "close-modal-modal-form-wrap" : "d-none"}`}>
                <div className={`custom-continue-modal`}>
                    <div className="modal-container">
                    <div className='modal-body'>
                        <div className='left-image'>
                        <img src={getImage("error-warning-success")} alt="ews-icon" />
                        </div>
                        <div className='right-body'>
                        <h2 className='title'>Close Message</h2>
                        <div className="description">By clicking continue, the conversation will be marked as closed and no further activities will be possible.</div>
                        <div className='button-container'>
                            <button className='back' onClick={() => handleCancel()}>Back</button>
                            <button className='continue' onClick={() => handleContinue()}>Continue</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CloseModalForm;
