import React, { useEffect } from 'react';
import { DateRangePicker } from "rsuite";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

const Calendar = ({handleSelectField, isOpenCalender}: any) => {
    const [date, setDate] = React.useState(null);
  
    const predefinedRanges: any = [
        {
            label: "Today",
            value: [new Date(), new Date()],
            placement: "left"
        },
        {
            label: "Yesterday",
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: "left"
        },
        {
            label: "Last 7 days",
            value: [subDays(new Date(), 6), new Date()],
            placement: "left"
        },
        {
            label: "Last 30 days",
            value: [subDays(new Date(), 29), new Date()],
            placement: "left"
        },
        {
            label: "Last 90 days",
            value: [subDays(new Date(), 89), new Date()],
            placement: "left"
        },
        {
            label: new Date().getFullYear() - 1,
            value: [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(new Date().getFullYear(), 0, 0)
            ],
            placement: "left"
        },
        {
            label: new Date().getFullYear() - 2,
            value: [
            new Date(new Date().getFullYear() - 2, 0, 1),
            new Date(new Date().getFullYear(), 0, 0)
            ],
            placement: "left"
        },
        {
            label: new Date().getFullYear() - 3,
            value: [
            new Date(new Date().getFullYear() - 3, 0, 1),
            new Date(new Date().getFullYear(), 0, 0)
            ],
            placement: "left"
        }
        // {
        //   label: "All time",
        //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        //   placement: "left"
        // }
    ];

    return (
        <React.Fragment>
            <DateRangePicker
                block
                value={date}
                ranges={predefinedRanges}
                // defaultOpen={true}
                open={isOpenCalender}
                size={"sm"}
                placeholder={"Select a date"}
                onOk={(value: any) => {
                    // console.log(value);
                    // setIsOpenCalender(!isOpenCalender);
                    handleSelectField(value, "date");
                    setDate(value);
                }}
                // renderTitle={(date: any) => {
                //     return (
                //         <div className="text-center">
                //             <h5>Choose a date</h5>
                //         </div>
                //     );
                // }}
                style={{ width: 250, display: "none" }}
                // renderExtraFooter={undefined}
                showMeridian={true}
            />
            {/* <div className='border bg-white shadow mt-3 p-2 rounded filter' style={{width: 100}}>
                <p onClick={() => handleSelectField("All", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>All</p>
                <p onClick={() => handleSelectField("Unread", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Unread</p>
                <p onClick={() => handleSelectField("Not Replied", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Not Replied</p>
                <p onClick={() => handleSelectField("Replied", "date")} style={{margin: 2, cursor: 'pointer'}} className='text-start'>Replied</p>
            </div> */}
        </React.Fragment>
    );

}

export default Calendar;
