import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import "./ModalForm.scss";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { useSelector } from "react-redux";

const color_values = {
    HEX: [
        '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec',
        '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec',
        // '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec', '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec',
    ],
    HSL: [
        'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)',
        // 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)',
    ],
    RGB: [
        "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)",
        // "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)",
    ],
};
const CreateLabelForm = ({ data_fields, handleCancel, handleParentCallback }: any) => {
    // const { setting_teams } = useSelector((state: any) => state?.teamState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);

    const [labelName, setLabelName] = useState<any>("");
    const [color, setColor] = useState<any>("");
    
    // const [teams, setTeams] = useState<any>(setting_teams);
    // const [selectedTeam, setSelectedTeam] = useState<any>(null);
    // // const [selectedTeams, setSelectedTeams] = useState<any>([]);
    // const dispatch = useDispatch();
    // const handleSelectTeams = (id: any) => {
    //     const already_selected = selectedTeams?.find((member_id: any) => member_id === id);
    //     // console.log({ already_selected, selectedTeams, id });
    //     if (already_selected) {
    //         const filteredSelectedTeams = selectedTeams?.filter((member_id: any) => member_id !== id);
    //         setSelectedTeams(filteredSelectedTeams);
    //     } else {
    //         const selected_teams = [...selectedTeams, id];
    //         setSelectedTeams(selected_teams);
    //     }
    // };

    // const handleSearchForTableColumn = (value: any) => {
    //     // console.log("value ==== ", value);
    //     const isEmailCheck = value?.includes('@');
    //     // const isNameCheck = value.includes(' ');
    //     // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
    //     // console.log({userManagementState})
    //     const query_data = setting_teams;
    //     if(isEmailCheck){
    //         const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
    //         setTeams(data);
            
    //     } else{
    //         const data = query_data?.filter((item: any) => {
    //             return item?.teamName?.toLowerCase().includes(value?.toLowerCase());
    //         })
    //         // console.log("data ==== ", data);
        
    //         if(!data?.length){
    //             const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
    //             setTeams(data);
    //         }else{
    //             setTeams(data);
    //         }
    //     }
    // }

    // const handleMoveNow = async () => {
    //     try {
    //         const data = data_fields;
    //         console.log({ data, selectedTeam });
    //         const clapupUserId = logged_in_user_secret_data?.cuid;
    //         const workspaceId = logged_in_user_secret_data?.wuid;
    //         if (!clapupUserId || !workspaceId) {
    //             return;
    //         }
    //         // const data_object = {
    //         //     clapupUserId, workspaceId,
    //         //     ticketNo: data?.ticketNo,
    //         //     channelId: data?.channelId,
    //         //     teamIdFrom: data?.teamId,
    //         //     teamIdTo: selectedTeam,
    //         // }
    //         // const url = inbox_api_url + `/api/${api_version}/teams/MoveEmailToTeam`;
    //         // const { status } = await axios.post(url, data_object, {
    //         //     headers: {
    //         //         'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
    //         //         'Content-Type': 'application/json'
    //         //     }
    //         // });
    //         // if (status === 200) {
    //         //     dispatch(setSuccessMessage("Moved Successfully"));
    //         //     setTimeout(() => setSelectedTeam(null), 2000);
    //         //     handleCancel();
    //         // }

    //     } catch (error) {
    //         const { response }: any = error;
    //         console.log('error', response);
    //         const { status } = response || {};
    //         if (status === 403 || status === 401) {
    //             dispatch(generateAccessToken());
    //         } else {
    //             dispatch(setErrorMessage("Something went wrong"));
    //         }
    //     }
    // };

    // const handleMoveNow = () => {
    //     console.log({ data_fields, data, selectedTeams });
    //     // handleParentCallback();
        
    // }



    return (
        <React.Fragment>
            <div className="modal-form">
                <h3 className="modal-title">Create Label</h3>
                <div className="modal-search">
                    <DebounceInput
                        type="text" 
                        placeholder={"Add your Label name here"}
                        minLength={0}
                        debounceTimeout={1000}
                        value={labelName || ""}
                        onChange={(event: any) => {
                            const { value } = event.target;
                            console.log({ value });
                            setLabelName(event.target.value)
                        }} 
                    />
                </div>

                <div className="color-picker-form-item">
                    <div className="label">Select Color</div>
                    <div className="color-picker-input-container">
                        <ColorPicker
                            palette_label={"Presets"}
                            color_values={color_values}
                            color_picker_container_class_name={"color-picker-container"}
                            value={color}
                            onChange={(value: any) => {
                                // console.log({ color_value: value });
                                setColor(value);
                            }}
                        />
                    </div>
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        // onClick={() => handleMoveNow()}
                    >
                        Create Now
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default CreateLabelForm;