import React from 'react';
import {
    // filter_by_labels, filter_by_teams,
    reviews_n_comments, messages_n_emails
} from './feed.left.config';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, setInboxMessageFilteringKeyByTeam, resetInboxMessages, resetSearchAnyData } from '../../../unified/reducer';
// import { getImage } from '../../../unified/image_service';
import "./FeedLeftBar.scss";
import { useParams } from 'react-router-dom';
import { modifyCurrentString } from '../../../utils/mediaService';


const FeedLeftBar = () => {
    const route_path = window.location.pathname;
    const { setting_teams: teams } = useSelector((state: any) => state?.teamState);
    const { team_filtering_key } = useSelector((state: any) => state?.unified);
    const { menuTags: filter_by_labels } = useSelector((state: any) => state?.workspaceManagementState);
    const { params } = useParams<{ params: string }>();

    const dispatch = useDispatch();
    const handlePageChange = (url: any) => {
        if (team_filtering_key) { // if we have filter_key and inbox messages -> we have to reset
            dispatch(setInboxMessageFilteringKeyByTeam(null));
            dispatch(resetInboxMessages());
        }
        dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
        dispatch(resetSearchAnyData());
        dispatch(changePage(url));
    };
    
    const filterInboxMessagesByTeam = (team_id: any) => {
        dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
        dispatch(resetSearchAnyData());
        dispatch(setInboxMessageFilteringKeyByTeam(team_id));
        // dispatch(changePage(route_path));
        dispatch(changePage(`/all-messages/team-${team_id}`));
    }

    // const filterContentChangeByTeamName = (team_name: any) => {
    //     dispatch(changePage(`/contents/${team_name}`));
    // }
    
    const filterInboxMessagesByLabelId = (tag_id: any) => {
        if (!params) {
            dispatch(resetInboxMessages());
        }
        dispatch(changePage(`/all-messages/label-${tag_id}`));
    }

    const getTeamIcon = (color = "#1354EC") => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="16" height="16" viewBox="0 0 16 16"><g><ellipse cx="8" cy="8" rx="8" ry="8" fill={color} fillOpacity="1"/></g></svg>
        )
    }
    
    const getLabelIcon = (color = "#1354EC") =>{
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="15.99998664855957" height="16" viewBox="0 0 15.99998664855957 16"><g><path d="M0,1.75198C0,1.75198,0,7.22003,0,7.22003C0,7.84182,0.245056,8.438,0.683963,8.87691C0.683963,8.87691,7.12126,15.3142,7.12126,15.3142C8.03565,16.2286,9.51697,16.2286,10.4314,15.3142C10.4314,15.3142,15.3142,10.4314,15.3142,10.4314C16.2286,9.51698,16.2286,8.03567,15.3142,7.12128C15.3142,7.12128,8.87689,0.683976,8.87689,0.683976C8.43799,0.245069,7.8418,0.0000127074,7.22002,0.0000127074C7.22002,0.0000127074,1.75563,0.0000127074,1.75563,0.0000127074C0.786375,-0.00364485,0,0.78273,0,1.75198ZM4.09646,2.9224C4.40688,2.9224,4.70458,3.04571,4.92408,3.26521C5.14357,3.48471,5.26688,3.78241,5.26688,4.09282C5.26688,4.40323,5.14357,4.70093,4.92408,4.92043C4.70458,5.13993,4.40688,5.26324,4.09646,5.26324C3.78605,5.26324,3.48835,5.13993,3.26885,4.92043C3.04936,4.70093,2.92605,4.40323,2.92605,4.09282C2.92605,3.78241,3.04936,3.48471,3.26885,3.26521C3.48835,3.04571,3.78605,2.9224,4.09646,2.9224Z" fill={color} fillOpacity="1" /></g></svg>
        )
    }
    
    const filter_key = params?.includes("team-") ? params?.replaceAll("team-", "") : params?.includes("label-") ? params?.replaceAll("label-", "") : "";
    // console.log("Teams settings => ", { params, filter_key });
    return (
        <React.Fragment>
            <h1 className='inbox-title'>Inbox</h1>
            <div className='tab-container-inbox-message-email'>
                <div className='tab-title'>
                    <div>{messages_n_emails?.title}</div>
                    <img src={messages_n_emails?.title_icon} alt='title-icon'/>
                </div>
                <div className='tab-body'>
                {
                    messages_n_emails?.tabs?.map((item: any) => (
                        <div key={item?.id}
                            className={`body-container${route_path === item?.route || route_path?.includes(item?.sub_route) ? "-active" : ""}`}
                            // className={`body-container${route_path?.includes(item?.route) || route_path?.includes(item?.sub_route) ? "-active" : ""}`}
                            onClick={() => {
                                if (item?.route) {
                                    handlePageChange(item?.route);
                                }
                            }}
                        >
                            <img src={item?.icon} alt="tab-icon" />
                            <div className="title">{item?.title}</div>
                        </div>
                    ))
                }
                </div>
                
                {/* Reviews & Comments */}
                {/*
                <div className='tab-container'>
                    <h2 className='tab-title'>{reviews_n_comments?.title}</h2>
                    <div className='tab-body'>
                    {
                        reviews_n_comments?.tabs?.map((item: any) => (
                            <div key={item?.id} className={`body-container${
                                route_path?.includes(item?.route) || route_path?.includes(item?.sub_route) ? "-active" : ""}`}
                                onClick={() => {
                                    if (item?.route) {
                                        handlePageChange(item?.route);
                                    }
                                }}
                            >
                                <img src={item?.icon} alt="tab-icon" />
                                <div className="title">{item?.title}</div>
                            </div>
                        ))
                    }
                    </div>
                </div> */}
            
                {/* Teams */}
                <div className='tab-container'>
                    <h2 className='tab-title'>Teams</h2>
                    <div className='tab-body'>
                    {
                        teams?.map((item: any) => (
                            <div key={item?.teamId}
                                // className={`body-container${item?.teamId === team_filtering_key ? "-active" : ""}`}
                                className={`body-container${item?.teamId === filter_key ? "-active" : ""}`}
                                onClick={() => filterInboxMessagesByTeam(item?.teamId)}
                                title={item?.teamName}
                            >
                                {/* <img className='bg-image' src={item?.icon} alt="tab-icon" /> */}
                                {getTeamIcon(item?.teamColor)}
                                <div className="title">{modifyCurrentString(item?.teamName, item?.teamName?.length > 18 ? 15 : 18, item?.teamName?.length > 18 ? "..." : " ")}</div>
                            </div>
                        ))
                    }
                    </div>
                </div>
                
                {/* Labels */}
                <div className='tab-container'>
                    <h2 className='tab-title'>Labels</h2>
                    <div className='tab-body'>
                    {
                        filter_by_labels?.map((item: any) => (
                            <div key={item?.tagId}
                                className={`body-container${String(item?.tagId) === filter_key ? "-active" : ""}`}
                                onClick={() => filterInboxMessagesByLabelId(item?.tagId)}
                            >
                                {/* <img src={item?.icon} alt="tab-icon" /> */}
                                {getLabelIcon(item?.tagColor)}
                                <div className="title">{modifyCurrentString(item?.tagName, item?.tagName?.length > 18 ? 15 : 18, item?.tagName?.length > 18 ? "..." : " ")}</div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    )
}

export default FeedLeftBar;