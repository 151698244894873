import React from 'react';
import emptyBox from '../../../../assets/img/Empty.svg';
import { useEffect, useState } from 'react';
// import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { RootState } from '../../../../store';
import { toggleReplyingOn, toggleIsInReplyingMode } from '../../../../unified/reducer';
// import { api_url } from '../../../../utils/server';
import Dialog from '../../../Dialogs/Dialog';
// import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
// import BotMemberConversation from '../Conversations/BotMemberConversation';
import { getImage } from '../../../../unified/image_service';
import connection from '../../../../utils/signalRconnectionConversation';
import AllMessagesMemberConversation from '../Conversations/AllMessagesMemberConversation';
// import { replyToWhatsAppMessage } from '../../../../unified/saga';
import '../Conversations.scss';
interface Props {
  conversationId: string;
// handleReplaceConversation: any;
  conversation?: any;
  statDetails?: any;
  activePage?: string;
  activeTab?: string; 
  routePath?: string;
  type?: string;
}

const AllMessagesConversations: React.FC<Props> = ({ conversation, type, statDetails, conversationId,  activePage, activeTab, routePath }) => {
  // const {currentWorkspace: curWorkspace} = useSelector((state: any) => state?.headerState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const query_string = window.location.search;
  const { unifiedData: data, isReplyingOn, isInReplyingMode } = useSelector((state: any) => state?.unified);
  // const {integrationConnections}: any = useSelector((state: RootState) => state?.login);  
  
  // const timelineRef:any = React.useRef();
  const textRef:any = React.useRef();
  // const [conversation, setConversation] = useState<any>(null);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<any>(null);
  const [isRemovingReplyText, setIsRemovingReplyText] = useState<boolean>(false);
  const [sender, setSender] = useState<any>(null);
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  
  // // ===== SignalR start ====
  // // Join a workspace (replace with actual workspace ID)
  // const joinWorkspace = async (workspaceId: any) => {
  //     try {
  //         await connection.invoke("JoinWorkspace", workspaceId);
  //         console.log(`SignalR_Conversation: Joined workspace: ${workspaceId}`);
  //     } catch (error) {
  //         console.error("SignalR_Conversation: Error joining workspace:", error);
  //     }
  // };

  // // Invoking route
  // useEffect(() => {
  //   console.log('SignalR_Conversation => ', {state: connection?.state, conversation});

  //   if (connection?.state === 'Connected' && conversation) {
  //     // console.log("SignalR_Conversation: => ", {state: connection?.state, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });


  //     if (logged_in_user_secret_data?.wuid) {
  //         joinWorkspace(logged_in_user_secret_data?.wuid);
  //     }

  //     const handleLockWhenTyping = (details: any) => {
  //       const sender_details = JSON.parse(details);
  //       const { isFromPrevious, name: sender_name, reviewId, provider, email, replyText } = sender_details || {};
  //       // console.log("SignalR_Conversation: received data:", {isFromPrevious, email_sender_details: sender_details, isInReplyingMode});
        
  //       if (isFromPrevious) {
  //         setSender(null);
  //         dispatch(toggleIsInReplyingMode(false));
  //       } else if (email !== user_info?.email && reviewId === conversation?.reviewId && provider === conversation?.provider) {
  //         console.log(`SignalR_Conversation User with ID ${sender_name} is typing`);
  //         if(replyText){
  //           setSender(sender_name);
  //           dispatch(toggleIsInReplyingMode(true));
  //         } else {
  //           setSender(null);
  //           dispatch(toggleIsInReplyingMode(false));
  //         }

  //         setTimeout(() => {
  //           setSender(null);
  //           dispatch(toggleIsInReplyingMode(false));
  //         }, 10000);
  //       }
  //     }

  //     // receives routes
  //     connection.on('TicketUnlocked', handleLockWhenTyping);

  //     // Clean up SignalR connection on component unmount
  //     return () => {
  //       connection.off('TicketUnlocked', handleLockWhenTyping);
  //       // leave workspace Id
  //       if (logged_in_user_secret_data?.wuid) {
  //         connection.invoke("LeaveWorkspace", (logged_in_user_secret_data?.wuid).catch(err => console.error('Failed to leave workspace: ', err));
  //       }
  //     }

  //   }
  // }, [connection?.state, conversation]);

  // useEffect(() => {
  //   if (conversation && connection?.state === "Connected" && replyText !== null) {
  //     const sender_info = {
  //       isFromPrevious: true,
  //       replyText, email: user_info?.email,
  //       name: user_info?.name, reviewId:
  //       conversation?.reviewId, 
  //       provider: conversation?.provider
  //     };
  //     console.log("SignalR_Conversation invoked by data: => ", {state: connection?.state, replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });
  //     if (replyText?.trim()) { 
  //       sessionStorage.setItem("prev_sender_info", JSON.stringify({ replyText: '', email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider }));
  //       sender_info.isFromPrevious = false;
  //     }

  //     // invoking routes
  //     const ticketId = conversation?.ticketNo;
  //     const providerExample = conversation?.provider;
  //     const workspaceId = logged_in_user_secret_data?.wuid;
  //     // "TicketLocked", ticketId,workspaceId,userName, provider)

  //     connection.invoke('TicketLocked', ticketId, workspaceId, JSON.stringify(sender_info), providerExample)
  //     .catch((error: any) => {
  //       console.error('SignalR_Conversation invoked: Failed to send typing status:', error);
  //     }); 
  //   }
  // }, [replyText]);

  // // ===== SignalR End ====
  
  useEffect(() => {
    dispatch(toggleIsInReplyingMode(false));
    if(isRemovingReplyText){
      const prev_sender_info = JSON.parse(sessionStorage.getItem("prev_sender_info") || "{}");
      console.log({sign_prev: prev_sender_info});
      if (prev_sender_info?.reviewId && connection) {
        sessionStorage.removeItem("prev_sender_info");
        setReplyText("");   
      }
    }
  }, [isRemovingReplyText]);

  const handleReply = async(replyData?: any) => {
    const {
      rating, ticketNo,
      templateId, type,
      inputValue: replyMessage,
      fromPhoneNumber, toPhoneNumber,
    }: any = replyData;
    if (replyMessage?.trim()) {
      const replyObject: any = {
        fromPhoneNumber: fromPhoneNumber || "",
        toPhoneNumber: toPhoneNumber || "",
        type: "text",
        replyMessage: replyMessage || "",
        workspaceId: logged_in_user_secret_data?.wuid,
        clapupUserId: logged_in_user_secret_data?.cuid,
        templateId: "",
        rating: rating,
        ticketNo: ticketNo || "",
      };
      console.log({ replyObject });
      // const result = await replyToWhatsAppMessage(replyObject);
      // console.log({result});
      // if (result) {
      //   setReplyText("");
      //   setIsReply(false);
      //   dispatch(toggleReplyingOn(false));
      // }
    } 
  }

  const [isGoForDetails, setIsGoForDetails] = useState<boolean>(false);
  const handleDetailChange = () => {
    sessionStorage.setItem('details', (!isGoForDetails) + '');
    
    setIsGoForDetails(!isGoForDetails);
  };

  useEffect(() => {
    const details = sessionStorage.getItem('details');
    // console.log({details});
    if(details === 'true'){
      setIsGoForDetails(true);
    } else {
      setIsGoForDetails(false);
    }
    
  }, [query_string]);
  const [movingPage, setMovingPage] = useState<any>(null);

  const handlePrevPath = () => {
    sessionStorage.setItem("details", !isGoForDetails + "");
    if (isReplyingOn) {
      setMovingPage(`${routePath}?${activeTab}=${activePage}`);
    } else { 
      navigate(`${routePath}?${activeTab}=${activePage}`);
    }
  }

  const handlePageChange = (flag: string) => {
    if (!flag) {
      setMovingPage(null);
    } else {
      navigate(movingPage);
      setMovingPage(null);
    }
  }



  // const {provider} = conversation || {};
  // console.log({person_1, person_2, properties, details});
  // console.log("con-length => 2", {conversation, statDetails});
  
  return (
    <React.Fragment>
      {
        movingPage ?
          <Dialog
            open={movingPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      
      {/* {conversation?.userName && statDetails?.userName ? ( */}
      {/* {conversation?.messageId ? ( */}
      {query_string?.includes("cov") ? (
        <AllMessagesMemberConversation
          type={type}
          sender={sender}
          replyText={replyText}
          isReply={isReply}
          isGoForDetails={isGoForDetails}
          conversation={conversation || {}}
          statDetails={statDetails || {}}
          handleDetailChange={handleDetailChange}
          handlePrevPath={handlePrevPath}
          // handleReplaceConversation={handleReplaceConversation}
          getImage={getImage}
          conversationId={conversationId}  
          workspaceSettings={workspaceSettings}  
          setIsReply={setIsReply} setReplyText={setReplyText}
          textRef={textRef} handleReply={handleReply}
        />
      ) :
      (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <img src={emptyBox} alt="" />
          <h4>Your Wall is Empty.</h4>
          <span>Select an item from inbox to display!</span>
        </div>
      )}
    </React.Fragment>
  );
}

export default AllMessagesConversations;
