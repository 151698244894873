import React, { useState } from "react";
import "./ModalForm.scss";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_version, inbox_api_url } from '../../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
import { generateAccessToken } from '../../../profile/reducer';
import { getGenerateApiImageByName, getImage } from '../../../unified/image_service';
// import { getImage, getGenerateApiImageByName } from "../../../../../../unified/image_service";
// import { inbox_api_url } from '../../../../../../utils/server';
// import { setErrorMessage, setSuccessMessage } from '../../../../../../unified/reducer';
// import { generateAccessToken } from '../../../../../../profile/reducer';

const getImageIconByColorTag = (color = "#000000") => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1" width="18" height="18" viewBox="0 0 18 18"
        >
            <g>
                <g>
                    <ellipse
                        cx="9" cy="9" rx="9" ry="9"
                        fill={color}
                        fillOpacity="1"
                    />
                </g>
            </g>
        </svg>
    )
};

// const dummy_people = [
//     {
//         id: 3,
//         userProfile: "",
//         userName: "Kuber Mannur",
//         userEmail: "ku@gmail.com",
//     },
//     {
//         id: 1,
//         userProfile: "",
//         userName: "Sreekanth PM",
//         userEmail: "sr@gmail.com",
//     },
//     {
//         id: 2,
//         userProfile: "",
//         userName: "Mehedi Hasan",
//         userEmail: "mm@gmail.com",
//     },
//     {
//         id: 4,
//         userProfile: "",
//         userName: "MMHK",
//         userEmail: "mmhk@gmail.com",
//     },
// ];

// const dummy_teams = [
//     {
//         id: 1,
//         teamColor: "#1354EC",
//         teamName: "Primary",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 2,
//         teamColor: "#F79009",
//         teamName: "Marketing",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 3,
//         teamColor: "#F04438",
//         teamName: "Human Resource",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 4,
//         teamColor: "#039855",
//         teamName: "Developer Team",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 5,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 6,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
// ];

const ShareModalForm = ({ data_fields, conversation, details_assignee_users, setting_teams, handleCancel, handleParentCallback }: any) => {
    const [shareTab, setShareTab] = useState<any>("People");
    const [accessType, setAccessType] = useState<any>("full");
    const [teams, setTeams] = useState<any>(setting_teams);
    const [users, setUsers] = useState<any>(details_assignee_users);
    const [selectedTeams, setSelectedTeams] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const dispatch = useDispatch();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const handleSelectUsers = (id: any) => {
        const already_selected = selectedUsers?.find((item_id: any) => item_id === id);
        if (already_selected) {
            const filteredSelectedUsers = selectedUsers?.filter((item_id: any) => item_id !== id);
            setSelectedUsers(filteredSelectedUsers);
            // console.log({ already_selected, selectedUsers, id });
        } else {
            const selected_users = [...selectedUsers, id];
            // console.log({ selected_users, selectedUsers, id });
            setSelectedUsers(selected_users);
        }
    };
    
    const handleSelectTeams = (id: any) => {
        const already_selected = selectedTeams?.find((item_id: any) => item_id === id);
        // console.log({ already_selected, selectedTeams, id });
        if (already_selected) {
            const filteredSelectedTeams = selectedTeams?.filter((item_id: any) => item_id !== id);
            setSelectedTeams(filteredSelectedTeams);
        } else {
            const selected_teams = [...selectedTeams, id];
            setSelectedTeams(selected_teams);
        }
    };

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        const query_data: any[] = shareTab === "People" ? details_assignee_users : setting_teams;
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setTeams(data);
            
        } else {
            const data = query_data?.filter((item: any) => {
                if (shareTab === "People") {
                    return item?.userName?.toLowerCase().includes(value?.toLowerCase());
                } else {
                    return item?.teamName?.toLowerCase().includes(value?.toLowerCase());
                }
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                shareTab === "People" ? setUsers(data) : setTeams(data);
            } else{
                shareTab === "People" ? setUsers(data) : setTeams(data);
            }

            if (!value) {
                setUsers(details_assignee_users);
                setTeams(setting_teams);
            }
        }
    }

    // const handleShareNow = () => {
    //     console.log({ data_fields, conversation, selectedTeams, selectedUsers });
    //     // handleParentCallback();
        
    // }

    const handleShareNow = async () => {
        try {
            console.log({ conversation });
            const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!clapupUserId || !workspaceId) {
                return;
            }
            const data_object = {
                clapupUserId, workspaceId,
                userList:  selectedUsers?.join(","),
                teamList: selectedTeams?.join(","),
                teamId: conversation?.teamId,
                messageId: conversation?.messageId,
                accessType: accessType,
            }
            const url = inbox_api_url + `/api/${api_version}/teams/ShareEmailMessages`;
            const { status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                dispatch(setSuccessMessage("Shared Successfully"));
                handleCancel();
                setTimeout(() => setSelectedTeams(null), 2000);
                setTimeout(() => setSelectedUsers(null), 2000);
                setTimeout(() => setShareTab("People"), 2000);
            }

        } catch (error) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };

    return (
        <React.Fragment>
            <div className="people-teams-table-form">
                <h3>Share the message with people or teams</h3>

                <div className="share-radio-actions">
                    <div className="radio-action">
                        <img
                            onClick={() => setAccessType("full")}
                            src={
                                getImage(
                                    accessType === "full"
                                    ? "active-radio-button"
                                    : "inactive-radio-button"
                                )
                            }
                            alt="tab-icon"
                        />
                        <div onClick={() => setAccessType("full")}>Full Access</div>
                    </div>
                    <div className="radio-action">
                        <img
                            onClick={() => setAccessType("partial")}
                            src={
                                getImage(
                                    accessType === "partial"
                                    ? "active-radio-button"
                                    : "inactive-radio-button"
                                )
                            }
                            alt="tab-icon"
                        />
                        <div onClick={() => setAccessType("partial")}>Partial Access</div>
                    </div>
                </div>
                <div className="form-item">
                    <DebounceInput
                        type="text" 
                        placeholder={"Search users or teams to share your item"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => handleSearchForTableColumn(event.target.value)} 
                    />
                </div>

                <div className="share-tabs">
                    <div 
                        className={`share-tab-container${shareTab === "People" ? "-active" : ""}`}
                        onClick={() => {
                            setShareTab("People")
                        }} 
                    >
                        People
                    </div>
                    <div 
                        className={`share-tab-container${shareTab === "Teams" ? "-active" : ""}`}
                        onClick={() => {
                            setShareTab("Teams")
                        }} 
                    >
                        Team
                    </div>
                </div>
                <div className="people-teams-table">
                    {
                        (teams?.length && shareTab === "Teams") || (users?.length && shareTab === "People")
                        ?   shareTab === "Teams" ?
                            <div className='table-responsive'>
                                <table className="table m-0">
                                    <thead className="d-none"><tr><th>Icon-Name</th><th></th></tr></thead>
                                    <tbody>
                                        {teams?.map((team: any, idx: number) => (
                                            <tr key={idx} className={`${team?.teamId === conversation?.teamId ? "d-none" : ""}`}>
                                                <td>
                                                    {getImageIconByColorTag(team?.teamColor)}
                                                    <div>{team?.teamName || "Team Name"}</div>
                                                </td>
                                                {/* <td>{user?.email}</td> */}
                                                <td>
                                                    <img
                                                        src={getImage(
                                                            selectedTeams?.find((team_id: any) => team_id === team?.teamId )
                                                            // ? "checked-icon" 
                                                            // : "un-checked-icon"
                                                            ? "active-radio-button" 
                                                            : "inactive-radio-button"
                                                        )}
                                                        alt="check-uncheck-icon"
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            handleSelectTeams(team?.teamId);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : 
                            <div className='table-responsive'>
                                <table className="table m-0">
                                    <thead className="d-none"><tr><th>User-Name</th><th></th></tr></thead>
                                    <tbody>
                                        {users?.map((user: any, idx: number) => (
                                            <tr key={idx} className={`${user?.userId === conversation?.logged_in_user_id ? "d-none" : ""}`}>
                                                <td>
                                                    <img src={getGenerateApiImageByName(user?.userName)} alt="user" />
                                                    <div>{user?.userName || "User Name"}</div>
                                                </td>
                                                <td>{user?.emailId}</td>
                                                <td>
                                                    <img
                                                        src={getImage(
                                                            selectedUsers?.find((member_id: any) => member_id === user?.userId )
                                                            // idx === 0
                                                            ? "checked-icon" 
                                                            : "un-checked-icon"
                                                        )}
                                                        alt="checked-unchecked-icon"
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            handleSelectUsers(user?.userId);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        :   <div className="no-data">
                                No Data Found
                            </div>
                    }
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleShareNow()}
                    >
                        Share Now
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ShareModalForm;