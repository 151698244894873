import React from 'react';
import { DebounceInput } from 'react-debounce-input';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import search from '../../assets/icons/search-icon.svg';
import Button from '../CustomButton/ButtonContainer';
import { RootState } from '../../store';
import Templates from './PromotionComponents/Template/Templates';
import EditingTemplate from './PromotionComponents/Template/EditingComponents/EditingTemplate';
import { searchTemplateText, toggleModalTemplates, getSearchTemplateList } from './reducer';
import UnPermission from '../../pages/un-permission/UnPermission';
import { setErrorMessage } from '../../unified/reducer';
// import { aws_access_key, aws_secret_key, cloudFlareR2ServiceUrl } from '../../utils/server';
// import { aws_access_key, aws_account_id, aws_secret_key } from '../../utils/server';
// import { Spinner } from 'react-bootstrap';  

const PromotionsTemplate = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const {toggle_template_edition}: any = useSelector((state: RootState) => state?.promotionsState);
  // const route_path = window.location.pathname;
  const query_string = window.location.search;
  const path = query_string.replace('?', '')?.replace('&', "=");
  // const path_array = path.split("=");
  
  
  const handleSearch = (value: any) => {
    console.log({ search: value });
    dispatch(searchTemplateText(value));
    if (value) {
      dispatch(getSearchTemplateList({
          searchText: value,
          workspaceId: logged_in_user_secret_data?.wuid,
      }));
    }
  };

  const {userPermissions} = useSelector((state: any) => state?.login);
  const [isPermissible, setIsPermissible] = React.useState(true);
  React.useEffect(() => {
      const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Template") : null;
      if( permission?.value === 0){
          setIsPermissible(false);
      }
  }, [userPermissions?.length]);

  const { whats_app_connected_users }: any = useSelector((state: RootState) => state?.login);
  const handleAddNewCampaign = () => {
    if (whats_app_connected_users?.length) {
      dispatch(toggleModalTemplates(true));
      return;
    }
    dispatch(setErrorMessage("No mobile no. is registered with this workspace. Please register!"));
  }

  return (
    <React.Fragment>
      {
        isPermissible
          ?
          <>
            {/* After Creating new template I have to go edition component*/}
            {
              toggle_template_edition
              ? <EditingTemplate/>
              :
              <div className="commerce-section">
                <div className={"content-section"}>
                  <div className="content-search-section position-sticky top-0 p-2">
                    <div className="search-wrap">
                      <DebounceInput
                        minLength={0}
                        debounceTimeout={500}
                        onChange={(e: any) => handleSearch(e.target.value)}
                        type="search"
                        placeholder={"Search for Templates"}
                      />
                      <img className="search-icon" src={search} alt="search" />
                    </div>
                    <div className='content-buttons'>
                      <Button
                        classes="mx-2 secondary-btn create-btn"
                        children={<p>New Template +</p>}
                        onClick={() => {
                          // dispatch(toggleCreateFolderModal(true));
                          // dispatch(toggleModalTemplates(true));
                          handleAddNewCampaign();
                        }}
                      />
                    </div>
                  </div>

                  <Templates/>
                </div>
              </div>
            }
          </>
          : <UnPermission/>
      }
    </React.Fragment>
  )
}

export default PromotionsTemplate;
