import React, {useEffect, useState} from 'react';
import { getFileSizeByURL } from '../../../../../utils/mediaService';
import { getImage } from '../../../../../unified/image_service';

const WhatsAppInfo = ({ data }: any) => {
    const [file, setFile] = useState<any>(null);

    const getFileInfo = async(url: string) => { 
        try {
            // Extract the file name from the URL
            const fileName = url.substring(url.lastIndexOf('/') + 1);
    
            // Extract the file extension and convert it to uppercase for fileSize
            const fileType = fileName.split('.')?.pop()?.toUpperCase();
            const fileSize = await getFileSizeByURL(url);
            console.log("file_info: ", { name: fileName, url, type: fileType, size: fileSize });
            setFile({ name: fileName, type: fileType, size: fileSize });
        } catch(err: any) {
            console.log(err);
        }
    };
    useEffect(() => {
        getFileInfo(data?.campaignString?.header)
    }, [data?.campaignString?.header]);
    // console.log({file_info: file})
    return (
        <React.Fragment>
            {
                file
                ? 
                <>
                    <div className='whatsapp-header'>
                        <div className='left-part'>
                            {
                                file?.type
                                ? <img className='file-image' src={getImage(`${file?.type}`)} alt='file-icon' />
                                : null
                            }
                            <div className='whatsapp-header-title'>
                                <p>{file?.name}</p>
                                <div 
                                    className='file-type-size'
                                >
                                    <p style={{margin: 0, padding: 0}}>{file?.type}</p>
                                    {
                                        file?.size
                                        ? <>
                                            <p style={{
                                                background: "#000000",
                                                borderRadius: '50%',
                                                padding: '3px',
                                                margin: 0
                                            }}></p>
                                            <p style={{margin: 0, padding: 0}}>{file?.size || "0.00 KB"}</p>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='right-part'>
                            <img 
                                className='whatsapp-download'
                                src={getImage("whatsapp-circle-download")}
                                alt="download"
                            />
                        </div>
                    </div>
                    <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                        dangerouslySetInnerHTML={{ __html: data?.campaignString?.body }}
                    />
                </>
                : null
            }
        </React.Fragment>
    );
}

export default WhatsAppInfo;