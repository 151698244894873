import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router';
import PromotionsCampaign from './PromotionsCampaign';
import PromotionsTemplate from './PromotionsTemplate';
import './Promotions.scss';
const Promotions = () => {
  const { global_message_type } = useSelector((state: RootState) => state?.unified);
  return (
    <React.Fragment>
      {
        global_message_type === 'campaign'
        ? <PromotionsCampaign/>
        : global_message_type === 'template' 
        ? <PromotionsTemplate />
        : null
      }
      
    </React.Fragment>
  )
}

export default Promotions;
