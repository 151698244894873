import { all, fork } from "redux-saga/effects";
import unifiedSaga from "../unified/saga";
import loginSaga from "../signup-login/saga";
import profileSaga from "../profile/saga";
// mmhk-region
import headerSaga from "../components/Layout/header/saga";
import profileManagementSettingSaga from "../pages/Settings/UserProfile/userProfileManageSaga";
import workspaceManagementSettingSaga from "../pages/Settings/WorkspaceSettings/workspaceSaga";
import bioPageSettingSaga from "../pages/Settings/BioPageSettings/bioPageSaga";
import userManagementSaga from "../pages/Settings/UserManagementSettings/userManagementSaga";
import agentSaga from "../pages/Settings/AgentSettings/agentSaga";
import contentManagementSaga from "../components/ContentComponents/saga";
import promotionsSaga from "../components/Promotions/saga";
import teamsSaga from "../pages/Settings/Teams/teamsSaga";
import integrationSaga from "../pages/Settings/Integrations/saga";

export function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(unifiedSaga),
        fork(profileSaga),
        // mmhk-region
        fork(headerSaga),
        fork(profileManagementSettingSaga),
        fork(userManagementSaga),
        fork(workspaceManagementSettingSaga),
        fork(bioPageSettingSaga),
        fork(teamsSaga),
        fork(agentSaga),
        fork(integrationSaga),
        fork(contentManagementSaga),
        fork(agentSaga),
        fork(promotionsSaga),
    ]);
}