import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { setIntegrationTab } from "../reducer";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import LocationSelectionTable from "./LocationSelectionTable";
import { generateAccessToken } from "../../../../profile/reducer";
import { setErrorMessage, setSuccessMessage } from "../../../../unified/reducer";
import { api_url, api_version } from "../../../../utils/server";
import axios from "axios";
import { deleteKeyValue, getKeyValue, setKeyValue } from "../../../../utils/indexedDBManagementService";

const IntegrationEmail: React.FC = () => {
    const dispatch = useDispatch();
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const [is_ready_to_select_google_location, setReadyToSelectGoogleLocation] = useState<any>(false);
    const { integration_tab } = useSelector((state: any) => state?.integrationState);
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();

    const [searchParams] = useSearchParams();

    const sessionInfoListener = (event: any) => {
        // if (event?.origin !== "https://www.facebook.com") {
        //     return;
        // }
        console.log('message event received:', event);
        try {
            const data = event?.data || {};
            // const data = JSON.parse(event?.data || "{}");
            console.log('event data: ==> ', data);
        } catch (error: any) {
            // Don’t parse info that’s not a JSON
            console.log({error});
            // dispatch(setErrorMessage("Connection Failed"));
            
            console.log('Non JSON Response', event?.data);
        }
    };
    
    useEffect(() => {
        window.addEventListener('message', sessionInfoListener);
        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const check_unipile_integration = await getKeyValue("integration_unipile");
            if (check_unipile_integration) {
                const status: any = searchParams.get('status');
                const accountId: any = searchParams.get('account_id');
                const name: any = searchParams.get('name');
                console.log({ check_unipile_integration, status, accountId, name });
                await deleteKeyValue("integration_unipile");
                // Handle the data as needed, e.g., saving to state or triggering further actions
                
            }
        })()
    }, [searchParams]);

    const handleCancel = () => {
        setReadyToSelectGoogleLocation(false);
    }
    const handleConnectEmailAccount = async () => {
        try {
            // console.log({ tag });
            const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!workspaceId || !clapupUserId) {
                return;
            }
            const payload_object: any = { clapupUserId, workspaceId };
            const url = api_url + `/api/${api_version}/teams/ConnectEmailAccount`;
            const { data, status } = await axios.post(url, payload_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                const { url: email_URL, object } = data || {};
                console.log({ data_url: email_URL });
                await setKeyValue("integration_unipile", "started");
                window.location.href = email_URL;
            }

        } catch (error: any) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };

    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_select_google_location} 
                setModalVisible={(flag: any) => setReadyToSelectGoogleLocation(flag)}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="select-location-setting-table-container"
                footer={null}
            >
                <LocationSelectionTable handleCancel={handleCancel} />
            </CommonModalCustomWidth>
            <div className="integrations-container"
                onClick={() => handleConnectEmailAccount()}
            >
                <div className="header-container">
                    <img src={getImage("Email")} alt="integration-icon" />
                    <div className="title">Email</div>
                </div>
                <div className="description">
                    Connect popular email providers or custom business email.
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationEmail;