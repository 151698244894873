import Constants from "../../../constants";

const initialState = {
    notifications: [],
    personalInfo: {},
    loading: false,
    user_profile_tab: "user-profile",
    is_updated_notification: false,
    error: null,
}

// get workspaces
export const getNotificationSettings = (data?: any) => {
    return {
        type: Constants.GET_NOTIFICATION_SETTINGS,
        payload: { ...data }
    };
}

// notification list

export const setNotificationSettingList = (data = {}) => {

    return {
        type: Constants?.SET_NOTIFICATION_SETTING_LIST,
        payload: data
    };
}

export const updateNotification = (data: any = null) => {
    return {
        type: Constants?.UPDATE_NOTIFICATION_PREFERENCE,
        payload: data
    };
}

export const toggleUpdateNotification = (data: any = null) => {
    return {
        type: "TOGGLE_UPDATE_NOTIFICATION",
        payload: data
    };
}



// profile info

export const getProfileInfo = (data: any = null) => {
    return {
        type: Constants.GET_PROFILE_INFO,
        payload: data
    };
}

export const updateProfileInfoSetting = (data: any = null) => {
    return {
        type: Constants.UPDATE_PROFILE_INFO_SETTING,
        payload: data
    };
}

export const updateProfileImage = (data: any = null) => {
    return {
        type: Constants.UPLOAD_PROFILE_IMAGE,
        payload: data
    };
}

export const setProfileInfo = (data: any = null) => {
    return {
        type: Constants?.SET_PROFILE_INFO,
        payload: data
    };
}

export const setUserProfileTab = (data: any = null) => {
    return {
        type: "SET_USER_PROFILE_TAB",
        payload: data,
    };
}


const profileManageSettingReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "TOGGLE_UPDATE_NOTIFICATION":
            return { ...state, is_updated_notification: action.payload };

        case "SET_USER_PROFILE_TAB":
            return { ...state, user_profile_tab: action.payload };

        case Constants.SET_NOTIFICATION_SETTING_LIST:
            return {
                ...state,
                notifications: action.payload,
                is_updated_notification: true,
            };

        case Constants.SET_PROFILE_INFO:
            return {
                ...state,
                personalInfo: action?.payload
            };

        case Constants.LOGOUT:
            return {
                ...state,
                user_profile_tab: "user-profile",
                is_updated_notification: false,
            };

        default:
            return state;
    }
}

export default profileManageSettingReducer;