import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import libphonenumber from 'google-libphonenumber';
import { RootState } from '../../../../../../../store';
import { getTemplateImage } from '../../../../../../../unified/image_service';
import { getCountries } from '../../../../../../../utils/my_data_service';
import { removeCallToActionTemplate, setCallToActionTemplate, toggleInitializedTemplate } from '../../../../../reducer';
import { setErrorMessage } from '../../../../../../../unified/reducer';
import { checkVariable } from '../../../../../../../utils/convertingFunction';
const CallToActionButtons: React.FC<any> = () => {
    const all_countries: any = getCountries();
    const dispatch = useDispatch();
    const {
        // toggle_template_edition,
        call_to_action_templates, is_initialized_template,
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const [selectedType, setSelectedType] = useState<any>("");
    const [actionButtons, setActionButtons] = useState<any[]>([]);

    const setEditableActionButtons = (updatable_action_buttons: any) => { 
        if (updatable_action_buttons?.length) {
            const actionButtons = updatable_action_buttons.map((btn: any) => {
                let countryCode = btn?.countryCode || "";
                let phoneNumber = btn?.phoneNumber || "";
                const country_code_phoneNumber = btn?.phoneNumber?.split("-");
                if (btn?.phoneNumber && country_code_phoneNumber?.length === 2) {
                    countryCode = country_code_phoneNumber[0];
                    phoneNumber = country_code_phoneNumber[1];
                }
                // console.log({country_code_phoneNumber, mainPhone: btn?.phoneNumber, countryCode, phoneNumber});
                return {
                    id: btn?.id,
                    templateId: btn?.templateId,
                    actionType: btn?.actionType,
                    buttonText: btn?.buttonText,
                    countryCode: countryCode,
                    phoneNumber: phoneNumber,
                    urlType: btn?.urlType,
                    url: btn?.url,
                    urlSlug: btn?.urlSlug,
                    updateType: 'update'
                }
            });
            // console.log({ actionButtons });
            setActionButtons(actionButtons);
            dispatch(setCallToActionTemplate(actionButtons));
        }
    };

    useEffect(() => {
        if (!actionButtons?.length || is_initialized_template) {
            setEditableActionButtons(call_to_action_templates);
            dispatch(toggleInitializedTemplate(false));
        };
    }, [actionButtons, is_initialized_template]);

    const handleAddActionButton = () => {
        if (actionButtons?.length > 1) {
            return;
        }
        setActionButtons([
            ...actionButtons,
            {
                id: Number(new Date()),
                templateId: null,
                actionType: selectedType,
                buttonText: "",
                countryCode: selectedType === "phoneNumber" ? "+1" : "",
                phoneNumber: "",
                urlType: selectedType === "phoneNumber" ? "" : "Static",
                url: "",
                urlSlug: "",
                updateType: 'insert'
            }
        ]);
    };
    const [countryCodes, setCountryCodes] = useState<any[]>([]);
    useEffect(() => {        
        // const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        // const regionCodes = phoneUtil.getSupportedRegions();
        // const countryCodes = regionCodes.map((regionCode: any) => {
        //     const countryCode = phoneUtil.getCountryCodeForRegion(regionCode);
        //     const countryName = phoneUtil.getRegionCodeForCountryCode(countryCode);
        //     return {
        //         country_name_code: regionCode,
        //         iso: `+${countryCode}`,
        //         full_country_name: countryName,
        //     };
        // });

        // console.log({countryCodes });
        setCountryCodes(all_countries);

    }, [countryCodes?.length])

    const handleRemoveActionButton = (id?: any) => {
        const removable_data = actionButtons?.find(btn => btn.id === id);
        const filter_action_buttons: any[] = actionButtons?.filter(button => button.id !== id);
        setActionButtons(filter_action_buttons);
        dispatch(setCallToActionTemplate(filter_action_buttons));
        if (removable_data?.updateType !== "insert") {
            dispatch(removeCallToActionTemplate({
                id: removable_data?.id,
                templateId: removable_data?.templateId,
            }));
        }
    };

    const handleChange = (key?: any, value?: any, id?: any) => {
        if (key === "buttonText") {
            if (value?.length > 25) {
                // dispatch(setErrorMessage("Button text should not more than 25 characters!"));
                return;
            }
        }

        if (key === "phoneNumber") {
            if (value?.length > 20) {
                // dispatch(setErrorMessage("Phone number should be max 20 characters!"));
                return;
            }
        }
        if (key === "url") {
            if (value?.length > 7) {
                const isUrl = new RegExp('^(http|https)://').test(value);
                if (!isUrl) {
                    dispatch(setErrorMessage(`Invalid URL!`));
                    return;
                }
            }
        }
        if (key === "urlSlug") {
            const regex = /\{\{[^}]+\}\}/g;
            const matches = value?.match(regex);
            // checking proper variable or not
            if (matches?.length) {
                const variable = matches[0]?.replace("{{", "").replace("}}", "");
                const is_variable = checkVariable(variable);
                // console.log({ variable, is_variable });
                if (!is_variable) {
                    dispatch(setErrorMessage("Invalid slug!"));
                    return;
                }
            }
        }
        if (key === "url" || key === "urlSlug") {
            const validate_action_button = actionButtons?.find((btn: any) => ( btn.id === id));
            if (validate_action_button?.urlType?.toLowerCase() === "static" && value?.length > 2000) {
                dispatch(setErrorMessage("URL should be maximum 2000 characters!"));
                return;
            }
            if (validate_action_button?.urlType?.toLowerCase() === "dynamic") {
                let validate_dynamic_url_length = key === "urlSlug"
                    ? (validate_action_button?.url?.length + "/".length + value?.length)
                    : (value?.length + "/".length + validate_action_button?.urlSlug?.length);
                
                if (validate_dynamic_url_length > 2000) {
                    dispatch(setErrorMessage("URL should be maximum 2000 characters!"));
                    return;
                }
            }
        }

        const updated_action_buttons: any[] = actionButtons?.map(btn => (
            btn.id === id
            ?   {
                    ...btn,
                    [key]: value,
                    isUpdatable: true,
                } 
            : btn
        ));
        setActionButtons(updated_action_buttons);
        dispatch(setCallToActionTemplate(updated_action_buttons));
    } 
    
    // console.log({actionButtons});
    return (
        <React.Fragment>
            <div className='call-to-action-buttons-form-container'>
                <div className='title-content'>
                    <div className='title'>Call To Action Buttons</div>
                    <div className='optional'>{"(Optional)"}</div>
                </div>

                <div className="selector-container">
                    <Select
                        style={{ width: 150, borderRadius: "5px" }}
                        placeholder="Select a actionType"
                        allowClear
                        onChange={(value) => setSelectedType(value)}
                    >
                        <Select.Option value="phoneNumber">Phone Number</Select.Option>
                        <Select.Option value="url">Website URL</Select.Option>
                    </Select>
                    <img
                        onClick={() => {
                            if (selectedType) {
                                handleAddActionButton();
                            }
                        }}
                        src={getTemplateImage("add-line")}
                        alt="add-line"
                    />
                </div>

                <div className="call-to-action-form">
                    {actionButtons.map((btn: any, index: any) => (
                        <div key={index} className="parent-form">
                            <div className="form">
                                <label className="form-item">
                                    Button Text
                                    {/* {"(Max 25 Characters)"} */}
                                </label>
                                <div className="form-item">
                                    <Input
                                        placeholder="Enter value"
                                        value={btn?.buttonText || ""}
                                        autoSave="off"
                                        autoComplete="off"
                                        onChange={(e: any) => {
                                            const { value } = e.target;
                                            const inputValue = value?.replace(/\s+/g, ' ');
                                            handleChange("buttonText", inputValue, btn?.id);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form">
                                <label className="form-item">
                                    {
                                        btn?.actionType === "phoneNumber"
                                        ? "Phone Number" 
                                        : 
                                        <span className='url-container'>
                                            <span>URL</span>
                                            <span className='static-dynamic-select'>
                                                <span onClick={() => handleChange("urlType", "Static", btn?.id)}>Static</span> 
                                                <img
                                                    src={btn?.urlType === "Static" ? getTemplateImage("template-active-select") : getTemplateImage("template-inactive-select")}
                                                    onClick={() => handleChange("urlType", "Static", btn?.id)}
                                                    alt="select-icon"
                                                />
                                            </span>
                                            <span className='static-dynamic-select'>
                                                <span onClick={() => handleChange("urlType", "Dynamic", btn?.id)}>Dynamic</span> 
                                                <img
                                                    src={btn?.urlType === "Dynamic" ? getTemplateImage("template-active-select") : getTemplateImage("template-inactive-select")}
                                                    onClick={() => handleChange("urlType", "Dynamic", btn?.id)}
                                                    alt="select-icon"
                                                />
                                            </span>
                                        </span>
                                    }
                                </label>
                                <div className="form-item">
                                    {
                                        btn?.actionType === "phoneNumber"
                                        ?
                                            <Select
                                                style={{ width: 160, textAlign: 'center', borderRadius: "5px", fontSize: '0.75rem'}}
                                                placeholder="US +1"
                                                size='large'
                                                // allowClear
                                                showSearch
                                                listHeight={150}
                                                value={btn?.countryCode || "+1"}
                                                // onChange={(value) => value && handleChange("countryCode", JSON.parse(value)?.iso, btn?.id)}
                                                onChange={(value) => value && handleChange("countryCode", value, btn?.id)}
                                                filterOption={(input: any, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {countryCodes?.map((country: any, index: any) => (
                                                    // <Select.Option key={index} value={JSON.stringify(country)}>{country?.country_code + " " + country?.iso}</Select.Option>
                                                    <Select.Option key={index} value={country?.iso}>{country?.country_code + " " + country?.iso}</Select.Option>
                                                ))}
                                            </Select>
                                        : null
                                    }
                                    <Input
                                        placeholder={`Enter ${btn?.actionType === "phoneNumber" ? "Phone Number" : "URL"}`}
                                        value={btn[btn?.actionType]}
                                        type={btn?.actionType === "phoneNumber" ? "number" : "text"}
                                        autoSave="off"
                                        autoComplete="off"
                                        onChange={(e: any) => {
                                            const { value } = e.target;
                                            if (btn?.actionType === "phoneNumber") {
                                                const inputValue = value?.replace(/\s+/g, '');
                                                handleChange(btn?.actionType, inputValue, btn?.id);
                                            } else {
                                                const inputValue = value?.replace(/[^a-zA-Z0-9\s_/:.-]/g, '')?.replace(/\s+/g, '');
                                                handleChange(btn?.actionType, inputValue, btn?.id);
                                            }
                                        }}
                                    />
                                    {
                                        btn?.urlType === "Dynamic"
                                            ? <Input
                                                placeholder='{{slug}}'
                                                className='url-slug-input'
                                                value={btn?.urlSlug}
                                                autoSave="off"
                                                autoComplete="off"
                                                onChange={(e: any) => {
                                                    const inputValue = e.target.value;
                                                    // space will be _after_ the slug is lowercased
                                                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s_{}]/g, '');
                                                    // const slug = sanitizedValue?.toLowerCase().replace(/ /g, '_');
                                                    const slug = sanitizedValue?.toLowerCase()?.replace(/\s+/g, '');
                                                    const regex = /\{\{[^}]+\}\}/g;
                                                    const matches = slug?.match(regex);
                                                    // checking proper variable or not
                                                    if (matches?.length) {
                                                        const variable = matches[0];
                                                        const is_variable = checkVariable(variable?.replaceAll("{", "").replaceAll("}", ""));
                                                        // console.log({ variable, is_variable });
                                                        if (!is_variable) {
                                                            dispatch(setErrorMessage("Invalid slug!"));
                                                            return;
                                                        }
                                                        handleChange("urlSlug", variable, btn?.id);
                                                        return;
                                                    }
                                                    handleChange("urlSlug", `${slug}`, btn?.id);
                                                }}
                                            />
                                            : null
                                    }
                                    <div className='remove-item' onClick={() => handleRemoveActionButton(btn?.id)}>Remove</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
};

export default CallToActionButtons;
