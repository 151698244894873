import React from 'react';
import './ReviewSummary.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';

const ReviewSummary = (props: any) => {
    const { userName, userImage, title_1, title_2, title_3, value_1, value_2, value_3, averageStar, provider}: any = props || {};
    return (
        <React.Fragment>
            <div className="review-summary">
                <div className="review-header">
                    <img className="profile-pic" src={userImage || getGenerateApiImageByName(userName || "Unknown")} alt="avatar" />
                    <div className="user-details">
                        <div className="username">{userName || "Unknown User"}</div>
                        {
                            Number(averageStar)
                            ?   <div className="stars">
                                    {/* Render star icons, replace with star images or icons */}
                                    {Array.from(Array(Math.floor(parseFloat(averageStar || 5))).keys()).map((_: any, index: number) => {
                                    return (
                                        <img
                                            className=""
                                            key={index}
                                            src={getImage("star")}
                                            alt="star"
                                        />
                                    );
                                    })}
                                </div>
                            : null
                        }
                    </div>
                    <img className="provider" src={getImage(provider || "facebook")} alt="provider-icon" />
                </div>
                <div className="review-stats">
                    <div className="stat">
                        <div className="value">{value_1 || 0}</div>
                        <div className="label">{title_1}</div>
                    </div>
                    <div className="stat">
                        <div className="value">{value_2 || 0}</div>
                        <div className="label">{title_2}</div>
                    </div>
                    <div className="stat">
                        <div className="value">{value_3 || 0}</div>
                        <div className="label">{title_3}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviewSummary;
