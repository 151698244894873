import React from 'react';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import prev from '../../../../assets/icons/prev-box.svg';
// import next from '../../../../assets/icons/next-box.svg';
// import moment from 'moment';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { setBoxId } from '../../../../unified/reducer';
// import Button from '../../../CustomButton/ButtonContainer';
// import { getGenerateApiImageByName } from '../../../../unified/image_service';
import './AllFeedCard.scss';
import AllFeedCard from './AllFeedCard';
import { getImage } from '../../../../unified/image_service';

const FacebookCard = (props: any) => {
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const {workspaceSettings} = useSelector(
      (state: any) => state?.workspaceManagementState
  );
  // const { workspaces } = useSelector((state: any) => state?.headerState);
  // const workSpaceName: any = workspaces?.find(
  //   (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  //   )?.workSpaceName || "";
    // const dispatch = useDispatch();
  const { handleAddToWall, wallUnifiedData, query_string, inbox, type, activePage, isFromModalBox, last } = props;
  // console.log({inbox_data: inbox});
  
  const {
    reviewId, SlNo,
    reviewerPhoteUrl,
    reviewerDisplayName,
    starRating,
    provider, reviewComment,
    createdOn,
  }: any = inbox || {};
  return (
    <React.Fragment>
      <AllFeedCard
        {...props}
        card_data={
          {
            reviewId,
            SlNo,
            profile_image: reviewerPhoteUrl,
            username: reviewerDisplayName || "Unknown User",
            star_ratings: starRating,
            provider,
            comment: reviewComment,
            date: createdOn,
          }
        }
        
        tag_element={
          inbox?.tag?.includes('mention') ? (
            <div className='recommend-title'>mentioned {(workspaceSettings?.workspaceName)}</div>
          ) : inbox?.tag?.includes('comment') ? (
            <div className='recommend-title'>commented to a post {(workspaceSettings?.workspaceName)}</div>
          ) : (inbox?.reviewtype || inbox?.reviewType) ? (
              (inbox?.reviewtype || inbox?.reviewType)?.toLowerCase() === "positive" ? (
                <div className='recommend-title'>
                  <img
                    src={getImage("fb-recommend")}
                    alt="fb-recommend"
                  />
                  <p>recommends {(workspaceSettings?.workspaceName)}</p>
                </div>
              ) : (
                <div className='recommend-title'>
                  <img
                    src={getImage("fb-dont-recommend")}
                    alt="fb-dont-recommend"
                  />
                  <p>doesn't recommend {(workspaceSettings?.workspaceName)}</p>
                </div>
              )
          ) : null
        }
        like_container={
          inbox?.tag?.includes('comment')
          ? <div className="like-container">
              <img
                src={getImage("like")}
                alt="like"
              />
              <div className="likes">
                {inbox?.likeCount === 0 || inbox?.likeCount === 1
                  ? inbox?.likeCount + " Like"
                  : inbox?.likeCount + " Likes"}
              </div>
            </div>
          : null
        }
      />
      {/* <div className={`feeds-card-header ${!query_string?.includes("wall") ? "feed-card-header-conversation" : ""} d-flex justify-content-between align-items-start`}>
        {
          inbox?.reviewerPhoteUrl || inbox?.imageUrl
          ? <img
              className="user-avatar"
              src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
              alt="avatar"
            />
            :
            <img
              className="user-avatar"
              src={getGenerateApiImageByName(inbox?.reviewerDisplayName)}
              alt="avatar"
            />
        }

        <div
          className="feeds-card-header-content d-flex justify-content-between align-items-start"
          style={{ width: "100%" }}
        >
          <div>
            <div className="name-rating d-flex m-0 align-items-center">
              <h6 className="user-name">
                {(inbox?.reviewerDisplayName || "FB")?.substr(0, 8) ||
                  (inbox?.reviewerDisplayName || "FB")?.substr(0, 8) || "Client Name"}
              </h6>
              <div className="ms-2 w-500 text-start">
                {(inbox?.reviewtype || inbox?.reviewType) ? (
                  <div className="recommend-not-recommend-image">
                    {(inbox?.reviewtype || inbox?.reviewType)?.toUpperCase() === "POSITIVE" ? (
                      <div className='image-fb-title'>
                        <img
                          // style={{ height: 9, width: 9 }}
                          src={getImage("fb-recommend")}
                          alt="fb-recommend"
                        />
                        <p>recommends</p>
                      </div>
                    ) : (
                      <div className='image-fb-title'>
                       <img
                        // style={{ height: 9, width: 9 }}
                        src={getImage("fb-dont-recommend")}
                        alt="fb-dont-recommend"
                        />
                        <p>doesn't recommend</p>
                      </div>
                    )}
                    <div className="mx-1 client-container">
                      <p className="client-name">{(workspaceSettings?.workspaceName || workSpaceName)?.substr(0, 6)}</p>
                    </div>
                  </div>
                ) : inbox?.tag?.includes('comment') ? (
                  <p className="client-container d-block">commented in <span className="client-name ms-1">{(workspaceSettings?.workspaceName || workSpaceName).substr(0, 6)}</span></p>
                ) : inbox?.tag?.includes('mention') ? (
                  <p className="client-container d-block">mentioned <span className="client-name ms-1">{(workspaceSettings?.workspaceName || workSpaceName).substr(0, 6)}</span></p>
                ) : null}
              </div>
            </div>
            <p className="text-start date">
              {
                // new Date(inbox?.createdOn).toDateString() + ' '
                // + (new Date(inbox?.createdOn).getHours() < 10 ? '0' + new Date(inbox?.createdOn).getHours() : new Date(inbox?.createdOn).getHours())
                // + ':' + (new Date(inbox?.createdOn).getMinutes() < 10 ? '0' + new Date(inbox?.createdOn).getMinutes() : new Date(inbox?.createdOn).getMinutes())
                // + ':' + (new Date(inbox?.createdOn).getSeconds() < 10 ? '0' + new Date(inbox?.createdOn).getSeconds() : new Date(inbox?.createdOn).getSeconds())
                moment(inbox?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
              }
            </p>
            {
              isFromModalBox
              ? <p
                  className="description mb-2"
                >
                  {inbox?.reviewComment}
                </p>
              : <>
                <p
                  className="text-start description mb-2 normal-description"
                  onClick={() => {
                    if (activePage !== "conversations") {
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                  style={{cursor: 'pointer'}}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
                <p
                  className="text-start description mb-2 lg-description"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (activePage !== "conversations"){
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
              </>
            }
          </div>

          <img
            className='provider-icon'
            src={getImage(inbox?.provider)}
            alt="provider"
          />
        </div>
      </div>
      
      {
        query_string?.includes("wall")
          ?
          <div className="feeds-card-footer w-100">
            {
              isFromModalBox
                ? <div className='prev-next-add-wall-container'>
                  <div className='prev-next-container'>
                    <div className='prev-next'
                      onClick={() => {
                        const prevIndex = inbox?.SlNo - 1;
                        console.log({ wallBoxId: prevIndex });
                          
                        if (prevIndex > 0) {
                          dispatch(setBoxId(prevIndex));
                        }
                      }}
                    >
                      <img className='prev' src={prev} alt="prev" />
                    </div>
                    <div className='prev-next'
                      onClick={() => {
                        const nextIndex = inbox?.SlNo + 1;
                        console.log({ wallBoxId: nextIndex });
                          
                        if (nextIndex <= last) {
                          dispatch(setBoxId(nextIndex));
                        }
                      }}
                    >
                      <img className='next' src={next} alt="next" />
                    </div>
                  </div>
                  <Button
                    classes='add-to-wall-btn'
                    onClick={() => {
                      if (!wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId) {
                        handleAddToWall(inbox);
                        // dispatch(setBoxId(null));
                      }
                    }}
                    disabled={wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId}
                  >
                    {wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId ? "Added" : "Add to Wall"}
                  </Button>
                </div>
                : <div className="d-flex align-items-center justify-content-between w-100">
                  {inbox?.hasOwnProperty("likeCount") &&
                    inbox?.likeCount !== null ? (
                    <div className="like-container">
                      <img
                        className="d-block"
                        src={getImage("like")}
                        alt="like"
                      />
                      <div className="likes">
                        {inbox?.likeCount === 0 || inbox?.likeCount === 1
                          ? inbox?.likeCount + " Like"
                          : inbox?.likeCount + " Likes"}
                      </div>
                    </div>
                  ) : null}
                  <button
                    className={`${(wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId) && activePage !== "conversations"
                      ? ""
                      : query_string?.includes("wall")) &&
                      "add-to-wall-btn-hover"
                      } add-to-wall-btn`}
                    type="button"
                    disabled={wallUnifiedData?.find(
                      (wd: any) =>
                        wd?.reviewId === inbox?.reviewId &&
                        activePage !== "conversations"
                    )}
                    onClick={() => {
                      if (activePage !== "conversations") {
                        handleAddToWall(inbox);
                      }
                    }}
                  >
                    {wallUnifiedData?.find(
                      (wd: any) => wd?.reviewId === inbox?.reviewId
                    ) && activePage !== "conversations"
                      ? "Added"
                      : "Add to Wall"}
                  </button>
                </div>
            }
          </div>
          : null
      } */}
    </React.Fragment>
  )
};

export default FacebookCard;
