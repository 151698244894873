import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./AccountSettings.scss";
import {
    getImage,
    // getGenerateApiImageByName
} from "../../../unified/image_service";
// import { requestForSelectingBioPageTemplate, toggleSelectedTemplate } from "../BioPageSettings/bioPageReducer";
import { setSuccessMessage, setErrorMessage } from "../../../unified/reducer";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";
import { updateProfileInfoSetting } from "../UserProfile/userProfileManageReducer";
import { updateWorkSettings } from "../WorkspaceSettings/workspaceReducer";
// import CommonModalCustomWidth from "../../../components/CommonModal/CommonModalCustomWidth";

const AccountSettings: React.FC = () => {
    // const parent_routes = workspace_routes;
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    // const path_name = window.location.pathname;
    const {
        workspaceSettings,
        // isUpdatedWorkspace 
    } = useSelector((state: any) => state?.workspaceManagementState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const { GeneralSettings, is_selected_bio_page_template, bioPageTemplates } = useSelector((state: any) => state?.bioPageSettingState);
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    
    // account use state
    const [registeredName, setRegisteredName] = useState<any>("");
    const [organizationName, setOrganizationName] = useState<any>("");
    const [address, setAddress] = useState<any>("");
    const [accountType, setAccountType] = useState<any>("");
    
    useEffect(() => {
        const {
            businessName,
            // workspaceName, workspaceType, logo, subDomainName, customerFeedBackRedirectURL, videoTestimonialRedirectURL
        }: any = workspaceSettings || {};
        const { registeredName, organizationName, address, accountType }: any = personalInfo || {};
        console.log("PersonalInfo & GeneralSettings ====> " ,{ personalInfo , GeneralSettings});
        if (personalInfo) {
            // setRegisteredName(registeredName || "");
            // setOrganizationName(organizationName || "");
            setOrganizationName(businessName || ""); // it will be changed
            setAddress(address || "");
            setAccountType(accountType || "");
        }
    }, [personalInfo]);

    const updateGeneralSettings = (key: any, value: any) => {
        dispatch(updateProfileInfoSetting({ columnName: key, columnValue: value, clapupUserId: logged_in_user_secret_data?.cuid }));
        setTimeout(() => dispatch(setSuccessMessage("Settings saved.")), 1000);
    }

    const handleUpdateWorkspaceSettingsField = (columnName: any, columnValue: any) => {
        const workspaceId = logged_in_user_secret_data?.wuid;
        const clapupUserId = logged_in_user_secret_data?.cuid;
        const roleId = logged_in_user_secret_data?.roleId;
         
        if (!workspaceId || !clapupUserId || !roleId) {
            return;
        }
        console.log({columnValue});
        if(!columnValue){
            // setWorkspaceState && setWorkspaceState("");
            dispatch(setErrorMessage("Value is required"));
        }else {
            const dataObject = {
                columnName,
                columnValue,
                clapupId: clapupUserId,
                roleId: roleId,
                workSpaceId: workspaceId,
            }
            console.log({dataObject});
            dispatch(updateWorkSettings(dataObject));
        }
    }


    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        const path = e.target.value;
        console.log({path, file});
        // setImageFile(file);

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     setLogoBaseUrl(base64Url);
        //     // handleLogoSubmit(base64Url);

        // })
        // .catch((error: any) => {
        //     console.log({error});
        // });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": logged_in_user_secret_data?.wuid,
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }
    const {userPermissions, is_show_mobile_settings_nav_bar} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);


    return (
        <React.Fragment>
            
            {/* <CommonModalCustomWidth 
                modal_visible={isReadyToSelectTemplate} 
                setModalVisible={setIsReadyToSelectTemplate}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                class_name="modal_container"
                footer={null}
            >
                
            </CommonModalCustomWidth> */}
            {/* Template Selection Modal */}
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                {/* <button>Save Changes</button> */}
            </div>
            <div className="body-container">
                <div className="account-settings-container">
                    <div className="settings-header-title">
                        <h3>Account Settings</h3>
                    </div>

                    <div className="settings-form-item">
                        <div className="title">Register Name</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    defaultValue={registeredName || ""}
                                    onChange={(e: any) => setRegisteredName(e.target.value)}
                                    placeholder={"Register Name"}
                                />
                                <img
                                    // onClick={() => {
                                    //     updateGeneralSettings('RegisteredName', registeredName);
                                    // }}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="settings-form-item">
                        <div className="title">Organization Name</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    defaultValue={organizationName || ""}
                                    onChange={(e: any) => setOrganizationName(e.target.value)}
                                    placeholder={"Organization Name"}
                                />
                                <img
                                    onClick={() => {
                                        // updateGeneralSettings('OrganizationName', organizationName);
                                        handleUpdateWorkspaceSettingsField("BusinessName", organizationName);
                                    }}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="settings-form-textarea-item">
                        <div className="title">Billing Address</div>
                        <div className="action-body">
                            <div className="input-container">
                                <textarea
                                    className=""
                                    defaultValue={address || ""}
                                    onChange={(e: any) => setAddress(e.target.value)}
                                    // defaultValue={"Padmanivas, Edayar, Kannavam PO, Kannur District 670650"}
                                    placeholder={"Billing Address"}
                                />
                                <img
                                    onClick={() => {
                                        updateGeneralSettings('Address', address);
                                    }}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title">Account Type</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className="disable-input"
                                    readOnly={true}
                                    value={accountType || "Guest Account"}
                                    placeholder={"Account"}
                                />
                                {/* <img className="tik" src={getImage("fill-success-tik")} alt="tik"/> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="settings-form-item">
                        <div className="title">Fav Icon</div>
                        <div className="action-body">
                            <div className="logo-container">
                                <img
                                    className='logo' 
                                    src={imageURL || getGenerateApiImageByName(bioPageName)} 
                                    alt={"logo"} 
                                />
                                <div className="action-container">
                                    <div
                                        onClick={() => handleDeleteWorkspaceLog()}
                                    >
                                        Delete
                                    </div>
                                    <input
                                        id="image" type="file"
                                        // accept='*'
                                        accept='image/png, image/jpg, image/jpeg, image/svg'
                                        onChange={(e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                            // setImage(e?.target?.files[0]?.name);
                                            handleImageChange(e);
                                            // if (e?.target?.files[0]?.size < 25000) {
                                            // }

                                        }}
                                        className="d-none"
                                    />
                                    <label htmlFor="image">Upload New</label>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="extra"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AccountSettings;