import React from "react";
import './Button.scss'

interface Props {
  iconComponent?: any;
  iconPath?: any;
  backgroundColor?: string;
  border?: string;
  color?: string;
  children?: React.ReactNode;
  height?: string;
  width?: string;
  margin?: string;
  onClick?: any;
  radius?: string
  fontSize?: string;
  image?: boolean;
  fontWeight?: string;
  disable?: boolean;
  padding?: string;
  cursor?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  position?: string;
  hover?: boolean;
  classes?: string;
}

const CustomButton: React.FC<Props> = ({
  iconComponent,
  iconPath,
  border,
  backgroundColor,
  color,
  children,
  height,
  onClick,
  radius,
  width,
  fontSize,
  image,
  margin,
  padding,
  fontWeight,
  disable,
  display,
  justifyContent,
  alignItems,
  hover,
  classes
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disable}
      className={`${hover ? 'hover' : ''} ${classes}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        border,
        borderRadius: radius,
        height,
        width: width,
        margin: margin,
        padding,
        fontSize: fontSize,
        fontWeight: fontWeight,
        cursor: disable ? 'not-allowed' : 'pointer',
        opacity: disable ? 0.5 : 1,
        display: display || '',
        justifyContent: justifyContent || '',
        alignItems: alignItems || '',
      }}
    >
      {iconComponent || (image && <img src={iconPath} style={{ width: '20px', margin: '8px 10px' }} />)}
      {children}
    </button>
  );
}

export default CustomButton;