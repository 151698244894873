import React from 'react';
import FacebookMentions from './Facebook/Mentions';
import FacebookComments from './Facebook/Comments';
import FacebookReviews from './Facebook/Reviews';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
const FacebookConversations = (props: any) => {
  const {
    conversationDetailsData,
    // unifiedData
  }: any = useSelector((state: RootState) => state?.unified);
  const {
    conversation,
    // sender
  } = props;
  const { tag } = conversation || {};
  // const route_path = window?.location?.pathname;
  // // console.log({ fb_conversation: conversation });
  // // console.log({ tag, sender });
  // const {
  //   InboxAll_FbMentionsConversation, InboxAll_FbReviewsConversation, InboxAll_FbCommentsConversation
  // } = unifiedData || {};

  // const all_details: any =
  //   route_path?.includes('unified') ?
  //     tag?.includes('review')
  //     ? { data: InboxAll_FbReviewsConversation, key: "ReviewId" }
  //     : tag?.includes('comment')
  //     ? { data: InboxAll_FbCommentsConversation, key: "reviewId" }
  //     : { data: InboxAll_FbMentionsConversation, key: "mentionId" }
  // : [];
  
  // const conversationDetailsData: any [] = all_details?.data?.filter((item: any) => item[all_details?.key] === conversation?.reviewId );

  // console.log({conversationDetailsData, all_details, conversation});
  return (
    <React.Fragment>
      {
        tag?.includes("mention")
          ? <FacebookMentions {...props} conversationDetailsData={conversationDetailsData} />
          : tag?.includes('comment')
            ? <FacebookComments {...props} conversationDetailsData={conversationDetailsData} />
            : <FacebookReviews {...props} conversationDetailsData={conversationDetailsData} />
        // : null
      }
    </React.Fragment>
  )
};

export default FacebookConversations;