// const project_url = window.location.host;
// const prod_url = "app.clapup.io";
// ===== AWS S3 =====
export const aws_account_id = process.env.REACT_AWS_ACCESS_ACCOUNT_ID || "7e93201b14c845ca2c0a69a67557e543";
export const aws_access_key = process.env.REACT_AWS_ACCESS_KEY_ID || "bd755bf91cf43678d86ec58a1059cdb9";
export const aws_secret_key = process.env.REACT_AWS_ACCESS_SECRET_KEY || "4b2f911d86287fc64ee74a7a8a6e587a5a0a8658ad7812b853a5c96dc51d9d7a";
export const cloudFlareR2ServiceUrl = process.env.REACT_AWS_CLOUD_FLARE_SERVICE_URL || "https://7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com";


// const auth_local_url = "http://localhost:3600";

export const auth_url = process.env.REACT_APP_AUTH_URL || "https://auth-test.clapup.me";

// ===== Clapup Server =======
// const clapup_dev_url = "https://dev2.clapup.me"; // it will be changed by below url
// const clapup_dev_url = "https://localhost:44367";
// const api_prod_url = process.env.REACT_APP_API_PROD_URL;
// const api_dev_url = process.env.REACT_APP_API_URL || "https://api.clapup.io";
// const api_dev_url = process.env.REACT_APP_API_URL || "https://api-test.clapup.me";
// export const api_url = process.env.NODE_ENV === "development"
//     ? (api_dev_url || "https://localhost:44367")
//     : process.env.REACT_APP_API_URL;

// const api_dev_url = process.env.REACT_APP_API_URL;
export const api_version = process.env.REACT_APP_API_VERSION || "v1.3";
export const api_url = process.env.REACT_APP_API_URL;
export const inbox_api_url = process.env.REACT_APP_INBOX_API_URL;

// export const api_url = "https://api-test.clapup.me";

// ===== Bio-page Server =====

// const bio_embed_local_url = "http://localhost:3001/wall/[main/google/facebook/feedback/video]";
// const bio_embed_local_url = process.env.REACT_APP_BIO_PAGE_LOCAL_URL;
// export const bio_url = bio_embed_local_url || "";

export const bio_url = "";

export const fb_app_id = process.env.REACT_APP_FB_APP_ID;
export const fb_whatsapp_app_id = process.env.REACT_APP_WHATSAPP_FB_APP_ID;
export const fb_whatsapp_config_id = process.env.REACT_APP_WHATSAPP_FB_CONFIG_ID;
export const fb_whatsapp_solution_id = process.env.REACT_APP_WHATSAPP_FB_SOLUTION_ID;

// SignalR api url
// export const signalR_api_url = process.env.REACT_APP_SIGNAL_R_API_URL || "https://signalr-test.clapup.me/RecordHub";
export const signalR_api_url = process.env.REACT_APP_SIGNAL_R_API_URL || "https://signalrapi-test.clapup.me/RecordHub";
export const signalR_team_api_url = process.env.REACT_APP_SIGNAL_R_TEAM_API_URL || "https://signalrapi-test.clapup.me/TeamHub";
export const signalR_user_api_url = process.env.REACT_APP_SIGNAL_R_USER_API_URL || "https://signalrapi-test.clapup.me/UserHub";
console.log({ auth_url, api_url, signalR_api_url, mode: process.env.NODE_ENV });
// console.log({ fb_whatsapp_app_id, fb_whatsapp_config_id, fb_whatsapp_solution_id });