import React, {useState, useEffect} from "react";
import AllMessages from "./messages-components/AllMessages";
import ArchiveMessages from "./messages-components/ArchiveMessages";
import AssignedToMeMessages from "./messages-components/AssignedToMeMessages";
import SharedWithMeMessages from "./messages-components/SharedWithMeMessages";
import SharedCopiesMessages from "./messages-components/SharedCopiesMessages";
import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { manageInboxSenderState } from "./reducer";
// import { RootState } from "../store";
// import TeamConnection from "../utils/signalRTeamConnection";

// import UnPermission from "../pages/un-permission/UnPermission";
const AllMessagesManagementComponent: React.FC = () => {
    // const { global_message_type } = useSelector((state: RootState) => state?.unified);
    // const dispatch = useDispatch();
    // const query_string = window.location.search;
    const route_path = window?.location?.pathname;
    const { params } = useParams<{ params: string }>();
    // const [route_path, setRoutePath] = useState<any>("");
    // useEffect(() => {
    //     console.log("Path useEffect ", {params, route_path, pathname})
    //     setRoutePath(pathname);
    // }, [params]);
    // // ===== SignalR start - inbox-email-message - replying ====
    // const {
    //     isReplyingOn,
    //     updated_assigned_user_id,
    //     state_inbox_conversation: conversation,
    //     // whatsapp_replying_text,
    //     // whats_app_sender_state: whats_app_sender
    // } = useSelector((state: RootState) => state?.unified);
    //   const { setting_teams } = useSelector((state: any) => state?.teamState);

    // // // Join a workspace (replace with actual workspace ID)
    // const joinWorkspace = async (workspaceId: any) => {
    //     try {
    //         await TeamConnection.invoke("JoinWorkspace", workspaceId);
    //         console.log(`SignalR_Archive_Inbox: Joined workspace: ${workspaceId}`);
    //     } catch (error) {
    //         console.error("SignalR_Archive_Inbox: Error joining workspace:", error);
    //     }
    // };

    // // Join a team (replace with actual team ID)
    // const joinTeamGroup = async (teamId: any) => {
    //     try {
    //         console.log(`SignalR_Inbox_Team_n: before join team: ${teamId}`);
    //         await TeamConnection.invoke("JoinTeamGroup", teamId);
    //         console.log(`SignalR_Inbox_Team_n: after join team: ${teamId}`);
    //     } catch (error) {
    //         console.log("SignalR_Inbox_Team: Error joining team:", error);
    //     }
    // };

    // // // Receiving hook
    // useEffect(() => {
    //     const user_info = JSON.parse(
    //         sessionStorage.getItem("accessed_user_details") || "{}"
    //     );
    //     console.log('SignalR_Conversation Receiving hook => ', {state: TeamConnection?.state});

    //     if (TeamConnection?.state === 'Connected') {
    //         // console.log("SignalR_Conversation: => ", {state: TeamConnection?.state, email: user_info?.email, name: user_info?.name, ticketNo: conversation?.messageId, provider: conversation?.provider });
    //         // there will be a loop for all team ids
    //         const promises = setting_teams?.map((team: any) => joinTeamGroup(team?.teamId));
    //         Promise.all(promises);
    //         // if (sessionStorage.getItem('wuid') || localStorage.getItem('wuid')) {
    //         //     joinWorkspace(sessionStorage.getItem('wuid') || localStorage.getItem('wuid'));
    //         // }

    //         const handleLockWhenAssignToClapupUserId = (details: any) => {
    //             const sender_details = JSON.parse(details);
    //             const {isForInserting, clapupUserId, sender_name, provider, sender_email, messageId, ticket_serial_no, ticket_no } = sender_details || {};
    //             console.log("SignalR_Conversation: received data:", { sender_details: sender_details, user_info});
                
    //             // update sender lock state for an array means inserting -> multiple users can multiple events
    //             // we can lock inbox item and can't reply for 10 mins using upcoming messageId, ticket_serial_no and ticket_no
    //             if (sender_email !== user_info?.email && messageId && ticket_serial_no && ticket_no) {
    //                 console.log(`SignalR_Conversation User with ${sender_name}: `, {sender_details: {...sender_details, clapupUserId, provider, isForInserting: isForInserting}});
    //                 dispatch(manageInboxSenderState({...sender_details, isForInserting: isForInserting }));
    //                 // if(replying_text){} else {}
                        
    //                 // setTimeout(() => {
    //                 //     console.log(`SignalR_Conversation User with ${sender_name}: `, {
    //                 //     sender_details: {
    //                 //         ...sender_details, isForInserting: false
                        
    //                 //     }});
    //                 //     dispatch(manageInboxSenderState({...sender_details, isForInserting: false}));
    //                 // }, 600000); // 10 mins = 600 sec
    //                 // // if (!sender) {}
    //             }
    //         }

    //         // receives methods with route
    //         console.log('SignalR_Conversation Receiving method called => ', {state: TeamConnection?.state});
    //         // TeamConnection.on('TicketLocked', handleLockWhenAssignToClapupUserId);
    //         TeamConnection.on('EmailMessageLocked', handleLockWhenAssignToClapupUserId);
    //         // TeamConnection.on('TicketUnlocked', handleLockWhenTyping);

    //         // Clean up SignalR TeamConnection on component unmount
    //         return () => {
    //             // TeamConnection.off('TicketLocked', handleLockWhenAssignToClapupUserId);
    //             TeamConnection.off('EmailMessageLocked', handleLockWhenAssignToClapupUserId);
    //         }

    //     }
    // }, [TeamConnection?.state]);

    // // invoking hook - current
    // useEffect(() => {
    //     try {
    //         const user_info = JSON.parse(
    //             sessionStorage.getItem("accessed_user_details") || "{}"
    //         );
    //         // console.log({conversation, replying_text, state: TeamConnection?.state});
            
    //         if (conversation?.messageStatus === "open" && TeamConnection?.state === "Connected" && updated_assigned_user_id) {
    //             const path = query_string.replace('?', '')?.replaceAll('&', "=");
    //             const path_array = path.split("=");
    //             const [
    //                 _1, _2,
    //                 _cov, conversation_id,
    //                 _sn, sl_no,
    //                 _ticket, cur_ticket_no
    //             ] = path_array;
                
    //             const sender_data = {
    //                 isInserting: true,
    //                 clapupUserId: sessionStorage.getItem('cuid') || localStorage.getItem('cuid'),
    //                 sender_email: user_info?.email,
    //                 sender_name: user_info?.name,
    //                 messageId: conversation_id,
    //                 teamId: conversation?.teamId,
    //                 ticket_serial_no: sl_no,
    //                 ticket_no: cur_ticket_no,
    //                 provider: conversation?.provider,
    //             };
    //             console.log("SignalR_Conversation invoked by new data: => ", {conversation, state: TeamConnection?.state, sender_data, email: user_info?.email, name: user_info?.name });
    //             // store old data...if route changed this old data will be used for removing from redux state
    //             sessionStorage.setItem("prev_sender_data", JSON.stringify({...sender_data, isInserting: false }));
        
    //             // // // invoking methods with route
    //             const teamId = sender_data?.teamId;
    //             const messageId = conversation_id;
    //             const providerExample = conversation?.provider;
    //             const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid');
                
    //             // LockEmailMessage(string messageId,  string teamId)
    //             // SendAsync("EmailMessageLocked", messageId)
    //             // invoke method parameter => ("method-name",true, info-string, ticketId, providerExample, workspaceId)
    //             // TeamConnection.invoke('LockTicket', true, JSON.stringify(sender_info), ticketId, providerExample, workspaceId)
    //             TeamConnection.invoke('LockEmailMessage', JSON.stringify(sender_data), messageId, teamId)
    //             .catch((error: any) => {
    //                 console.error('SignalR_Conversation invoked: Failed to send typing status:', error);
    //             }); 
    //         }
    //     } catch (err: any) {
    //         console.log("SignalR Error: ", {err});
    //     }
    // }, [updated_assigned_user_id]);
    
    // // // invoking hook - old data
    // // useEffect(() => {
    // //     try {
    // //         if (TeamConnection?.state === "Connected" && !updated_assigned_user_id) {
    // //             // removing previous sender state
    // //             const prevSavedInfo: any = JSON.parse(
    // //                 sessionStorage.getItem("prev_sender_data") || "{}"
    // //             );
    // //             if (prevSavedInfo?.messageId && prevSavedInfo?.teamId && prevSavedInfo?.ticket_no && prevSavedInfo?.ticket_serial_no) {
    // //                 console.log("SignalR_Conversation invoked for removing previous data by isFromNew: false => ", { prevSavedInfo });
    // //                 // invoking methods with route
    // //                 console.log("SignalR_Conversation invoked by data: => ", {state: TeamConnection?.state, prevSavedInfo });
    // //                 sessionStorage.removeItem("prev_sender_data");
    // //                 localStorage.removeItem("prev_sender_data");
    // //                 const ticketId = prevSavedInfo?.ticket_no;
    // //                 const providerExample = prevSavedInfo?.provider;
    // //                 const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid');
    // //                 // // "TicketLocked", ticketId,workspaceId,userName, provider)
                    
    // //                 // LockEmailMessage(string messageId,  string teamId)
    // //                 // SendAsync("EmailMessageLocked", messageId)
    // //                 // TeamConnection.invoke('LockEmailMessage', JSON.stringify(prevSavedInfo), teamId)
    // //                 // const messageId = prevSavedInfo?.messageId;
    // //                 // const teamId = prevSavedInfo?.teamId;
    // //                 TeamConnection.invoke('LockTicket', true, JSON.stringify(prevSavedInfo), ticketId, providerExample, workspaceId)
    // //                 .catch((error: any) => {
    // //                     console.error('SignalR_Conversation invoked: Failed to send typing status:', error);
    // //                 }); 
    // //             }
    // //         }
    // //     } catch (err: any) {
    // //             console.log("SignalR Error: ", { err });
    // //     }
    // // }, [TeamConnection?.state, query_string, route_path]);

    // // ===== SignalR start - inbox-email-message - replying ====


    
    // const history = useNavigate();
    // const {userPermissions} = useSelector((state: any) => state?.login);
    // const [isPermissible, setIsPermissible] = React.useState(true);
    // React.useEffect(() => {
    //     console.log({userPermissions});
    //     const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Messages") : null;
    //     if( permission?.value === 0){
    //         setIsPermissible(false);
    //     }
    // }, [userPermissions?.length]);
    

    // console.log({global_message_type, data});
    
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            {/* <RightBottomMessagesTabs /> */}
            {
                params?.includes('archives')
                ? <ArchiveMessages />
                : params?.includes('assigned-to-me') 
                ? <AssignedToMeMessages />
                : params?.includes('shared-with-me') 
                ? <SharedWithMeMessages />
                : params?.includes('shared-copies') 
                ? <SharedCopiesMessages />
                : route_path?.includes('/all-messages') 
                ? <AllMessages />
                : null
            }
        </React.Fragment>
    )
}

export default AllMessagesManagementComponent;

