import React from 'react';
// import moment from 'moment';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
// import { modifyString } from '../../../../utils/mediaService';
// import { getGenerateApiImageByName } from '../../../../unified/image_service';
import BotMessagesReviewSummary from './BotMessagesReviewSummary/BotMessagesReviewSummary';
import BotMessagesSummaryList from './BotMessagesReviewSummary/BotMessagesSummaryList';
import BotMessagesAttachmentSummary from './BotMessagesReviewSummary/BotMessagesAttachmentSummary';
import ReviewHeader from './ReviewHeader/ReviewHeader';

const BotConversationDetails = ({ workspaceSettings, statDetails, type }: any) => {
  // const route_path = window?.location?.pathname;
  // const query_string = window.location.search;
  // const query_tag = query_string?.includes("open") ? "open"
  // : query_string?.includes("inProgress") ? "inProgress"
  // : query_string?.includes("closed") ? "closed" 
  // : query_string?.includes("spam") ? "spam" 
  // : "all";
  // 1
  // const {userName, reviewerPhoteUrl, ticketNo} = statDetails || {};
  
  // 3
  // const { unifiedData, isReplyingOn, isInReplyingMode }: any = useSelector((state: RootState) => state?.unified);
  
  // console.log({ conversation, unifiedDetailsData, isInReplyingMode, isReplyingOn });
  
  // const conversationDetails = all_details?.find((item: any) => item?.QuestionId === conversation?.reviewId);

  // function getDateDifference(date1: any, date2: any) {
  //   if(!date1 || !date2) return " --- ";
  //   // Calculate the time difference in milliseconds
  //   const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
  //   console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
    
  //   // Calculate the number of days
  //   let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  //   // Calculate the remaining hours
  //   const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  //   // Calculate the remaining minutes
  //   const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  //   let res = "";
  //   let years: any = 0;
  //   if (days > 365) {
  //     years = Math.floor(days / 365);
  //     res += years + "Years, ";
  //     days -= years * 365;
  //     res += days + "Days ";
  //     days = 0;
  //   }
  //   if (days > 0) {
  //     res += days + "Days, ";
  //   }
  //   if (!years) {
  //     res += hours + "Hours, " + minutes + "Minutes";
  //   }
  //   return res;
  // }

  // const [showMore, setShowMore] = React.useState(false);
  const { botInboxData: bot_inbox_data}: any = useSelector((state: RootState) => state?.unified);
  // const dispatch = useDispatch();

  // const {}: any = bot_inbox_data || {};

  // const wallUnifiedData: any[] = CFWall || [];
  const { chatSummaryList }: any = bot_inbox_data || {};
  const summary_data: any = chatSummaryList?.find((item: any) => item?.ticketNo === statDetails?.ticketNo);
  const { summary }: any = summary_data || {};
  console.log({ summary_data: summary_data, summary, chatSummaryList, ticketNo: statDetails?.ticketNo });
  // const summary = "Lorem Ipsum is simply dummy text of year printing and typesetting industry. Lorem and ipsum has been the industry's standard with text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it  to make a type specimen book. when an unknown  printer took a galley of type and scrambled it  to make a type specimen book";
  // useEffect(() => {
  //   if (summary) {
      
  //     console.log({ summary });
  //   }
  // }, [ticketNo, summary]);
  return (
    <React.Fragment>
      <div className="conversation-details">
        <ReviewHeader
          tabs={["General", "Notes & Reminders", "Discussions"]}
          handleChangeTab={(data: any) => console.log(data)}
          value={"General"}
        />
        {/* <div className='details-tab-header'>
          <div className="tab tab-active">General</div>
          <div className="tab">Notes & Reminders</div>
          <div className="tab">Discussions</div>
        </div> */}
        <div className="details-body">
          <BotMessagesReviewSummary
            userName={statDetails?.userName}
            userImage={statDetails?.reviewerPhoteUrl || null} // conversation?.reviewerPhoteUrl
            contact={statDetails?.contact}
            provider={statDetails?.provider || "bot-messages"}
            value_1={Number(statDetails?.days || 0)}
            value_2={Number(statDetails?.threads || 0)}
            value_3={Number(statDetails?.totalMessages || 0)}
            title_1={"Days"}
            title_2={"Threads"}
            title_3={"Score"}
          />
          
          <BotMessagesSummaryList
            list_data={{
              provider: statDetails?.provider || "bot-messages",
              tag: statDetails?.tag,
              type: "Customer Feedback",
              to: workspaceSettings?.workspaceName,
              tag_label: "Reviewed on",
              createdOn: statDetails?.createdOn,
              repliedBy: statDetails?.userName || "Unknown User",
              repliedOn: statDetails?.reviewReplyUpdatedon,
            }}
          />

          <BotMessagesAttachmentSummary
            attachments={statDetails?.attachments}
            summary={statDetails?.summary}
          />
            
        </div>
      </div>
      {/* Laptop/ Mobile */}
      {/* <div className="w-100 h-100 bot-conversation-details">
        <div className="bot-inbox-wall w-100">
          <div className="right-feed-card rounded">
            <div className="d-flex align-items-start" style={{padding: '3%'}}>
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(userName)}
                      alt="avatar"
                  />
              }
              <div className="user-name-provider-container">
                <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                  <div style={{marginTop: `0`}}>
                    <div className="user_name">
                      {userName || "User Name"}
                    </div>
                    <div className="date-time">{moment(statDetails?.createdOn).format("DD MMM YYYY, hh:mm:ss A")}</div>
                  </div>
                </div>
                <img
                  className="social-logo"
                  src={getImage(type)}
                  alt={type || "type-icon"}
                />
              </div>
            </div>
            <div className="footer w-100">
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Days</span>
                <span className="value">{statDetails?.days}</span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Threads</span>
                <span className="value">{statDetails?.threads}</span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Messages</span>
                <span className="value">{statDetails?.totalMessages}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="bot-properties-wall w-100">
          <div className='properties-titles'>
            <div className="titles">Ticket No:</div>
            <div className="titles">Email:</div>
            <div className="titles">Channel:</div>
            <div className="titles">Assigned To:</div>
            <div className="titles">Status:</div>
          </div>
          <div className='properties-values'>
            <div className="values">
              <div className='value'>{modifyString(statDetails?.ticketNo, 10, 10)}</div>
            </div>
            <div className="values">
              <div className='value email-value'>{modifyString(statDetails?.userEmail, statDetails?.userEmail?.length > 20 ? 5 : 5, 10, statDetails?.userEmail?.length > 20 ? "..." : "")}</div>
            </div>
            <div className="values">
              <div className='value'>
                <div className='channel-color'>
                  <img style={{height: '100%', width: '100%'}} src={workspaceSettings?.logo || user_avatar_2} alt="channel" />
                </div>
                <span>{statDetails?.channel || "Channel"}</span>
              </div>
            </div>
            <div className="values">
              <div className='value'>
                <div className='assigned-color'>
                  <img style={{height: '100%', width: '100%'}} src={getImage(type)} alt="type-icon" />
                </div>
                <span>{"A I Bot"}</span>
              </div>
            </div>
            <div className="values">
              <div className='value'>
                <div className='status-color'></div>
                <span>In Progress</span>
              </div>
            </div>
          </div>
        </div>
        <div className="bot-details-wall w-100">
          <div className='details-container'>
            <div className='details-title'>Conversation Summary</div>
            <div className='details-value'>
              {
                
                summary?.length < 120 ? 
                <div>{summary || ""}</div>
                : !showMore && summary?.length > 120 ? 
                <div>
                  {summary?.substring(0, 120) + "..."} 
                  <span style={{cursor: 'pointer', fontWeight: 600, color: '#000000'}} onClick={()=> setShowMore(!showMore)}>More</span>
                </div>
                : summary?.length ? 
                <div>
                  {summary + "..."}
                  <span style={{cursor: 'pointer', fontWeight: 600, color: '#000000'}} onClick={()=> setShowMore(!showMore)}>Less</span>
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div> */}

    </React.Fragment>
  )
};

export default BotConversationDetails;
