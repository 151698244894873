import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { 
    getKeyValue, 
    getAllKeysFromAllStores, 
    deleteDatabase 
} from './utils/indexedDBManagementService';
import { setLoggedInUserSecretData } from './signup-login/loginReducer';
import { setLogout } from './components/Layout/header/headerReducer';
import Constants from './constants';

const SESSION_TIMEOUT = 30 * 60 * 1000; // n x 60 x 1000 = n minutes in milliseconds

const PrivateRoute = ({ children }: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const { params } = useParams();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);

    useEffect(() => {
        const handleSession = async () => {
            try {

                const localStorageData = localStorage;
                for (let i = 0; i < localStorageData?.length; i++) {
                    const key: any = localStorageData.key(i);
                    const value: any = localStorageData.getItem(key);
                    // console.log(key, "=== key ==> ", value);
                    const flag = sessionStorage.getItem(key) !== value &&
                        key !== undefined && key !== "access_token" &&
                        key !== "refresh_token"
                    if (flag) {
                        sessionStorage.setItem(key, value);
                    }
                }

                // Handle tab/window close logic
                // const handleBeforeUnload = async () => {
                //     try {
                //         await deleteDatabase();
                //         dispatch({ type: Constants.LOGOUT });
                //         dispatch(setLogout(true));
                //         setIsLoggedIn(false);
                //     } catch (err) {
                //         console.error("Error during unload:", err);
                //     }
                // };

                const sessionStartTime: any = localStorage.getItem('session_start_time');
                const currentTime = Date.now();
                // console.log('Session expired, clearing data...', {
                //     pre_flag: currentTime - parseInt(sessionStartTime, 10),
                //     flag: sessionStartTime && currentTime - parseInt(sessionStartTime, 10) >= SESSION_TIMEOUT,
                //     sessionStartTime,
                //     currentTime,

                // });
                setIsLoading(true);
                if (sessionStartTime && currentTime - parseInt(sessionStartTime, 10) >= SESSION_TIMEOUT) {
                    console.log('Session expired, clearing data...');
                    await deleteDatabase();
                    localStorage.clear();
                    sessionStorage.clear();
                    dispatch({ type: Constants.LOGOUT });
                    setIsLoggedIn(false);
                }
                else if (!logged_in_user_secret_data) {
                    const userSecretData = (await getKeyValue("logged_in_user_secret_data")) || {};
                    const userDetailsData = (await getKeyValue("accessed_user_details")) || {};
                    const allKeysStoreData = await getAllKeysFromAllStores();

                    console.log("Fetched user data:", {
                        userSecretData,
                        userDetailsData,
                        allKeysStoreData,
                    });

                    if (!userSecretData) {
                        dispatch({ type: Constants.LOGOUT });
                        return;
                    }

                    if (userSecretData) {
                        setIsLoggedIn(true);
                        dispatch(
                            setLoggedInUserSecretData({
                                accessed_user_details: userDetailsData,
                                logged_in_user_secret_data: userSecretData,
                            })
                        );
                    } else {
                        dispatch({ type: Constants.LOGOUT });
                    }
                } else {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error("Error managing session:", error);
            } finally {
                setIsLoading(false);
            }
        };

        handleSession();
    }, [logged_in_user_secret_data, dispatch]);

    // Extend session on user activity
    useEffect(() => {
        const updateSessionTime = () => {
            const currentTime = Date.now();
            // console.log({ currentTime });
            localStorage.setItem("session_start_time", currentTime.toString());
        };

        window.addEventListener("click", updateSessionTime);
        window.addEventListener("keypress", updateSessionTime);

        return () => {
            window.removeEventListener("click", updateSessionTime);
            window.removeEventListener("keypress", updateSessionTime);
        };
    }, []);
    
    // if route changed
    useEffect(() => {
        const updateSessionTime = () => {
            const currentTime = Date.now();
            // console.log({ currentTime, params, pathname: location.pathname, search_name: location.search });
            localStorage.setItem("session_start_time", currentTime.toString());
        };

        updateSessionTime();
    }, [location.pathname, location.search, params]);

    return isLoggedIn ? (
        children
    ) : !isLoading ? (
        <Navigate to="/login" state={{ from: location.pathname }} />
    ) : null;
};

export default PrivateRoute;
