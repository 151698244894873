import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import prev from '../../../../assets/icons/prev-box.svg';
import next from '../../../../assets/icons/next-box.svg';
import Button from '../../../CustomButton/ButtonContainer';
import { getGenerateApiImageByName, getImage } from '../../../../unified/image_service';
import { setBoxId } from '../../../../unified/reducer';
import moment from 'moment';
import './AllFeedCard.scss';
import { convertISODate } from '../../../../utils/convertingFunction';

const AllFeedCard = (props: any) => {
  const {
    inbox, tag_element, card_data, like_container,
    isFromModalBox, activePage, wallUnifiedData, handleAddToWall, last
  }: any = props || {};
  const {
    reviewId,
    SlNo,
    profile_image,
    username,
    star_ratings,
    provider,
    comment,
    date,
  }: any = card_data || {};
  const dispatch = useDispatch();
  // const route_path = window.location.pathname;
  // const {workspaceSettings} = useSelector((state: any) => state?.workspaceManagementState);
  const query_string = window.location.search;
  return (
    <React.Fragment>
      <div className="feed-card-header">
        <img
          className="profile-pic"
          src={getGenerateApiImageByName(username)} // profile_image || 
          alt="avatar"
        />
        <div className="user-details">
          <div className="username-stars-rating-container">
            <div className="username">{username?.length < 15 ? username : (username?.substr(0, provider === "facebook" ? 12 : 9) + "...") }</div>
              {
                Number(star_ratings)
                ?   
                  <div className="stars-rating-container">
                    <div className="stars">
                      {
                        Array.from(Array(Math.floor(parseFloat(star_ratings))).keys()).map((_: any, index: number) => {
                        return (
                            <img
                                className=""
                                key={index}
                                src={getImage("star")}
                                alt="star"
                            />
                        );
                        })
                      } 
                    </div>
                    {/* <span className="stars">★★★★☆</span> */}
                    <div className="rating">{star_ratings}/5</div>
                  </div>
                : null
              }
          </div>
          <div className="tag-text">{tag_element || "reviewed WS"}</div>
        </div>
        <img className="provider-logo" src={getImage(provider)} alt="Google" />
      </div>

      <div className="feed-card-body">
        {
          isFromModalBox
          ? <p>{comment}</p>
          : <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (activePage !== "conversations") {
                  dispatch(setBoxId(SlNo));
                }
              }}
            >
              {comment?.length < 30 ? comment + (query_string?.includes("wall") ? "...Read More" : "") : comment?.substring(0, query_string?.includes("wall") ? 28 : 35) + "..." + (query_string?.includes("wall") ? "Read More" : "")}

            </p>
        }
      </div>

      {
        query_string?.includes("wall")
        ?
          <div className="feed-card-footer">
            {
              isFromModalBox
              ? <div className='prev-next-add-wall-container'>
                  <div className='prev-next-container'>
                    <div className='prev-next'
                      onClick={() => {
                        const prevIndex = inbox?.SlNo - 1;
                        console.log({ wallBoxId: prevIndex });
                          
                        if (prevIndex > 0) {
                          dispatch(setBoxId(prevIndex));
                        }
                      }}
                    >
                      <img className='prev' src={prev} alt="prev" />
                    </div>
                    <div className='prev-next'
                      onClick={() => {
                        const nextIndex = inbox?.SlNo + 1;
                        console.log({ wallBoxId: nextIndex });
                          
                        if (nextIndex <= last) {
                          dispatch(setBoxId(nextIndex));
                        }
                      }}
                    >
                      <img className='next' src={next} alt="next" />
                    </div>
                  </div>
                  <Button
                    classes='add-to-wall-btn'
                    onClick={() => {
                      if (!wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId) {
                        handleAddToWall(inbox);
                        // dispatch(setBoxId(null));
                      }
                    }}
                    disabled={wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId}
                  >
                    {wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId ? "Added" : "Add to Wall"}
                  </Button>
                </div>
              : <div className="prev-next-add-wall-container">
                  <div className='left-container'>
                    {
                      like_container || null
                    }
                  </div>
                  <button
                    className={`${(wallUnifiedData?.find((wd: any) => wd?.reviewId === reviewId) && activePage !== "conversations"
                      ? ""
                      : query_string?.includes("wall")) &&
                      "add-to-wall-btn-hover"
                      } add-to-wall-btn`}
                    type="button"
                    disabled={wallUnifiedData?.find((wd: any) => wd?.reviewId === reviewId) && activePage !== "conversations"}
                    onClick={() => {
                      if (activePage !== "conversations") {
                        handleAddToWall(inbox);
                      }
                    }}
                  >
                    {wallUnifiedData?.find(
                      (wd: any) => wd?.reviewId === reviewId
                    ) && activePage !== "conversations"
                      ? "Added"
                      : "Add to Wall"}
                  </button>
                </div>
            }
          </div>
          : <div className="feed-card-footer">
              {
                like_container
                ? <div className='left-container'>
                    {
                      like_container
                    }
                  </div>
                : null
              }
              <p className="date">{moment(convertISODate(date)).format("DD MMM YYYY, hh:mm:ss A")}</p>
            </div>
      }
    </React.Fragment>
  )
};

export default AllFeedCard;
