import React from "react";
// import { useNavigate } from "react-router-dom";

interface DashboardTeamCardProps {
    card?: any;
}

const DashboardTeamCard: React.FC<DashboardTeamCardProps> = ({ card }: any) => {
    // const navigate = useNavigate();
    // const handleRoute = (route: any) => {
    //     navigate(route);
    // }
    const getCardIcon = (color = "#1354EC") => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="16" height="16" viewBox="0 0 16 16"><g><ellipse cx="8" cy="8" rx="8" ry="8" fill={color} fillOpacity="1"/></g></svg>
        )
    }
    return (
        <React.Fragment>
            <div className='custom-card'>
                <div className='header-title'>
                    {/* <div style={{background: `${card?.color}`}} className='title-icon'></div> */}
                    {getCardIcon(card?.teamColor)}
                    <div>{card?.teamName}</div>
                </div>
                <div className='description'>
                    <div>{card?.members}</div>
                    <div>Members</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardTeamCard;