import React, { useEffect, useRef, useState } from 'react';
// import emptyBox from '../../../../assets/img/Empty.svg';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
// import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// // import star_half from '../../../../assets/icons/star-half-icon.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// import back_btn from '../../../../assets/icons/back.svg';
// import reply_icon from '../../../../assets/icons/reply-icon.svg';
// import edit_icon from '../../../../assets/icons/edit-icon.svg';
// import send_icon from '../../../../assets/icons/send-icon.svg';
// import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessMessage,
  // toggleIsInReplyingMode,
  toggleReplyingOn
} from '../../../../unified/reducer';
import ConversationHeaderSD from './ConversationHeaderSD';
import ConversationHeader from './ConversationHeader';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import ConversationCommonHeader from './ConversationComponents/ConversationCommonHeader';
import ConversationCommonBody from './ConversationComponents/ConversationCommonBody';
import ConversationCommonReply from './ConversationComponents/ConversationCommonReply';
const GoogleConversations = (props: any) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<any[]>([{}, {}]);
  const [isCountDownOn, setIsCountDownOn] = useState(false);
  const route_path = window?.location?.pathname;
  const query_string = window.location.search;
  const query_tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const {
    // conversationDetailsData,
    unifiedData,
    isReplyingOn, isInReplyingMode
  }: any = useSelector((state: RootState) => state?.unified);
  const {sender, conversation, handleReplaceConversation, getImage, handlePrevPath, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange} = props;
  const { tag, replyText } = conversation || {};
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  )?.workSpaceName || "";
  const {
    reviewedbyUserName, reviewerDisplayName,
    reviewerPhoteUrl, mentionedbyUserName,
    createdOn,
    reviewReplyComment, reviewComment, provider,
    starRating, ratingStars,
    // reviewReplyUpdatedon,
    // likeCount, locationName, recieverCompany
  } = conversation || {};
  // console.log({google_conversation: conversation, conversationDetailsData});
  useEffect(() => {
    console.log({ email_sender: sender });
  }, [sender]);
    
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Parameters<F>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // console.log({value: event.target.value});
    const value = event?.target?.value || "";
    setReplyText(value);
  };

  const debouncedTextChange = debounce(handleTextChange, 500);

  const {
    InboxAll_googleQandAConversation
  } = unifiedData || {};

  const all_details: any[] =
  (route_path?.includes('unified') && query_tag !== 'reviews')
  ? InboxAll_googleQandAConversation
  : [];
  
  const conversationDetailsData: any [] = all_details?.filter((item: any) => item?.QuestionId === conversation?.reviewId);

  console.log({conversationDetailsData});

  const [isForReplyingUser, _] = useState<any>()
  // Automatically scroll to the bottom of the conversation when a new message is added
  const scrollRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState<any>(false);

  // Check if the content is overflowing
  useEffect(() => {
    console.log({ client_height: scrollRef?.current?.clientHeight, scrollHeight: scrollRef.current.scrollHeight });
    if (scrollRef.current) {
      const isContentOverflowing = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
      setIsOverflowing(isContentOverflowing);
      
      // Scroll to the bottom only if content is overflowing
      if (isContentOverflowing) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [messages?.length, scrollRef?.current?.clientHeight]);
  
  return (
    <React.Fragment>
      <ConversationCommonHeader
        data_fields={{
          username_1: conversation?.reviewerDisplayName || "User Name",
          username_2: workspaceSettings?.workspaceName,
          user_profile_1: null, // conversation?.reviewerPhoteUrl, 
          user_profile_2: workspaceSettings?.logo,
          // star_ratings, 
          tag_text: conversation?.tag?.includes("googleQandA")
          ? ` asked a question to ${workspaceSettings?.workspaceName}`
          : ` reviewed ${workspaceSettings?.workspaceName}`,  // optional props fields
          action_buttons: null,
          // action_buttons: [{ text: "Close", },{ text: "Share",},{text: "Move"}], // optional props fields
          provider: conversation?.provider || "",
        }}
        handleParentCallback={(data: any) => console.log({ data })}
      />

      <div className="conversation-data">
        <div
          className="conversation-container"
          ref={scrollRef}
          style={{justifyContent: isOverflowing ||  messages?.length > 2 ? "flex-start" : "flex-end"}}
        >
        {
          messages.map((item: any, idx: any) => (
            idx === 0
              ? <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    // username: workspaceSettings?.workspaceName || "WS Name",
                    username: conversation?.tag?.includes("googleQandA") ? "You Answered" : "You Replied",
                    user_profile: workspaceSettings?.logo || getGenerateApiImageByName(workspaceSettings?.workspaceName),
                    star_ratings: 5, // optional props fields
                    inVisibleRatingNumber: true, // optional props fields
                    tag_text: null,  // optional props fields
                    date: item?.createdOn || new Date(),
                    message_subject: null, // optional props fields
                    message: item?.reviewComment,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={false}
                />
              : <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: item?.reviewerDisplayName || conversation?.reviewerDisplayName,
                    user_profile: null, // conversation?.reviewerPhoteUrl,
                    tag_text: conversation?.tag?.includes("googleQandA") ? `Asked a question to ${workspaceSettings?.workspaceName}` : null,  // optional props fields
                    star_ratings: conversation?.starRating || conversation?.ratingStars || 3, // optional props fields
                    date: item?.createdOn || conversation?.createdOn,
                    message_subject: null, // optional props fields
                    message: item?.reviewComment || conversation?.reviewComment,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={true}
                  handleReply={(data: any) => console.log({data})}//handleReply
                />
          ))
        }
        </div>
      </div>

      <ConversationCommonReply
        data_fields={null}
        handleParentCallback={(data: any) => console.log({data})}
        isReply={isReply}
        isCountDownOn={isCountDownOn}
        setReplyText={setReplyText}
        isForReplyingUser={true}
        textRef={textRef}
        sender={sender}
        showingLeftContent={false}
        showFileInputIcon={false} 
        showEmojiInputIcon={false}
        showLockIcon={true}
      />
      {/* <div className="conversation-section-container-lg h-100">
        <ConversationHeader
          header_title={
            tag?.includes("googleQandA")
            ? " asked a question to "
            : " reviewed "
          }
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" style={{flexDirection: "column"}} id="scrollableDiv">
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <img
                          className="user-avatar"
                          src={getGenerateApiImageByName((reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.substr(0, 2).toUpperCase())}
                          alt="avatar"
                      />
                  }
                  <div className="description">
                    <div
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <div className="d-flex user_name me-2 ">
                        {reviewerDisplayName || "User Name"}
                      </div>
                    </div>
                    {
                      starRating && (
                        <div className="rating-social-email">
                          <div>
                            {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                              return (
                                <img
                                  className="star"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                          </div>
                          <div className="rating">
                            {starRating || ratingStars}/5
                          </div>
                        </div>
                      )
                    }
                    
                    <p>{reviewComment}</p>
                  </div>
                </div>
                <p className="text-start mt-2 date">
                  {"Review"}{" "}
                  -{" "}
                  <span>
                    {
                      moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                    }
                  </span>
                </p>
              </div>
              {(reviewReplyComment) ? (
                  <div className="conversation-person-2">
                    <div className="details">
                      {
                        workspaceSettings?.logo
                        ? <img
                            className="user-avatar"
                            src={workspaceSettings?.logo || user_avatar_2}
                            alt="user_avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(workSpaceName)}
                              alt="avatar"
                          />
                      }
                      <span className="description">
                        <p>{reviewReplyComment}</p>
                      </span>
                    </div>
                    <p className="text-end mt-2 date">
                      {"Replied"} - {" "}
                      <span>
                        {
                          moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || "WS Name")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
              ) : conversationDetailsData?.length ? (
                  conversationDetailsData?.map((conversation: any, idx: number) => (
                    <div className="conversation-person-2" key={idx}>
                      <div className="details">
                        <img
                          className="user-avatar"
                          src={conversation?.AuthorPhotoUrl || user_avatar_2}
                          alt="user_avatar"
                        />
                        <span className="description">
                          <p>{conversation?.Answer}</p>
                        </span>
                      </div>
                      <p className="text-end mt-2 date">
                        {"Replied"} - {" "}
                        <span>
                          {
                            moment(conversation?.UpdatedTime).format("DD MMM YYYY, hh:mm:ss A") + " by " + (conversation?.AuthorDisplayName || "Author")
                            // moment(details?.requested_time).format('Y-m-d')
                          }
                        </span>
                      </p>
                    </div>
                  ))
                ) : null
              }
            </div>
          </Scrollbars>
        </div>
        <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            {
              sender
              ? `${sender} is typing...`
              : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
            }
          </p>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  defaultValue={replyText}
                  placeholder="Write here..."
                  onChange={debouncedTextChange}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                      handleReply();
                    } else {
                      setIsReply(true);
                      dispatch(toggleReplyingOn(true));
                      // dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {
                    // replyText && isReply
                    // ? "Save" : 
                    isReply
                    ? "Send"
                    : replyText
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}>
                {
                  sender
                  ? "You can not reply now. Editor is locked"
                  : "Click reply and start adding your reply."
                }
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* mobile device */}
      {/* <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
         
          <ConversationHeaderSD
            header_title={
              tag?.includes("googleQandA")
              ? " asked a question to "
              : " reviewed "
            }
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="conversation-body" style={{flexDirection: 'column'}} id="scrollableDiv">

                  <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(reviewerDisplayName)}
                              alt="avatar"
                          />
                      }
                      <div className="description">
                        <div
                          className={`d-flex align-items-center user-ratings-container`}
                        >
                          <div className="user_name me-2">
                            {reviewerDisplayName || "User Name"}
                          </div>
                        </div>
                        {
                          starRating && (
                            <div className="rating-social-email">
                              <div>
                                {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                                  return (
                                    <img
                                      className="star"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                              </div>
                              <div className="rating">
                                {starRating || ratingStars}/5
                              </div>
                            </div>
                          )
                        }
                        <p>{reviewComment}</p>
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      {"Review "} - {" "}
                      <span>
                        {
                          // new Date(createdOn).toDateString() +
                          moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                    {
                      reviewReplyComment ? (
                      // {reviewComment2 && (
                      <div className="conversation-person-2">
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar_2}
                                alt="user-2"
                              />
                            : <img
                                  className="user-avatar"
                                  src={getGenerateApiImageByName(workSpaceName)}
                                  alt="avatar"
                              />
                          }
                          <span className="description">
                            <p>{reviewReplyComment}</p>
                          </span>
                        </div>
                        <p className="text-end mt-2 date">
                          {"Replied"} -{" "}
                          <span>
                            {
                              moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || "WS Name")
                              // moment(details?.requested_time).format('Y-m-d')
                            }
                          </span>
                        </p>
                      </div>
                      ) : conversationDetailsData?.length ? (
                        conversationDetailsData?.map((conversation: any, idx: number) => (
                          <div className="conversation-person-2" key={idx}>
                            <div className="details">
                              <img
                                className="user-avatar"
                                src={conversation?.AuthorPhotoUrl || user_avatar_2}
                                alt="user_avatar"
                              />
                              <span className="description">
                                <p>{conversation?.Answer}</p>
                              </span>
                            </div>
                            <p className="text-end mt-2 date">
                              {"Replied"} - {" "}
                              <span>
                                {
                                  moment(conversation?.UpdatedTime).format("DD MMM YYYY, hh:mm:ss A") + " by " + (conversation?.AuthorDisplayName || "Author")
                                  // moment(details?.requested_time).format('Y-m-d')
                                }
                              </span>
                            </p>
                          </div>
                        ))
                      ) : null
                    }
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                  }
                </p>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      defaultValue={""}
                      placeholder="Write here..."
                      onChange={debouncedTextChange}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (!sender) {
                          if (isReply) {
                            handleReply();
                          } else {
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            // dispatch(toggleIsInReplyingMode(true));
                          }
                        }
                      }}
                      // type="button"
                      // disabled={sender ? true : false}
                    >
                      {replyText || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : replyText ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}
                  >
                      {
                        sender
                        ? "You can not reply now. Editor is locked"
                        : "Click reply and start adding your reply."
                      }
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}
    </React.Fragment>
  )
};

export default GoogleConversations;
