import React from "react";
import BotMessagesManagementComponent from "./messages-components/BotMessages";

// import UnPermission from "../pages/un-permission/UnPermission";
const BotMainManagementComponent: React.FC = () => {
    // const { global_message_type } = useSelector((state: RootState) => state?.unified);
    // const dispatch = useDispatch();
    // const route_path = window?.location?.pathname;
    // const query_string = window.location.search;
    // const history = useNavigate();
    
    // const {userPermissions} = useSelector((state: any) => state?.login);
    // const [isPermissible, setIsPermissible] = React.useState(true);
    // React.useEffect(() => {
    //     console.log({userPermissions});
    //     const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Messages") : null;
    //     if( permission?.value === 0){
    //         setIsPermissible(false);
    //     }
    // }, [userPermissions?.length]);
    

    // console.log({global_message_type, data});
    
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            {/* <RightBottomMessagesTabs /> */}
            <BotMessagesManagementComponent/>
        </React.Fragment>
    )
}

export default BotMainManagementComponent;

