import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../../../store';
// import { getImage } from '../../../unified/image_service';
import { DebounceInput } from 'react-debounce-input';
import { setOpenGlobalModal } from '../../../../components/ContentComponents/reducer';
import { setErrorMessage } from '../../../../unified/reducer';
import axios from 'axios';
import { api_url, api_version } from '../../../../utils/server';

const HostNameForm = ({ parentCallback }: any) => {
  const {
    // open_global_drawer,
    open_global_modal,
  }: any = useSelector((state: any) => state?.contentState);
  const { logged_in_user_secret_data }: any = useSelector((state: any) => state?.login);
  
  const dispatch = useDispatch();
  const [publishingHostName, setPublishingHostName] = useState<any>("");
  // const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;
  useEffect(() => { 
    setPublishingHostName("");
  }, [open_global_modal]);

  const handleCancel = () => {
    dispatch(setOpenGlobalModal(null));
    setPublishingHostName("");
  };

  const handlePublishFile = async() => { 
    try {
      if (!publishingHostName?.trim()) {
        dispatch(setErrorMessage("Enter host name"));
        return;
      }
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      const roleId = logged_in_user_secret_data?.roleId;
      if (!workspaceId || !clapupUserId) {
        return;
      }
      // const publishPayload: any = {
      //   // columnName: "HostName",
      //   columnName: "SubDomainName",
      //   columnValue: publishingHostName?.trim(),
      //   clapupId: clapupUserId,
      //   roleId: roleId,
      //   workSpaceId: workspaceId,
      // }

      const publishPayload: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        subdomainName: publishingHostName?.trim(),
      }
      
      // const { status } = await axios.post(`${api_url}/api/${api_version}/Clapup/UpdateWorkspaceElements`, 
      const { status } = await axios.post(`${api_url}/api/${api_version}/Clapup/UpdateWorkspaceSettings`, 
        publishPayload, {
        headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
        }
      });
      if (status === 200) {
        parentCallback(publishingHostName?.trim());
      }
    } catch (err: any) {
      console.log({ err });
      dispatch(setErrorMessage("Something is wrong"));
      parentCallback(publishingHostName?.trim());
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value?.toLowerCase()?.trim();
    if (value?.length > 100) {
      value = value.substring(0, 100);
    }
    // Use regex to allow only lowercase letters and numbers
    const validatedValue = value?.replace(/[^a-z0-9_]/g, '');
    setPublishingHostName(validatedValue);
  };

  return (
    <React.Fragment>
      <div className="hostname-publishing-form">
        <h3>Hostname for file publishing</h3>
    
        <div className="requirement-description">
          {"Hostname is required for publishing files to the internet with a publish option. Word limit is 20, and no special character or any spaces are allowed. The file are published with hostname.clapup.in as base url. Once enabled, you can ask support to add your business domain to publish file for publishing to hostname.your domain.com. "} 
        </div>
        
        <div className="form-item">
          <DebounceInput
            type="text" 
            placeholder={"eg: your company (microsoft)"}
            minLength={0}
            maxLength={100}
            debounceTimeout={10}
            value={publishingHostName}
            onChange={handleInputChange} 
          />
        </div>

        <div className="action-buttons">
          <div
            className="text-button"
            onClick={() => {
              handleCancel();
            }}
          >
              Cancel
          </div>
          <div
              className="text-button"
              onClick={() => handlePublishFile()}
          >
            Add
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HostNameForm;

