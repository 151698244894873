import React, { useState, useEffect } from 'react';
import { Table, Select, Switch } from 'antd';
import './UserTable.scss';
import { getImage } from '../../../../../unified/image_service';
import { getKeyValue } from '../../../../../utils/indexedDBManagementService';
import { useSelector, useDispatch } from 'react-redux';

const UserTable = ({ selectedRows, setSelectedRows, is_bordered = true, loading, table_data, roles, updateRole, updateStatus, deleteFromTable, table_columns, height, width, rowSelection_data}: any) => {
    // const [currentRows, setCurrentRows] = React.useState<any>([]);
    const [isAllRowSelected, setIsAllRowSelect] = useState(false);
    // const [logged_in_user_secret_data, setLoggedInUserData] = useState<any>(null);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);

    // useEffect(() => { 
    //     (async () => {
    //         if (!logged_in_user_secret_data) {
    //             const user_secret_data = await getKeyValue("logged_in_user_secret_data");
    //             // console.log({ user_secret_data });
    //             setLoggedInUserData(user_secret_data);
    //         }
    //     })()
    // }, [logged_in_user_secret_data]);
    const handleSelectRow = (selectedRowKey: any, flag: boolean) => {
        console.log({selectedRowKey, flag, selectedRows});
        if (flag) {
            const newSelectedRows = [...selectedRows, selectedRowKey];
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.filter((row: any) => row !== selectedRowKey);
            setSelectedRows(newSelectedRows);
        }
    }

    const handleSelectAllRow = (flag: boolean) => {
        // console.log({flag});
        setIsAllRowSelect(flag);
        if (flag) {
            const newSelectedRows = table_data.map((row: any) => row.userId);
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows([]);
        }
    }

    return (
        <React.Fragment>
            <table className="user-management-table">
                <thead>
                    <tr>
                        <th>
                            Name
                            {/* <div className='d-flex gap-2'>
                                <span className='check-btn'>
                                    <input 
                                        className='form-check-input' 
                                        checked={isAllRowSelected}
                                        onChange={(e) => {
                                            handleSelectAllRow(e.target.checked);
                                        }}
                                        type="checkbox" 
                                    />
                                </span>
                                <span className='mx-4 table-header-title'>Name</span>
                            </div> */}
                        </th>
                        <th className='text-center'>Role</th>
                        <th className='text-center'>Status</th>
                        <th className='text-center'>Disable</th>
                        <th className='text-center'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                {
                    (table_data?.length && roles?.length ? table_data : [])?.map((record: any, idx: number) => <tr key={idx}>
                        {/* <td>
                            <div className='d-flex gap-2'>
                                <span className='check-btn'>
                                    <input 
                                        checked={selectedRows?.includes(record?.userId)}
                                        onChange={(e) => {
                                            handleSelectRow(record?.userId, e.target.checked);
                                        }}
                                        className='form-check-input' 
                                        type="checkbox" 
                                    />
                                </span>
                                <span className='mx-4 table-body-title'>removable {record?.name}</span>
                            </div>
                        </td> */}
                        <td>{record?.email || "ex@gamil.com"}</td>
                        <td className='text-center'>
                            {
                                record?.role?.includes("Super")
                                ? <p className='super-admin'>Super Admin</p>
                                : <p className='super-admin'>{record?.role}</p>
                                // <Select
                                //     defaultValue={record?.roleId || null}
                                //     style={{
                                //         fontSize: 14,
                                //         width: 150,
                                //     }}
                                //     onChange={(value: any) => {
                                //         // const role_data = roles?.find((item: any) => (item?.roleName === value));
                                //         // console.log({ record, value });
                                //         if (!logged_in_user_secret_data?.wuid) {
                                //             updateRole({userId: record?.userId, roleId: value, workspaceId: logged_in_user_secret_data.wuid });
                                //         }
                                //     }}
                                //     placeholder="Select"
                                //     size='middle'
                                //     // allowClear
                                //     disabled={logged_in_user_secret_data?.roleName?.includes("Super") ? false : true}
                                //     // suffixIcon={record?.role?.includes("Super") ? false : <img src={arrow_icon} className='arrow-icon' alt='arrow-down' />}
                                // >
                                //     {
                                //         roles?.map((item: any, idx: number) => {
                                //             return (
                                //                 <Select.Option style={{textAlign: 'center'}} key={idx} value={item?.id}>{item?.roleName}</Select.Option>
                                //             )
                                //         })
                                //     }
                                // </Select>
                            }

                        </td>
                        <td className='text-center'>{record?.status || "N/A"}</td>
                        <td className='text-center'>
                            <Switch
                                // checkedChildren={<CheckOutlined />}
                                // unCheckedChildren={<CloseOutlined />}
                                size='small'
                                defaultChecked={record?.role === "Super Admin" ? true : record?.status === "Active" ? true : false}
                                onChange={(checked: any) => {
                                    console.log('checked', checked);
                                    updateStatus({ userId: record?.userId, status: checked ? "Active" : "Deactive", workspaceId: logged_in_user_secret_data?.wuid });
                                }}
                                disabled={
                                    // logged_in_user_secret_data?.cuid === record?.id ? false
                                    // : 
                                    logged_in_user_secret_data?.roleName?.includes("Super") ? false
                                    : true
                                }
                            />
                        </td>
                        <td className='text-center'>
                            {
                                record?.role === "Super Admin"
                                ? null
                                : <img 
                                    onClick={() => {
                                        deleteFromTable({userId: record?.userId, status: "Delete", workspaceId: logged_in_user_secret_data?.wuid});
                                    }}
                                    width={11} 
                                    src={getImage("delete-icon")}
                                    style={{cursor: 'pointer'}} 
                                />
                            }
                        </td>
                    </tr>
                    )
                }
                </tbody>
            </table>

            {/* Ant Table */}
            {/* <div className='lg-device user-management-table'>
                <Table
                    loading={loading}
                    // className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    bordered={false}
                    // rowKey={(record: any) => record?.userId}
                    // rowSelection={rowSelection_data || {
                    //     type: 'checkbox',
                    //     onChange: (selectedRowKeys: any, selectedRows: any) => {
                    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    //     },
                    // }}
                    // rowClassName={() => "rowClassName1"}
                    // scroll={{ x: width || 540 }}
                    // style={{height: height}}
                    scroll={{ x: width || 550, y: height || 380 }}
                />
            </div> */}
            {/* <div className='sm-device user-management-table'>
                <Table
                    loading={loading}
                    className="table-style"
                    columns={table_columns}
                    dataSource={table_data}
                    pagination={false}
                    bordered={false}
                    // rowKey={(record: any) => record?.userId}
                    // rowSelection={rowSelection_data || {
                    //     type: 'checkbox',
                    //     onChange: (selectedRowKeys: any, selectedRows: any) => {
                    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    //     },
                    // }}
                    // rowClassName={() => "rowClassName1"}
                    scroll={{ x: 1024 }}
                    // style={{height: height}}
                    // scroll={{ x: 1024 }}
                />
            </div> */}
        </React.Fragment>
    );
};

export default UserTable;