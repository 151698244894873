import React, { useEffect } from 'react';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import prev from '../../../../assets/icons/prev-box.svg';
// import next from '../../../../assets/icons/next-box.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../../../unified/reducer';
// import Button from '../../../button/button-container';
import { getGenerateApiImageByName, getImage } from '../../../../unified/image_service';
import './AllFeedCard.scss';
import { convertISODate } from '../../../../utils/convertingFunction';

const BotMessageCard = (props: any) => {
  const { inbox, handleSeeOtherBotConversation, type, activePage, last, isFromModalBox  } = props;
  const dispatch = useDispatch();
  const query_string = window.location.search;
  const status_tag = query_string?.includes("open") ? "open"
  : query_string?.includes("inProgress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("starred") ? "starred" 
  : "all";
  const path = query_string.replace('?', '')?.replaceAll('&', "=");
  const path_array = path.split("=");
  const [isOpenedDropdown, setIsOpenedDropdown] = React.useState(false);
  const [threads, setThreads] = React.useState<any>([]);
  // const [active_inbox_user, setActiveInboxUser] = React.useState<any>(null);
  // const [active_thread, setActiveInboxThread] = React.useState<any>(null);
  useEffect(() => {
    if (!threads?.length) {
    }
    if (!threads?.length) {
      if (status_tag === "all") { 
        setThreads(inbox?.userWiseChatDetailsSubs?.length ? inbox?.userWiseChatDetailsSubs : []);
      } else {
        const filtered_threads = inbox?.userWiseChatDetailsSubs?.filter((thread: any) => thread?.chatStatus === status_tag);
        // console.log({filtered_threads, status_tag});
        
        setThreads(filtered_threads);
      }
    }
  }, [isOpenedDropdown]);

  useEffect(() => {
    if (status_tag === "all") { 
      setThreads(inbox?.userWiseChatDetailsSubs?.length ? inbox?.userWiseChatDetailsSubs : []);
    } else {
      const filtered_threads = inbox?.userWiseChatDetailsSubs?.filter((thread: any) => thread?.chatStatus === status_tag);
      console.log({filtered_threads, status_tag});
      setThreads(filtered_threads);
    }
    
  }, [query_string])

  useEffect(() => {
    if (threads?.length) {
      if (path_array?.length > 5) {
        const [_0, _1, _2, conversation_id, _4, user] = path_array;
        const active_user = user;
        if (active_user) {
          // setActiveInboxUser(active_user);
          const active_thread = threads?.find((thread: any) => thread?.ticketNo === conversation_id);
          console.log({ active_thread, active_user, inbox});
          if (active_thread) {
            // setActiveInboxThread(active_thread);
            setIsOpenedDropdown(true);
          }
          
        }
      }
    }
  }, [threads?.length]);
  const first_thread: string = (threads?.length && threads[0]?.ticketNo) && ((threads[0]?.ticketNo)?.toString() || new Date().toISOString());
  const first_thread_by_tag: any = threads?.length ? threads[0] : {};
  return (
    <React.Fragment>
      {
        threads?.length
        ?
        <div className='feeds-card'>
          <div className={`feed-card-hover ${query_string?.includes(inbox?.userName) ? "feed-card-active" : ""}`}
            style={{cursor: 'pointer'}}
            onClick={() => {
              if (threads?.length) {
                const first_thread: any = threads[0];
                  const seeing_conversation_object: any = {
                    userName: inbox?.userName,
                    userEmail: inbox?.userEmail,
                    ...first_thread,
                  };
                  console.log({ seeing_conversation_object });
                  if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                    return;
                  }
                  handleSeeOtherBotConversation(seeing_conversation_object);
                // setActiveInboxUser(inbox?.userName);
                // setActiveInboxThread(threads[0]);
              } else {
                dispatch(setErrorMessage("No conversations available"));
              }
            }}
          >
            <div className="feed-card-header">
              <img
                className="profile-pic"
                src={inbox?.reviewerPhoteUrl || getGenerateApiImageByName(inbox?.userName)} // profile_image || 
                alt="avatar"
              />
              <div className="user-details">
                <div className="username-stars-rating-container">
                  <div className="username">{inbox?.userName?.length < 18 ? inbox?.userName : (inbox?.userName?.substr(0, 15) + "...") }</div>
                </div>
                <div className="tag-date">
                  { 
                    first_thread_by_tag && first_thread_by_tag?.createdOn
                    ? moment(first_thread_by_tag?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
                    : moment(convertISODate(new Date())).format("DD MMM YYYY, hh:mm:ss A")
                  }
                </div>
              </div>
              <img className="provider-logo" src={getImage(type)} alt="provider-icon" />
            </div>

            <div className="feed-card-body">
              <p style={{ cursor: 'pointer', color: (first_thread_by_tag && first_thread_by_tag?.color ) || "#000000"}}>
                {
                  first_thread_by_tag && first_thread_by_tag?.lastMessage
                  ? first_thread_by_tag?.lastMessage?.length < 31 ? first_thread_by_tag?.lastMessage : first_thread_by_tag?.lastMessage?.substring(0, 37) + "..."
                  : null
                }
              </p>
              {/* {
                isFromModalBox
                ? <p>{first_thread_by_tag?.lastMessage}</p>
                    :
              } */}
            </div>
            
            <div className="feed-card-footer">
              <div className="footer-content">
                <div className="left-emoji">
                  <div className="left-body-emoji">
                    <img
                      src={getImage("exclamation_icon")}
                      alt="exclamation"
                    />
                    {
                      threads[0]?.chatStatus === "closed"
                      ? <img src={getImage("closed-status")} alt="status-icon"/>
                      : threads[0]?.chatStatus === "dispatched"
                      ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                      : threads[0]?.chatStatus === "toDispatched"
                      ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                      : threads[0]?.chatStatus === "inProgress"
                      ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                      : threads[0]?.chatStatus === "reportedSpam"
                      ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                      : threads[0]?.chatStatus === "open"
                      ? <img src={getImage("opened-status")} alt="status-icon"/>
                      : <img src={getImage("opened-status")} alt="status-icon"/>
                      // : null
                    }
                    {/* {
                      !isOpenedDropdown
                          ?
                      : null
                    } */}
                    <div>{"Bot"}</div>
                  </div>
                </div>
                
                <div className='right-side-content'>
                  {/* {
                    inbox?.userName === whats_app_sender_state?.sender_inbox_userName
                    ? <img src={getImage("thread_lock_icon")} alt="thread-lock" />
                    // : threads?.length && threads[0]?.chatStatus === "closed"
                    // ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                    : null
                  } */}
                  {
                    threads?.length > 1 && !isOpenedDropdown
                    ? <img
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setIsOpenedDropdown(true);
                        }}
                        style={{ cursor: 'pointer' }}
                        src={getImage("dropdown_inactive")}
                        alt="dropdown"
                      />
                    : threads?.length > 1 && isOpenedDropdown
                    ? <img
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setIsOpenedDropdown(false);
                        }}
                        style={{ cursor: 'pointer' }}
                        src={getImage("dropdown_active")}
                        alt="dropdown"
                      />
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
          {
            isOpenedDropdown
            ? 
            <div className='treads'>
              {
                // threads?.map((thread: any, idx: number) => (
                [{}, {}, {}]?.map((thread: any, idx: number) => (
                  // <div key={idx} className={`tread-container feed-card-hover feed-card-active`}>
                  <div key ={idx} className = {`tread-container feed-card-hover ${query_string?.includes(thread?.ticketNo) ? "feed-card-active" : ""} ${idx === 0 ? "d-none" : ""}`}
                    onClick={() => {
                      const seeing_conversation_object = {
                        userName: inbox?.userName,
                        userEmail: inbox?.userEmail,
                        ...thread,
                      };
                      console.log({ seeing_conversation_object });
                      if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                        return;
                      }
                      handleSeeOtherBotConversation(seeing_conversation_object);
                      // setActiveInboxUser(inbox?.userName);
                      // setActiveInboxThread(thread);
                    }}
                  >
                    <div className="feed-card-header">
                      <img
                        className="profile-pic"
                        src={inbox?.reviewerPhoteUrl || getGenerateApiImageByName(inbox?.userName)} // profile_image || 
                        alt="avatar"
                      />
                      <div className="user-details">
                        <div className="username-stars-rating-container">
                          <div className="username">{!inbox?.userName ? "User Name" : inbox?.userName?.length < 18 ? inbox?.userName : (inbox?.userName?.substr(0, 15) + "...") }</div>
                        </div>
                        <div className="tag-date">
                          { 
                            thread?.createdOn
                            ? moment(convertISODate(thread?.createdOn)).format("DD MMM YYYY, hh:mm:ss A")
                            : moment(convertISODate(new Date())).format("DD MMM YYYY, hh:mm:ss A")
                          }
                        </div>
                      </div>
                      <img className="provider-logo" src={getImage(type)} alt="provider-icon" />
                    </div>

                    <div className="feed-card-body">
                      <p style={{ cursor: 'pointer', color: (thread?.color ) || "#000000"}}>
                        {
                          thread?.lastMessage
                          ? thread?.lastMessage?.length < 31 ? thread?.lastMessage : thread?.lastMessage?.substring(0, 37) + "..."
                          : "As the brand, so is the service. Great..."
                        }
                      </p>
                      {/* {
                        isFromModalBox
                        ? <p>{first_thread_by_tag?.lastMessage}</p>
                            :
                      } */}
                    </div>
                    

                    <div className="feed-card-footer">
                      <div className="footer-content">
                        <div className="left-emoji">
                          <div className="left-body-emoji">
                            <img
                              src={getImage("exclamation_icon")}
                              alt="exclamation"
                            />
                            
                            {
                              thread?.chatStatus === "closed"
                              ? <img src={getImage("closed-status")} alt="status-icon"/>
                              : thread?.chatStatus === "dispatched"
                              ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                              : thread?.chatStatus === "toDispatched"
                              ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                              : thread?.chatStatus === "inProgress"
                              ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                              : thread?.chatStatus === "reportedSpam"
                              ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                              : thread?.chatStatus === "open"
                              ? <img src={getImage("opened-status")} alt="status-icon"/>
                              : <img src={getImage("opened-status")} alt="status-icon"/>
                              // : null
                            }
                            {/* {
                              !isOpenedDropdown
                                ?
                              : null
                            } */}
                            <div>
                              {"Unassigned Thread Tag"}
                            </div>
                          </div>
                        </div>
                        
                        <div className='right-side-content'>
                          {/* {
                            inbox?.userName === whats_app_sender_state?.sender_inbox_userName
                            ? <img src={getImage("thread_lock_icon")} alt="thread-lock" />
                            : thread?.chatStatus === "closed"
                            ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                            : null
                          } */}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            : null
          }
        </div>
        : null
      }
      {/* {
        threads?.length
        ? 
          <div className='feeds-card'>
            <div className={`feed-card-hover ${query_string?.includes(first_thread) ? "feed-card-active" : ""}`}>
              <div className="feeds-card-header d-flex justify-content-between align-items-start"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (threads?.length) {
                    const first_thread: any = threads[0];
                    const seeing_conversation_object: any = {
                      userName: inbox?.userName,
                      userEmail: inbox?.userEmail,
                      ...first_thread,
                    };
                    handleSeeOtherBotConversation(seeing_conversation_object);
                  } else {
                    dispatch(setErrorMessage("No conversations available"));
                  }
                }}
              >
                {
                  inbox?.reviewerPhoteUrl || inbox?.imageUrl
                  ? <img
                      className="user-avatar"
                      src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
                      alt="avatar"
                    />
                    :
                    <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(inbox?.userName)}
                      alt="avatar"
                    />
                }
                <div
                  className="feeds-card-header-content d-flex justify-content-between align-items-start"
                  style={{ width: "100%" }}
                >
                  <div>
                    <div className="name-rating d-flex m-0 align-items-center">
                      <h6 className="user-name">
                        {inbox?.userName?.substr(0, 10) || "Client Name"}
                      </h6>
                      
                    </div>
                    <p className="text-start date">
                      { 
                        first_thread_by_tag && first_thread_by_tag?.createdOn
                        ? moment(first_thread_by_tag?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
                        : null
                      }
                    </p>
                    {
                      isFromModalBox
                      ? <p
                          className="description mb-2"
                        >
                          {
                            first_thread_by_tag && first_thread_by_tag?.lastMessage
                            ? first_thread_by_tag?.lastMessage?.length < 31 ? first_thread_by_tag?.lastMessage : first_thread_by_tag?.lastMessage?.substring(0, 27) + "..."
                            : null
                          }
                        </p>
                      : <>
                        <p
                          className="text-start description mb-2 normal-description"
                          // onClick={() => {
                          //   if (activePage !== "conversations") {
                          //     dispatch(setBoxId(inbox?.SlNo));
                          //   }
                          // }}
                          style={{cursor: 'pointer'}}
                        >
                          {
                            first_thread_by_tag && first_thread_by_tag?.lastMessage
                            ? first_thread_by_tag?.lastMessage?.length < 31 ? first_thread_by_tag?.lastMessage : first_thread_by_tag?.lastMessage?.substring(0, 27) + "..."
                            : null
                          }
                        </p>
                        <p
                          className="text-start description mb-2 lg-description"
                          style={{ cursor: 'pointer' }}
                          // onClick={() => {
                          //   if (activePage !== "conversations"){
                          //     dispatch(setBoxId(inbox?.SlNo));
                          //   }
                          // }}
                        >
                          {
                            first_thread_by_tag && first_thread_by_tag?.lastMessage
                            ? first_thread_by_tag?.lastMessage?.length < 31 ? first_thread_by_tag?.lastMessage : first_thread_by_tag?.lastMessage?.substring(0, 27) + "..."
                            : null
                          }
                        </p>
                      </>
                    }
                  </div>

                  <img
                    className='provider-icon'
                    src={getImage(type)}
                    alt="provider"
                  />
                </div>
              </div>
              <div className="feeds-card-footer w-100" style={{padding: "1.38% 3.5%"}}>
                <div className="footer-content w-100">
                  <div className="left-emoji">
                    <img
                      className='exclamation-icon'
                      src={getImage("exclamation_icon")}
                      alt="exclamation"
                    />
                    <div className="left-body-emoji">
                      {
                        !isOpenedDropdown
                        ? <img
                            className='exclamation-icon'
                            src={getImage("bot_smile_icon")}
                            alt="smile"
                          />
                        : null
                      }
                      <span className="d-block client">
                        {first_thread_by_tag?.chatBotName}
                      </span>
                    </div>
                  </div>
                  {
                    !isOpenedDropdown
                    ? <div className='d-flex align-items-center' style={{gap: '10px'}}>
                        <img src={getImage("tik_right_icon")} alt="tik-right" />
                        <img
                          onClick={() => setIsOpenedDropdown(true)}
                          style={{ cursor: 'pointer' }}
                          src={getImage("dropdown_inactive")}
                          alt="dropdown"
                        />
                      </div>
                    : <img
                        onClick={() => setIsOpenedDropdown(false)}
                        style={{ cursor: 'pointer' }}
                        src={getImage("dropdown_active")}
                        alt="dropdown"
                      />
                  }
                </div>
              </div>
            </div>
            {
              isOpenedDropdown
              ? 
              <div className='treads'>
                {
                  threads?.map((thread: any, idx: number) => (
                    // <div key={idx} className={`tread-container feed-card-hover feed-card-active`}>
                    <div key={idx} className={`tread-container feed-card-hover ${query_string?.includes(thread?.ticketNo) ? "feed-card-active" : ""} ${idx === 0 ? "d-none" : ""}`}
                      onClick={() => {
                        const seeing_conversation_object = {
                          userName: inbox?.userName,
                          userEmail: inbox?.userEmail,
                          ...thread,
                        };
                        handleSeeOtherBotConversation(seeing_conversation_object);
                        // setActiveInboxUser(inbox?.userName);
                        // setActiveInboxThread(thread);
                      }}
                    >
                      <div className="feeds-card-header d-flex justify-content-between align-items-start"
                        style={{cursor: 'pointer'}}
                      >
                        {
                          inbox?.reviewerPhoteUrl || inbox?.imageUrl
                          ? <img
                              className="user-avatar"
                              src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
                              alt="avatar"
                            />
                          :
                            <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(inbox?.userName)}
                              alt="avatar"
                            />
                        }
                        <div
                          className="feeds-card-header-content d-flex justify-content-between align-items-start"
                          style={{ width: "100%" }}
                        >
                          <div>
                            <div className="name-rating d-flex m-0 align-items-center">
                              <h6 className="user-name">
                                {inbox?.userName?.substr(0, 10) || "Client Name"}
                              </h6>
                              
                            </div>
                            <p className="text-start date">
                              { 
                                thread?.createdOn
                                ? moment(thread?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
                                : null
                              }

                            </p>
                            {
                              isFromModalBox
                              ? <p
                                  className="description mb-2"
                                >
                                  {
                                    thread?.lastMessage
                                    ? thread?.lastMessage?.length < 31 ? thread?.lastMessage : thread?.lastMessage?.substring(0, 27) + "..."
                                    : null
                                  }
                                </p>
                              : <>
                                <p
                                  className="text-start description mb-2 normal-description"
                                  // onClick={() => {
                                  //   if (activePage !== "conversations") {
                                  //     dispatch(setBoxId(inbox?.SlNo));
                                  //   }
                                  // }}
                                  style={{cursor: 'pointer'}}
                                >
                                  {
                                    thread?.lastMessage
                                    ? thread?.lastMessage?.length < 31 ? thread?.lastMessage : thread?.lastMessage?.substring(0, 27) + "..."
                                    : null
                                  }
                                </p>
                                <p
                                  className="text-start description mb-2 lg-description"
                                  style={{ cursor: 'pointer' }}
                                  // onClick={() => {
                                  //   if (activePage !== "conversations"){
                                  //     dispatch(setBoxId(inbox?.SlNo));
                                  //   }
                                  // }}
                                >
                                  {
                                    thread?.lastMessage
                                    ? thread?.lastMessage?.length < 31 ? thread?.lastMessage : thread?.lastMessage?.substring(0, 27) + "..."
                                    : null
                                  }
                                </p>
                              </>
                            }
                          </div>
        
                          <img
                            className='provider-icon'
                            src={getImage(type)}
                            alt="provider"
                          />
                        </div>
                      </div>
                      <div className="feeds-card-footer w-100" style={{padding: "1.38% 3.5%"}}>
                        <div className="footer-content w-100">
                          <div className="left-emoji">
                            <img
                              className='exclamation-icon'
                              src={getImage("bot_smile_icon")}
                              alt="smile"
                            />
                          </div>
                          <img src={getImage("tik_right_icon")} alt="tik-right" />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
              : null
            }
          </div>
        : null
      } */}
    </React.Fragment>
  )
}

export default BotMessageCard;
