import React from 'react';
import './SkeletonLoader.scss';

const MultipleSkeletonLoaders = ({ width = "550px", height = "400px" }) => {
  return (
    <React.Fragment>
      <div style={{ width: width, height: height }} className="skeleton-loader-container">
        <div className="multiple-skeleton-loader">
          <div className="skeleton-avatar"></div>
          <div className="text-skeleton-container">
            <div className="left-body">
              <div className="skeleton-text"></div>
              <div className="skeleton-subtext"></div>
            </div>
            <div className="skeleton-subtext"></div>
          </div>
        </div>
        <div className="multiple-skeleton-loader">
          <div className="skeleton-avatar"></div>
          <div className="text-skeleton-container">
            <div className="left-body">
              <div className="skeleton-text"></div>
              <div className="skeleton-subtext"></div>
            </div>
            <div className="skeleton-subtext"></div>
          </div>
        </div>
        <div className="multiple-skeleton-loader">
          <div className="skeleton-avatar"></div>
          <div className="text-skeleton-container">
            <div className="left-body">
              <div className="skeleton-text"></div>
              <div className="skeleton-subtext"></div>
            </div>
            <div className="skeleton-subtext"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultipleSkeletonLoaders;

