import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router-dom";
import { setInboxMessageFilteringKeyByTeam, setInboxSharedWithMeMessagesPayload, triggerGetInboxSharedWithMeMessages } from "../reducer";
// import TopTabs from "../../components/layout/TopTabs";
// import RightBottomMessagesTabs from "../../components/layout/RightBottomMessagesTabs";
import UnPermission from "../../pages/un-permission/UnPermission";
import SharedWithMeMessageFeeds from "../../components/Feeds/feed-messages/feeds_shared_with_me_messages";

const SharedWithMeMessages: React.FC = () => {
    // const history = useNavigate();
    const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    const query_string = window.location.search;
    const { params } = useParams<{ params: string }>();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const { SharedWithMeInboxData: data, team_filtering_key, searchText, searchStartDate, searchEndDate, searchFilterStatus } = useSelector((state: RootState) => state?.unified);
    const tag = query_string?.includes("open") ? "open"
        : query_string?.includes("inProgress") ? "inProgress"
        : query_string?.includes("closed") ? "closed" 
        : query_string?.includes("starred") ? "starred" 
        : "all";
    
    const {
        allMails, closedMails,
        openMails, inProgressMails,
        // statDetails, msgDetails: chatDetails,
        }: any = data || {};
        
        
    const DataObject:any = tag === "all"
        ? allMails || []
        : tag === "closed"
        ? closedMails
        : tag === "inProgress"
        ? inProgressMails
        : tag === "starred"
        ? []
        : tag === "open"
        ? openMails
        // : tag ? []
        : allMails || [];
        
    useEffect(() => {
        // console.log('Path useEffect ===============> ', {SharedWithMeInboxData: data});
        if (!data || !DataObject?.emailDetails?.length) {
            // handleTriggerSharedWithMeMessages(triggerGetInboxSharedWithMeMessages, true);
            handleTriggerSharedWithMeMessages(setInboxSharedWithMeMessagesPayload, true);
        }
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
    }, [
        // route_path,
        params
        // searchText, searchStartDate, searchEndDate, searchFilterStatus
    ]);

    const messagePayload:any = {
        teamId: team_filtering_key || "all",
        workspaceId: logged_in_user_secret_data?.wuid,
        clapupUserId: logged_in_user_secret_data?.cuid,
        createdOn: new Date().toISOString().replace('Z', ''),
        // search
        // searchText,
        // fromDate: searchStartDate?.replace('Z', ''), 
        // toDate: searchEndDate?.replace('Z', ''), 
        // readStatus: searchFilterStatus,
    }

    // filtering by team
    useEffect(() => {
        if (team_filtering_key) {
            handleTriggerSharedWithMeMessages(setInboxSharedWithMeMessagesPayload, { ...messagePayload, teamId: team_filtering_key });
            // setTimeout(() => dispatch(setInboxMessageFilteringKeyByTeam(null)), 2000);
        }
        // else {
        //     if (data) {
        //         // handleTriggerAllMessages(triggerGetInboxAllMessages, true);
        //         handleTriggerSharedWithMeMessages(setInboxSharedWithMeMessagesPayload, {});
        //     }
        // }
    }, [team_filtering_key,]);

    const handleTriggerSharedWithMeMessages = (callBack = triggerGetInboxSharedWithMeMessages, _?: any, line?: any) => {
        // console.log("====IsFirstTime===> ", { dataObj: _, searchText, searchStartDate });
                
        const dataObj = {
            workspaceId: logged_in_user_secret_data?.wuid,
            clapupUserId: logged_in_user_secret_data?.cuid,
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isForSearched: (searchText || searchStartDate || searchEndDate) ? true : false,
            ..._,
            isFirstTime: _ ? true : false,
            
        };

        console.log("======Next line=====> ", { dataObj, searchText, searchStartDate });

        if(tag === "open"){
            messagePayload.tag = "open";
            // messagePayload.inboxType = "inboxReviews";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else if(tag === "inProgress"){
            messagePayload.tag = "inProgress";
            // messagePayload.inboxType = "QandA";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else if(tag === "closed"){
            messagePayload.tag = "closed";
            // messagePayload.inboxType = "comments";
            const payload = {...messagePayload, ...dataObj,}
            dispatch(callBack(payload));
        } else if( tag === "starred"){
            messagePayload.tag = "starred";
            // messagePayload.inboxType = "mentions";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else {
            messagePayload.tag = "all";
            // messagePayload.inboxType = "customerFeedback";
            const payload = {...messagePayload, ...dataObj,}
            dispatch(callBack(payload));
        }
    }

    const handleSearchData = (key?: any, value?: any) => {
        let dataObj: any = {
            workspaceId: logged_in_user_secret_data?.wuid,
            clapupUserId: logged_in_user_secret_data?.cuid,
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isFirstTime: true,
        };
        console.log("key, value ======> ", { key, value, dataObj });
        
        if(key === "readStatus"){
            dataObj.readStatus = value;
            dataObj.isForSearched = value ? true : false;
            console.log({dataObj});
            // dispatch({ type: "FILTER_BY_READ_STATUS", payload: value });
            // handleTriggerSharedWithMeMessages(triggerGetInboxSharedWithMeMessages, dataObj);
        } else if (key === "searchText") {
            if (!value) {
                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
                return;
            }
            dataObj.searchText = value;
            dataObj.isForSearched = value ? true : false;
            console.log({dataObj});
            dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: value });
            handleTriggerSharedWithMeMessages(triggerGetInboxSharedWithMeMessages, dataObj);
        } else {
            if (!value?.fromDate || !value?.toDate) {
                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                return;
            }
            dataObj = {
                ...dataObj,
                isForSearched: (value?.fromDate && value?.toDate) ? true : false,
                ...value
            };
            dispatch({ type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value });
            console.log({dataObj});
            handleTriggerSharedWithMeMessages(triggerGetInboxSharedWithMeMessages, dataObj);
        }
    };

    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "whatsApp") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);

    // console.log({ data});
    
    return (
        <React.Fragment>
            {
                isPermissible
                    ?
                    <SharedWithMeMessageFeeds
                        handleTriggerData={handleTriggerSharedWithMeMessages}
                        handleSearchData={handleSearchData}
                        triggerData={triggerGetInboxSharedWithMeMessages}
                        // feedsContent={data}
                        type={'share-with-me-messages'}
                    />
                    : <UnPermission/>
            }
        </React.Fragment>
    )
}

export default SharedWithMeMessages;

