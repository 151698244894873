import React, {useState, useEffect} from "react";
import {Select} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";
import { setEditableAgent } from "../agentReducer";
import { toggleShowMobileSettingsNavBar } from "../../../../signup-login/loginReducer";
import { getBotDetailsSettings, updateBotDetailsSettings } from "../agentReducer";
import { setErrorMessage, setSuccessMessage } from "../../../../unified/reducer";
import { updateBotFileData, uploadBotFileData } from "../agentSaga";
import { api_url, api_version } from "../../../../utils/server";
import axios from "axios";

const AgentSettings: React.FC<any> = ({aiChatBotId}: any) => {
    const dispatch = useDispatch();
    const { logged_in_user_secret_data, is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    // state
    const [agentName, setAgentName] = useState<any>(null);
    const [agentHeadline, setAgentHeadline] = useState<any>(null);
    const [agentDescription, setAgentDescription] = useState<any>(null);
    const [agentGreetingMessage, setAgentGreetingMessage] = useState<any>(null);
    const [primaryColor, setPrimaryColor] = useState<any>(null);
    const [secondaryColor, setSecondaryColor] = useState<any>(null);
    // const [botFile, setBotFile] = useState<any>("");
    // const [botFileURL, setBotFileURL] = useState<any>("");
    const [knowledgeBaseFileName, setKnowledgeBaseFileName] = useState<any>("filename.pdf");
    const [isEnableOnBioPage, setIsEnableOnBioPage] = useState<any>(false);
    const { all_bot_details } = useSelector((state: any) => state?.agentState);
    const { success_message } = useSelector((state: any) => state?.unified);
    const [clapUpBotDetails, setClapUpBotDetails] = useState<any>(null);
    const [activateField, setActivateField] = useState<any>("");
    
    useEffect(() => {
        if (all_bot_details?.length) {
            console.log({ all_bot_details });
            
            // const clapUpBotDetails = all_bot_details?.find((item: any) => item?.aiChatBotName);
            const clapUpBotDetails = all_bot_details?.find((details: any) => details?.aiChatBotId === aiChatBotId);
            setClapUpBotDetails(clapUpBotDetails);
        }
        // else {
        //     dispatch(getBotDetailsSettings());
        // }
    }, [all_bot_details?.length, success_message]);
    
    useEffect(() => {
        console.log({ clapUpBotDetails });
        
        if (clapUpBotDetails !== null) {
            setAgentName(clapUpBotDetails?.aiChatBotName);
            setAgentHeadline(clapUpBotDetails?.aiChatBotHeadLine);
            setAgentDescription(clapUpBotDetails?.aiChatBotDescription);
            setAgentGreetingMessage(clapUpBotDetails?.greetingMsg);
            setPrimaryColor(clapUpBotDetails?.primaryColor);
            setSecondaryColor(clapUpBotDetails?.secondaryColor);
            setKnowledgeBaseFileName(clapUpBotDetails?.knowledgeBase);
            setIsEnableOnBioPage(Number(clapUpBotDetails?.isEnabledInBioPage) ? true : false);
        }
    }, [
        clapUpBotDetails?.aiChatBotName, clapUpBotDetails?.aiChatBotHeadLine,
        clapUpBotDetails?.aiChatBotDescription, clapUpBotDetails?.greetingMsg,
        clapUpBotDetails?.primaryColor, clapUpBotDetails?.secondaryColor,
        clapUpBotDetails?.knowledgeBase, clapUpBotDetails?.isEnabledInBioPage
    ]);

    const handleUpdateAgentDetailsSettingField = async (fieldName: any, fieldValue: any, setState?: any) => {
        // console.log({columnValue});
        try {
            if(!fieldValue && setState){
                return setState && setState("error");
            }
            const dataObject: any = {
                // clapupId: logged_in_user_secret_data?.cuid,
                // workSpaceId: logged_in_user_secret_data?.wuid,
                // roleId: logged_in_user_secret_data?.roleId,
                clapupUserId: logged_in_user_secret_data?.cuid || null,
                aiChatBotId: clapUpBotDetails?.aiChatBotId,
                fieldName: fieldName,
                fieldValue: fieldValue,
                // linktype: "BiopageclapUpBotDetails",
            }
            console.log({dataObject});
            // dispatch(updateBotDetailsSettings(dataObject));
            const url = api_url + `/api/${api_version}/AIChatBot/UpdateAIChatBotSettings`;
            const { status }: any = await axios.post(
                url,
                dataObject,
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // const status = 200;
            if (status === 200) {
                const accessed_data = {
                    clapupUserId: logged_in_user_secret_data?.cuid,
                    workspaceId: logged_in_user_secret_data?.wuid,
                    roleId: logged_in_user_secret_data?.roleId,
                }
                dispatch(getBotDetailsSettings("From Agent", accessed_data));
                setTimeout(() => {
                    dispatch(setSuccessMessage("Saved Successfully"));
                    setActivateField("");
                }, 2000);
            }
        } catch (err: any) {
            console.log({ err });
            dispatch(setErrorMessage("Server Error"));
        }

    }

    const handleAgentKnowledgeBaseFileSubmit = async (botFileData?: any) => {
        if(!botFileData){
            return;
        }
        const {
            aiChatBotId, aiChatBotName,
            aiChatBotHeadLine, aiChatBotDescription,
            greetingMsg, primaryColor, secondaryColor,
            isEnabledInBioPage, contentId, iFrameUrl
        } = clapUpBotDetails || {};
        const insert_object: any = {
            aiChatBotId: aiChatBotId || "",
            aiChatBotName: aiChatBotName || "",
            aiChatBotHeadLine: aiChatBotHeadLine || "",
            aiChatBotDescription: aiChatBotDescription || "",
            greetingMsg: greetingMsg || "",
            primaryColor: primaryColor || "",
            secondaryColor: secondaryColor || "",
            isEnabledInBioPage: isEnabledInBioPage ? "true" : "false",
            contentId: contentId || "",
            iFrameUrl: iFrameUrl || "",
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
        }
        console.log({ insert_object });
        
        const { data, success, message } = clapUpBotDetails?.contentId
            ?   await updateBotFileData({
                    contentFile: botFileData, contentType: "AIChatBotFiles",
                    contentId: clapUpBotDetails?.contentId,
                    uniqueNo: clapUpBotDetails?.uniqueNo,
                    aiChatBotId: clapUpBotDetails?.aiChatBotId
                })
            :   await uploadBotFileData({
                    contentFile: botFileData,
                    contentType: "AIChatBotFiles",
                    aiChatBotId: clapUpBotDetails?.aiChatBotId
                }, insert_object);
        console.log("botFile-response ===> ", {data, success, message});
        if (success) {
            const accessed_data = {
                clapupUserId: logged_in_user_secret_data?.cuid,
                workspaceId: logged_in_user_secret_data?.wuid,
                roleId: logged_in_user_secret_data?.roleId,
            }
            dispatch(getBotDetailsSettings(null, accessed_data));
            setTimeout(() => {
                dispatch(setSuccessMessage("File Saved"));
            }, 2000);
        } else {
            dispatch(setErrorMessage("Server Error"));
        }
    }

    const color_values = {
        HEX: ['#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#B42318', '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#B42318',],
        HSL: ['hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(4, 76%, 40%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(4, 76%, 40%)',],
        RGB: ["rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(180, 35, 24)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(180, 35, 24)",],
    };

    const handleUpdateActivateField = () => {
        if(!activateField){
            return;
        }
        
        // activateField === "aiChatBotName" && handleUpdateAgentDetailsSettingField("aiChatBotName", agentName);
        // activateField === "aIChatBotHeadLine" && handleUpdateAgentDetailsSettingField("aIChatBotHeadLine", agentHeadline);
        // activateField === "aIChatBotDescription" && handleUpdateAgentDetailsSettingField("aIChatBotDescription", agentDescription);
        // activateField === "greetingMsg" && handleUpdateAgentDetailsSettingField("greetingMsg", agentGreetingMessage);
        activateField === "primaryColor" && handleUpdateAgentDetailsSettingField("primaryColor", primaryColor);
        activateField === "secondaryColor" && handleUpdateAgentDetailsSettingField("secondaryColor", secondaryColor);
    };

    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button
                    onClick={() => handleUpdateActivateField()}
                >
                    <div>Save Changes</div>
                </button>
            </div>
            <div className="body-container">
                <div className="agent-list-settings-container">
                    <div className="settings-header-title">
                        <div 
                            className="back-path-container"
                            onClick={() => dispatch(setEditableAgent(null))}
                        >
                            <img className="back-arrow" src={getImage("back-arrow")} alt="back-arrow" />
                            <h3>Agent Management</h3>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Agent Name</div>
                            <div className="description">This is to identify the agent. Give a unique name to your bot</div>
                        </div>
                        <div className="action-body">
                            <div className="label-input-container">
                                {/* <label style={{color: "transparent"}}>{"Non Label"}</label> */}
                                <input
                                    className=""
                                    value={agentName || ""}
                                    onChange={(e: any) => {
                                        const { value } = e.target;
                                        setAgentName(value);
                                        // setActivateField("aiChatBotName")
                                    }}
                                    placeholder={"Agent Name"}
                                />
                                <img
                                    onClick={() => handleUpdateAgentDetailsSettingField("aiChatBotName", agentName)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Agent Headline</div>
                            <div className="description">This is the heading displayed in the chat bot widget. Customer will understand what is the purpose of agent</div>
                        </div>
                        <div className="action-body">
                            <div className="label-input-container">
                                {/* <label style={{color: "transparent"}}>{"Non Label"}</label> */}
                                <input
                                    className=""
                                    value={agentHeadline || ""}
                                    onChange={(e: any) => {
                                        const { value } = e.target;
                                        setAgentHeadline(value);
                                        // setActivateField("aIChatBotHeadLine");
                                    }}
                                    placeholder={"Agent Headline"}
                                />
                                <img
                                    onClick={() => handleUpdateAgentDetailsSettingField("aIChatBotHeadLine", agentHeadline)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Agent Description</div>
                            <div className="description">This is the description displayed in the chat widget. Customer will understand what is the purpose of agent</div>
                        </div>
                        <div className="action-body">
                            <div className="label-input-container">
                                {/* <label>eg: {"(2h, 1d, 15m, never)"}</label> */}
                                <textarea
                                    className=""
                                    value={agentDescription || ""}
                                    onChange={(e: any) => {
                                        const { value } = e.target;
                                        setAgentDescription(value);
                                        // setActivateField("aIChatBotDescription");
                                    }}
                                    placeholder={"Ask any questions to our bot and, it will try to answer from the knowledge it is trained for."}
                                />
                                <img
                                    onClick={() => handleUpdateAgentDetailsSettingField("aIChatBotDescription", agentDescription)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Greeting Message</div>
                            <div className="description">This will be used to greet user who open the chat widget. eg: Hi there, How can I help you today?</div>
                        </div>
                        <div className="action-body">
                            <div className="label-input-container">
                                {/* <label style={{color: "transparent"}}>{"Non Label"}</label> */}
                                <input
                                    className=""
                                    value={agentGreetingMessage || ""}
                                    onChange={(e: any) => {
                                        const { value } = e.target;
                                        setAgentGreetingMessage(value);
                                        // setActivateField("greetingMsg");
                                    }}
                                    placeholder={"Greeting Message"}
                                />
                                <img
                                    onClick={() => handleUpdateAgentDetailsSettingField("greetingMsg", agentGreetingMessage,)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Primary Color</div>
                            <div className="description">This will be used to style the chat widget, its overall look and feel to match your brand color</div>
                        </div>
                        <div className="color-picker-action-body">
                            <div className="color-picker-input-container">
                                <ColorPicker
                                    palette_label={null}
                                    color_values={color_values}
                                    value={primaryColor}
                                    color_picker_container_class_name={"settings-team-color-picker-container"}
                                    onChange={(value: any) => {
                                        console.log({ color_value: value });
                                        setPrimaryColor(value);
                                        setActivateField("primaryColor");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Secondary Color</div>
                            <div className="description"> Give a suitable secondary color for your chat widget</div>
                        </div>
                        <div className="color-picker-action-body">
                            <div className="color-picker-input-container">
                                <ColorPicker
                                    palette_label={null}
                                    color_values={color_values}
                                    value={secondaryColor}
                                    color_picker_container_class_name={"settings-team-color-picker-container"}
                                    onChange={(value: any) => {
                                        console.log({ color_value: value });
                                        setSecondaryColor(value);
                                        setActivateField("secondaryColor");
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Close Conversation</div>
                            <div className="description">Mention the time frame with which a conversation will be automatically close due to inactivity. For WhatsApp and Facebook, this will be 24 hrs</div>
                        </div>
                        <div className="action-body">
                            <div className="label-input-container">
                                {/* <label>eg: {"eg: (2h, 1d, 15m)"}</label> */}
                                <input
                                    className=""
                                    readOnly={true}
                                    value={knowledgeBaseFileName?.replace(" ", "")}
                                    placeholder={"Select Email or set auto"}
                                />
                                <input
                                    id="image" type="file"
                                    // accept='*'
                                    // accept='image/png, image/jpg, image/jpeg, image/svg'
                                    accept='application/pdf'
                                    onChange={(e: any) => {
                                        // console.log({ img: e?.target?.files[0] });
                                        // setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                        // setBotPdfURL(URL.createObjectURL(e?.target?.files[0]));
                                        setKnowledgeBaseFileName(e?.target?.files[0]?.name?.replace(" ", ""));
                                        handleAgentKnowledgeBaseFileSubmit(e?.target?.files[0]);
                                        // setPdfFile(e?.target?.files[0]);
                                        // handleImageChange(e);
                                        // if (e?.target?.files[0]?.size < 25000) {
                                        // }

                                    }}
                                    className="d-none"
                                />
                                <label className="upload-icon" htmlFor="image">
                                    <img src={getImage("upload-icon")} alt="tik"/>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="settings-form-item">
                        <div className="title-description-container">
                            <div className="title">Enable on biopage</div>
                            <div className="description">If you disable it,  chat widget will not appear on your biopage. By default it will be disabled</div>
                        </div>
                        <div className="action-body">
                            <img
                                onClick={() => {
                                    // console.log({
                                    //     checked: isEnableOnBioPage,
                                    //     agentName, agentHeadline,
                                    //     agentDescription, agentGreetingMessage,
                                    //     primaryColor, secondaryColor,
                                    //     knowledgeBase: clapUpBotDetails?.knowledgeBase,
                                    //     isEnabled: Number(clapUpBotDetails?.isEnabledInBioPage)
                                    // });
                                    
                                    if ((!agentName || !agentHeadline || !agentDescription || !agentGreetingMessage || !primaryColor || !secondaryColor || !clapUpBotDetails?.knowledgeBase)) { 
                                        dispatch(setErrorMessage("Please fill all fields to enable bot on biopage"));
                                        return;
                                    }
                                    // setIsEnableOnBioPage(!isEnableOnBioPage);
                                    handleUpdateAgentDetailsSettingField("enableInBioPage", isEnableOnBioPage ? 0 : 1, null);
                                }}
                                className="switch-icon"
                                src={getImage(isEnableOnBioPage ? "active-notification" : "in-active-notification")} 
                                alt="tik"
                            />
                        </div>
                    </div>
                    <div className="extra"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AgentSettings;