import React, { useRef, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { shadesOfPurple } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import SyntaxHighlighter from "react-syntax-highlighter";
import './EmbedCode.scss';
// import {
//   // solarizedLight,
//   // solarizedDark,
//   // darcula,
//   // dark,
//   // tomorrow,
//   // a11yDark,
//   // dracula,
//   // gruvboxDark,
//   // gruvboxLight,
//   // hopscotch,
//   // nightOwl,
//   // nord,
//   // pojoaque,
//   // shadesOfPurple,
//   // vs,
//   // vs2015,
//   // xcode,
//   // atomOneDark,

// } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Button from "../CustomButton/ButtonContainer";
import { Tooltip } from "antd";
import {CopyTwoTone, CopyOutlined, CopyFilled} from "@ant-design/icons";
const SyntaxHighlighterAny = SyntaxHighlighter as any;

const EmbedCode = ({ url }: any) => {
  const [embedCode, setEmbedCode] = useState("");
  const [copiedEmbedCode, setCopiedEmbedCode] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isEmbedCodeCopiedByButton, setIsEmbedCodeCopiedByButton] = useState(false);
  useEffect(() => {
    const copiedCode = `
  <iframe 
    src="${url}" 
    width="100%" height="100%" 
    frameborder="0" 
    allowfullscreen
  >
  </iframe>
    `;
    const code = `${copiedCode}`;
    setEmbedCode(code);
    setCopiedEmbedCode(copiedCode);
  }, [url]);

  const handleCopiedUrl = () => {
    navigator.clipboard.writeText(url);
    // navigator.clipboard.writeText(copiedEmbedCode);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  
  const handleCopiedUrlWithEmbedCode = () => {
    navigator.clipboard.writeText(copiedEmbedCode);
    setIsEmbedCodeCopiedByButton(true);
    setTimeout(() => {
      setIsEmbedCodeCopiedByButton(false);
    }, 3000);
  };

  return (
    <React.Fragment>
      <div className="embed-code-container">
        <div
          className="border rounded p-2 position-relative"
          style={{ height: 285, overflowY: "hidden" }}
        >
          <SyntaxHighlighterAny
            language="javascript"
            style={shadesOfPurple}
          // PreTag="div"
          // showLineNumbers={true}
          // wrapLines={true}
          // lineProps={{ style: { wordBreak: "break-all", whiteSpace: "pre-wrap" } }}
          >
            {embedCode || ''}
          </SyntaxHighlighterAny>
          <Tooltip
            title={
              <small
                // className={`${isCopied ? "text-success" : "text-warning"}`}
                style={{
                  color: `${isCopied ? "#56e756" : "yellow"}`,
                }}
              >
                {isCopied ? "Copied!" : "Copy url"}
              </small>
            }
          >
            <CopyOutlined
              onClick={() => handleCopiedUrl()}
              className={"m-2 rounded p-0 position-absolute"}
              style={{
                // color: "#FAFBFC",
                // backgroundColor: "#ABB9CC",
                color: `${isCopied ? "#56e756" : "yellow"}`,
                right: 7,
                top: 7,
                width: "fit-content",
                zIndex: 10,
              }}
            />
          </Tooltip>
          <Button
            onClick={() => handleCopiedUrlWithEmbedCode()}
            classes={`m-2 rounded btn ${isEmbedCodeCopiedByButton
                ? "btn-outline-success bg-light text-success"
                : "btn-outline-warning"
              }  position-absolute`}
            style={{
              // color: "#FAFBFC",
              // backgroundColor: "#ABB9CC",
              // color: `${isEmbedCodeCopiedByButton ? "#56e756" : ""}`,
              left: 0,
              bottom: 3,
              width: "fit-content",
              zIndex: 10,
            }}
          >
            {isEmbedCodeCopiedByButton ? "Copied!" : "Copy embed code"}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmbedCode;
