import React from 'react';
import './MessagesReviewSummary.scss';
import { getImage } from '../../../../../unified/image_service';

const MessagesAttachmentSummary = (props: any) => {
    const { attachments, summary}: any = props || {};
    return (
        <React.Fragment>
            <div className="attachment-summary">
                <div className="attachment-container">
                    <div className="attachment-header">Received</div>
                    <div className="content-data-container">
                        {
                            // attachments?.length
                            !attachments?.length
                            ?   [1,2, 3, 4]?.map((item: any, idx: any) => (
                                    <div key={idx} className='attachment-content-container'>
                                        <div className="attachment-content">
                                            <img className="attachment-logo" src={getImage("pdf-attachment")} alt="attachment" />
                                            <div className="attachment-details">
                                                <div className="attachment-name">{"attachment.pdf"}</div>
                                                <div className="size">{"15 KB"}</div>
                                            </div>
                                            <img 
                                                className="download" 
                                                // src={getImage("attachment-delete")} 
                                                src={getImage("attachment-download")} 
                                                alt="download-icon" 
                                            />
                                        </div>
                                    </div>
                                ))
                                : <div className='attachment-content-container'>
                                    <div className="attachment-content">
                                        No Attachments
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                <div className="attachment-container">
                    <div className="attachment-header">Sent</div>
                    <div className="content-data-container">
                        {
                            // attachments?.length
                            attachments?.length
                            ?   [1,2]?.map((item: any, idx: any) => (
                                    <div key={idx} className='attachment-content-container'>
                                        <div className="attachment-content">
                                            <img className="attachment-logo" src={getImage("pdf-attachment")} alt="attachment" />
                                            <div className="attachment-details">
                                                <div className="attachment-name">{"attachment.pdf"}</div>
                                                <div className="size">{"15 KB"}</div>
                                            </div>
                                            <img 
                                                className="download" 
                                                src={getImage("attachment-download")} 
                                                alt="download-icon" 
                                            />
                                        </div>
                                    </div>
                                ))
                                : <div className='attachment-content-container'>
                                    <div className="attachment-content">
                                        No Attachments
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="summary">
                    <div className="summary-header">Summary</div>
                    <div className="summary-body">
                        {summary || "Summary Not Generated"} 
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MessagesAttachmentSummary;
