import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setInboxAllMessagesPayload, setInboxMessageFilteringKeyByTeam, triggerGetInboxAllMessages } from "../reducer";
// import TopTabs from "../../components/layout/TopTabs";
// import RightBottomMessagesTabs from "../../components/layout/RightBottomMessagesTabs";
// import BotMessageFeeds from "../../components/feeds/feed-messages/feeds_bot_messages";
import AllMessageFeeds from "../../components/Feeds/feed-messages/feeds_all_messages";
import UnPermission from "../../pages/un-permission/UnPermission";
import { useParams } from "react-router-dom";

const AllMessages: React.FC = () => {
    // const history = useNavigate();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    const query_string = window.location.search;
    const { params } = useParams<{ params: string }>();
    const team_filtering_key =  route_path?.includes("team-") || route_path?.includes("label-")
                                ?   params?.includes("team-") ? params?.replaceAll("team-", "") 
                                    : params?.replaceAll("label-", "") 
                                : null;
    const {
        AllInboxData: data,
        // team_filtering_key,
        searchText, searchStartDate, searchEndDate, searchFilterStatus
    } = useSelector((state: RootState) => state?.unified);
    
    const tag = query_string?.includes("open") ? "open"
        : query_string?.includes("inProgress") ? "inProgress"
        : query_string?.includes("closed") ? "closed" 
        : query_string?.includes("starred") ? "starred" 
        : "all";
    
    const {
        allMails, closedMails,
        openMails, inProgressMails,
        // statDetails, msgDetails: chatDetails,
        }: any = data || {};
        
        
    const DataObject:any = tag === "all"
        ? allMails || []
        : tag === "closed"
        ? closedMails
        : tag === "inProgress"
        ? inProgressMails
        : tag === "starred"
        ? []
        : tag === "open"
        ? openMails
        // : tag ? []
        : allMails || [];
        
    // const nextDate: any[]
    //     = tag === "all"
    //     ? [new Date().toISOString()?.replace('Z', '')]
    //     // : tag === "reviews"
    //     // ? CFReviews_CFReviewsNextDate
    //     : [];

    const messagePayload: any = {
        // team id or label(tag) id for filtering
        teamId:  team_filtering_key || "all",
        workspaceId: logged_in_user_secret_data?.wuid,
        clapupUserId: logged_in_user_secret_data?.cuid,
        createdOn: new Date().toISOString().replace('Z', ''),
        // search
        // searchText,
        // fromDate: searchStartDate?.replace('Z', ''), 
        // toDate: searchEndDate?.replace('Z', ''), 
        // readStatus: searchFilterStatus,
    };
        
    useEffect(() => {
        // console.log('Path useEffect ===============> ', {AllInboxData: data});
        // console.log('path useEffect ===============> ', {messagePayload, team_filtering_key, params});
        // if(!isFirstTime && !data && !searchText && !searchStartDate && !searchFilterStatus){
        //     console.log({isFirstTime: 87, length: data, searchText, searchStartDate, searchEndDate, searchFilterStatus});
        //     setIsFirstTime(true);
        //     handleTriggerAllMessages(triggerGetInboxAllMessages, true, "26");
        // }

        if (!data || !DataObject?.emailDetails?.length) {
            // handleTriggerAllMessages(triggerGetInboxAllMessages, true);
            handleTriggerAllMessages(setInboxAllMessagesPayload, true);
        }
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
    }, [
        // route_path,
        params
        // searchText, searchStartDate, searchEndDate, searchFilterStatus
    ]);

    // filtering by team
    useEffect(() => {
        if (team_filtering_key) {
            handleTriggerAllMessages(setInboxAllMessagesPayload, { ...messagePayload, teamId: team_filtering_key.replaceAll("team-", "") });
            // setTimeout(() => dispatch(setInboxMessageFilteringKeyByTeam(null)), 2000);
        }
        // else {
        //     if (data) {
        //         // handleTriggerAllMessages(triggerGetInboxAllMessages, true);
        //         handleTriggerAllMessages(setInboxAllMessagesPayload, {});
        //     }
        // }
    }, [team_filtering_key]);

    const handleTriggerAllMessages = (callBack = triggerGetInboxAllMessages, _?: any, line?: any) => {
        // console.log("====IsFirstTime===> ", { dataObj: _, searchText, searchStartDate });
                
        const dataObj = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isForSearched: (searchText || searchStartDate || searchEndDate) ? true : false,
            ..._,
            isFirstTime: _ ? true : false,
            
        };

        console.log("======Next line=====> ", { dataObj, searchText, searchStartDate });

        if(tag === "open"){
            messagePayload.tag = "open";
            // messagePayload.inboxType = "inboxReviews";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else if(tag === "inProgress"){
            messagePayload.tag = "inProgress";
            // messagePayload.inboxType = "QandA";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else if(tag === "closed"){
            messagePayload.tag = "closed";
            // messagePayload.inboxType = "comments";
            const payload = {...messagePayload, ...dataObj,}
            dispatch(callBack(payload));
        } else if( tag === "starred"){
            messagePayload.tag = "starred";
            // messagePayload.inboxType = "mentions";
            const payload = {...messagePayload, ...dataObj,};
            dispatch(callBack(payload));
        } else {
            messagePayload.tag = "all";
            // messagePayload.inboxType = "customerFeedback";
            const payload = {...messagePayload, ...dataObj,}
            dispatch(callBack(payload));
        }
    }

    const handleSearchData = (key?: any, value?: any) => {
        let dataObj: any = {
            workspaceId: logged_in_user_secret_data?.wuid,
            clapupUserId: logged_in_user_secret_data?.cuid,
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isFirstTime: true,
        };
        console.log("key, value ======> ", { key, value, dataObj });
        
        if(key === "readStatus"){
            dataObj.readStatus = value;
            dataObj.isForSearched = value ? true : false;
            console.log({dataObj});
            // dispatch({ type: "FILTER_BY_READ_STATUS", payload: value });
            // handleTriggerAllMessages(triggerGetInboxAllMessages, dataObj);
        } else if (key === "searchText") {
            if (!value) {
                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
                return;
            }
            dataObj.searchText = value;
            dataObj.isForSearched = value ? true : false;
            console.log({dataObj});
            dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: value });
            handleTriggerAllMessages(triggerGetInboxAllMessages, dataObj);
        } else {
            if (!value?.fromDate || !value?.toDate) {
                dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                return;
            }
            dataObj = {
                ...dataObj,
                isForSearched: (value?.fromDate && value?.toDate) ? true : false,
                ...value
            };
            dispatch({ type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value });
            console.log({dataObj});
            handleTriggerAllMessages(triggerGetInboxAllMessages, dataObj);
        }
    };

    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "whatsApp") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);

    // console.log({ data});
    
    return (
        <React.Fragment>
            {
                isPermissible
                    ?
                    <AllMessageFeeds
                        handleTriggerData={handleTriggerAllMessages}
                        handleSearchData={handleSearchData}
                        triggerData={triggerGetInboxAllMessages}
                        // feedsContent={data}
                        type={'all-messages'}
                    />
                    : <UnPermission/>
            }
        </React.Fragment>
    )
}
export default AllMessages;

