import React from 'react';
import '../WallConversations.scss';
import emptyBox from '../../../../assets/img/Empty.svg';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
// import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import google_logo from '../../../../assets/icons/google-logo.svg';
import delete_icon from '../../../../assets/icons/delete-icon.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import start from '../../../../assets/icons/star-icon.svg';
import twitter_logo from '../../../../assets/icons/twitter-icon.svg';
import facebook_logo from '../../../../assets/icons/facebook-reviews.svg';
import feedback_logo from '../../../../assets/icons/feedback-line-1.2.svg';
import video_logo from '../../../../assets/icons/video-chat-line-1.2.svg';
// import video_link from '../../../../assets/icons/video-link.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import fb_recommendation from '../../../../assets/icons/fb-recommendation-star.svg';
import fb_dont_recommendation from '../../../../assets/icons/facebook-dont-recommendation-star.svg';
import like_icon from '../../../../assets/icons/feather-thumbs-up.svg';
import heart_icon from '../../../../assets/icons/heart-icon.svg';
import retweet_icon from '../../../../assets/icons/retweet-icon.svg';
// import { dummyConversationData } from './dummy_conversation_data';
import moment from 'moment';
import { useState } from 'react';
import { getGenerateApiImageByName } from '../../../../unified/image_service';

interface Props {
    wall_conversation: any;
    handleDelete: any;
}

const TwitterData: React.FC<Props> = ({ wall_conversation, handleDelete }) => {
  const [isMore, setIsMore] = useState<boolean>(false);
  // const timelineRef:any = React.useRef();
  // const [conversation, setConversation] = useState<any>(null);
  // useEffect(() => {   
  //   timelineRef.current?.scrollIntoView({ behavior: "smooth" });
  //   timelineRef.current?.scrollTo(0, timelineRef.current.scrollHeight);
  //   const conversation = dummyConversationData?.find((conversation: any) => conversation?.id === parseInt(wall_conversation?.id));
  //   console.log({conversation});
  //   // textRef.current.value = conversation?.person_2?.message || "";     
    
  //   setConversation(conversation);
  // }, [wall_conversation, dummyConversationData]);


  const getImage = (type: any) => {
      switch (type) {
        case 'google':
          return google_logo;
        case 'lock_icon':
          return lock_icon;
        case 'facebook':
          return facebook_logo;
        case 'facebookComments':
          return facebook_logo;
        case 'twitter':
          return twitter_logo;
        case 'videoTestimonial':
          return video_logo;
        case 'customerFeedBack':
          return feedback_logo;
        case 'heart':
          return heart_icon;
        case 'retweet':
          return retweet_icon;
        case 'fb-recommend':
          return fb_recommendation;
        case 'fb-dont-recommend':
          return fb_dont_recommendation;
        case 'like':
          return like_icon;
        default:
          return twitter_logo;
      }
  };

  // console.log({wall_conversation});
  const {reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, mentionedbyUserName, mentionedbyUserImageUrl, reviewtype, createdOn, reviewComment, provider, starRating, ratingStars, likeCount, retweetCount, locationName, recieverCompany, mentionedbyUserEmailId, reviewComment2} = wall_conversation || {};
  
  const test_string = "Lorem Ipsum is simply dummy text used by the printing and typesetting industry. 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book It has survived not only five centuries, but also the leap into electronic typesetting.";
  return (
    <React.Fragment>
      {wall_conversation ? (
        <div
          className={`wall-section-container`}
          // className={`wall-section-container ${wall_conversation?.provider === "videoTestimonial" && "wall-video"}`}
          // style={{
          //   width:
          //     wall_conversation?.provider === "videoTestimonial"
          //       ? "506px"
          //       : "270px",
          // }}
        >
          <div className="wall">
            <div className="wall-section-header">
              {
                mentionedbyUserImageUrl
                ? <img
                    className="user-avatar"
                    src={mentionedbyUserImageUrl || user_avatar}
                    alt="avatar"
                  />
                : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(mentionedbyUserImageUrl || "T USER")}
                    alt="avatar"
                  />
              }
              <div className="name-rating d-flex flex-column m-0 align-items-start">
                <h6 className="user-name mx-2 my-0">
                  {mentionedbyUserName || "User"}
                </h6>
                <p className="mt-1 mx-2">
                  {wall_conversation?.twitter_link
                    ? <a className="twitter-link" href={wall_conversation?.twitter_link} target="_blank" rel="noreferrer">
                      {wall_conversation?.twitter_link_title}
                    </a> : null}
                </p>
              </div>
            </div>
            <div className="wall-section-body">
              <p className="text-start description">
                {
                  reviewComment
                }
                {/* {
                  // wall_conversation?.description?.substring(0, 32) + ".."
                  reviewComment?.substring(
                    0,
                    !isMore ? 63 : reviewComment?.length + 1
                  ) ||
                    reviewComment.substring(
                      0,
                      !isMore ? 63 : test_string?.length + 1
                    )
                }
                {
                  reviewComment?.length > 63 ? <span onClick={() => setIsMore(!isMore)}>
                    {isMore ? "...Read Less" : "...Read More"}
                  </span>
                  : null
                } */}
              </p>
              {wall_conversation?.provider === "videoTestimonial" && (
                <div className="video-container mt-1">
                  {/* <img src={video_link} alt="" /> */}
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "60.25%",
                      paddingRight: "105%",
                    }}
                  >
                    <iframe
                      src={`https://iframe.mediadelivery.net/embed/${wall_conversation?.LibraryId}/${wall_conversation?.videoId}?autoplay=false`}
                      loading="lazy"
                      style={{
                        border: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        borderRadius: 8,
                      }}
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
            <div className="wall-section-footer">
              <p className="text-start date">
                {/* get second, minutes and hours manually*/}
                {
                  // new Date(createdOn).toDateString() + ' '
                  // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                  // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                  // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds())
                  moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                }
              </p>
              <div className="verify-delete">
                <p>Verified</p>
                <img
                  onClick={() => handleDelete(wall_conversation?.reviewId)}
                  className="delete-icon"
                  src={delete_icon}
                  alt="delete"
                />
              </div>
            </div>
            <img
              className="social-link"
              style={{ width: 20, height: 20 }}
              src={getImage(provider)}
              alt={provider}
            />
          </div>
        </div>
      ) : (
        <div className="empty-wall-section">
          <img src={emptyBox} alt="" />
          <h4>Your Wall is Empty.</h4>
          <span>Select an item from inbox to display!</span>
        </div>
      )}
    </React.Fragment>
  );
}

export default TwitterData;