import React from 'react';
import '../Conversations.scss';
import emptyBox from '../../../../assets/img/Empty.svg';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store';
import { toggleReplyingOn, toggleIsInReplyingMode } from '../../../../unified/reducer';
import { api_url } from '../../../../utils/server';
import Dialog from '../../../Dialogs/Dialog';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import BotMemberConversation from '../Conversations/BotMemberConversation';
import { getImage } from '../../../../unified/image_service';
interface Props {
  conversationId: string;
// handleReplaceConversation: any;
  conversation?: any;
  statDetails?: any;
  activePage: string;
  activeTab: string; 
  routePath: string;
  type?: string;
}

const BotMessagesConversations: React.FC<Props> = ({ conversation, type, statDetails, conversationId,  activePage, activeTab, routePath }) => {
  const {
    logged_in_user_secret_data,
    // accessed_user_details
  } = useSelector((state: any) => state?.login);
  // const {currentWorkspace: curWorkspace} = useSelector((state: any) => state?.headerState);
  // const user_info = accessed_user_details;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const query_string = window.location.search;
  const { unifiedData: data, isReplyingOn, isInReplyingMode } = useSelector((state: any) => state?.unified);
  // const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  // const {
  //   InboxAll_InboxAll, InboxAll_InboxAllnextDate,
  //   InboxReviews_InboxReviews, InboxReviews_InboxReviewsNextDate,
  //   InboxComments_InboxComments, InboxComments_InboxCommentsNextDate,
  //   InboxMentions_InboxMentions, InboxMentions_InboxMentionsNextDate,
  //   InboxQandA_InboxQandA, InboxQandA_InboxQandANextDate

  // }: any = data || {};
  
  // const unifiedData: any[] = tag === "all"
  //     ? InboxAll_InboxAll
  //     : tag === "reviews"
  //       ? InboxReviews_InboxReviews
  //     : tag === "comments"
  //       ? InboxComments_InboxComments
  //     : tag === "mentions"
  //       ? InboxMentions_InboxMentions
  //     : tag === "qa"
  //       ? InboxQandA_InboxQandA
  //     : [];
  
  // console.log({ unified_data: data });
  
  
  // const timelineRef:any = React.useRef();
  const textRef:any = React.useRef();
  // const [conversation, setConversation] = useState<any>(null);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<any>(null);
  // const [connection, setConnection] = useState<any>(null);
  // const [isRemovingReplyText, setIsRemovingReplyText] = useState<boolean>(false);

  // Signalr connection ======= start =======
  // useEffect(() => {
  //   console.log("Come to connect new hub");
    
  //   const newConnection = new HubConnectionBuilder()
  //     .withUrl(`${api_url}/lockReviewReplyHub`)
  //     .configureLogging(LogLevel.Information)
  //     .build();

  //   setConnection(newConnection);
  //   // console.log({newConnection});
    
  // }, [""]);

  // const [sender, setSender] = useState<any>(null);


  // useEffect(() => {
  //   if (connection?.state === 'Disconnected') {
  //     connection.start()
  //     .then(() => {
  //       console.log('SignalR connected');
  //       const prev_sender_info = JSON.parse(sessionStorage.getItem("prev_sender_info") || "{}");
        
  //       if (prev_sender_info?.reviewId) {
  //         setIsRemovingReplyText(true);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log('SignalR connection error:', error);
  //     });


  //     connection.on('LockWhenTyping', (details: any) => {
  //       const sender_details = JSON.parse(details);
  //       const { isFromPrevious, name: sender_name, reviewId, provider, email, replyText } = sender_details || {};
  //       console.log({isFromPrevious, email_sender_details: sender_details, isInReplyingMode});
        
  //       if (isFromPrevious) {
  //         setSender(null);
  //         dispatch(toggleIsInReplyingMode(false));
  //       } else if (email !== user_info?.email && reviewId === conversation?.reviewId && provider === conversation?.provider) {
  //         console.log(`SignalR User with ID ${sender_name} is typing`);
  //         if(replyText){
  //           setSender(sender_name);
  //           dispatch(toggleIsInReplyingMode(true));
  //         } else {
  //           setSender(null);
  //           dispatch(toggleIsInReplyingMode(false));
  //         }
  //         // setTimeout(() => {
  //         //   setSender(null);
  //         //   dispatch(toggleIsInReplyingMode(false));
  //         // }, 10000);
  //       }
  //     });




  //     //  connection.on('ReceiveTypingStatus', (typingUser) => {
  //     //    console.log(`${typingUser} is typing`);
  //     //    setIsTyping(true);
  //     //    setTimeout(() => {
  //     //      setIsTyping(false);
  //     //    }, 2000);
  //     //  });


  //   } else {
  //     console.log('SignalR connection is not in Disconnected state, cannot start.');
  //   }
  // }, [connection]);

  // useEffect(() => {
  //   if (conversation && replyText !== null) {
  //     const sender_info = {isFromPrevious: true, replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider };
  //     console.log("SignalR: => ", { replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });
  //     if (replyText) { 
  //       sessionStorage.setItem("prev_sender_info", JSON.stringify({ replyText: '', email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider }));
  //       sender_info.isFromPrevious = false;
  //     }
  //     connection.invoke('LockWhileTyping', true, JSON.stringify(sender_info), conversation?.reviewId, conversation?.provider)
  //     .catch((error: any) => {
  //       console.error('Failed to send typing status:', error);
  //     });
  //     // if (replyText.length) {
  //     //   console.log("SignalR: => ", { replyText, email: user_info?.email, name: user_info?.name, reviewId: conversation?.reviewId, provider: conversation?.provider });
  //     //   connection.invoke('LockWhileTyping', true, sender_info, conversation?.reviewId, conversation?.provider)
  //     //   .catch((error: any) => {
  //     //     console.error('Failed to send typing status:', error);
  //     //   });
  //     // } else {
  //     //   console.log({email_replyText: replyText});
        
  //     //   connection.invoke('LockWhileTyping', false,  sender_info,  conversation?.reviewId, conversation?.provider)
  //     //   .catch((error: any) => {
  //     //     console.error('SignalR Failed to send typing status:', error);
  //     //   });
  //     // }  
  //   }
  // }, [replyText]);

  // Signalr connection ======= End =========
  
  // useEffect(() => {
  //   dispatch(toggleIsInReplyingMode(false));
  //   if(isRemovingReplyText){
  //     const prev_sender_info = JSON.parse(sessionStorage.getItem("prev_sender_info") || "{}");
  //     console.log({sign_prev: prev_sender_info});
  //     if (prev_sender_info?.reviewId && connection) {
  //       sessionStorage.removeItem("prev_sender_info");
  //       setReplyText("");   
  //     }
  //   }
  // }, [isRemovingReplyText]);
    

  // useEffect(() => { 
  //   timelineRef.current?.scrollIntoView({ behavior: "smooth" });
  //   timelineRef.current?.scrollTo(0, timelineRef.current.scrollHeight);
  //   // const conversation = unifiedData?.find((conversation: any) => conversation?.reviewId === conversationId);
  //   console.log("Hello => ", {conversation, workspaceSettings});
  //   // if(conversation){
  //   //   // textRef.current.value = conversation?.person_2?.message || "";
  //   //   setSender(null);
  //   //   setIsReply(false);
  //   //   // setReplyText("");   
  //   //   setConversation(conversation);

  //   //   // const dataObj: any = {
  //   //   //     clapupUserId: logged_in_user_secret_data?.cuid,
  //   //   //     roleId: logged_in_user_secret_data?.roleId,
  //   //   //     workspaceId: logged_in_user_secret_data?.wuid,
  //   //   // }
  //   //   // console.log({dataObj});
        
  //   //   // if (conversation?.provider?.includes('google')) {
  //   //   //   // console.log({ conversation, workspaceSettings });
  //   //   //   const tag = conversation?.tag;
  //   //   //   // dataObj.locationId = conversation?.locationId;
  //   //   //   // dataObj.reviewerDisplayName = conversation?.reviewerDisplayName;
  //   //   //   // console.log({googleDataObj: dataObj, tag, conversation});
  //   //   //   if (tag?.includes('googleQandA')) {
  //   //   //     // dispatch(triggerConversationDetails(null, `${api_url}/api/${api_version}/google/GetGoogleAnswer?questionId=${conversation?.reviewId}`, 'GET'));
  //   //   //   } else {

  //   //   //   }
  //   //   // } else if (conversation?.provider?.includes('facebook')) {
  //   //   //   // dataObj.reviewerName = conversation?.reviewedbyUserName;
  //   //   //   dataObj.pageId = conversation?.reviewPageId;
  //   //   //   const tag = conversation?.tag;
  //   //   //   if (tag === 'comments') {
  //   //   //     dataObj.postId = conversation?.PostId;
  //   //   //     dataObj.commentId = conversation?.reviewId;
  //   //   //     dataObj.facebookUserId = integrationConnections[2]?.UserId;
  //   //   //     console.log({ dataObj, integrationConnections });
  //   //   //     // dispatch(triggerConversationDetails(dataObj, api_url + `/api/${api_version}/facebook/GetPostDetailsByCommentId`));
  //   //   //   } else if (tag === 'mentions') {
  //   //   //     dataObj.mentionId = conversation?.reviewId;
  //   //   //     console.log({"==== Hello dataObj ====> ": dataObj });
  //   //   //     // dispatch(triggerConversationDetails(dataObj, api_url + `/api/${api_version}/facebook/GetMentionReplies`));
  //   //   //   } else if (tag === 'reviews') {
  //   //   //     dataObj.reviewId = "4457768454284935" || conversation?.reviewId;
  //   //   //     console.log({"==== Hello dataObj ====> ": dataObj });
  //   //   //     // dispatch(triggerConversationDetails(dataObj, api_url + `/api/${api_version}/facebook/GetReviewReplies`));
  //   //   //     // dispatch(triggerFacebookReviewsUnifiedDetails(dataObj));
  //   //   //   }
  //   //   // }
  //   //   // else if (conversation?.provider?.includes('twitter')) {
  //   //   //     // will be added later
  //   //   //     console.log({dataObj});
  //   //   //     dispatch(triggerTwitterReviewsUnifiedDetails(dataObj));
  //   //   // // } else if(conversation?.provider === 'customerFeedback') {
  //   //   // } else if(conversation?.provider?.includes('customer')) {
  //   //   //     console.log({dataObj});
  //   //   //     dispatch(triggerCustomerFeedbackReviewsUnifiedDetails(dataObj));
  //   //   // } else if(conversation?.provider?.includes('video')) {
  //   //   //     console.log({dataObj});
  //   //   //     dispatch(triggerVideoTestimonialReviewsUnifiedDetails(dataObj));
  //   //   // }
  //   // }
  // }, [conversationId]);

  const handleReply = (reply = replyText) => {
    if (reply) {
      console.log({reply});
      setIsReply(false);
      dispatch(toggleReplyingOn(false));
    } 
  }

  const [isGoForDetails, setIsGoForDetails] = useState<boolean>(false);
  const handleDetailChange = () => {
    sessionStorage.setItem('details', (!isGoForDetails) + '');
    
    setIsGoForDetails(!isGoForDetails);
  };

  useEffect(() => {
    const details = sessionStorage.getItem('details');
    // console.log({details});
    if(details === 'true'){
      setIsGoForDetails(true);
    } else {
      setIsGoForDetails(false);
    }
    
  }, [query_string]);
  const [movingPage, setMovingPage] = useState<any>(null);

  // const handleReplaceConversation = (flag: string, idx: number) => {
  //   sessionStorage.setItem("details", isGoForDetails + "");
  //   console.log({flag, idx});
    
  //   // if(flag === 'next'){
  //   //   const nextConversation = unifiedData[(idx + 1) > (unifiedData?.length) ? idx : (idx)];
  //   //   if (nextConversation && nextConversation?.reviewId !== conversationId) {
  //   //     // if (nextConversation) {
  //   //     if(isReplyingOn){
  //   //       setMovingPage(`${routePath}?${activeTab}=${activePage}&cov=${nextConversation?.reviewId}`);
  //   //     } else {
  //   //       navigate(`${routePath}?${activeTab}=${activePage}&cov=${nextConversation?.reviewId}`);
  //   //     }
  //   //   }
  //   // } else {
  //   //   const prevConversation = unifiedData[(idx - 1) < 1 ? 0 : (idx-2)];
  //   //   console.log({prevConversation, SlNo: prevConversation?.SlNo, Idx: (idx) < 1 ? 1 : (idx - 1)});
      
  //   //   if (prevConversation && prevConversation?.reviewId !== conversationId) {
  //   //   // if (prevConversation) {
  //   //     if (isReplyingOn) {
  //   //       setMovingPage(`${routePath}?${activeTab}=${activePage}&cov=${prevConversation?.reviewId}`);
  //   //     } else { 
  //   //       navigate(`${routePath}?${activeTab}=${activePage}&cov=${prevConversation?.reviewId}`);
  //   //     }
  //   //   }
  //   // }
  // }

  const handlePrevPath = () => {
    sessionStorage.setItem("details", !isGoForDetails + "");
    if (isReplyingOn) {
      setMovingPage(`${routePath}?${activeTab}=${activePage}`);
    } else { 
      navigate(`${routePath}?${activeTab}=${activePage}`);
    }
  }

  const handlePageChange = (flag: string) => {
    if (!flag) {
      setMovingPage(null);
    } else {
      navigate(movingPage);
      setMovingPage(null);
    }
  }



  // const {provider} = conversation || {};
  // console.log({person_1, person_2, properties, details});
  // console.log("con-length => ", {conversation, statDetails});
  
  return (
    <React.Fragment>
      {
        movingPage ?
          <Dialog
            open={movingPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      {conversation?.userName && statDetails?.userName ? (
        <BotMemberConversation
          type={type}
          // sender={sender}
          replyText={replyText}
          isReply={isReply}
          isGoForDetails={isGoForDetails}
          conversation={conversation || {}}
          statDetails={statDetails || {}}
          handleDetailChange={handleDetailChange}
          handlePrevPath={handlePrevPath}
          // handleReplaceConversation={handleReplaceConversation}
          getImage={getImage}
          conversationId={conversationId}  
          workspaceSettings={workspaceSettings}  
          setIsReply={setIsReply} setReplyText={setReplyText}
          textRef={textRef} handleReply={handleReply}
        />
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <img src={emptyBox} alt="" />
          <h4>Your Wall is Empty.</h4>
          <span>Select an item from inbox to display!</span>
        </div>
      )}
    </React.Fragment>
  );
}

export default BotMessagesConversations;
