import React, { useEffect, useRef, useState } from 'react';
import './ConversationComponent.scss';
import CustomCountdown from '../../../../CustomCountdown/CustomCountdown';
import { useSelector } from 'react-redux';
import { getImage } from '../../../../../unified/image_service';
import { setErrorMessage, setSuccessMessage, setAttachments, setInboxReplyText, toggleReplyingOn, assignUserIdForInboxEmailByMessageId } from '../../../../../unified/reducer';
import { generateAccessToken } from '../../../../../profile/reducer';
import axios from 'axios';
import { inbox_api_url } from '../../../../../utils/server';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'antd';
import { deleteFile, getAllFiles, saveFile, setKeyValue } from '../../../../../utils/indexedDBManagementService';

const ConversationCommonReply = (props: any) => {
    const [isReadyToAssignUserEmail, setIsReadyToAssignUserEmail] = useState<boolean>(false);
    const {
        data_fields, handleParentCallback, conversation,
        isReply, isCountDownOn,
        isForReplyingUser, textRef,
        showingLeftContent, // we will work on next version
        sender,
        showFileInputIcon, showEmojiInputIcon, showLockIcon,
        // after clicking reply button
        lastMessageData,
        handleAssignedUserForDetails,
        // attachment management
        handleSendMessageWithAttachment,
        // attachments,
        // handleRemoveAttachment,
        // handleFileUpload,
    } = props || {};
    const dispatch = useDispatch();
    const route_path = window.location.pathname;
    const { attachments, inboxReplyText, isReplyingOn } = useSelector((state: any) => state?.unified);
    const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
    const { messageId, ticket_no, ticket_serial_no } = conversation || {};

    useEffect(() => { 
        try {
            if (isReadyToAssignUserEmail) {
                // call assign login user email api
                console.log({ isReadyToAssignUserEmail, lastMessageData });
                // return;
                handleAssignedUserForDetails(lastMessageData);
                
            }
        } catch (err: any) {
            console.log({err});
            
        }
    }, [isReadyToAssignUserEmail]);
    
    // for changing replying button from send button
    useEffect(() => { 
        try {
            if (!inboxReplyText) {
                setIsReadyToAssignUserEmail(false);
            } else if(isReplyingOn) {
                setIsReadyToAssignUserEmail(true);
            }
        } catch (err: any) {
            console.log({err});
            
        }
    }, [inboxReplyText, isReplyingOn]);

    useEffect(() => {
        // try {
        //     const user_info = JSON.parse(
        //         sessionStorage.getItem("accessed_user_details") || localStorage.getItem("accessed_user_details") || "{}"
        //     );
        //     console.log({conversation, messageId, ticket_no, ticket_serial_no, user_info });
        //     if (user_info) {
        //         // call assign login user email api
        //         // console.log({ isReadyToAssignUserEmail, user_info });
        //         setLoggedInUser(user_info);
                
        //     }
        // } catch (err: any) {
        //     console.log({ err });
            
        // }
        setIsReadyToAssignUserEmail(false);
    }, [messageId, ticket_no, ticket_serial_no]);

    // Replying Body
    //   const textRef:any = useRef(null);
    const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: Parameters<F>) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
        };
    };
    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // console.log({value: event.target.value});
        const value = event?.target?.value || "";
        dispatch(setInboxReplyText(value));
    };

    const debouncedTextChange = debounce(handleTextChange, 500);
    // console.log({ isForReplyingUser });
    const [isOpenAttachmentDropdown, setIsOpenAttachmentDropdown] = useState<any>(false);
    const extractAttachmentName = (st: string): string => st.includes("-") ? st.split("-")[0] : st;
    const extractAttachmentNameWithDots = (st: string): string => {
        const result = st.includes("-") ? st.split("-")[0] : st;
        if (result?.length > 23) {
            return result.substring(0, 22) + "...";
        }
        return result;
    };

    const [attachment_menu, setAttachmentMenu] = useState<any>(null);
    // const [attachment_data, setAttachmentData] = useState<any>([]);
    // attachment
    // const [attachments, setAttachments] = useState<{ key: string; file: File }[]>([]);

    // Load attachment files from IndexedDB on component mount
    useEffect(() => {
        if (!attachments?.length) {
            (async () => {
                const savedFiles = await getAllFiles();
                dispatch(setAttachments(savedFiles));
            })();
        }
    }, []);

    // Handle file upload
    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            for (const file of filesArray) {
                const file_name = file?.name?.replaceAll(" ", "");
                // Check for duplicate files
                const isDuplicated = attachments?.find((item: any) => item?.key?.includes(file_name));
                if (isDuplicated) {
                    dispatch(setErrorMessage("The attachment is already uploaded"));
                    return;
                }
                
                // Check file size (15MB = 15 * 1024 * 1024 bytes)
                const MAX_SIZE_MB = 15;
                console.log({ file_name, file_size: file?.size });
                if (file?.size > MAX_SIZE_MB * 1024 * 1024) {
                    dispatch(setErrorMessage(`The file "${file.name}" exceeds the maximum size of ${MAX_SIZE_MB}MB`));
                    return;
                }
                const key = `${file_name}-${Date.now()}`; // Unique key
                await saveFile(key, file); // Save each file to IndexedDB
                dispatch(setAttachments([...attachments, { key, file }]));
                // setAttachments((prev) => [...prev, { key, file }]);
            }
        }
    };

    // Handle file removal
    const handleRemoveAttachment = async (key: string) => {
        const res = await deleteFile(key); // Remove from IndexedDB
        // console.log("Removed From DB: ", res);
        if (res) {
            const filtered_attachments = attachments?.filter((attachment: any) => attachment?.key !== key);
            dispatch(setAttachments(filtered_attachments)); // Update state
            // setAttachments((prev) => prev.filter((attachment) => attachment.key !== key)); // Update state
        }
    };

    useEffect(() => {
        console.log({ attachments });
        if(attachments?.length > 2){
            // setAttachmentData(attachments);
            const attachment_menu = (
                <div className='attachment-menu-container'>
                    <div className="attachment-items-container scroll-content">
                        {
                            attachments?.slice(2)?.map((attachment: any, idx: any) => (
                                <div key={idx} className={`attachment-item`}>
                                    <div title={extractAttachmentName(attachment?.key)} className='item-title'>{extractAttachmentNameWithDots(attachment?.key)}</div>
                                    <img
                                        onClick={() => handleRemoveAttachment(attachment?.key)}
                                        src={getImage("remove-attachment")} alt="remove" 
                                    />
                                    {/* <img src={getGenerateApiImageByName(au?.userName)} alt="assignee-name" /> */}
                                </div>
                            ))
                        }
                    </div>
                </div>
            );
            // console.log({ attachment_menu });
            setAttachmentMenu(attachment_menu);
        } else {
            setIsOpenAttachmentDropdown(false);
            setAttachmentMenu(null);
        }
    }, [attachments?.length]);

    const replyingFlag = (isReplyingOn || (isReadyToAssignUserEmail && isForReplyingUser)) ? true : false;

    return (
        <React.Fragment>
            <div className="conversation-replying-container">
                <div className='replying-header'
                    style={{justifyContent: isForReplyingUser ? "space-between" : 'flex-end'}}
                >
                {
                    isForReplyingUser
                    ?   <div className="replying-info">
                        {
                            sender
                            ? `${sender} is typing...`
                            : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
                        }
                        </div>
                    : null
                }
                <CustomCountdown
                    customClass={"countdown"}
                    onChange={(val: string) => {
                    // console.log(val);
                    }}
                    isStarted={isCountDownOn}
                />
                </div>
                <div
                    className={`
                        replying-body-footer
                        ${(!isReadyToAssignUserEmail && !isReply) ? "nonactive-footer" : ""}
                        ${(isForReplyingUser || replyingFlag) ? "" : "disable-footer"}
                    `}
                >
                    <textarea
                        ref={textRef}
                        className=""
                        readOnly={!isReply && (!isForReplyingUser || !isReadyToAssignUserEmail)}
                        // readOnly={!replyingFlag}
                        value={inboxReplyText}
                        placeholder={replyingFlag ? "Click reply and start replying..." : !isForReplyingUser ? "You cannot reply here" : "Click reply and start replying..."}
                        onChange={(e: any) => {
                            const { value } = e.target;
                            dispatch(setInboxReplyText(value));
                        }}
                        style={{ resize: "none" }}
                        autoFocus={isReply || isForReplyingUser || isReadyToAssignUserEmail}
                        // onClick={() => {
                        //     if (!isReadyToAssignUserEmail && conversation?.messageStatus === "open") {
                        //         setIsReadyToAssignUserEmail(true);
                        //     }
                        // }}
                    />
                    <div className="attachment-footer">
                        <div className='attachment-items-container'>
                            {
                                attachments?.slice(0, 2)?.map((item: any, idx: any) => (
                                    <div key={idx} className="attachment-item">
                                        <div title={extractAttachmentName(item?.key)} className='attachment-key'>{extractAttachmentNameWithDots(item?.key)}</div>
                                        <img
                                            onClick={() => handleRemoveAttachment(item?.key)}
                                            src={getImage("remove-attachment")} alt="remove"
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="attachment-dropdown">
                            {
                                attachment_menu !== null
                                ?   <Dropdown 
                                        onOpenChange={() => {setIsOpenAttachmentDropdown(!isOpenAttachmentDropdown);}}
                                        open={isOpenAttachmentDropdown}
                                        trigger={['click']}
                                        disabled={false}
                                        overlay={attachment_menu} 
                                        placement="topRight"
                                    >
                                        <img src={getImage("dots")} alt='dots'/>
                                    </Dropdown>
                                : null
                            }
                            
                        </div>
                    </div>
                    <div className='replying-footer'
                        style={{justifyContent: showingLeftContent ? "space-between" : "flex-end"}}
                    >
                        {/* {
                            showingLeftContent
                            ? <div className="quick-reply-advance">
                                <button>Quick Reply</button>
                                <button>Advance</button>
                            </div>
                            : null
                        } */}

                        <div className="reply-action-buttons">
                            <input
                                onChange={handleFileUpload}
                                id='file-input' type='file' accept='*' style={{ display: 'none' }}
                            />
                            {
                                showFileInputIcon
                                ? <label htmlFor='file-input'><img src={getImage("file-input-icon")} alt='tiny-icon'/></label>
                                : null
                            }
                            {/* {
                                showEmojiInputIcon
                                ? <img src={getImage("emoji-input-icon")} alt='tiny-icon'/>
                                : null
                            } */}
                            {
                                showLockIcon
                                ? <img src={getImage("pro-lock-icon")} alt='pro-lock-icon'/>
                                : null
                            }
                            <button
                                className="reply-button"
                                onClick={() => {
                                    // if (!isReadyToAssignUserEmail || !isReply) {
                                    if (replyingFlag) {
                                        // we can send message with attachments or without attachments
                                        handleSendMessageWithAttachment(); // take inboxReplyText and attachments from unified redux state
                                    } else if (!isReadyToAssignUserEmail || !isReply) {
                                        setIsReadyToAssignUserEmail(true);
                                    }
                                }}
                            >
                                {replyingFlag  ? "Send" : "Reply"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ConversationCommonReply
