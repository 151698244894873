import * as XLSX from 'xlsx';
// import * as XLSXStyle from 'xlsx-style';
const export_phone_number_summary_CSV = async ({ fromDate, toDate, data, columnHeadings, title, excel_file_name }: any, size = 'A1') => {
    // Create a new workbook and a new worksheet
    const workbook = XLSX.utils.book_new();
    const phone_number_array = [
        ['Phone_Number'],
        ...Array.from({ length: 10000 }, () => [""])
    ];
    const worksheet: any = XLSX.utils.aoa_to_sheet(phone_number_array);

    // Format cells to text
    const formatAsText = (ws: any, cell: any) => {
        // if (!ws[cell]) return;
        ws[cell].z = '@'; // '@' means text format in Excel
    };

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cell = XLSX.utils.encode_cell({ r: row, c: col });
            formatAsText(worksheet, cell);
        }
    }

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    // Write the workbook to a file
    XLSX.writeFile(workbook, 'template.xlsx');

    // // // old
    // const workbook = XLSX.utils.book_new();
    // const worksheet = XLSX.utils.json_to_sheet(data, { header: columnHeadings });

    // // Merge cells for "From Date" and "To Date" header
    // // const mergeCells = [
    // //     { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
    // //     { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
    // // ];
    // // worksheet['!merges'] = mergeCells;

    // // // Set values for "From Date" and "To Date" header
    // // worksheet.A1 = { v: '' };
    // // worksheet.B1 = { v: 'From Date' };
    // // // worksheet.C1 = { v: fromDate };
    // // worksheet.C1 = { v: "" };
    // // worksheet.D1 = { v: 'To Date' };
    // // // worksheet.E1 = { v: toDate };
    // // worksheet.E1 = { v: "" };
    // // worksheet.A2 = { v: 'Sl No' };
    // // worksheet.B2 = { v: 'Agent Name' };
    // // worksheet.C2 = { v: 'Gross Order Value' };
    // // worksheet.D2 = { v: 'Refunded Orders' };
    // // worksheet.E2 = { v: 'Active Orders' };

    // // A1 | B1 | C1 | D1 | E1
    // // A2 | B2 | C2 | D2 | E2
    // worksheet.A1 = { v: 'Phone_Number' };
    // // worksheet.B1 = { v: '' };
    // // worksheet.C1 = { v: "" };
    // // worksheet.D1 = { v: '' };
    // // worksheet.E1 = { v: "" };
    // // worksheet.A2 = { v: '' };
    // // worksheet.B2 = { v: '' };
    // // worksheet.C2 = { v: '' };
    // // worksheet.D2 = { v: '' };
    // // worksheet.E2 = { v: '' };
    // XLSX.utils.book_append_sheet(workbook, worksheet, title);

    // XLSX.writeFile(workbook, `${excel_file_name}.xlsx`, { compression: true });
};

const export_phone_number_summary_CSV_backup = async ({ fromDate, toDate, data, columnHeadings, title, excel_file_name }: any, size = 'A1') => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, { header: columnHeadings });

    // Merge cells for "From Date" and "To Date" header
    // const mergeCells = [
    //     { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
    //     { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
    // ];
    // worksheet['!merges'] = mergeCells;

    // // Set values for "From Date" and "To Date" header
    // worksheet.A1 = { v: '' };
    // worksheet.B1 = { v: 'From Date' };
    // // worksheet.C1 = { v: fromDate };
    // worksheet.C1 = { v: "" };
    // worksheet.D1 = { v: 'To Date' };
    // // worksheet.E1 = { v: toDate };
    // worksheet.E1 = { v: "" };
    // worksheet.A2 = { v: 'Sl No' };
    // worksheet.B2 = { v: 'Agent Name' };
    // worksheet.C2 = { v: 'Gross Order Value' };
    // worksheet.D2 = { v: 'Refunded Orders' };
    // worksheet.E2 = { v: 'Active Orders' };

    // A1 | B1 | C1 | D1 | E1
    // A2 | B2 | C2 | D2 | E2
    worksheet.A1 = { v: 'Phone_Number' };
    // worksheet.B1 = { v: '' };
    // worksheet.C1 = { v: "" };
    // worksheet.D1 = { v: '' };
    // worksheet.E1 = { v: "" };
    // worksheet.A2 = { v: '' };
    // worksheet.B2 = { v: '' };
    // worksheet.C2 = { v: '' };
    // worksheet.D2 = { v: '' };
    // worksheet.E2 = { v: '' };
    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    XLSX.writeFile(workbook, `${excel_file_name}.xlsx`, { compression: true });
};

const extract_title_variables_from_file = async ({ file }: any): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook: any = XLSX.read(data, { type: 'array' });
            const firstSheetName: any = workbook.SheetNames[0];
            const worksheet: any = workbook.Sheets[firstSheetName];
            const range = XLSX.utils.decode_range(worksheet['!ref']);

            const variables: string[] = [];

            for (let col = 1; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
                const cellValue = worksheet[cellAddress]?.v?.toLowerCase()?.replace(/\s+/g, '_');

                if (cellValue && cellValue.trim() !== '') {
                    variables.push(cellValue);
                }
            }

            resolve(variables);
        };

        reader.onerror = (err: ProgressEvent<FileReader>) => {
            reject(err);
        };

        reader.readAsArrayBuffer(file);
    });
}

// ===== Extraction Methods of .xlsx file ====
interface ExcelData {
    [key: string]: string[];
}

const extract_data_from_xlsx_file = async (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
            const data: any = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook: any = XLSX.read(data, { type: 'array' });
            const firstSheetName: any = workbook.SheetNames[0];
            const worksheet: any = workbook.Sheets[firstSheetName];
            const range: any = XLSX.utils.decode_range(worksheet['!ref']);
            // if (range.e.c > 2) {
            //     resolve(null);
            //     return;
            // }
            const result: ExcelData = {};

            for (let col = range.s.c; col <= range.e.c; col++) {
                const propertyName = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })]?.v?.toLowerCase()?.replace(/\s+/g, '_');
                if (!propertyName) {
                    continue;
                }
                result[propertyName] = [];
                for (let row = range.s.r + 1; row <= range.e.r; row++) {
                    const cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: col })]?.v;
                    result[propertyName].push(cellValue || "");
                }
            }
            resolve(result);
        };

        reader.onerror = (err: ProgressEvent<FileReader>) => {
            reject(err);
        };

        reader.readAsArrayBuffer(file);
    });
};

const extract_campaign_data_from_xlsx_file = async (file: any): Promise<any> => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
            const data: any = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook: any = XLSX.read(data, { type: 'array' });
            const firstSheetName: any = workbook.SheetNames[0];
            const worksheet: any = workbook.Sheets[firstSheetName];
            const range: any = XLSX.utils.decode_range(worksheet['!ref']);
            // if (range.e.c > 2) {
            //     resolve(null);
            //     return;
            // }
            // Find the column index for "Phone_Number"
            let phoneNumberColumnIndex = -1;
            for (let col = range.s.c; col <= range.e.c; col++) {
                const columnName = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })]?.v?.toLowerCase()?.replace(/\s+/g, '_');
                if (columnName === "phone_number") {
                    phoneNumberColumnIndex = col;
                    break;
                }
            }

            if (phoneNumberColumnIndex === -1) {
                // console.log({ phoneNumberColumnIndex });

                resolve({ result: null, campaign_table_data: [], error: `Phone Number is missing in your ${file?.name} file` }); // If "Phone_Number" column not found, resolve with null
                return;
            }

            const result: ExcelData = {};

            // Get the number of rows in the "Phone_Number" column
            let numberOfRows = range.s.r || 0;
            for (let row = range.s.r; row <= range.e.r; row++) {
                const cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 0 })]?.v;
                if (cellValue) {
                    numberOfRows = row;
                }
            }
            // console.log({ numberOfRows });

            for (let col = range.s.c; col <= range.e.c; col++) {
                const propertyName = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })]?.v?.toLowerCase()?.replace(/\s+/g, '_');
                if (!propertyName) {
                    continue;
                }
                result[propertyName] = [];
                // for (let row = range.s.r + 1; row <= range.e.r; row++) {
                for (let row = range.s.r + 1; row <= numberOfRows; row++) {
                    const cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: col })]?.v;
                    result[propertyName].push(cellValue || "");
                }
            }
            const campaign_contact_table_data = get_campaign_table_data(result);
            resolve({ result, campaign_contact_table_data });
        };

        reader.onerror = (err: ProgressEvent<FileReader>) => {
            reject(err);
        };

        reader.readAsArrayBuffer(file);
    });
};

// ===== Get methods of .xlsx  data proper campaign table array ====
const get_campaign_table_data = (data: any) => {
    const result: any[] = data?.phone_number?.reduce((acc: any, _: any, index: number) => {
        const newObj = Object.keys(data).reduce((obj: any, key: any) => {
            obj[key] = data[key][index] || null;
            return obj;
        }, {});
        if (newObj?.phone_number) {
            acc.push(newObj);
        }
        return acc;
    }, []);
    const processedData: any[] = result?.reduce<any[]>((acc, item) => {
        // Check if any value is null
        const hasNullValue = Object.values(item).some(value => value === null);

        // Check if phone_number matches the required format
        const isPhoneNumberValid = /^\+\d+-\d+$/.test(item.phone_number);

        // Determine unSelectable value
        const unSelectable: boolean = hasNullValue || !isPhoneNumberValid;

        // Add the new object with unSelectable property to the accumulator
        acc.push({
            ...item,
            selectable: !unSelectable
        });

        return acc;
    }, []);
    console.log({ result, processedData });
    return processedData;
};

const export_order_summary_CSV = async ({ fromDate, toDate, data, columnHeadings, title, excel_file_name }: any, size = 'A1') => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, { header: columnHeadings });

    // Merge cells for "From Date" and "To Date" header
    // const mergeCells = [
    //     { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
    //     { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
    // ];
    // worksheet['!merges'] = mergeCells;

    // Set values for "From Date" and "To Date" header
    worksheet.A1 = { v: '' };
    worksheet.B1 = { v: 'From Date' };
    worksheet.C1 = { v: fromDate };
    worksheet.D1 = { v: 'To Date' };
    worksheet.E1 = { v: toDate };
    worksheet.F1 = { v: '' };
    worksheet.A2 = { v: 'Sl No' };
    worksheet.B2 = { v: 'Order Name' };
    worksheet.C2 = { v: 'Order Value' };
    worksheet.D2 = { v: 'Order Date' };
    worksheet.E2 = { v: 'Order Status' };
    worksheet.F2 = { v: 'Delivery Status' };

    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    XLSX.writeFile(workbook, `${excel_file_name}.xlsx`, { compression: true });
};

export { export_phone_number_summary_CSV, extract_campaign_data_from_xlsx_file, get_campaign_table_data, extract_data_from_xlsx_file, extract_title_variables_from_file, export_order_summary_CSV };