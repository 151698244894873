import Constants from "../../constants";

const initialState = {
    /// ==== new requirements =====
    folder_paths: [],
    folder_paths_payload: null,
    path_contents: [],
    open_global_modal: null,
    open_global_drawer: null,
    is_content_deleted: false,
    showGlobalWarningDialog: null,
    /// ==== new requirements =====
    // bio-page system folders
    bio_page_folders: [],
    // folder
    is_showing_create_folder: false,
    all_folders: [],
    folderNextDate: null,
    // content
    is_content_coming: false,
    updatable_content: {
        clapupUserId: null,
        workspaceId: null,
        roleId: null,
        contentId: "",
        properties_Keywords: "",
        sEO_UrlSlug: "",
        sEO_Desc: "",
        sEO_Title: "",
        bioPage_Caption: "",
        bioPage_Title: "",
        bioPage_Story: ""
    },
    show_uploading_progress: false,
    uploading_file: null,
    uploadingProgressCount: 0,
    folder_contents: [],
    view_content_data: null,
    contentNextDate: null,
    is_reverted_version_successfully: false,
    is_updated_folder_content: false,
    selected_deletable_contents: [],
    content_versions: {
        currentVersion: {},
        previousVersions: []
    },
    default_content_idx: null,
    showing_save_button: true,
    is_content_uploading_error: false,
    delete_message: null,
    folder_sort_type: null,
    content_sort_type: null,
}

// ===== new requirements of contents =======
export const setIsContentDeleted = (data: any) => {
    return {
        type: "SET_IS_CONTENT_DELETED",
        payload: data,
    }
};

export const setShowGlobalWarningDialog = (data: any) => {
    return {
        type: "SHOW_GLOBAL_WARNING_DIALOG",
        payload: data,
    }
};

export const setOpenGlobalDrawer = (data: any) => {
    return {
        type: "OPEN_GLOBAL_DRAWER",
        payload: data,
    }
};

export const setOpenGlobalModal = (data: any) => {
    return {
        type: "OPEN_GLOBAL_MODAL",
        payload: data,
    }
};

export const setFolderPathsToIndexDB = (data: any) => {
    return {
        type: Constants.SET_UP_FOLDER_PATHS_FOR_INDEX_DB,
        payload: data,
    }
};

export const setFolderPathsForRedux = (data: any) => {
    return {
        type: Constants.SET_UP_FOLDER_PATHS_FOR_REDUX,
        payload: data,
    }
};

// set path contents payload
export const setFolderPathContentsPayload = (data: any) => {
    return {
        type: Constants.SET_FOLDER_PATH_CONTENTS_PAYLOAD,
        payload: data,
    }
};

// get path contents
export const getFolderPathContents = (data: any) => {
    return {
        type: Constants.GET_FOLDER_PATH_CONTENTS,
        payload: data,
    }
};

export const setRecentFileContents = (data: any) => {
    return {
        type: "SET_CONTENT_RECENT_FILES",
        payload: data,
    }
};

// set path contents from indexDB
export const setFolderPathContentsFromIndexDB = (data: any) => {
    const {
        contentDetails, nextDate,
        recentFiles,
        not_found_data, is_data_come
    } = data || {};
    // console.log({ current_stored_folder_key_content: data });
    return {
        type: Constants.SET_FOLDER_PATH_CONTENTS_FROM_INDEX_DB,
        payload: {
            contentDetails: contentDetails || [],
            contentNextDate: nextDate,
            recentFiles: recentFiles,
            not_found_data: not_found_data || null,
            is_data_come: is_data_come
        },
    }
};

// set path contents from api and recent files should not come
export const setFolderPathContents = (data: any, isForScrolling?: any) => {
    const {
        contentDetails, nextDate,
        // recentFiles,
        not_found_data, is_data_come
    } = data || {};
    return {
        type: Constants.SET_FOLDER_PATH_CONTENTS,
        payload: {
            contentDetails: contentDetails || [],
            contentNextDate: nextDate,
            // recentFiles: recentFiles,
            isForScrolling,
            not_found_data: not_found_data || null,
            is_data_come: is_data_come
        },
    }
};


// ===== new requirements of contents =======

export const setIsContentComing = (data?: any) => {
    return {
        type: "SET_IS_CONTENT_COMING",
        payload: data,
    }
};

export const setUpdatableContentBeforeSaving = (data?: any) => {
    return {
        type: "SET_UPDATABLE_CONTENT_BEFORE_SAVING",
        payload: {
            ...data
        },
    }
};

export const updateContentBeforeSaving = (data?: any) => {
    return {
        type: "UPDATE_CONTENT_BEFORE_SAVING",
        payload: {
            key: data.key,
            value: data.value
        },
    }
};

export const saveContentDetails = (data?: any, content_idx?: any) => {
    return {
        type: Constants.SAVE_CONTENT_DETAILS,
        payload: { data, content_idx },
    }
};

export const setContentDetails = (data?: any, content_idx?: any) => {
    return {
        type: Constants.SET_CONTENT_DETAILS,
        payload: { data, content_idx },
    }
};

export const setIsContentUploadedError = (data: any) => {
    return {
        type: "SET_IS_CONTENT_UPLOADED_ERROR",
        payload: data
    }
}

export const setUploadProgressCount = (data: any) => {
    return {
        type: "SET_UPLOADING_PROGRESS_COUNT",
        payload: data
    }
}

export const setShowingUploadingProgress = (data: any, uploading_file?: any) => {
    return {
        type: "SET_SHOW_UPLOADING_PROGRESS",
        payload: data,
        uploading_file: uploading_file,
    }
}

export const setShowingSaveButton = (data: any) => {
    return {
        type: "SET_SHOWING_SAVE_BUTTON",
        payload: data
    }
}

// ======================= Bio Page Folder - Start =====================
export const getBioPageFolders = (data = {}) => {
    return {
        type: Constants.GET_BIO_PAGE_FOLDERS,
        payload: {
            ...data,
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: true,
            folderPath: ""
        }
    }
};

export const setBioPageFolders = (data?: any) => {
    return {
        type: Constants.SET_BIO_PAGE_FOLDERS,
        payload: data
    }
};

// ======================= Bio Page Folder - End =====================
// ======================= Folder - Start =====================

export const toggleCreateFolderModal = (data: boolean) => {
    return {
        type: "TOGGLE_CREATE_FOLDER_MODAL",
        payload: data
    }
}

export const createContentFolder = (data: any, folderNextDate: any) => {
    return {
        type: Constants.CREATE_FOLDER,
        payload: data,
        folderNextDate,
    };
}

export const addNewFolder = (data: any) => {
    return {
        type: Constants.ADD_NEW_FOLDER,
        payload: data
    }
};

export const getFirstTimeFolders = (data?: any) => {
    return {
        type: Constants.GET_FIRST_TIME_FOLDERS,
        payload: {
            ...data,
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: true,
            folderPath: ""
        }
    }
}

export const setFirstTimeFolders = (data: any) => {
    return {
        type: Constants.SET_FIRST_TIME_FOLDERS,
        payload: data
    }
}

export const getFolders = (data?: any, accessed_data = {}) => {
    return {
        type: Constants.GET_FOLDERS,
        payload: {
            ...accessed_data,
            createdOn: data?.nextDate,
            isFolder: true,
            folderPath: ""
        }
    }
};

export const setFolders = (data?: any) => {
    return {
        type: Constants.SET_ALL_FOLDERS,
        payload: data
    }
};

// it will be taken last
export const deleteFolders = (folderIds?: any, accessed_data = {}) => {
    return {
        type: Constants.DELETE_FOLDERS,
        payload: {
            payload_object: {
                ...accessed_data,
                // comma separated string content ids to delete
                contentId: folderIds?.map((id: any) => id).join(','),
                isFolderDelete: true,
            },
            folder_ids: folderIds
        }
    }
};

export const removeFolders = (data?: any, message = "Deleted Successfully") => {
    return {
        type: Constants.REMOVE_FOLDERS,
        payload: data,
        message
    }
};

export const setSearchFolderText = (data?: any) => {
    return {
        type: Constants.SET_SEARCH_FOLDER_TEXT,
        payload: data
    }
};

// =================== Folder - End   ====================

// =================== Content (video / image) - Start ====================

export const setSearchContentText = (data: any) => {
    return {
        type: Constants.SET_SEARCH_CONTENT_TEXT,
        payload: data
    }
}

export const setDefaultContentIdx = (data: any) => {
    return {
        type: Constants.SET_DEFAULT_CONTENT_INDEX,
        payload: data,
    }
}

export const setSelectedDeletableContents = (data: any) => {
    return {
        type: Constants.SET_SELECTED_DELETABLE_CONTENTS,
        payload: data
    }
}

export const getOneTimeFolderContents = (data?: any, accessed_data = {}) => {
    return {
        type: Constants.GET_ONE_TIME_FOLDER_CONTENTS,
        payload: {
            ...accessed_data,
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: false,
            folderPath: data?.folderName || ""
        }
    }
};

export const setOneTimeFolderContents = (data: any) => {
    return {
        type: Constants.SET_ONE_TIME_FOLDER_CONTENTS,
        payload: data
    }
};

// Scrolling to get more content data
export const getFolderContents = (data?: any, accessed_data = {}) => {
    return {
        type: Constants.GET_FOLDER_CONTENTS,
        payload: {
            ...accessed_data,
            createdOn: data?.nextDate,
            isFolder: false,
            folderPath: data?.folderName || ""
        }
    }
};

export const setFolderContents = (data?: any) => {
    return {
        type: Constants.SET_FOLDER_CONTENTS,
        payload: data
    }
};


// content

export const setSortFolderListSortType = (data?: any) => {
    return {
        type: "SET_SORTING_FOLDER_LIST_TYPE",
        payload: data
    }
}

export const setSortContentListSortType = (data?: any) => {
    return {
        type: "SET_SORTING_CONTENT_LIST_TYPE",
        payload: data
    }
}

export const createFolderContent = (data?: any, file?: any) => {
    return {
        type: Constants.CREATE_FOLDER_CONTENT,
        payload: data,
        contentFile: file,
    }
};

export const updateFolderContentNewVersion = (data?: any, file?: any, content_data?: any) => {
    return {
        type: Constants.UPDATE_FOLDER_CONTENT_NEW_VERSION,
        payload: data,
        contentFile: file,
        content_data
    }
};

export const uploadContentFileByAwsLink = (data?: any) => {
    return {
        type: Constants.AWS_PRE_ASSIGN_LINK,
        payload: data
    }
}

export const setFolderContent = (data?: any) => {
    return {
        type: Constants.SET_FOLDER_CONTENT,
        payload: data
    }
};

export const setIsUpdatedContent = (data?: any) => {
    return {
        type: "SET_IS_UPDATED_CONTENT",
        payload: data
    }
};

export const updateFolderContent = (payloadObject?: any, data?: any) => {
    return {
        type: Constants.UPDATE_FOLDER_CONTENT,
        payload: payloadObject,
        data
    }
};

export const setUpdateFolderContent = (data?: any) => {
    return {
        type: Constants.SET_UPDATE_FOLDER_CONTENT,
        payload: data
    }
};


export const deleteContents = (contentIds?: any, accessed_data = {}) => {
    return {
        type: Constants.DELETE_FOLDER_CONTENTS,
        payload: {
            payload_object: {
                ...accessed_data,
                // comma separated string content ids to delete
                contentId: contentIds?.map((id: any) => id).join(','),
                isFolderDelete: false,
            },
            content_ids: contentIds
        }
    }
};

export const removeContentsFromFolder = (data?: any, message = "Deleted Successfully") => {
    return {
        type: Constants.REMOVE_CONTENTS_FROM_FOLDER,
        payload: data,
        message,
    }
};

export const showDeleteMessage = (data?: any) => {
    return {
        type: "REMOVE_DELETE_MESSAGE",
        payload: data
    }
};

export const getContentVersions = (data?: any) => {
    return {
        type: Constants.GET_CONTENT_VERSIONS,
        payload: data
    }
};

export const setContentVersions = (data?: any) => {
    return {
        type: Constants.SET_CONTENT_VERSIONS,
        payload: data
    }
};

export const requestToRevertVersion = (data?: any) => {
    return {
        type: Constants.REQUEST_TO_REVERT_VERSION,
        payload: data
    }
};

export const setRevertVersionSuccessfully = (data?: any) => {
    return {
        type: "SET_REVERT_VERSION_SUCCESSFULLY",
        payload: data
    }
};

export const setViewContentData = (data?: any) => {
    return {
        type: "SET_VIEW_CONTENT_DATA",
        payload: data,
    }
}

export const resetNotFoundFlagAndDeletedContentFlags = () => {
    return {
        type: "RESET_NOT_FOUND_CONTENT_AND_DELETED_CONTENT_FLAGS"
    }
}

export const setIsDataCome = (data?: any) => {
    return {
        type: "SET_IS_DATA_COME",
        payload: data
    }
}

export const setNotFoundData = (data?: any) => {
    return {
        type: "SET_NOT_FOUND_DATA",
        payload: data
    }
}

// =================== Content (video / image) - End ====================

const mediaManagementReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "RESET_NOT_FOUND_CONTENT_AND_DELETED_CONTENT_FLAGS":
            return {
                ...state,
                // path_contents: [],
                // contentNextDate: null,
                // recentFiles: [],
                not_found_data: false,
                is_content_deleted: false
            };
        case "SET_NOT_FOUND_DATA":
            return { ...state, not_found_data: action.payload };
        case "SET_IS_DATA_COME":
            return { ...state, is_data_come: action.payload };
        case "SET_VIEW_CONTENT_DATA":
            return { ...state, view_content_data: action.payload };
        case Constants.SET_SEARCH_FOLDER_TEXT:
            return { ...state, searched_folder_text: action.payload };
        case Constants.SET_SEARCH_CONTENT_TEXT:
            return { ...state, searched_content_text: action.payload };

        case Constants.SET_CONTENT_DETAILS:
            const { data, content_idx } = action.payload;
            const { folder_contents } = state;
            const updatedFolderContents = [...folder_contents];
            updatedFolderContents[content_idx] = { ...updatedFolderContents[content_idx], ...data };
            return { ...state, folder_contents: updatedFolderContents };

        case Constants.SET_FOLDER_PATH_CONTENTS_PAYLOAD:
            return { ...state, folder_paths_payload: action.payload };

        case "SET_CONTENT_RECENT_FILES":
            return { ...state, recentFiles: action.payload };

        case Constants.SET_FOLDER_PATH_CONTENTS:
            const {
                contentDetails, contentNextDate, isForScrolling,
                // recentFiles,
                not_found_data, is_data_come
            } = action.payload;
            let expected_path_contents = contentDetails;
            if (isForScrolling) {
                expected_path_contents = [
                    ...state.path_contents,
                    ...expected_path_contents
                ]
            }
            return {
                ...state,
                path_contents: expected_path_contents,
                contentNextDate: contentNextDate,
                // recentFiles: recentFiles,
                not_found_data: not_found_data || null,
                is_data_come
            };

        case Constants.SET_FOLDER_PATH_CONTENTS_FROM_INDEX_DB:
            const {
                contentDetails: content_details_from_index_db,
                contentNextDate: content_next_date_from_index_db,
                recentFiles: recent_files_from_index_db,
                not_found_data: not_found_from_index_db,
                is_data_come: is_data_come_from_index_db
            } = action.payload;
            let expected_path_contents_from_indexDB = content_details_from_index_db;
            // console.log({ expected_path_contents_from_indexDB, current_stored_folder_key_content: action.payload });
            return {
                ...state,
                path_contents: expected_path_contents_from_indexDB,
                contentNextDate: content_next_date_from_index_db,
                recentFiles: recent_files_from_index_db,
                not_found_data: not_found_from_index_db || null,
                is_data_come: is_data_come_from_index_db
            };

        case Constants.SET_UP_FOLDER_PATHS_FOR_REDUX:
            return { ...state, folder_paths: action.payload };
        case "OPEN_GLOBAL_MODAL":
            return { ...state, open_global_modal: action.payload };
        case "OPEN_GLOBAL_DRAWER":
            return { ...state, open_global_drawer: action.payload };
        case "SET_IS_CONTENT_DELETED":
            return { ...state, is_content_deleted: action.payload };
        case "SHOW_GLOBAL_WARNING_DIALOG":
            return { ...state, showGlobalWarningDialog: action.payload };
        case "SET_IS_CONTENT_COMING":
            return { ...state, is_content_coming: action.payload };
        case "SET_UPDATABLE_CONTENT_BEFORE_SAVING":
            return { ...state, updatable_content: action.payload };

        case "UPDATE_CONTENT_BEFORE_SAVING":
            return {
                ...state,
                updatable_content: {
                    ...state.updatable_content,
                    [action.payload.key]: action.payload.value
                }
            };

        case Constants.SET_DEFAULT_CONTENT_INDEX:
            return { ...state, default_content_idx: action.payload };

        case Constants.SET_SELECTED_DELETABLE_CONTENTS:
            return {
                ...state,
                selected_deletable_contents: action.payload
            };

        case Constants.SET_ONE_TIME_FOLDER_CONTENTS:
            return { ...state, folder_contents: action.payload?.contents, contentNextDate: action.payload?.contentNextDate };

        case Constants.SET_FOLDER_CONTENTS:
            return { ...state, folder_contents: [...state.folder_contents, ...action.payload?.contents], contentNextDate: action.payload?.contentNextDate };

        case "SET_SORTING_FOLDER_LIST_TYPE":
            return {
                ...state,
                folder_sort_type: action.payload,
            }

        case "SET_SORTING_CONTENT_LIST_TYPE":
            return {
                ...state,
                content_sort_type: action.payload,
            }
        case Constants.SET_FOLDER_CONTENT:
            return { ...state, folder_contents: [action.payload, ...state.folder_contents] };

        case Constants.SET_UPDATE_FOLDER_CONTENT:
            return {
                ...state,
                folder_contents: state?.folder_contents?.map((item: any) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };

        case Constants.REMOVE_CONTENTS_FROM_FOLDER:
            return {
                ...state,
                // folder_contents: state.folder_contents.filter((item: any) => item.id !== action.payload)
                // payload is an array of id
                folder_contents: state.folder_contents.filter((item: any) => !action.payload.includes(item.id)),
                selected_deletable_contents: [],
                delete_message: action.message,
            };

        case Constants.REMOVE_FOLDERS:
            // return { ...state, all_folders: state.all_folders.filter((item: any) => item.id !== action.payload) };
            return {
                ...state,
                all_folders: state.all_folders.filter((item: any) => !action.payload.includes(item.id)),
                delete_message: action.message,
            };

        case Constants.ADD_NEW_FOLDER:
            return { ...state, all_folders: [action.payload, ...state.all_folders] }

        case Constants.SET_FIRST_TIME_FOLDERS:
            return { ...state, all_folders: action.payload?.folders, folderNextDate: action.payload?.contentNextDate };

        case Constants.SET_ALL_FOLDERS:
            return {
                ...state,
                all_folders: [...action?.payload?.folders, ...state?.all_folders],
                folderNextDate: action?.payload?.folderNextDate
            };

        case Constants.SET_BIO_PAGE_FOLDERS:
            return { ...state, bio_page_folders: action.payload.folders, bio_page_folder_nextDate: action.payload.folderNextDate };

        case "SET_IS_UPDATED_CONTENT":
            return { ...state, is_updated_folder_content: action.payload }

        case "SET_IS_CONTENT_UPLOADED_ERROR":
            return { ...state, is_content_uploading_error: action.payload }

        case "TOGGLE_CREATE_FOLDER_MODAL":
            return { ...state, is_showing_create_folder: action.payload }

        case "SET_SHOWING_SAVE_BUTTON":
            return { ...state, showing_save_button: action.payload }

        case "SET_SHOW_UPLOADING_PROGRESS":
            return {
                ...state,
                show_uploading_progress: action.payload,
                uploading_file: action.uploading_file || null,
            }

        case "SET_UPLOADING_PROGRESS_COUNT":
            return { ...state, uploadingProgressCount: action.payload }

        case "REMOVE_DELETE_MESSAGE":
            return { ...state, delete_message: null }
        case Constants.SET_CONTENT_VERSIONS:
            return { ...state, content_versions: action.payload }
        case "SET_REVERT_VERSION_SUCCESSFULLY":
            return { ...state, is_reverted_version_successfully: action.payload }
        case Constants.LOGOUT:
            return {
                ...state,
                bio_page_folders: [],
                all_folders: [],
                folderNextDate: null,
                folder_contents: [],
                view_content_data: null,
                folder_paths_payload: null,
                path_contents: [],
                folder_paths: [],
                contentNextDate: null,
                is_reverted_version_successfully: false,
                open_global_modal: null,
                open_global_drawer: null,
                is_content_deleted: false,
                showGlobalWarningDialog: null,
                is_content_coming: false,
                updatable_content: {
                    clapupUserId: null,
                    workspaceId: null,
                    roleId: null,
                    contentId: "",
                    properties_Keywords: "",
                    sEO_UrlSlug: "",
                    sEO_Desc: "",
                    sEO_Title: "",
                    bioPage_Caption: "",
                    bioPage_Title: "",
                    bioPage_Story: ""
                },
                is_updated_folder_content: false,
                selected_deletable_contents: [],
                content_versions: {
                    currentVersion: {},
                    previousVersions: []
                },
                default_content_idx: null,
                showing_save_button: true,
                show_uploading_progress: false,
                uploading_file: null,
                is_showing_create_folder: false,
                is_content_uploading_error: false,
                uploadingProgressCount: 0,
                delete_message: null,
                folder_sort_type: null,
                content_sort_type: null,
            };
        default:
            return state;
    }
}

export default mediaManagementReducer;