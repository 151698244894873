import Constants from "../../../constants";

const initialState = {
    setting_teams: [],
    is_got_new_setting_team_list: false,
    editable_team: null,
    team_tab: "Team Members",
    is_ready_to_add_team_member_user: false,
    setting_channels: [],
    team_channels: [],
    team_users: [],
    team_emails_by_team_id: [],
    setting_users: [],
    is_ready_to_add_channel: false,
    editable_team_channel: null,
}

// Team Creation....
export const requestForGettingTeamChannels = (workspaceId?: any) => {
    return {
        type: Constants.REQUEST_FOR_GETTING_TEAM_CHANNELS,
        payload: { workspaceId }
    };
}

export const requestForGettingTeamUsers = (workspaceId?: any) => {
    return {
        type: Constants.REQUEST_FOR_GETTING_TEAM_USERS,
        payload: { workspaceId }
    };
}

export const setSettingUsers = (data: any) => {
    return {
        type: Constants.SET_SETTING_USERS,
        payload: data
    };
}

export const setSettingTeamChannels = (data: any) => {
    return {
        type: Constants.SET_SETTING_TEAM_CHANNELS,
        payload: data
    };
}

export const requestForAddingTeamMember = (data: any) => {
    return {
        type: Constants.REQUEST_FOR_ADDING_TEAM_MEMBERS,
        payload: data
    };
}

export const triggerRemoveTeamMember = (data: any) => {
    return {
        type: "REMOVE_TEAM_MEMBER",
        payload: data
    };
}

export const triggerRemoveTeamChannel = (data: any) => {
    return {
        type: "REMOVE_TEAM_CHANNEL",
        payload: data
    };
}

export const requestForAddingTeamChannel = (data: any) => {
    return {
        type: Constants.REQUEST_FOR_ADDING_TEAM_CHANNELS,
        payload: data
    };
}

export const requestForDeletingTeam = (data: any) => {
    return {
        type: "REQ_FOR_DELETING_TEAM",
        payload: data
    };
}

export const toggleUpdatedTeamContent = (data: any) => {
    return {
        type: "TOGGLE_UPDATED_TEAM_CONTENT",
        payload: data
    };
}

export const requestForCreatingTeam = (data: any) => {
    return {
        type: Constants.REQUEST_FOR_CREATING_TEAM,
        payload: { ...data },
    };
}

export const requestForUpdatingTeamSettings = (data: any) => {
    return {
        type: Constants.REQUEST_FOR_UPDATING_TEAM,
        payload: data,
    };
}

export const requestForGettingTeamEmails = (data: any) => {
    return {
        type: Constants.REQUEST_FOR_GETTING_TEAM_EMAILS,
        payload: data,
    };
}

export const setTeamEmails = (data: any) => {
    return {
        type: Constants.SET_TEAM_EMAILS,
        payload: data,
    };
}

export const toggleGotNewSettingTeamList = (data: any) => {
    return {
        type: "TOGGLE_GOT_NEW_SETTING_TEAM_LIST",
        payload: data,
    };
}

export const getTeamList = (data?: any) => {
    return {
        type: Constants.GET_TEAM_LIST,
        payload: data,
    };
}

export const setTeamList = (data: any) => {
    return {
        type: Constants.SET_TEAM_LIST,
        payload: data,
    };
}

export const setTeamTab = (data: any = null) => {
    return {
        type: Constants.SET_TEAM_TAB,
        payload: data,
    };
}

export const setEditableTeam = (data: any = null) => {
    return {
        type: Constants.SET_EDITABLE_TEAM,
        payload: data,
    };
}

// Channel
export const updateChannelSetting = (data: any) => {
    return {
        type: "UPDATE_TEAM_CHANNEL_SETTING",
        payload: data
    }
}


export const setEditableTeamChannel = (data: any = null) => {
    return {
        type: Constants.SET_EDITABLE_TEAM_CHANNEL,
        payload: data,
    };
}

export const setReadyToAddChannel = (data: any = null) => {
    return {
        type: Constants.SET_READY_TO_ADD_CHANNEL,
        payload: data,
    };
}

// ===== Team member users and channels =====
export const getSettingTeamUserAndChannelList = (team_data: any) => {
    return {
        type: Constants.GET_TEAM_USER_AND_CHANNEL_LIST,
        payload: { ...team_data }
    }
}

export const setSettingTeamUserList = (data: any) => {
    return {
        type: Constants.SET_TEAM_USER_LIST,
        payload: data
    }
}

export const setSettingTeamChannelList = (data: any) => {
    return {
        type: Constants.SET_TEAM_CHANNEL_LIST,
        payload: data
    }
}
// ===== Team member users and channels =====
export const setReadyToTeamMemberUser = (data: any = null) => {
    return {
        type: Constants.SET_READY_TO_TEAM_USER,
        payload: data,
    };
}

export const resetTeamTab = (data?: any) => {
    return {
        type: "RESET_TEAM_TAB",
        payload: data
    };
}

const teamsReducer = (state: any = initialState, action: any) => {
    // console.log({user_reducer_action_data: action});

    switch (action.type) {
        case "TOGGLE_UPDATED_TEAM_CONTENT":
            return { ...state, is_updated_team_content: action.payload }
        case "TOGGLE_GOT_NEW_SETTING_TEAM_LIST":
            return { ...state, is_got_new_setting_team_list: action.payload }
        case Constants.SET_TEAM_LIST:
            return { ...state, setting_teams: action.payload, is_got_new_setting_team_list: true }
        case Constants.SET_READY_TO_ADD_CHANNEL:
            return { ...state, is_ready_to_add_channel: action.payload };
        case Constants.SET_READY_TO_TEAM_USER:
            return { ...state, is_ready_to_add_team_member_user: action.payload };

        case Constants.SET_TEAM_EMAILS:
            return { ...state, team_emails_by_team_id: action.payload };

        case Constants.SET_TEAM_USER_LIST:
            return { ...state, team_users: action.payload };

        case Constants.SET_TEAM_CHANNEL_LIST:
            return { ...state, team_channels: action.payload };

        case Constants.SET_SETTING_USERS:
            return { ...state, setting_users: action.payload };

        case Constants.SET_SETTING_TEAM_CHANNELS:
            return { ...state, setting_channels: action.payload };


        case Constants.SET_EDITABLE_TEAM_CHANNEL:
            return { ...state, editable_team_channel: action.payload };
        case Constants.SET_TEAM_TAB:
            return { ...state, team_tab: action.payload };
        case Constants.SET_EDITABLE_TEAM:
            return {
                ...state,
                editable_team: action.payload,
                editable_team_channel: action.payload ? state.editable_team_channel : null,
                team_tab: action.payload ? state.team_tab : "Team Members",
            };
        case "RESET_TEAM_TAB":
            return {
                ...state,
                editable_team: null,
                editable_team_channel: null,
                team_users: [],
                team_channels: [],
                team_tab: "Team Members",
            };

        case Constants.LOGOUT:
            return {
                ...state,
                setting_teams: [],
                setting_channels: [],
                setting_users: [],
                team_channels: [],
                team_users: [],
                team_emails_by_team_id: [],
                editable_team: null,
                team_tab: "Team Members",
                editable_team_channel: null,
                is_ready_to_add_team_member_user: false,
                is_ready_to_add_channel: false,
                is_got_new_setting_team_list: false,
            };

        default:
            return state;
    }
}

export default teamsReducer;