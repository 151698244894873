import React, { useState } from 'react';
import './ContentViewer.scss';

const ImageViewer = ({ content }: any) => {
    return (
    <React.Fragment>
        <div className="image-viewer-container">
            <img src={content?.presignedUrl} alt="content-image" />
        </div>
    </React.Fragment>
  )
}

export default ImageViewer;