import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import './CommonModal.scss';
import { Modal } from 'antd';


const CommonModalCustomWidth = ({title, okText, cancelText, footer, modal_visible, setModalVisible, children, padding, closeIcon = false, centered, border = false, height = null, class_name = ""}: any) => {
    const [visible, setVisible]: any = React.useState(false);
    // const dispatch = useDispatch();
    useEffect(() => {
        // console.log('modal_visible', modal_visible);
        modal_visible ? setVisible(modal_visible) : setVisible(false);
    }, [modal_visible]);

    const handleOk = ({e}: any) => {
        e?.preventDefault();
        console.log({values: e});
        // setModalVisible(false);
    };
    return (
        <React.Fragment>
            <Modal
                title={title || ""}
                centered={centered || false}
                closable={closeIcon}
                closeIcon={closeIcon}
                open={visible}
                // border={border}
                onOk={(e: any) => {
                    console.log({e: e.target});
                    handleOk(e);
                }}
                okText={okText || "Continue"}
                onCancel={() => {
                    // setVisible(false);
                    setModalVisible(false);
                }}
                cancelText={cancelText || "Cancel"}
                footer={footer}
                // className="custom-modal-body"
                style={{ padding: padding || '20px 24px', width: 'fit-content'}}
                className={`${class_name}`}
                
            >
                {
                    children
                }
            </Modal>
        </React.Fragment>
    );
};

CommonModalCustomWidth.defaultProps = {
    title: '',
    width: 0,
    visible: false,
    onCancel: () => { },
    okText: "Ok",
    cancelText: "Cancel",
    footer: null,
    modal_visible: false, setModalVisible: () => { },
    children: null,
    padding: "0",
    closeIcon: false,
    centered: false,
    border: false,
    height: null,
    class_name: ""
};

export default CommonModalCustomWidth;