export const convertImageToBase64 = (file: File) => {
    return new Promise(resolve => {
        let baseURL: any = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load something...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader?.result;
            console.log({ baseURL });
            resolve(baseURL);
        };
    });
}

const capitalizeSentence = (sentence = "") => {

    return sentence?.split(' ')
        ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        ?.join(' ');

};

const checkVariable = (variable: string): boolean => {
    // const variable_regex = /^[a-zA-Z0-9_]*$/;
    // return variable_regex.test(variable);
    const identifierRegex = /^[a-zA-Z_][a-zA-Z_0-9]*$/;
    return identifierRegex.test(variable);
};

const normalizeString = (value: string): string => {
    return value?.replaceAll("</p><p>", " ")
        ?.replaceAll("<p>", "")
        ?.replaceAll("</p>", "")
        ?.replaceAll("<br>", "")
        ?.trim()
        ?.replace(/\s+/g, ' ');
};

// "5/8/2024 1:04:46AM"
const convertISODate = (value: any) => {
    if (!value) {
        return null;
    }
    // console.log({
    //     given_value: value,
    //     expected_value: new Date(value).toLocaleString(),
    //     current_time: new Date().toLocaleString()
    // });
    try {
        const dateString = value;
        const [datePart, timePart] = dateString.split(' ');
        const [month, day, year] = datePart.split('/');
        const [time, period] = timePart.match(/(\d+:\d+:\d+)(AM|PM)/).slice(1, 3);

        let [hours, minutes, seconds] = time.split(':').map(Number);
        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;

        const date = new Date(year, month - 1, day, hours, minutes, seconds).toUTCString();
        // if (new Date(value)) {
        //     console.log({ date, Date: new Date(value)?.toISOString() });
        //     return new Date(value);
        // }
        // console.log({ date });
        return date;
    } catch (err: any) {
        // console.log({ err });
        return new Date(value).toUTCString();
    }
}

const takingCharacterAndUnderScoreAndNumberOnlyOneSpace = (inputValue: string) => {
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s_]/g, '');
    // Replace multiple spaces with a single space
    const formattedValue = sanitizedValue.replace(/\s+/g, ' ');
    // Replace spaces with underscore
    // const templateName = formattedValue.replace(/\s/g, '_');
    // Removing Underscore
    // const expectedValue = formattedValue.replace(/\s/g, '');
    return formattedValue;
}

const takingCharacterAndUnderScoreAndNumber = (inputValue: string) => {
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s_]/g, '');
    // Replace multiple spaces with a single space
    const formattedValue = sanitizedValue.replace(/\s+/g, ' ');
    // Replace spaces with underscore
    // const templateName = formattedValue.replace(/\s/g, '_');
    // Removing Underscore
    // const expectedValue = formattedValue.replace(/\s/g, '');
    return formattedValue;
}

export { takingCharacterAndUnderScoreAndNumber, takingCharacterAndUnderScoreAndNumberOnlyOneSpace, convertISODate, capitalizeSentence, checkVariable, normalizeString };