import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {

  // // new requirements
  // setFolderPathsToIndexDB,
  setOpenGlobalDrawer,
} from '../reducer';
import { getImage } from '../../../unified/image_service';
import { setSuccessMessage } from '../../../unified/reducer';
import moment from 'moment';

const FolderEditorFields = ({ content, parentCallback }: any) => {
  const {
    // open_global_drawer,
    // open_global_modal,
  }: any = useSelector((state: RootState) => state?.contentState);
  
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;

  const handleCancel = () => {
    dispatch(setOpenGlobalDrawer(null));
  };

  const handleSave = () => {
    dispatch(setSuccessMessage(`${content?.isFolder ? "Folder" : "File"} Saved Successfully`));
    setTimeout(() => handleCancel(), 1000);
  }

  const formatDate = (date: any) => {
      // const date = "12/19/2024 5:15:50AM"; // input date
      try {
        if (!date) {
          return "No date";
        }
        // Parse the input date without applying local timezone offset
        const parsedDate = moment.utc(date, "M/D/YYYY h:mm:ssA", true);
        
        // Ensure the date is parsed as local and then convert to UTC
        const result = parsedDate.local().format("DD MMM YYYY, hh.mm A");
        // console.log({ input_date: date, result });
        return result; 
      } catch (err) {
        return "Wrong Date Format";
      }
  };

  return (
    <React.Fragment>
      <div className='folder-properties-container'>
        <div className="header-title-container">
          <div className="title">{content?.isFolder ? "Folder" : "File"} Properties</div>
          <img 
            onClick={() => handleCancel()}
            src={getImage("circle-cross-icon")}
            alt="cross-icon"
          />
        </div>
        <div className="properties-form">
          <div className="form-item">
            <label>{content?.isFolder ? "Folder" : "File"} Name</label>
            <input type="text"
              style={{ background: "#FFFFFF" }}
              placeholder={`Enter ${content?.isFolder ? "folder" : "file"} name`}
              readOnly={false}
              defaultValue={content?.contentFileName}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>
          <div className="form-item">
            <label>Created By</label>
            <input type="text"
              className='disabled-input'
              readOnly={true}
              // value={"MMHK"}
              value={content?.createdByName}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>
          <div className="form-item">
            <label>Created On</label>
            <input type="text"
              className='disabled-input'
              readOnly={true}
              // value={"16 June 2023, 14.23"}
              value={formatDate(content?.createdOn)}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>
          <div className="form-item">
            <label>Last Update</label>
            <input type="text"
              className='disabled-input'
              readOnly={true}
              title={formatDate(content?.updatedOn) + " by " + content?.updatedByName}
              // value={"16 June 2023, 14.23 by MMHK"}
              value={formatDate(content?.updatedOn) + " by " + content?.updatedByName}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>

          <div className="form-item">
            <label>{content?.isFolder ? "Folder" : "File"} Description</label>
            <textarea
              placeholder={`Add description for this ${content?.isFolder ? "folder" : "file"}`}
              readOnly={false}
              // value={folderDescription}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>
          <div className="form-item">
            <label>Key words for quick search</label>
            <textarea
              className='keyword-textarea'
              placeholder={`Add keywords, that can be used for quick searching and finding this ${content?.isFolder ? "folder" : "file"}`}
              readOnly={false}
              // value={folderKeyWords}
              onChange={(e: any) => {
                const { value } = e.target;
              }}
            />
          </div>
          <div className="action-buttons">
            <button
              onClick={() => handleCancel()}
              className="action-button"
            >
              Cancel
            </button>
            <button
              className="action-button"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FolderEditorFields;

