import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// import back_btn from '../../../../assets/icons/back.svg';
// import reply_icon from '../../../../assets/icons/reply-icon.svg';
// // import edit_icon from '../../../../assets/icons/edit-icon.svg';
// // import send_icon from '../../../../assets/icons/send-icon.svg';
// // import prev_icon from '../../../../assets/icons/previous-icon.svg';
// // import next_icon from '../../../../assets/icons/next-icon.svg';
// import emptyBox from '../../../../assets/img/Empty.svg';
// // import clapup_whatsapp_icon from '../../../../assets/icons/bot-icons/clapup-bot-icon.svg';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// // import start from '../../../../assets/icons/star-icon.svg';
// import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessMessage,
  setWhatsAppReplyingText,
  // triggerClosedWhatsApp,
  setAssignedUserIdOneTimeConversationChange,
  setIsCountDownOn,
  toggleReplyingOn,
  setAttachments,
  setInboxReplyText,
  assignUserIdForInboxEmailByMessageId,
  setErrorMessage,
} from '../../../../unified/reducer';
// import CustomCountdown from '../../../CustomCountdown/CustomCountdown';
// import BlinkingOneDot from '../../../Loaders/BlinkingOneDot/BlinkingOneDot';
// import MessagesDetails from '../AllDetails/MessagesDetails';
// import CustomRating from '../../../Loaders/CustomRatting/CustomRating';
import { replyToWhatsAppMessage } from '../../../../unified/saga';
import WhatsAppInfo from './WhatsAppInfo/WhatsAppInfo';
// import { RootState } from '../../../../store';
import {
  // getGenerateApiImageByName,
  getImage
} from '../../../../unified/image_service';
import ConversationCommonReply from './ConversationComponents/ConversationCommonReply';
import ConversationCommonBody from './ConversationComponents/ConversationCommonBody';
import ConversationCommonHeader from './ConversationComponents/ConversationCommonHeader';
import { clearStoredAttachmentFileData, deleteKeyValue } from '../../../../utils/indexedDBManagementService';
import { api_version, inbox_api_url } from '../../../../utils/server';
import { generateAccessToken } from '../../../../profile/reducer';
// import { getSettingTeamUserAndChannelList } from '../../../../pages/Settings/Teams/teamsReducer';

const AllMessagesMemberConversation = (props: any) => {
  const dispatch = useDispatch();
  const route_path = window.location.pathname;
  const [messages, setMessages] = useState<any[]>([]);
  const [isCountDownOn, _] = useState(false);
  const { logged_in_user_secret_data, accessed_user_details } = useSelector((state: any) => state?.login);
  
  // const [rating, setRating] = useState<any>(4);
  // const {
  //   isClosedWhatsAppInboxThread,
  //   // isCountDownOn,
  //   isReplyingOn,
  //   whats_app_sender_state
  // }: any = useSelector((state: RootState) => state?.unified);
  const {
    conversation: conversation_data, setIsReply, textRef, type,
    // type, statDetails,
    // handleReplaceConversation,
    // handlePrevPath,
    // handleReply, conversationId, isGoForDetails, handleDetailChange,
  } = props;
  const [isDisabledReplyingBySignalR, setIsDisabledReplyingBySignalR] = useState<any>(false);
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const {
    // setting_users, 
    // setting_channels,
    setting_teams, 
    team_channels
  } = useSelector((state: any) => state?.teamState);
    const { workspaces } = useSelector((state: any) => state?.headerState);
  const { updated_assigned_user_id, inbox_sender_state, isReplyingOn } = useSelector((state: any) => state?.unified);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  )?.workSpaceName ||  "";
  // Important and VIP SignalR
  useEffect(() => {
    // if (inbox_sender_state?.length) {
    // }
    // const isDisabledReplyingBySignalR = (inbox_sender_state?.find((item: any) => item?.messageId === conversation?.messageId && item?.ticket_serial_no === conversation?.ticket_serial_no && item?.ticket_no === conversation?.ticket_no));
    const isDisabledReplyingBySignalR = (inbox_sender_state?.find((item: any) => item?.messageId === conversation?.messageId && item?.ticket_no === conversation?.ticket_no));
    // console.log({ inbox_sender_state, isDisabledReplyingBySignalR });
    setIsDisabledReplyingBySignalR(isDisabledReplyingBySignalR ? true : false);
    
  }, [inbox_sender_state?.length]);
  const [conversation, setConversation] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior (form submission)
      // handleSubmit();
    }
  };

  useEffect(() => { 
    if (isCountDownOn) {
      setTimeout(() => { 
        dispatch(setIsCountDownOn(false));
        // dispatch(setWhatsAppReplyingText(''));
      }, 600000); // 10 mins = 600 sec
    }
  }, [isCountDownOn]);

  // const handleInputChange = (value: any) => {
  //   // const { value } = e.target;
  //   setInputValue(value);
  //   dispatch(setWhatsAppReplyingText(value));
  //   dispatch(setIsCountDownOn(true));
  //   // setReplyText(e.target.value);
  // };

  const handleToReplyMessage = async(replyData?: any) => {
    const {
      attachments,
      inputValue: replyMessage,
      fromPhoneNumber, toPhoneNumber,
    }: any = replyData;
    console.log({ conversation, attachments, replyData });
    return;
    if (replyMessage?.trim()) {
      const replyObject: any = {
        // fromPhoneNumber: fromPhoneNumber || "",
        // toPhoneNumber: toPhoneNumber || "",
        fromPhoneNumber: toPhoneNumber || "",
        toPhoneNumber: fromPhoneNumber || "",
        type: "text",
        replyMessage: replyMessage || "",
        workspaceId: logged_in_user_secret_data?.wuid,
        clapupUserId: logged_in_user_secret_data?.cuid,
        templateId: "",
        // rating: rating,
        // ticketNo: ticketNo || "",
      };
      // console.log({ replyObject });
      const result = await replyToWhatsAppMessage(replyObject);
      // const result = true;
      console.log({result});
      if (result) {
        // setReplyText("");
        setIsReply(false);
        dispatch(toggleReplyingOn(false));
        const { ticketNo, userName } = conversation;
        const newMessage = {
          ticketNo,
          // userName,
          userName: workspaceSettings?.workSpaceName || workSpaceName,
          message: inputValue,
          // rating,
          createdOn: new Date().toISOString(),
        };
        setMessages([newMessage, ...messages]);

        // Reset the input value or update your state as needed
        setInputValue('');
        dispatch(setWhatsAppReplyingText(''));
        dispatch(setIsCountDownOn(false));
      }
    } 
  }
  
  // const closeReplying = () => {
  //   const closed_object = {
  //     userName: conversation?.userName,
  //     ticketNo: conversation?.ticketNo,
  //     status: "closed",
  //   };
  //   dispatch(setIsCountDownOn(false));
  //   dispatch(setWhatsAppReplyingText(''));
  //   dispatch(triggerClosedWhatsApp(closed_object));
  // }

  const handleSubmit = () => {
    // Perform your submit logic here
    // console.log('Submitted: ', { inputValue, conversation, messages });
    if (!inputValue?.trim()) {
      return;
    }
    // handleToReplyMessage({...conversation, inputValue});
    // handleReply({...conversation, inputValue, rating});
  };

  // clicking particular message reply button
  const { messageId, ticket_no, ticket_serial_no } = conversation || {};

  const handleAssignedUserForDetails = async (reply_message_data?: any) => {
    // console.log({ conversation, reply_message_data });
    // last message data or selected message data for replying
    if (!reply_message_data) {
      return;
    }
    if (conversation?.messageStatus !== "open") {
      dispatch(toggleReplyingOn(reply_message_data || true)); // it may be removable
      return;
    }
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!clapupUserId || !workspaceId) {
          return;
      }
      const data_object = {
          clapupUserId, workspaceId,
          assigningUserId: conversation?.logged_in_user_id,
          ticketNo: ticket_no,
          teamId: conversation?.teamId,
          channelId: conversation?.channelId,
      }
      const url = inbox_api_url + `/api/${api_version}/teams/AssignEmailToUser`;
      const { status } = await axios.post(url, data_object, {
          headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
          }
      });
      if (status === 200) {
        // dispatch(setSuccessMessage("Assigned Successfully"));
        // I have to update inbox item for assignedUserId by message id
        // await setKeyValue("isReplying", true);
        dispatch(toggleReplyingOn(reply_message_data || true));
        // console.log({ reply_message_data });
        dispatch(assignUserIdForInboxEmailByMessageId(conversation, conversation?.logged_in_user_id, route_path));
      }

    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            dispatch(generateAccessToken());
        } else {
            dispatch(setErrorMessage("Something went wrong"));
        }
    }
  };

  // messages insertion in local state 
  useEffect(() => {
    console.log({conversation_data});
    try {
      dispatch(setAssignedUserIdOneTimeConversationChange(null));
      setConversation(conversation_data);
      if (conversation_data?.ticketNoWiseDetails?.length) {
        setMessages([]);
        const current_messages = conversation_data?.ticketNoWiseDetails?.filter((item: any) => item?.ticketNo === conversation_data?.ticket_no);
        setTimeout(() => setMessages(current_messages), 500);
        
        // setMessages(conversation_data?.messages?.reverse())
        // dispatch(setWhatsAppReplyingText(null));
        setInputValue("");
      } else {
        setMessages([]);
        // dispatch(setWhatsAppReplyingText(null));
        setInputValue("");
      }
    } catch (err: any) {
      console.log({ err });
          
    }
  }, [conversation_data?.messageId, conversation_data?.ticket_no, conversation_data?.ticket_serial_no]);

  // const [sender, setSender] = useState<any>(null);
  // useEffect(() => {
  //   if (whats_app_sender_state) {
  //     setSender(whats_app_sender_state?.sender_name);
  //   } else {
  //     setSender(null);
  //   }
  // }, [whats_app_sender_state]);

  const test_data = {
    "ticketNo": "69b1aa46-1a93-11ef-8382-6adf3b91f978",
    "userName": "Clapup",
    "message": "{\"header\":\"Hi {{Kuber}}\",\"body\":\"<p>Hello Kuber</p><p><br></p><p>Festival of Kerala is Nearing! You will be excited to shop with our fabulous products and avail 20% discount on all new arrival products. Use the code SYSINF123. </p><p><br></p><p>Happy purchaing!</p>\"}",
    "createdOn": "25-05-2024 12:36:30",
    "messageType": "campaign",
    "campaignString": {
      "header": "https://oss-ycloud-publicread.oss-ap-southeast-1.aliyuncs.com/api-docs/sample/sample.jpg",
      "body": "<p>Hello Kuber</p><p><br></p><p>Festival of Kerala is Nearing! You will be excited to shop with our fabulous products and avail 20% discount on all new arrival products. Use the code SYSINF123. </p><p><br></p><p>Happy purchaing!</p>"
    }
  };

  // const [isForReplyingUser, __] = useState<any>();
  // Automatically scroll to the bottom of the conversation when a new message is added
  const scrollRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState<any>(false);

  // Check if the content is overflowing
  useEffect(() => {
    // console.log({ client_height: scrollRef?.current?.clientHeight, scrollHeight: scrollRef.current.scrollHeight });
    if (scrollRef.current) {
      const isContentOverflowing = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
      setIsOverflowing(isContentOverflowing);
      
      // Scroll to the bottom only if content is overflowing
      if (isContentOverflowing) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [messages?.length, scrollRef?.current?.clientHeight]);

  useEffect(() => { 
    if (updated_assigned_user_id) {
      const current_conversation_data = {
        ...conversation,
        assignedUserId: updated_assigned_user_id,

      }
      // console.log({conversation, current_conversation_data});
      
      setConversation(current_conversation_data);
      dispatch(setAssignedUserIdOneTimeConversationChange(null));
    }
  }, [updated_assigned_user_id]);
  // const getRandomService = (): string => {
  //     const randomNumber = Math.floor(Math.random() * 100); // Generates a random number between 0 and 99
  //     return randomNumber % 2 === 0 ? "whatsapp" : "google";
  // };

  // const provider = getRandomService();
  // console.log({ isDisabledReplyingBySignalR });
  
  const { attachments: attachment_files, inboxReplyText } = useSelector((state: any) => state?.unified);


  const sendFileInChunks = async (file: any) => {
    try {
      const baseChunkSize = 512 * 1024; // 512 KB
      const fileSize = file.size;
  
      // Calculate chunk size dynamically based on the file size
      const totalChunks = Math.ceil(fileSize / baseChunkSize);
      const chunkSize = Math.ceil(fileSize / totalChunks);
  
      if (totalChunks === 1) {
        // Single chunk upload for files <= 512 KB
        const formData = new FormData();
        formData.append("FileName", file.name);
        formData.append("FileSize", file.size.toString());
        formData.append("ChunkIndex", "0");
        formData.append("TotalChunks", "1");
  
        // Convert file to Base64 or required format
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            const chunkData = reader.result?.toString() || "";
            formData.append("ChunkData", chunkData);
  
            axios
              .post(`${inbox_api_url}/api/${api_version}/teams/UploadAttachmentsAsChunks`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then(resolve)
              .catch(reject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file); // Read the whole file as Base64
        });
      }
  
      // Process the file in chunks for files > 512 KB
      const chunkPromises = Array.from({ length: totalChunks }).map((_, chunkIndex) => {
        const chunkStart = chunkIndex * chunkSize;
        const chunkEnd = Math.min(chunkStart + chunkSize, fileSize);
        const fileChunk = file.slice(chunkStart, chunkEnd);
  
        const formData = new FormData();
        formData.append("FileName", file.name);
        formData.append("FileSize", file.size.toString());
        formData.append("ChunkIndex", chunkIndex.toString());
        formData.append("TotalChunks", totalChunks.toString());
  
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const chunkData = reader.result?.toString() || "";
            formData.append("ChunkData", chunkData);
  
            axios
              .post(`${inbox_api_url}/api/${api_version}/teams/UploadAttachmentsAsChunks`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then(resolve)
              .catch(reject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(fileChunk); // Read the chunk as Base64
        });
      });
  
      return Promise.all(chunkPromises);
    } catch (err: any) {
      console.log({ err });
      return false;
    }
  };
  
  
  const sendEmailWithAttachments = async (conversationData: any, files: any) => {
    // const uploading_time = Date.now().toString(); // Unique identifier for this email
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return;
      }

      // Uploading all file attachments
      const uploadPromises = files?.map((file: any) => {
        // const fileId = `${file.name?.replaceAll(" ", "-")}-${uploading_time}`;
        return sendFileInChunks(file);
      });
    
      // Wait for all file uploads to resolve
      const res_data = await Promise.all(uploadPromises);
      // console.log({ res_data, team_channels });
      if (!res_data) {
        return;
      }
      const {
        inboxReplyText,
        messageId, ticket_no:
        ticketNo, ticket_serial_no,
        teamId, threadId,
        channelId, message_details,
        messageFromEmail, 
      } = conversationData || {};
      const {toList, bccList, ccList, messageSubject} = message_details || {};
      // console.log({ team_channels, message_details, conversationData, toList, bccList, ccList, ticketNo, channelId});
      
      const file_attachment_objects = files?.map((file: any) => {
        // const fileId = `${file.name?.replaceAll(" ", "-")}-${uploading_time}`;
        return {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        };
      });


      const conversation_channel = team_channels?.find((item: any) => item?.channelId === channelId);
      const conversation_team = setting_teams?.find((item: any) => item?.teamId === teamId);

      const toRecipients = toList?.map((item: any) => (
        {
          identifier: item?.email
        }
      ));
      
      const ccRecipients = ccList?.map((item: any) => (
        {
          identifier: item?.email
        }
      ));
      
      const bccRecipients: any[] = bccList?.map((item: any) => (
        {
          identifier: item?.email
        }
      ));

      if (conversation_channel?.autoBCCEmailId) {
        bccRecipients.push({identifier: conversation_channel?.autoBCCEmailId })
      }

      const replyToEmailObject = {
        workspaceId: workspaceId,
        repliedUserId: clapupUserId,
        channelId: channelId || "",
        message: inboxReplyText || "",
        teamId: teamId || "",
        ticketNo: ticketNo || "",
        messageId: messageId || "",
        isAttachmentsPresent: files?.length ? true : false,
        repliedUserEmail: accessed_user_details?.email || "", // taking from channel id
        repliedUserDisplayName: accessed_user_details?.name || "",
        // repliedUserEmail: channel?.autoBCCEmailId || "", // taking from channel id
        subject: messageSubject || "", // if click big reply button, last message of subject otherwise a particular message subject, toList, bccList and ccList  
        toRecipients: toRecipients?.length ? toRecipients : [{ identifier: messageFromEmail } ],
        ccRecipients: ccRecipients?.length ? ccRecipients : [{ identifier: "" } ],
        bccRecipients: bccRecipients?.length ? bccRecipients : [{ identifier: "" } ],
        attachmentDetail: file_attachment_objects,
        // newly come in payload
        threadId: threadId || "",
        serialNo: ticket_serial_no || "",
        teamName: conversation_team?.teamName || "",
      };

      console.log({logged_in_user_secret_data, replyToEmailObject, conversation_channel, conversation_team});
      
      // return;
      // Send the email request once all uploads are complete
      const { data, status } = await axios.post(`${inbox_api_url}/api/${api_version}/teams/ReplyToEmail`, replyToEmailObject, {
        headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
        }
      });
    
      console.log("Email response: ", { data });
      if (status === 200) {
        dispatch(toggleReplyingOn(null));
        dispatch(setAttachments([]));
        dispatch(setInboxReplyText(null));
        // await deleteKeyValue("isReplying"); // not necessary
        await clearStoredAttachmentFileData();
      }
      // return response.data; // Return response if needed
      
    }catch(err: any){
      console.log({err});
    }
  };
  
  const handleSendMessageWithAttachment = async() => {
    // console.log({ attachment_files, inboxReplyText });
    if (!inboxReplyText?.trim() && !attachment_files?.length) {
      return;
    }
    const attachments = attachment_files?.map((item: any) => item?.file);
    console.log({ attachments, conversation_data: { ...conversation, message_details: isReplyingOn, inboxReplyText } });
    // return;
    sendEmailWithAttachments({ ...conversation, message_details: isReplyingOn, inboxReplyText }, attachments);
    
    // with attachments and without attachments use can send message
    // handleToReplyMessage({...conversation, inputValue});

  };
  const reply_flag = isDisabledReplyingBySignalR || !conversation?.messageStatus || (conversation?.messageStatus === "inProgress" && conversation?.logged_in_user_id !== conversation?.assignedUserId) || conversation?.messageStatus === "closed" || type === "shared-copies-messages";

  // console.log({workspaceSettings, reply_flag, isDisabledReplyingBySignalR, logged_in_user_id: conversation?.logged_in_user_id, assignedUserId: conversation?.assignedUserId, messageStatus: conversation?.messageStatus, type})
  return (
    <React.Fragment>
      <ConversationCommonHeader
        type={type}
        data_fields={{
          username_1: conversation?.userName || conversation?.messageFromEmail || "",
          username_2: conversation?.provider === "whatsapp" ? "Sycient Technology" : workspaceSettings?.workspaceName,
          user_profile_1: null, 
          user_profile_2:  conversation?.provider === "whatsapp" ? getImage("whatsapp") : workspaceSettings?.logo,
          // star_ratings, tag_text,  // optional props fields
          // action_buttons: !(conversation?.logged_in_user_id !== conversation?.assignedUserId || conversation?.messageStatus === "closed" || conversation?.messageStatus === "open" || type === "shared-copies-messages")
          action_buttons: (conversation?.logged_in_user_id !== conversation?.assignedUserId || conversation?.messageStatus === "closed" || conversation?.messageStatus === "open" || type === "shared-copies-messages")
                          ? null
                          : [
                              // {icon: <img src={getImage("tag-icon")} alt='tag-icon' /> }, 
                              { text: "Close", }, 
                              { text: "Share", }, 
                              { text: "Move" }
                            ], // optional props fields
          // action_buttons: null,
          provider: conversation?.provider === "whatsapp" ? "whatsapp-conversation-header-icon" : conversation?.provider,
        }}
        conversation={{...conversation, ticketNo: conversation?.ticket_no}}
        handleParentCallback={(data: any) => console.log({ data })}
      />
      <div className="conversation-data">
        <div
          className="conversation-container"
          ref={scrollRef}
          style={{justifyContent: isOverflowing ||  messages?.length > 0 ? "flex-start" : "flex-end"}}
        >
        {
          messages?.map((item: any, idx: any) => (
            item?.messageFromEmail === conversation?.logged_in_user_email
            // idx === 0
              ? <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username:  conversation?.provider === "whatsapp" ? conversation?.fromPhoneNumber : "You Replied",
                    user_profile: conversation?.provider === "whatsapp" ? getImage(conversation?.provider) : workspaceSettings?.logo,
                    star_ratings: null, // optional props fields
                    tag_text: conversation?.provider === "whatsapp"
                              ? "Sycient Technology"
                              : item?.toList?.length 
                              ? `To: ${item?.toList?.map((item: any) => item?.email)?.join(", ")} ${item?.ccList?.length ? `, Cc: ${item?.ccList?.map((item: any) => item?.email)?.join(", ")}` : ""} ${item?.bccList?.length ? `, Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}` : ""}`
                              : item?.ccList?.length 
                              ? `Cc: ${item?.ccList?.map((item: any) => item?.email)?.join(", ")} ${item?.bccList?.length ? `, Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}` : ""}`
                              : item?.bccList?.length 
                              ? `Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}`
                              : null,
                              // : `To: support@tezla.com, Cc: ceo@tezla.com`,  // optional props fields
                    date: item?.messageCreatedOn,
                    message_subject:  conversation?.provider === "whatsapp" ? null
                                      : item?.messageSubject ? `Sub: ${item?.messageSubject}` 
                                      : null, // optional props field for email
                    attachment: conversation?.provider === "whatsapp" ?
                                  item?.messageType?.toLowerCase() !== "campaign"
                                  ? <WhatsAppInfo data={(test_data)} /> //<p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p> 
                                  : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                                  ? <div style={{ whiteSpace: 'pre-line', margin: 0 }} dangerouslySetInnerHTML={{ __html: (test_data)?.campaignString?.header + "\n" + (test_data)?.campaignString?.body }}/>
                                  : <WhatsAppInfo data={(item || test_data)} />
                                : <div style={{ whiteSpace: 'pre-line', margin: 0 }} dangerouslySetInnerHTML={{ __html: item?.message?.trim()}}/> , // optional props fields
                      // message: item?.message,
                  }}
                  message_data={item}
                  handleParentCallback={(data: any) => handleAssignedUserForDetails(data)}
                  isReply={false}
                />
              : <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: conversation?.provider === "whatsapp" ? conversation?.toPhoneNumber
                              : item?.messageFromDisplayName || item?.messageFromEmail || null,
                    user_profile: getImage(conversation?.provider),
                    star_ratings: null, // optional props fields
                    tag_text: conversation?.provider === "whatsapp"
                              ? "Sycient Technology"
                              : item?.toList?.length 
                              ? `To: ${item?.toList?.map((item: any) => item?.email)?.join(", ")} ${item?.ccList?.length ? `, Cc: ${item?.ccList?.map((item: any) => item?.email)?.join(", ")}` : ""} ${item?.bccList?.length ? `, Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}` : ""}`
                              : item?.ccList?.length 
                              ? `Cc: ${item?.ccList?.map((item: any) => item?.email)?.join(", ")} ${item?.bccList?.length ? `, Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}` : ""}`
                              : item?.bccList?.length 
                              ? `Bcc: ${item?.bccList?.map((item: any) => item?.email)?.join(", ")}`
                              : null,
                              
                              // : null,
                              // : `To: support@tezla.com, Cc: ceo@tezla.com`,  // optional props fields
                    date: item?.messageCreatedOn,
                    message_subject: conversation?.provider === "whatsapp"
                                    ? null
                                    : item?.messageSubject ? `Sub: ${item?.messageSubject}`
                                    : null, // optional props fields
                    attachment: conversation?.provider === "whatsapp" ?
                                  item?.messageType?.toLowerCase() !== "campaign"
                                  ? <WhatsAppInfo data={(test_data)} /> //<p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p> 
                                  : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                                  !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                                  ? <div style={{ whiteSpace: 'pre-line', margin: 0 }} dangerouslySetInnerHTML={{ __html: (test_data)?.campaignString?.header + "\n" + (test_data)?.campaignString?.body }}/>
                                  : <WhatsAppInfo data={(item || test_data)} />
                                : <div style={{ whiteSpace: 'pre-line', margin: 0 }} dangerouslySetInnerHTML={{ __html: item?.message?.trim()}}/> , // optional props fields
                                // : <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message?.trim()}</p> , // optional props fields
                    // message: item?.message,
                  }}
                  message_data={item}
                  handleParentCallback={(data: any) => {
                    // console.log({ data });
                    handleAssignedUserForDetails(data);
                  }}
                  // isReply={ !conversation?.messageStatus || conversation?.messageStatus === "closed" || type === "shared-copies-messages" ? false : true}
                  isReply={ !conversation?.messageStatus || conversation?.messageStatus === "closed" || (conversation?.messageStatus === "inProgress" && conversation?.logged_in_user_id !== conversation?.assignedUserId) || type === "shared-copies-messages" ? false : true}
                />
          ))
        }
        </div>
      </div>

      <ConversationCommonReply
        data_fields={null}
        conversation={conversation}
        handleParentCallback={(data: any) => console.log({data})}
        isReply={isReplyingOn}
        isCountDownOn={isCountDownOn}
        isForReplyingUser={
          // isDisabledReplyingBySignalR || !conversation?.messageStatus || (conversation?.messageStatus === "inProgress" && conversation?.logged_in_user_id !== conversation?.assignedUserId) || conversation?.messageStatus === "closed" || type === "shared-copies-messages"
          reply_flag
          ? false
          : true
        }
        // after clicking reply button
        handleAssignedUserForDetails={handleAssignedUserForDetails}
        // attachment management
        lastMessageData={messages[messages?.length -1]}
        handleSendMessageWithAttachment={handleSendMessageWithAttachment}
        // attachments={attachments}
        // handleRemoveAttachment={handleRemoveAttachment}
        // handleFileUpload={handleFileUpload}
        // ==== X ====
        textRef={textRef}
        showingLeftContent={false}
        // sender={sender}
        showFileInputIcon={true} 
        showEmojiInputIcon={true}
        showLockIcon={true}
      />
      {/* <div className="conversation-section-container-lg h-100">
        <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(conversation?.userName)}
                      alt="avatar"
                  />
              }
              <span className="description">
                {conversation?.userName?.substr(0, 13) ||
                  conversation?.userName?.substr(0, 13) ||
                  "User Name"}{" "}
                </span>
            </div>
            <div className="person-2" style={{width: 320}}>
              {
                workspaceSettings?.logo
                // other_user?.logo
                ? <img
                    src={workspaceSettings?.logo || user_avatar_2}
                    alt="user-2"
                  />
                : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                    alt="avatar"
                  />
              }
              <p className="user-2">
                {workspaceSettings?.workspaceName  ||  "WS Name"}
              </p>
            </div>
          </div>
          <div className="conversation-features-media"
            style={{justifyContent: 'flex-end', width: '40%' }}
          >
            <div className="features whats-app-features">
              <div className='count-down-container'>
                <BlinkingOneDot
                  isStarted={isCountDownOn}
                />
                <CustomCountdown
                  customClass={"countdown"}
                  onChange={(val: string) => {
                    // console.log(val);
                  }}
                  isStarted={isCountDownOn}
                />
              </div>

              <div className='whats-app-close-container'
                onClick={() => closeReplying()}
              >
                Close
              </div>
            </div>
            <div className="media">
              <img src={getImage(type)} alt="type-icon" />
            </div>
          </div>
        </div>
        {
          messages?.length
          ?
          <div className="conversation-section-video-feedback">
              <Scrollbars
                style={{
                  paddingRight: 12,
                  // display: "flex",
                  // flexDirection: "column-reverse",
                  // overflow: 'auto'
                }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"62vh"}
                thumbMinSize={10}
                universal={true}
                id="scrollableDiv"
              >
                <div className="ai-bot-conversation-body">
                  
                  {
                    messages.map((item: any, idx: any) => (
                      item?.userName !== conversation?.userName
                      // item?.userName !== other_user?.userName
                      || item?.messageType?.toLowerCase() === "campaign"
                      ?
                      <div key={idx} className={`conversation-person-2 right-side`}>
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                            
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User")}</p>
                      </div>
                      :
                      <div className="conversation-person-1 left-side" key={idx}>
                        <div className="details">
                          {
                            reviewerPhoteUrl
                            ? <img
                                className="user-avatar"
                                src={reviewerPhoteUrl || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(item?.userName)}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                            {"Messaged - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A")}
                        </p>
                      </div>
                    ))
                  }
                </div>
              </Scrollbars>
          </div>
          :
          <div className="empty-feeds-section">
            <div
              className="empty-wall-section position-absolute"
              style={{ top: "45%" }}
            >
              <div>
                <img src={emptyBox} alt="" />
                <h4>Empty Feeds</h4>
              </div>
            </div>

          </div>
        }

        <div className="conversation-footer" style={{bottom: 44}}>
          <div className='d-flex justify-content-between my-2' style={{padding: '0 2.9% 0 2.5%'}}>
            <p className="text-start sender">
              {
                sender
                ? `${sender} is typing...`
                : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                // `Sending as ${other_user?.userName || "Other User"}`
              }
            </p>
            <CustomRating rating={rating || 4} 
              onRating={(rating: number) => {
                // console.log(rating);
                setRating(rating);
              }}
            />
          </div>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  placeholder="Write here..."
                  value={inputValue || ""}
                  onChange={(e: any) => handleInputChange(e.target.value)}
                  onKeyDown={handleKeyPress}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    // if (isReply) {
                    if (isReplyingOn) {
                      handleSubmit();
                    } else {
                      // console.log("Click Reply");
                      setIsReply(true);
                      dispatch(toggleReplyingOn(true));
                      // dispatch(toggleReplyingOn(true));
                      // dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {isReplyingOn
                    ? "Send"
                    // : isReplyingOn
                    // ? "Save"
                    : inputValue
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(isReplyingOn || sender) ? lock_icon : unlock_icon}
                  // src={isReply ? lock_icon : unlock_icon}
                  // src={isReplyingOn ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <p className={`click-reply-des ${(isReplyingOn && !sender) ? "d-none" : ""}`}>
                {
                  // sender
                  // ? "You can not reply now. Editor is locked"
                  // : "Click reply and start adding your reply."
                  "Click reply and start adding your reply."
                }
              </p>
            </div>
          </div>
        </div>
      </div> */}
      
      {/* <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(type)}
              alt="type-messages"
            />
          </div>
          <div className="header-body">
            <div className="bot-body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center p-1' style={{fontSize: "0.75rem", background: '#14B0BF'}}>
                      {
                        (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.length === 2
                        ? (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : (conversation?.userName || "User Name")?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {(conversation?.userName)?.substr(0, 6) || "User Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                {
                  workspaceSettings?.logo || other_user?.logo
                  ? <img
                      className="image-logo"
                      src={ workspaceSettings?.logo || other_user?.logo || user_avatar_2}
                      alt="user-2"
                    />
                  : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center'>
                      {
                        (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.split(" ")?.slice(0, 2)?.length === 2
                        ? (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <p className="ws-name">
                  {workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User"}
                </p>
              </div>
            </div>
            <div className="conversation-features-media"
              style={{
                justifyContent: 'center',
                // width: '40%'
              }}
            >
              <div className="features whats-app-features">
                <div className='count-down-container'>
                  <BlinkingOneDot
                    isStarted={isCountDownOn}
                  />
                  <CustomCountdown
                    customClass={"countdown"}
                    onChange={(val: string) => {
                      // console.log(val);
                    }}
                    isStarted={isCountDownOn}
                  />
                </div>

                <div
                  onClick={() => closeReplying()}
                  className='whats-app-close-container'
                >
                  Close
                </div>
                
              </div>
              <div className="media">
                <img src={getImage(type)} alt="type-icon" />
              </div>
            </div>
          </div>
        
        <div className="header-bottom">
          <span
            onClick={() => isGoForDetails && handleDetailChange()}
            style={{ color: !isGoForDetails ? "#1570EF" : "" }}
          >
            Messages
          </span>
          <span
            onClick={() => !isGoForDetails && handleDetailChange()}
            style={{ color: isGoForDetails ? "#1570EF" : "" }}
          >
            Details
          </span>
        </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <MessagesDetails type={type} statDetails={statDetails} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
                id="scrollableDiv"
              >
                <div className="conversation-body" style={{height: '62%', paddingRight: '1%'}}>
                  {
                    messages?.map((item: any, idx: any) => (
                      item?.userName !== conversation?.userName
                      // item?.userName !== other_user?.userName
                      || item?.messageType?.toLowerCase() === "campaign"
                      ?
                      <div key={idx} className={`conversation-person-2 right-side`}>
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                            
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User")}</p>
                      </div>
                      :
                      <div className="conversation-person-1 left-side" key={idx}>
                        <div className="details">
                          {
                            reviewerPhoteUrl
                            ? <img
                                className="user-avatar"
                                src={reviewerPhoteUrl || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(conversation?.userName)}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                           <p>{item?.message}</p>
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                            {"Messaged - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A")}
                        </p>
                      </div>
                      
                    ))
                  }
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                  }
                </p>
                <div style={{display: 'flex', gap: '0.8rem'}}>
                  <CustomRating rating={rating || 4} 
                    onRating={(rating: number) => {
                      // console.log(rating);
                      setRating(rating);
                    }}
                  />
                  <img
                    onClick={() => {
                      setIsReply(false);
                      dispatch(toggleReplyingOn(false));
                      // dispatch(toggleIsInReplyingMode(false));
                    }}
                    // src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                    src={isReply ? lock_icon : unlock_icon}
                    alt="lock-unlock"
                  />
                </div>
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      placeholder="Write here..."
                      value={inputValue || ""}
                      onChange={(e: any) => handleInputChange(e.target.value)}
                      onKeyDown={handleKeyPress}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (!sender) {
                          if (isReply) {
                            handleSubmit();
                          } else {
                            console.log("Click Reply");
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            // dispatch(toggleIsInReplyingMode(true));
                          }
                        }
                      }}
                      // type="button"
                      // disabled={sender ? true : false}
                    >
                      <img src={reply_icon} alt="reply" />
                    </div>
                  </div>
                  <p className={`click-reply-des ${(isReplyingOn && !sender) ? "d-none" : ""}`}>
                      {
                        // sender
                        // ? "You can not reply now. Editor is locked"
                        // : "Click reply and start adding your reply."
                        "Click reply and start adding your reply."
                      }
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}
    </React.Fragment>
  )
}

export default AllMessagesMemberConversation;
