import React from 'react';
import moment from 'moment';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import ReviewSummary from './ReviewSummary/ReviewSummary';
import SummaryList from './ReviewSummary/SummaryList';
import ReviewHeader from './ReviewHeader/ReviewHeader';

const FacebookDetails = ({ workspaceSettings, conversation, unifiedDetailsData }: any) => {
  const {
    // conversationDetailsData,
    isReplyingOn, isInReplyingMode
  }: any = useSelector((state: RootState) => state?.unified);
  // const { reviewerDisplayName, mentionedbyUserName, recieverName, reviewerPhoteUrl, mentionedbyUserImageUrl, reviewtype, createdOn, reviewComment, provider, tag, starRating, likeCount, retweetCount, locationName, recieverCompany, mentionedbyUserEmailId, reviewComment2 } = conversation || {};
  // const {reviewer, location, page, properties, details} = unifiedDetailsData || {};
  
  // const {
  //     // facebook
  //     Reviewer, Reviews, reviews, Comments, comments,
  //     // google
  //     reviewerName, daysFromFirstReview, reviewerReviewCount, averageRating,
  //     // video testimonial
  //     totalReviews,
  //     // facebook, twitter
  //     Mentions, mentions,
  //     // twitter
  //     Days, days, Today,
  // } = reviewer || {};
  // const {
  //   // facebook
  //   reviewCount, ratingStars, followerCount, followersCount,
  //   // google, facebook,
  //   todaysCount,
  //   // google, video testimonial, customer feedback
  //   averageResponse, locationAverageReviewRating, locationReviewCount,
  //   // video testimonial
  //   averageRating: locationAverageRating, requestCount, responseCount,
  //   // twitter
  //   totalMentionCount,
  //   todaysTotalMentionCount,
  //   Followers,

      
  // } = location || page || {};
  console.log({conversation_data: conversation, unifiedDetailsData, isInReplyingMode, isReplyingOn});
  // const {ResponseTime, RequestedDate, SubmittedOn, repliedBy, repliedOn, reviewedOn, MentionedOn, requestorEmail} = details || {};
  // const getDateDifference = (date1: any, date2: any) => {
  //   if(!date1 || !date2) return " --- ";
  //   // Calculate the time difference in milliseconds
  //   const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
  //   console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
    
  //   // Calculate the number of days
  //   let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  //   // Calculate the remaining hours
  //   const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  //   // Calculate the remaining minutes
  //   const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  //   let res = "";
  //   let years: any = 0;
  //   if (days > 365) {
  //     years = Math.floor(days / 365);
  //     res += years + "Years, ";
  //     days -= years * 365;
  //     res += days + "Days ";
  //     days = 0;
  //   }
  //   if (days > 0) {
  //     res += days + "Days, ";
  //   }
  //   if (!years) {
  //     res += hours + "Hours, " + minutes + "Minutes";
  //   }
  //   return res;
  // }

  return (
    <React.Fragment>
      {/* Mobile */}
      {/* <div className="w-100 h-100 conversation-details-mobile">
        <div className="inbox-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl || mentionedbyUserImageUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || mentionedbyUserImageUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {averageRating ? (
                      <div>
                        <span className="user_name">
                          {reviewerDisplayName || "FB USER"}
                        </span>
                        <div className="rating-social-email">
                          <div>
                            {averageRating ? (
                              <div>
                                {Array.from(
                                  Array(
                                    Math.floor(parseFloat(averageRating || 0)) || 0
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                <span className="ms-2">
                                  {Number(averageRating) || 0}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {reviewerDisplayName || "FB USER"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserReviewsCount || 0}
                    </span>
                    <span className="title">
                      Reviews
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserCommentsCount || 0}
                    </span>
                    <span className="title">
                      Comments
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserMentionsCount || 0}
                    </span>
                    <span className="title">
                      Mentions
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-center">
                    {
                      locationAverageReviewRating || ratingStars || locationAverageRating
                      ? <>
                          <span className="user_name">
                            {workspaceSettings?.workspaceName || "Tezla Inc"}
                          </span>
                          <div className="rating-social-email">
                            <div>
                              {Array.from(
                                Array(
                                  Math.floor(
                                    parseFloat(
                                      locationAverageReviewRating || ratingStars || locationAverageRating
                                    )
                                  )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                              <span className="ms-2">
                                {Number(0)}
                              </span>
                            </div>
                          </div>
                        </>
                      : <span className="user_name mt-2">
                          {workspaceSettings?.workspaceName || "Tezla Inc"}
                        </span>
                    }
                    
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.pageCommentCount || unifiedDetailsData?.pageMentionCount || unifiedDetailsData?.pageReviewCount || 0)}
                    </span>
                    <span className="title">
                      {
                        tag?.includes("review")
                        ? "Reviews"
                        : tag?.includes("comment")
                        ? "Comments"
                        : "Mentions"
                      }
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TodayCount || 0)}
                    </span>
                    <span className="title">
                      Today
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.PageFollowersCount || 0)}
                    </span>
                    <span className="title">
                      Followers
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
              conversation ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {`Facebook ${tag === "comments" ? "Comment" :  tag === "mentions" ? "Mention" : "Review"}`}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    <span className="lock-unlock pt-1 mt-1">
                      {isInReplyingMode ? <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      : !isReplyingOn ? (
                        <span>
                          Unlocked <img src={unlock_icon} alt="unlocked" />
                        </span>
                      ) : properties?.lock_title ? (
                        <span>{properties?.lock_title}</span>
                      ) : (
                        <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="type-to-lock">
                    {isInReplyingMode || isReplyingOn ? (
                      <span className="not-replied">Not Replied</span>
                      ) : (
                      <span className="replied">Replied</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    {
                      tag?.includes("review")
                      ? "Reviewed"
                      : tag?.includes("comment")
                      ? "Commented"
                      : "Mentioned"
                    }
                    :
                  </span>
                  <span>
                    Replied:
                  </span>
                  <span>Res. Time:</span>
                </div>
                <div className="req-res-value">
                  <span>
                    {createdOn
                      ? moment(createdOn).format("ll") +
                        " " +
                        moment(createdOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {conversation?.reviewReplyUpdatedo
                      ? moment(conversation?.reviewReplyUpdatedo).format("ll") +
                        " " +
                        moment(conversation?.reviewReplyUpdatedo).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div> */}
      
      {/* Laptop */}
      {/* <div className="w-100 h-100 conversation-details-lg">
        <div className="inbox-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl || mentionedbyUserImageUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || mentionedbyUserImageUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {averageRating ? (
                      <div>
                        <span className="user_name">
                          {reviewerDisplayName || "FB USER"}
                        </span>
                        <div className="rating-social-email">
                          <div>
                            {averageRating ? (
                              <div>
                                {Array.from(
                                  Array(
                                    Math.floor(parseFloat(averageRating || 0)) || 0
                                  ).keys()
                                ).map((item: any, index: number) => {
                                  return (
                                    <img
                                      className="start"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                                <span className="ms-2">
                                  {Number(averageRating) || 0}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {reviewerDisplayName || "FB USER"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserReviewsCount || 0}
                    </span>
                    <span className="title">
                      Reviews
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserCommentsCount || 0}
                    </span>
                    <span className="title">
                      Comments
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {unifiedDetailsData?.UserMentionsCount || 0}
                    </span>
                    <span className="title">
                      Mentions
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-center">
                    {
                      locationAverageReviewRating || ratingStars || locationAverageRating
                      ? <>
                          <span className="user_name">
                            {workspaceSettings?.workspaceName || "Tezla Inc"}
                          </span>
                          <div className="rating-social-email">
                            <div>
                              {Array.from(
                                Array(
                                  Math.floor(
                                    parseFloat(
                                      locationAverageReviewRating || ratingStars || locationAverageRating
                                    )
                                  )
                                ).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                                                           
                              <span className="ms-2">
                                {Number(0)}
                              </span>
                            </div>
                          </div>
                        </>
                      : <span className="user_name mt-2">
                          {workspaceSettings?.workspaceName || "Tezla Inc"}
                        </span>
                    }
                    
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.pageCommentCount || unifiedDetailsData?.pageMentionCount || unifiedDetailsData?.pageReviewCount || 0)}
                    </span>
                    <span className="title">
                      {
                        tag?.includes("review")
                        ? "Reviews"
                        : tag?.includes("comment")
                        ? "Comments"
                        : "Mentions"
                      }
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TodayCount || 0)}
                    </span>
                    <span className="title">
                      Today
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.PageFollowersCount || 0)}
                    </span>
                    <span className="title">
                      Followers
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
              conversation ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {`Facebook ${tag === "comments" ? "Comment" :  tag === "mentions" ? "Mention" : "Review"}`}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    <span className="lock-unlock pt-1 mt-1">
                      {isInReplyingMode ? <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      : !isReplyingOn ? (
                        <span>
                          Unlocked <img src={unlock_icon} alt="unlocked" />
                        </span>
                      ) : properties?.lock_title ? (
                        <span>{properties?.lock_title}</span>
                      ) : (
                        <span>
                          Locked <img src={lock_icon} alt="locked" />
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="type-to-lock">
                    {isInReplyingMode || isReplyingOn ? (
                      <span className="not-replied">Not Replied</span>
                      ) : (
                      <span className="replied">Replied</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    {
                      tag?.includes("review")
                      ? "Reviewed"
                      : tag?.includes("comment")
                      ? "Commented"
                      : "Mentioned"
                    }
                    :
                  </span>
                  <span>
                    Replied:
                  </span>
                  <span>Res. Time:</span>
                </div>
                <div className="req-res-value">
                  <span>
                    {createdOn
                      ? moment(createdOn).format("ll") +
                        " " +
                        moment(createdOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {conversation?.reviewReplyUpdatedo
                      ? moment(conversation?.reviewReplyUpdatedo).format("ll") +
                        " " +
                        moment(conversation?.reviewReplyUpdatedo).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div> */}

      <div className="conversation-details">
        <ReviewHeader
          tabs={["General",]}
          handleChangeTab={(data: any) => console.log(data)}
          value={"General"}
        />
          {/* <div className='details-tab-header'>
            <div className="tab tab-active">General</div>
          </div> */}
        <div className="details-body">
          <ReviewSummary
            userName={conversation?.reviewerDisplayName}
            userImage={null} // conversation?.reviewerPhoteUrl
            averageRating={unifiedDetailsData?.averageRating}
            provider={conversation?.provider}
            value_1={Number(unifiedDetailsData?.UserReviewsCount || 0)}
            value_2={Number(unifiedDetailsData?.UserCommentsCount || 0)}
            value_3={Number(unifiedDetailsData?.UserMentionsCount || 0)}
            title_1={"Reviews"}
            title_2={"Comments"}
            title_3={"Mentions"}
          />
            
          <ReviewSummary
            userName={workspaceSettings?.workspaceName}
            userImage={workspaceSettings?.logo || getGenerateApiImageByName(workspaceSettings?.workspaceName)}
            averageRating={unifiedDetailsData?.ratingStars}
            provider={conversation?.provider}
            value_1={Number(conversation?.tag === "comments" ? (unifiedDetailsData?.pageCommentCount || 0) :  conversation?.tag === "mentions" ? (unifiedDetailsData?.pageMentionCount || 0) : (unifiedDetailsData?.pageReviewCount || 0))}
            value_2={Number(unifiedDetailsData?.TodayCount || 0)}
            value_3={Number(unifiedDetailsData?.PageFollowersCount || 0)}
            title_1={"Reviews"}
            title_2={"Today"}
            title_3={"Followers"}
          />
          
          <SummaryList
            list_data={{
              provider: conversation?.provider,
              tag: conversation?.tag,
              type: `Facebook ${conversation?.tag === "comments" ? "Comment" :  conversation?.tag === "mentions" ? "Mention" : "Review"}`,
              to: workspaceSettings?.workspaceName,
              tag_label:  conversation?.tag?.includes("review")
                          ? "Reviewed on"
                          : conversation?.tag?.includes("comment")
                          ? "Commented on"
                          : "Mentioned on",
              createdOn: conversation?.createdOn,
              repliedBy: conversation?.reviewerDisplayName || "Unknown User",
              repliedOn: conversation?.reviewReplyUpdatedon,
            }}
          />
            
        </div>
      </div>
    </React.Fragment>
  )
};

export default FacebookDetails;
