import * as signalR from "@microsoft/signalr";
import { api_url } from "./server";

// const hubUrl = "https://signalrapi-dev.clapup.me/RecordHub";
// const hubUrl = "https://api-dev.clapup.me/SignalRHub";
const hubUrl = (api_url || "https://api-dev.clapup.me") + "/SignalRHub";

let connection_conversation = new signalR.HubConnectionBuilder()
    .withUrl(
        hubUrl,
        {
            withCredentials: true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }
    )
    .configureLogging(signalR.LogLevel.Information)
    .build();

connection_conversation.start()
    .then(() => { console.log("SignalR_Conversation: connected => ", { hubUrl }); })
    .catch((error) => {
        console.log("SignalR_Conversation => ", { hubUrl });
        console.log("SignalR_Conversation connection error:", error);
    });

export default connection_conversation;