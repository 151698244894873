import React, { useEffect, useState } from 'react';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { manageInboxSenderState, setErrorMessage, toggleClosedWhatsApp } from '../../../../unified/reducer';
import { getGenerateApiImageByName, getImage } from '../../../../unified/image_service';
import './AllFeedCard.scss';
import { convertISODate } from '../../../../utils/convertingFunction';

const AllMessageCard = (props: any) => {
  const { inbox, handleSeeConversation, type, activePage, last, lastEmailDetails  } = props;
  const dispatch = useDispatch();
  const {
    inbox_sender_state,
    // isClosedWhatsAppInboxThread,
    // isReplyingOn,
    // There will be a ticketNo which will match and lock-icon will come
    // if lock-icon will will come the receiver will not reply text 
    // whats_app_sender_state
  } = useSelector((state: RootState) => state?.unified);
  const path_name = window.location.pathname;
  const query_string = window.location.search;
  const status_tag = query_string?.includes("open") ? "open"
  : query_string?.includes("inProgress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("starred") ? "starred" 
  : "all";
  // const path = query_string.replace('?', '')?.replaceAll('&', "=");
  // const path_array = path.split("=");
  const [isOpenedDropdown, setIsOpenedDropdown] = React.useState(false);
  const [threads, setThreads] = React.useState<any[]>([]);
  // const [active_inbox_user, setActiveInboxUser] = React.useState<any>(null);
  // const [active_thread, setActiveInboxThread] = React.useState<any>(null);
  useEffect(() => {
    
    if (!threads?.length) {
      // if (inbox?.ticketNoWiseDetails?.length) {
      //   const current_unique_threads = Object.values(
      //     inbox?.ticketNoWiseDetails?.reduce((acc: any, item: any) => {
      //       const key = `${item.ticketNo}-${item.messageId}`;
      //       if (!acc[key]) {
      //         acc[key] = item;
      //       }
      //       return acc;
      //     }, {})
      //   );
      //   setThreads(current_unique_threads);
      // }
      // if (path_name?.includes("archives") || status_tag === "all") { 
      //   // console.log({inbox, threads, path_name});
      //   setThreads(inbox?.ticketNoWiseDetails?.length ? inbox?.ticketNoWiseDetails : []);
      // }
      // else {
      //   setThreads(inbox?.ticketNoWiseDetails?.length ? inbox?.ticketNoWiseDetails : []);
      //   // const filtered_threads = inbox?.ticketNoWiseDetails?.filter((thread: any) => thread?.messageStatus === status_tag);
      //   // // console.log({filtered_threads, status_tag});
        
      //   // setThreads(filtered_threads);
      // }
    }
  }, [isOpenedDropdown]);
  
  const [activeInbox, setActiveInbox] = useState<any>(null);
  useEffect(() => {
    const path = query_string.replace('?', '')?.replaceAll('&', "=");
    const path_array = path.split("=");
    if (path_array?.length > 7) {
      const [
        _active_tab, _active_page,
        _cov, conversation_id,
        _sn, _sl_no,
        _ticket, _cur_ticket_no
      ] = path_array;
      setActiveInbox(conversation_id);
    } else {
      setActiveInbox(null);
    }

    const threadDetails = lastEmailDetails?.find((item: any) => item?.messageFromEmail === inbox?.messageFromEmail);
    // console.log({ inbox_data: inbox, threadDetails });


    if (threadDetails) {
      setThreads(threadDetails?.lastEmailMainDetList || []);
    }

    // if (inbox?.ticketNoWiseDetails?.length) {
    //   const current_unique_threads = Object.values(
    //     inbox?.ticketNoWiseDetails?.reduce((acc: any, item: any) => {
    //       const key = `${item.ticketNo}-${item.messageId}`;
    //       if (!acc[key]) {
    //         acc[key] = item;
    //       }
    //       return acc;
    //     }, {})
    //   );

    //   const unique_threads_by_locked_or_not = current_unique_threads?.map((thread: any) => {
    //     const isLocked = inbox_sender_state?.find((sender_data: any) => sender_data?.messageId === thread?.messageId && sender_data?.ticket_no === thread?.ticketNo);
    //     return {
    //       ...thread,
    //       isLocked: isLocked,
    //     }
    //   });
    //   setThreads(unique_threads_by_locked_or_not);
    // }
    
  }, [query_string]);
  
  // Important and VIP SignalR
  useEffect(() => {
    
    if (threads?.length && inbox_sender_state?.length) {
      const filtered_threads = threads?.map((thread: any) => {
        const isLocked = (inbox_sender_state?.find((item: any) => item?.messageId === thread?.messageId && item?.ticket_no === thread?.ticketNo));
        return {
          ...thread,
          isLocked: isLocked
        }
      
      });
      console.log({inbox_sender_state, filtered_threads});
      setThreads(filtered_threads);
      // if () {
        // } else {
          //   setThreads(inbox?.ticketNoWiseDetails?.length ? inbox?.ticketNoWiseDetails : []);
          // }
    } else if(threads?.length) {
      const filtered_threads = threads?.map((thread: any) => {
        const isLocked = (inbox_sender_state?.find((item: any) => item?.messageId === thread?.messageId && item?.ticket_no === thread?.ticketNo));
        return {
          ...thread,
          isLocked: isLocked,
        }
      
      });
      // console.log({inbox_sender_state, filtered_threads});
      setThreads(filtered_threads);

    }
    
  }, [inbox_sender_state?.length]);

  // useEffect(() => {
  //   if (threads?.length) {
  //     if (path_array?.length > 5) {
  //       const [_0, _1, _2, conversation_id, _4, user] = path_array;
  //       const active_user = user;
  //       if (active_user) {
  //         // setActiveInboxUser(active_user);
  //         const active_thread = threads?.find((thread: any) => thread?.ticketNo === conversation_id);
  //         // console.log({ active_thread, active_user, inbox});
  //         if (active_thread) {
  //           // setActiveInboxThread(active_thread);
  //           setIsOpenedDropdown(true);
  //         }
          
  //       }
  //     }
  //   }
  // }, [threads?.length]);

  // useEffect(() => {
  //   console.log({ whats_app_sender_state, inbox });
  // }, [whats_app_sender_state]);
  const first_thread_by_tag: any = threads?.length ? threads[0] : {};
  // const isLockedByOtherUser = inbox_sender_state?.find((item: any) => item?.messageId === inbox?.messageId && item?.ticket_no === first_thread_by_tag?.ticketNo);
  // const isLockedByOtherUser = (inbox_sender_state?.find((item: any) => item?.messageId === inbox?.messageId && item?.ticket_serial_no === first_thread_by_tag?.serialNo && item?.ticket_no === first_thread_by_tag?.ticketNo));
  // console.log({ first_thread_by_tag, inbox, threads, status_tag });
  
  return (
    <React.Fragment>
      <div className='feeds-card'>
        <div className={`feed-card-hover ${ activeInbox === inbox?.messageId && query_string?.includes(first_thread_by_tag?.ticketNo) ? "feed-card-active" : ""}`}
          style={{cursor: 'pointer'}}
          onClick={() => {
            // if (isLockedByOtherUser) {
            if (first_thread_by_tag?.isLocked) {
              return;
            }
            if (threads?.length) {
              const seeing_conversation_object = {
                ...inbox,
                key_sl_no: threads[0]?.serialNo,
                key_ticket_no: threads[0]?.ticketNo,
                key_ticket: threads[0],
              };
              // console.log({ seeing_conversation_object });
              if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                return;
              }
              handleSeeConversation(seeing_conversation_object);
            } else {
              dispatch(setErrorMessage("No conversations available"));
            }
          }}
        >
          <div className="feed-card-header">
            <img
              className="profile-pic"
              src={getGenerateApiImageByName(inbox?.messageFromDisplayName || inbox?.messageFromEmail)} // profile_image || 
              alt="avatar"
            />
            <div className="user-details">
              <div className="username-stars-rating-container">
                <div className="username">{(inbox?.messageFromDisplayName || inbox?. messageFromEmail)?.length < 18 ? (inbox?.messageFromDisplayName || inbox?.messageFromEmail) : ((inbox?.messageFromDisplayName || inbox?.messageFromEmail)?.substr(0, 15) + "...") }</div>
              </div>
              <div className="tag-date">
                { 
                  first_thread_by_tag && first_thread_by_tag?.messageCreatedOn
                  ? moment(first_thread_by_tag?.messageCreatedOn).local().format("DD MMM YYYY, hh:mm:ss A")
                  : moment(convertISODate(new Date())).local().format("DD MMM YYYY, hh:mm:ss A")
                }
              </div>
            </div>
            <img className="provider-logo" src={getImage(inbox?.provider)} alt="provider-icon" />
          </div>

          <div className="feed-card-body">
            <p style={{ cursor: 'pointer', color: path_name?.includes("archives") ? "#0F43BD" : inbox?.messageStatus === "open" ? "#0F43BD" : (first_thread_by_tag && first_thread_by_tag?.color)  || "#000000"}}>
              {
                first_thread_by_tag && first_thread_by_tag?.lastMessage
                ? first_thread_by_tag?.lastMessage?.trim()?.length < 31 ? first_thread_by_tag?.lastMessage : first_thread_by_tag?.lastMessage?.trim()?.substring(0, 28) + "..."
                : "As the brand, so is the service. Great..."
              }
            </p>
            {/* {
              isFromModalBox
              ? <p>{first_thread_by_tag?.lastMessage}</p>
                  :
            } */}
          </div>
          
          <div className="feed-card-footer">
            <div className="footer-content">
              <div className="left-emoji">
                <div className="left-body-emoji">
                  <img
                    src={getImage("exclamation_icon")}
                    alt="exclamation"
                  />
                  {
                    inbox?.messageStatus === "closed"
                    ? <img src={getImage("closed-status")} alt="status-icon"/>
                    : inbox?.messageStatus === "dispatched"
                    ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                    : inbox?.messageStatus === "toDispatched"
                    ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                    : inbox?.messageStatus === "inProgress"
                    ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                    : inbox?.messageStatus === "reportedSpam"
                    ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                    : inbox?.messageStatus === "open"
                    ? <img src={getImage("opened-status")} alt="status-icon"/>
                    // all archive messages will contain messageStatus: "closed"
                    : <img src={getImage("closed-status")} alt="status-icon"/>
                    // : null
                  }
                  {/* {
                    !isOpenedDropdown
                        ?
                    : null
                  } */}
                  <div>{inbox?.messageStatus}</div>
                </div>
              </div>
              
              <div className='right-side-content'>
                {
                  // isLockedByOtherUser
                  first_thread_by_tag?.isLocked // By other user
                  ? <img
                      // this will be removed
                      onClick={() => {
                        dispatch(manageInboxSenderState({...first_thread_by_tag?.isLocked, isForInserting: false }));
                      }}
                      src={getImage("thread_lock_icon")} alt="thread-lock"
                    />
                  : null
                }

                {/* {
                  inbox?.userName === whats_app_sender_state?.sender_inbox_userName
                  ? <img src={getImage("thread_lock_icon")} alt="thread-lock" />
                  // : threads?.length && threads[0]?.messageStatus === "closed"
                  // ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                  : null
                } */}
                {
                  threads?.length > 1 && !isOpenedDropdown
                  ? <img
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setIsOpenedDropdown(true);
                      }}
                      style={{ cursor: 'pointer' }}
                      src={getImage("dropdown_inactive")}
                      alt="dropdown"
                    />
                  : threads?.length > 1 && isOpenedDropdown
                  ? <img
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setIsOpenedDropdown(false);
                      }}
                      style={{ cursor: 'pointer' }}
                      src={getImage("dropdown_active")}
                      alt="dropdown"
                    />
                  : null
                }
              </div>
            </div>
          </div>
        </div>
        {
          isOpenedDropdown
          ? 
          <div className='treads'>
            {
              threads?.map((thread: any, idx: number) => (
              // [{}, {}, {}]?.map((thread: any, idx: number) => (
                // <div key={idx} className={`tread-container feed-card-hover feed-card-active`}>
                <div key ={idx} className = {`tread-container feed-card-hover ${query_string?.includes(inbox?.messageId) && query_string?.includes(thread?.ticketNo) ? "feed-card-active" : ""} ${idx === 0 ? "d-none" : ""}`}
                  onClick={() => {
                    if (inbox_sender_state?.find((item: any) => item?.messageId === inbox?.messageId && item?.ticket_serial_no === thread?.serialNo && item?.ticket_no === thread?.ticketNo)) {
                    // if (thread?.isLocked) {
                      return;
                    }
                    const seeing_conversation_object = {
                      ...inbox,
                      key_sl_no: thread?.serialNo,
                      key_ticket_no: thread?.ticketNo,
                      key_ticket: thread,
                    };
                    console.log({ seeing_conversation_object });
                    if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                      return;
                    }
                    handleSeeConversation(seeing_conversation_object);
                  }}
                >
                  <div className="feed-card-header">
                    <img
                      className="profile-pic"
                      src={getGenerateApiImageByName(inbox?.messageFromDisplayName || inbox?.messageFromEmail)} // profile_image || 
                      alt="avatar"
                    />
                    <div className="user-details">
                      <div className="username-stars-rating-container">
                        <div className="username">{!(inbox?.messageFromDisplayName || inbox?.messageFromEmail) ? "User Name" : (inbox?.messageFromDisplayName || inbox?.messageFromEmail)?.length < 18 ? (inbox?.messageFromDisplayName || inbox?.messageFromEmail) : ((inbox?.messageFromDisplayName || inbox?.messageFromEmail)?.substr(0, 15) + "...") }</div>
                      </div>
                      <div className="tag-date">
                        { 
                          thread?.messageCreatedOn
                          ? moment(convertISODate(thread?.messageCreatedOn)).local().format("DD MMM YYYY, hh:mm:ss A")
                          : moment(convertISODate(new Date())).local().format("DD MMM YYYY, hh:mm:ss A")
                        }
                      </div>
                    </div>
                    <img className="provider-logo" src={getImage(thread?.provider || inbox?.provider)} alt="provider-icon" />
                  </div>

                  <div className="feed-card-body">
                    <p style={{ cursor: 'pointer', color: path_name?.includes("archives") ? "#0F43BD" : inbox?.messageStatus === "open" ? "#0F43BD" : thread?.color  || "#000000"}}>
                      {
                        thread?.lastMessage
                        ? thread?.lastMessage?.trim()?.length < 31 ? thread?.lastMessage : thread?.lastMessage?.trim()?.substring(0, 28) + "..."
                        : "As the brand, so is the service. Great..."
                      }
                    </p>
                    
                  </div>
                  

                  <div className="feed-card-footer">
                    <div className="footer-content">
                      <div className="left-emoji">
                        <div className="left-body-emoji">
                          <img
                            src={getImage("exclamation_icon")}
                            alt="exclamation"
                          />
                          
                          {
                            inbox?.messageStatus === "closed"
                            ? <img src={getImage("closed-status")} alt="status-icon"/>
                            : inbox?.messageStatus === "dispatched"
                            ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                            : inbox?.messageStatus === "toDispatched"
                            ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                            : inbox?.messageStatus === "inProgress"
                            ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                            : inbox?.messageStatus === "reportedSpam"
                            ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                            : inbox?.messageStatus === "open"
                            ? <img src={getImage("opened-status")} alt="status-icon"/>
                            : <img src={getImage("closed-status")} alt="status-icon"/>
                            // : null
                          }
                          
                          <div>
                            {
                              thread?.messageStatus || "Closed"
                              // || inbox?.messageStatus
                            }
                          </div>
                        </div>
                      </div>
                      
                      <div className='right-side-content'>
                        {
                          // (inbox_sender_state?.find((item: any) => item?.messageId === inbox?.messageId && item?.ticket_serial_no === thread?.serialNo && item?.ticket_no === thread?.ticketNo))
                          thread?.isLocked // By other user
                            ? <img
                                // this will be removed
                                onClick={() => {
                                  dispatch(manageInboxSenderState({...thread?.isLocked, isForInserting: false }));
                                }}
                                src={getImage("thread_lock_icon")} alt="thread-lock"
                              />
                            : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          : null
        }
      </div>
      {/* {
        threads?.length
        ?
        : null
      } */}
    </React.Fragment>
  )
}
export default AllMessageCard;
