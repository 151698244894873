import React, { useEffect, useState } from "react";
import "./ProgressBar.scss"; // Assuming your SCSS file is named this way
import { getImage, image_constant } from "../../../unified/image_service";
// import image_constant from "../../../unified/image_service";
interface ProgressBarType {
    fileName: string;
    progress_count?: any;
    error: any;
    error_message?: any;
}

const ProgressBar: React.FC<ProgressBarType> = ({fileName, progress_count, error, error_message}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 99) return prev + 1;
        clearInterval(interval);
        // onComplete();
        return 99;
      });
    }, 300); // Adjust the speed here (30ms per increment)

    if(progress_count === 100){
        setProgress(100);
        clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [progress_count]);

  return (
    <div className="uploading-wrap">
        <div className="uploading-continue-modal">
            <div className="uploading-modal-container">
                <div className="uploading-modal-body">
                    <div className="left-image">
                        <img 
                            src={
                                error ? image_constant.error_upload_warning
                                : progress === 100 ? image_constant.success_upload_warning
                                : image_constant.being_uploaded_warning
                            }
                            alt="Upload Icon"
                        />
                    </div>
                    <div className="right-body">
                        <div className="title-container">
                            <div className="title">{fileName}</div>
                            {
                                !error && progress === 100 ? <img src={getImage("fill-success-tik")} alt="Success Icon" /> : null
                            }
                        </div>
                        <div className="loading-container">
                            <div
                                className="progress-bar"
                                style={{ 
                                    background: error ? "#F04438" : progress === 100 ? "#12B76A" : "#1354EC",
                                    width: `${progress}%`
                                }}
                            ></div>
                        </div>
                        <div className="footer-container">
                            <div>
                            {
                                error ? 
                                    error_message || "Uploaded failed! Please try again later"
                                : progress === 100 ? "File upload success!"
                                : "File being uploaded..."
                            }     
                            </div>
                            <span>{error ? 30 : progress}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProgressBar;
