import React, {  useEffect, useState } from "react";
import FeedTabs from './FeedTabs';
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "../CustomButton/ButtonContainer";
import './AllFeeds.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import search from '../../assets/icons/search-icon.svg';
import emptyBox from '../../assets/img/Empty.svg';
// import user_avatar from '../../assets/icons/user-avatar.svg';
// import google_logo from '../../assets/icons/google-logo.svg';
import cross_icon from '../../assets/icons/cross-svgrepo.svg';
import Search from "../Search/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import { 
  // resetUnifiedInbox, resetWallUnifiedInbox, triggerSearchWallData,, twitterMentions 
  changePage, setBoxId, toggleReplyingOn, triggerDeleteWallUnifiedData, triggerWallUnified
} from "../../unified/reducer";
import { RootState } from "../../store";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import ConversationDetails from "./ConversationDetails/AllDetails/ConversationsDetails";
import Conversations from "./ConversationDetails/AllConversations/Conversations";
import WallConversations from "./WallConversations/WallConversations";
import InboxCard from "./InboxCard/InboxCard";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonModal from "../CommonModal/CommonModal";
import RequestReview from "../RequestReview/RequestReview";
import { bio_url } from "../../utils/server";
import EmbedCode from "../EmbedCode/EmbedCode";
import Dialog from "../Dialogs/Dialog";
import MobileBottomTabs from "../Layout/MobileBottomTabs";
import FeedLeftBar from "./FeedLeftBar/FeedLeftBar";
// import { DebounceInput } from "react-debounce-input";
// import { triggerGetIntegrationConnections } from "../../signup-login/loginReducer";
// const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

interface Props {
  feedsEmpty?: boolean;
  feedsEmptyContent?: string;
  buttonContent?: string;
  imagePath?: any;
  handleTriggerData?: any;
  triggerData?: any;
  type?: any;
  isTagChanged?: any;
  handleSearchData?: any;
}

const FeedsComponent: React.FC<Props> = ({ type, handleTriggerData, handleSearchData, triggerData }) => {
  const route_path = window.location.pathname;
  const path = window.location.pathname;
  const query_string = window.location.search;
  const tag_type = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  const navigate = useNavigate();
  const [sText, setSearchText] = useState('');
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  
  // useEffect(() => {
  //   if(!sText) {
  //     dispatch({type: "REMOVE_SEARCH_TEXT"});
  //     dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
  //     dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
  //   }
  // }, [sText])
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  const {
    wallUnifiedData: wall_unified_data, embedObject,
    searchText, searchPayload, searchStartDate, searchEndDate, searchFilterStatus,
    wallBoxId, unifiedData: unified_data, isReplyingOn, pageRoute
  }: any = useSelector((state: RootState) => state?.unified);
  const dispatch = useDispatch();

  const {
    InboxWall, InboxWall_nextDate,
    CFWall, CFWall_nextDate,
    VTWall, VTWall_nextDate,
    GoogleWall, GoogleWall_nextDate,
    FacebookWall, FacebookWall_nextDate,
    TwitterWall, TwitterWall_nextDate,

  }: any = wall_unified_data || {};

  const wallUnifiedData:
    any[] =
    route_path?.includes('/unified')
    ? InboxWall
    : route_path?.includes('/customer-feedback')
    ? CFWall
    : route_path?.includes('/video-testimonial')
    ? VTWall
    : route_path?.includes('/google')
    ? GoogleWall
    : route_path?.includes('/facebook')
    ? FacebookWall
    : route_path?.includes('/twitter')
    ? TwitterWall
    : [];

  const {
    InboxAll_InboxAll, InboxAll_InboxAllnextDate,
    InboxReviews_InboxReviews, InboxReviews_InboxReviewsNextDate,
    InboxComments_InboxComments, InboxComments_InboxCommentsNextDate,
    InboxMentions_InboxMentions, InboxMentions_InboxMentionsNextDate,
    InboxQandA_InboxQandA, InboxQandA_InboxQandANextDate
  }: any = unified_data || {};
    
    
  const unifiedData:
    any[] =
    route_path?.includes('/unified') ?
      (tag_type === "all"
        ? InboxAll_InboxAll
      : tag_type === "reviews"
        ? InboxReviews_InboxReviews
      : tag_type === "comments"
        ? InboxComments_InboxComments
      : tag_type === "mentions"
        ? InboxMentions_InboxMentions
      : tag_type === "qa"
      ? InboxQandA_InboxQandA : [])
      
    : [];
    

  const nextDate: any[] =
    route_path?.includes('/unified') ?
      (tag_type === "all"
      ? InboxAll_InboxAllnextDate
      : tag_type === "reviews"
        ? InboxReviews_InboxReviewsNextDate
      : tag_type === "comments"
        ? InboxComments_InboxCommentsNextDate
      : tag_type === "mentions"
        ? InboxMentions_InboxMentionsNextDate
      : tag_type === "qa"
        ? InboxQandA_InboxQandANextDate
      : [])
    : [];
      
            
  const [conversation, setConversation] = useState<any>(null);
  const [conversationId, setConversationId] = useState<any>(null);
  const [wallData, setWallData] = useState<any[]>([]);
  // const [tag, setTag] = useState<any>(null);
  const activeTab = query_string?.includes("reviews") ? "reviews"
    : query_string?.includes("mentions") ? "mentions"
      : query_string?.includes("comments") ? "comments"
        : query_string?.includes("qa") ? "qa"
          : "all";
  const activePage = query_string?.includes("conversations") ? "conversations" : "wall";
  // const [activePage, setActivePage] = useState<any>("conversations");
  // const [activeTab, setActiveTab] = useState<any>("all");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [isWallLoading, setIsWallLoading] = useState<boolean>(false);
  useEffect(() => {
    // console.log( "Check type ======> ",{tag_type, type, unifiedData});
    // console.log("Embed Object ======> ", embedObject);
    setData(unifiedData);
      // filterByTag(query_string);
    if(unifiedData?.length && nextDate?.length){
      setHasMore(true);
      // setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      setHasMore(false);
    }
    
    
    const flag = nextDate?.filter((nd: any) => nd?.createdOn);
    // console.log({flag, nextDate, unifiedData});
    
    if(unifiedData?.length && (nextDate === null || !flag?.length)){
      setIsLoading(true);
      setHasMore(false);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    
    // setData(dummyData);
  }, [query_string, unifiedData?.length, nextDate?.length, searchText, searchStartDate, searchEndDate, searchFilterStatus]);
  
  useEffect(() => {
    // console.log( "wallUnifiedData length conversation_id ======> ", wallUnifiedData?.length);
    
    setIsWallLoading(true);
    setTimeout(() => {
      setIsWallLoading(false);
    }, 3000);
    if (wallUnifiedData?.length) {
      setWallData(wallUnifiedData);
    }else {
      setWallData([]);
    }

    if (unifiedData?.length) {
      if (conversationId && !conversation) {
        const conversation = unifiedData?.find((conversation: any) => conversation?.reviewId === conversationId);
        // console.log({conversation});
        setConversation(conversation);
      }
    }
  }, [wallUnifiedData?.length, unifiedData?.length, conversationId]);

  const fetchMoreData = () => {
    // if (nextDate === null || !nextDate?.length) {
    //   setHasMore(false);
    //   return;
    // }
    setHasMore(true);
    // a fake async api call like which sends
    console.log({type_more: true});
    
    setTimeout(() => {
      setHasMore(false);
      handleTriggerData(triggerData, false);
      // setData(data.concat(unifiedData.slice(data?.length, data?.length + 7)));
    }, 500);
  };
  
  const filterBySearchData = (value?: any, key?: any) => {
    // dispatch
    // console.log("key, value ======> ", {key, value});

    handleSearchData(key, value);
    // setIsLoading(true);
    // if(key === "readStatus"){
    //   dispatch({type: "FILTER_BY_READ_STATUS", payload: value});
    // } else if(key === "searchText"){
    //   dispatch({type: "FILTER_BY_SEARCH_TEXT", payload: value});
    // } else{
    //   dispatch({type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value});
    // }

  };

  
  useEffect(() => {
    // if (!integrationConnections?.length) {
    //   const workspaceId = logged_in_user_secret_data?.wuid;
    //   dispatch(triggerGetIntegrationConnections(workspaceId));
    // }
    if (query_string && unifiedData?.length) {
      setIsLoading(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 3000);
      const path = query_string.replace('?', '')?.replace('&', "=");
      const path_array = path.split("=");
      // console.log({path_array});
      // console.log({query_string});
      // filterByTag(query_string);
      setHasMore(true);
      if (
        query_string?.includes("reviews=conversations")
        || query_string?.includes("mentions=conversations")
        || query_string?.includes("comments=conversations")
        || query_string?.includes("qa=conversations")
        || query_string?.includes("all=conversations")
        || query_string?.includes("reviews=wall")
        || query_string?.includes("mentions=wall")
        || query_string?.includes("comments=wall")
        || query_string?.includes("qa=wall")
        || query_string?.includes("all=wall")
      ) {
        // setActiveTab("all");
        // setActivePage("conversations");
        // console.log({ path_array });
        console.log({ query_string });
        if(path_array?.length > 2) {
          // console.log("Conversation Id ======> ", path_array[path_array?.length - 1]);
          
          // console.log("Conversation Id wallUnifiedData", unifiedData);
          // setConversation(null);
          const conversation = unifiedData?.find((conversation: any) => conversation?.reviewId === path_array[path_array.length - 1]);
          // console.log("coversation_id", conversation);
          if(conversation){
            setConversation(conversation);
            setConversationId(path_array[path_array.length - 1]);
          }
          else {
            setConversation(null);
          }
          
        } else {
          setIsLoading(true);
          // setTimeout(() => {
          //   setIsLoading(false);
          // }, 2000);
          setConversationId(null);
        }
      } else {
        // console.log({ path_array });
        // console.log({ query_string });
        navigate(route_path);
      }

    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 8000);
  }, [query_string, unifiedData?.length]);

  const [currentPage, setCurrentPage] = useState<any>(null);
  useEffect(() => {
    dispatch(toggleReplyingOn(false));
    setCurrentPage(null);
  }, [query_string]);

  useEffect(() => {
    if (!pageRoute && !isReplyingOn) {
      setSearchText("");
      dispatch({ type: "REMOVE_SEARCH_TEXT" });
      dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
      dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
      // dispatch(resetUnifiedInbox());
      // dispatch(resetWallUnifiedInbox());
      setCurrentPage(null);
    }
  }, [pageRoute, isReplyingOn]);
  
  const handlePageChange = (page?: any) => {
    console.log("Page change", page);
    if (!page) {
      // dispatch(toggleReplyingOn(false));
      setCurrentPage(null);
      return;
    }
    if(page?.isFromReply){
      navigate(page?.value);
      setCurrentPage(null);
    } else {
      if (isReplyingOn) {
        dispatch(changePage(`${route_path}?${page}`));
      } else {
        setSearchText("");
        dispatch({ type: "REMOVE_SEARCH_TEXT" });
        dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
        dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
        // dispatch(resetUnifiedInbox());
        // dispatch(resetWallUnifiedInbox());  
        // dispatch(resetUnifiedInbox());
        navigate(`${route_path}?${page}`);
        setCurrentPage(null);
      }
    }
  };

  const wallDataKey: any =
    route_path?.includes('/unified')
    ? 'InboxWall'
    : route_path?.includes('/customer-feedback')
    ? 'CFWall'
    : route_path?.includes('/video-testimonial')
    ? 'VTWall'
    : route_path?.includes('/google')
    ? 'GoogleWall'
    : route_path?.includes('/facebook')
    ? 'FacebookWall'
    : route_path?.includes('/twitter')
    ? 'TwitterWall'
    : 'InboxWall';
  
  const handleDeleteWallConversation = (id: any) => {
    // console.log({id, wallUnifiedData});
    
    // const newWallData = wallUnifiedData?.filter((wd: any) => wd?.reviewId !== id);
    const deleteWallRecord = wallUnifiedData?.find((wd: any) => wd?.reviewId === id);
    console.log({deleteWallRecord, id});
    if (deleteWallRecord) {
      const deleteWallPayload = {
        id: deleteWallRecord?.reviewId,
        clapupId: logged_in_user_secret_data?.cuid,
        workSpaceId: logged_in_user_secret_data?.wuid,
        roleId: logged_in_user_secret_data?.roleId,
        // provider: capitalize(deleteWallRecord?.provider),
        providerType: deleteWallRecord?.tag === "mentions" && deleteWallRecord?.provider === "twitter" ? "twitterMentions"
                    : deleteWallRecord?.tag === "mentions" && deleteWallRecord?.provider?.includes("facebook") ? "facebookMentions"  
                    : deleteWallRecord?.tag === "comments" ? "facebookComments" 
                    : deleteWallRecord?.provider === "videoTestimonial" ? "videoTestimonial" 
                    : deleteWallRecord?.provider === "customerFeedBack" ? "customerFeedback" 
                    : deleteWallRecord?.tag === "googleQandA" ? "googleQandA" 
                    : (deleteWallRecord?.provider === "google" && deleteWallRecord?.tag === "reviews") ? "googleReviews" 
                    : (deleteWallRecord?.provider === "facebook" && deleteWallRecord?.tag === "reviews") ? "facebookReviews" 
                    : "",
        wallType: route_path?.includes("unified") ? "inbox" : "general",
        actionFlag: 0,
      };

      dispatch(triggerDeleteWallUnifiedData({deleteWallPayload, data: deleteWallRecord, key: wallDataKey}));
    }
  };

  const handleFindWallUnifiedData = () => {
    // dispatch wall unified data
    // handleTriggerUnified();
    console.log("active tab ======> ", activeTab);
    // setConversationId(null);
    // handlePageChange(`all=wall`);
    dispatch(changePage(`${route_path}?all=wall`));
    // handlePageChange(conversationId ? `${activeTab}=wall&conv=${conversationId}` : `${activeTab}=wall`);
  };


  // console.log({activePage, activeTab, conversationId});
  // const [feedHover, setFeedHover] = useState<any>(false);
  // const handleFeedsHover = () => {
  //   setFeedHover(true);
  // }
  const [isRequestedReview, setIsRequestedReview] = useState<boolean>(false);
  const [isEmbedCode, setIsEmbedCode] = useState<any>(false);

  // wall unified data for embed code from biopage
  // const handleEmbedCodeGenerateAndCopy_Old = () => {
  //   // const embedCode = `<iframe src="https://www.clapup.com/embed/${logged_in_user_secret_data?.cuid}" width="100%" height="100%" style="border: none;"></iframe>`;
  //   // navigator.clipboard.writeText(embedCode);
  //   // toast.success("Embed code copied to clipboard");
  //   console.log("Clicked Embed Code");
  //   // embed code generate and copy by embedObject
  //   const {
  //     clapupUserId,
  //     roleId,
  //     workspaceId,
  //     googleReviewsCreatedOn,
  //     facebookReviewsCreatedOn,
  //     customerFeedbackCreatedOn,
  //     videoTestimonialCreatedOn,
  //     facebookCommentsCreatedOn,
  //     facebookMentionsCreatedOn,
  //     googleQuestionsCreatedOn,
  //   }: any = embedObject;
  //   const bio_page_embed_object = {
  //     clapupUserId: clapupUserId || "",
  //     roleId: roleId || "",
  //     workspaceId: workspaceId || "",
  //     googleReviewsCreatedOn: googleReviewsCreatedOn || "",
  //     facebookReviewsCreatedOn: facebookReviewsCreatedOn || "",
  //     customerFeedbackCreatedOn: customerFeedbackCreatedOn || "",
  //     videoTestimonialCreatedOn: videoTestimonialCreatedOn || "",
  //     facebookCommentsCreatedOn: facebookCommentsCreatedOn || "",
  //     facebookMentionsCreatedOn: facebookMentionsCreatedOn || "",
  //     googleQuestionsCreatedOn: googleQuestionsCreatedOn || "",
  //     menuType: "inbox" // menuType values are inbox, google, facebook, customerFeedback, videoTestimonial
  //   };
  //   const encoded_embed_code = btoa(JSON.stringify(bio_page_embed_object));
  //   // console.log({ encoded_embed_code });
  //   const decoded_embed_code = JSON.parse(atob(encoded_embed_code));
  //   console.log({ decoded_embed_code });
  //   const api_url = bio_url + "embed_code=" + encoded_embed_code;
    
  //   // navigator?.clipboard?.writeText(api_url);
  //   setIsEmbedCode(api_url);
  // }

  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const handleEmbedCodeGenerateAndCopy = () => {
    console.log("Clicked Embed Code");
    const { subDomainName, domainName } = workspaceSettings || {};
    let bio_page_embed_url = "";
    if (bio_url) {//for local only
      bio_page_embed_url = bio_url;
    } else {
      bio_page_embed_url = `https://${subDomainName}.${domainName}`;
    }
    const api_url = bio_page_embed_url + "/wall/main";
    setIsEmbedCode(api_url);
  }

  const handleSearchWallData = (searchText?: any, isSearchingWall?: any) => {
    if (isSearchingWall === "wall") {
      console.log("Wall Search Text ======> ", { searchText, isSearchingWall, searchPayload });
      // const { readStatus, fromDate, toDate } = searchPayload;
      // delete searchPayload?.isFirstTime;
      dispatch({ type: "FILTER_BY_WALL_SEARCH_TEXT", payload: searchText });
      const wallSearchObject: any = {
        // ...searchPayload,
        clapupUserId: logged_in_user_secret_data?.cuid,
        roleId: logged_in_user_secret_data?.roleId,
        workspaceId: logged_in_user_secret_data?.wuid,
        searchText,
        isFirstTime: true, // Only for wall search
        key: 'InboxWall',
        nextKey: 'InboxWall_nextDate',
        nextDate: [],
        providerType: 'inbox',
        tag: 'all',
        readStatus: "All",
        fromDate: "2000-01-01T00:00:00.00000",
        toDate: new Date().toISOString().replace("Z", ""),
        lastSerialNo: 0,
      };
      console.log({ WallSearchObject: wallSearchObject });
      dispatch(triggerWallUnified(wallSearchObject));
      // dispatch(triggerSearchWallData(wallSearchObject));
    } 
  };

  // console.log({activePage: activePage});
  
  const [wallBoxData, setWallBoxData] = useState<any>(null);
  useEffect(() => {
    const curWallBoxData = unifiedData?.find((wd: any) => wd?.SlNo === wallBoxId);
    // console.log({unifiedData, curWallBoxData, wallBoxId});
    setWallBoxData(curWallBoxData);
  }, [wallBoxId]);
  // console.log({inbox_data: conversation, conversationId});

  return (
    <>
      {
        currentPage?.value ?
          <Dialog
            open={currentPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      <div
        className={`
          feeds-container w-100 position-relative
          ${
            data?.length && conversationId
              ? "feeds-conversations-details"
              : "feeds-row-mobile"
          }
        `}
        // style={{
        //   padding: data?.length && conversationId ? "0" : "2.1% 4% 0% 2%",
        // }}
      >
        <Row className={`mobile-conversations mobile-feed`}>
          <Col
            md={3}
            className={data?.length && conversationId ? "" : "feeds-section"}
          >
            {!conversationId ? (
              <div>
                {query_string?.includes("wall") ? (
                  <Search
                    input_height={32}
                    parentCallback={handleSearchWallData}
                    parentKey="wall"
                  />
                ) : (
                  <Search
                    input_height={32}
                    parentCallback={(value: any, key: any) =>
                      filterBySearchData(value, key)
                    }
                    searchText={sText}
                    setSearchText={setSearchText}
                    setConversationId={setConversationId}
                    activeTab={activeTab}
                    activePage={activePage}
                  />
                )}

                {query_string?.toLocaleLowerCase()?.includes("wall") ? null : (
                  <FeedTabs activePage={activePage} handlePageChange={handlePageChange}/>
                )}
              </div>
            ) : // : !isLoading && data?.length && conversationId ? (
            conversationId ? (
              <div className="conversations h-100">
                <Conversations
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                />
              </div>
            ) : null}
  
            {
              // conversations
              query_string?.toLocaleLowerCase()?.includes("wall") ? (
                <div className="wall-section">
                  <div className="wall-search-section mt-3">
                    <div className="embed-wall pb-3">
                      {/* <div className="sm-search-container position-sticky top-0">
                        <DebounceInput
                          minLength={0}
                          debounceTimeout={500}
                          onChange={(e: any) =>
                            handleSearchWallData(e.target.value)
                          }
                          type="search"
                          placeholder={"Search"}
                        />
                        <img
                          className="search-icon"
                          src={search}
                          alt="search"
                        />
                      </div> */}
                      <div className="action-buttons">
                        <Button
                          classes="secondary-btn"
                          children={"Embed Code"}
                          // children={isEmbedCode ? "Copied" : "Embed Code"}
                          onClick={() => handleEmbedCodeGenerateAndCopy()}
                        />

                        {path?.includes("customer-feedback") ||
                        path?.includes("video-testimonial") ||
                        path?.includes("google") ||
                        path?.includes("facebook") ? (
                          <Button
                            classes="secondary-btn"
                            children="Request Review"
                            onClick={() => setIsRequestedReview(true)}
                          />
                        ) : null}

                      </div>
                    </div>
                    {isWallLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "50vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : wallData?.length ? (
                      <WallConversations
                        handleDelete={handleDeleteWallConversation}
                        wallData={wallData}
                      />
                    ) : (
                      <div className="empty-wall-section">
                        <img src={emptyBox} alt="" />
                        <h4>Your Wall is Empty.</h4>
                        <span>Select an item from inbox to display!</span>
                      </div>
                    )}
                  </div>
                </div>
              )
              // inbox feeds
              : !conversationId || !query_string ? (
                <div className="feeds-section-hove mt-2">
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    className="feed-scrollin"
                    scrollThreshold={0.9}
                    style={{ overflow: "scroll" }}
                    next={() => {
                      console.log(
                        `prev -> ${data?.length}, next -> ${data?.length + 20}`
                      );
                      fetchMoreData();
                    }}
                    inverse={false}
                    hasMore={hasMore}
                    loader={
                      // !isLoading && hasMore && data?.length ? (
                      //   <div className="d-flex justify-content-center align-items-center mt-1">
                      //     <Spinner
                      //       size="sm"
                      //       animation="border"
                      //       variant="primary"
                      //     />
                      //   </div>
                      // ) :
                      null
                    }
                    scrollableTarget="scrollableDiv"
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }

                    height={"80vh"}
                  >

                    {data?.length && !isLoading ? (
                      data.map((item: any, index: number) => {
                        return (
                          <div
                            onClick={() => {
                              if (query_string?.includes("conversations") && !query_string?.includes(item?.reviewId)) {
                                if (isReplyingOn) {
                                  setCurrentPage({ isFromReply: true, value: `${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}` });
                                } else {
                                  navigate(`${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}`);
                                }
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            className={`${
                              conversationId === item?.reviewId &&
                              query_string?.includes("conversations")
                                ? "feed-card-active"
                                : ""
                            } feeds-card my-2 ${
                              query_string?.includes("conversations") &&
                              "feed-card-hover"
                            }`}
                            key={index}
                          >
                            <InboxCard
                              type={type}
                              inbox={item}
                              activePage={activePage}
                              activeTab={activeTab}
                              routePath={route_path}
                              wallData={wallData}
                              setWallData={setWallData}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="empty-feeds-section">
                        <div
                          className="empty-wall-section position-absolute"
                          style={{ top: "35%" }}
                        >
                          {/* {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center mt-1">
                              <Spinner
                                size="sm"
                                animation="border"
                                variant="primary"
                              />
                            </div>
                          ) : (
                            <div>
                              <img src={emptyBox} alt="" />
                              <h4>Empty Feeds</h4>
                            </div>
                          )} */}
                          <div>
                            <img src={emptyBox} alt="" />
                            <h4>Empty Feeds</h4>
                          </div>
                        </div>

                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              ) : null
              // isLoading && conversationId && <div
              //     className="d-flex justify-content-center align-items-center"
              //     style={{ height: "100vh" }}
              //   >
              //     <Spinner animation="border" variant="primary" />
              //   </div>
            }

            {conversationId ? null : !conversationId ? (
              <MobileBottomTabs/>
            ) : null}
          </Col>
        </Row>
      </div>

      <div className="feeds-row-lg feeds-container">
        <div
          style={{
            // width: `${query_string?.includes("conversations") ? "99%" : "98%"}`,
            // height: "90.4vh",
            overflow: "hidden",
            // display: "flex",
            // gap: '0.4%',
          }}
          className="feed-row-lg-container"
        >
          {
            !query_string?.toLocaleLowerCase()?.includes("wall")
            ?   (
            <div className="conversation-left-side">
              <FeedLeftBar/>
            </div>
            ) : null
          }
          <div className="feeds-sections"
          // <div className="feeds-section col col-xl-3 col-xxl-3"
            // style={{ width: query_string?.includes("conversations") ? '25%' : "25%" }}
          >
            <FeedTabs activePage={activePage} handlePageChange={handlePageChange}/>
            
            <div className="feeds-section-hover">
              <InfiniteScroll
                dataLength={data?.length || 0}
                className="scrollin"
                scrollThreshold={0.9}
                style={{ overflow: "scroll", paddingRight: 2 }}
                next={() => {
                  console.log(
                    `prev -> ${data?.length}, next -> ${data?.length + 20}`
                  );
                  fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={
                  // !isLoading && hasMore && data?.length ? (
                  //   <div className="d-flex justify-content-center align-items-center mt-1">
                  //     <Spinner
                  //       size="sm"
                  //       animation="border"
                  //       variant="primary"
                  //     />
                  //   </div>
                  // ) :
                  null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"85vh"}
                height={"88vh"}
              >
                <div className="search-bar-container">
                  <Search
                    parentCallback={(value: any, key: any) =>
                      filterBySearchData(value, key)
                    }
                    input_height={32}
                    searchText={sText}
                    setSearchText={setSearchText}
                    setConversationId={setConversationId}
                    activeTab={activeTab}
                    activePage={activePage}
                  />
                </div>

                {/* Infinity Scrolling */}

                {(data?.length) ? (
                // {(data?.length && !isLoading) || conversationId ? (
                  data?.map((item: any, index: number) => {
                    return (
                      <div
                        onClick={() => {
                          if (query_string?.includes("conversations") && !query_string?.includes(item?.reviewId)) {
                            // dispatch({type: "REMOVE_SEARCH_TEXT"});
                            // dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
                            // dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
                            if (isReplyingOn) {
                              setCurrentPage({ isFromReply: true, value: `${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}` });
                            } else {
                              navigate(`${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}`);
                            }
                          }
                        }}
                        style={{ cursor: query_string?.includes("conversations") ? "pointer" : "" }}
                        className={`
                          ${
                            conversationId === item?.reviewId
                            && query_string?.includes("conversations")
                            ? "feed-card-active"
                            : ""
                          } 
                          ${
                            wallBoxId === item?.SlNo
                            && activePage === "wall"
                            ? "feed-card-active"
                            : ""
                          } 
                          feeds-card 
                          ${
                            !query_string?.includes("wall") &&
                            "feed-card-hover"
                          }
                      `}
                        key={index}
                      >
                        <InboxCard
                          type={type}
                          inbox={item}
                          activePage={activePage}
                          activeTab={activeTab}
                          routePath={route_path}
                          wallData={wallData}
                          setWallData={setWallData}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="empty-feeds-section">
                    <div
                      className="empty-wall-section position-absolute"
                      style={{ top: "35%" }}
                    >
                      {/* {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        </div>
                      ) : (
                        <div>
                          <img src={emptyBox} alt="" />
                          <h4>Empty Feeds</h4>
                        </div>
                      )} */}
                      <div>
                        <img src={emptyBox} alt="" />
                        <h4>Empty Feeds</h4>
                      </div>
                    </div>

                    {/* <img src={imagePath} alt="" />
                    <h4>{feedsEmptyContent}</h4>
                    <Button classes="secondary" children={buttonContent} /> */}
                  </div>
                )}
              </InfiniteScroll>
              {/* </Scrollbars> */}
            </div>
          </div>
          <div
            className={`${query_string?.includes("wall") ? "wall-sections" : "conversation-sections"}`}
            // className={query_string?.includes("conversations") ? "wall-section col-xl-6 col-xxl-6" : "wall-section col-xl-9 col-xxl-9"}
            // md={query_string?.includes("conversations") ? 6 : 9}
            // style={{
            //   width: query_string?.includes("conversations") ? 'inherit' : "72%",
            //   flexShrink: query_string?.includes("conversations") ? '1' : "inherit",
            // }}
          >
            {query_string?.toLocaleLowerCase()?.includes("wall") ? (
              <div className="wall-search-section mt-3">
                <div className="embed-wall pb-3">
                  <div className="lg-search-container position-sticky top-0">
                    <Search
                      parentCallback={handleSearchWallData}
                      parentKey="wall"
                    />
                    {/* <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    onChange={(e: any) => handleSearchWallData(e.target.value)}
                    type="search"
                    placeholder={"Search"}
                  /> */}
                    {/* <input onChange={(e: any) => handleSearchWallData(e.target.value)} type="search" alt="" placeholder="Search" /> */}
                    {/* <img className="search-icon" src={search} alt="search" /> */}
                  </div>
                  <div className="d-flex">
                    <Button
                      classes="mx-2 secondary-btn"
                      children={"Embed Code"}
                      // children={isEmbedCode ? "Copied" : "Embed Code"}
                      onClick={() => handleEmbedCodeGenerateAndCopy()}
                    />

                    {path?.includes("customer-feedback") ||
                      path?.includes("video-testimonial") ||
                      path?.includes("google") ||
                      path?.includes("facebook") ? (
                      <Button
                        classes="secondary-btn"
                        children="Request Review"
                        onClick={() => setIsRequestedReview(true)}
                      />
                    ) : null}

                    {/* <Button classes="secondary" children="Embed Code" onClick={() => toggleModal()} /> */}
                  </div>
                </div>
                {/* Vanish hoye jabe conditionally jokhn conversation er add to wall button e click & embed code nia khela hobe */}
                {
                  // isWallLoading ? (
                  //   <div
                  //     className="d-flex justify-content-center align-items-center"
                  //     style={{ height: "50vh" }}
                  //   >
                  //     {/* <Spinner size="sm" animation="border" variant="primary" /> */}
                  //     {/* medium size spinner */}
                  //     <Spinner animation="border" variant="primary" />
                  //   </div>
                  // ) :
                  wallData?.length ? (
                    <WallConversations
                      handleDelete={handleDeleteWallConversation}
                      wallData={wallData}
                    />
                  ) : (
                  <div className="empty-wall-section">
                    <img src={emptyBox} alt="" />
                    <h4>Your Wall is Empty.</h4>
                    <span>Select an item from inbox to display!</span>
                  </div>
                )}
              </div>
            ) :
              // !isLoading && data?.length &&
                conversationId ? (
            // ) : data?.length && conversationId ? (
              <div className="conversations h-100">
                <Conversations
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                />
              </div>
            ) : (
              <div className="empty-wall-section">
                {isLoading && conversationId ? (
                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <div>
                    <img src={emptyBox} alt="" />
                    <h4>Your Wall is Empty.</h4>
                    <span>Select an item from inbox to display!</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {query_string?.includes("conversations") && conversation && conversationId ? (
            <div className="conversation-details-right-side">
              <ConversationDetails conversationId={conversation?.reviewId} />
            </div>
          ) : null}

        </div>

        {isEmbedCode && (
          <CommonModal
            padding={"10px 5px"}
            // width={480}
            border={false}
            title={
              <p
                className="position-absolute top-0"
                style={{ right: 0, width: "fit-content", zIndex: 10 }}
              >
                <Button
                  onClick={() => setIsEmbedCode(false)}
                  classes={
                    "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                  }
                  style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
                >
                  {/* font awesome icon fa-xmark in react */}
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <img
                    className="mb-1 p-0"
                    height={10}
                    src={cross_icon}
                    alt=""
                  />
                </Button>
              </p>
            }
            centered={false}
            closable={false}
            closeIcon={false}
            okText={null}
            cancelText={null}
            footer={false}
            modal_visible={true}
            setModalVisible={setIsEmbedCode}
          >
            <div className="position-relative" style={{ padding: "0 10px" }}>
              <EmbedCode url={isEmbedCode} />
              {/* <EmbedCode url={isEmbedCode} /> */}
              {/* <RequestReview callback={setIsEmbedCode} /> */}
            </div>
          </CommonModal>
        )}
        {isRequestedReview && (
          <CommonModal
            padding={"10px 5px"}
            width={480}
            title={
              <p className="text-end">
                <Button
                  onClick={() => setIsRequestedReview(false)}
                  classes={
                    "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                  }
                  style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
                >
                  {/* font awesome icon fa-xmark in react */}
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <img
                    className="mb-1 p-0"
                    height={10}
                    src={cross_icon}
                    alt=""
                  />
                </Button>
              </p>
            }
            centered={true}
            closable={false}
            closeIcon={false}
            okText={null}
            cancelText={null}
            footer={false}
            modal_visible={true}
            setModalVisible={setIsRequestedReview}
          >
            <div
              className="position-relative request-review-form"
              // style={{ padding: "0 80px" }}
            >
              <RequestReview callback={setIsRequestedReview} />
            </div>
          </CommonModal>
        )}

        {(wallBoxId || wallBoxId === 1)  && (
          <CommonModal
            padding={"10px 5px"}
            width={480}
            height={"580px"}
            title={
              <div className="text-end cross-icon-container">
                <Button
                  onClick={() => dispatch(setBoxId(null))}
                  classes={
                    "cross-icon-btn"
                  }
                  style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
                >
                  {/* font awesome icon fa-xmark in react */}
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                  <img
                    className="cross-icon"
                    src={cross_icon}
                    alt=""
                  />
                </Button>
              </div>
            }
            centered={true}
            closable={false}
            closeIcon={false}
            okText={null}
            cancelText={null}
            footer={false}
            modal_visible={true}
            setModalVisible={() => dispatch(setBoxId(null))}
          >
            <div
              className="position-relative wall-box-modal"
              // style={{ padding: "0 80px" }}
            >
              {/* <InboxModal inbox={wallBoxData} last={unifiedData?.length} /> */}
              <InboxCard
                type={type}
                last={unifiedData?.length}
                isFromModalBox={true}
                inbox={wallBoxData}
                activePage={activePage}
                activeTab={activeTab}
                routePath={route_path}
                wallData={wallData}
                setWallData={setWallData}
              />
            </div>
          </CommonModal>
        )}

        {/* <div className="conversation-wall">
          <button
            onClick={() => {
              `${activePage}` !== "conversations"
                && dispatch(changePage("/unified?all=conversations"));
                // && handlePageChange(conversationId ? `all=conversations` : `all=conversations`)
            }}
            className={`inbox-button${
              (query_string?.includes("conversations")) ? "-active" : ""
            }`}
            type="button"
          >Conversation</button>
          
          <button
            onClick={() => {
              if (route_path?.includes("gallery")) {
                navigate("/unified?all=wall");
              } else { 
                `${activePage}` !== "wall" && handleFindWallUnifiedData()
              }
            }}
            className={`inbox-button${
              (query_string?.includes("wall")) ? "-active" : ""
            }`}
              type="button"
          >Wall</button>

          <button
              onClick={() =>
                (`${activePage}` === "wall" || `${activePage}` === 'conversations') && (isReplyingOn ? dispatch(changePage("/gallery?all=content")) : navigate("/gallery?all=content"))
              }
              className={`inbox-button${
                (route_path?.includes("gallery")) ? "-active" : ""
              }`}
              type="button"
          >Content</button>

        </div> */}

        {/* old version */}
        {/* <button
          onClick={() => handlePageChange(conversationId ? `${activeTab}=conversations&conv=${conversationId}` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button"></button>
        <button
          onClick={() => (`${activePage}` === "wall") && handlePageChange(conversationId ? `${activeTab}=conversations` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button">
        </button> */}
        {/* <div className="conversation-wall ms-0 d-flex flex-column justify-content-start align-items-start mt-1">
          <Tooltip title="Inbox" color="white" style={{ color: 'black !important' }} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` === "wall" &&
                handlePageChange(
                  conversationId ? `all=conversations` : `all=conversations`
                )
              }
              className={`inbox-btn${
                query_string?.includes("conversations") ? "-active" : ""
              }`}
              type="button"
            ></button>
          </Tooltip>
          <Tooltip title="Wall" color="white" style={{color: 'black !important'}} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` !== "wall" && handleFindWallUnifiedData()
              }
              className={`coder-wall-btn${
                activePage === "wall" ? "-active" : ""
              }`}
              type="button"
              // title="Wall"
            ></button>
          </Tooltip>
        </div> */}
      </div>
    </>
  );
}

export default FeedsComponent;