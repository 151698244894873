import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
// import { inbox_api_url } from '../../../utils/server';
// import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
// import { generateAccessToken } from '../../../profile/reducer';
// import { getImage } from '../../../unified/image_service';
import "./GlobalDrawer.scss";

const GlobalDrawer = ({ header_title, warning_message, left_button_title, right_button_title, children, data_fields, parentCallback, handleCancel }: any) => {
    const dispatch = useDispatch();
    const {
        open_global_drawer: showDrawer,
    }: any = useSelector((state: any) => state?.contentState);
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [cancelingOn, setCancelingOn] = useState<any>(false);
    useEffect(() => {
        if(showDrawer){
            setOpenDrawer(true);
        } else {
            if (openDrawer) {
                handleBack();
            }
        }
    }, [showDrawer])
    
    const handleBack = async () => {
        setCancelingOn(true);
        setTimeout(() => { 
            setOpenDrawer(false);
            setCancelingOn(false);
            handleCancel();
        }, 500);
    }

    // const handleContinue = async () => {
    //     console.log({ data_fields });
    //     // parentCallback();
    // }
    
    return (
        <React.Fragment>
            <div className={` ${cancelingOn ? "closing-time-drawer" : openDrawer ? "drawer-wrap" : "d-none"}`}
                onClick={() => handleBack()}
            >
                <div className={`drawer-container`}
                    onClick={(e: any) => e.stopPropagation()}
                >
                    {
                        children
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default GlobalDrawer;
