import React, { useState } from 'react';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';
import VideoViewer from './VideoViewer';
import UnavailableViewer from './UnavailablePreview';
const ContentViewer = ({ content }: any) => {
    return (
        <React.Fragment>
            {
                content?.contentFileType?.includes("image") ? <ImageViewer content={content}/>
                : content?.contentFileType?.includes("video") ? <VideoViewer content={content}/>
                : content?.contentFileType?.includes("pdf") ? <PdfViewer content={content}/>
                : <UnavailableViewer/>
            }
        </React.Fragment>
    )
}

export default ContentViewer;