import React, {useState, useEffect} from "react";
import {Select} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import {
    getImage,
    // getGenerateApiImageByName
} from "../../../../unified/image_service";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";
import { requestForUpdatingTeamSettings } from "../teamsReducer";

const color_values = {
    HEX: ['#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#B42318', '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#B42318',],
    HSL: ['hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(4, 76%, 40%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(4, 76%, 40%)',],
    RGB: ["rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(180, 35, 24)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(180, 35, 24)",],
};

const SettingsTeam: React.FC = () => {
    const dispatch = useDispatch();
    const {
        editable_team,
        setting_teams: teams,
        team_emails_by_team_id,
     } = useSelector((state: any) => state?.teamState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const [teamName, setTeamName] = useState<any>(null);
    const [teamColor, setTeamColor] = useState<any>(null);
    // const [selected_email, setSelectedEmail] = useState<any>(false);
    const [defaultReplyAddress, setDefaultReplyAddress] = useState<any>(null);
    const [unAssignConversation, setUnAssignConversation] = useState<any>(null);
    const [closeConversation, setCloseConversation] = useState<any>(null);
    const [isolateInbox, setIsIsolatedInbox] = useState<any>(false);
    const [mergeDuplicateMails, setIsMergedDuplicateEmail] = useState<any>(false);
    // console.log({ team_emails_by_team_id });
    useEffect(() => {
        const {
            teamName, teamColor, defaultReplyAddress,
            unAssignConversation, closeConversation,
            isolateInbox, mergeDuplicateMails
        } = editable_team || {};

        setTeamName(teamName);
        setTeamColor(teamColor);
        setDefaultReplyAddress(defaultReplyAddress);
        setUnAssignConversation(unAssignConversation);
        setCloseConversation(closeConversation);
        setIsIsolatedInbox(isolateInbox ? "1" : "0");
        setIsMergedDuplicateEmail(mergeDuplicateMails ? "1" : "0");
    }, [editable_team?.teamId]);
    
    const handleChangeTeamSettings = (fieldName: any, fieldValue: any) => {
        const clapupUserId = logged_in_user_secret_data?.cuid || null;
        const workspaceId = logged_in_user_secret_data?.wuid || null;
        // const roleId = logged_in_user_secret_data?.roleId || null;
        const data_payload = {
            workspaceId: workspaceId || "",
            clapupUserId: clapupUserId || "",
            // roleId: roleId || "",
            teamId: editable_team?.teamId,
            fieldName: fieldName,
            fieldValue: fieldValue
        };
        dispatch(requestForUpdatingTeamSettings(data_payload));
    }    

    return (
        <React.Fragment>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Team Name</div>
                    <div className="description">This will used to identify team from other teams. Use a unique names that represent your team.</div>
                </div>
                <div className="action-body">
                    <div className="label-input-container">
                        <label className="d-none" style={{color: "transparent"}}>{"Non Label"}</label>
                        <input
                            className=""
                            value={teamName || ""}
                            onChange={(e) => setTeamName(e.target.value)}
                            placeholder={"Team Name"}
                        />
                        <img
                            className="non-label-tik"
                            onClick={() => handleChangeTeamSettings("teamName", teamName)}
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Team Color</div>
                    <div className="description">This will be used to differentiate the team from other. Use a unique color that is not used by other teams.</div>
                </div>
                <div className="color-picker-action-body">
                    <div className="color-picker-input-container">
                        <ColorPicker
                            palette_label={null}
                            color_values={color_values}
                            value={teamColor}
                            color_picker_container_class_name={"settings-team-color-picker-container"}
                            onChange={(value: any) => {
                                console.log({ color_value: value });
                                setTeamColor(value);
                                handleChangeTeamSettings("teamColor", value)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Set default reply address</div>
                    <div className="description">Will be used as default email for replying. For dynamically using reply address choose auto. This can be changed from advanced editor.</div>
                </div>
                <div className="action-body">
                    <div className="label-input-container">
                        <label className="d-none" style={{color: "transparent"}}>{"Non Label"}</label>
                        <Select
                            className='custom-select'
                            size={"large"}
                            onChange={(value: any) => { 
                                // console.log({selected_email: value});
                                setDefaultReplyAddress(value);
                                handleChangeTeamSettings("defaultReplyAddress", value);
                            }}
                            value={defaultReplyAddress || null}
                            placeholder="Select Email or set auto"
                        >
                            {
                                (team_emails_by_team_id?.length ? team_emails_by_team_id : [{email: "3pottersmanager@gmail.com"}])?.map((item: any, idx: any) => (
                                    <Select.Option value={item?.email} key={idx}>{item?.email}</Select.Option>
                                ))
                            }
                            {/* <Select.Option value="mmhk@gmail.com">mmhk@gmail.com</Select.Option>
                            <Select.Option value="sreekanth@gmail.com">sreekanth@gmail.com</Select.Option> */}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Unassign Conversation</div>
                    <div className="description">When a conversation is not closed, and inactive for mentioned time, with a user message as active, it will be unassigned and shown as new.</div>
                </div>
                <div className="action-body">
                    <div className="label-input-container">
                        <label>eg: {"(2h, 1d, 15m, never)"}</label>
                        <input
                            className=""
                            defaultValue={unAssignConversation || ""}
                            onChange={(e) => setUnAssignConversation(e.target.value)}
                            placeholder={"Select Email or set auto"}
                        />
                        <img
                            className="tik"
                            onClick={() => handleChangeTeamSettings("unAssignConversation", unAssignConversation)}
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Close Conversation</div>
                    <div className="description">Mention the time frame with which a conversation will be automatically close due to inactivity. For WhatsApp and Facebook, this will be 24 hrs.</div>
                </div>
                <div className="action-body">
                    <div className="label-input-container">
                        <label>eg: {"eg: (2h, 1d, 15m)"}</label>
                        <input
                            className=""
                            defaultValue={closeConversation || ""}
                            onChange={(e) => setCloseConversation(e.target.value)}
                            placeholder={"Select Email or set auto"}
                        />
                        <img
                            className="tik"
                            onClick={() => handleChangeTeamSettings("closeConversation", closeConversation)}
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Isolate Inbox</div>
                    <div className="description">In case of multiple email configured for same inbox, when same user send email to both email, it will be merged together. </div>
                </div>
                <div className="action-body">
                    <img
                        onClick={() => {
                            const value = isolateInbox === "1" ? "0" : "1";
                            setIsIsolatedInbox(value);
                            handleChangeTeamSettings("isolateInbox", value);
                        }}
                        className="switch-icon"
                        src={getImage(isolateInbox === "1" ? "active-notification" : "in-active-notification")} 
                        alt="tik"
                    />
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Merge Duplicate Emails</div>
                    <div className="description">This will used to identify team from other teams. Use a unique names that represent your team.</div>
                </div>
                <div className="action-body">
                    <img
                        onClick={() => {
                            const value = mergeDuplicateMails === "1" ? "0" : "1";
                            setIsMergedDuplicateEmail(value);
                            handleChangeTeamSettings("mergeDuplicateMails", value);
                        }}
                        className="switch-icon"
                        src={getImage(mergeDuplicateMails === "1" ? "active-notification" : "in-active-notification")} 
                        alt="tik"
                    />
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default SettingsTeam;