import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
// import { convertImageToBase64 } from '../../../../../utils/convertingFunction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setCampaignDetailsPayload } from '../../../reducer';
import { setErrorMessage } from '../../../../../unified/reducer';
import { capitalizeSentence } from '../../../../../utils/convertingFunction';
// import { capitalize } from '../../../../../dashboard/image_service';
// import { setErrorMessage } from '../../../../../dashboard/reducer';
// import { extractImageNameFromUrl } from '../../../../../pages/page-routes';
// import moment from 'moment';
// import { SearchOutlined } from '@ant-design/icons';
// import { getCampaignImage } from '../../../../../dashboard/image_service';

// type VariablesFormProps = {
//     onCancel?: () => void;
//     onParentCallback?: (data?: any, file?: any) => void;
// };

const VariablesForm: React.FC<any> = ({onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const {campaign_details_payload}: any = useSelector((state: RootState) => state?.promotionsState);
    const { selected_template, current_template_id, excel_sheet_variables, non_variables_values_comma_string, non_used_variables }: any = campaign_details_payload || {};
    const { templateHeader, templateHeaderType, templateCallToActionSlug, headerSampleData, templateBody, bodySampleData, templateId }: any = selected_template || {};
    const [nonUsedVariables, setNonUsedVariables] = useState<any[]>([]);

    const generateVariablesObject = (string: string, sample_string?: string, type?: string ) => { 
        const regex = /{{(.*?)}}/g;
        // Set to store unique variable names
        const variableNamesSet = new Set();

        // Match variables and add their names to the set
        let match;
        while ((match = regex.exec(string)) !== null) {
            const variableName = match[1]?.trim()?.replaceAll(" ", "_");
            variableNamesSet.add(variableName); // Add variable name to the set
        }

        // Set to store unique variables with their corresponding objects
        const variablesSet = new Set();

        // Iterate over the set of variable names and construct corresponding objects
        variableNamesSet.forEach(variableName => {
            const variableObject = { variableName, variableValue: "", type };
            variablesSet.add(variableObject); // Add object to the set
        });

        // Set variable values based on the variable names in the sample_string
        // sample_string?.split(', ').forEach((pair: any) => {
        //     const [name, value] = pair?.split('=');
        //     variablesSet.forEach((variableObject: any) => {
        //         if (variableObject?.variableName === name) {
        //             variableObject.variableValue = value;
        //         }
        //     });
        // });
        return Array.from(variablesSet);
    };

    useEffect(() => {
        // console.log({selected_template, templateCallToActionSlug, non_variables_values_comma_string, excel_sheet_variables, non_used_variables})
        // if (templateId && !non_used_variables?.length) {
        if (selected_template?.templateId && !nonUsedVariables?.length) {
            if (selected_template?.templateId === current_template_id) {
                // setNonUsedVariables(non_used_variables);
                // const nonUsedVariables: any = non_used_variables?.filter((variable: any) => excel_sheet_variables?.find((ex_var: any) => ex_var === variable?.variableName) ? false : true);
                let header_string = templateHeader || "";
                if (templateHeaderType?.toLowerCase() !== "text" && header_string) { 
                    header_string = "{{header_url}}";
                }
                const headerVariablesArray: any[] = generateVariablesObject(header_string, headerSampleData || "", "Header");
                const body_string = templateBody || "";
                const bodyVariablesArray: any[] = generateVariablesObject(body_string, bodySampleData || "", "Body");
                let template_call_to_action_slug_string: any = "";
                if (templateCallToActionSlug) { 
                    // template_call_to_action_slug_string = "{{dynamic_url}}";
                    template_call_to_action_slug_string = templateCallToActionSlug;
                }
                const call_to_action_slug_variables: any[] = generateVariablesObject(template_call_to_action_slug_string, "", "Call To Action");
                const variables = [
                    ...bodyVariablesArray,
                    ...headerVariablesArray,
                    ...call_to_action_slug_variables,
                    ...non_used_variables, // This is identical to the previous one
                ];
                const nonUsedVariables: any = variables?.filter((variable: any) => excel_sheet_variables?.find((ex_var: any) => ex_var === variable?.variableName) ? false : true);
                const uniqueData = variables?.length
                    ?   Object.values(
                            nonUsedVariables?.reduce((acc: any, current: any) => {
                            const key = `${current.variableName}-${current.type}`;
                        
                            // If there's already an entry and the current variableValue is empty, skip it
                            if (acc[key] && current.variableValue === '') {
                                return acc;
                            }
                        
                            // If there's already an entry with a non-empty variableValue, skip the current if its value is empty
                            if (acc[key] && acc[key].variableValue !== '' && current.variableValue === '') {
                                return acc;
                            }
                        
                            // Otherwise, take the current entry
                            acc[key] = current;
                        
                            return acc;
                            }, {})
                    )
                    : [];
                // console.log({variables, uniqueData, excel_sheet_variables, nonUsedVariables});
                
                setNonUsedVariables(uniqueData);
                dispatch(setCampaignDetailsPayload({
                    ...campaign_details_payload,
                    current_template_id: selected_template?.templateId,
                    non_used_variables: uniqueData
                }));
            } else {
                let header_string = templateHeader || "";
                // if (templateHeaderType?.toLowerCase() !== "text" && header_string) { 
                if (templateHeaderType?.toLowerCase() !== "text") { 
                    header_string = "{{header_url}}";
                }
                const headerVariablesArray: any[] = generateVariablesObject(header_string, headerSampleData || "", "Header");
                const body_string = templateBody || "";
                const bodyVariablesArray: any[] = generateVariablesObject(body_string, bodySampleData || "", "Body");
                let template_call_to_action_slug_string: any = "";
                if (templateCallToActionSlug) { 
                    // template_call_to_action_slug_string = "{{dynamic_url}}";
                    template_call_to_action_slug_string = templateCallToActionSlug;
                }
                const call_to_action_slug_variables: any[] = generateVariablesObject(template_call_to_action_slug_string, "", "Call To Action");
                const variables = [
                    ...bodyVariablesArray,
                    ...headerVariablesArray,
                    ...call_to_action_slug_variables
                ];
                const nonUsedVariables: any = variables?.filter((variable: any) => excel_sheet_variables?.find((ex_var: any) => ex_var === variable?.variableName) ? false : true);
                console.log({variables, excel_sheet_variables, nonUsedVariables});
                
                setNonUsedVariables(nonUsedVariables);
                dispatch(setCampaignDetailsPayload({
                    ...campaign_details_payload,
                    current_template_id: selected_template?.templateId,
                    non_used_variables: nonUsedVariables
                }));
            }
        }
        // else if (non_used_variables?.length) {
        //     setNonUsedVariables(non_used_variables);
        // }
        // const nonUsedVariables = [...bodySampleData, ...headerSampleData];
        // setNonUsedVariables(nonUsedVariables);
    }, [selected_template?.templateId, nonUsedVariables?.length]);

    const handleSubmit = () => {
        const is_value_empty = non_used_variables?.find((variable: any) => !variable?.variableValue);
        if (is_value_empty) {
            dispatch(setErrorMessage("Please fill all the variables"));
            return;
        }
        const checking_header_url = non_used_variables?.find((variable: any) => variable?.variableName === "header_url");
        // if (checking_header_url && !new RegExp('^(http|https)://').test(checking_header_url?.variableValue)) {
        //     dispatch(setErrorMessage("Invalid URL!"));
        //     return;
        // }

        if (templateHeaderType?.toLowerCase() !== "text") {
            const header_url_value = checking_header_url?.variableValue;
            const isUrl = new RegExp('^(http|https)://').test(header_url_value);
            if (!isUrl) {
                dispatch(setErrorMessage("Invalid URL!"));
                return;
            }
            if (header_url_value) { 
                if (templateHeaderType?.toLowerCase() === "image") { 
                    // checking .jpg and .png and .jpeg check in the templateHeader string for last time
                    const isJPG = header_url_value?.endsWith(".jpg");
                    const isJPEG = header_url_value?.endsWith(".jpeg");
                    const isPNG = header_url_value?.endsWith(".png");
                    // console.log({ isJPG, isJPEG, isPNG });
                    if (!isJPG && !isJPEG && !isPNG) {
                        dispatch(setErrorMessage("Invalid Image URL!"));
                        return;
                    }
                }
                
                if (templateHeaderType?.toLowerCase() === "document") { 
                    // checking .pdf check in the header_url_value string for last time
                    const isPDF = header_url_value?.endsWith(".pdf");
                    // console.log({ isPDF });
                    if (!isPDF) {
                        dispatch(setErrorMessage("Invalid Document URL!"));
                        return;
                    }
                }
                
                if (templateHeaderType?.toLowerCase() === "video") { 
                    // checking .mp4 check in the header_url_value string for last time
                    const isMP4 = header_url_value?.endsWith(".mp4");
                    // console.log({ isMP4 });
                    if (!isMP4) {
                        dispatch(setErrorMessage("Invalid Video URL!"));
                        return;
                    }
                }
            }
        }
        // console.log({campaign_details_payload, non_used_variables, is_value_empty});
        // return;
        onParentCallback && onParentCallback();
    };

    const handleChange = (key: any, value: any) => {
        // console.log({ key, value });
        // if (key !== "header_url" && key !== "dynamic_url") {
        // if (value?.length > 60) {
        //     dispatch(setErrorMessage("Variable should be max 60 characters"));
        //     return;
        // }
        if (key !== "header_url") {
            if (value?.length > 60) {
                dispatch(setErrorMessage("Variable should be max 60 characters"));
                return;
            }
            const isUrl = new RegExp('^(http|https)://').test(value);
            if (isUrl) {
                dispatch(setErrorMessage("Invalid variable!"));
                return;
            }
        } else if (key === "header_url") {
            // if (value?.length > 100) {
            //     dispatch(setErrorMessage("Variable should be max 100 characters"));
            //     return;
            // }
            const isUrl = new RegExp('^(http|https)://').test(value);
            if (value?.length > 8) {
                if (!isUrl) {
                    dispatch(setErrorMessage("Invalid URL!"));
                    return;
                }
            }
        }
        // return;
        const cur_non_used_variables = non_used_variables?.map((variable: any) => { 
            if (variable?.variableName === key) {
                variable.variableValue = value;
            }
            return variable;
        });
        setNonUsedVariables(cur_non_used_variables);
        dispatch(setCampaignDetailsPayload({
            ...campaign_details_payload,
            non_used_variables: cur_non_used_variables
        }));
    }

    return (
        <React.Fragment>
            <div className="form-input-container">
                <p className='header-title'>Add Variables</p>
                
                {
                    nonUsedVariables?.map((variable: any, index: number) => { 
                        return (
                            <div key={index} className='variable-input-group'>
                                <label>
                                    {/* {variable?.type} - {variable?.variableName}  */}
                                    {
                                        variable?.variableName === "header_url"
                                        ?   (
                                                templateHeaderType?.toLowerCase() === "text"
                                                ? `${variable?.type} ${variable?.variableName} (Max 60 Characters)`
                                                : `Header ${capitalizeSentence(templateHeaderType)} URL (Max Size ${templateHeaderType?.toLowerCase() === "image" ? "5 Mb" : templateHeaderType?.toLowerCase() === "video" ? "16 Mb" : "100 Mb"})`
                                            )
                                        // : variable?.variableValue === "sample_url" 
                                        // ? " (Max 2000 Characters)" 
                                        // : `${variable?.type} - ${variable?.variableName} (Max 60 Characters)`
                                        : `${variable?.type} ${variable?.variableName} (Max 60 Characters)`
                                    }
                                </label>
                                <Input
                                    className='variable-input'
                                    placeholder={
                                        variable?.variableName === "header_url" && templateHeaderType?.toLowerCase() !== "text"
                                        ? `Enter the URL for Header ${capitalizeSentence(templateHeaderType)}`
                                        : `Enter Value for ${variable?.variableName}`
                                        
                                    }
                                    // readOnly={true}
                                    value={variable?.variableValue}
                                    onChange={(e: any) => {
                                        // console.log(e.target.value);
                                        const inputValue = e.target.value;
                                        const formattedValue = inputValue.replace(/\s+/g, ' ');
                                        handleChange(variable?.variableName, formattedValue);
                                    }}
                                />
                            </div>
                        )
                    })
                }

                {
                    !nonUsedVariables?.length
                    ?   (
                            <div className='d-flex justify-content-center align-items-center' style={{height: 200}} >
                                <h5>No Variables Found</h5>
                            </div>
                    ) 
                    : null
                }
                {/* <div key={index} className='variable-input-group'>
                    <label>Call to Action URL</label>
                    <Input
                        className='variable-input'
                        placeholder='URL'
                        defaultValue={'IN - India'}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            // handleChange(e.target.value, setCurCampaignText)
                        }}
                    />
                </div>

                <div className='variable-input-group'>
                    <label>CTA 2 Url</label>
                    <Input
                        className='variable-input'
                        placeholder='URL'
                        defaultValue={"Kerala"}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            // handleChange(e.target.value, setCurCampaignText)
                        }}
                    />
                </div>

                <div className='variable-input-group'>
                    <label>Header Variable</label>
                    <Input
                        className='variable-input'
                        placeholder='Header Variable'
                        defaultValue={"670650"}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            // handleChange(e.target.value, setCurCampaignText)
                        }}
                    />
                </div>

                <div className='variable-input-group'>
                    <label>Variable 3</label>
                    <Input
                        className='variable-input'
                        placeholder='Variable'
                        defaultValue={"670650"}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            // handleChange(e.target.value, setCurCampaignText)
                        }}
                    />
                </div>

                <div className='variable-input-group'>
                    <label>Variable 4</label>
                    <Input
                        className='variable-input'
                        placeholder='Variable'
                        defaultValue={"670650"}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            // handleChange(e.target.value, setCurCampaignText)
                        }}
                    />
                </div> */}

                <div className='form-button-container'>
                    <button
                        type='button'
                        className='cancel-button'
                        onClick={onCancel}
                    >
                        Back
                    </button>
                    <button
                        // disabled={}
                        className='create-button'
                        onClick={() => handleSubmit()}
                        type='button'
                    >
                        {`Yes, Create` }
                    </button>
                </div> 
            </div>
        </React.Fragment>
    )
};

export default VariablesForm;
