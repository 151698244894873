import React, { Fragment, useState } from 'react';
// import image_icon from '../../../../assets/icons/file-image-icon.svg';
// import dots from '../../../../assets/icons/more-action.svg';
// import global from '../../../../assets/icons/global.svg';
// import lock from '../../../../assets/icons/lock.svg';
// import switch_icon from '../../../../assets/icons/switch-off.svg';
// import switch_icon_active from '../../../../assets/icons/switch-on.svg';
// import '../Content.scss';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import moment from 'moment';
// import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import {
  // setInfoMessage,
  setErrorMessage, setSuccessMessage
} from '../../../unified/reducer';
// import ContentImageAndVideo from './ContentImageAndVideo';
// import Dialog from '../../Dialogs/Dialog';
import {
  // removeContentsFromFolder, setFolderContents, getOneTimeFolderContents, setOneTimeFolderContents,
  // setDefaultContentIdx, setSelectedDeletableContents,
  // getFolderContents, setIsUpdatedContent, updateFolderContent,
  // deleteContents,
  // getContentVersions,
  setShowingUploadingProgress,
  // setIsContentUploadedError,
  setUploadProgressCount,
  // new requirements
  setFolderPathsToIndexDB,
  setOpenGlobalDrawer,
  setOpenGlobalModal,
  // setFolderPathContentsPayload,
  setShowGlobalWarningDialog,
  setIsContentUploadedError,
  // setViewContentData,
  resetNotFoundFlagAndDeletedContentFlags,
} from '../reducer';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { Spinner } from 'react-bootstrap';
import { download2Content, modifyString } from '../../../utils/mediaService';
// import { convertISODate } from '../../../utils/convertingFunction';
import { getImage } from '../../../unified/image_service';
import WarningModalForm from '../../Dialogs/GlobalModalForms/WarningModalForm';
// import CommonModalCustomWidth from '../../CommonModal/CommonModalCustomWidth';
import ContentShareForm from './ContentShareForm';
import GlobalDrawer from '../../Dialogs/GlobalDrawer/GlobalDrawer';
import FolderEditorFields from './FolderEditorFields';
import GlobalModal from '../../Dialogs/GlobalModal/GlobalModal';
import MoveOrCopyForm from './MoveOrCopyForm';
import { api_url, api_version } from '../../../utils/server';
import axios from 'axios';
import { generateAccessToken } from '../../../profile/reducer';
import { deleteKeyValue, setKeyValue } from '../../../utils/indexedDBManagementService';
// import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
// const content_data = [
//   {
//     id: 12,
//     contentFileName: "Social Media Images",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Sreekanth PM",
//     size: "15 MB",
//     isReleased: false,
//     stage: "publish",
//   },
//   {
//     id: 1,
//     contentFileName: "Testing Folder",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Sreekanth PM",
//     size: "15 MB",
//     isReleased: true,
//     stage: "unpublish",
//   },
//   {
//     id: 2,
//     contentFileName: "dubai-way.docx",
//     createdOn: "8.30 AM, 12 June, 2024",
//     createdBy: "Sreekanth PM",
//     size: "20 MB",
//     isReleased: true,
//     stage: "unpublish",
//     checkedOut: true,
//   },
//   {
//     id: 3,
//     contentFileName: "dubai-way.xlsx",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "MMHK",
//     size: "15 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: false,
//     isShared: true,
//   },
//   {
//     id: 4,
//     contentFileName: "dubai-way.pdf",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Sreekanth PM",
//     size: "11 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: true,
//   },
//   {
//     id: 5,
//     contentFileName: "dubai-way.pptx",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "MMHK",
//     size: "10 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: true,
//   },
//   {
//     id: 6,
//     contentFileName: "dubai-way.jpg",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Sreekanth PM",
//     size: "15 MB",
//     isReleased: false,
//     stage: "unpublish",
//     checkedOut: true,
//   },
//   {
//     id: 7,
//     contentFileName: "dubai-way.docx",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "MMHK",
//     size: "8 MB",
//     isReleased: false,
//     stage: "unpublish",
//     checkedOut: true,
//   },
//   {
//     id: 8,
//     contentFileName: "dubai-way.jpeg",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Kuber Mannur",
//     size: "15 MB",
//     isReleased: false,
//     stage: "unpublish",
//     checkedOut: true,
//   },
//   {
//     id: 9,
//     contentFileName: "dubai-way.mp4",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "Sreekanth PM",
//     size: "15 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: true,
//   },
//   {
//     id: 10,
//     contentFileName: "dubai-way.png",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "MMHK",
//     size: "12 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: true,
//   },
//   {
//     id: 11,
//     contentFileName: "dubai-way.pdf",
//     createdOn: "7.35 AM, 12 June, 2022",
//     createdBy: "MMHK",
//     size: "15 MB",
//     isReleased: false,
//     stage: "publish",
//     checkedOut: true,
//   },
// ];

const ParentContents = ({ category, parentFolderPaths, content_data, updateContentIntoStateAndDB }: any) => {
  // setNewVersionVideoFile, handleUploadNewVersionFile
  const {
    // searched_content_text, content_sort_type,
    // folder_contents, is_updated_folder_content,
    // selected_deletable_contents, default_content_idx, show_uploading_progress,
    // new requirement
    // contentNextDate,
    folder_paths,
    // open_global_drawer, showEditorFieldDrawer,
    showGlobalWarningDialog,
  }: any = useSelector((state: RootState) => state?.contentState);
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const { setting_teams: teams } = useSelector((state: any) => state?.teamState);
  // const { params } = useParams<{ params: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;
  // const path = query_string.replace('?', '')?.replace('&', "=");
  // const path_array = path.split("=");
  // new requirements    
  const { menuTags: filter_by_labels } = useSelector((state: any) => state?.workspaceManagementState);
  // const [contents, setContents] = useState<any>(content_data);
  const [showingContentShareForm, setShowingContentShareForm] = useState<any>(null);
  const [showingForMovingOrCopyContentToFolder, setShowingForMovingOrCopyContentToFolder] = useState<any>(null);
  const [showFolderEditorDrawer, setShowFolderEditorDrawer] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState<any>(null);
  const [isReleasedDropdown, setIsReleasedDropdown] = useState<any>(null);

  const handleShowChildContentsByFolderName = (data: any) => {
    if (!data?.isFolder) {
      handleViewContent(data);
      return;
    }
    
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    if (!workspaceId || !clapupUserId) {
      return;
    }
    
    const lastFolder = folder_paths[folder_paths?.length - 1];
    const isAlReadyFilteredByTagLabel = filter_by_labels?.find((item: any) => item?.tagId === lastFolder?.tagId);
    // const get_content_payload = {
    //   workspaceId: workspaceId,
    //   clapupUserId: clapupUserId,
    //   teamId: "", // if team is come in root otherwise blank ""
    //   createdOn: new Date().toISOString().replace('Z', ''), // "2024-12-05T05:48:33.831Z",
    //   isFolder: 1,
    //   folderPath: folderPath || "",
    //   category: category,
    // };
    
    // for checking label 
    // if (isAlReadyFilteredByTagLabel) {
    //   // console.log({ get_content_payload, data, folder_paths });
    //   const new_folder_path = {
    //     id: data?.contentFileName?.replaceAll(" ", "") + "-" + Number(new Date()),
    //     folderPath: folderPath || "",
    //     name: data?.contentFileName,
    //   };
    //   const folder_object = {
    //       isForInserting: true,
    //       isNotRefreshing: false,
    //       key: "folder_paths",
    //       value: [
    //           ...folder_paths?.filter((item: any) => item?.tagId !== lastFolder?.tagId),
    //           new_folder_path,
    //       ]
    //   };
    //   dispatch(setFolderPathsToIndexDB(folder_object));
    //   // dispatch(setFolderPathContentsPayload(get_content_payload));
    //   return;
    // }
    // ======== for checking label ========
    // console.log({ get_content_payload, data, folder_paths });\
    const folderPath = `${data?.internalFileName || data?.InternalFileName}`;
    const new_folder_path = {
      id: data?.contentFileName?.replaceAll(" ", "") + "-" + Number(new Date()),
      folderPath: folderPath || "",
      name: data?.contentFileName,
    };
    const folder_object = {
      isForInserting: true,
      isNotRefreshing: true,
      key: "folder_paths",
      value: [
        ...folder_paths,
        new_folder_path,
      ]
    };
    // console.log({ folder_object, isAlReadyFilteredByTagLabel, filter_by_labels });
    // return;
    dispatch(resetNotFoundFlagAndDeletedContentFlags());
    dispatch(setFolderPathsToIndexDB(folder_object));
    // dispatch(setFolderPathContentsPayload(get_content_payload));
  }

  // ============ Modal Action Management =========
  // Draft-Release content
  const handleDraftReleaseContent = (content: any, header_title?: any, warning_message?: any, key?: any, value?: any) => {
    // api, content necessary data...., header_title,
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    if (!workspaceId || !clapupUserId) {
      setIsReleasedDropdown(null);
      setShowDropdown(null);
      return;
    }

    const payload: any = {
      workspaceId: workspaceId,
      clapupUserId: clapupUserId,
      contentId: content?.contentId,
      category: content?.category || category,
      stage: value
    };

    const content_object = {
      api_url: api_url + `/api/${api_version}/content/ChangeContentStage`,
      header_title: header_title || "",
      warning_message: warning_message || "",
      left_button_title: "Back",
      right_button_title: "Continue",
      success_message: header_title === "Release File" ? "File release successfully" : "Draft version created successfully",
      key: key,
      value: value,
      payload,
      content
    }

    dispatch(setShowGlobalWarningDialog(content_object));
    setIsReleasedDropdown(null);
    setShowDropdown(null);
  };

  // isLocked: true -> show checkin and do checkout / false -> show checkout and do checkin content
  const handleCheckInAndOut = async(content: any, mode?: any, header_title?: any, warning_message?: any, key?: any, value?: any) => {
    // api, content necessary data...., header_title,
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      setShowDropdown(null);
      setIsReleasedDropdown(null);
      if (!workspaceId || !clapupUserId) {
        return;
      }

      const payload: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        contentId: content?.contentId,
        category: content?.category || category,
        mode: mode, // checkin/checkout
      };
  
      const success_message = value ? "File check-out successfully" : "File check-in successfully";
      // // if we have needed dialog we will implement it
      // const content_object: any = {
      //   api_url: "",
      //   header_title: header_title || "",
      //   warning_message: warning_message || "",
      //   left_button_title: "Back",
      //   right_button_title: "Continue",
      //   key: key,
      //   value: value,
      //   success_message,
      //   payload,
      //   content
      // }
  
      const url = api_url + `/api/${api_version}/content/CheckInCheckOutContent`;
      const { data: response_data, status } = await axios.post(url, payload, {
          headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
          }
      });
      if (status === 200) {
          // dispatch(setSuccessMessage("Closed Successfully"));
          updateContent({ key, value, content, response_data, success_message });
      }
    } catch (error: any) {
      const { response }: any = error;
      console.log('error', response);
      const { status } = response || {};
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      } else {
          dispatch(setErrorMessage("Something went wrong"));
      }
    }

  };

  // Publish content
  const handlePublishContent = async(content: any, mode?: any, header_title?: any, warning_message?: any) => {
    // api, content necessary data...., header_title,
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      setShowDropdown(null);
      setIsReleasedDropdown(null);
      if (!workspaceId || !clapupUserId) {
        return;
      }

      const payload: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        contentId: content?.contentId,
        category: content?.category || category,
        mode: mode, // publish/undoPublish
      };
      // api, content necessary data...., header_title,
      // success_message = "Published successfully" or "Unpublished successfully"
      const content_object = {
        api_url: api_url + `/api/${api_version}/content/PublishUnPublishContent`,
        header_title: header_title || "",
        warning_message: warning_message || "",
        left_button_title: "Back",
        right_button_title: "Continue",
        success_message: header_title === "Undo Publish" ? "Unpublished successfully" : "Published successfully",
        key: "contentUrl",// it will be updated when mode is publish
        payload: payload,
        // value: content?.stage === "publish" ? "unpublish" : "publish",
        content
      }
      dispatch(setShowGlobalWarningDialog(content_object));
    } catch (error: any) {
      const { response }: any = error;
      console.log('error', response);
      const { status } = response || {};
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      } else {
          dispatch(setErrorMessage("Something went wrong"));
      }
    }
  };
  
  // Delete content
  const handleDeleteContent = (content: any, header_title?: any, warning_message?: any, key?: any ) => {
    // api, content necessary data...., header_title,
    // success_message = "Folder/File deleted successfully"
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    setShowDropdown(null);
    setIsReleasedDropdown(null);
    if (!workspaceId || !clapupUserId) {
      return;
    }

    const payload: any = {
      workspaceId: workspaceId,
      clapupUserId: clapupUserId,
      contentId: content?.contentId,
      category: content?.category || category,
      cloudFlarePath: content?.cloudFlareR2Path
    };

    const content_object = {
      api_url: api_url + `/api/${api_version}/content/DeleteContent`,
      header_title: header_title || "",
      warning_message: warning_message || "",
      left_button_title: "Back",
      right_button_title: "Continue",
      success_message: header_title?.includes("Folder") ? "Folder deleted successfully" : "File deleted successfully",
      key: key,
      payload,
      content
    }
    dispatch(setShowGlobalWarningDialog(content_object));
    setShowDropdown(null);
  };
  
  // Archive content
  const handleArchiveContent = (content: any, header_title?: any, warning_message?: any, key?: any, value?: any ) => {
    // api, content necessary data...., header_title,
    // success_message = "Folder/File archived successfully"
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    setShowDropdown(null);
    setIsReleasedDropdown(null);
    if (!workspaceId || !clapupUserId) {
      return;
    }

    const payload: any = {
      workspaceId: workspaceId,
      clapupUserId: clapupUserId,
      contentId: content?.contentId,
      category: content?.category || category,
      stage: value
    };

    const content_object = {
      api_url: api_url + `/api/${api_version}/content/ChangeContentStage`,
      header_title: header_title || "",
      warning_message: warning_message || "",
      left_button_title: "Back",
      right_button_title: "Continue",
      success_message: header_title?.includes("Folder") ? "Folder archived successfully" : "File archived successfully",
      key: key,
      value: value,
      payload,
      content
    }
    dispatch(setShowGlobalWarningDialog(content_object));
    setShowDropdown(null);
  };
  
  // Move or copy content to folders of shared files, personal files or teams 
  const handleMovingOrCopyContentToFolder = (content: any, isNotMovable?: any) => {
    let folders: any[] = [...teams];
    let category_type = "";
    let team_category: any = null;
    if (category === "personal" || content?.category === "personal") {
      folders = [{ folderPath: "shared", folderName: "Shared Files" }, { folderPath: "personal", folderName: "Personal Files" }, ...teams];
      category_type = "personal";
    } else if (category === "shared" || content?.category === "shared") {
      folders = [{ folderPath: "shared", folderName: "Shared Files" }, ...teams];
      category_type = "shared";
    }

    const parent_folders = parentFolderPaths?.slice(1)?.map((item: any) => item.folderPath)?.join("/");
    const category_folder = parentFolderPaths?.length ? parentFolderPaths[0] : null;
    const team_folder = teams?.find((item: any) => item?.internalName === category_folder?.name || item?.teamName === category_folder?.name);
    if (team_folder) {
      category_type = team_folder?.internalName;
      team_category = {
        teamId: team_folder?.teamId,
        teamName: team_folder?.teamName,
        internalName: team_folder?.internalName,
      }
    }
    const parent_folder_paths = category + (parent_folders ? `-${parent_folders}` : "");
    const parent_folder_paths_check_index = category_type + (parent_folders ? `-${parent_folders}` : "");
    // const category
    console.log({ category_type, parent_folder_paths, team_category, teams, category_folder });
    folders = folders?.map((item: any) => (
      {
        ...item,
        source_path: item?.folderPath === "shared" || item?.folderPath === "personal" ? item?.folderPath : item?.internalName,
        // folderPath: item?.folderPath === "shared" || item?.folderPath === "personal" ? item?.folderPath : item?.teamId,
      }
    ));
    dispatch(setOpenGlobalModal(content));
    setShowingForMovingOrCopyContentToFolder({isNotMovable: isNotMovable, folders, content, team_category, parent_folder_paths, parent_folder_paths_check_index, category_type});
  };

  // Share content to team and assigned users
  const handleShareContent = (content: any, header_title?: any, warning_message?: any, key?: any, value?: any) => {
    // api, content necessary data...., header_title, 
    // success_message = "File archived successfully"
    const workspaceId = logged_in_user_secret_data?.wuid;
    const clapupUserId = logged_in_user_secret_data?.cuid;
    setShowDropdown(null);
    setIsReleasedDropdown(null);
    if (!workspaceId || !clapupUserId) {
      return;
    }

    const root_folder_category = folder_paths?.length ? folder_paths[0] : {};
    const folders = folder_paths?.slice(1)?.map((item: any) => item.folderPath);
    const folderPath = folders?.join("/");
    const payload: any = {
      workspaceId: workspaceId,
      clapupUserId: clapupUserId,
      contentId: content?.contentId,
      category: content?.category || category,
      isFolder: content?.isFolder ? 1 : 0,
      sharedFromTeam: root_folder_category?.internalName || root_folder_category?.internalFileName || "",
      folderPath: folderPath || "",
      accessLevel: "",
      sharedToUserList: "",
      sharedToTeamList: "",
    };
    console.log({ root_folder_category, payload, folder_paths});
    
    const content_object = {
      api_url: api_url + (header_title?.toLowerCase()?.includes("edit") ? `/api/${api_version}/content/RemoveContentSharing` : `/api/${api_version}/content/ShareContent`),
      header_title: header_title || "",
      warning_message: warning_message || "",
      left_button_title: "Back",
      right_button_title: "Continue",
      success_message: header_title?.includes("Folder") ? "Folder shared successfully" : "File shared successfully",
      content,
      key, value,
      payload,
    }
    dispatch(setOpenGlobalModal(content_object));
    setShowingContentShareForm(content_object);
    setIsReleasedDropdown(null);
    setShowDropdown(null);
  };

  // for uploading new version
  // create folder and file content
  const handleCreateContent = async (content_data: any) => {
    const { file, generate_response_data, contentPayload, content, content_path } = content_data || {};
    const { contentId, internalFileName, internalName, url: pre_assigned_url } = generate_response_data || {};
    const { folderName, isForFolder } = contentPayload || {};
    if (isForFolder) {
      const duplicate = content_data?.find((item: any) => item?.contentFileName?.toLowerCase() === folderName?.toLowerCase()?.trim());
      if (duplicate) {
        // error test
        dispatch(setUploadProgressCount(100));
        dispatch(setIsContentUploadedError(true));
        setTimeout(() => { 
          dispatch(setShowingUploadingProgress(false));
          dispatch(setUploadProgressCount(0));
        }, 3000);
        dispatch(setErrorMessage("This folder is already exist"));
        return;
      }
    }
    // const uploading_time = Date.now().toString(); // Unique identifier for this email
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        // error test
        throw "error";
      }
      
      // console.log({ content_data });
      const { name: fileName, size, type } = file || {};
      const sizeInBytes = size || 0;
      // Convert bytes to kilobytes (KB)
      const sizeInKB = sizeInBytes / 1024;
      // // Convert bytes to megabytes (MB)
      // const sizeInMB = sizeInKB / 1024;
      let fileSize = "";
      // if (sizeInMB > 15) {
      //   dispatch(setShowingUploadingProgress(false));
      //   dispatch(setIsContentUploadedError(true));
      //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      //   return;
      // }
      fileSize = sizeInKB.toFixed(2) + " KB";
      // if (sizeInMB > 1) {
      //   fileSize = sizeInMB.toFixed(2) + " MB";
      // } else {
      //   fileSize = sizeInKB.toFixed(2) + " KB";
      // }

      let file_type = type;
      const content_name = modifyString(fileName, 1, 6, ".");
      if (content_name?.includes(".sfdt")) {
        file_type = "application/json";
      }
      // console.log({contentObject, folders, folderPath, breadCrumbPaths});
      const upload_new_version_payload: any = {
        contentId: contentId,
        clapupUserId: clapupUserId,
        workspaceId: workspaceId,
        contentFileType: file_type,
        contentFileName: fileName,
        contentFileSize: fileSize,
        category: content?.category,
        presignedUrl: pre_assigned_url,
        cloudFlareR2Path: content_path,
        internalFileName: internalFileName || internalName || "",
        versionNo: content?.version || 0,
      };

      const { data: uploaded_response_data, status: uploaded_status } = await axios.post(
        `${api_url}/api/${api_version}/content/UploadNewVersion`,
        upload_new_version_payload, {
        headers: {
          'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // // Creating content
      // const { data, status } = await axios.post(
      //   `${api_url}/api/${api_version}/content/CreateConten`,
      //   contentObject, {
      //   headers: {
      //     'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
      //     'Content-Type': 'application/json'
      //   }
      // });
    
      if (uploaded_status === 200) {
        // console.log("Create Content response: ", { uploaded_response_data });
        // setTimeout(() => getFolderContents(true), 2000); // for new fetch
        // concatFolderContent(data);
        
        console.log("Uploaded Content response: ", { uploaded_response_data, uploaded_status });

        // success test
        dispatch(setUploadProgressCount(100));
        setTimeout(() => { 
          dispatch(setShowingUploadingProgress(false));
        }, 5000);
        // updateContent({content: uploaded_response_data, success_message: "Uploaded New Version"});
        updateContent({content: uploaded_response_data, success_message: ""});
        // getFolderContents();
        // if (isForFolder) {
        //   dispatch(setOpenGlobalModal(null));
        //   dispatch(setSuccessMessage("Folder Created Successfully"));
        // } else {
        //   dispatch(setSuccessMessage("Uploaded New Version"));
        // }
      }
      // return response.data; // Return response if needed
      
    } catch (error) {
      const { response }: any = error;
      console.log('error', response);
      const { status } = response || {};
      // error test
      dispatch(setUploadProgressCount(100));
      dispatch(setIsContentUploadedError(true));
      setTimeout(() => { 
        dispatch(setShowingUploadingProgress(false));
        dispatch(setUploadProgressCount(0));
      }, 3000);
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      } 
      // else {
      //   dispatch(setErrorMessage("Something went wrong"));
      // }
    }
  };

  // upload file
  const handleUploadNewVersionContent = async ( file: any, content?: any ) => {
    // const uploading_time = Date.now().toString(); // Unique identifier for this email
    try {
      const { name: fileName, type } = file;
      const duplicateCheck = content_data?.find((item: any) => item?.contentFileName?.toLowerCase() === fileName?.toLowerCase());
      if (duplicateCheck) {
        dispatch(setErrorMessage("This content is already exist"));
        return;
      }
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return;
      }
      
      // const sizeInBytes = size;
      // // Convert bytes to kilobytes (KB)
      // const sizeInKB = sizeInBytes / 1024;
      // // Convert bytes to megabytes (MB)
      // const sizeInMB = sizeInKB / 1024;
      // let fileSize = "";
      // // if (sizeInMB > 15) {
      // //   dispatch(setShowingUploadingProgress(false));
      // //   dispatch(setIsContentUploadedError(true));
      // //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      // //   return;
      // // }
      // if (sizeInMB > 1) {
      //   fileSize = sizeInMB.toFixed(2) + " MB";
      // } else {
      //   fileSize = sizeInKB.toFixed(2) + " KB";
      // }
          
      const folders = folder_paths?.slice(1)?.map((item: any) => item.folderPath);
      const folderPath = folders?.join("/");
      const root_folder_category = folder_paths?.length ? folder_paths[0] : {};
      const teamInternalName = root_folder_category?.internalFileName || root_folder_category?.internalName || "";
      const contentObject = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        teamInternalName: teamInternalName,
        contentId: content?.contentId || "", // if we want to upload new version then contentId should be there
        fileName: fileName,
        path: folderPath || "", // "root -> blank or parent-folder-1/parent-folder-2 ",
        contentFileType: type,
        mode: content?.contentId ? "update" : "new", // "new or update",
        category: content?.category || category, // "shared or personal or main(if from team)",
        version: content?.version || 0, //if file is new 0 else 1
      };
      
      // for shared-with-me it is confusing...I am not clear
      let content_path = (teamInternalName || (category === "shared" ? "sharedfiles" : category === "personal" ? "mypersonalfiles" : "shareToMe")) + (folderPath ? `/${folderPath}` : "");
      
      // console.log({contentObject, content_path});
      // return;
      // // uploading started
      dispatch(setShowingUploadingProgress(true, file));
      dispatch(setUploadProgressCount(1));
      // Uploading file for getting contentId, pre_assigned_url, internalFileName
      const content_name = modifyString(fileName, 1, 6, ".");
      if (!type && !content_name?.includes(".sfdt")) {
        throw ("Format not supported");
      }
      // Uploading file for getting contentId, pre_assigned_url, internalFileName
      const { data: generated_data, status: generated_status } = await axios.post(`${api_url}/api/${api_version}/content/GeneratePutPresignedUrl`, contentObject, {
        headers: {
          'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // console.log("File response: ", { generated_data });
      if (generated_status === 200 && generated_data?.url) {
        const {url: pre_assigned_url, internalFileName} = generated_data;
        content_path = `${content_path}/${internalFileName}`;
        // const pre_assigned_url = "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/sharedfiles/mmhk25-39ac0237-bdfd-41d3-9c2b-654bd4b77522-1.png?X-Amz-Expires=3577&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0ad77b3401369b73425d76c732dcf41e%2F20241207%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241207T053810Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=6c70609da1df07783bc977b64a6ef3a6aaf1ccd08e823515c8936dc00a7a8856";
        const { status } = await axios.put(pre_assigned_url, file, {
        // const { data, status } = await axios.put(generated_data?.url, file, {
          headers: {
            'Content-Type': `${type}`, // Set the Content-Type based on the file type
          }
        });
        // console.log({ generated_data,  data});
        if (status === 200) {
          handleCreateContent({
            file,
            generate_response_data: generated_data,
            contentPayload: contentObject,
            content,
            content_path
          });
        }
      }
      // testing purpose
      // handleCreateContent({file, generate_response_data: {contentId: "10255", internalFileName: "testing", url: "https://google.com"}, contentPayload: contentObject});
      // return response.data; // Return response if needed
      
    } catch (error) {
      const { response }: any = error;
      console.log('error', response);
      const { status } = response || {};
      // error test
      // error test
      let error_message = error === "Format not supported" ? error : true;
      dispatch(setUploadProgressCount(100));
      dispatch(setIsContentUploadedError(error_message));
      setTimeout(() => { 
        dispatch(setShowingUploadingProgress(false));
        dispatch(setUploadProgressCount(0));
      }, 3000);
      if (status === 403 || status === 401) {
          dispatch(generateAccessToken());
      }
      // else {
      //     dispatch(setErrorMessage("Something went wrong"));
      // }
    }
  };

  // update content in redux and indexDB after calling api events
  const updateContent = (data: any) => {
    const { key, value, response_data, content, success_message } = data || {};
    const updatable_content: any = { ...content };
    if (key === "stage" || key === "isLocked" || key === "isShared") {
      updatable_content[key] = value;
      if (key === "isLocked") {
        updatable_content["updatedBy"] = logged_in_user_secret_data?.cuid;
      }
    } else if (key === "contentUrl") {
      updatable_content[key] = response_data || ""; // only for contentUrl
      // updatable_content["updatedBy"] = logged_in_user_secret_data?.cuid;
    }
    
    console.log({ data, response_data });
    updateContentIntoStateAndDB({updatable_content, success_message, key, value});
    // dispatch(setShowGlobalWarningDialog(null));

    // const updated_contents = contents?.map((item: any) => {
    //   if (item?.id === content?.id) {
    //     return {
    //       ...item,
    //       [key]: value,
    //     }
    //   }
    //   return item;
    // });
    // setContents(updated_contents);
  };

  const getContentIconTitle = (content: any) => {
    if (content?.isFolder) {
      return "folder-icon";
    }
    const content_name = modifyString(content?.contentFileName, 1, 6, ".");
    if (
      content?.contentFileType?.includes("image")
      && content_name?.toLowerCase()?.includes(".svg")
    ) {
      return "content-svg-icon";
    }
    else if (content?.contentFileType?.includes("image")) {
      return "content-image-icon";
    }
    else if (content?.contentFileType?.includes("audio")) {
      return "content-audio-icon";
    } 
    else if (content?.contentFileType?.includes("video")) {
      return "content-video-icon";
    }
    else if (content_name?.toLowerCase()?.includes(".xml")) {
      return "content-xml-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".less")) {
      return "content-less-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".scss")) {
      return "content-scss-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".css")) {
      return "content-css-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".json")) {
      return "content-json-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".tar")) {
      return "content-tar-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".rar")) {
      return "content-rar-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".iso")) {
      return "content-iso-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".7z")) {
      return "content-7z-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".zip")) {
      return "content-zip-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".xls")
      || content_name?.toLowerCase()?.includes(".csv")
      || content_name?.toLowerCase()?.includes(".ods")) {
      return "content-xlsx-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".pdf")) {
      return "content-pdf-icon";
    } 
    else if (content_name?.toLowerCase()?.includes(".ppt")
      || content_name?.toLowerCase()?.includes(".odp")) {
      return "content-pptx-icon";
    } 
    else if (content_name?.includes(".rtf")) {
      return "content-rtf-icon";
    } 
    else if (content_name?.includes(".txt")) {
      return "content-text-icon";
    } 
    else if (
      content_name?.toLowerCase()?.includes(".doc")
      || content_name?.toLowerCase()?.includes(".sfdt")
      || content?.contentFileType?.includes("text")
    ) {
      return "content-docx-icon";
    } 
    else {
      return "null";
    }
  }

  const formatDate = (date: any) => {
    // const date = "12/19/2024 5:15:50AM"; // input date
    try {
      if (!date) {
        return "No date";
      }
      // Parse the input date without applying local timezone offset
      const parsedDate = moment.utc(date, "M/D/YYYY h:mm:ssA", true);
      
      // Ensure the date is parsed as local and then convert to UTC
      const result = parsedDate.local().format("hh.mm A, DD MMM, YYYY");
      // console.log({ input_date: date, result });
      return result; 
    } catch (err) {
      return "Wrong Date Format";
    }
  };

  const getFileSize = (sizeInKB: string): string => {
    try {
      const size = parseFloat(sizeInKB?.replace(" KB", "").trim());
      if (isNaN(size)) {
        throw new Error("Invalid size");
      }
    
      if (size >= 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} GB`;
      } else if (size >= 1024) {
        return `${(size / 1024).toFixed(2)} MB`;
      } else {
        return `${size.toFixed(2)} KB`;
      }
      
    } catch (err: any) {
      return "N/A"
    }
  };

  const location = useLocation();

  const handleViewContent = async (view_content_item: any) => { 
    const previous_path = location.pathname;
    const view_content: any = {
      ...view_content_item,
      previous_path,
    };
    await setKeyValue("view_content_data", view_content);
    // dispatch(setViewContentData(view_content));
    navigate(`/contents/view/${view_content?.contentId}`);
  };

  const getContentName = (content_name?: any) => {
    if (content_name?.length < 30 || !content_name?.trim()) {
      return content_name;
    }
    const result = modifyString(content_name, 23, 7, "..");
    return result;
  } 

  return (
    <React.Fragment>
      {/* All Warning Message */}
      <WarningModalForm
        showWarningDialog={showGlobalWarningDialog}
        data_fields={showGlobalWarningDialog}
        header_title={showGlobalWarningDialog?.header_title}
        warning_message={showGlobalWarningDialog?.warning_message}
        left_button_title={showGlobalWarningDialog?.left_button_title || "Back"}
        right_button_title={showGlobalWarningDialog?.right_button_title || "Continue"}
        handleCancel={() => dispatch(setShowGlobalWarningDialog(null))}
        parentCallback={(data: any) => {
          updateContent(data);
        }}
      />

      {/* share modal form  */}
      {
        showingContentShareForm
        ? <GlobalModal
            handleCancel={() => {
              dispatch(setOpenGlobalModal(null));
              setShowingContentShareForm(null);
            }}
          >
            <ContentShareForm
              data_fields={showingContentShareForm}
              handleCancel={() => {
                dispatch(setOpenGlobalModal(null));
                setShowingContentShareForm(null);
              }}
              handleParentCallback={(data: any) => updateContent(data)}
            />
          </GlobalModal>
        : null
      }

      {/* showing modal for move or copy a folder or file to selected folders  */}
      {
        showingForMovingOrCopyContentToFolder
        ? <GlobalModal
            handleCancel={async () => {
              await deleteKeyValue("all_child_folders");
              dispatch(setOpenGlobalModal(null));
              setShowingForMovingOrCopyContentToFolder(null);
            }}
          >
            <MoveOrCopyForm
              category={showingForMovingOrCopyContentToFolder?.content?.category || category}
              isOnlyForCoping={category === "sharedWithMe" || showingForMovingOrCopyContentToFolder?.isNotMovable ? true : false}
              team_category={showingForMovingOrCopyContentToFolder?.team_category}
              category_type={showingForMovingOrCopyContentToFolder?.category_type}
              parent_folder_paths_check_index={showingForMovingOrCopyContentToFolder?.parent_folder_paths_check_index}
              parent_folder_paths={showingForMovingOrCopyContentToFolder?.parent_folder_paths}
              folder_data={showingForMovingOrCopyContentToFolder?.folders}
              content={showingForMovingOrCopyContentToFolder?.content}
              parentCallback={(data: any) => { console.log({ data }) }}
            />
          </GlobalModal>
        : null
      }

      {/* Folder editor field drawer */}
      {
        showFolderEditorDrawer
        ? <GlobalDrawer 
            handleCancel={() => {
              dispatch(setOpenGlobalDrawer(null));
              setShowFolderEditorDrawer(null);
            }}
          >
            <FolderEditorFields content={showFolderEditorDrawer} parentCallback={(data: any) => {console.log({data})} } />
          </GlobalDrawer>
        : null
      }
      <>
      {/* {
        deleteFolderContentId ?
          <Dialog
            open={deleteFolderContentId}
            parentCallback={(id: any) => handleDeleteFolderContent(id)}
            classes='dialog-container-content'
            title='Delete File?'
            warning_dialog_content='If you click Yes, file will be deleted forever. This action is irreversible.'
            footer={true}
            left_btn_hover='No, Cancel'
            right_btn_hover='Yes, Delete'
          />
        : updatableContent ?
          <Dialog
            open={updatableContent}
              parentCallback={(id: any) => {
                // console.log({id});
                if (id) {
                  handleUpdateContent(updatableContent, "biopage");
                } else {
                  setUpdatableContent(null);
                }
              }}
            classes='dialog-container-content'
            title='Continue?'
            right_button_text='Yes, Continue'
            warning_dialog_content={
              !updatableContent?.addedToBioPage
              ? 'If you click Yes, image will be added to your biopage gallery section.'
              : 'If you click Yes, image will be removed from your biopage gallery section.'
            }
            footer={true}
          />
        : isStateChange ?
            <Dialog
              open={isStateChange}
              parentCallback={(content: any) => {
                console.log({isStateChange, content});
                if (content) {
                  handleStateChange(content);
                } else {
                  setIsStateChange(null);
                }
              }}
              classes='dialog-container-content'
              title='Continue?'
              right_button_text='Yes, Continue'
              warning_dialog_content={
                isStateChange?.stage === "publish"
                ? 'If you click Yes, image will become private and will not be available through a unique url.'
                : 'If you click Yes, image will become public and will be available through a unique url.'
              }
              footer={true}
            />
        : null
      } */}
      </>

      {/* <InfiniteScroll
        dataLength={contents?.length}
        // className="border"
        scrollThreshold={0.9}
        style={{ overflow: "scroll", paddingRight: 0, paddingBottom: 10 }}
        next={() => {
            console.log(
              `prev -> ${contents?.length}, next -> ${contents?.length + 20}`
            );
            fetchMoreData();
        }}
        inverse={false}
        hasMore={hasMore}
        loader={
          isLoading && contents?.length > 9 ? (
            <div className="d-flex justify-content-center align-items-center mt-1">
            <Spinner
                size="sm"
                animation="border"
                variant="primary"
            />
            </div>
          ) : null
        }
        scrollableTarget="scrollableDiv"
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        // height={"73.8vh"} // 43.5vh
        height={"50vh"} // 43.5vh
      > */}
      <div className='table-responsive'>
        <table className="table">
            <thead className="top-table">
                <tr>
                    <th>Name</th>
                    <th className='action-date-icon-container'>Added Date</th>
                    <th>Size</th>
                    <th>Created By</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {content_data?.map((content: any, idx: number) => (
                <tr key={idx}
                    onClick={() => handleShowChildContentsByFolderName(content)}
                    style={{borderBottom: idx === (content_data?.length - 1) ? "none" : "0.5px solid #C7CDD6"}}
                >
                    <td
                      // title={content?.contentFileName}
                      title={logged_in_user_secret_data?.roleName}
                    >
                      <div className='title-icon-container'>
                          <img src={getImage(getContentIconTitle(content))} alt="content-icon"/>
                          <p>{getContentName(content?.contentFileName || "Content Name")}</p>
                      </div>
                    </td>
                    <td title={content?.contentFileName} className='action-date-icon-container'>
                      <div className='action-date-container'>
                        {
                          // (content?.sharedBy === logged_in_user_secret_data?.cuid && content?.isShared) || (content?.createdBy === logged_in_user_secret_data?.cuid && content?.isShared) // pending thing when shared successfully
                          // (category !== "sharedWithMe") && (content?.updatedBy === logged_in_user_secret_data?.cuid && content?.isShared) // pending thing when shared successfully
                          (category !== "sharedWithMe") && (content?.isShared) // pending thing when shared successfully
                          ? <img
                              style={{cursor: category === "archives" ? "not-allowed" : "pointer"}}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering the row click
                                if (category === "archives") {
                                  return;
                                }
                                handleShareContent(content, "Edit the shared access from team or users", null, "isShared", null);
                              }}
                              src={getImage("content-row-share-icon")}
                              alt="content-icon" 
                            />
                          : null
                        }
                        
                        {
                          category === "archives"
                          ? null : 
                          <Fragment>
                            
                            {/* Release */}
                            {
                              content?.stage === "release"
                              ? <img
                                  src={getImage("content-row-release-icon")}
                                  alt="content-icon" 
                                />
                              : null
                            }
                            
                            {/* Publish */}
                            {
                              content?.contentUrl
                              ? <img
                                  src={getImage("content-row-publish-icon")}
                                  alt="content-icon" 
                                />
                              : null
                            }
                            
                            {/* Check-Out */}
                            {
                              content?.isLocked
                              ? <img
                                  src={getImage("content-row-checkout-icon")}
                                  alt="content-icon" 
                                />
                              : null
                            }
                          </Fragment>
                        }
                        <p>{formatDate(content?.createdOn) || "7.35 AM, 12 June, 2022"}</p>
                      </div>
                    </td>
                    <td title={content?.contentFileName}  className='size'>{content?.contentSize?.includes("KB") ? getFileSize(content?.contentSize) : (content?.contentSize || "")}</td>
                    <td title={content?.contentFileName}>{content?.createdByName || ""}</td>
                    <td onClick={(e: any) => e.stopPropagation()}>
                      <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        disabled={logged_in_user_secret_data?.roleName?.toLowerCase() === "viewer" || (category === "sharedWithMe" && content?.accessLevel !== "full") ? true : false}
                        overlay={
                          <div className='dropdown-container'>
                            {/* draft-release-archive of file */}
                            {
                              // We have to set 4 Fragments according to the docs of context menu
                              // if owner checked out a content he, super-admin & admin can do anything
                              // and for other users context menu only have share option, and they can view the file with fields as readonly
                              // content?.isLocked && 
                              category === "archives" &&
                              (content?.createdBy === logged_in_user_secret_data?.cuid
                              || logged_in_user_secret_data?.roleName?.replaceAll(" ", "")?.replaceAll("-", "")?.toLowerCase() === "superadmin")
                              // content?.updatedBy === logged_in_user_secret_data?.cuid
                              // owner & super-admin
                              ? <Fragment>
                                  {/* Share - Folder is required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      // setShowDropdown(null);
                                      handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                    <span>Share</span>
                                  </div>                     
                                  
                                  {/* Download */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
      
                                  {/* Delete - Folder is required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      // setShowDropdown(null);
                                      const header_title = !content?.isFolder ? "Delete File" : "Delete Folder";
                                      const warning_message = !content?.isFolder
                                        ? "By clicking continue, the file will be deleted. The file will be lost forever. Do you want to continue and delete the file?"
                                        : "By clicking continue, the folder will be deleted. All the files inside this folder will be lost forever. Do you want to continue and delete the folder?";
                                      handleDeleteContent(content, header_title, warning_message, "delete");
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-delete-icon`)} alt="content-icon" />
                                    <span>Delete</span>
                                  </div>
                                  
                                </Fragment>
                              : category === "archives" &&
                               (logged_in_user_secret_data?.roleName?.toLowerCase() === "admin"
                              || logged_in_user_secret_data?.roleName?.toLowerCase() === "editor"
                              || logged_in_user_secret_data?.roleName?.toLowerCase() === "member")
                              // || admin, editor & member 
                              ? <Fragment>
                                  {/* Share - Folder is required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      // setShowDropdown(null);
                                      handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                    <span>Share</span>
                                  </div>
                                  
                                  
                                  {/* Download */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
                                </Fragment>
                              
                              : content?.createdBy === logged_in_user_secret_data?.cuid 
                              || logged_in_user_secret_data?.roleName?.replaceAll(" ", "")?.replaceAll("-", "")?.toLowerCase() === "superadmin"
                              // : content?.updatedBy === logged_in_user_secret_data?.cuid
                              // owner & super-admin
                              ? <Fragment>
                                  {/* Draft, Release and Archive - only file */}
                                  {
                                    content?.isFolder // Not folder
                                    ? null
                                    : !content?.isLocked 
                                    // && content?.createdBy === logged_in_user_secret_data?.cuid
                                    ? <Dropdown
                                        trigger={['click']}
                                        placement="topRight"
                                        disabled={(category === "sharedWithMe" && content?.accessLevel !== "full") ? true : false}
                                        overlay={
                                          <div className='child-dropdown-container'>
                                            {
                                              content?.contentUrl ? null
                                              : <div className='dropdown-item'
                                                  onClick={() => {
                                                    // setIsReleasedDropdown(null);
                                                    // setShowDropdown(null);
                                                    const header_title = content?.stage === "draft" ? "Release File" : "Create Draft File";
                                                    const warning_message = content?.stage === "draft"
                                                      ? "By clicking continue, the file will be marked as released and users can use it for office purposes."
                                                      : "By clicking continue, new draft version will be created by keeping the existing version as released one.";
                                                    const key = "stage";
                                                    const value = content?.stage === "draft" ? "release" : "draft";
                                                    handleDraftReleaseContent(content, header_title, warning_message, key, value);
                                                    
                                                  }}
                                                >
                                                  <div className="child-dropdown-item">
                                                    {
                                                      content?.stage === "draft"
                                                      ? <div className='title-container'>
                                                          <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                                          <span>Release</span>
                                                        </div>
                                                      : <div className='title-container'>
                                                          <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                                          <span>Draft</span>
                                                        </div>
                                                    }
                                                  </div>
                                                </div>
                                            }
                                            
                                            {
                                              content?.stage === "draft" || category === "sharedWithMe"
                                              ? null
                                              :  <div className='dropdown-item'
                                                  onClick={async () => {
                                                    const header_title = "Archive File";
                                                    const warning_message = "By clicking continue, the file will be archived and will be moved to archive section. It will not be available here afterwards.";
                                                    handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                                  }}
                                                >
                                                  <img src={getImage("file-content-archive-icon")} alt="content-icon" />
                                                  <span>Archive</span>
                                                </div>
                                            }
                                          </div>
                                        }
                                        open={content?.contentId === isReleasedDropdown?.contentId}
                                        onOpenChange={(e: any) => {
                                          // console.log({ e });
                                          if (e) {
                                            setShowDropdown(content?.contentId);
                                            setIsReleasedDropdown(content);
                                            // setEditableContent(content);
                                          } else {
                                            setShowDropdown(null);
                                            setIsReleasedDropdown(null);
                                          }
                                        }}
                                      >
                                        <div className={`dropdown-item ${isReleasedDropdown ? "dropdown-item-active" : ""}`}>
                                          <div className="child-dropdown-item">
                                            {
                                              content?.stage === "draft"
                                              ? <div className='title-container'>
                                                  <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                                  <span>Draft</span>
                                                </div>
                                              : <div className='title-container'>
                                                    <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                                    <span>Release</span>
                                                  </div>
                                            }
                                            <img className="chevron-icon" src={getImage("file-content-child-back-icon")} alt="chevron-icon" />
                                          </div>
                                        </div>
                                      </Dropdown>
                                    : content?.stage === "release" || content?.isLocked
                                    ? <div className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          // const header_title = content?.stage === "draft" ? "Release File" : "Create Draft File";
                                          // const warning_message = content?.stage === "draft"
                                          //   ? "By clicking continue, the file will be marked as released and users can use it for office purposes."
                                          //   : "By clicking continue, new draft version will be created by keeping the existing version as released one.";
                                          // const key = "stage";
                                          // const value = content?.stage === "draft" ? "release" : "draft";
                                          // handleDraftReleaseContent(content, header_title, warning_message, key, value);
                                          return;
                                        }}
                                      >
                                        <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                        <span>Draft</span>
                                      </div>
                                    // : content?.contentUrl || content?.isLocked ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                          // const header_title = content?.stage === "draft" ? "Release File" : "Create Draft File";
                                          // const warning_message = content?.stage === "draft"
                                          //   ? "By clicking continue, the file will be marked as released and users can use it for office purposes."
                                          //   : "By clicking continue, new draft version will be created by keeping the existing version as released one.";
                                          // const key = "stage";
                                          // const value = content?.stage === "draft" ? "release" : "draft";
                                          // handleDraftReleaseContent(content, header_title, warning_message, key, value);
                                          
                                        }} 
                                      >
                                        <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                        <span>Release</span>
                                      </div>// only used for file not folder 
                                  }
                                  
                                  {/* check out/in - only file */}
                                  {
                                    content?.isFolder 
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "draft"
                                          ? !content?.isLocked
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkout", null, null, "isLocked", true);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-out-icon")} alt="content-icon" />
                                                <span>Check-Out</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkin", null, null, "isLocked", false);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-in-icon")} alt="content-icon" />
                                                <span>Check-In</span>
                                              </div>
                                          : null
                                        }
                                        
                                        
                                      </>
                                  }
                                  
                                  {/* copy & move - File & Folder are required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      setShowDropdown(null);
                                      if (content?.isFolder) { // not required in this version
                                        return;
                                      }
                                      // dispatch(setOpenGlobalModal(content));
                                      // setShowingForMovingOrCopyContentToFolder(content);
                                      handleMovingOrCopyContentToFolder(content);
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-move-or-copy-icon`)} alt="content-icon" />
                                    <span>Copy {category === "sharedWithMe" ? "" : "or Move"}</span>
                                  </div>
                                  
                                  {/* upload new version - only file */}
                                  {
                                    // content?.isFolder || category === "sharedWithMe"
                                    content?.isFolder 
                                    ? null
                                    : <>
                                        <label
                                          className='dropdown-item'
                                          htmlFor={"content-" + content?.id}
                                          onClick={() => {
                                            // console.log("Event for uploading New Version");
                                            // setEditableContent(content);
                                          }}
                                        >
                                          <img src={getImage("file-content-upload-version-icon")} alt="content-icon" />
                                          <span>Upload New Version</span>
                                        </label>
                                        <input
                                          id={"content-" + content?.id} type="file"
                                          accept={'*'}
                                          onChange={async (e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            dispatch(setShowingUploadingProgress(true));
                                            dispatch(setUploadProgressCount(1));
                                            setShowDropdown(null);
                                            try {
                                              const file = e.target.files[0];
                                              handleUploadNewVersionContent(file, content);
                                              // const { type } = file;
        
                                              // if (type?.includes("video")) {
                                              //   setNewVersionVideoFile(file);
                                              // } else {
                                              //   handleUploadNewVersionFile(file);
                                              // }
                                              // setTim
                                            } catch (err: any) {
                                              console.log({ err });
                                              dispatch(setErrorMessage("Something went wrong"));
                                            }
        
                                          }}
                                          className="d-none"
                                        />
                                        
                                      </>
                                  }
                                  
                                  {/* get url - only file */}
                                  {
                                    content?.isFolder 
                                    ? null
                                    : <>
                                        {
                                          !content?.contentUrl
                                          ? null
                                          : <div className='dropdown-item'
                                              onClick={() => {
                                                // console.log("Event for uploading New Version");
                                                if (content?.contentUrl) {
                                                  navigator.clipboard.writeText(content?.contentUrl?.replaceAll('@', "/") || "Content URL N/A");
                                                  dispatch(setSuccessMessage("URL copied to clipboard"));
                                                  setShowDropdown(null);
                                                  return;
                                                }
                                                dispatch(setErrorMessage("Content URL is unavailable"));
                                              }}
                                            >
                                              <img src={getImage("file-content-get-url-icon")} alt="content-icon" />
                                              <span>Get URL</span>
                                            </div>
                                        }
                                      </>
                                  }

                                  {/* publish/undo-publish - only file */}
                                  {
                                    // content?.isFolder || category === "sharedWithMe"
                                    content?.isFolder 
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "release"
                                          ? !content?.contentUrl
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Publish File";
                                                  const warning_message = "By clicking continue, file will be published and will be available through a unique url. Do you want to continue?";
                                                  handlePublishContent(content, "publish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-publish-icon")} alt="content-icon" />
                                                <span>Publish</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Undo Publish";
                                                  const warning_message = "By clicking continue, content will be un published and made private. URL returns 404. Do you want to continue?";
                                                  handlePublishContent(content, "undoPublish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-undo-publish-icon")} alt="content-icon" />
                                                <span>Undo Publish</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }

                                  {/* Share - File & Folder are required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      // setShowDropdown(null);
                                      handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                    <span>Share</span>
                                  </div>

                                  {/* Field Editor - File & Folder are required */}
                                  <div className='dropdown-item'
                                    onClick={() => {
                                      dispatch(setOpenGlobalDrawer(content));
                                      setShowFolderEditorDrawer(content);
                                      setShowDropdown(null);
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-field-editor-icon`)} alt="content-icon" />
                                    <span>Field Editor</span>
                                  </div>
                                  
                                  {/* Archive - Only Folder are required */}
                                  {
                                    !content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const header_title = "Archive Folder";
                                          const warning_message =  "By clicking continue, the folder will be archived. The folder and its contents will be moved to archives. Do you want to continue and archive the files?"
                                    
                                          handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                        }}
                                      >
                                        <img src={getImage("folder-content-archive-icon")} alt="content-icon" />
                                        <span>Archive</span>
                                      </div>
                                  }
                                  
                                  {/* Download - only file */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
      
                                  {/* {
                                    category === "sharedWithMe" ? null
                                    // :  content?.createdBy === logged_in_user_secret_data?.cuid && (content?.isFolder || content?.stage === "draft")
                                  } */}
                                  {/* Delete - File & Folder are required */}
                                  <div
                                    className='dropdown-item'
                                    onClick={() => {
                                      // setShowDropdown(null);
                                      const header_title = !content?.isFolder ? "Delete File" : "Delete Folder";
                                      const warning_message = !content?.isFolder
                                        ? "By clicking continue, the file will be deleted. The file will be lost forever. Do you want to continue and delete the file?"
                                        : "By clicking continue, the folder will be deleted. All the files inside this folder will be lost forever. Do you want to continue and delete the folder?";
                                      handleDeleteContent(content, header_title, warning_message, "delete");
                                    }}
                                  >
                                    <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-delete-icon`)} alt="content-icon" />
                                    <span>Delete</span>
                                  </div>
                                </Fragment>
                              
                              // admin
                              : logged_in_user_secret_data?.roleName?.replaceAll(" ", "")?.toLowerCase() === "admin"
                              ? <Fragment>
                                  {/* Draft, Release and Archive - only file */}
                                  {
                                    content?.isFolder // Not folder
                                    ? null
                                    // admin
                                    // : !content?.isLocked && content?.createdBy === logged_in_user_secret_data?.cuid
                                    : !content?.isLocked
                                    // || content?.updatedBy === logged_in_user_secret_data?.cuid)
                                    ? <Dropdown
                                        trigger={['click']}
                                        placement="topRight"
                                        disabled={(category === "sharedWithMe" && content?.accessLevel !== "full") ? true : false}
                                        overlay={
                                          <div className='child-dropdown-container'>
                                            {
                                              content?.contentUrl ? null
                                              : <div className='dropdown-item'
                                                  onClick={() => {
                                                    // setIsReleasedDropdown(null);
                                                    // setShowDropdown(null);
                                                    const header_title = content?.stage === "draft" ? "Release File" : "Create Draft File";
                                                    const warning_message = content?.stage === "draft"
                                                      ? "By clicking continue, the file will be marked as released and users can use it for office purposes."
                                                      : "By clicking continue, new draft version will be created by keeping the existing version as released one.";
                                                    const key = "stage";
                                                    const value = content?.stage === "draft" ? "release" : "draft";
                                                    handleDraftReleaseContent(content, header_title, warning_message, key, value);
                                                    
                                                  }}
                                                >
                                                  <div className="child-dropdown-item">
                                                    {
                                                      content?.stage === "draft"
                                                      ? <div className='title-container'>
                                                          <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                                          <span>Release</span>
                                                        </div>
                                                      : <div className='title-container'>
                                                          <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                                          <span>Draft</span>
                                                        </div>
                                                    }
                                                  </div>
                                                  
                                                </div>
                                            }
                                            
                                            {
                                              content?.stage === "draft" || category === "sharedWithMe"
                                              ? null
                                              :  <div className='dropdown-item'
                                                  onClick={async () => {
                                                    const header_title = "Archive File";
                                                    const warning_message = "By clicking continue, the file will be archived and will be moved to archive section. It will not be available here afterwards.";
                                                    handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                                  }}
                                                >
                                                  <img src={getImage("file-content-archive-icon")} alt="content-icon" />
                                                  <span>Archive</span>
                                                </div>
                                            }
                                          </div>
                                        }
                                        open={content?.contentId === isReleasedDropdown?.contentId}
                                        onOpenChange={(e: any) => {
                                          // console.log({ e });
                                          if (e) {
                                            setShowDropdown(content?.contentId);
                                            setIsReleasedDropdown(content);
                                            // setEditableContent(content);
                                          } else {
                                            setShowDropdown(null);
                                            setIsReleasedDropdown(null);
                                          }
                                        }}
                                      >
                                        <div className={`dropdown-item ${isReleasedDropdown ? "dropdown-item-active" : ""}`}>
                                          <div className="child-dropdown-item">
                                            {
                                              content?.stage === "draft"
                                              ? <div className='title-container'>
                                                  <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                                  <span>Draft</span>
                                                </div>
                                              : <div className='title-container'>
                                                  <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                                  <span>Release</span>
                                                </div>
                                            }
                                            <img className="chevron-icon" src={getImage("file-content-child-back-icon")} alt="content-icon" />
                                          </div>
                                        </div>
                                      </Dropdown>
                                    : content?.stage === "draft"
                                    ? <div  className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }}
                                      >
                                        <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                        <span>Draft</span>
                                      </div>
                                    // : content?.contentUrl || content?.isLocked ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }} 
                                      >
                                        <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                        <span>Release</span>
                                      </div> // only used for file not folder 
                                  }
                                  
                                  {/* check in/out - only file */}
                                  {
                                    // !content?.contentFileName?.toLowerCase()?.includes(".")
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "draft"
                                          ? !content?.isLocked
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkout", null, null, "isLocked", true);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-out-icon")} alt="content-icon" />
                                                <span>Check-Out</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkin", null, null, "isLocked", false);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-in-icon")} alt="content-icon" />
                                                <span>Check-In</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }
                                  
                                  {/* copy - File & Folder are required */}
                                  {
                                    // content?.isFolder || (content?.stage === "draft" && !content?.isLocked)
                                    // content?.isFolder ? null :
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          setShowDropdown(null);
                                          if (content?.isFolder) { // not required in this version
                                            return;
                                          }
                                          // dispatch(setOpenGlobalModal(content));
                                          // setShowingForMovingOrCopyContentToFolder(content);
                                          handleMovingOrCopyContentToFolder(content, true); // true = it is not movable
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-move-or-copy-icon`)} alt="content-icon" />
                                        <span>Copy</span>
                                        {/* <span>Copy {category === "sharedWithMe" ? "" : "or Move"}</span> */}
                                      </div>
                                  }
                                  
                                  {/* upload new version - only file */}
                                  {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) 
                                    || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        <label
                                          className='dropdown-item'
                                          htmlFor={"content-" + content?.id}
                                          onClick={() => {
                                            // console.log("Event for uploading New Version");
                                            // setEditableContent(content);
                                          }}
                                        >
                                          <img src={getImage("file-content-upload-version-icon")} alt="content-icon" />
                                          <span>Upload New Version</span>
                                        </label>
                                        <input
                                          id={"content-" + content?.id} type="file"
                                          accept={'*'}
                                          onChange={async (e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            dispatch(setShowingUploadingProgress(true));
                                            dispatch(setUploadProgressCount(1));
                                            setShowDropdown(null);
                                            try {
                                              const file = e.target.files[0];
                                              handleUploadNewVersionContent(file, content);
                                              // const { type } = file;
        
                                              // if (type?.includes("video")) {
                                              //   setNewVersionVideoFile(file);
                                              // } else {
                                              //   handleUploadNewVersionFile(file);
                                              // }
                                              // setTim
                                            } catch (err: any) {
                                              console.log({ err });
                                              dispatch(setErrorMessage("Something went wrong"));
                                            }
        
                                          }}
                                          className="d-none"
                                        />
                                      </>
                                  }
                                  
                                  {/* get url - only file */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          !content?.contentUrl || (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                          ? null
                                          : <div className='dropdown-item'
                                              onClick={() => {
                                                // console.log("Event for uploading New Version");
                                                if (content?.contentUrl) {
                                                  navigator.clipboard.writeText(content?.contentUrl?.replaceAll('@', "/") || "Content URL N/A");
                                                  dispatch(setSuccessMessage("URL copied to clipboard"));
                                                  setShowDropdown(null);
                                                  return;
                                                }
                                                dispatch(setErrorMessage("Content URL is unavailable"));
                                              }}
                                            >
                                              <img src={getImage("file-content-get-url-icon")} alt="content-icon" />
                                              <span>Get URL</span>
                                            </div>
                                        }
                                        
                                      </>
                                  }
                                  
                                  
                                  {/* publish/und-publish - only file */}
                                  {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                    || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "release"
                                          ? !content?.contentUrl
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Publish File";
                                                  const warning_message = "By clicking continue, file will be published and will be available through a unique url. Do you want to continue?";
                                                  handlePublishContent(content, "publish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-publish-icon")} alt="content-icon" />
                                                <span>Publish</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Undo Publish";
                                                  const warning_message = "By clicking continue, content will be un published and made private. URL returns 404. Do you want to continue?";
                                                  handlePublishContent(content, "undoPublish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-undo-publish-icon")} alt="content-icon" />
                                                <span>Undo Publish</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }
                                  
                                  {/* share - File & Folder are required  */}
                                  {
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          // setShowDropdown(null);
                                          handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                        <span>Share</span>
                                      </div>
                                  }
                                  
                                  {/* field editor - File & Folder are required */}
                                  {
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          dispatch(setOpenGlobalDrawer(content));
                                          setShowFolderEditorDrawer(content);
                                          setShowDropdown(null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-field-editor-icon`)} alt="content-icon" />
                                        <span>Field Editor</span>
                                      </div>
                                  }
                                  
                                  {/* Archive - Folder is required */}
                                  {
                                    !content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const header_title = "Archive Folder";
                                          const warning_message =  "By clicking continue, the folder will be archived. The folder and its contents will be moved to archives. Do you want to continue and archive the files?"
                                    
                                          handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                        }}
                                      >
                                        <img src={getImage("folder-content-archive-icon")} alt="content-icon" />
                                        <span>Archive</span>
                                      </div>
                                  }
                                  
                                  {/* Download - only file */}
                                  {
                                    content?.isFolder ? null
                                    : !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
                                </Fragment>
                              
                              // editor
                              : logged_in_user_secret_data?.roleName?.replaceAll(" ", "")?.toLowerCase() === "editor"
                              ? <Fragment>
                                  {/* Draft, Release and Archive - only file */}
                                  {
                                    content?.isFolder // Not folder
                                    ? null
                                    : content?.stage === "draft"
                                    ? <div  className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }}
                                      >
                                        <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                        <span>Draft</span>
                                      </div>
                                    // : content?.contentUrl || content?.isLocked ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }} 
                                      >
                                        <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                        <span>Release</span>
                                      </div> // only used for file not folder 
                                  }
                                  
                                  {/* check in/out - only file */}
                                  {
                                    // !content?.contentFileName?.toLowerCase()?.includes(".")
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "draft"
                                          ? !content?.isLocked
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkout", null, null, "isLocked", true);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-out-icon")} alt="content-icon" />
                                                <span>Check-Out</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkin", null, null, "isLocked", false);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-in-icon")} alt="content-icon" />
                                                <span>Check-In</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }
                                  
                                  {/* copy - File & Folder are required */}
                                  {
                                    // content?.isFolder || (content?.stage === "draft" && !content?.isLocked)
                                    // content?.isFolder ? null :
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          setShowDropdown(null);
                                          if (content?.isFolder) { // not required in this version
                                            return;
                                          }
                                          // dispatch(setOpenGlobalModal(content));
                                          // setShowingForMovingOrCopyContentToFolder(content);
                                          handleMovingOrCopyContentToFolder(content, true); // true = it is not movable
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-move-or-copy-icon`)} alt="content-icon" />
                                        <span>Copy</span>
                                        {/* <span>Copy {category === "sharedWithMe" ? "" : "or Move"}</span> */}
                                      </div>
                                  }
                                  
                                  {/* upload new version - only file */}
                                  {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) 
                                    || content?.stage === "release" || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        <label
                                          className='dropdown-item'
                                          htmlFor={"content-" + content?.id}
                                          onClick={() => {
                                            // console.log("Event for uploading New Version");
                                            // setEditableContent(content);
                                          }}
                                        >
                                          <img src={getImage("file-content-upload-version-icon")} alt="content-icon" />
                                          <span>Upload New Version</span>
                                        </label>
                                        <input
                                          id={"content-" + content?.id} type="file"
                                          accept={'*'}
                                          onChange={async (e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            dispatch(setShowingUploadingProgress(true));
                                            dispatch(setUploadProgressCount(1));
                                            setShowDropdown(null);
                                            try {
                                              const file = e.target.files[0];
                                              handleUploadNewVersionContent(file, content);
                                              // const { type } = file;
        
                                              // if (type?.includes("video")) {
                                              //   setNewVersionVideoFile(file);
                                              // } else {
                                              //   handleUploadNewVersionFile(file);
                                              // }
                                              // setTim
                                            } catch (err: any) {
                                              console.log({ err });
                                              dispatch(setErrorMessage("Something went wrong"));
                                            }
        
                                          }}
                                          className="d-none"
                                        />
                                      </>
                                  }
                                  
                                  {/* get url - only file */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          !content?.contentUrl || (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                          ? null
                                          : <div className='dropdown-item'
                                              onClick={() => {
                                                // console.log("Event for uploading New Version");
                                                if (content?.contentUrl) {
                                                  navigator.clipboard.writeText(content?.contentUrl?.replaceAll('@', "/") || "Content URL N/A");
                                                  dispatch(setSuccessMessage("URL copied to clipboard"));
                                                  setShowDropdown(null);
                                                  return;
                                                }
                                                dispatch(setErrorMessage("Content URL is unavailable"));
                                              }}
                                            >
                                              <img src={getImage("file-content-get-url-icon")} alt="content-icon" />
                                              <span>Get URL</span>
                                            </div>
                                        }
                                        
                                      </>
                                  }
                                  
                                  
                                  {/* publish/und-publish - only file */}
                                  {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                    || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "release"
                                          ? !content?.contentUrl
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Publish File";
                                                  const warning_message = "By clicking continue, file will be published and will be available through a unique url. Do you want to continue?";
                                                  handlePublishContent(content, "publish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-publish-icon")} alt="content-icon" />
                                                <span>Publish</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Undo Publish";
                                                  const warning_message = "By clicking continue, content will be un published and made private. URL returns 404. Do you want to continue?";
                                                  handlePublishContent(content, "undoPublish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-undo-publish-icon")} alt="content-icon" />
                                                <span>Undo Publish</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }
                                  
                                  {/* share - File & Folder are required  */}
                                  {
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          // setShowDropdown(null);
                                          handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                        <span>Share</span>
                                      </div>
                                  }
                                  
                                  {/* field editor - File & Folder are required */}
                                  {
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          dispatch(setOpenGlobalDrawer(content));
                                          setShowFolderEditorDrawer(content);
                                          setShowDropdown(null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-field-editor-icon`)} alt="content-icon" />
                                        <span>Field Editor</span>
                                      </div>
                                  }
                                  
                                  {/* Archive - Folder is required */}
                                  {
                                    !content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const header_title = "Archive Folder";
                                          const warning_message =  "By clicking continue, the folder will be archived. The folder and its contents will be moved to archives. Do you want to continue and archive the files?"
                                    
                                          handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                        }}
                                      >
                                        <img src={getImage("folder-content-archive-icon")} alt="content-icon" />
                                        <span>Archive</span>
                                      </div>
                                  }
                                  
                                  {/* Download - only file */}
                                  {
                                    content?.isFolder ? null
                                    : !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
                                </Fragment>
                              
                              // member
                              : logged_in_user_secret_data?.roleName?.replaceAll(" ", "")?.toLowerCase() === "member"
                              ? <Fragment>
                                  {/* Draft, Release and Archive - only file */}
                                  {
                                    content?.isFolder // Not folder
                                    ? null
                                    : content?.stage === "draft"
                                    ? <div  className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }}
                                      >
                                        <img src={getImage("file-content-draft-icon")} alt="content-icon" />
                                        <span>Draft</span>
                                      </div>
                                    // : content?.contentUrl || content?.isLocked ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          // setIsReleasedDropdown(null);
                                          // setShowDropdown(null);
                                          return;
                                        }} 
                                      >
                                        <img src={getImage("file-content-release-icon")} alt="content-icon" />
                                        <span>Release</span>
                                      </div> // only used for file not folder 
                                  }
                                  
                                  {/* check in/out - only file */}
                                  {
                                    // !content?.contentFileName?.toLowerCase()?.includes(".")
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "draft"
                                          ? !content?.isLocked
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkout", null, null, "isLocked", true);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-out-icon")} alt="content-icon" />
                                                <span>Check-Out</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // console.log("Event for uploading New Version");
                                                  // setShowDropdown(null);
                                                  handleCheckInAndOut(content, "checkin", null, null, "isLocked", false);
                                                }}
                                              >
                                                <img src={getImage("file-content-check-in-icon")} alt="content-icon" />
                                                <span>Check-In</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  }
                                  
                                  {/* copy - File & Folder are required */}
                                  {
                                    // content?.isFolder || (content?.stage === "draft" && !content?.isLocked)
                                    // content?.isFolder ? null :
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          setShowDropdown(null);
                                          if (content?.isFolder) { // not required in this version
                                            return;
                                          }
                                          // dispatch(setOpenGlobalModal(content));
                                          // setShowingForMovingOrCopyContentToFolder(content);
                                          handleMovingOrCopyContentToFolder(content, true); // true = it is not movable
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-move-or-copy-icon`)} alt="content-icon" />
                                        <span>Copy</span>
                                        {/* <span>Copy {category === "sharedWithMe" ? "" : "or Move"}</span> */}
                                      </div>
                                  }
                                  
                                  {/* upload new version - only file */}
                                  {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) 
                                    || content?.stage === "release" || content?.contentUrl || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        <label
                                          className='dropdown-item'
                                          htmlFor={"content-" + content?.id}
                                          onClick={() => {
                                            // console.log("Event for uploading New Version");
                                            // setEditableContent(content);
                                          }}
                                        >
                                          <img src={getImage("file-content-upload-version-icon")} alt="content-icon" />
                                          <span>Upload New Version</span>
                                        </label>
                                        <input
                                          id={"content-" + content?.id} type="file"
                                          accept={'*'}
                                          onChange={async (e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            dispatch(setShowingUploadingProgress(true));
                                            dispatch(setUploadProgressCount(1));
                                            setShowDropdown(null);
                                            try {
                                              const file = e.target.files[0];
                                              handleUploadNewVersionContent(file, content);
                                              // const { type } = file;
        
                                              // if (type?.includes("video")) {
                                              //   setNewVersionVideoFile(file);
                                              // } else {
                                              //   handleUploadNewVersionFile(file);
                                              // }
                                              // setTim
                                            } catch (err: any) {
                                              console.log({ err });
                                              dispatch(setErrorMessage("Something went wrong"));
                                            }
        
                                          }}
                                          className="d-none"
                                        />
                                      </>
                                  }
                                  
                                  {/* get url - only file */}
                                  {
                                    content?.isFolder
                                    ? null
                                    : <>
                                        {
                                          !content?.contentUrl || (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                          ? null
                                          : <div className='dropdown-item'
                                              onClick={() => {
                                                // console.log("Event for uploading New Version");
                                                if (content?.contentUrl) {
                                                  navigator.clipboard.writeText(content?.contentUrl?.replaceAll('@', "/") || "Content URL N/A");
                                                  dispatch(setSuccessMessage("URL copied to clipboard"));
                                                  setShowDropdown(null);
                                                  return;
                                                }
                                                dispatch(setErrorMessage("Content URL is unavailable"));
                                              }}
                                            >
                                              <img src={getImage("file-content-get-url-icon")} alt="content-icon" />
                                              <span>Get URL</span>
                                            </div>
                                        }
                                        
                                      </>
                                  }
                                  
                                  
                                  {/* publish/und-publish - only file */}
                                  {/* {
                                    (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid)
                                    || content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <>
                                        {
                                          content?.stage === "release"
                                          ? !content?.contentUrl
                                            ? <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Publish File";
                                                  const warning_message = "By clicking continue, file will be published and will be available through a unique url. Do you want to continue?";
                                                  handlePublishContent(content, "publish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-publish-icon")} alt="content-icon" />
                                                <span>Publish</span>
                                              </div>
                                            : <div className='dropdown-item'
                                                onClick={() => {
                                                  // setShowDropdown(null);
                                                  const header_title = "Undo Publish";
                                                  const warning_message = "By clicking continue, content will be un published and made private. URL returns 404. Do you want to continue?";
                                                  handlePublishContent(content, "undoPublish", header_title, warning_message);
                                                }}
                                              >
                                                <img src={getImage("file-content-undo-publish-icon")} alt="content-icon" />
                                                <span>Undo Publish</span>
                                              </div>
                                          : null
                                        }
                                      </>
                                  } */}
                                  
                                  {/* share - File & Folder are required  */}
                                  {
                                    !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div
                                        className='dropdown-item'
                                        onClick={() => {
                                          // setShowDropdown(null);
                                          handleShareContent(content, "Share the file with team or users", null, "isShared", null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-share-icon`)} alt="content-icon" />
                                        <span>Share</span>
                                      </div>
                                  }
                                  
                                  {/* field editor - File & Folder are required */}
                                  {
                                   (!content?.isFolder && content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) || content?.stage === "draft" ? null
                                    : <div className='dropdown-item'
                                        onClick={() => {
                                          dispatch(setOpenGlobalDrawer(content));
                                          setShowFolderEditorDrawer(content);
                                          setShowDropdown(null);
                                        }}
                                      >
                                        <img src={getImage(`${content?.isFolder ? "folder" : "file"}-content-field-editor-icon`)} alt="content-icon" />
                                        <span>Field Editor</span>
                                      </div>
                                  }
                                  
                                  {/* Archive - Folder is required */}
                                  {
                                    !content?.isFolder || category === "sharedWithMe"
                                    ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const header_title = "Archive Folder";
                                          const warning_message =  "By clicking continue, the folder will be archived. The folder and its contents will be moved to archives. Do you want to continue and archive the files?"
                                    
                                          handleArchiveContent(content, header_title, warning_message, "stage", "archive");
                                        }}
                                      >
                                        <img src={getImage("folder-content-archive-icon")} alt="content-icon" />
                                        <span>Archive</span>
                                      </div>
                                  }
                                  
                                  {/* Download - only file */}
                                  {
                                    content?.isFolder ? null
                                    : !content?.isFolder && (content?.isLocked && content?.updatedBy !== logged_in_user_secret_data?.cuid) ? null
                                    : <div className='dropdown-item'
                                        onClick={async () => {
                                          const contentUrl = await download2Content(content);
                                          console.log({ contentUrl });
                                          if (contentUrl) {
                                            setShowDropdown(null);
                                          } else {
                                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                          }
                                        }}
                                      >
                                        <img src={getImage("file-content-download-icon")} alt="content-icon" />
                                        <span>Download</span>
                                      </div>
                                  }
                                </Fragment>
                              
                              : null
                            }
                          </div>
                        }
                        open={content?.contentId === showDropdown}
                        onOpenChange={(e: any) => {
                          // console.log({ e });
                          if (e) {
                            setShowDropdown(content?.contentId);
                            // setEditableContent(content);
                          } else {
                            setShowDropdown(null);
                            // setEditableContent(null);
                          }
                        }}
                      >
                        <img
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents triggering the row click
                            if (logged_in_user_secret_data?.roleName?.toLowerCase() === "viewer" || (category === "sharedWithMe" && content?.accessLevel !== "full")) {
                              return;
                            }
                            setShowDropdown(content?.contentId);
                          }}
                          style={{cursor: logged_in_user_secret_data?.roleName?.toLowerCase() === "viewer" || (category === "sharedWithMe" && content?.accessLevel !== "full") ? "not-allowed" : "pointer"}}
                          src={getImage("more-action")}
                          alt="more-action"
                        />
                      </Dropdown>
                    </td>
                </tr>
                ))}    
            </tbody>
        </table>
      </div> 
      {/* </InfiniteScroll> */}
      
    </React.Fragment>
  )
}

export default ParentContents;

