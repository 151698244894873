import React from 'react';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import prev from '../../../../assets/icons/prev-box.svg';
// import next from '../../../../assets/icons/next-box.svg';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// import { setBoxId } from '../../../../unified/reducer';
// import Button from '../../../CustomButton/ButtonContainer';
// import { getGenerateApiImageByName } from '../../../../unified/image_service';
import './AllFeedCard.scss';
import AllFeedCard from './AllFeedCard';

const FeedbackCard = (props: any) => {
  const { workspaceSettings } = useSelector(
    (state: any) => state?.workspaceManagementState
  );
  const { inbox } = props;
  // const { getImage, handleAddToWall, wallUnifiedData, query_string, inbox, type, activePage, last, isFromModalBox  } = props;
  // const dispatch = useDispatch();

  const {
    reviewId, SlNo,
    reviewerPhoteUrl,
    reviewerDisplayName,
    starRating,
    provider, reviewComment,
    createdOn,
  }: any = inbox || {};
  
  return (
    <React.Fragment>
      <AllFeedCard
        {...props}
        card_data={
          {
            reviewId,
            SlNo,
            profile_image: reviewerPhoteUrl,
            username: reviewerDisplayName,
            star_ratings: starRating,
            provider,
            comment: reviewComment,
            date: createdOn,
          }
        }
        tag_element={<div className="recommend-title">reviewed {workspaceSettings?.workspaceName}</div>}
      />

      {/* <div className={`feeds-card-header ${!query_string?.includes("wall") ? "feed-card-header-conversation" : ""} d-flex justify-content-between align-items-start`}>
        {
          inbox?.reviewerPhoteUrl || inbox?.imageUrl
          ? <img
              className="user-avatar"
              src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
              alt="avatar"
            />
            :
            <img
              className="user-avatar"
              src={getGenerateApiImageByName(inbox?.reviewerDisplayName)}
              alt="avatar"
            />
        }
        <div
          className="feeds-card-header-content d-flex justify-content-between align-items-start"
          style={{ width: "100%" }}
        >
          <div>
            <div className="name-rating d-flex m-0 align-items-center">
              <h6 className="user-name">
                {inbox?.reviewerDisplayName?.substr(0, 12) || "Client Name"}
              </h6>
              <div className="my-0 ms-2">
                {
                  inbox?.ratingStars ? (
                    <span className="d-flex align-items-center">
                      <span className="d-flex rating align-items-start">
                        {Array.from(
                          Array(Number(inbox?.ratingStars)).keys()
                        ).map((item: any, index: number) => {
                          return (
                            <img
                              style={{ marginRight: 4 }}
                              key={index}
                              src={start}
                              alt="star"
                            />
                          );
                        })}
                      </span>
                      <span className="rating-value">
                        {Number(inbox?.ratingStars)}/5
                      </span>
                    </span>
                  ) : null
                }
              </div>
            </div>
            <p className="text-start date">
              {
                // new Date(inbox?.createdOn).toDateString() + ' '
                // + (new Date(inbox?.createdOn).getHours() < 10 ? '0' + new Date(inbox?.createdOn).getHours() : new Date(inbox?.createdOn).getHours())
                // + ':' + (new Date(inbox?.createdOn).getMinutes() < 10 ? '0' + new Date(inbox?.createdOn).getMinutes() : new Date(inbox?.createdOn).getMinutes())
                // + ':' + (new Date(inbox?.createdOn).getSeconds() < 10 ? '0' + new Date(inbox?.createdOn).getSeconds() : new Date(inbox?.createdOn).getSeconds())
                moment(inbox?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
              }
            </p>
            {
              isFromModalBox
              ? <p
                  className="description mb-2"
                >
                  {inbox?.reviewComment}
                </p>
              : <>
                <p
                  className="text-start description mb-2 normal-description"
                  onClick={() => {
                    if (activePage !== "conversations") {
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                  style={{cursor: 'pointer'}}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
                <p
                  className="text-start description mb-2 lg-description"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (activePage !== "conversations"){
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
              </>
            }
          </div>

          <img
            className='provider-icon'
            src={getImage(inbox?.provider)}
            alt="provider"
          />
        </div>
      </div>
      {
        query_string?.includes("wall")
          ?
          <div className="feeds-card-footer w-100">
            {
              isFromModalBox
                ? <div className='prev-next-add-wall-container'>
                  <div className='prev-next-container'>
                    <div className='prev-next'
                      onClick={() => {
                        const prevIndex = inbox?.SlNo - 1;
                        console.log({ wallBoxId: prevIndex });
                        
                        if (prevIndex > 0) {
                          dispatch(setBoxId(prevIndex));
                        }
                      }}
                    >
                      <img className='prev' src={prev} alt="prev" />
                    </div>
                    <div className='prev-next'
                      onClick={() => {
                        const nextIndex = inbox?.SlNo + 1;
                        console.log({ wallBoxId: nextIndex });
                        
                        if (nextIndex <= last) {
                          dispatch(setBoxId(nextIndex));
                        }
                      }}
                    >
                      <img className='next' src={next} alt="next" />
                    </div>
                  </div>
                  <Button
                    classes='add-to-wall-btn'
                    onClick={() => {
                      if (!wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId) {
                        handleAddToWall(inbox);
                        // dispatch(setBoxId(null));
                      }
                    }}
                    disabled={wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId}
                  >
                    {wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId ? "Added" : "Add to Wall"}
                  </Button>
                </div>
                : <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex align-items-center">
                    {inbox?.recieverCompany ? (
                      <span className="d-block client">{inbox?.recieverCompany}</span>
                    ) : null}
                  </div>
                  <button
                    className={`${(wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId) && activePage !== "conversations"
                        ? ""
                        : query_string?.includes("wall")) &&
                      "add-to-wall-btn-hover"
                      } add-to-wall-btn`}
                    type="button"
                    disabled={wallUnifiedData?.find(
                      (wd: any) =>
                        wd?.reviewId === inbox?.reviewId &&
                        activePage !== "conversations"
                    )}
                    onClick={() => {
                      if (activePage !== "conversations") {
                        handleAddToWall(inbox);
                      }
                    }}
                  >
                    {wallUnifiedData?.find(
                      (wd: any) => wd?.reviewId === inbox?.reviewId
                    ) && activePage !== "conversations"
                      ? "Added"
                      : "Add to Wall"}
                  </button>
                </div>
            }
          
          </div>
          : null
      } */}
    </React.Fragment>
  )
};

export default FeedbackCard;
