import React, {useEffect, useState} from 'react';
import './MessagesReviewSummary.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';
// import moment from 'moment';
// import { convertISODate } from '../../../../../utils/convertingFunction';
import { DebounceInput } from 'react-debounce-input';
import { Dropdown } from 'antd';
import { useSelector, useDispatch, } from 'react-redux';
import axios from 'axios';
import { setErrorMessage, setSuccessMessage, assignUserIdForInboxEmailByMessageId } from '../../../../../unified/reducer';
import { generateAccessToken } from '../../../../../profile/reducer';
import { api_version, inbox_api_url } from '../../../../../utils/server';

const MessagesSummaryList = (props: any) => {
    const dispatch = useDispatch();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const route_path = window.location.pathname;
    const { list_data, main_data: conversation, details_assignee_users, disable_email, disable_telephone, disable_tag, disable_assignee, type }: any = props || {};
    const {
        provider,
        telephone,
        tags: current_tags,//conversation tags
        email,
        ticketNo,
        channelId: channel,
        chatStatus,
    }: any = list_data || {};
    const [phoneNumber, setPhoneNumber] = useState<any>(telephone);
    const [contactEmail, setContactEmail] = useState<any>(email);
    // tag management
    const { menuTags: tag_labels } = useSelector((state: any) => state?.workspaceManagementState);
    const [tags, setTags] = useState<any>(tag_labels || []);
    const [tag_data, setTagData] = useState<any>(null);
    const [isOpenTagDropdown, setIsOpenTagDropdown] = useState<any>("");

    const handleSetTagLabelForDetails = async (tag: any) => {
        try {
            // console.log({ tag });
            // const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!workspaceId) {
                return;
            }
            const data_object = {
                workspaceId,
                messageId: conversation?.messageId,
                teamName: tag?.teamName,
            }
            const url = inbox_api_url + `/api/${api_version}/teams/AssignTag`;
            const { status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                setIsOpenTagDropdown(false);
                setTagData({tagColor: tag?.tagColor, tagName: tag?.tagName});
                dispatch(setSuccessMessage("Saved Successfully"));
            }

        } catch (error) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
                setIsOpenTagDropdown(false);
            } else {
                setIsOpenTagDropdown(false);
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };
    const getTagIconByHexCode = (hex_code?: any) =>{
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="15.99998664855957" height="16" viewBox="0 0 15.99998664855957 16"><g><path d="M0,1.75198C0,1.75198,0,7.22003,0,7.22003C0,7.84182,0.245056,8.438,0.683963,8.87691C0.683963,8.87691,7.12126,15.3142,7.12126,15.3142C8.03565,16.2286,9.51697,16.2286,10.4314,15.3142C10.4314,15.3142,15.3142,10.4314,15.3142,10.4314C16.2286,9.51698,16.2286,8.03567,15.3142,7.12128C15.3142,7.12128,8.87689,0.683976,8.87689,0.683976C8.43799,0.245069,7.8418,0.0000127074,7.22002,0.0000127074C7.22002,0.0000127074,1.75563,0.0000127074,1.75563,0.0000127074C0.786375,-0.00364485,0,0.78273,0,1.75198ZM4.09646,2.9224C4.40688,2.9224,4.70458,3.04571,4.92408,3.26521C5.14357,3.48471,5.26688,3.78241,5.26688,4.09282C5.26688,4.40323,5.14357,4.70093,4.92408,4.92043C4.70458,5.13993,4.40688,5.26324,4.09646,5.26324C3.78605,5.26324,3.48835,5.13993,3.26885,4.92043C3.04936,4.70093,2.92605,4.40323,2.92605,4.09282C2.92605,3.78241,3.04936,3.48471,3.26885,3.26521C3.48835,3.04571,3.78605,2.9224,4.09646,2.9224Z" fill={hex_code || "#1354EC"} fillOpacity="1" /></g></svg>
        )
    }

    // const tags = [
    //     {
    //         label: "#12B76A",
    //         value: "Billing",
    //     },
    //     {
    //         label: "#0F43BD",
    //         value: "Training Request",
    //     },
    //     {
    //         label: "#F79009",
    //         value: "Payment",
    //     },
    //     {
    //         label: "#F04438",
    //         value: "Onboarding Issues",
    //     },
    // ];

    const handleSearchTags = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        const query_data = tag_labels;
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setTags(data);
            
        } else{
            const data = query_data?.filter((item: any) => {
                return item?.tagName?.toLowerCase().includes(value?.toLowerCase());
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                setTags(data);
            }else{
                setTags(data);
            }
        }
    }

    const tag_menu = (
        <div className='message-bot-menu-container'>
            <div className="search-container">
                {/* <img className='search-icon' src={getImage("search-icon")} alt="search" /> */}
                <DebounceInput
                    // value={searchInput}
                    // style={{height: 58}}
                    type="text" 
                    placeholder={"Search Labels"}
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(e: any) => handleSearchTags(e.target.value)} 
                />
            </div>
            <div className="menu-items scroll-content">
                {
                    tags?.map((tag: any) => (
                        <div className="item" key={tag?.tagId}
                            onClick={() => {
                                handleSetTagLabelForDetails(tag);
                            }}
                        >
                            {
                                getTagIconByHexCode(tag?.tagColor)
                            }
                            <div className='item-title'>{ tag?.tagName }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
    
    // assignee management

    const [users, setUsers] = useState<any>(null);
    const [assignee_user, setAssigneeUser] = useState<any>(null);
    const [isOpenAssigneeUserDropdown, setIsOpenAssigneeUserDropdown] = useState<any>("");
    // const assignee_users = [
    //     {
    //         id: "#12B76A",
    //         username: "Mehedi Hasan",
    //     },
    //     {
    //         id: "#0F43BD",
    //         username: "Kuber Mannur",
    //     },
    //     {
    //         id: "#F79009",
    //         username: "Sreekanth PM",
    //     },
    //     {
    //         id: "#F04438",
    //         username: "MMHK",
    //     },
    // ];
    const handleSearchAssigneeUser = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        const query_data = details_assignee_users;
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setUsers(data);
            
        } else{
            const data = query_data?.filter((item: any) => {
                return item?.userName?.toLowerCase().includes(value?.toLowerCase());
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                setUsers(data);
            }else{
                setUsers(data);
            }
        }
    }

    const handleAssignedUserForDetails = async (assigned_user: any) => {
        try {
            console.log({ assigned_user, conversation });
            // return;
            const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!clapupUserId || !workspaceId) {
                return;
            }
            const data_object = {
                clapupUserId, workspaceId,
                assigningUserId: assigned_user?.userId,
                ticketNo: ticketNo,
                teamId: conversation?.teamId,
                channelId: conversation?.channelId,
            }
            const url = inbox_api_url + `/api/${api_version}/teams/AssignEmailToUser`;
            const { status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                setIsOpenAssigneeUserDropdown(false);
                setAssigneeUser(assigned_user);
                dispatch(assignUserIdForInboxEmailByMessageId(conversation, assigned_user?.userId, route_path));
                dispatch(setSuccessMessage("Assigned Successfully"));
            }

        } catch (error) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
                setIsOpenAssigneeUserDropdown(false);
            } else {
                setIsOpenAssigneeUserDropdown(false);
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };
    
    const assignee_menu = (
        <div className='message-bot-menu-container'>
            <div className="search-container">
                {/* <img className='search-icon' src={getImage("search-icon")} alt="search" /> */}
                <DebounceInput
                    // value={searchInput}
                    // style={{height: 58}}
                    type="text" 
                    placeholder={"Search Users"}
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(e: any) => handleSearchAssigneeUser(e.target.value)} 
                />
            </div>
            <div className="menu-items scroll-content">
                {
                    users?.map((au: any) => (
                        <div key={au?.userId} className={`item ${au?.userId === conversation?.logged_in_user_id ? "d-none" : ""}`}
                            onClick={() => {
                                handleAssignedUserForDetails(au);
                                // setAssigneeUser(au);
                                // setIsOpenAssigneeUserDropdown(false);
                            }}
                        >
                            <img src={getGenerateApiImageByName(au?.userName)} alt="assignee-name" />
                            <div className='item-title'>{ au?.userName }</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

    useEffect(() => { 
        console.log({ list_data, type, conversation, details_assignee_users });
        if (conversation?.messageStatus === "open") {
            const default_assigned_user = {
                userId: conversation?.logged_in_user_id,
                userEmail: conversation?.logged_in_user_email,
                userName: conversation?.logged_in_user_name,
            }
            setAssigneeUser(default_assigned_user);
        }else if (conversation?.assignedUserId) {
            const assigned_usr = details_assignee_users?.find((user: any) => user?.userId === conversation?.assignedUserId);
            setAssigneeUser(assigned_usr);
        } else {
            setAssigneeUser(null);
        }
        setUsers(details_assignee_users);
        setTags(tag_labels);
        // setTagData({tagColor: null, tagName: conversation?.tags || null});
    }, [conversation?.messageId, conversation?.ticket_serial_no, conversation?.ticket_no]);
    return (
        <React.Fragment>
            <div className="review-table">
                <div className="table-row">
                    <div className="label">Email:</div>
                    <div className="value">
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            readOnly={disable_email ? true : false}
                            style={{ background: disable_email ? "#F0F2F4" : "#FFFFFF" }}
                            title={contactEmail || email || ""}
                            value={contactEmail || email || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                console.log({ value });
                                setContactEmail(value);
                            }}
                            type="text"
                            placeholder={"Enter email"}
                        />
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Telephone:</div>
                    <div className="value">
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            readOnly={provider === "whatsapp" || disable_telephone ? true : false}
                            style={{ background: provider === "whatsapp" || disable_telephone ? "#F0F2F4" : "#FFFFFF" }}
                            value={phoneNumber}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                console.log({ value });
                                setPhoneNumber(value);
                            }}
                            type="text"
                            placeholder={"Enter phone number"}
                        />
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">
                        Tag:
                    </div>
                    <div className="value">
                        <Dropdown 
                            onOpenChange={() => {setIsOpenTagDropdown(!isOpenTagDropdown);}}
                            open={isOpenTagDropdown}
                            trigger={['click']} 
                            overlay={tag_menu}
                            disabled={disable_tag}
                            placement="bottom"
                        >
                            <div className="value-details">
                                {/* <img src={getImage("primary-icon")} alt="provider-icon" /> */}
                                {getTagIconByHexCode(tag_data?.tagColor)}
                                <div>{tag_data?.tagName || "No Tags"}</div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Ticket No:</div>
                    <div className="value">
                        <div className="value-details"># {ticketNo || "N/A"}</div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Channel:</div>
                    <div className="value">
                        <div className="value-details">
                            <img src={getImage(provider)} alt="provider-icon" />
                            {/* Have to show channel details email id  */}
                            <div style={{textTransform: 'capitalize'}}>{provider || (channel ? (channel?.length < 20 ? channel : (channel?.substr(0, 17) + "..."))  : "N/A")}</div> 
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Assigned:</div>
                    <div className="value">
                        <Dropdown 
                            onOpenChange={() => {setIsOpenAssigneeUserDropdown(!isOpenAssigneeUserDropdown);}}
                            open={isOpenAssigneeUserDropdown}
                            trigger={['click']}
                            disabled={disable_assignee}
                            overlay={assignee_menu} 
                            placement="bottom"
                        >
                            <div title={assignee_user?.userName} className="value-details">
                                {
                                    assignee_user?.userName 
                                    ? <img src={getGenerateApiImageByName(assignee_user?.userName)} alt="assignee-name" />
                                    : <img src={getImage("opened-status")} alt="assignee-status" />
                                }
                                <div>{assignee_user?.userName || "Unassigned"}</div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Status:</div>
                    <div className="value">
                        <div className="value-details">
                            {
                              chatStatus === "closed"
                              ? <img src={getImage("closed-status")} alt="status-icon"/>
                              : chatStatus === "dispatched"
                              ? <img src={getImage("dispatched-status")} alt="status-icon"/>
                              : chatStatus === "toDispatched"
                              ? <img src={getImage("to-dispatched-status")} alt="status-icon"/>
                              : chatStatus === "inProgress"
                              ? <img src={getImage("in-progressed-status")} alt="status-icon"/>
                              : chatStatus === "reportedSpam"
                              ? <img src={getImage("report-spam-status")} alt="status-icon"/>
                              : chatStatus === "open"
                              ? <img src={getImage("opened-status")} alt="status-icon"/>
                              : <img src={getImage("closed-status")} alt="status-icon"/>
                              // : null
                            }
                            <div style={{textTransform: "capitalize"}}>{chatStatus || "Closed"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MessagesSummaryList;
