import React from 'react';
import './MessagesReviewSummary.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';

const MessagesReviewSummary = (props: any) => {
    const { userName, contact, userImage, title_1, title_2, title_3, value_1, value_2, value_3, averageStar, provider}: any = props || {};
    return (
        <React.Fragment>
            <div className="review-summary">
                <div className="review-header">
                    <img className="profile-pic" src={userImage || getGenerateApiImageByName(userName || "Unknown")} alt="avatar" />
                    <div className="user-details">
                        <div title={userName} className="username">{userName ? (userName?.length < 25 ? userName : (userName?.substr(0, 22) + "...")) : "Unknown User"}</div>
                        <div title={contact} className="contact">{contact ? (contact?.length < 27 ? contact : (contact?.substr(0, 24) + "..."))  : "example@gmail.com"}</div>
                    </div>
                    {/* <img className="provider" src={getImage(provider || "whats-app-messages")} alt="provider-icon" /> */}
                    <img className="provider" src={provider ? getImage(provider || "whats-app-messages") : getGenerateApiImageByName("Unknown")} alt="provider-icon" />
                </div>
                <div className="review-stats">
                    <div className="stat">
                        <div className="value">{value_1 || 0}</div>
                        <div className="label">{title_1}</div>
                    </div>
                    <div className="stat">
                        <div className="value">{value_2 || 0}</div>
                        <div className="label">{title_2}</div>
                    </div>
                    <div className="stat">
                        <div className="value">{value_3 || 0}</div>
                        <div className="label">{title_3}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MessagesReviewSummary;
