import React, { useRef, useState } from 'react';
import './ContentViewer.scss';

const VideoViewer = ({ content }: any) => {
    const videoRef = useRef<any>(null);
    const handleVideoLoaded = () => {
        // modifyString
        // for seeing the video duration change
        console.log({video_default_duration: videoRef?.current?.duration});
    };
    // const playVideo = () => {
    //     if (videoRef.current) {
    //       // console.log({video_default_Ref: videoRef})
    //       videoRef.current.pause();
    //     }
    // };
    return (
        <React.Fragment>
            <div className="video-viewer-container">
                <video
                    ref={videoRef}
                    className="video-content"
                    // src={item?.videoUrl}
                    // src={currentContentUrl?.replaceAll("@", "/") || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/MehediClapup3_12.jpg?X-Amz-Expires=35997&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T094129Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=077bd19e3eb91675a8d639ee0a0a39beedd3e288611f0522109ecfa6ca7f1a46"}
                    src={content?.presignedUrl} // one by one
                    // src={item?.videoUrl || 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'}
                    onLoadedMetadata={handleVideoLoaded}
                    controls
                />
            </div>
        </React.Fragment>
    )
}

export default VideoViewer;