// New Version React-18
import React from 'react';
import { createRoot } from 'react-dom/client';
// // pdf license
import './utils/configs/syncfusion-license';
import './index.css';
import App from './App';
import 'antd/dist/antd.min.css';
// import 'antd/dist/antd.css';
import 'rsuite/dist/rsuite.min.css';

// import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-svg-core/styles.css';
import { Provider } from 'react-redux';
import store from './store';
const container: any = document.getElementById('root');
const root: any = createRoot(container);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>,
);

// Old Version React-17

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import 'font-awesome/css/font-awesome.min.css';
// import 'antd/dist/antd.min.css';
// import '@fortawesome/fontawesome-svg-core/styles.css';
// import { Provider } from 'react-redux';
// import store from './store';
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// reportWebVitals();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
