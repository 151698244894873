import google_logo from '../assets/icons/google-logo.svg';
import bot_message_icon from '../assets/icons/message-bot.svg';
import un_permissible_cross_icon from '../assets/img/un-permissible-cross-icon.svg';
import empty_box from '../assets/img/Empty.svg';
import form_close_icon from '../assets/icons/form-close-icon.svg';
import square_active_check from '../assets/icons/square-active-check.svg';
import square_inactive_check from '../assets/icons/square-inactive-check.svg';
import back_path_line from '../assets/icons/back-path-line.svg';
import edit_line from '../assets/icons/edit-line.svg';
import mini_carousel_left_button from '../assets/icons/mini-carousel-left-button.svg';
import mini_carousel_right_button from '../assets/icons/mini-carousel-right-button.svg';
import mini_carousel_preview_button from '../assets/icons/mini-carousel-preview-button.svg';
import preview_left_button from '../assets/icons/preview-left-button.svg';
import preview_right_button from '../assets/icons/preview-right-button.svg';
import preview_cross_button from '../assets/icons/preview-cross-icon.svg';
import x_icon from '../assets/icons/x-icon.svg';
import search_icon from '../assets/icons/search-icon.svg';
// commerce campaign & template == start
import campaign_icon from '../assets/icons/campaign-icon.svg';
import template_icon from '../assets/icons/table-template-icon.svg';
import select_active_template_icon from '../assets/icons/template-active-select.svg';
import select_inactive_template_icon from '../assets/icons/template-inactive-select.svg';

import right_bottom_tab_campaign_icon from '../assets/icons/campaign-icon.svg';
import right_bottom_tab_template_icon from '../assets/icons/template-icon.svg';
// commerce campaign & template == end
// messages inbox & details == start
import save_tik_icon from '../assets/icons/save-tik.svg';
import save_tik_campaign_icon from '../assets/icons/save-tik-pro.svg';
import schedular_icon from '../assets/icons/schedular-icon.svg';
import right_bottom_tab_message_icon from '../assets/icons/right-side-message.svg';
import right_bottom_tab_bot_icon from '../assets/icons/right-side-bot.svg';
// import whats_app_message_icon from '../assets/icons/whatsapp-icon.svg';
import whats_app_message_icon from '../assets/icons/whatsapp-message-icon.svg';
import whatsapp_conversation_header_icon from '../assets/icons/whatsapp-conversation-header-icon.svg';
import inbox_whats_app_message_icon from '../assets/icons/inbox-whatsapp.svg';
import whatsapp_circle_download_icon from '../assets/icons/whatsapp-circle-download.svg';
import whatsapp_pdf_icon from '../assets/icons/whatsapp-file-pdf.svg';
import whatsapp_mp4_icon from '../assets/icons/whatsapp-file-mp4.svg';
import whatsapp_png_icon from '../assets/icons/whatsapp-file-png.svg';
import whatsapp_jpg_icon from '../assets/icons/whatsapp-file-jpg.svg';
// import whatsapp_jpeg_icon from '../assets/icons/whatsapp-file-jpg.svg';
// messages inbox & details == end
import clapup_bot_icon from '../assets/icons/bot-icons/clapup-bot-icon.svg';
import exclamation_icon from '../assets/icons/exclamation-circle.svg';
import bot_smile_icon from '../assets/icons/smile-circle.svg';
import dropdown_active from '../assets/icons/drop-down-active.svg';
import dropdown_inactive from '../assets/icons/drop-down-inactive.svg';
import bot_tik_right_icon from '../assets/icons/tik-right-icon.svg';
import twitter_logo from '../assets/icons/twitter-icon.svg';
import facebook_logo from '../assets/icons/facebook-reviews.svg';
import feedback_logo from '../assets/icons/feedback-line-1.2.svg';
import video_logo from '../assets/icons/video-chat-line-1.2.svg';
// import video_link from '../assets/icons/video-link.svg';
import lock_icon from '../assets/icons/lock-icon.svg';
import special_lock_icon from '../assets/icons/lock-special.svg';
import star from '../assets/icons/star-icon.svg';
import star_half from '../assets/icons/star-half-icon.svg';
import pro_lock_icon from '../assets/icons/pro-lock-icon.svg';
import emoji_input_icon from '../assets/icons/emoji-input-icon.svg';
import file_input_icon from '../assets/icons/file-input-icon.svg';

import fb_recommendation from '../assets/icons/fb-recommendation-star.svg';
import fb_dont_recommendation from '../assets/icons/facebook-dont-recommendation-star.svg';
import like_icon from '../assets/icons/feather-thumbs-up.svg';
import heart_icon from '../assets/icons/heart-icon.svg';
import retweet_icon from '../assets/icons/retweet-icon.svg';
import copy_icon_line from '../assets/icons/copy-icon-line.svg';
import delete_icon_line from '../assets/icons/delete-icon-line.svg';
import global_language_icon from '../assets/icons/global-language.svg';
import global_line from '../assets/icons/global-line.svg';
import save_icon from '../assets/icons/save-icon.svg';
import man_collaborator from '../assets/icons/man-collaborator.svg';
import woman_collaborator from '../assets/icons/woman-collaborator.svg';
import boy_collaborator from '../assets/icons/boy-collaborator.svg';
import add_line_icon from '../assets/icons/add-line.svg';
import warning_icon from '../assets/icons/warning.svg';

// rating icons
import face_grin_star_icon from '../assets/icons/smile-grin-start.svg';
import face_smile_icon from '../assets/icons/face-smile.svg';
import face_meh_icon from '../assets/icons/face-meh.svg';
import face_frown_light_icon from '../assets/icons/face-frown-light.svg';
import face_frown_icon from '../assets/icons/face-frown.svg';
// status icons
import open_status_icon from '../assets/icons/opened-status.svg';
import close_status_icon from '../assets/icons/closed-status.svg';
import dispatched_status_icon from '../assets/icons/dispatched-status.svg';
import in_progress_status_icon from '../assets/icons/in-progress-status.svg';
import to_dispatched_status_icon from '../assets/icons/to-dispatched-status.svg';
import report_spam_status_icon from '../assets/icons/report-spam-status.svg';

// settings icons
import add_plus_icon from '../assets/icons/pro-settings/invite-plus-1.33.svg';
import fill_success_tik_icon from '../assets/icons/pro-settings/fill-success-tik-1.33.svg';
import un_fill_success_tik from '../assets/icons/pro-settings/un-fill-success-tik-1.33.svg';
import template_active_icon from '../assets/icons/settings/biopage/active.svg';
import template_inactive_icon from '../assets/icons/settings/biopage/inactive.svg';
import active_notification_icon from '../assets/icons/pro-settings/active-notification-1.33.svg';
import in_active_notification_icon from '../assets/icons/pro-settings/in-active-notification-1.33.svg';
import back_arrow_icon from '../assets/icons/pro-settings/back-marketing-1.33.svg';
import delete_icon from '../assets/icons/pro-settings/delete-line-1.33.svg';
import gmail_icon from '../assets/icons/pro-settings/gmail-icon-1.33.svg';
import gmail_icon_circle from '../assets/icons/pro-settings/gmail-icon-circle-1.33.svg';
import un_checked_icon from '../assets/icons/pro-settings/un-checked-1.33.svg';
import checked_icon from '../assets/icons/pro-settings/checked-1.33.svg';
import twitter_icon from '../assets/icons/pro-settings/twitter-icon-1.33.svg';
import email_icon from '../assets/icons/pro-settings/email-icon-1.33.svg';
import upload_icon from '../assets/icons/settings/upload-icon.svg';
// dashboard icons
import learn_n_participate_icon from '../assets/icons/pro-settings/learn-and-participate-1.33.svg';
import dashboard_feature_icon from '../assets/icons/pro-settings/dashboard-feature-1.33.svg';
import error_warning_success_icon from '../assets/icons/pro-settings/error-warning-success-1.33.svg';
import nav_hum_burger_icon from '../assets/icons/nav-hamburger.svg';
import link_icon from '../assets/icons/pro-settings/link-icon-1.33.svg';
import edit_icon from '../assets/icons/settings/edit-icon.svg';
import primary_icon from "../assets/icons/feed-left/team-primary.svg";
import pdf_attachment_icon from "../assets/icons/pdf-attachment.svg";
import download_attachment_icon from "../assets/icons/attachment-download.svg";
import delete_attachment_icon from "../assets/icons/attachment-delete.svg";
import bot_avatar_icon from "../assets/icons/bot-avatar.svg";
import tag_icon from "../assets/icons/tag-icon.svg";
import dots from "../assets/icons/more-action.svg";
import team_folder_icon from "../assets/icons/team-folder-icon.svg";
import remove_attachment from "../assets/icons/attachment-cross.svg";
import circle_plus from "../assets/icons/feed-left/circle-plus.svg";
import big_circle_exclamation from "../assets/icons/circle-exclamation.svg";
import circle_top_arrow from "../assets/icons/circle-top-arrow.svg";
import circle_down_arrow from "../assets/icons/circle-down-arrow.svg";
import circle_tik_icon from "../assets/icons/circle-tik-icon.svg";
import circle_cross_icon from "../assets/icons/circle-cross-icon.svg";
import removable_content from "../assets/icons/removable_content.svg";
import folder_icon from "../assets/icons/folder-icon.svg";
// content preview icons

// content list icons
import content_less_icon from "../assets/icons/content-less-icon.svg";
import content_scss_icon from "../assets/icons/content-scss-icon.svg";
import content_css_icon from "../assets/icons/content-css-icon.svg";
import content_xml_icon from "../assets/icons/content-xml-icon.svg";
import content_json_icon from "../assets/icons/content-json-icon.svg";
import content_tar_icon from "../assets/icons/content-tar-icon.svg";
import content_rar_icon from "../assets/icons/content-rar-icon.svg";
import content_7z_icon from "../assets/icons/content-7z-icon.svg";
import content_iso_icon from "../assets/icons/content-iso-icon.svg";
import content_zip_icon from "../assets/icons/content-zip-icon.svg";
import content_docx_icon from "../assets/icons/content-docx-icon.svg";
import content_rtf_icon from "../assets/icons/content-rtf-icon.svg";
import content_text_icon from "../assets/icons/content-text-icon.svg";
import content_pdf_icon from "../assets/icons/content-pdf-icon.svg";
import content_pptx_icon from "../assets/icons/content-pptx-icon.svg";
import content_xlsx_icon from "../assets/icons/content-xlsx-icon.svg";
import content_video_icon from "../assets/icons/content-video-icon.svg";
import content_svg_icon from "../assets/icons/content-svg-icon.svg";
import content_image_icon from "../assets/icons/content-image-icon.svg";
import content_audio_icon from "../assets/icons/content-audio-icon.svg";
// table list
import content_row_share_icon from "../assets/icons/content-share-icon.svg";
import content_row_release_icon from "../assets/icons/content-release-icon.svg";
import content_row_publish_icon from "../assets/icons/content-publish-icon.svg";
import content_row_checkout_icon from "../assets/icons/content-checkout-icon.svg";
import content_nothing_to_show from "../assets/icons/nothing-to-show.svg";

// context dropdown icons
// file
import file_content_child_back_icon from "../assets/icons/content-child-back-icon.svg"; // chevron icon
import file_content_release_icon from "../assets/icons/file-content-release-icon.svg";
import file_content_draft_icon from "../assets/icons/file-content-draft-icon.svg";
import file_content_archive_icon from "../assets/icons/file-content-archive-icon.svg";
import file_content_check_out_icon from "../assets/icons/file-content-check-out-icon.svg";
import file_content_check_in_icon from "../assets/icons/file-content-check-in-icon.svg"; // need check in
import file_content_get_url_icon from "../assets/icons/file-content-get-url-icon.svg";
import file_content_move_copy_icon from "../assets/icons/file-content-move-or-copy-icon.svg";
import file_content_upload_version_icon from "../assets/icons/file-content-upload-version-icon.svg";
import file_content_share_icon from "../assets/icons/file-content-dropdown-share-icon.svg";
import file_content_field_editor_icon from "../assets/icons/file-content-field-editor-icon.svg";
import file_content_download_icon from "../assets/icons/file-content-download-icon.svg";
import file_content_delete_icon from "../assets/icons/file-content-delete-icon.svg";
import file_content_publish_icon from "../assets/icons/file-content-publish-icon.svg";
import file_content_undo_publish_icon from "../assets/icons/file-content-undo-publish-icon.svg";
import big_back_arrow from "../assets/icons/next-path.svg";
// folder
import folder_content_archive_icon from "../assets/icons/folder-content-archive-icon.svg";
import folder_content_move_copy_icon from "../assets/icons/folder-content-move-or-copy-icon.svg";
import folder_content_share_icon from "../assets/icons/folder-content-share-icon.svg";
import folder_content_field_editor_icon from "../assets/icons/folder-content-field-editor-icon.svg";
import folder_content_delete_icon from "../assets/icons/folder-content-delete-icon.svg";
// ===== x ====
import folder_shared_files from "../assets/icons/feed-left/shared-files.svg";
import folder_personal_files from "../assets/icons/feed-left/personal-files.svg";
import folder_shared_with_me_files from "../assets/icons/feed-left/shared-with-me-files.svg";
import folder_archive_files from "../assets/icons/feed-left/archive-icon.svg";
import being_uploaded_warning from "../assets/icons/being-uploaded-warning.svg";
import error_upload_warning from "../assets/icons/error-upload-warning.svg";
import success_upload_warning from "../assets/icons/success-upload-warning.svg";

// content viewer
import view_delete_icon from "../assets/icons/view-delete-icon.svg";

const getImage = (type: any) => {
  switch (type) {
    case "big-circle-exclamation":
      return big_circle_exclamation;
    case "view-delete-icon":
      return view_delete_icon;
    case "folder-shared-files":
      return folder_shared_files;
    case "folder-personal-files":
      return folder_personal_files;
    case "folder-shared-with-me-files":
      return folder_shared_with_me_files;
    case "folder-archive-files":
      return folder_archive_files;
    case "big-back-arrow":
      return big_back_arrow;
    case "circle-tik-icon":
      return circle_tik_icon;
    case "circle-cross-icon":
      return circle_cross_icon;
    // file context dropdown
    case "file-content-field-editor-icon":
      return file_content_field_editor_icon;
    case "file-content-download-icon":
      return file_content_download_icon;
    case "file-content-release-icon":
      return file_content_release_icon;
    case "file-content-draft-icon":
      return file_content_draft_icon;
    case "file-content-child-back-icon":
      return file_content_child_back_icon;
    case "file-content-archive-icon":
      return file_content_archive_icon;
    case "file-content-check-out-icon":
      return file_content_check_out_icon;
    case "file-content-check-in-icon":
      return file_content_check_in_icon;
    case "file-content-get-url-icon":
      return file_content_get_url_icon;
    case "file-content-move-or-copy-icon":
      return file_content_move_copy_icon;
    case "file-content-upload-version-icon":
      return file_content_upload_version_icon;
    case "file-content-share-icon":
      return file_content_share_icon;
    case "file-content-publish-icon":
      return file_content_publish_icon;
    case "file-content-undo-publish-icon":
      return file_content_undo_publish_icon;
    case "file-content-delete-icon":
      return file_content_delete_icon;
    // file context dropdown

    // folder context dropdown
    case "folder-content-archive-icon":
      return folder_content_archive_icon;
    case "folder-content-move-or-copy-icon":
      return folder_content_move_copy_icon;
    case "folder-content-share-icon":
      return folder_content_share_icon;
    case "folder-content-field-editor-icon":
      return folder_content_field_editor_icon;
    case "folder-content-delete-icon":
      return folder_content_delete_icon;
    // folder context dropdown

    // content row table list icons
    case "nothing-to-show":
      return content_nothing_to_show;
    case "content-row-checkout-icon":
      return content_row_checkout_icon;
    case "content-row-publish-icon":
      return content_row_publish_icon;
    case "content-row-release-icon":
      return content_row_release_icon;
    case "content-row-share-icon":
      return content_row_share_icon;
    // content row table list icons
    case "content-image-icon":
      return content_image_icon;
    case "content-audio-icon":
      return content_audio_icon;
    case "content-video-icon":
      return content_video_icon;
    case "content-less-icon":
      return content_less_icon;
    case "content-scss-icon":
      return content_scss_icon;
    case "content-css-icon":
      return content_css_icon;
    case "content-xml-icon":
      return content_xml_icon;
    case "content-svg-icon":
      return content_svg_icon;
    case "content-json-icon":
      return content_json_icon;
    case "content-tar-icon":
      return content_tar_icon;
    case "content-rar-icon":
      return content_rar_icon;
    case "content-iso-icon":
      return content_iso_icon;
    case "content-7z-icon":
      return content_7z_icon;
    case "content-zip-icon":
      return content_zip_icon;
    case "content-xlsx-icon":
      return content_xlsx_icon;
    case "content-pptx-icon":
      return content_pptx_icon;
    case "content-pdf-icon":
      return content_pdf_icon;
    case "content-rtf-icon":
      return content_rtf_icon;
    case "content-text-icon":
      return content_text_icon;
    case "content-docx-icon":
      return content_docx_icon;
    case "folder-icon":
      return folder_icon;
    case "removable_content":
      return removable_content;
    case "team-folder-icon":
      return team_folder_icon;
    case "circle-plus":
      return circle_plus;
    case "circle-top-arrow":
      return circle_top_arrow;
    case "circle-down-arrow":
      return circle_down_arrow;
    case "remove-attachment":
      return remove_attachment;
    case "more-action":
      return dots;
    case "dots":
      return dots;
    case "tag-icon":
      return tag_icon;
    case "bot-avatar":
      return bot_avatar_icon;
    case "attachment-delete":
      return delete_attachment_icon;
    case "attachment-download":
      return download_attachment_icon;
    case "pdf-attachment":
      return pdf_attachment_icon;
    case "primary-icon":
      return primary_icon;
    case "half-star":
      return star_half;
    case "star":
      return star;
    case 'edit-icon':
      return edit_icon;
    case 'link-icon':
      return link_icon;
    case 'search-icon':
      return search_icon;
    case 'mobile-nav-icon':
      return nav_hum_burger_icon;
    case 'error-warning-success':
      return error_warning_success_icon;
    case 'warning-icon':
      return warning_icon;
    case 'feature-icon':
      return dashboard_feature_icon;
    case 'dashboard-card-learn-and-participate':
      return learn_n_participate_icon;
    case 'upload-icon':
      return upload_icon;
    case 'Email':
      return email_icon;
    case 'Twitter':
      return twitter_icon;
    case 'gmail':
      return gmail_icon;
    case 'gmail-icon':
      return gmail_icon;
    case 'gmail-icon-circle':
      return gmail_icon_circle;
    case 'delete-icon':
      return delete_icon;
    case 'back-arrow':
      return back_arrow_icon;
    case 'active-notification':
      return active_notification_icon;
    case 'in-active-notification':
      return in_active_notification_icon;
    case 'fill-success-tik':
      return fill_success_tik_icon;
    case 'template_inactive_icon':
      return template_inactive_icon;
    case 'template_active_icon':
      return template_active_icon;
    case 'un-fill-success-tik':
      return un_fill_success_tik;
    case 'add-plus':
      return add_plus_icon;
    case 'preview_left_button':
      return preview_left_button;
    case 'preview_right_button':
      return preview_right_button;
    case 'preview_cross_button':
      return preview_cross_button;
    case 'mini_carousel_left_button':
      return mini_carousel_left_button;
    case 'mini_carousel_right_button':
      return mini_carousel_right_button;
    case 'mini_carousel_preview_button':
      return mini_carousel_preview_button;
    case 'whatsapp-circle-download':
      return whatsapp_circle_download_icon;
    case 'PDF':
      return whatsapp_pdf_icon;
    case 'MP4':
      return whatsapp_mp4_icon;
    case 'PNG':
      return whatsapp_png_icon;
    case 'JPG':
      return whatsapp_jpg_icon;
    case 'JPEG':
      return whatsapp_jpg_icon;
    case 'edit-line-icon':
      return edit_line;
    case 'save-icon':
      return save_icon;
    case 'global-line':
      return global_line;
    case 'back-path-line':
      return back_path_line;
    case 'global-language':
      return global_language_icon;
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'checked-icon':
      return checked_icon;
    case 'un-checked-icon':
      return un_checked_icon;
    case 'active-radio-button':
      return select_active_template_icon;
    case 'inactive-radio-button':
      return select_inactive_template_icon;
    case 'save-tik':
      return save_tik_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'right-side-message':
      return right_bottom_tab_message_icon;
    case 'right-side-bot':
      return right_bottom_tab_bot_icon;
    case 'inbox-whats-app-messages':
      return inbox_whats_app_message_icon;
    case 'whatsapp-conversation-header-icon':
      return whatsapp_conversation_header_icon;
    case 'Whatsapp':
      return whats_app_message_icon;
    case 'whatsapp':
      return whats_app_message_icon;
    case 'whats-app-messages':
      return whats_app_message_icon;
    case 'clapup-bot-icon':
      return clapup_bot_icon;
    case 'exclamation_icon':
      return exclamation_icon;
    case 'bot_smile_icon':
      return bot_smile_icon;
    case 'dropdown_active':
      return dropdown_active;
    case 'dropdown_inactive':
      return dropdown_inactive;
    case 'tik_right_icon':
      return bot_tik_right_icon;
    case 'bot-messages':
      return bot_message_icon;
    case 'Google':
      return google_logo;
    case 'google':
      return google_logo;
    case 'file-input-icon':
      return file_input_icon;
    case 'emoji-input-icon':
      return emoji_input_icon;
    case 'pro-lock-icon':
      return pro_lock_icon;
    case 'lock_icon':
      return lock_icon;
    case 'thread_lock_icon':
      return special_lock_icon;
    case 'Facebook':
      return facebook_logo;
    case 'facebook':
      return facebook_logo;
    case 'facebookComments':
      return facebook_logo;
    case 'twitter':
      return twitter_logo;
    case 'videoTestimonial':
      return video_logo;
    case 'customerFeedBack':
      return feedback_logo;
    case 'heart':
      return heart_icon;
    case 'retweet':
      return retweet_icon;
    case 'fb-recommend':
      return fb_recommendation;
    case 'fb-dont-recommend':
      return fb_dont_recommendation;
    case 'like':
      return like_icon;
    case 'empty':
      return empty_box;
    case 'x-icon':
      return x_icon;
    case 'un-permissible-cross-icon':
      return un_permissible_cross_icon;
    // thread status icon
    case 'closed-status':
      return close_status_icon;
    case 'opened-status':
      return open_status_icon;
    case 'dispatched-status':
      return dispatched_status_icon;
    case 'in-progressed-status':
      return in_progress_status_icon;
    case 'to-dispatched-status':
      return to_dispatched_status_icon;
    case 'report-spam-status':
      return report_spam_status_icon;

    // rating icons
    case 'rating-5':
      return face_grin_star_icon;
    case 'rating-4':
      return face_smile_icon;
    case 'rating-3':
      return face_meh_icon;
    case 'rating-2':
      return face_frown_light_icon;
    case 'rating-1':
      return face_frown_icon;
    default:
      return face_frown_icon;
  }
};

export const image_constant = {
  folder_icon: folder_icon,
  being_uploaded_warning: being_uploaded_warning,
  success_upload_warning: success_upload_warning,
  error_upload_warning: error_upload_warning,
};

const getCampaignImage = (type: any) => {
  switch (type) {
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'template-active-select':
      return select_active_template_icon;
    case 'template-inactive-select':
      return select_inactive_template_icon;
    case 'save-tik':
      return save_tik_icon;
    case 'save-tik-campaign':
      return save_tik_campaign_icon;
    case 'schedular-icon':
      return schedular_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'empty':
      return empty_box;
    default:
      return empty_box;
  }
};

const getTemplateImage = (type: any) => {
  switch (type) {
    case 'campaign':
      return campaign_icon;
    case 'table-template':
      return template_icon;
    case 'square-active-check':
      return square_active_check;
    case 'square-inactive-check':
      return square_inactive_check;
    case 'form-close-icon':
      return form_close_icon;
    case 'template-active-select':
      return select_active_template_icon;
    case 'template-inactive-select':
      return select_inactive_template_icon;
    case 'copy-icon-line':
      return copy_icon_line;
    case 'delete-icon-line':
      return delete_icon_line;
    case 'save-tik':
      return save_tik_icon;
    case 'right-side-campaign':
      return right_bottom_tab_campaign_icon;
    case 'right-side-template':
      return right_bottom_tab_template_icon;
    case 'add-line':
      return add_line_icon;
    case 'empty':
      return empty_box;
    default:
      return empty_box;
  }
};

const getConversationImage = (type: any) => {
  switch (type) {
    case 'google':
      return google_logo;
    case 'lock_icon':
      return lock_icon;
    case 'facebook':
      return facebook_logo;
    case 'facebookComments':
      return facebook_logo;
    case 'twitter':
      return twitter_logo;
    case 'videoTestimonial':
      return video_logo;
    case 'customerFeedBack':
      return feedback_logo;
    case 'heart':
      return heart_icon;
    case 'retweet':
      return retweet_icon;
    case 'fb-recommend':
      return fb_recommendation;
    case 'fb-dont-recommend':
      return fb_dont_recommendation;
    case 'like':
      return like_icon;
    default:
      return google_logo;
  }
};

const testCollaboratorImage = (type: any) => {
  switch (type) {
    case "man-collaborator":
      return man_collaborator;
    case "woman-collaborator":
      return woman_collaborator;
    case "boy-collaborator":
      return boy_collaborator;
    default:
      return google_logo;
  }
};

const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

const getGenerateApiImageByName = (name?: string) => {
  return `https://ui-avatars.com/api/?name=${name || "Unknown User"}&background=0F43BD&color=fff&rounded=true&size=32`
};

const extractImageNameFromUrl = (url?: string) => {
  const st_url = url?.replaceAll("@", "/");
  // console.log({ st_url });
  return st_url?.substring(st_url.lastIndexOf('/') + 1) || "";
}

export { extractImageNameFromUrl, getImage, getGenerateApiImageByName, getCampaignImage, getTemplateImage, testCollaboratorImage, getConversationImage, capitalize };
