import React from 'react';
import './SkeletonLoader.scss';
const SkeletonLoader = ({ width = '100%', height = '20px', borderRadius = '4px', margin = "0px 0px" }) => {
  return (
    <React.Fragment>
      <div className="single-skeleton-loader" style={{ width, height, borderRadius, margin }}></div>
    </React.Fragment>
  )
}

export default SkeletonLoader;
