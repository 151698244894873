import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_version } from '../../../utils/server';
import { setErrorMessage } from '../../../unified/reducer';
import { generateAccessToken } from '../../../profile/reducer';
import { getImage } from '../../../unified/image_service';
import "./GlobalModalForm.scss";

const WarningModalForm = ({ header_title, warning_message, left_button_title, right_button_title, showWarningDialog, data_fields, parentCallback, handleCancel }: any) => {
    const dispatch = useDispatch();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const [cancelingOn, setCancelingOn] = useState<any>(false);
    const handleContinue = async () => {
        try {

            const { payload, api_url } = data_fields; // api_end_point, necessary-object-properties
            if(!payload || !api_url){
                parentCallback(data_fields);
                return;
            }
            // console.log({ data, payload });
            
            const url = api_url;
            const { data, status } = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                parentCallback({...data_fields, response_data: data});
                // dispatch(setSuccessMessage("Closed Successfully"));
            }

        } catch (error: any) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };
    
    const handleBack = async () => {
        setCancelingOn(true);
        setTimeout(() => { 
        setCancelingOn(false);
        handleCancel();
        }, 500);
    }

    // const handleContinue = async () => {
    //     console.log({ data_fields });
    //     // parentCallback();
    // }
    
    return (
        <React.Fragment>
            <div className={` ${cancelingOn ? "warning-close-modal" : showWarningDialog ? "warning-modal-wrap" : "d-none"}`}>
                <div className={`custom-continue-modal`}>
                    <div className="modal-container">
                    <div className='modal-body'>
                        <div className='left-image'>
                        <img src={getImage("error-warning-success")} alt="ews-icon" />
                        </div>
                        <div className='right-body'>
                        <h2 className='title'>{header_title || "Warning Tile?"}</h2>
                        <div className="description">{warning_message || "By clicking continue, the conversation will be marked as closed and no further activities will be possible."}</div>
                        <div className='footer-button-container'>
                            <div className='back' onClick={() => handleBack()}>{left_button_title || "Back"}</div>
                            <div className='continue' onClick={() => handleContinue()}>{right_button_title || "Continue"}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WarningModalForm;
