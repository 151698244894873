import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../unified/image_service";
import "./IntegrationsSettings.scss";
import { setIntegrationTab } from "./reducer";
import SkeletonLoader from "../../../components/Loaders/SkelitonLoader/SkeletonLoader";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
// import IntegrationManagement from "./Components/IntegrationManagement";

const IntegrationsSettingsLoader: React.FC = () => {
    const dispatch = useDispatch();
    const { integration_tab } = useSelector((state: any) => state?.integrationState);

    const handleTabChange = (tab?: any) => {
        dispatch(setIntegrationTab(tab));
    }
    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button>
                    <div>Add Site</div>
                    <img src={getImage("add-plus")} alt="invite-icon" />
                </button>
            </div>
            <div className="body-container">
                <div className="integration-setting-list-container">
                    <div className="settings-header-title">
                        <h3>Integrations</h3>
                    </div>
                    <div className="settings-header-tab">
                        <div className={`tab ${integration_tab === "Integrations" ?  "active" : ""}`} onClick={() => handleTabChange("Integrations")}>Integrations</div>
                        <div className={`tab ${integration_tab === "Connected Accounts" ?  "active" : ""}`}>Connected Accounts</div>
                    </div>

                    <div className="loader-integration-settings-container">
                        {
                            [1, 2]?.map((item: any) => (
                            <div key={item} className="loader-integrations-container">
                                <SkeletonLoader borderRadius="25px" width="150px" height="20px"/>
                                <SkeletonLoader margin="28px 0 9px 0" borderRadius="25px" height="14px"/>
                                <SkeletonLoader borderRadius="25px" height="14px"/>
                            </div>
                            ))
                        }
                    </div>

                    <div className="circle-loader-settings-container">
                        <p className="loader-title">Please wait while we make everything perfect</p>
                        <CircleLoader size="38px" borderWidth="4px"/>
                    </div>
                    {/* {
                        integration_tab === "Integrations"
                        ? <IntegrationManagement/>
                        : <ConnectedAccounts/>
                    } */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationsSettingsLoader;