import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AgentSettings from "./Components/AgentSettings";
import "./Agent.scss";
import AgentList from "./Components/AgentList";

const Agents: React.FC = () => {
    // const parent_routes = workspace_routes;
    const { editable_agent_id } = useSelector((state: any) => state?.agentState);

    
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    return (
        <React.Fragment>
            {
                editable_agent_id
                ? <AgentSettings aiChatBotId={editable_agent_id}/>
                : <AgentList/>
            }
        </React.Fragment>
    );
};

export default Agents;