import React from 'react';
import '../../../LinkManagement.scss';
// import { convertImageToBase64 } from '../../../../../../utils/convertingFunction';
// import { setErrorMessage } from '../../../../../../dashboard/reducer';
import { useDispatch, useSelector } from 'react-redux';

type LinkProps = {
    link_item?: any;
    id?: number;
    label?: "";
    displayText?: "";
    url?: "";
    logoName?: '';
    logo?: any;
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const SimpleLinkForm: React.FC<LinkProps> = ({link_item, id, displayText, label, url, logoName, logo, onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const [curDisplayText, setCurDisplayText] = React.useState(displayText);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    const [curLabel, setCurLabel] = React.useState(label);
    const [curUrl, setCurUrl] = React.useState(url);
    const [image, setImage] = React.useState<any>(null);
    const handleSubmit = () => {
        // const dummy_images = [
        //     "https://i.ibb.co/Z1KQVMG/facebook.png",
        //     "https://i.ibb.co/cxx56hX/google.png",
        //     "https://i.ibb.co/xmgRtQN/instagram.png",
        //     "https://i.ibb.co/fXCyV0S/mail.png",
        //     "https://i.ibb.co/z4LsPft/pinterest-icon.png",
        //     "https://i.ibb.co/g3BWMkW/twitter.png",
        // ];

        // const dummy_image = dummy_images[Math.floor(Math.random() * dummy_images.length)];

        const data = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
            displayText: curDisplayText,
            id: link_item?.id || 0,
            linkUrl: curUrl,
            label: curLabel,
            // iconUrl: image?.base64Url || logo, // we have to work in future
            iconUrl: link_item?.iconUrl || "",
            iconName: image?.name || logoName,
            parentId: 0,
            linkType: 1,
            videoLink: '',
            mediaType: '',
            contentIds: '',
            mode: link_item ? "update" : "insert",
            linkCategory: "customlinks",
            contentId: link_item?.contentId || "",
            uniqueNo: link_item?.uniqueNo || null,
        }
        onParentCallback && onParentCallback(data, image?.file);
    };

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e?.target?.files[0];
        const { name }: any = file || {};
        // console.log({file});
        // setImageFile(file);

        setImage({file, name: name?.replaceAll(" ", "")});
        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // console.log({base64Url, name});
        //     setImage({base64Url, name});
            
        // }).catch((error: any) => {
        //     console.log({ error });
        //     dispatch(setErrorMessage("Something went wrong while converting image to base64"))
        // });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": logged_in_user_secret_data?.wuid,
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const handleChange = (value: any, setState: any) => {
        setState(value);
    }

    // console.log({file: image})

    return (
        <div className='form-link-container'>
            <form
                id='link-form'
                className='form'
            >
                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"displayText"}
                        autoFocus={true}
                        autoComplete='off'
                        autoSave='off'
                        onChange={(e: any) => handleChange(e.target.value, setCurDisplayText)}
                        value={curDisplayText || ""}
                        placeholder={"Enter the Display Text"}
                        style={{margin: '0 0 3.5% 0'}}
                    />
                </div>

                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"label"}
                        autoComplete='off'
                        autoSave='off'
                        onChange={(e: any) => handleChange(e.target.value, setCurLabel)}
                        value={curLabel || ""}
                        placeholder={"Enter the label"}
                        style={{margin: '0 0 3.5% 0'}}
                    />
                </div>
                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"url"}
                        autoComplete='off'
                        autoSave='off'
                        onChange={(e: any) => handleChange(e.target.value, setCurUrl)}
                        value={curUrl || ""}
                        placeholder={"Enter the Link URL"}
                        style={{margin: '0 0 3.5% 0'}}
                    />
                </div>
                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"logo"}
                        autoComplete='off'
                        autoSave='off'
                        readOnly={true}
                        placeholder={"Upload the link icon"}
                        value={image?.name ? image?.name : logoName || ""}
                    />
                    <input
                        // disabled={image ? false : true}
                        id="image"
                        type="file"
                        accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                        onChange={(e: any) => {
                            // console.log({ img: e?.target?.files[0] });
                            // setImage(e?.target?.files[0]?.name);
                            handleImageChange(e); 
                        }}
                        className="form-input-field d-none w-100 my-1" placeholder="Biopage Image"
                    />
                    <label className='image-label' htmlFor="image">Upload</label>
                </div>
            </form>
            <div className='form-button-container'>
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >
                    {
                        id ? "Cancel" : "No, Cancel"
                    }
                </button>
                <button
                    disabled={((image?.name || logoName)&& curDisplayText && curLabel && curUrl) ? false : true}
                    className='create-button'
                    onClick={() => handleSubmit()}
                    type='button'
                >
                    {
                        id ? "Save" : "Yes, Create"
                    }
                </button>
            </div>

        </div>
    )
};

export default SimpleLinkForm;
