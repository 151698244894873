import React from 'react';
import '../Conversations.scss';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import google_logo from '../../../../assets/icons/google-logo.svg';
// import twitter_logo from '../../../../assets/icons/twitter-icon.svg';
// import facebook_logo from '../../../../assets/icons/facebook-reviews.svg';
// import feedback_logo from '../../../../assets/icons/feedback-line-1.2.svg';
// import video_logo from '../../../../assets/icons/video-chat-line-1.2.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import fb_recommendation from '../../../../assets/icons/fb-recommendation-star.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// import like_icon from '../../../../assets/icons/feather-thumbs-up.svg';
// import heart_icon from '../../../../assets/icons/heart-icon.svg';
// import retweet_icon from '../../../../assets/icons/retweet-icon.svg';
// import { useEffect, useState } from 'react';
// import moment from 'moment';
// import { getImage } from '../../../../unified/image_service';
import { useSelector } from 'react-redux';
import BotConversationDetails from '../Details/BotConversationDetails';
import AllMessagesConversationDetails from '../Details/AllMessagesConversationDetails';
// import { Session } from 'inspector';


interface Props {
  statDetails?: any;
  searchText?: string;
  type?: string;
}

const MessagesDetails: React.FC<Props> = ({  type, statDetails }) => {
  // const query_string = window.location.search;
  // const tag = query_string?.includes("open") ? "open"
  // : query_string?.includes("inProgress") ? "inProgress"
  // : query_string?.includes("closed") ? "closed" 
  // : query_string?.includes("spam") ? "spam" 
  // : "all";
          
  // const {currentWorkspace: curWorkspace} = useSelector((state: any) => state?.headerState);
  const {workspaceSettings} = useSelector(
      (state: any) => state?.workspaceManagementState
  );
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  )?.workSpaceName || "";

  return (
    <React.Fragment>
      {
        type === "bot-messages"
        ? <BotConversationDetails 
            type={type}
            workspaceSettings={{ ...workspaceSettings, workspaceName: workSpaceName }}
            statDetails={statDetails || {}}
          />
        : <AllMessagesConversationDetails
            type={type}
            workspaceSettings={{ ...workspaceSettings, workspaceName: workSpaceName }}
            conversation={statDetails || {}}
          />
        // : type === "whats-app-messages"
        // ? <AllMessagesConversationDetails type={type} workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} statDetails={statDetails || {}}/>
        // : null
      }
    </React.Fragment>
  );
}

export default MessagesDetails;
