import React, { useEffect, useRef } from 'react';
import "./ContentManagement.scss";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Row, Col } from 'antd';
import { Carousel, Spinner } from 'react-bootstrap';
import preview_media from '../../../assets/icons/preview-media-pro.svg';
import preview_info from '../../../assets/icons/preview-info-pro.svg';
// import preview_media from '../../../assets/icons/preview-media.svg';
// import preview_info from '../../../assets/icons/preview-info.svg';
// import carousel_prev from '../../../assets/icons/carousel-prev.svg';
import carousel_prev from '../../../assets/icons/carousel-prev-pro.svg';
// import carousel_next from '../../../assets/icons/carousel-next.svg';
import carousel_next from '../../../assets/icons/carousel-next-pro.svg';
import { setDefaultContentIdx, setSelectedDeletableContents, setShowingSaveButton, setUpdatableContentBeforeSaving, setUpdateFolderContent } from '../../ContentComponents/reducer';
import ContentSeoImageTabs from './ContentSeoImageTabs';
import ContentSeoVideoTabs from './ContentSeoVideoTabs';
import { useNavigate } from 'react-router';
import { generatePresignedUrl } from '../../../utils/mediaService';

export default function ContentImageAndVideo({ activeFolder }: any) {
  const { default_content_idx, folder_contents }: any = useSelector((state: RootState) => state?.contentState);
  const content = folder_contents[default_content_idx];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route_path = window.location.pathname;
  const videoRef = useRef<any>(null);
  const slider: any = useRef<any>(null);
  const [contents, setContents] = React.useState<any[]>([]);
  const [isInfo, setIsInfo] = React.useState<boolean>(false);
  const [currentContentUrl, setCurrentContentUrl] = React.useState<any>(null);
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);

  const getCurContentUrl = async() => {
    // const contentUrl = await getContentUrl(folder_contents[default_content_idx]);
    // console.log({contentUrl});
    // setCurrentContentUrl(contentUrl);
    const content = folder_contents[default_content_idx];
    const new_all_content = folder_contents;
    const new_content_fields: any = await generatePresignedUrl(content, logged_in_user_secret_data?.access_token);
    console.log({ new_content_fields });
    new_all_content[default_content_idx] = {...content, ...new_content_fields};
    // setContents(new_all_content);
    setCurrentContentUrl(new_content_fields?.presignedUrl);
    dispatch(setUpdateFolderContent({ ...content, ...new_all_content }));
  }

  useEffect(() => {
    if (folder_contents?.length) {
      setContents(folder_contents);
    }
  }, [folder_contents?.length]);

  useEffect(() => {
    // console.log({default_content_idx, contents});
    setCurrentContentUrl(null);
    if (default_content_idx !== null && !content?.length && folder_contents?.length) {
      // console.log({ default_content_idx, content_id: folder_contents[default_content_idx]?.id });
      let selected_id = folder_contents[default_content_idx]?.id; 
      const selected_content = folder_contents[default_content_idx];
      if (!selected_id) {
        selected_id = folder_contents[default_content_idx - 1]?.id;
        dispatch(setDefaultContentIdx(default_content_idx - 1));
      }
      dispatch(setUpdatableContentBeforeSaving({
        clapupUserId: logged_in_user_secret_data?.cuid,
        workspaceId: logged_in_user_secret_data?.wuid,
        roleId: logged_in_user_secret_data?.roleId,
        contentId: selected_content?.id,
        sEO_Title: selected_content?.sEO_Title || "",
        sEO_UrlSlug: selected_content?.sEO_UrlSlug || "",
        sEO_Desc: selected_content?.sEO_Desc || "",
        bioPage_Title: selected_content?.bioPage_Title || "",
        bioPage_Caption: selected_content?.bioPage_Caption || "",
        bioPage_Story: selected_content?.bioPage_Story || "",
        properties_Keywords: selected_content?.properties_Keywords || "",
      }));
      // console.log({selected_id});
      // console.log({ default_content_idx, content_id: folder_contents[default_content_idx]?.id });
      dispatch(setSelectedDeletableContents([selected_id]));
      // console.log({ selected_content });
      if (new Date(selected_content?.urlExpiresOn) < new Date()) {
        getCurContentUrl();
      }
    }
  }, [default_content_idx, contents?.length]);

  // pause the video playback when the slide is changed

  const playVideo = () => {
    if (videoRef.current) {
      // console.log({video_default_Ref: videoRef})
      videoRef.current.pause();
    }
  };

  const handleVideoLoaded = () => {
    // modifyString
    // for seeing the video duration change
    // console.log({video_default_duration: videoRef.current.duration});
  };

  // console.log({content_default: contents[default_content_idx]})
  return (
    <React.Fragment>
      <div className="special-lg-md-device w-100">
        <Row gutter={32} className='custom-carousel w-100'>
          <Col span={3} sm={2} md={1} lg={2} className='px-0 special-lg-md-device'>
            <button
              className='carousel-bt'
              type='button'
              // disabled={!contents?.length}
              onClick={() => { 
                playVideo();
                dispatch(setShowingSaveButton(true));
                slider.current.prev();
              }}
            >
              <img src={carousel_prev} alt="prev" />
            </button>
          </Col>
          <Col span={24} sm={20} md={12} lg={12} className='px-0'>
            {
              contents[default_content_idx]?.id
              ? (
                <Carousel
                  ref={slider}
                  interval={null}
                  indicators={false}
                  nextIcon={false}
                  prevIcon={false}
                  activeIndex={default_content_idx}
                  // defaultActiveIndex={default_content_idx}
                  onSlide={(e: any) => {
                    navigate(`${route_path}?${activeFolder}=contents&idx=${e}`);
                    dispatch(setDefaultContentIdx(e));
                  }}
                >
                  {
                    contents?.length
                    ? contents?.map((item: any, index: number) => {
                      return (
                        <Carousel.Item key={index}>
                          {/* <p>{getContentUrl(item)}</p> */}
                          
                          {
                            // item?.subCategory === 'GalleryVideos'
                            item?.category?.includes('Video')
                            ? (
                              <div className='content-carousel-container'>
                                <video
                                  ref={videoRef}
                                  className="carousel-video"
                                  // src={item?.videoUrl}
                                  // src={currentContentUrl?.replaceAll("@", "/") || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/MehediClapup3_12.jpg?X-Amz-Expires=35997&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T094129Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=077bd19e3eb91675a8d639ee0a0a39beedd3e288611f0522109ecfa6ca7f1a46"}
                                  src={currentContentUrl || item?.presignedUrl?.replaceAll("@", "/")} // one by one
                                    // src={item?.videoUrl || 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'}
                                  onLoadedMetadata={handleVideoLoaded}
                                  controls
                                />
                                {/* <iframe className="carousel-video" src={item?.videoUrl || "https://www.youtube.com/embed/lsd-sl2WBic?si=6HsrrilLPc0r2paO"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen /> */}
                                {/* <ContentSeoVideoTabs/> */}
                              </div>
                            ) : (
                              <div className='content-carousel-container'>
                                <img className="carousel-image" 
                                  // src={item?.imageUrl || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/mmhk40_13.png?X-Amz-Expires=604796&x-response-content-disposition=attachment%3B%20filename%3D%22mmhk40_13.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0ad77b3401369b73425d76c732dcf41e%2F20231130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231130T071449Z&X-Amz-SignedHeaders=host&X-Amz-Signature=c2b566b604a39dfc86d662ff959a3a0248c6e49097d52f30c8c8752f05cdd54f"} 
                                  src={currentContentUrl || item?.presignedUrl?.replaceAll("@", "/")} 
                                  // src={item?.displayPresignedUrl} 
                                  // src={currentContentUrl} // one by one 
                                  alt={item?.contentFileName} 
                                />
                                {/* <ContentSeoImageTabs/> */}
                              </div>
                            )
                          }
                        </Carousel.Item>
                      );
                    })
                    : <Spinner animation="border" variant="dark" />
                    // : <Spinner animation="grow" variant="info" />
                    
                  }
                </Carousel>
              ) : null
            }
          </Col>
          <Col span={3} sm={2} md={1} lg={2} className='px-0 special-lg-md-device'>
            <button
              className=' carousel-bt'
              type='button'
              // disabled={!contents?.length}
              onClick={() => {
                playVideo();
                dispatch(setShowingSaveButton(true));
                slider?.current?.next();
              }}
            >
              <img src={carousel_next} alt="next" />
            </button>
          </Col>
          <Col span={24} sm={24} md={10} lg={8} className='p-0 m-0 h-100'>
            {
              // contents[default_content_idx]?.subCategory === 'GalleryVideos'
              contents[default_content_idx]?.category?.includes('Video')
              ? <ContentSeoVideoTabs/>
              : <ContentSeoImageTabs/>
            }
          </Col>
        </Row>
      </div>
      <div className="special-sm-device w-100 ">
        <div
          className="w-100 d-flex justify-content-center align-items-center mobile-seo-container"
          style={{
            paddingTop: !isInfo ? 20 : 10
          }}
        >
          <Row gutter={32} className='custom-carousel'>
            <Col span={0} sm={0} md={1} lg={1} className='px-0 special-lg-md-device'>
              <button
                className='carousel-bt'
                type='button'
                // disabled={!contents?.length}
                onClick={() => { 
                  playVideo();
                  dispatch(setShowingSaveButton(true));
                  slider.current.prev();
                }}
              >
                <img src={carousel_prev} alt="prev" />
              </button>
            </Col>
            {
              !isInfo
                ?
                <Col span={24} sm={24} md={24} lg={24} className='px-0'>
                  {
                    contents[default_content_idx]?.id
                    ? (
                      <Carousel
                        ref={slider}
                        interval={null}
                        indicators={false}
                        nextIcon={false}
                        prevIcon={false}
                        defaultActiveIndex={default_content_idx}
                        onSlide={(e: any) => {
                          navigate(`${route_path}?${activeFolder}=contents&idx=${e}`);
                          dispatch(setDefaultContentIdx(e));
                        }}
                      >
                        {
                          contents?.length
                          ? contents?.map((item: any, index: number) => {
                            return (
                              <Carousel.Item key={index}>
                                {/* <p>{getContentUrl(item)}</p> */}
                                
                                {
                                  // item?.subCategory === 'GalleryVideos'
                                  item?.category?.includes('Video')
                                  ? (
                                    <div className='content-carousel-container'>
                                      <video
                                        ref={videoRef}
                                        className="carousel-video"
                                        // src={item?.videoUrl}
                                        // src={currentContentUrl?.replaceAll("@", "/") || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/MehediClapup3_12.jpg?X-Amz-Expires=35997&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T094129Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Signature=077bd19e3eb91675a8d639ee0a0a39beedd3e288611f0522109ecfa6ca7f1a46"}
                                        src={currentContentUrl || item?.presignedUrl?.replaceAll("@", "/")} // one by one
                                          // src={item?.videoUrl || 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'}
                                        onLoadedMetadata={handleVideoLoaded}
                                        controls
                                      />
                                      {/* <iframe className="carousel-video" src={item?.videoUrl || "https://www.youtube.com/embed/lsd-sl2WBic?si=6HsrrilLPc0r2paO"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen /> */}
                                      {/* <ContentSeoVideoTabs/> */}
                                    </div>
                                  ) : (
                                    <div className='content-carousel-container'>
                                      <img className="carousel-image" 
                                        // src={item?.imageUrl || "https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/mmhk40_13.png?X-Amz-Expires=604796&x-response-content-disposition=attachment%3B%20filename%3D%22mmhk40_13.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0ad77b3401369b73425d76c732dcf41e%2F20231130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231130T071449Z&X-Amz-SignedHeaders=host&X-Amz-Signature=c2b566b604a39dfc86d662ff959a3a0248c6e49097d52f30c8c8752f05cdd54f"} 
                                        src={currentContentUrl || item?.presignedUrl?.replaceAll("@", "/")} 
                                        // src={item?.displayPresignedUrl} 
                                        // src={currentContentUrl} // one by one 
                                        alt={item?.contentFileName} 
                                      />
                                      {/* <ContentSeoImageTabs/> */}
                                    </div>
                                  )
                                }
                              </Carousel.Item>
                            );
                          })
                          : <Spinner animation="border" variant="dark" />
                          // : <Spinner animation="grow" variant="info" />
                          
                        }
                      </Carousel>
                    ) : null
                  }
                </Col>
                : null
            }
            <Col span={0} sm={0} md={1} lg={1} className='px-0 special-lg-md-device'>
              <button
                className=' carousel-bt'
                type='button'
                // disabled={!contents?.length}
                onClick={() => {
                  playVideo();
                  dispatch(setShowingSaveButton(true));
                  slider?.current?.next();
                }}
              >
                <img src={carousel_next} alt="next" />
              </button>
            </Col>
            {
              isInfo
                ? 
                  <Col span={24} sm={24} md={24} lg={24} className='p-0 m-0'>
                    {
                      // contents[default_content_idx]?.subCategory === 'GalleryVideos'
                      contents[default_content_idx]?.category?.includes('Video')
                      ? <ContentSeoVideoTabs/>
                      : <ContentSeoImageTabs/>
                    }
                  </Col>
                : null
            }
          </Row>
        </div>
        <div className='gallery-info-bottom-tabs'>
          <div
            className={`preview-info${!isInfo ? "-active" : ""}`}
            onClick={() => setIsInfo(false)}
            >
            <img src={preview_media} alt="preview-media" />
          </div>
          <div
            className={`preview-info${isInfo ? "-active" : ""}`}
            onClick={() => setIsInfo(true)}
          >
            <img src={preview_info} alt="preview-info" />
          </div>
        </div>
      </div>
      
      
    </React.Fragment>
  )
}
