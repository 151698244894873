import React, { useEffect } from 'react';
import video_icon from '../../../../../assets/icons/settings/embed-video-icon.svg';
import add_media_icon from '../../../../../assets/icons/settings/add-media-icon.svg';
import delete_icon from '../../../../../assets/icons/settings/biopage/delete-icon.svg';
import '../../../LinkManagement.scss';
import { useDispatch, useSelector } from 'react-redux';
import ImageRecords from './ImageRecords';
import {
    getFirstTimeEmbedImageRecords,
    // getEmbedImageRecords, resetEmbedRecords
} from '../../../../Settings/BioPageSettings/bioPageReducer';
import { modifyString } from '../../../../../utils/mediaService';

type ImageProps = {
    id?: number;
    label?: "";
    displayText?: "";
    logoName?: '';
    logo?: any;
    children?: [];
    link_item?: any;
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const EmbedImageForm: React.FC<ImageProps> = ({ link_item, id, displayText, label, logoName,  onCancel, onParentCallback }) => {
    const {
        embed_image_records,
        // embed_image_next_date
    } = useSelector((state: any) => state?.bioPageSettingState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    const dispatch = useDispatch();
    const [curDisplayText, setCurDisplayText] = React.useState(displayText || "");
    const [curLabel, setCurLabel] = React.useState(label || "");
    // const [curChildren, setCurChildren] = React.useState<any>(children || []);
    const [curChildren, setCurChildren] = React.useState<any>([]);

    const [isAddingImage, setIsAddingImage] = React.useState(false);
    const [image, setImage] = React.useState<any>(null);
    

    useEffect(() => { 
        // console.log({ embed_image_records });
        if (!curChildren?.length && embed_image_records?.length) {
            setCurChildren(embed_image_records?.filter((item: any) => item.selected === 1));
        }
    }, [embed_image_records?.length]);

    useEffect(() => {
        // console.log({ id });
        if (id) {
            dispatch(getFirstTimeEmbedImageRecords({ id, workspaceId: logged_in_user_secret_data?.wuid }));
        } else {
            dispatch(getFirstTimeEmbedImageRecords({ id: "", workspaceId: logged_in_user_secret_data?.wuid }));
            // setTimeout(() => , 1000);
        }
    }, [id, isAddingImage]);
        
    const handleSubmit = () => {
        const contentIdsByComma: any = curChildren?.map((child: any) => child?.id).join(', ');
        
        const data = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
            displayText: curDisplayText,
            id: link_item?.id || 0,
            linkUrl: '',
            label: curLabel,
            // // iconUrl: image?.base64Url || logo, // we have to work in future
            iconUrl: link_item?.iconUrl || "",
            iconName: image?.name || logoName,
            parentId: 0,
            linkType: 5,
            mode: link_item ? "update" : "insert",
            linkCategory: "customlinks",
            mediaType: "images",
            contentIds: contentIdsByComma,
            videoLink: '',
            contentId: link_item?.contentId || "",
            uniqueNo: link_item?.uniqueNo || null,
        };

        onParentCallback && onParentCallback(data, image?.file);
    };
    
    
    const handleUpdate = (curChild: any) => {
        setCurChildren([...curChildren, curChild]);
        setIsAddingImage(false);
    };

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e?.target?.files[0];
        const { name }: any = file || {};
        // console.log({path});
        // setImageFile(file);
        setImage({file, name: name?.replaceAll(" ", "")});

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // console.log({base64Url, name});
        //     setImage({base64Url, name});
            
        // }).catch((error: any) => {
        //     console.log({ error });
        //     dispatch(setErrorMessage("Something went wrong while converting image to base64"))
        // });
        
    }
    
    const handleDeleteChild = (id: any) => {
        const filteredChildren = curChildren.filter((child: any) => child.id !== id);
        setCurChildren(filteredChildren);
    };

    const handleChange = (e: any, setState: any) => {
        setState(e.target.value);
    };

    return (
        <div>
            {
                isAddingImage 
                ?   <ImageRecords
                        addedMedia={curChildren}
                        handleUpdate={handleUpdate}
                        setIsAddingImage={setIsAddingImage}
                    />
                :   <div className='form-link-container form-component' 
                        style={{
                            height: curChildren?.length > 2 ? '55vh': '100%',
                        }}
                    >
                        <div className='scroll-hover'>
                            <form
                                id='link-form'
                                className='form'
                                style={{
                                    padding: '3% 3% 3% 3%',
                                }}
                            >
                                <div
                                    className='input-group'
                                >
                                    <input
                                        type="text" name={"displayText"}
                                        autoFocus={true}
                                        autoComplete='off'
                                        autoSave='off'
                                        value={curDisplayText || ""}
                                        onChange={(e: any) => handleChange(e, setCurDisplayText)}
                                        placeholder={"Enter the Display Text"}
                                        style={{margin: '0 0 2.5% 0'}}
                                        
                                    />
                                </div>

                                <div
                                    className='input-group'
                                >
                                    <input
                                        type="text" name={"label"}
                                        autoComplete='off'
                                        autoSave='off'
                                        onChange={(e: any) => handleChange(e, setCurLabel)}
                                        value={curLabel || ""}
                                        placeholder={"Enter the label"}
                                        style={{margin: '0 0 2.5% 0'}}
                                    />
                                </div>
                                
                                <div
                                    className='input-group'
                                >
                                    <input
                                        type="text" name={"logo"}
                                        autoComplete='off'
                                        autoSave='off'
                                        readOnly={true}
                                        placeholder={"Upload the link icon"}
                                        style={{margin: '0 0 2.5% 0'}}
                                        value={image?.name || logoName}
                                    />
                                    <input
                                        // disabled={image ? false : true}
                                        id="image"
                                        type="file"
                                        accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                                        onChange={(e: any) => {
                                            // console.log({ img: e?.target?.files[0] });
                                            // setImage(e?.target?.files[0]?.name);
                                            handleImageChange(e); 
                                        }}
                                        className="form-input-field d-none w-100" placeholder="Biopage Image"
                                    />
                                    <label className='image-label' htmlFor="image">Upload</label>
                                </div>
                            </form>

                            <button
                                className='add-video-button'
                                onClick={() => {
                                    setIsAddingImage(true);
                                }}
                            >
                                <span>Add Image Files</span>
                                <img src={add_media_icon} alt="add-media-icon" className={``} />
                            </button>
                            {
                                curChildren?.length > 0 
                                ? <div className='child-list-container'>
                                    {
                                        curChildren?.map((child: any, index: number) => { 
                                            return (
                                                <div key={index} className='child-item'>
                                                    <div className='input-container'>
                                                        <div className='logo-text-label-container'>
                                                            <div className='logo-text'>
                                                                <img
                                                                    src={video_icon}
                                                                    alt="item-icon"
                                                                    className={``}
                                                                />
                                                                {/* <p className='input-value'>{`video-${(index+1)}.jpg`}</p> */}
                                                                <p className='input-value'>{modifyString(child?.contentFileName || child?.fileName)}</p>
                                                            </div>
                                                            {/* <p>{"Label"}</p> */}
                                                        </div>
                                                        <div className='edit-delete-container'>
                                                            {/* <img
                                                                src={edit_icon}
                                                                alt="edit-icon"
                                                                className={``}
                                                                onClick={() => {
                                                                    // setCurChild(child);
                                                                    // setCurDisplayText(child.displayText);
                                                                    // setCurLabel(child.label);
                                                                    // setCurYouTubeLink(child.youtubeLink);
                                                                    // setImage({ base64Url: child.logo, name: child.logoName });
                                                                }}
                                                            /> */}
                                                            <img
                                                                src={delete_icon}
                                                                alt="delete-icon"
                                                                className={``}
                                                                onClick={() => {
                                                                    handleDeleteChild(child?.id);
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>
            }

            <div className='form-button-container'
                style={{
                    paddingRight: curChildren?.length > 2 ? '1%': '0',
                }}
            >
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >
                    {
                        id ? "Cancel" : "No, Cancel"
                    }
                </button>
                <button
                    disabled={
                        // (curChildren?.length && curLabel && curDisplayText && (image?.name || logoName))
                        (curLabel && curDisplayText && (image?.name || logoName))
                        ? false
                        : true
                    }
                    className='create-button'
                    onClick={() => handleSubmit()}
                    type='button'
                >
                    {
                        id ? "Save" : "Yes, Create"
                    }
                </button>
            </div>
        </div>
    )
};

export default EmbedImageForm;

