import React from 'react';
// import moment from 'moment';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import SummaryList from './ReviewSummary/SummaryList';
import ReviewSummary from './ReviewSummary/ReviewSummary';
import ReviewHeader from './ReviewHeader/ReviewHeader';

const VideoDetails = ({ workspaceSettings, getImage, conversation, unifiedDetailsData }: any) => {
  // const { reviewerDisplayName, reviewerPhoteUrl, reviewComment, provider, tag, starRating, likeCount, retweetCount, locationName, recieverCompany, mentionedbyUserEmailId, requestedDate, createdOn } = conversation || {};
  // const {reviewer, location, page, properties, details} = unifiedDetailsData || {};
  
  console.log({ conversation, unifiedDetailsData });
  // const {ResponseTime, RequestedDate, SubmittedOn, repliedBy, repliedOn, reviewedOn, MentionedOn, requestorEmail} = details || {};


  return (
    <React.Fragment>
      <div className="conversation-details">
        <ReviewHeader
          tabs={["General",]}
          handleChangeTab={(data: any) => console.log(data)}
          value={"General"}
        />
        {/* <div className='details-tab-header'>
          <div className="tab tab-active">General</div>
        </div> */}
        <div className="details-body">
          <ReviewSummary
            userName={conversation?.reviewerDisplayName}
            userImage={null} // conversation?.reviewerPhoteUrl
            averageRating={unifiedDetailsData?.AverageRating}
            provider={conversation?.provider}
            value_1={Number(unifiedDetailsData?.Days || unifiedDetailsData?.DaysDifference || 0)}
            value_2={Number(unifiedDetailsData?.Questions || unifiedDetailsData?.ReviewsCount || 0)}
            value_3={Number(unifiedDetailsData?.Replied || unifiedDetailsData?.AverageRating || 0)}
            title_1={"Days"}
            title_2={"Reviews"}
            title_3={"Rating"}
          />
              
          <ReviewSummary
            userName={workspaceSettings?.workspaceName}
            userImage={workspaceSettings?.logo || getGenerateApiImageByName(workspaceSettings?.workspaceName)}
            averageRating={unifiedDetailsData?.reviewRating}
            provider={conversation?.provider}
            value_1={Number(unifiedDetailsData?.LocationQuestionCount || unifiedDetailsData?.totalReviews || 0)}
            value_2={Number(unifiedDetailsData?.Today || unifiedDetailsData?.ReviewsTodayCount || 0)}
            value_3={Number(unifiedDetailsData?.unAnswered || unifiedDetailsData?.reviewRating || 0)}
            title_1={"Reviews"}
            title_2={"Today"}
            title_3={"Rating"}
          />
              
              
          <SummaryList
            list_data={{
              provider: conversation?.provider,
              tag: conversation?.tag,
              type: "Video Testimonial",
              to: workspaceSettings?.workspaceName,
              tag_label: "Reviewed on",
              createdOn: conversation?.createdOn,
              repliedBy: conversation?.reviewerDisplayName || "Unknown User",
              repliedOn: conversation?.reviewReplyUpdatedon,
            }}
          />
              
        </div>
      </div>
      {/* Mobile */}
      {/* <div className="w-100 h-100 conversation-details-mobile">
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <div>
                      <span className="user_name">
                        {reviewerDisplayName || "Client"}
                      </span>
                      <span className="rating-social-email">
                        <div className='d-flex flex-row align-items-center'>
                          <div>
                            {Array.from(
                              Array(
                                Math.floor(parseFloat(unifiedDetailsData?.AverageRating) || 1)
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            <span className="ms-2">
                              {(Number(unifiedDetailsData?.AverageRating) || "0") + ".0"}
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Reviewer_daysFromFirstReview || 0)}
                    </span>
                    <span className="title">
                      {"Days"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.ReviewCount || 0)}
                    </span>
                    <span className="title">
                      {"Reviews"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.AverageRating || 0)}
                    </span>
                    <span className="title">
                      {"Avg. Rating"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      <div className='d-flex flex-row align-items-center'>
                        {Array.from(Array(Math.floor(parseFloat(unifiedDetailsData?.Reviewer_AverageRating)) || 1).keys()).map((item: any, index: number) => {
                          return (
                            <img
                              className="start"
                              key={index}
                              src={start}
                              alt="star"
                            />
                          );
                        })}
                        <span className="d-block ms-2">
                          {(Number(unifiedDetailsData?.Reviewer_AverageRating) ||  "0")}
                        </span>
                      </div>
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TotalRequestCount || 0)}
                    </span>
                    <span className="title">
                      {"Requests"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TotalResponseCount || 0)}
                    </span>
                    <span className="title">
                      {"Responses"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Reviewer_AverageRating || 0)}
                    </span>
                    <span className="title">
                      {"Resp. Rate"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
            unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Video Testimonial"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        <span>N/A</span>
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    Requested:
                  </span>
                  <span>
                    Responded:
                  </span>
                  <span>Res. Time:</span>
                </div>
                <div className="req-res-value">
                  <span>
                    {conversation?.createdOn
                      ? moment(conversation?.createdOn).format("ll") +
                        " " +
                        moment(conversation?.createdOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {conversation?.reviewReplyUpdatedon
                      ? moment(conversation?.reviewReplyUpdatedon).format("ll") +
                        " " +
                        moment(conversation?.reviewReplyUpdatedon).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div> */}

      {/* Laptop */}
      {/* <div className="w-100 h-100 conversation-details-lg">
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <div>
                      <span className="user_name">
                        {reviewerDisplayName ||
                          "Client"}
                      </span>
                      <span className="rating-social-email">
                        <div className='d-flex flex-row align-items-center'>
                          <div>
                            {Array.from(
                              Array(
                                Math.floor(parseFloat(unifiedDetailsData?.AverageRating) || 1)
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            <span className="ms-2">
                              {(Number(unifiedDetailsData?.AverageRating) || "0") + ".0"}
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Reviewer_daysFromFirstReview || 0)}
                    </span>
                    <span className="title">
                      {"Days"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.ReviewCount || 0)}
                    </span>
                    <span className="title">
                      {"Reviews"}
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.AverageRating || 0)}
                    </span>
                    <span className="title">
                      {"Avg. Rating"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      <div className='d-flex flex-row align-items-center'>
                        {Array.from(Array(Math.floor(parseFloat(unifiedDetailsData?.Reviewer_AverageRating)) || 1).keys()).map((item: any, index: number) => {
                          return (
                            <img
                              className="start"
                              key={index}
                              src={start}
                              alt="star"
                            />
                          );
                        })}
                        <span className="d-block ms-2">
                          {(Number(unifiedDetailsData?.Reviewer_AverageRating) ||  "0")}
                        </span>
                      </div>
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TotalRequestCount || 0)}
                    </span>
                    <span className="title">
                      {"Requests"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.TotalResponseCount || 0)}
                    </span>
                    <span className="title">
                      {"Responses"}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(unifiedDetailsData?.Reviewer_AverageRating || 0)}
                    </span>
                    <span className="title">
                      {"Resp. Rate"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
              unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Video Testimonial"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        <span>N/A</span>
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    Requested:
                  </span>
                  <span>
                    Responded:
                  </span>
                  <span>Res. Time:</span>
                </div>
                <div className="req-res-value">
                  <span>
                    {conversation?.createdOn
                      ? moment(conversation?.createdOn).format("ll") +
                        " " +
                        moment(conversation?.createdOn).format("LT")
                      : // new Date(MentionedOn).toDateString() + " " +
                        // new Date(MentionedOn).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span>
                    {conversation?.reviewReplyUpdatedon
                      ? moment(conversation?.reviewReplyUpdatedon).format("ll") +
                        " " +
                        moment(conversation?.reviewReplyUpdatedon).format("LT")
                      : // new Date(replied_time).toDateString() + " " +
                        // new Date(replied_time).toLocaleTimeString()
                        " --- "}
                  </span>
                  <span className="d-block">
                    {getDateDifference(conversation?.createdOn, conversation?.reviewReplyUpdatedon)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div> */}
    </React.Fragment>
  )
};

export default VideoDetails;
