import React, { useEffect } from 'react';
import search_icon from '../../../../../assets/icons/settings/user-management/search-icon.svg';
import arrow_left_icon from '../../../../../assets/icons/settings/arrow-left-icon.svg';
import video_icon from '../../../../../assets/icons/settings/embed-video-icon.svg';
import add_media_icon from '../../../../../assets/icons/settings/add-media-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { getEmbedVideoRecords } from '../../../../Settings/BioPageSettings/bioPageReducer';
import { modifyString } from '../../../../../utils/mediaService';
type VideoRecordProps = {
    addedMedia?: any;
    handleUpdate: (media: any) => void;
    setIsAddingVideo: (isAddingVideo: boolean) => void;
};

const VideoRecords: React.FC<VideoRecordProps> = ({addedMedia, handleUpdate, setIsAddingVideo }) => { 
    const { embed_video_records: allMedia, embed_video_next_date } = useSelector((state: any) => state?.bioPageSettingState);
    const [searchText, setSearchText] = React.useState<string>('');
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    
    const dispatch = useDispatch();
    // const [allMedia, setAllMedia] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    useEffect(() => {
        // console.log({data: embed_video_records, embed_video_next_date});
        // setAllMedia(embed_video_records);
        setIsLoading(false);
    }, [allMedia?.length]);

    const fetchMoreData = () => {
        setIsLoading(true);
        if (!embed_video_next_date || embed_video_next_date?.length === 0) {
            setHasMore(false);
            setIsLoading(false);
            return;
        }
        setTimeout(() => {
            // setAllMedia(allMedia.concat(allMedia));
            const data_object: any = {
                isForScrolling: true,
            };

            if (searchText) {
                data_object.searchText = searchText;
                data_object.settingsId = "";
            }

            dispatch(getEmbedVideoRecords(data_object, embed_video_next_date[0], logged_in_user_secret_data?.wuid));
            setIsLoading(false);
        }, 1500);
    };

    useEffect(() => {
        // console.log({ searchText });
        
        if (searchText?.length) {
            const data_object = {
                settingsId: "",
                searchText: searchText,
                isForScrolling: false,
            };
            dispatch(getEmbedVideoRecords(data_object, null, logged_in_user_secret_data?.wuid));
            // const filteredData = embed_image_records?.filter((item: any) => {
            //     return item?.contentFileName?.toLowerCase().includes(searchText?.toLowerCase());
            // });
            // setAllMedia(filteredData);
        }
    }, [searchText]);

    // Define a custom debounce function
    const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
        let timerId: NodeJS.Timeout;
        return (...args: Parameters<F>) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            func(...args);
        }, delay);
        };
    };

    // Create a debounced version of the setSearchText function
    const debouncedSetSearchText = debounce((value: string) => {
        setSearchText(value);
        if (value === '') {
            dispatch(getEmbedVideoRecords(null, null, logged_in_user_secret_data?.wuid));
        }
    }, 500); // Adjust the debounce delay as needed (in milliseconds)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value;
        debouncedSetSearchText(value);
    };

    return (
        <div className='form-link-container form-component' 
            style={{
                height: '55vh',
                // height: '100%',
            }}
        >
            <div className='back-container'>
                <img onClick={() => setIsAddingVideo(false)} src={arrow_left_icon} alt="back" />
                <Input 
                    // status="warning"
                    className='search-input'
                    onChange={handleInputChange}
                    placeholder="Search for contents"
                    prefix={<img src={search_icon} className='search-icon' alt='search-icon'/>} 
                    // prefix={<SearchOutlined className='search-icon' />} 
                />
            </div>
            {
                allMedia?.length
                 ? <>
                    <table className='table table-responsiv'
                        style={{ 
                            // height: '46vh',
                            margin: 0
                        }}
                    >
                        <thead>
                            <tr style={{ paddingRight: '1%'}}>
                                <th>Name</th>
                                <th className='text-center'>Size</th>
                                <th className='text-center'>Last Update</th>
                                <th className='text-end'>Add</th>
                            </tr>
                        </thead>

                    </table>
                    <InfiniteScroll
                        dataLength={allMedia?.length}
                        // className="border"
                        scrollThreshold={0.9}
                        style={{ overflow: "scroll" }}
                        next={() => {
                            console.log(
                                `prev -> ${allMedia?.length}, next -> ${allMedia?.length + 20}`
                            );
                            fetchMoreData();
                        }}
                        inverse={false}
                        hasMore={hasMore}
                        loader={
                            isLoading && allMedia?.length ? (
                                <div className="d-flex justify-content-center align-items-center mt-1">
                                <Spinner
                                    size="sm"
                                    animation="border"
                                    variant="primary"
                                />
                                </div>
                            ) : null
                        }
                        scrollableTarget="scrollableDiv"
                        // endMessage={
                        //   <p style={{ textAlign: "center" }}>
                        //     <b>Yay! You have seen it all</b>
                        //   </p>
                        // }

                        height={"42.5vh"} // 43.5vh
                    >
                    <table className='table table-responsive mb-0'
                        // style={{ height: '45vh', }}
                    >
                        <thead></thead>
                        <tbody className='child-list-table table-hove'>
                            {
                                allMedia?.map((media: any, index: number) => { 
                                    return (
                                        <tr key={index} className='child-item'>
                                            <td className='logo-text'>
                                                <img
                                                    src={video_icon}
                                                    alt="item-icon"
                                                    className={``}
                                                />
                                                {/* <p>{`video-${(index+1)}.mp4`}</p> */}
                                                <p>{modifyString(media?.contentFileName)}</p>
                                            </td>
                                            <td className='text-center'>
                                                {/* <p>{"2.8 MB - size"}</p> */}
                                                <p>{media?.contentSize || "N/A"}</p>
                                            </td>
                                            <td className='text-center'>
                                                <p>{new Date(media?.updatedOn || undefined).toDateString()}</p>
                                            </td>
                                            <td className='text-end'>
                                                {
                                                    !addedMedia?.find((item: any) => item?.id === media?.id)?.id
                                                    // media?.selected === 0
                                                    ? <img
                                                        src={add_media_icon}
                                                        alt="add-icon"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            handleUpdate(media);
                                                        }}
                                                    />
                                                    : null
                                                }
                                                {/* <img
                                                    src={add_media_icon}
                                                    alt="add-icon"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleUpdate(media);
                                                    }}
                                                /> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                                
                        </tbody>

                    </table>
                    </InfiniteScroll>
                </>
                : <div className='h-75 d-flex flex-column align-items-center justify-content-center'>
                    <p className='text-center'>No videos found</p>
                </div>
            
            }
        </div>
    );
}

export default VideoRecords;