import React, { CSSProperties } from 'react';
import './CircleLoader.scss';

interface CircleLoaderProps {
  size?: string; // Diameter of the loader
  borderWidth?: string; // Border thickness
}

const CircleLoader: React.FC<CircleLoaderProps> = ({ size = '50px', borderWidth = '6px' }) => {
  const customStyle: CSSProperties = {
    '--loader-size': size,
    '--loader-border-width': borderWidth,
  } as CSSProperties;

  return <div className="circle-loader" style={customStyle}></div>;
};

export default CircleLoader;
