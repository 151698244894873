import React from 'react';
import { Input } from 'antd';
import { RootState } from '../../../../store';
import { updateContentBeforeSaving } from '../../../ContentComponents/reducer';
import { useDispatch, useSelector } from 'react-redux';

export default function BiopageTab() {
  const dispatch = useDispatch();
  const { updatable_content }: any = useSelector((state: RootState) => state?.contentState);

  // const content = folder_contents[default_content_idx];
  // const content_payload = {
  //   contentId: content?.id,
  //   sEO_Title: content?.sEO_Title,
  //   sEO_UrlSlug: content?.sEO_UrlSlug,
  //   sEO_Desc: content?.sEO_Desc,
  //   bioPage_Title: content?.bioPage_Title,
  //   bioPage_Caption: content?.bioPage_Caption,
  //   bioPage_Story: content?.bioPage_Story,
  //   properties_Keywords: content?.properties_Keywords,
  // };
  // console.log({updatable_content});
  return (
    <div className='biopage-seo-container scroll-content'>
      <div className='form-group'>
        <label className="form-label">Image Title</label>
        <Input
          className='form-input'
          placeholder='Add the Title of the Image'
          onChange={(e: any) => {
            const value = e.target.value;
            dispatch(updateContentBeforeSaving({
              key: "bioPage_Title",
              value,
            }))
          }}
          value={updatable_content?.bioPage_Title}
        />
      </div>
      <div className='form-group'>
        <label className="form-label">Image Caption</label>
        <Input
          className='form-input'
          placeholder='Add the Caption'
          onChange={(e: any) => {
            const value = e.target.value;
            dispatch(updateContentBeforeSaving({
              key: "bioPage_Caption",
              value,
            }))
          }}
          value={updatable_content?.bioPage_Caption}
        />
      </div>
      <div className='form-group'>
        <label className="form-label">Your Story</label>
        <Input.TextArea
            className='form-textarea'
            rows={6}
            placeholder='Add your story about the image'
            onChange={(e: any) => {
              const value = e.target.value;
              dispatch(updateContentBeforeSaving({
                key: "bioPage_Story",
                value,
              }))
            }}
            value={updatable_content?.bioPage_Story}
        />
      </div>
    </div>
  )
}
