import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
import Constants from '../../constants';
import { generateAccessToken } from '../../profile/reducer';
import { api_url, api_version } from '../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../unified/reducer';
import { getFirstTimeCampaignList, getFirstTimeTemplateList, getTemplateDetailsById, injectTemplateIdAfterCreatingTemplate, setCampaignDetailsPayload, setFirstTimeCampaignList, setFirstTimeTemplateList, setSearchTemplateData, setTemplateDetailsById, setTimeZones, toggleIsAlreadySaved, toggleIsSubmittedTemplate } from './reducer';
import { normalizeString } from '../../utils/convertingFunction';
import { getKeyValue } from '../../utils/indexedDBManagementService';

// Create Content
export const uploadBotFileData = async(data: any) => {
    try {
        const {
            contentFile,
            // isForBioPage,
            contentType
        } = data;
        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        const logged_in_user_secret_data = await getKeyValue("logged_in_user_secret_data");
        const common_payload: any = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
        };

        const url = api_url + `/api/${api_version}/gallery/CreateContent`;
        const { name: fileName, size, type }: any = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        let content_payload: any = {
            contentType: contentType || "AIChatBotFiles",
            folderPath: contentType || "AIChatBotFiles",
            subCategory: contentType || "AIChatBotFiles",
            contentText: "",
            isFolder: "0",
            contentFileName: fileName.replaceAll(" ", ""),
            fileSize,
            isAvailableinGallery: false,
            isCreationCompleted: true,
            ...common_payload,
        };

        if (type?.includes("video")) {
            content_payload["contentType"] = "AIChatBotFiles";
            content_payload["folderPath"] = 'AIChatBotFiles';
            content_payload["subCategory"] = "AIChatBotFiles";
        }

        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const {
            id: contentId, folderPath,
            contentTypeId, workspaceId,
            url: aws_pre_assigned_url,
            contentFileName, uniqueNo
        } = content_response;
        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        // const new_image_file_name = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "pdf") {
                mimetype = "application/pdf";
            } else if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            } else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            } else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            } else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            } else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            } else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            } else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            } else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            } else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            } else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }
        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        });

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }
        
        const cloudFlareR2Path = `${folderPath}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + `/api/${api_version}/gallery/UpdateCloudFlareR2UploadStatusAfterContentCreation`;
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId, contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }
        
        // const stage_url = api_url + `/api/${api_version}/gallery/UpdateContentStages`;
        // const { data: publish_stage_response, status: stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         workspaceId,
        //         contentId,
        //         stageName: "publish",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );
        // if (stage_status !== 200) {
        //     return { success: false, message: "Update stage error" };
        // }
        

        // const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         workspaceId,
        //         contentId,
        //         stageName: "biopage",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );


        const ai_configure_payload = {
            workspaceId: common_payload?.workspaceId,
            clapupUserId: common_payload?.clapupUserId,
            contentId: contentId,
            chatBotType: "biopage",
            chatBotId: data?.aiChatBotId
        };

        const open_ai_config_url = api_url + `/api/${api_version}/AIChatBot/ConfigureAIChatBot`;
        const { data: open_ai_response, status: open_ai_status }: any = await axios.post(
            open_ai_config_url,
            ai_configure_payload,
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // console.log({open_ai_response, open_ai_status});
        
        if (open_ai_status !== 200) {
            return { success: false, message: "open ai error" };
        }
        
        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
            contentId
        };

        return {
            success: true,
            data: currentContent,
            message: "Published in Biopage"
        };
        

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Update Content
export const updateBotFileData = async(data: any) => {
    try {
        const logged_in_user_secret_data = await getKeyValue("logged_in_user_secret_data");
        const { contentFile, contentType, contentId, uniqueNo, aiChatBotId } = data;

        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        const common_payload: any = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
        };
        const url = api_url + `/api/${api_version}/gallery/UploadNewVersion`;
        const { name: fileName, size, type } = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        const content_payload: any = {
            ...common_payload,
            contentId,
            contentFileName: fileName.replaceAll(" ", ""),
            contentSize: fileSize
        };
        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const { url: aws_pre_assigned_url } = content_response;
        const contentFileName = fileName.replaceAll(" ", "");

        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "pdf") {
                mimetype = "application/pdf";
            } else if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            }
            else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            }
            else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            }
            else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            }
            else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            }
            else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            }
            else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            }
            else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }
        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        })

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }
        const folder_path = contentType || "ImageAssets";

        const cloudFlareR2Path = `${folder_path}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + `/api/${api_version}/gallery/UpdateContentAfterNewVersionUpload`;
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId: content_payload?.workspaceId , contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }

        // const update_stage_payload = {
        //     ...common_payload,
        //     contentId,
        //     stageName: "publish",
        //     stageValue: "1"
        // };

        // const stage_url = api_url + `/api/${api_version}/gallery/UpdateContentStages`;
        // const { data: publish_stage_response, status: stage_status }: any = await axios.post(
        //     stage_url,
        //     update_stage_payload,
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );

        // if (stage_status !== 200) {
        //     return { success: false, message: "Update stage error" };
        // }

        // const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
        //     stage_url,
        //     {
        //         ...common_payload,
        //         contentId,
        //         stageName: "biopage",
        //         stageValue: "1"
        //     },
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // );

        // if (bio_page_stage_status !== 200) {
        //     return { success: false, message: "Update bio-page stage error" };
        // }

        const ai_configure_payload = {
            workspaceId: common_payload?.workspaceId,
            clapupUserId: common_payload?.clapupUserId,
            contentId: contentId,
            chatBotType: "biopage",
            chatBotId: aiChatBotId
        };

        const open_ai_config_url = api_url + `/api/${api_version}/AIChatBot/ConfigureAIChatBot`;
        const { data: open_ai_response, status: open_ai_status }: any = await axios.post(
            open_ai_config_url,
            ai_configure_payload,
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // console.log({open_ai_response, open_ai_status});
        
        if (open_ai_status !== 200) {
            return { success: false, message: "open ai error" };
        }
        

        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
        };

        return {
            success: true,
            data: currentContent
        };

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Worker Saga:
function* getFirstTimeTemplateListData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/GetTemplates?workspaceId=${logged_in_user_secret_data?.wuid}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            const { templateDetails } = data;
            yield put(setFirstTimeTemplateList(templateDetails));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* getSearchTemplateListData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/SearchTemplates`;
        const { data, status }: any = yield call(axios.post, url, action?.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            const { result } = data;
            yield put(setSearchTemplateData(result || []));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* updateTemplateDetailsBeforeSubmit(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const {
            toggle_template_edition: save_template_payload,
            variable_payload
        } = action.payload;

        const update_stage_payload = {
            templateId: save_template_payload?.templateId,
            templateDisplayName: save_template_payload?.templateDisplayName,
            templateName: save_template_payload?.templateName,
            templateCategory: save_template_payload?.templateCategory,
            workspaceId: save_template_payload?.workspaceId || logged_in_user_secret_data?.wuid,
            clapupUserId: save_template_payload?.clapupUserId || logged_in_user_secret_data?.cuid,
            templateHeaderType: save_template_payload?.templateHeaderType,
            templateHeader: save_template_payload?.templateHeader,
            templateBody: save_template_payload?.templateBody,
            templateFooter: save_template_payload?.templateFooter,
            language: save_template_payload?.language,
            isPromotionOptOut: save_template_payload?.isPromotionOptOut
        }
        // console.log({ variable_payload });
        // return;
        const save_template_url = api_url + `/api/${api_version}/WhatsApp/UpdateTemplate`;
        const { status }: any = yield call(axios.post, save_template_url, update_stage_payload,
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const variable_url = api_url + `/api/${api_version}/WhatsApp/SubmitTemplate`;
            const { status: variable_status }: any = yield call(axios.post, variable_url, variable_payload,
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log({ variable_response, variable_status });
            if (variable_status === 200) {
                yield put(getTemplateDetailsById({ templateId: save_template_payload?.templateId || update_stage_payload?.templateId }));
                yield put(toggleIsSubmittedTemplate(true));
                yield put(setSuccessMessage("Submitted successfully"));
            } else {
                yield put(setErrorMessage("Server Error!"));
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
        yield put(setErrorMessage("Server Error!"));
    }
};

// Worker Saga:
function* saveTemplateDetails(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const {
            toggle_template_edition: save_template_payload,
            call_to_action_templates: call_to_action_template_payload,
            quick_reply_buttons_template: reply_buttons_template_payload 
        } = action.payload;

        const checking_value_quick_reply_buttons = reply_buttons_template_payload?.find((btn: any) => !btn?.buttonText?.trim());
        if (checking_value_quick_reply_buttons) {
            yield put(setErrorMessage("Please fill all quick reply button text!"));
            return;
        }
        // console.log({reply_buttons_template_payload, call_to_action_template_payload, save_template_payload})
        // for dynamic call to action website url slug check
        const website_url_slug_object = call_to_action_template_payload?.find((btn: any) => btn?.urlType?.toLowerCase() === "dynamic");
        // `/api/${api_version}/WhatsApp/InsertTemplate";
        const insert_template_payload: any = {
            templateDisplayName: save_template_payload?.templateDisplayName,
            templateName: save_template_payload?.templateName,
            templateCategory: save_template_payload?.templateCategory,
            workspaceId: save_template_payload?.workspaceId || logged_in_user_secret_data?.wuid,
            clapupUserId: save_template_payload?.clapupUserId || logged_in_user_secret_data?.cuid,
            templateHeaderType: save_template_payload?.templateHeaderType,
            templateHeader: save_template_payload?.templateHeader,
            // templateBody: normalizeString(save_template_payload?.templateBody || ""),
            templateBody: save_template_payload?.templateBody || "",
            templateFooter: save_template_payload?.templateFooter,
            language: save_template_payload?.language,
            isPromotionOptOut: save_template_payload?.isPromotionOptOut,
        };
        const update_stage_payload: any = {
            templateId: save_template_payload?.templateId,
            ...insert_template_payload,
        }

        if (website_url_slug_object) {
            const { urlSlug } = website_url_slug_object;
            // insert_template_payload["templateCallToActionSlug"] = `${urlSlug.replace(/urlSlug/g, `{{${urlSlug}}}`)}`;
            insert_template_payload["templateCallToActionSlug"] = `templateCallToActionSlug  {{${urlSlug?.replace(/\{\{|\}\}/g, '')}}}`;
            // update_stage_payload["websiteUrlSlug"] = urlSlug;
        }else {
            insert_template_payload["templateCallToActionSlug"] = "";
        }
        console.log({ insert_template_payload, call_to_action_template_payload });
        // return;
        const save_template_url = api_url + (update_stage_payload?.templateId ? `/api/${api_version}/WhatsApp/UpdateTemplate` : `/api/${api_version}/WhatsApp/InsertTemplate`);
        const { data, status }: any = yield call(axios.post, save_template_url,
            update_stage_payload?.templateId
            ? update_stage_payload 
            : insert_template_payload,
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        // console.log({ data, status });
        if (status === 200) {
            const { templateId } = data;
            // /api/${api_version}/WhatsApp/InsertTemplateCallToAction
            // /api/${api_version}/WhatsApp/UpdateTemplateCallToAction
            // run the array call_to_action_template_payload which is an array of objects
            if (call_to_action_template_payload?.length) {
                const modifiedActionPayload: any[] = [];
                call_to_action_template_payload?.map((btn: any) => {
                    const { id, updateType, isUpdatable, actionType, buttonText, countryCode, phoneNumber, urlType, url, urlSlug } = btn;
                    updateType === "insert"
                    ?
                    modifiedActionPayload.push({
                        updateType,
                        id,
                        templateId: templateId || save_template_payload?.templateId,
                        actionType,
                        buttonText,
                        phoneNumber: countryCode + "-" + phoneNumber,
                        urlType,
                        url,
                        urlSlug: urlType === "Static" ? "" : urlSlug
                    })
                    : isUpdatable && modifiedActionPayload.push({
                        updateType,
                        id,
                        templateId: templateId || save_template_payload?.templateId,
                        actionType,
                        buttonText,
                        phoneNumber: countryCode + "-" + phoneNumber,
                        urlType,
                        url,
                        urlSlug: urlType === "Static" ? "" : urlSlug
                    })
                });
                // console.log({ modifiedActionPayload });
                const call_to_action_requests = modifiedActionPayload?.map((btn: any) =>
                    call(axios.post,
                        (api_url + (btn?.updateType === "update" ? `/api/${api_version}/WhatsApp/UpdateTemplateCallToAction` : `/api/${api_version}/WhatsApp/InsertTemplateCallToAction`)),
                        btn?.updateType === "update"
                        ?   {
                                id: btn?.id,
                                templateId: btn?.templateId || templateId,
                                actionType: btn?.actionType,
                                buttonText: btn?.buttonText,
                                phoneNumber: btn?.phoneNumber,
                                urlType: btn?.urlType,
                                url: btn?.url,
                                urlSlug:  btn?.urlSlug
                            }
                        :
                            {
                                templateId: btn?.templateId || templateId,
                                actionType: btn?.actionType,
                                buttonText: btn?.buttonText,
                                phoneNumber: btn?.phoneNumber,
                                urlType: btn?.urlType,
                                url: btn?.url,
                                urlSlug:  btn?.urlSlug

                            }
                        ,
                        {
                            headers: {
                                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                );
    
                // Execute all requests concurrently
                // const call_to_action_responses =
                yield all(call_to_action_requests);                
                // console.log({ call_to_action_responses });
            }

            // run the array reply_buttons_template_payload which is an array of objects
            if (reply_buttons_template_payload?.length) {
                const replyButtonsUrl = api_url + `/api/${api_version}/WhatsApp/UpdateTemplateQuickReply`;
                // Map each button payload with templateId
                const modifiedReplyPayload: any[] = [];
                reply_buttons_template_payload.map((btn: any) => {
                    const { id, buttonText, updateType, isUpdatable } = btn;
                    updateType === "insert"
                    ? modifiedReplyPayload.push({
                        id: 0,
                        templateId: templateId || save_template_payload?.templateId,
                        buttonText: buttonText,
                        updateType: updateType
                    })
                    : isUpdatable && modifiedReplyPayload.push({
                        id: id,
                        templateId: templateId || save_template_payload?.templateId,
                        buttonText: buttonText,
                        updateType: updateType
                    });
                });

                console.log({ modifiedReplyPayload });
                
    
                // Make POST requests for each button payload
                const reply_requests = modifiedReplyPayload?.map((btn: any) =>
                    call(axios.post, replyButtonsUrl, btn, {
                        headers: {
                            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                );
    
                // Execute all requests concurrently
                // const reply_responses =
                yield all(reply_requests);
                // console.log({ reply_responses})
            }
            yield put(getTemplateDetailsById({ templateId: templateId || update_stage_payload?.templateId }));
            yield put(toggleIsAlreadySaved(templateId || update_stage_payload?.templateId));
            yield put(injectTemplateIdAfterCreatingTemplate(templateId || update_stage_payload?.templateId));
            
        } 
        yield put(setSuccessMessage(`Saved Successfully`));
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* getTemplateDetailsDataById(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/GetTemplateById?templateId=`+action?.payload?.templateId;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            yield put(setTemplateDetailsById(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// getting template by id for the campaign selected template
export async function getSelectedTemplate(templateId: any) {
    try {
        const logged_in_user_secret_data = await getKeyValue("logged_in_user_secret_data");
        const { data, status }: any = await axios.get(api_url + `/api/${api_version}/WhatsApp/GetTemplateById?templateId=` + templateId,
            {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            return data?.templateDetails;
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        return null;
    }
}

// Worker Saga:
function* removeCallToActionTemplateData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/DeleteTemplateCallToAction`;
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            yield put(setSuccessMessage("Remove Successfully!"));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* removeQuickReplyTemplateData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const replyButtonsUrl = api_url + `/api/${api_version}/WhatsApp/UpdateTemplateQuickReply`;
        const { status }: any = yield call(axios.post, replyButtonsUrl, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            yield put(setSuccessMessage("Remove Successfully!"));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* removeTemplateData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/DeleteTemplate`;
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            yield put(getFirstTimeTemplateList());
            yield put(setSuccessMessage("Delete Successfully!"));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// ========= Campaign =========
// Worker Saga:
function* createCampaignData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { insert_campaign_payload, campaign_details } = action.payload;
        // console.log({combinedDateTimeString: insert_campaign_payload, campaign_details });
        // return;
        const url = api_url + `/api/${api_version}/WhatsApp/InsertCampaignMain`;
        const { data, status }: any = yield call(axios.post, url, insert_campaign_payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // const data: any = { campaignId: Number(new Date()) };
        // const status = 200;
        // console.log({ data, status });
        if (status === 200) {
            const { campaignId } = data;
            const { selected_contacts, non_used_variables }: any = campaign_details;
            if (selected_contacts?.length) {
                const contactUrl = api_url + `/api/${api_version}/WhatsApp/InsertCampaignDetails`;
                // Map each button payload with templateId
                const modifiedContactPayloads: any[] = [];
                selected_contacts?.map((contact: any) => {
                    const { phone_number, isSelected, selectable, header_url, ...rest } = contact;
                    const variableValues: any = non_used_variables?.filter((item: any) => item?.variableName !== "header_url")
                                                ?.map((variable: any) => variable?.variableValue && `${variable?.variableName}=${variable?.variableValue}`)
                                                .join(', ');
                
                    const header_url_data_from_non_used_variable = non_used_variables?.find((variable: any) => variable?.variableValue && variable?.variableName === "header_url");  
                    const contactValues: any = Object?.entries(rest)?.map(([key, value]) => `${key}=${value}`).join(', ');
                    const campaignValues = variableValues + ((variableValues && contactValues) ? (", " + contactValues) : (contactValues) ? (""+ contactValues) : "");
                    // console.log({variableValues, contactValues, campaignValues});
                    
                    modifiedContactPayloads.push({
                        campaignId: campaignId || campaign_details?.campaignId,
                        phoneNumber: phone_number,
                        campaignValues,
                        headerUrl: header_url_data_from_non_used_variable ? header_url_data_from_non_used_variable?.variableValue : header_url || "",
                    })
                    // updateType === "insert"
                    // ?   modifiedContactPayloads.push({
                    //         campaignId: campaignId || campaign_details?.templateId,
                    //         phoneNumber: phone_number,
                    //         updateType: updateType
                    // })
                    // :   isUpdatable && modifiedContactPayloads.push({
                    //         id: id,
                    //         templateId: templateId || save_template_payload?.templateId,
                    //         buttonText: buttonText,
                    //         updateType: updateType
                    // });
                });
                // return;
                // console.log({ modifiedReplyPayload });
                
    
                // Make POST requests for each button payload
                const contact_requests = modifiedContactPayloads?.map((contact: any) =>
                    call(axios.post, contactUrl, contact, {
                        headers: {
                            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                );
    
                // Execute all requests concurrently
                // const contact_responses =
                yield all(contact_requests);
                // console.log({ contact_responses})
            }
            yield put(setSuccessMessage("Created Successfully!"));
            yield put(getFirstTimeCampaignList());
            delay(2000);
            yield put(setCampaignDetailsPayload({}));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        yield put(setErrorMessage("Server Error!"));
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Worker Saga:
function* getCampaignListData(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/GetCampaignDetails?workspaceId=${logged_in_user_secret_data?.wuid}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            const { campaignDetails } = data;
            yield put(setFirstTimeCampaignList(campaignDetails));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};
// Worker Saga:
function* getTimeZones(action: any = {}): Generator<any> { 
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const url = api_url + `/api/${api_version}/WhatsApp/GetTimezoneList`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ data, status });
        if (status === 200) {
            yield put(setTimeZones(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
};

// Watcher Saga:
function* promotionsSaga() {
    
    yield all([
        // Template
        takeEvery(Constants.GET_FIRST_TIME_TEMPLATE_LIST, getFirstTimeTemplateListData),
        takeEvery(Constants.GET_SEARCH_TEMPLATE_LIST, getSearchTemplateListData),
        takeEvery(Constants.SAVE_TEMPLATE_DETAILS, saveTemplateDetails),
        takeEvery(Constants.REMOVE_TEMPLATE_DATA, removeTemplateData),
        takeEvery(Constants.REMOVE_CALL_ACTION_TEMPLATE, removeCallToActionTemplateData),
        takeEvery(Constants.REMOVE_QUICK_REPLY_TEMPLATE, removeQuickReplyTemplateData),
        takeEvery(Constants.GET_TEMPLATE_DETAILS_BY_ID, getTemplateDetailsDataById),
        takeEvery(Constants.UPDATE_TEMPLATE_DETAILS_BEFORE_SUBMIT, updateTemplateDetailsBeforeSubmit),
        // Campaign
        takeEvery(Constants.GET_TIME_ZONES, getTimeZones),
        takeEvery(Constants.INSERT_CAMPAIGN_DATA, createCampaignData),
        takeEvery(Constants.GET_FIRST_TIME_CAMPAIGN_LIST, getCampaignListData),
    ]);
}

export default promotionsSaga;