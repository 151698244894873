import Constants from "../../constants";

// template
export const getFirstTimeTemplateList = () => {
    return {
        type: Constants.GET_FIRST_TIME_TEMPLATE_LIST,
        payload: {}
    };
}

export const getTemplateList = () => {
    return {
        type: Constants.GET_TEMPLATE_LIST,
        payload: {}
    };
}

export const toggleUpdatedTemplateBySignalR = (data: any) => {

    return {
        type: Constants.TOGGLE_UPDATE_TEMPLATE_SIGNAL_R_REQUEST,
        payload: data
    };
}

export const appendTemplateListBySignalR = (data: any) => {

    return {
        type: Constants.APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_TEMPLATE,
        payload: data
    };
}

export const setFirstTimeTemplateList = (data: any) => {

    return {
        type: Constants.SET_FIRST_TIME_TEMPLATE_LIST,
        payload: data
    };
}

export const getSearchTemplateList = (data: any) => {

    return {
        type: Constants.GET_SEARCH_TEMPLATE_LIST,
        payload: data
    };
};

export const setSearchTemplateData = (data: any) => {
    return {
        type: Constants.SET_SEARCH_TEMPLATE_DATA,
        payload: data
    };
};

export const setTemplateList = (data: any) => {

    return {
        type: Constants.SET_TEMPLATE_LIST,
        payload: data
    };
}

export const toggleModalTemplates = (data: any) => {
    return {
        type: Constants.TOGGLE_MODAL_TEMPLATES,
        payload: data
    };
}

export const getTemplateDetailsById = (data: any) => {
    return {
        type: Constants.GET_TEMPLATE_DETAILS_BY_ID,
        payload: data
    };
}

export const setTemplateDetailsById = (data: any) => {
    return {
        type: Constants.SET_TEMPLATE_DETAILS_BY_ID,
        payload: data
    };
}

export const removeCallToActionTemplate = (data: any) => {
    return {
        type: Constants.REMOVE_CALL_ACTION_TEMPLATE,
        payload: data
    };
}

export const removeQuickReplyTemplate = (data: any) => {
    return {
        type: Constants.REMOVE_QUICK_REPLY_TEMPLATE,
        payload: data
    };
}

export const toggleTemplateEdition = (data: any) => {
    return {
        type: Constants.TOGGLE_TEMPLATE_EDITION,
        payload: data
    };
}

export const setCallToActionTemplate = (data: any) => {
    return {
        type: Constants.SET_CALL_TO_ACTION_TEMPLATE,
        payload: data
    };
}

export const setQuickReplyButtonsTemplate = (data: any) => {
    return {
        type: Constants.SET_QUICK_REPLY_BUTTONS_TEMPLATE,
        payload: data
    };
}

export const saveTemplateDetails = (data: any) => {
    return {
        type: Constants.SAVE_TEMPLATE_DETAILS,
        payload: data
    };
}

export const deleteTemplateData = (data: any) => {
    return {
        type: Constants.REMOVE_TEMPLATE_DATA,
        payload: data
    };
}

export const toggleEditingMasterNonGeneralPreview = (data: any) => {
    return {
        type: Constants.TOGGLE_EDITING_MASTER_NON_GENERAL_PREVIEW,
        payload: data
    };
}

export const toggleModalTemplateMasterVariables = (data: any) => {
    return {
        type: Constants.TOGGLE_MODAL_TEMPLATE_MASTER_VARIABLES,
        payload: data
    };
}

export const toggleForEditing = (data: any) => {
    return {
        type: Constants.TOGGLE_FOR_EDITING_TEMPLATE,
        payload: data
    };
}

export const toggleIsAlreadySaved = (data: any) => {
    return {
        type: Constants.TOGGLE_IS_ALREADY_SAVED,
        payload: data
    };
}

export const toggleEditableTemplate = (data: any) => {
    return {
        type: Constants.TOGGLE_EDITABLE_TEMPLATE,
        payload: data
    };
}

export const injectTemplateIdAfterCreatingTemplate = (data: any) => {
    return {
        type: Constants.INJECT_TEMPLATE_ID_AFTER_CREATING_TEMPLATE,
        payload: data
    };
};

export const updateTemplateDataBeforeSubmit = (data: any) => {
    return {
        type: Constants.UPDATE_TEMPLATE_DETAILS_BEFORE_SUBMIT,
        payload: data
    };
};

export const toggleIsSubmittedTemplate = (data: any) => {
    return {
        type: "IS_SUBMITTED_TEMPLATE",
        payload: data
    };
};

export const searchTemplateText = (data: any) => {
    return {
        type: "SEARCH_TEMPLATE_TEXT",
        payload: data
    };
};

export const toggleInitializedTemplate = (data: any) => {
    return {
        type: "IS_INITIALIZED_TEMPLATE",
        payload: data
    };
};

export const getTimeZones = () => {
    return {
        type: Constants.GET_TIME_ZONES
    };
};

export const setTimeZones = (data?: any) => {
    return {
        type: Constants.SET_TIME_ZONES,
        payload: data
    };
};

const initialState = {
    // Template
    search_template_text: '',
    is_updated_template_by_signal_r: false,
    template_list: [],
    search_template_list: [],
    toggle_modal_template: false,
    call_to_action_templates: null,
    quick_reply_buttons_template: null,
    is_already_saved: null,
    isEditableTemplate: false,
    toggle_template_edition: null, // insert and update template
    editing_for_master_non_general_preview: true,
    toggle_modal_template_master_variables: false,
    toggle_for_editing: false,
    isSubmittedTemplate: false,
    is_initialized_template: false,
    // Campaign
    campaign_list: [],
    searched_campaign_list: [],
    searched_campaign_text: '',
    campaign_details_payload: null,
    time_zones: [],
}

// Campaign
export const setCampaignDetailsPayload = (data: any) => {
    return {
        type: Constants.SET_CAMPAIGN_DETAILS_PAYLOAD,
        payload: data
    };
};

export const getFirstTimeCampaignList = () => {
    return {
        type: Constants.GET_FIRST_TIME_CAMPAIGN_LIST,
        payload: {}
    };
}

export const setFirstTimeCampaignList = (data: any) => {

    return {
        type: Constants.SET_FIRST_TIME_CAMPAIGN_LIST,
        payload: data
    };
}

export const insertCampaignData = (data: any) => {
    return {
        type: Constants.INSERT_CAMPAIGN_DATA,
        payload: data
    };
};

export const setSearchedCampaignList = (data: any) => {
    return {
        type: Constants.SET_SEARCHED_CAMPAIGN_LIST,
        payload: data
    };
};

export const setCampaignText = (data: any) => {
    return {
        type: "SEARCHED_CAMPAIGN_TEXT",
        payload: data
    };
};


const promotionsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        // Campaign
        case Constants.SET_FIRST_TIME_CAMPAIGN_LIST:
            console.log({ campaign_list: action.payload });

            return {
                ...state,
                campaign_list: action.payload
            };
        case "SEARCHED_CAMPAIGN_TEXT":
            return {
                ...state,
                searched_campaign_text: action.payload
            };
        case Constants.SET_SEARCHED_CAMPAIGN_LIST:
            return {
                ...state,
                searched_campaign_list: action.payload
            };
        case Constants.SET_CAMPAIGN_DETAILS_PAYLOAD:
            return {
                ...state,
                campaign_details_payload: action.payload
            };
        case Constants.SET_TIME_ZONES:
            return {
                ...state,
                time_zones: action.payload
            };
        // Template
        case Constants.TOGGLE_EDITABLE_TEMPLATE:
            return {
                ...state,
                isEditableTemplate: action.payload
            };
        case Constants.SET_TEMPLATE_LIST:
            return {
                ...state,
                template_list: [...state.template_list, ...action.payload]
            };
        case Constants.TOGGLE_UPDATE_TEMPLATE_SIGNAL_R_REQUEST:
            return {
                ...state,
                is_updated_template_by_signal_r: action.payload
            }
        case Constants.APPEND_SIGNAL_R_DATA_FOR_WHATSAPP_TEMPLATE:
            const signal_r_template = action.payload;
            const expected_template_list = state.template_list?.map((item: any) => {
                let new_template = { ...item };
                if (signal_r_template?.templateId === item?.templateId) {
                    new_template = {
                        ...new_template,
                        status: signal_r_template.status || signal_r_template.Status,
                    }
                }
                return new_template;
            }) || [];
            // console.log({ signal_r_template, expected_template_list });
            return {
                ...state,
                template_list: expected_template_list,
                is_updated_template_by_signal_r: true,
            }
        case Constants.SET_FIRST_TIME_TEMPLATE_LIST:
            return {
                ...state,
                template_list: action.payload
            };
        case Constants.TOGGLE_IS_ALREADY_SAVED:
            return {
                ...state,
                is_already_saved: action.payload
            };
        case Constants.SET_QUICK_REPLY_BUTTONS_TEMPLATE:
            return {
                ...state,
                quick_reply_buttons_template: action.payload
            };

        case Constants.SET_CALL_TO_ACTION_TEMPLATE:
            return {
                ...state,
                call_to_action_templates: action.payload
            };
        case Constants.TOGGLE_FOR_EDITING_TEMPLATE:
            return {
                ...state,
                toggle_for_editing: action.payload
            };
        case Constants.TOGGLE_MODAL_TEMPLATE_MASTER_VARIABLES:
            return {
                ...state,
                toggle_modal_template_master_variables: action.payload
            };
        case Constants.TOGGLE_EDITING_MASTER_NON_GENERAL_PREVIEW:
            return {
                ...state,
                editing_for_master_non_general_preview: action.payload
            };
        case Constants.TOGGLE_MODAL_TEMPLATES:
            return {
                ...state,
                toggle_modal_template: action.payload
            };
        case Constants.SET_TEMPLATE_DETAILS_BY_ID:
            return {
                ...state,
                toggle_template_edition: action.payload.templateDetails,
                call_to_action_templates: action.payload.callToAction,
                quick_reply_buttons_template: action.payload.quickReplay,
                is_initialized_template: true
            };
        case Constants.TOGGLE_TEMPLATE_EDITION:
            return {
                ...state,
                toggle_template_edition: action.payload
            };
        case Constants.INJECT_TEMPLATE_ID_AFTER_CREATING_TEMPLATE:
            return {
                ...state,
                toggle_template_edition: {
                    ...state.toggle_template_edition,
                    templateId: action.payload
                }
            };

        case "IS_INITIALIZED_TEMPLATE":
            return {
                ...state,
                is_initialized_template: action.payload
            };
        case "IS_SUBMITTED_TEMPLATE":
            return {
                ...state,
                isSubmittedTemplate: action.payload
            };
        case "SEARCH_TEMPLATE_TEXT":
            return {
                ...state,
                search_template_text: action.payload
            };
        case Constants.SET_SEARCH_TEMPLATE_DATA:
            return {
                ...state,
                search_template_list: action.payload
            };
        case Constants.LOGOUT:
            return {
                ...state,

            };

        default:
            return state;
    }
}

export default promotionsReducer;