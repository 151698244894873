import { takeEvery, put, call, all, takeLatest, delay, select } from 'redux-saga/effects';
import Constants from '../constants';
import axios from "axios";
import {
    receiveDashboard, setUnifiedData,
    setWallUnifiedData, setUnifiedDetailsData,
    resetWallUnifiedInbox, setRequestReviewData, setConversationDetailsData,
    addToWallDataByKey, removeFromWallDataByKey,
    setUpdatedWhatsappPhoneOrEmail,
    setErrorMessage, setBotData,
    setWhatsAppInboxData, setClosedWhatsApp,
    setSuccessMessage,
    setInboxArchiveMessages,
    setInboxAllMessages,
    setInboxAssignedToMeMessages,
    setInboxSharedWithMeMessages,
    setInboxSharedCopiesMessages,
    setSearchInboxArchiveMessages,
    setSearchInboxAllMessages,
    setSearchInboxAssignedToMeMessages,
    setSearchInboxSharedWithMeMessages,
    setSearchInboxSharedCopiesMessages,
    setSearchInboxArchiveMessagesByScrolling,
    setInboxArchiveMessagesByScrolling,
    setSearchInboxSharedCopiesMessagesByScrolling,
    setInboxSharedCopiesMessagesByScrolling,
    setSearchInboxSharedWithMeMessagesByScrolling,
    setInboxSharedWithMeMessagesByScrolling,
    setSearchInboxAssignedToMeMessagesByScrolling,
    setInboxAssignedToMeMessagesByScrolling,
    setSearchInboxAllMessagesByScrolling,
    setInboxAllMessagesByScrolling,
    setReplyingOnState,
    updateInboxSenderState,
    setIsSearched,
    setIsFetching,
    setEmptyData,
    setTeamInboxSharedUserList,
    getTeamInboxSharedUserList,
} from './reducer';
import { generateAccessToken } from '../profile/reducer';
import { api_url, api_version, inbox_api_url } from '../utils/server';
import { deleteKeyValue, deleteSenderKeyValue, getKeyValue, setKeyValue, setSenderKeyValue } from '../utils/indexedDBManagementService';
// Selector to get specific state
const selectData = (state: any) => state.unified;
export async function replyToWhatsAppMessage(data?: any) {
    console.log('replyToWhatsAppMessage', data);
    const url = api_url + `/api/${api_version}/WhatsApp/ReplyToMessage`;
    try {
        const logged_in_user_secret_data: any = await getKeyValue("logged_in_user_secret_data");
        const response = await axios.post(url, data, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

function* dashboardRequest(action: any): any {
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
          axios.post,
          api_url + `/api/${api_version}/dashboard/getdashboarddata`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(receiveDashboard(data));
            // yield put(generateAccessToken());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// mmhk-region
// unified inbox - old version
// function* unifiedInboxRequest(action: any): any {
//     // console.log('unifiedInboxRequest=====', action.payload)
//     const {isFirstTime, searchText, readStatus, toDate, fromDate} = action.payload;
//     let url = api_url + `/api/${api_version}/clapup/getDataForInboxAndMenu`;
//     const dataObject: any = {
//         ...action.payload,
//     }
//     if(!readStatus){
//         dataObject.readStatus = 'All';
//     }
//     if(searchText || readStatus || toDate || fromDate){
//         url = api_url + `/api/${api_version}/clapup/searchData`;
//         delete dataObject.includeGoogleReviews;
//         delete dataObject.includeFacebookReviews;
//         delete dataObject.includeTwitterMentions;
//         delete dataObject.includeCustomerFeedback;
//         delete dataObject.includeVideoTestimonials;
//         delete dataObject.includeFacebookComments;
//         delete dataObject.includeFacebookMentions;
//         delete dataObject.includeGoogleQandA;
//         delete dataObject.mappedGoogleUserId;
//     } else {
//         delete dataObject.searchText;
//         delete dataObject.readStatus;
//         delete dataObject.toDate;
//         delete dataObject.fromDate;
//         delete dataObject.providerType;
//     }
//     delete dataObject.isFirstTime;
//     try {
        // const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
//         const { data, status } = yield call(axios.post, url, dataObject, {
//             headers: {
//               'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
//               'Content-Type': 'application/json'
//             }
//         });
//         // const { data } = yield call(axios.post, api_url + `/api/${api_version}/clapup/unifiedInbox`, action.payload);
//         if (status === 200) {
//             if (data === "Error while fetching data!") {
//                 yield put(setUnifiedData([], [], isFirstTime));
//             } else {
//                 const unifiedData = data?.data;
//                 yield put(
//                   setUnifiedData(unifiedData, data?.nextDate, isFirstTime, {
//                     ...dataObject,
//                     readStatus: action.payload.readStatus,
//                     toDate: action.payload.toDate,
//                     fromDate: action.payload.fromDate,
//                     providerType: action.payload.providerType,
//                   })
//                 );
//             }
//         }  
//     } catch (error) {
//         const { response }: any = error;
//         console.log('error', response);
//         const { status } = response || {};
//         if (status === 403) {
//             yield put(generateAccessToken());
//         }
//     }
// }

// unified inbox
function* getInboxAndWallRequest(action: any): any {
    const {isFirstTime, searchText, readStatus, toDate, fromDate, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/${api_version}/clapup/ConsolidatedInboxAndMenu`;
    const dataObject: any = {
        ...action.payload,
    }
    // console.log({searchText, readStatus, toDate, fromDate, providerType, tag});
    if(!readStatus){
        dataObject.readStatus = 'All';
    }
    if (searchText || readStatus || toDate || fromDate) {
        
        delete dataObject.lastSerialNoCommentsAll;
        delete dataObject.lastSerialNoInboxAll;
        delete dataObject.lastSerialNoMentionsAll;
        delete dataObject.lastSerialNoQandAAll;
        delete dataObject.lastSerialNoReviewsAll;
        //
        delete dataObject.lastSerialNoGoogleAll;
        delete dataObject.lastSerialNoGoogleReviews;
        delete dataObject.lastSerialNoGoogleQandA;
        delete dataObject.lastSerialNoFacebookAll;
        delete dataObject.lastSerialNoFacebookReviews;
        delete dataObject.lastSerialNoFacebookComments;
        delete dataObject.lastSerialNoFacebookMentions;
        delete dataObject.lastSerialNoCFReviews;
        delete dataObject.lastSerialNoVTReviews;
        delete dataObject.lastSerialNoInboxWall;
        delete dataObject.lastSerialNoCFWall;
        delete dataObject.lastSerialNoVTWall;
        delete dataObject.lastSerialNoGoogleWall;
        delete dataObject.lastSerialNoFacebookWall;
        // url = api_url + `/api/${api_version}/clapup/searchData`; 
        url = api_url + `/api/${api_version}/clapup/ConsolidatedSearchForInboxAndMenu`; 
    } else {
        delete dataObject.searchText;
        delete dataObject.readStatus;
        delete dataObject.toDate;
        delete dataObject.fromDate;
        delete dataObject.providerType;
        delete dataObject.tag;
        delete dataObject.lastSerialNo;
    }
    delete dataObject.includeGoogleReviews;
    delete dataObject.includeFacebookReviews;
    delete dataObject.includeTwitterMentions;
    delete dataObject.includeCustomerFeedback;
    delete dataObject.includeVideoTestimonials;
    delete dataObject.includeFacebookComments;
    delete dataObject.includeFacebookMentions;
    delete dataObject.includeGoogleQandA;
    delete dataObject.mappedGoogleUserId;  
    delete dataObject.isFirstTime;
    delete dataObject.key;
    delete dataObject.nextKey;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(axios.post, url, dataObject, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // const { data } = yield call(axios.post, api_url + `/api/${api_version}/clapup/unifiedInbox`, action.payload);
        if (status === 200) {
            if (data === "Error while fetching data!") {
                yield put(setUnifiedData([], [], isFirstTime));
            } else {
                const unifiedData = data;
                // to lower case
                if (providerType?.includes("inbox")) {
                    yield put(
                      setUnifiedData(unifiedData, {
                        ...dataObject,
                        readStatus: action.payload.readStatus,
                        toDate: action.payload.toDate,
                        fromDate: action.payload.fromDate,
                        providerType: action.payload.providerType,
                      }, key, nextKey)
                    );
                } else {
                    yield put(
                        setWallUnifiedData(unifiedData, {
                        ...dataObject,
                        readStatus: action.payload.readStatus,
                        toDate: action.payload.toDate,
                        fromDate: action.payload.fromDate,
                        providerType: action.payload.providerType,
                      }, key, nextKey)
                    );
                }
            }
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// search wall data
function* searchWallDataRequest(action: any): any {
    const { key, nextKey } = action.payload;
    delete action.payload.key;
    delete action.payload.nextKey;
    const dataObject: any = {
        ...action.payload,
    }
    delete dataObject.includeGoogleReviews;
    delete dataObject.includeFacebookReviews;
    delete dataObject.includeTwitterMentions;
    delete dataObject.includeCustomerFeedback;
    delete dataObject.includeVideoTestimonials;
    delete dataObject.includeFacebookComments;
    delete dataObject.includeFacebookMentions;
    delete dataObject.includeGoogleQandA;
    delete dataObject.mappedGoogleUserId;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/clapup/searchWallData`,
            dataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const wallUnifiedData = data;
            yield put(setWallUnifiedData(wallUnifiedData, action.payload, key, nextKey));
        } 
        // if (status === 200) {
        //     const wallUnifiedData = data?.data?.map((item: any, index: number) => {
        //         return {
        //             ...item,
        //             idx: index
        //         }
        //     });
        //     yield put(setWallUnifiedData(wallUnifiedData, dataObject));
        // } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// all team inbox shared user list
function* requestForTeamInboxAllSharedUserList(action: any): any {
    const { teamId: finder_team_key, shared_user_key } = action.payload;
    try {
        console.log({ teamId: finder_team_key, shared_user_key });
        const all_team_inbox_shared_users: any = yield call(getKeyValue, "all_team_inbox_shared_users");
        const others_stored_shared_users: any[] = all_team_inbox_shared_users?.filter((item: any) => !item.hasOwnProperty(finder_team_key)) || [];
        let shared_users_key_value: any = all_team_inbox_shared_users?.find((item: any) => item.hasOwnProperty(finder_team_key))?.[finder_team_key] || null;
        console.log({ others_stored_shared_users, shared_users_key_value, teamId: finder_team_key, finder_team_key, shared_user_key });
        if (!shared_users_key_value?.length) {
            const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
            const assignee_users_url = inbox_api_url + `/api/${api_version}/teams/GetUsersForAssignment`;
            const assignee_users_payload = {
                workspaceId: logged_in_user_secret_data?.workspaceId,
                clapupUserId: logged_in_user_secret_data?.clapupUserId,
                teamId: finder_team_key,
            }
            const { data: user_data } = yield call(
                axios.post,
                assignee_users_url,
                assignee_users_payload,
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const { userDetails } = user_data || {};
            shared_users_key_value = userDetails;
            const all_kinds_of_team_users: any[] = [
                ...others_stored_shared_users,
            ];
            if (userDetails?.length) {
                all_kinds_of_team_users.push({[finder_team_key]: shared_users_key_value})
            }
            yield call(setKeyValue, "all_team_inbox_shared_users", all_kinds_of_team_users);
        }
        
        yield put(setTeamInboxSharedUserList({ shared_user_key, user_details: shared_users_key_value }));
        
    } catch (error: any) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
};

// all inbox messages
function* InboxAllMessagesRequest(action: any): any {
    // console.log('Archive Data Object: ', action.payload);
    const {
        // readStatus,
        searchText,
        toDate, fromDate, clapupUserId,
        workspaceId, createdOn, teamId,
        isForSearched,
        inboxType: type, tag: category,
        isScrolled, // for category = key => I have to update with category wise data after scrolling
        // key, nextKey, providerType, tag
    } = action.payload || {};
    try {
        const {
            all_messages_details_assignee_users, AllInboxData,
            // assigned_to_me_details_assignee_users,
            // shared_with_me_details_assignee_users,
            // shared_copies_details_assignee_users,
            // archive_details_assignee_users,
        } = yield select(selectData);
        if (!isForSearched && !isScrolled) {
            console.log({ AllInboxData });
            if (AllInboxData) {
                yield put(setInboxAllMessages(AllInboxData));
                return;
            }
        }
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (!workspaceId || !clapupUserId) {
            return;
        }
        const inboxType = type || "main"
        // delete action.payload.key;
        // delete action.payload.nextKey;
        let sagaDataObject: any = {
            clapupUserId,
            workspaceId,
            createdOn,
            teamId,
            inboxType: inboxType,
            category: category || "all"
            // status: tag || "all"
        }
        
        const key = category === "open"
            ? "openMails"
            : category === "closed"
            ? "closedMails"
            : category  === "inProgress"
            ? "inProgressMails"
            : category === "starred"
            ? "starredMails"
            : "allMails";
        
        if (isForSearched) {
            // sagaDataObject.inboxType = inboxType || "archives";
            // sagaDataObject.category = category || "all";
            sagaDataObject.searchText = searchText || "";
            sagaDataObject.fromDate = fromDate;
            sagaDataObject.toDate = toDate;
            // delete sagaDataObject.createdOn;
        }
        
        // console.log({
        //     // readStatus,
        //     isForSearched,
        //     sagaDataObject,
        //     searchText,
        //     toDate, fromDate
        // });
                
        let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAllEmailMessages`;
        if (isForSearched) {
            url = inbox_api_url + `/api/${api_version}/teams/SearchEmails`;
        }
        
        if (!isScrolled) {
            yield put(setIsFetching(true));
            yield put(setEmptyData(true));
        }
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const { allMails, closedMails, openMails, inProgressMails } = data || {};
            if (isForSearched && (!allMails?.emailDetails?.length && !closedMails?.emailDetails?.length && !openMails?.emailDetails?.length && !inProgressMails?.emailDetails?.length)) {
                yield put(setSearchInboxAllMessages({
                    allMails: { emailDetails: [] },
                    closedMails: { emailDetails: [] }, 
                    openMails: { emailDetails: [] },
                    inProgressMails: { emailDetails: [] },
                }));
                delay(1000);
                yield put(setIsSearched(true));
                return;
            }
            yield put(setEmptyData(false));
            if (!isForSearched && !isScrolled) {
                let user_details = all_messages_details_assignee_users;
                // console.log({ user_details });
                
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                yield put(setInboxAllMessages(expectedData));
                if (!user_details?.length || teamId === "all") {
                    yield put(getTeamInboxSharedUserList({teamId, shared_user_key: "all_messages_details_assignee_users"}));
                } else {
                    yield put(setTeamInboxSharedUserList({ shared_user_key: "all_messages_details_assignee_users", user_details }));
                }
            } else if (isForSearched && !isScrolled) {
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                // yield put(setSearchInboxAllMessages(data));
                yield put(setSearchInboxAllMessages(expectedData));
                delay(1000);
                yield put(setIsSearched(true));
            } else if (isForSearched && isScrolled) {
                // updating by category = key data only in search area reducer
                yield put(setSearchInboxAllMessagesByScrolling(data, key));
            } else {
                // updating by category = key data only in main area reducer
                yield put(setInboxAllMessagesByScrolling(data, key));
            }
        } else {
            if (!isScrolled) {
                yield put(setIsFetching(false));
                yield put(setEmptyData(true));
            }
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
        if (!isScrolled) {
            yield put(setIsFetching(false));
            yield put(setEmptyData(true));
        }
    }
}

// assigned to me inbox messages
function* InboxAssignedToMeMessagesRequest(action: any): any {
    // console.log('Archive Data Object: ', action.payload);
    const {
        // readStatus,
        searchText,
        toDate, fromDate, clapupUserId,
        workspaceId, createdOn, teamId,
        isForSearched,
        inboxType: type, tag: category,
        isScrolled, // for category = key => I have to update with category wise data after scrolling
        // key, nextKey, providerType, tag
    } = action.payload || {};
    try {
        const {
            assigned_to_me_details_assignee_users, AssignedToMeInboxData
        } = yield select(selectData);
        if (!isForSearched && !isScrolled) {
            console.log({ AssignedToMeInboxData });
            if (AssignedToMeInboxData) {
                yield put(setInboxAssignedToMeMessages(AssignedToMeInboxData));
                return;
            }
        }
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (!workspaceId || !clapupUserId) {
            return;
        }
        const inboxType = type || "assignedToMe"
        // delete action.payload.key;
        // delete action.payload.nextKey;
        let sagaDataObject: any = {
            clapupUserId,
            workspaceId,
            createdOn,
            teamId,
            inboxType: inboxType,
            category: category || "all"
            // status: tag || "all"
        }
        
        const key = category === "open"
            ? "openMails"
            : category === "closed"
            ? "closedMails"
            : category  === "inProgress"
            ? "inProgressMails"
            : category === "starred"
            ? "starredMails"
            : "allMails";
        
        if (isForSearched) {
            // sagaDataObject.inboxType = inboxType || "archives";
            // sagaDataObject.category = category || "all";
            sagaDataObject.searchText = searchText || "";
            sagaDataObject.fromDate = fromDate;
            sagaDataObject.toDate = toDate;
            // delete sagaDataObject.createdOn;
        }
        
        console.log({
            // readStatus,
            isForSearched,
            sagaDataObject,
            searchText,
            toDate, fromDate
        });
        
        if (!isScrolled) {
            yield put(setIsFetching(true));
            yield put(setEmptyData(true));
        }
        // let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAssignedToMeEmailMessages`;
        let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAllEmailMessages`;
        if (isForSearched) {
            url = inbox_api_url + `/api/${api_version}/teams/SearchEmails`;
        }
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const { allMails, closedMails, openMails, inProgressMails } = data || {};
            // console.log("search Inbox",{ data, inboxType, category });
            if (isForSearched && (!allMails?.emailDetails?.length && !closedMails?.emailDetails?.length && !openMails?.emailDetails?.length && !inProgressMails?.emailDetails?.length)) {
                // console.log("search Inbox",{ data, inboxType, category });
                yield put(setSearchInboxAssignedToMeMessages({
                    allMails: { emailDetails: [] },
                    closedMails: { emailDetails: [] }, 
                    openMails: { emailDetails: [] },
                    inProgressMails: { emailDetails: [] },
                }));
                delay(1000);
                yield put(setIsSearched(true));
                return;
            }
            yield put(setEmptyData(false));
            // const { emailDetails: archive_inbox_data, statDetails: archive_stat_details, chatSummaryList: archive_chat_summary_list } = data || {}
            // console.log("con-length => ", {mailDetails})
            // yield put(setInboxArchiveMessages(mailDetails));
            if (!isForSearched && !isScrolled) {
                let user_details = assigned_to_me_details_assignee_users;
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }

                yield put(setInboxAssignedToMeMessages(expectedData));
                if (!user_details?.length) {
                    yield put(getTeamInboxSharedUserList({teamId, shared_user_key: "assigned_to_me_details_assignee_users"}));
                } else {
                    yield put(setTeamInboxSharedUserList({ shared_user_key: "assigned_to_me_details_assignee_users", user_details }));
                }
            } else if (isForSearched && !isScrolled) {
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                // yield put(setSearchInboxAssignedToMeMessages(data));
                yield put(setSearchInboxAssignedToMeMessages(expectedData));
                delay(1000);
                yield put(setIsSearched(true));
            } else if (isForSearched && isScrolled) {
                // updating by category = key data only in search area reducer
                yield put(setSearchInboxAssignedToMeMessagesByScrolling(data, key));
            } else {
                // updating by category = key data only in main area reducer
                yield put(setInboxAssignedToMeMessagesByScrolling(data, key));
            }
        } else {
            if (!isScrolled) {
                yield put(setIsFetching(false));
                yield put(setEmptyData(true));
            }
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
        if (!isScrolled) {
            yield put(setIsFetching(false));
            yield put(setEmptyData(true));
        }
    }

}

// shared with me inbox messages
function* InboxSharedWithMeMessagesRequest(action: any): any {
    // console.log('Archive Data Object: ', action.payload);
    const {
        // readStatus,
        searchText,
        toDate, fromDate, clapupUserId,
        workspaceId, createdOn, teamId,
        isForSearched,
        inboxType: type, tag: category,
        isScrolled, // for category = key => I have to update with category wise data after scrolling
        // key, nextKey, providerType, tag
    } = action.payload || {};
    try {
        const {
            shared_with_me_details_assignee_users, SharedWithMeInboxData
        } = yield select(selectData);
        if (!isForSearched && !isScrolled) {
            console.log({ SharedWithMeInboxData });
            if (SharedWithMeInboxData) {
                yield put(setInboxSharedWithMeMessages(SharedWithMeInboxData));
                return;
            }
        }
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (!workspaceId || !clapupUserId) {
            return;
        }
        const inboxType = type || "sharedWithMe"
        // delete action.payload.key;
        // delete action.payload.nextKey;
        let sagaDataObject: any = {
            clapupUserId,
            workspaceId,
            createdOn,
            teamId,
            inboxType: inboxType,
            category: category || "all"
            // status: tag || "all"
        }
        
        const key = category === "open"
            ? "openMails"
            : category === "closed"
            ? "closedMails"
            : category  === "inProgress"
            ? "inProgressMails"
            : category === "starred"
            ? "starredMails"
            : "allMails";
        
        if (isForSearched) {
            // sagaDataObject.inboxType = inboxType || "archives";
            // sagaDataObject.category = category || "all";
            sagaDataObject.searchText = searchText || "";
            sagaDataObject.fromDate = fromDate;
            sagaDataObject.toDate = toDate;
            // delete sagaDataObject.createdOn;
        }
        
        console.log({
            // readStatus,
            isForSearched,
            sagaDataObject,
            searchText,
            toDate, fromDate
        });

        if (!isScrolled) {
            yield put(setIsFetching(true));
            yield put(setEmptyData(true));
        }      
        // let url = inbox_api_url + `/api/${api_version}/teams/GetInboxSharedWithMeEmailMessages`;
        let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAllEmailMessages`;
        if (isForSearched) {
            url = inbox_api_url + `/api/${api_version}/teams/SearchEmails`;
        }
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const { allMails, closedMails, openMails, inProgressMails } = data || {};
            // console.log("search Inbox",{ data, inboxType, category });
            if (isForSearched && (!allMails?.emailDetails?.length && !closedMails?.emailDetails?.length && !openMails?.emailDetails?.length && !inProgressMails?.emailDetails?.length)) {
                // console.log("search Inbox",{ data, inboxType, category });
                yield put(setSearchInboxSharedWithMeMessages({
                    allMails: { emailDetails: [] },
                    closedMails: { emailDetails: [] }, 
                    openMails: { emailDetails: [] },
                    inProgressMails: { emailDetails: [] },
                }));
                delay(1000);
                yield put(setIsSearched(true));
                return;
            }
            yield put(setEmptyData(false));
            if (!isForSearched && !isScrolled) {
                
                let user_details = shared_with_me_details_assignee_users;
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }

                yield put(setInboxSharedWithMeMessages(expectedData));
                if (!user_details?.length) {
                    yield put(getTeamInboxSharedUserList({teamId, shared_user_key: "shared_with_me_details_assignee_users"}));
                } else {
                    yield put(setTeamInboxSharedUserList({ shared_user_key: "shared_with_me_details_assignee_users", user_details }));
                }
            } else if (isForSearched && !isScrolled) {
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                // yield put(setSearchInboxSharedWithMeMessages(data));
                yield put(setSearchInboxSharedWithMeMessages(expectedData));
                delay(1000);
                yield put(setIsSearched(true));
            } else if (isForSearched && isScrolled) {
                // updating by category = key data only in search area reducer
                yield put(setSearchInboxSharedWithMeMessagesByScrolling(data, key));
            } else {
                // updating by category = key data only in main area reducer
                yield put(setInboxSharedWithMeMessagesByScrolling(data, key));
            }
        } else {
            if (!isScrolled) {
                yield put(setIsFetching(false));
                yield put(setEmptyData(true));
            }
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
        if (!isScrolled) {
            yield put(setIsFetching(false));
            yield put(setEmptyData(true));
        }
    }
}

// shared copies inbox messages
function* InboxSharedCopiesMessagesRequest(action: any): any {
    // console.log('Archive Data Object: ', action.payload);
    const {
        // readStatus,
        searchText,
        toDate, fromDate, clapupUserId,
        workspaceId, createdOn, teamId,
        isForSearched,
        inboxType: type, tag: category,
        isScrolled, // for category = key => I have to update with category wise data after scrolling
        // key, nextKey, providerType, tag
    } = action.payload || {};
    try {
        const {
            shared_copies_details_assignee_users, SharedCopiesInboxData
        } = yield select(selectData);
        if (!isForSearched && !isScrolled) {
            console.log({ SharedCopiesInboxData });
            if (SharedCopiesInboxData) {
                yield put(setInboxSharedCopiesMessages(SharedCopiesInboxData));
                return;
            }
        }
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (!workspaceId || !clapupUserId) {
            return;
        }
        const inboxType = type || "sharedCopies"
        // delete action.payload.key;
        // delete action.payload.nextKey;
        let sagaDataObject: any = {
            clapupUserId,
            workspaceId,
            createdOn,
            teamId,
            inboxType: inboxType,
            category: category || "all"
            // status: tag || "all"
        }
        
        const key = category === "open"
            ? "openMails"
            : category === "closed"
            ? "closedMails"
            : category  === "inProgress"
            ? "inProgressMails"
            : category === "starred"
            ? "starredMails"
            : "allMails";
        
        if (isForSearched) {
            // sagaDataObject.inboxType = inboxType || "archives";
            // sagaDataObject.category = category || "all";
            sagaDataObject.searchText = searchText || "";
            sagaDataObject.fromDate = fromDate;
            sagaDataObject.toDate = toDate;
            // delete sagaDataObject.createdOn;
        }
        
        console.log({
            // readStatus,
            isForSearched,
            sagaDataObject,
            searchText,
            toDate, fromDate
        });
        
        if (!isScrolled) {
            yield put(setIsFetching(true));
            yield put(setEmptyData(true));
        }
        // let url = inbox_api_url + `/api/${api_version}/teams/GetInboxSharedCopiesEmailMessages`;
        let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAllEmailMessages`;
        if (isForSearched) {
            url = inbox_api_url + `/api/${api_version}/teams/SearchEmails`;
        }
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const { allMails, closedMails, openMails, inProgressMails } = data || {};
            // console.log("search Inbox",{ data, inboxType, category });
            if (isForSearched && (!allMails?.emailDetails?.length && !closedMails?.emailDetails?.length && !openMails?.emailDetails?.length && !inProgressMails?.emailDetails?.length)) {
                // console.log("search Inbox",{ data, inboxType, category });
                yield put(setSearchInboxSharedCopiesMessages({
                    allMails: { emailDetails: [] },
                    closedMails: { emailDetails: [] }, 
                    openMails: { emailDetails: [] },
                    inProgressMails: { emailDetails: [] },
                }));
                delay(1000);
                yield put(setIsSearched(true));
                return;
            }
            yield put(setEmptyData(false));
            // const { emailDetails: archive_inbox_data, statDetails: archive_stat_details, chatSummaryList: archive_chat_summary_list } = data || {}
            // console.log("con-length => ", {mailDetails})
            // yield put(setInboxArchiveMessages(mailDetails));
            if (!isForSearched && !isScrolled) {
                let user_details = shared_copies_details_assignee_users;
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }

                yield put(setInboxSharedCopiesMessages(expectedData));
                if (!user_details?.length) {
                    yield put(getTeamInboxSharedUserList({teamId, shared_user_key: "shared_copies_details_assignee_users"}));
                } else {
                    yield put(setTeamInboxSharedUserList({ shared_user_key: "shared_copies_details_assignee_users", user_details }));
                }
            } else if (isForSearched && !isScrolled) {
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                // yield put(setSearchInboxSharedCopiesMessages(data));
                yield put(setSearchInboxSharedCopiesMessages(expectedData));
                delay(1000);
                yield put(setIsSearched(true));
            } else if (isForSearched && isScrolled) {
                // updating by category = key data only in search area reducer
                yield put(setSearchInboxSharedCopiesMessagesByScrolling(data, key));
            } else {
                // updating by category = key data only in main area reducer
                yield put(setInboxSharedCopiesMessagesByScrolling(data, key));
            }
        } else {
            if (!isScrolled) {
                yield put(setIsFetching(false));
                yield put(setEmptyData(true));
            }
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
        if (!isScrolled) {
            yield put(setIsFetching(false));
            yield put(setEmptyData(true));
        }
    }
}

// archive inbox messages
function* InboxArchiveMessagesRequest(action: any): any {
    // console.log('Archive Data Object: ', action.payload);
    const {
        // readStatus,
        searchText,
        toDate, fromDate, clapupUserId,
        workspaceId, createdOn, teamId,
        isForSearched,
        inboxType: type, tag: category,
        isScrolled, // for category = key => I have to update with category wise data after scrolling
        // key, nextKey, providerType, tag
    } = action.payload || {};
    try {
        const {
            archive_details_assignee_users, ArchiveInboxData
        } = yield select(selectData);
        if (!isForSearched && !isScrolled) {
            console.log({ ArchiveInboxData });
            if (ArchiveInboxData) {
                yield put(setInboxArchiveMessages(ArchiveInboxData));
                return;
            }
        }
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (!workspaceId || !clapupUserId) {
            return;
        }
        const inboxType = type || "archives"
        // delete action.payload.key;
        // delete action.payload.nextKey;
        let sagaDataObject: any = {
            clapupUserId,
            workspaceId,
            createdOn,
            teamId,
            inboxType: inboxType,
            category: category || "all"
            // status: tag || "all"
        }
        
        const key = category === "open"
            ? "openMails"
            : category === "closed"
            ? "closedMails"
            : category  === "inProgress"
            ? "inProgressMails"
            : category === "starred"
            ? "starredMails"
            : "allMails";
        
        if (isForSearched) {
            // sagaDataObject.inboxType = inboxType || "archives";
            // sagaDataObject.category = category || "all";
            sagaDataObject.searchText = searchText || "";
            sagaDataObject.fromDate = fromDate;
            sagaDataObject.toDate = toDate;
            // delete sagaDataObject.createdOn;
        }
        
        console.log({
            // readStatus,
            isForSearched,
            sagaDataObject,
            searchText,
            toDate, fromDate
        });
        
        if (!isScrolled) {
            yield put(setIsFetching(true));
            yield put(setEmptyData(true));
        }
        // let url = inbox_api_url + `/api/${api_version}/teams/GetInboxArchivesEmailMessages`;
        let url = inbox_api_url + `/api/${api_version}/teams/GetInboxAllEmailMessages`;
        if (isForSearched) {
            url = inbox_api_url + `/api/${api_version}/teams/SearchEmails`;
        }
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const { allMails, closedMails, openMails, inProgressMails } = data || {};
            // console.log("search Inbox",{ data, inboxType, category });
            if (isForSearched && (!allMails?.emailDetails?.length && !closedMails?.emailDetails?.length && !openMails?.emailDetails?.length && !inProgressMails?.emailDetails?.length)) {
                // console.log("search Inbox",{ data, inboxType, category });
                yield put(setSearchInboxArchiveMessages({
                    allMails: { emailDetails: [] },
                    closedMails: { emailDetails: [] }, 
                    openMails: { emailDetails: [] },
                    inProgressMails: { emailDetails: [] },
                }));
                delay(1000);
                yield put(setIsSearched(true));
                return;
            }
            yield put(setEmptyData(false));
            // const { emailDetails: archive_inbox_data, statDetails: archive_stat_details, chatSummaryList: archive_chat_summary_list } = data || {}
            // console.log("con-length => ", {mailDetails})
            // yield put(setInboxArchiveMessages(mailDetails));
            if (!isForSearched && !isScrolled) {
                let user_details = archive_details_assignee_users;
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }

                yield put(setInboxArchiveMessages(expectedData));
                if (!user_details?.length) {
                    yield put(getTeamInboxSharedUserList({teamId, shared_user_key: "archive_details_assignee_users"}));
                } else {
                    yield put(setTeamInboxSharedUserList({ shared_user_key: "archive_details_assignee_users", user_details }));
                }
            } else if (isForSearched && !isScrolled) {
                const expectedData: any = {
                    allMails: { emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] },
                    closedMails: { emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] },
                    openMails: { emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] },
                    inProgressMails: { emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] },
                };
                if (allMails) {
                    expectedData["allMails"] = {...allMails, emailDetails: allMails?.emailDetails?.length ? allMails?.emailDetails : [] }
                }
                if (closedMails) {
                    expectedData["closedMails"] = {...closedMails, emailDetails: closedMails?.emailDetails?.length ? closedMails?.emailDetails : [] }
                }
                if (openMails) {
                    expectedData["openMails"] = {...openMails, emailDetails: openMails?.emailDetails?.length ? openMails?.emailDetails : [] }
                }
                if (inProgressMails) {
                    expectedData["inProgressMails"] = {...inProgressMails, emailDetails: inProgressMails?.emailDetails?.length ? inProgressMails?.emailDetails : [] }
                }
                // yield put(setSearchInboxArchiveMessages(data));
                yield put(setSearchInboxArchiveMessages(expectedData));
                delay(1000);
                yield put(setIsSearched(true));
            } else if (isForSearched && isScrolled) {
                // updating by category = key data only in search area reducer
                yield put(setSearchInboxArchiveMessagesByScrolling(data, key));
            } else {
                // updating by category = key data only in main area reducer
                yield put(setInboxArchiveMessagesByScrolling(data, key));
            }
        } else {
            if (!isScrolled) {
                yield put(setIsFetching(false));
                yield put(setEmptyData(true));
            }
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
        if (!isScrolled) {
            yield put(setIsFetching(false));
            yield put(setEmptyData(true));
        }
    }
}

// whats-app inbox messages
function* whatsAppDataInboxRequest(action: any): any {
    console.log('Whatsapp Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, workspaceId, createdOn, isForSearched, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/${api_version}/WhatsApp/GetWhatsAppMessages`;
    delete action.payload.key;
    delete action.payload.nextKey;
    let sagaDataObject: any = {
        workspaceId,
        createdOn,
        // status: tag || "all"
    }
    
    searchText && (sagaDataObject.searchText = searchText);
    if (searchText && toDate && fromDate) {
        sagaDataObject.fromDate = fromDate;
        sagaDataObject.toDate = toDate;
    }
    console.log({sagaDataObject, searchText, readStatus, toDate, fromDate, providerType, tag });
    // if (searchText || readStatus) {
    if (isForSearched) {
        sagaDataObject = {
            ...sagaDataObject,
            searchText: searchText,
            workspaceId: sagaDataObject?.workspaceId,
            // status: tag || "all",
            status: "",
            createdOn: createdOn,
            fromDate: fromDate,
            toDate: toDate,
            assignedTo: readStatus || "All"

        }

        console.log({ sagaDataObject });
        // return;
        
        
        url = api_url + `/api/${api_version}/WhatsApp/SearchMessageDetails`; 
        // if (key?.includes("Wall") && searchText) {
        //     url = api_url + `/api/${api_version}/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        // } else if (key?.includes("Wall") && !searchText) { 
        //     url = api_url + `/api/${api_version}/clapup/ConsolidatedWallDataForInboxAndMenu`;
        // }
        
    } 

    
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            // const {main} = data;
            console.log('Whatsapp Data: ', data);
            yield put(setWhatsAppInboxData(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* whatsAppConversationClosedRequest(action: any): any {
    console.log('Whatsapp Closed Data Object: ', action.payload);
    const {
        userName, 
        ticketNo, 
        // status 
    } = action.payload || {};
    const payload_object = {
        ticketNo: ticketNo,
        status: "closed",
    }
    let url = api_url + `/api/${api_version}/WhatsApp/UpdatewhatsAppMessageStatus`;
 
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            url,
            payload_object,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            console.log('closed whatsapp Data: ', data, {...payload_object, userName});
            yield put(setClosedWhatsApp({...payload_object, userName}));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', error);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// bot inbox messages
function* botDataInboxRequest(action: any): any {
    console.log('Bot Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, workspaceId, createdOn, isForSearched, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/${api_version}/AIChatBot/GetAIChatBotMessages`;
    delete action.payload.key;
    delete action.payload.nextKey;
    const sagaDataObject: any = {
        workspaceId,
        createdOn,
        status: tag || "all"
    }
    
    searchText && (sagaDataObject.searchText = searchText);
    if (searchText && toDate && fromDate) {
        sagaDataObject.fromDate = fromDate;
        sagaDataObject.toDate = toDate;
    }
    console.log({sagaDataObject, searchText, readStatus, toDate, fromDate, providerType, tag });
    if (isForSearched) {
        
        // delete dataObject.lastSerialNoCommentsAll;
        // delete dataObject.lastSerialNoInboxAll;
        // delete dataObject.lastSerialNoMentionsAll;
        // delete dataObject.lastSerialNoQandAAll;
        // delete dataObject.lastSerialNoReviewsAll;
        
        url = api_url + `/api/${api_version}/AIChatBot/SearchChatDetails`; 
        // if (key?.includes("Wall") && searchText) {
        //     url = api_url + `/api/${api_version}/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        // } else if (key?.includes("Wall") && !searchText) { 
        //     url = api_url + `/api/${api_version}/clapup/ConsolidatedWallDataForInboxAndMenu`;
        // }
        
    } 

    
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const botData = data;
            console.log('Bot Data: ', botData);
            yield put(setBotData(botData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// wall unified inbox
function* wallUnifiedInboxRequest(action: any): any {
    console.log('Unified WallInbox Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/${api_version}/clapup/ConsolidatedWallDataForInboxAndMenu`;
    delete action.payload.key;
    delete action.payload.nextKey;
    const dataObject: any = {
        ...action.payload,
    }
    // console.log({ searchText, readStatus, toDate, fromDate, providerType, tag });
    
    if(!readStatus){
        dataObject.readStatus = 'All';
    }
    if (searchText || readStatus || toDate || fromDate) {
        
        delete dataObject.lastSerialNoCommentsAll;
        delete dataObject.lastSerialNoInboxAll;
        delete dataObject.lastSerialNoMentionsAll;
        delete dataObject.lastSerialNoQandAAll;
        delete dataObject.lastSerialNoReviewsAll;
        //
        
        // url = api_url + `/api/${api_version}/clapup/searchData`; 
        url = api_url + `/api/${api_version}/clapup/ConsolidatedSearchForInboxAndMenu`; 
        if (key?.includes("Wall") && searchText) {
            url = api_url + `/api/${api_version}/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        } else if (key?.includes("Wall") && !searchText) { 
            url = api_url + `/api/${api_version}/clapup/ConsolidatedWallDataForInboxAndMenu`;
        }
        
    } 
    if (url?.includes("ConsolidatedWallDataForInboxAndMenu")) {
        delete dataObject.searchText;
        delete dataObject.readStatus;
        delete dataObject.toDate;
        delete dataObject.fromDate;
        delete dataObject.providerType;
        delete dataObject.tag;
        delete dataObject.lastSerialNo;
    } else {
        delete dataObject.lastSerialNoGoogleAll;
        delete dataObject.lastSerialNoGoogleReviews;
        delete dataObject.lastSerialNoGoogleQandA;
        delete dataObject.lastSerialNoFacebookAll;
        delete dataObject.lastSerialNoFacebookReviews;
        delete dataObject.lastSerialNoFacebookComments;
        delete dataObject.lastSerialNoFacebookMentions;
        delete dataObject.lastSerialNoCFReviews;
        delete dataObject.lastSerialNoVTReviews;
        delete dataObject.lastSerialNoInboxWall;
        delete dataObject.lastSerialNoCFWall;
        delete dataObject.lastSerialNoVTWall;
        delete dataObject.lastSerialNoGoogleWall;
        delete dataObject.lastSerialNoFacebookWall;
    }
    
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        // const { data } = yield call(axios.post, api_url + `/api/${api_version}/clapup/unifiedWallInbox`, action.payload);
        const { data, status } = yield call(
            axios.post,
            url,
            // api_url + `/api/${api_version}/clapup/GetWallDataForInboxAndMenu`,
            dataObject,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const wallUnifiedData = data;
            yield put(setWallUnifiedData(wallUnifiedData, dataObject, key, nextKey));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// old version
// function* wallUnifiedInboxRequest(action: any) {
//     console.log('Unified WallInbox Data Object: ', action.payload)
//     try {
//         // const { data } = yield call(axios.post, api_url + `/api/${api_version}/clapup/unifiedWallInbox`, action.payload);
//         const { data, status } = yield call(
//             axios.post,
//             api_url + `/api/${api_version}/clapup/ConsolidatedWallDataForInboxAndMenu`,
//             // api_url + `/api/${api_version}/clapup/GetWallDataForInboxAndMenu`,
//             action.payload,
//             {
//                 headers: {
//                   'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
//                   'Content-Type': 'application/json'
//                 }
//             }
//         );
//         if (status === 200) {
//             const wallUnifiedData = data?.data?.map((item: any, index: number) => {
//                 return {
//                     ...item,
//                     idx: index
//                 }
//             });
//             yield put(setWallUnifiedData(wallUnifiedData, action.payload));
//         }  
//     } catch (error) {
//         const { response }: any = error;
//         console.log('error', response);
//         const { status } = response || {};
//         if (status === 403) {
//             yield put(generateAccessToken());
//         }
//     }
// }

// set google review data according to the selected unified inbox
function* getGoogleReviewDataDetails(action: any): any {
    // console.log('getGoogleReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/google/ReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties} = data
            yield put(setUnifiedDetailsData({location: location[0], reviewer: reviewer[0], details: details[0], properties: properties[0]}));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set facebook review data according to the selected unified inbox
function* getFacebookCommentDataDetails(action: any): any {
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/facebook/CommentsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer?.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* getFacebookMentionDataDetails(action: any): any {
    // console.log('getFacebookReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/facebook/MentionsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* getFacebookReviewDataDetails(action: any): any {
    // console.log('getFacebookReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/facebook/ReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set customer feedback review data according to the selected unified inbox
function* getCustomerFeedbackReviewDataDetails(action: any): any {
    // console.log('getCustomerFeedbackReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/clapup/cFReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );

        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const customerFeedbackData: any = {};
            reviewer.length && (customerFeedbackData.reviewer = reviewer[0]);
            location?.length && (customerFeedbackData.location = location[0]);
            details?.length && (customerFeedbackData.details = details[0]);
            properties?.length && (customerFeedbackData.properties = properties[0]);
            yield put(setUnifiedDetailsData(customerFeedbackData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set video testimonial review data according to the selected unified inbox
function* getVideoTestimonialReviewDataDetails(action: any): any {
    // console.log('getVideoTestimonialReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        // const { data } = yield call(axios.post, api_url + `/api/${api_version}/facebook/vTReviewsConversationDetails`, action.payload);
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/clapup/vTReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const videoData: any = {};
            reviewer.length && (videoData.reviewer = reviewer[0]);
            location?.length && (videoData.location = location[0]);
            details?.length && (videoData.details = details[0]);
            properties?.length && (videoData.properties = properties[0]);
            yield put(setUnifiedDetailsData(videoData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// *******Implement will be later *******
// set twitter review data according to the selected unified inbox
function* getTwitterReviewDataDetails(action: any): any {
    // console.log('getTwitterReviewDataDetails', action.payload)
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/twitter/MentionsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const twitterData: any = {};
            reviewer.length && (twitterData.reviewer = reviewer[0]);
            location?.length && (twitterData.location = location[0]);
            properties?.length && (twitterData.properties = properties[0]);
            details?.length && (twitterData.details = details[0]);
            yield put(setUnifiedDetailsData(twitterData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// add to wall data
function* addToWallDataRequest(action: any): any {
    const { data, key } = action;
    // console.log('Add To Wall Data', action.addToWallPayload);
    // return;
    const { providerType }: any = action?.addToWallPayload;
    
    try {
        // const {res}  = yield call(axios.post, api_url + `/api/${api_version}/clapup/addToWall`, action.addToWallPayload);
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const common_payload: any = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
        };
        if (providerType === "videoTestimonial") {
            const stage_url = api_url + `/api/${api_version}/gallery/UpdateContentStages`;
            const { data: stage_response, status: stage_status } = yield call(
                axios.post,
                stage_url,
                {
                    ...common_payload,
                    contentId: data?.contentId,
                    stageName: "publish",
                    stageValue: "1"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (stage_status !== 200) {
                yield put(setErrorMessage("Update publish stage failed"))
                return { success: false, message: "Update stage error" };
            }
        }
        const { status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/clapup/updateWall`,
            action.addToWallPayload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            // add to wall directly from frontend
            yield put(addToWallDataByKey(key, data));
            // yield put(resetWallUnifiedInbox());
        }

    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// delete from wall data
function* deleteFromWallDataRequest(action: any): any {
    // console.log('Delete From Wall Data', action.deleteWallPayload);
    const { deleteWallPayload, data, key } = action;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        // console.log({ deleteWallPayload, data });
        const { providerType } = deleteWallPayload;
        const common_payload: any = {
            clapupUserId: logged_in_user_secret_data?.cuid,
            workspaceId: logged_in_user_secret_data?.wuid,
            roleId: logged_in_user_secret_data?.roleId,
        };
        if (providerType === "videoTestimonial") {
            const stage_url = api_url + `/api/${api_version}/gallery/UpdateContentStages`;
            const { data: stage_response, status: stage_status } = yield call(
                axios.post,
                stage_url,
                {
                    ...common_payload,
                    contentId: data?.contentId,
                    stageName: "publish",
                    stageValue: "0"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (stage_status !== 200) {
                yield put(setErrorMessage("Update publish stage failed"))
                return { success: false, message: "Update stage error" };
            }
        }
        const { status } = yield call(
            axios.post, api_url + `/api/${api_version}/clapup/updateWall`,
            deleteWallPayload, 
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(removeFromWallDataByKey(key, data));
            // yield put(resetWallUnifiedInbox());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// request for review data
function* requestForReviewData(action: any): any {
    const {url} = action.payload;
    delete action.payload.url;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            url || api_url + `/api/${api_version}/clapup/requestForCustomerFeedback`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(setRequestReviewData(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// request for locations data
function* getLocations(action?: any): any {
    const { url} = action?.payload || {};
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.get,
            url || api_url + `/GetCountryListwithCode`,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put({ type: Constants.SET_LOCATIONS, payload: data });
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// conversational details data
function* getConversationDataDetailsData(action: any): any {
    const { url, payload, method } = action;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        if (method === 'GET') {
            const { data, status } = yield call(
                axios.get, url,
                {
                    headers: {
                      'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                      'Content-Type': 'application/json'
                    }
                }
            );
            if (status === 200) {
                yield put(setConversationDetailsData(data?.Table));
            }  
            
        } else {
            const { data, status } = yield call(axios.post, url, payload, {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                yield put(setConversationDetailsData(data));
            }  
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}
        
// update whatsapp details for phone number and email
function* updateForWhatsappPhoneNumberOrEmailData(action: any): any {
    const { updated_state, whatsapp_stat_details_object} = action.payload;
    const url = api_url + `/api/${api_version}/WhatsApp/UpdateWhatsAppContactDetails`
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            url,
            whatsapp_stat_details_object,
            {
                headers: {
                  'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(setUpdatedWhatsappPhoneOrEmail(updated_state));
            delay(1500);
            yield put(setSuccessMessage("Saved Successfully"));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
};

// request for facebook like-unlike
function* likeUnlikeFacebook(action?: any): any {
    const url = api_url + `/api/${api_version}/facebook/LikeUnlike`;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        console.log({facebook_res: data, status});
        if (status === 200) {
            console.log({facebook_message: "Successfully hit the fb like & unlike api"});
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}

// request for facebook replying post
function* replyingFacebookPost(action?: any): any {
    const url = api_url + `/api/${api_version}/facebook/replytopagereview`;
    try {
        const logged_in_user_secret_data: any = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        console.log({facebook_res: data, status});
        if (status === 200) {
            // console.log({facebook_message: "Successfully hit the fb replying api"});
            yield put(setSuccessMessage(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}

const manageReplyingData = async (data: any) => {
    // console.log("KeyData", { data });
    try {
        if (data) {
            const res = await setKeyValue("isReplying", data);
            return res;
        } else {
            const res = await deleteKeyValue("isReplying");
            return res;
        }
    } catch (error) {
        return false
    }
}

// request for changing replying on state
function* requestForChangingReplyingState(action?: any): Generator<any, void, any> {
    try {
        // Call the manageData function with the necessary arguments
        const result = yield call(manageReplyingData, action.payload);

        // Check the result from manageData
        if (result) {
            // Dispatch success if the result is truthy
            yield put(setReplyingOnState(action.payload));
        } else {
            console.error('manageData returned false');
        }
        // Dispatch a success
        yield put(setReplyingOnState(action.payload));
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}

// getAllSenderKeyValues -> we have to get all sender info to insert all  in inbox_sender_state of redux store
const managingSenderData = async (data: any) => {
    console.log("KeyData", { data });

    // Destructure properties from the input data
    const { 
        isForInserting, 
        messageId, 
        ticket_no, 
        clapupUserId, 
        sender_email, 
        sender_name, 
        teamId, 
        ticket_serial_no, 
        provider 
    } = data || {};

    if (!messageId || !ticket_no) {
        console.error("Missing required fields: messageId or ticket_no");
        return false;
    }

    try {
        // Construct a unique key for storing data
        const key = `${messageId}-${ticket_no}`;

        if (isForInserting) {
            // Example: Prepare the value for insertion
            const value = {
                clapupUserId,
                sender_email,
                sender_name,
                teamId,
                ticket_serial_no,
                provider,
                timestamp: new Date().toISOString(),
            };

            // Replace with your insertion logic
            console.log("Inserting key-value pair:", { key, value });
            const res = await setSenderKeyValue(key, value); // Assume setSenderKeyValue is defined
            return res;
        } else {
            // Replace with your deletion logic
            console.log("Deleting key:", key);
            const res = await deleteSenderKeyValue(key); // Assume deleteKeyValue is defined
            return res;
        }
    } catch (error) {
        console.error("Error managing sender data:", error);
        return false;
    }
};


// request for changing replying on state
function* requestForManagingSenderState(action?: any): Generator<any, void, any> {
    try {
        // Call the manageData function with the necessary arguments
        const result = yield call(managingSenderData, action.payload);
        console.log({result});
        // // Check the result from manageData
        if (result) {
            // Dispatch success if the result is truthy
            yield put(updateInboxSenderState(action.payload));
        } else {
            console.error('manageData returned false');
            yield put(setErrorMessage("Index DB Error"));
        }
        // // Dispatch a success
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}


//watcher saga
function* unifiedSaga() {
    yield all([
        // mmhk-region
        takeEvery(Constants.MANAGE_INBOX_SENDER_STATE, requestForManagingSenderState),
        takeEvery(Constants.TOGGLE_REPLYING_ON, requestForChangingReplyingState),
        takeEvery(Constants.TRIGGER_DASHBOARD_DATA, dashboardRequest),
        takeEvery(Constants.GET_LOCATIONS, getLocations),
        takeEvery(Constants.TRIGGER_REQUEST_REVIEW, requestForReviewData),
        takeEvery(Constants.TRIGGER_ADD_TO_WALL, addToWallDataRequest),
        takeEvery(Constants.TRIGGER_DELETE_FROM_WALL, deleteFromWallDataRequest),
        // filtering start
        takeEvery(Constants.TRIGGER_INBOX_AND_WALL, getInboxAndWallRequest),
        // filtering end
        // messages
        takeEvery(Constants.GET_ALL_TEAM_INBOX_SHARED_USERS, requestForTeamInboxAllSharedUserList),
        takeEvery(Constants.GET_FIRST_TIME_INBOX_ALL_DATA, InboxAllMessagesRequest),
        takeEvery(Constants.GET_FIRST_TIME_INBOX_ASSIGNED_TO_ME_DATA, InboxAssignedToMeMessagesRequest),
        takeEvery(Constants.GET_FIRST_TIME_INBOX_SHARED_WITH_ME_DATA, InboxSharedWithMeMessagesRequest),
        takeEvery(Constants.GET_FIRST_TIME_INBOX_SHARED_COPIES_DATA, InboxSharedCopiesMessagesRequest),
        takeEvery(Constants.GET_FIRST_TIME_INBOX_ARCHIVE_DATA, InboxArchiveMessagesRequest),
        takeEvery(Constants.GET_FIRST_TIME_WHATSAPP_DATA_INBOX, whatsAppDataInboxRequest),
        takeEvery(Constants.TRIGGER_CLOSED_WHATSAPP, whatsAppConversationClosedRequest),
        takeEvery(Constants.TRIGGER_UPDATE_WHATSAPP_PHONE_OR_EMAIL, updateForWhatsappPhoneNumberOrEmailData),
        // bot
        takeEvery(Constants.GET_FIRST_TIME_BOT_DATA_INBOX, botDataInboxRequest),
        // wall unified inbox
        takeEvery(Constants.TRIGGER_WALL_UNIFIED_INBOX, wallUnifiedInboxRequest),
        takeEvery(Constants.TRIGGER_SEARCH_WALL_DATA, searchWallDataRequest),
        takeEvery(Constants.TRIGGER_GOOGLE_DATA, getGoogleReviewDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_COMMENT_DATA, getFacebookCommentDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_MENTION_DATA, getFacebookMentionDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_REVIEW_DATA, getFacebookReviewDataDetails),
        takeEvery(
            Constants.TRIGGER_CUSTOMER_FEEDBACK_DATA,
            getCustomerFeedbackReviewDataDetails
        ),
        takeEvery(
            Constants.TRIGGER_VIDEO_TESTIMONIAL_DATA,
            getVideoTestimonialReviewDataDetails
        ),
        // implement twitter will be done later
        takeEvery(Constants.TRIGGER_TWITTER_DATA, getTwitterReviewDataDetails),
        // conversational details
        // takeEvery(Constants.TRIGGER_CONVERSATION_DETAILS_DATA, getConversationDataDetailsData),
    ]);
    
    
    //   only one time call
    yield takeLatest(Constants.TRIGGER_CONVERSATION_DETAILS_DATA, getConversationDataDetailsData);
    yield takeLatest(Constants.LIKE_UNLIKE_FACEBOOK_POST, likeUnlikeFacebook);
    yield takeLatest(Constants.REPLYING_FACEBOOK_POST, replyingFacebookPost);
    // yield takeLatest(Constants.GET_COUNTRIES, getCountries);
}

export default unifiedSaga;