import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
// all packages and others image and icon should be removed from dashboard component
// import GoogleComponent from './unified/google-component';
// import FacebookComponent from './unified/facebook-component';
// import VideoTestimonialComponent from './unified/video-testimonial-component';
// import TwitterComponent from './dashboard/twitter-component';
// import Login from './signup-login/login';
import Profile from './profile/profile';
import UnifiedComponent from './unified/unified-inbox';
// import CustomerFeedbackComponent from './unified/customer-feedback';
import PrivateRoute from './PrivateRoute';
import Layout from './components/Layout/layout';
import Gallery from './unified/gallery';
// import { account_routes, user_routes, workspace_routes } from './pages/page-routes';
import Workspaces from './pages/Settings/Integrations/Workspaces';
import SignUpLogin from './signup-login/signup-login';
import AllMessagesManagementComponent from './unified/all-messages-components';
import CampaignTemplate from './unified/campaign-template';
import Settings from './pages/Settings/Settings';
import {
    settings_menu_routes,
    // unified_sub_keys, content_menu_routes
} from './components/Layout/global-menu/menu.config';
import DashboardPage from './pages/Dashboard/Dashboard';
import LinkManagement from './pages/LinkManagement/LinkManagement';
import BotMainManagementComponent from './unified/bot-messages-components';
import ContentComponent from './components/ContentComponents/ContentComponent';
import ContentView from './components/ContentComponents/ContentView';
// import SignUpDashboard from './pages/Dashboard/SignUpDashboard';

const Routing: React.FC = () => (
    <Router>
        <Routes>
            {/* <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Login />} />        */}
            {/* Design sesh howar por ei comment uthiya dite hobe */}
            <Route path="/login" element={<SignUpLogin />} />
            {/* new requirement - hyperlink for signup */}
            {/* <Route path="/signup" element={<SignUpLogin />} />  */}
            {/* with email and password - path === "/email-login" || path === "/email-signup" */}
            <Route path="/email-signup" element={<SignUpLogin />} /> 
            <Route path="/email-login" element={<SignUpLogin />} /> 
            {/* signup-login */}
            {/* <Route path="/profile" element={<Profile/>} /> */}
            <Route path="/profile" element={<Layout><Profile /></Layout>} />
            {/* new dashboard design */}
            {/* ====== dashboard sign up modal from profile component -- testing==== */}
            {/* <Route path="/" element={<PrivateRoute><Layout><SignUpDashboard /></Layout></PrivateRoute>} /> */}
            {/* ====== dashboard sign up modal ==== */}
            <Route path="/" element={<PrivateRoute><Layout><DashboardPage /></Layout></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Layout><DashboardPage /></Layout></PrivateRoute>} />
            <Route path="/gallery" element={<PrivateRoute><Layout pad={true}><Gallery /></Layout></PrivateRoute>} />
            {/* {
                content_menu_routes?.map((path: any) => (
                    <Route key={path?.route} path={path?.route} element={<PrivateRoute><Layout pad={true}><ContentComponent /></Layout></PrivateRoute>} />                    
                ))
            } */}
            <Route path={"/contents/:params"} element={<PrivateRoute><Layout pad={true}><ContentComponent /></Layout></PrivateRoute>} />                    
            <Route path={"/contents/view/:params"} element={<PrivateRoute><ContentView /></PrivateRoute>} />                    
            <Route path="/promotions" element={<PrivateRoute><Layout pad={true}><CampaignTemplate /></Layout></PrivateRoute>} />
            {/* {
                unified_sub_keys?.map((path: any) => (
                    path?.includes("/unified")
                    ? <Route key={path} path={path} element={<PrivateRoute><Layout pad={true}><UnifiedComponent /></Layout></PrivateRoute>} />
                    : path === "/bot-messages"
                    ? <Route key={path} path="/bot-messages" element={<PrivateRoute><Layout pad={true}><BotMainManagementComponent /></Layout></PrivateRoute>} />
                    : <Route key={path} path={path} element={<PrivateRoute><Layout pad={true}><AllMessagesManagementComponent /></Layout></PrivateRoute>} />
                    
                ))
            } */}
            <Route path={"/all-messages"} element={<PrivateRoute><Layout pad={true}><AllMessagesManagementComponent /></Layout></PrivateRoute>} />
            <Route path={"/all-messages/:params"} element={<PrivateRoute><Layout pad={true}><AllMessagesManagementComponent /></Layout></PrivateRoute>} />
            <Route path="/bot-messages" element={<PrivateRoute><Layout pad={true}><BotMainManagementComponent /></Layout></PrivateRoute>} />
            <Route path={"/unified"} element={<PrivateRoute><Layout pad={true}><UnifiedComponent /></Layout></PrivateRoute>} />
            {/* <Route path="/all-messages" element={<PrivateRoute><Layout pad={true}><AllMessagesManagementComponent /></Layout></PrivateRoute>} /> */}
            {/* <Route path="/unified" element={<PrivateRoute><Layout pad={true}><UnifiedComponent /></Layout></PrivateRoute>} /> */}
            {/* <Route path="/twitter" element={<PrivateRoute><Layout pad={true}><TwitterComponent /></Layout></PrivateRoute>} /> */}
            
            {/* hidden section ---> new requirement of new design */}
            {/* <Route path="/video-testimonial" element={<PrivateRoute><Layout pad={true}><VideoTestimonialComponent /></Layout></PrivateRoute>} />
            <Route path="/google" element={<PrivateRoute><Layout pad={true}><GoogleComponent /></Layout></PrivateRoute>} />
            <Route path="/customer-feedback" element={<PrivateRoute><Layout pad={true}><CustomerFeedbackComponent /></Layout></PrivateRoute>} />
            <Route path="/facebook" element={<PrivateRoute><Layout pad={true}><FacebookComponent /></Layout></PrivateRoute>} /> */}
            {/* hidden section ---> new requirement of new design */}
            
            {/* new requirements ---> new design */}
            <Route path="/link-management" element={<PrivateRoute><Layout><LinkManagement /></Layout></PrivateRoute>} />
            {/* new requirements --->new design */}
            
            {/* new requirements ---> hidden section */}
            {/* ====== old design ===== start ===== */}
            {/* user-management */}
            {/* {
                user_routes?.map((route) => {
                    return route.route_children?.map((child, idx) => (
                        <Route key={idx} path={route.path+child.path} element={<PrivateRoute><Layout><route.component /></Layout></PrivateRoute>} />

                    ))
                })
            } */}
            

            {/* account-management */}
            {/* {
                account_routes?.map((route) => {
                    return route.route_children?.map((child, idx) => (
                        <Route key={idx} path={route.path+child.path} element={<PrivateRoute><Layout><route.component /></Layout></PrivateRoute>} />

                    ))
                })
            } */}

            {/* workspace-management */}
            <Route path="/workspace/integration/facebook" element={<PrivateRoute><Layout><Workspaces/></Layout></PrivateRoute>} />
            {/* {
                workspace_routes?.map((route, index) => {
                    if (route.route_children?.length === 0) {
                        return <Route key={index} path={route.path} element={<PrivateRoute><Layout><route.component /></Layout></PrivateRoute>} />
                    } else {
                        return route.route_children?.map((child, idx) => (
                    <Route key={idx} path={route.path+child.path} element={<PrivateRoute><Layout><route.component /></Layout></PrivateRoute>} />
                        ))
                    }
                })
            } */}
            {/* new requirements ---> hidden section */}

            {/* ====== old design ===== End ===== */}

            {/* new requirement ---> new design */}
            {/* Settings */}
            {
                settings_menu_routes?.map((route: any, idx: number) => (
                    <Route key={idx} path={route?.url} element={<PrivateRoute><Layout><Settings>{route.component ? <route.component /> : null}</Settings></Layout></PrivateRoute>} />
                ))
            }
            <Route path="*" element={<div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <h5>404 | Page not found</h5>
            </div>} />
        </Routes>
    </Router>
)

export default Routing;
