import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import Constants from '../constants';
import axios from "axios";
import {
    getRequestToken, setFacebookConnection, setGoogleAccessData,
    setLoginError, setGoogleSignUpData, setIntegrationConnectionData, 
    setTwitterAuthData, setTwitterConnection, 
    setUserPermissions, 
    setWhatsAppConnectedUsers, 
    triggerGetIntegrationConnections, 
    setIntegrationConnectedAccounts, 
    getIntegrationConnectedAccounts 
} from "./loginReducer";
import { resetUnifiedInbox, setErrorMessage, setSuccessMessage, twitterMentions } from '../unified/reducer';
import { auth_url, api_url, api_version } from '../utils/server';
import { generateAccessToken } from '../profile/reducer';
// import Cookies from 'js-cookie';
import { getFacebookConnectionPages, toggleShowFbPageMapModal } from '../pages/Settings/WorkspaceSettings/workspaceReducer';
import { getKeyValue } from '../utils/indexedDBManagementService';

//twitter

// const url = 'https://app.clapup.me';
const url = auth_url;
const devURL = url + '/api/v1';
const requestTokenURL = `${url}/request_token`;
const accessTokenURL = `${url}/access_token`;
const verifyTokenURL = `${url}/verify_twitter`;

const token = 'AAAAAAAAAAAAAAAAAAAAAFAXUAEAAAAAWWJcGiSWHJtoLJaJdTHSkjCZ8LU%3Du8wtPEV5mftIPmYAqPbT6JrIl7HZWQgvCv4Ex8Fyu6vgd1mLt3';

const toQueryString = (params: any) => {
    return (
        '?' +
        Object.entries(params)
            .map(
                ([key, value]: any) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join('&')
    );
}

function* requestToken(action: any): Generator<any> {
    // const redirect = `https://localhost:4000`;
    const redirect = `https://${window.location.host}/profile`;

    // const redirect = 'https://dashboard.clapup.me/';
    // const requestParams = toQueryString({ oauth_callback: redirect });
    // https://app.clapup.me/request_token?oauth_callback=https://auth.clapup.me/profile
    const requestParams = redirect;
    console.log({params: requestTokenURL+ "?oauth_callback=" + requestParams})
    try {
        const { data }: any = yield call(axios.get, `${requestTokenURL+ "?oauth_callback=" + requestParams}`);
        console.log('RequestToken twitter ==> ', {data});
        
        yield put(getRequestToken(data));
    }
    catch (error) {
        console.log('request error', error);
    }
}

function* accessToken(action: any): Generator<any> {
    const { oauth_token, secret, oauth_verifier } = action.payload;
    const headers = { Authorization: `Bearer ${token}` };
    const accessParams = toQueryString({
        oauth_token: oauth_token,
        oauth_token_secret: secret,
        oauth_verifier: oauth_verifier,
    });
    
    // const verifyParams = toQueryString({
    //     oauth_token: oauth_token,
    //     oauth_token_secret: secret
    // });
    // console.log(accessParams, 'access token')
    try {
        if (oauth_verifier) {
            const { data }: any = yield call(axios.get, `${accessTokenURL + accessParams}/?code=${window.location.host}`, { headers: headers });
            const { screen_name, user_id } = data.authenticatedData;
            // console.log('access token', data)
            // sessionStorage.setItem('twitterUserId', user_id)
            const authPayload = {
                "twitterUserId": user_id,
                "twitterUserName": screen_name,
                "twitterUserEmail": "kuber.mannur@gmail.com",
                "twitterProviderKey": user_id,
                "twitterProviderName": "TWITTER",
                "twitterProviderDisplayName": "TWITTER"
            }

            try {
                const { data }: any = yield call(axios.post, `${devURL}/twitter/auth`, authPayload);
                // console.log('clapupUserId', data);
                const mentions: any = { "twitterUserId": user_id, "clapupUserId": data.clapupUserId };
                sessionStorage.setItem('twitter', mentions.twitterUserId)
                yield put(twitterMentions(mentions));
                try {
                    // const { data }: any =
                    yield call(axios.get, `${verifyTokenURL + accessParams}`, { headers: headers });
                    // console.log("verify twitter", data)

                }
                catch (error) {

                }
            }
            catch (error) {

            }
        }
    }
    catch (error) {
        console.log('access token', error)
    }
}


function* triggerTwitterAuthData(action: any): Generator<any> {
    const { oauth_token, secret, oauth_verifier } = action.payload;
    const headers = { Authorization: `Bearer ${token}` };
    const accessParams = toQueryString({
        oauth_token: oauth_token,
        oauth_token_secret: secret,
        oauth_verifier: oauth_verifier,
    });

    try {
        if (oauth_verifier) {
            const { data: authData }: any = yield call(axios.get, `${accessTokenURL + accessParams}/?code=${window.location.host}`, { headers: headers });
            // const { screen_name, user_id } = authData?.authenticatedData;
            console.log('access token', authData);
            yield put(setTwitterAuthData(authData?.authenticatedData));
            // sessionStorage.setItem('twitterUserId', user_id)
            // const authPayload = {
            //     "twitterUserId": user_id,
            //     "twitterUserName": screen_name,
            //     "twitterUserEmail": "kuber.mannur@gmail.com",
            //     "twitterProviderKey": user_id,
            //     "twitterProviderName": "TWITTER",
            //     "twitterProviderDisplayName": "TWITTER"
            // }

            // try {
            //     const { data }: any = yield call(axios.post, `${devURL}/twitter/auth`, authPayload);
            //     console.log('Auth Data', data);
            //     const mentions: any = { "twitterUserId": user_id, "clapupUserId": data.clapupUserId };
            //     sessionStorage.setItem('twitter', mentions.twitterUserId)
            //     yield put(setTwitterAuthData(mentions));
            //     try {
            //         const { data }: any = yield call(axios.get, `${verifyTokenURL + accessParams}`, { headers: headers });
            //         console.log("verify twitter", data)

            //     }
            //     catch (error) {

            //     }
            // }
            // catch (error) {

            // }
        }
    }
    catch (error) {
        console.log('access token', error)
    }
}

// user permission list

function* getUserPermissionList(action: any): any {
    // debugger
    // console.log("getPermissionList action payload ==159==> ", action.payload)
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(axios.post, api_url + `/api/${api_version}/ClapupIdentity/GetPermissionList`, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setUserPermissions(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* triggerGoogleSignUp(action: any): any {
    try {
        const { data } = yield call(axios.post, api_url + `/api/${api_version}/ClapupIdentity/GoogleSignUp`, action.payload);
        yield put(setGoogleSignUpData(data));
    }
    catch (error: any) {
        console.log('Google sign up error', error);
        yield put(setGoogleSignUpData(error));
    }
}

function* triggerGoogleLogin(action: any): any {
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/ClapupIdentity/GoogleSignIn`,
            action.payload
        );
        // const { token, refreshToken } = data;
        // const { data: token_res } =  yield call(axios.post, auth_url + `/api/v1/set-new-token`, { token, refreshToken });
        // if (token_res?.status) {
        //     Cookies.remove('refresh_token');
        // }
        // console.log({ token_res });
        if (status === 200) {
            const { inviteId } = action.payload || {};
            if (inviteId) {
                const { clapupUserId, loginDetails, token: access_token}: any = data || {};
                const details = loginDetails?.length ? loginDetails[0] : null;
                if (details) {
                    const accessed_user_details = yield call(getKeyValue, "accessed_user_details");
                    const { workspaceId }: any = details || {};
                    const convert_to_clap_up_user_payload = {
                        userEmail: accessed_user_details?.email,
                        workspaceId: workspaceId,
                        inviteId: inviteId,
                        clapupUserId: clapupUserId
                    };
                    const { data: convert_data, status: convert_status } = yield call(axios.post,
                        api_url + `/api/${api_version}/ClapupIdentity/ConvertToClapupUser`,
                        convert_to_clap_up_user_payload,
                        {
                            headers: {
                            'Authorization': `Bearer ${access_token}`,
                            'Content-Type': 'application/json'
                            }
                        }
                    );
                    if (convert_status === 200) {
                        yield put(setGoogleAccessData(data));
                    }  
                }
            } else {
                yield put(setGoogleAccessData(data));
            }
        }

    }
    catch (error) {
        console.log('Google login error', error);
        // yield put(setGoogleAccessData(error));
        yield put(setErrorMessage("Server Error: 500"));
        yield put(setLoginError(true));
    }
}

function* triggerTwitterIntegration(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(axios.post, api_url + `/api/${api_version}/twitter/ConnectTwitter`, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setTwitterConnection(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* findIntegrationConnectedAccounts(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { wuid: workspaceId } = logged_in_user_secret_data || {};
        if (!workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        const url = api_url + `/api/${api_version}/teams/GetConnectedAccountsDetails?workspaceId=${workspaceId}`;
        const { data, status } = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            const { accountDetails } = data || {};
            yield put(setIntegrationConnectedAccounts(accountDetails));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* disconnectConnectedAccount(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { clapupUserId, channelId } = action.payload || {};
        if (!clapupUserId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!channelId) {
            yield put(setErrorMessage("Channel id is required!"));
            return;
        }
        const url = api_url + `/api/${api_version}/teams/DisconnectChannel`;
        const { status } =yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });

        if (status === 200) {
            yield put(getIntegrationConnectedAccounts());
        }
        

    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* triggerIntegrationConnections(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { wuid: workspaceId } = logged_in_user_secret_data || {};
        if (!workspaceId) {
            return;
        }
        const { data, status } = yield call(axios.post, api_url + `/api/${api_version}/clapup/getSocialLogin?workspaceId=${workspaceId}`, {}, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setIntegrationConnectionData(data?.Table));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* triggerIntegrationDisConnect(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { status } = yield call(
            axios.post, 
            api_url + `/api/${api_version}/clapup/disconnectSocialLogin`,
            {
                provider: action?.payload?.provider,
                providerUserId: action.payload?.providerUserId,
                workspaceId: action.payload?.workspaceId
            },
            {
                headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(resetUnifiedInbox());
            // yield put(triggerGetIntegrationConnections());
            yield put(getIntegrationConnectedAccounts());
            
            if (action.payload.Provider?.totoLowerCase() === 'facebook') {
                yield put(getFacebookConnectionPages(logged_in_user_secret_data?.wuid));
                yield put(toggleShowFbPageMapModal(false));
                yield put(setFacebookConnection(null));
            }
            yield delay(2000);
            yield put(setSuccessMessage("Disconnected Successfully"));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* facebookIntegrationConnections(action: any): any {
    // return;
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/${api_version}/facebook/ConnectFacebookAccount`,
            action.payload,
            {
                headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
                }
            }
        );        
        if (status === 200) {
            sessionStorage.setItem("fb_user_id", data?.facebookUserId)
            yield put(setFacebookConnection(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// function* facebookIntegrationDisConnect(action: any): any {
//     try {
    // const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
//         const { data } = yield call(
//             axios.post,
//             api_url + `/api/${api_version}/clapup/disconnectSocialLogin?Provider=${action?.payload?.Provider}&ProviderUserId=${action.payload?.ProviderUserId}`
//         );
//         console.log('Integration connections Data ==> ', data);
        
//         yield put(setIntegrationConnectionData(data?.Table));

//     }
//     catch (error) {
//         console.log('Integration connections error', error);
//     }
// }

// whatsapp
function* getWhatsAppConnectionUsers(action: any): any {
    try {
        // const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { data } = yield call( axios.get,
            api_url + `/api/${api_version}/WhatsApp/GetWhatsAppRegisteredNumbers?workspaceId=${action?.payload}`
        );
        console.log('Integration connections Data ==> ', data);
        
        yield put(setWhatsAppConnectedUsers(data?.registeredNumberDetails));

    }
    catch (error) {
        console.log('Integration connections error', error);
    }
}

function* triggerWhatsappDisConnect(action: any): any {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        const { status } = yield call(
            axios.post, 
            api_url + `/api/${api_version}/clapup/disconnectSocialLogin`,
            {
                Provider: action?.payload?.Provider,
                ProviderUserId: action.payload?.ProviderUserId,
                WorkspaceId: action.payload?.WorkspaceId
            },
            {
                headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(setWhatsAppConnectedUsers([]));
            // yield put(triggerGetIntegrationConnections(workspaceId));
            // if (action.payload.Provider?.totoLowerCase() === 'facebook') {
            //     yield put(getFacebookConnectionPages());
            //     yield put(toggleShowFbPageMapModal(false));
            //     yield put(setFacebookConnection(null));
            // }
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

//watcher saga
function* loginSaga() {
    yield all([
        takeEvery(Constants.GET_USER_PERMISSION_LIST, getUserPermissionList),
        takeEvery(Constants.TWITTER_LOGIN_TRIGGER, requestToken),
        takeEvery(Constants.TWITTER_ACCESS_TOKEN, accessToken),
        // mmhk-region
        takeEvery("DISCONNECT_CONNECTED_ACCOUNT", disconnectConnectedAccount),
        takeEvery(Constants.TRIGGER_GOOGLE_SIGN_UP, triggerGoogleSignUp),
        takeEvery(Constants.TRIGGER_GOOGLE_LOGIN, triggerGoogleLogin),
        // integration
        takeEvery(Constants.GET_CONNECTED_ACCOUNTS, findIntegrationConnectedAccounts),
        takeEvery(Constants.TRIGGER_TWITTER_AUTH_DATA, triggerTwitterAuthData),
        takeEvery(Constants.TRIGGER_TWITTER_INTEGRATION, triggerTwitterIntegration),
        takeEvery(Constants.TRIGGER_FACEBOOK_INTEGRATION, facebookIntegrationConnections),
        takeEvery(Constants.TRIGGER_GET_CONNECTIONs, triggerIntegrationConnections),
        takeEvery(Constants.TRIGGER_DISCONNECT_INTEGRATION_CONNECTION, triggerIntegrationDisConnect),
        
        // whatsapp
        takeEvery(Constants.GET_WHATS_APP_CONNECTED_USERS, getWhatsAppConnectionUsers),
        takeEvery(Constants.DISCONNECT_WHATS_APP, triggerWhatsappDisConnect),
    ]);
}

export default loginSaga;