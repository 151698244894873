import React, {useEffect} from 'react'
import queryString from 'query-string';
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../../store';
const Workspaces: React.FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const query_string = window.location.search;
    // sessionStorage.setItem("app_connect", "google-connection");
    const connection_type = sessionStorage.getItem("app_connect");
    const { facebook_connection_data, logged_in_user_secret_data}: any = useSelector((state: RootState) => state?.login);
    useEffect(() => {
        try{
            // const query = query_string.replace('?ws_settings=', '');
            console.log({ connection_type });
            // console.log({path, query});
            // const { access_token, code }: any = queryString.parse(
            const { code }: any = queryString.parse(
            window.location.href.split("?")[1]
            );
            console.log({ code });
            if (code) {
                // const redirectUri = `${window.location.origin}/workspace/integrations`;
                const redirectUri = `${window.location.origin}/settings/integrations`;
                const dataObj: any = {
                  code,
                  redirectUri: encodeURIComponent(redirectUri),
                  workspaceId: logged_in_user_secret_data?.wuid,
                  clapupUserId: logged_in_user_secret_data?.cuid,
                };
                console.log({ dataObj });
                // dispatch(triggerFacebookConnection(dataObj));
                // history("/workspace/integrations");
                history("/settings/integrations");
              }
            // if (access_token) {
            //     sessionStorage.setItem("fb_access_token", access_token);
            //     axios.get(`https://graph.facebook.com/me?fields=name,id,gender,location,picture,email,birthday&access_token=${access_token}`)
            //     .then((res) => {
            //     // console.log('response  ======71=====>', res);
            //     if (res?.data) {
            //         console.log("response_data  ======48=====>", res.data);
            //         const facebookObj: any = {
            //             facebookUserId: res?.data?.id,
            //             facebookUserName: res?.data?.name,
            //             clapupUserId: sessionStorage.getItem("cuid"),
            //             roleId: sessionStorage.getItem("roleId"),
            //             workspaceId: sessionStorage.getItem("wuid"),
            //             authAccessToken: access_token,
            //         };
            //         res?.data?.email
            //         ? (facebookObj.facebookUserEmail = res.data.email)
            //         : (facebookObj.facebookUserEmail =
            //             "mmhk.mmhkitsforyou@gmail.com");
            //         console.log({ facebookObj });
                    
            //         // dispatch(getProfileInfo());
            //         dispatch(triggerFacebookConnection(facebookObj));
            //         dispatch(toggleShowFbPageMapModal(true));
            //         // get_integration_connections();
            //         dispatch({
            //             type: "SET_INTEGRATION_TYPE",
            //             payload: "facebook",
            //         });
            //         sessionStorage.setItem("fb_user_id", res?.data?.id);
            //         sessionStorage.removeItem("app_connect");
            //         sessionStorage.removeItem("isLoading");
            //         sessionStorage.removeItem("fb_access_token");
            //         // history("/workspace/integrations");
            //     }
            //     })
            //     .catch((err) => {
            //         console.log("err data  =========>", err);
            //     });
            
            // }
        } catch (err: any) {
            console.log("Error fb connection: ", err);
        }
    }, [dispatch, query_string, connection_type]);

    useEffect(() => {
        if (facebook_connection_data?.length) {
            history("/settings/integrations");
            // history("/workspace/integrations");
        }
    }, [history, facebook_connection_data?.length]);
    return (
        <div className='menu-container'>
            <div style={{height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner animation="border" variant="primary" /> 
            </div>
        </div>
    )
}

export default Workspaces;
