import { takeEvery, put, call, all } from 'redux-saga/effects';
import axios from "axios";

import { 
    setUserList, 
    setIsInvitationSent, 
    setUpdateStatus,
    deleteUser,
    setConvertedClapUpUser,
    getUsers,
} from './userManagementReducer';
import Constants from '../../../constants';
import { api_url, api_version } from '../../../utils/server';
import { generateAccessToken } from '../../../profile/reducer';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
import { getKeyValue } from '../../../utils/indexedDBManagementService';

// Worker Saga:
function* findUsers(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUsersSaga action===>", action);
    try {
        const { clapupUserId, roleId, workspaceId } = action?.payload;
        if (!clapupUserId || !roleId || !workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/GetWorkspaceUsers?clapupUserId=${clapupUserId}&roleId=${roleId}&workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
            }
        });
        // console.log('findUsers===user manager saga==>', data);
        if (status === 200) {   
            yield put(setUserList(data?.map((user: any, idx: number) => {
                return {
                    ...user,
                    idx: (idx + 1)
                }
            })));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateUserRole(action: any): Generator<any> {
    // console.log("header -> Worker saga => updateUserRole action===>");
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/AddUsersToRoles`;
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            // console.log('updateUserRole===user manager saga==>', data)
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* inviteUser(action: any): Generator<any> {
    // console.log("header -> Worker saga => inviteUser action===>", action);
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/InviteUser `;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            // console.log('inviteUser===user manager saga==>', data)

            const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
            const accessed_data = {
                clapupUserId: logged_in_user_secret_data?.cuid,
                workspaceId: logged_in_user_secret_data?.wuid,
                roleId: logged_in_user_secret_data?.roleId,
            }
            yield put(setIsInvitationSent(data ? true : false));
            yield put(getUsers(accessed_data));

        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateUserStatus(action: any): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/UpdateUserStatus`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log('updateUserStatus===user manager saga==>', data);
        if (status === 200) {   
            if(action.payload.status === "Delete"){
                yield put(deleteUser(data));
                yield put(setSuccessMessage("Deleted Successfully"));
            } else{
                yield put(setUpdateStatus({...action.payload, status: action.payload.status}));
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// convert to clapup user
function* convertToClapupUser(action: any): Generator<any> {
    try {
        const logged_in_user_secret_data = yield call(getKeyValue, "logged_in_user_secret_data");
        let url = api_url + `/api/${api_version}/ClapupIdentity/ConvertToClapupUser`;
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            yield put(setConvertedClapUpUser(data));
        } 
    } catch (error) {
        const { response, message }: any = error;
        yield put(setErrorMessage(message));
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* userManagementSaga() {
    
    yield all([
      takeEvery(Constants.CONVERT_TO_CLAPUP_USER, convertToClapupUser),
      takeEvery(Constants.GET_USER_REQUEST, findUsers),
      takeEvery(Constants.UPDATE_USER_ROLE, updateUserRole),
      takeEvery(Constants.UPDATE_STATUS, updateUserStatus),
      takeEvery(Constants.INVITE_USER, inviteUser),
    ]);
}

export default userManagementSaga;

