import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {

  // // new requirements
  // setFolderPathsToIndexDB,
  setOpenGlobalModal,
} from '../reducer';
import { getImage } from '../../../unified/image_service';
import { DebounceInput } from 'react-debounce-input';
import { setErrorMessage } from '../../../unified/reducer';
import { takingCharacterAndUnderScoreAndNumberOnlyOneSpace } from '../../../utils/convertingFunction';

const CreateFolderForm = ({ parentCallback }: any) => {
  const {
    // open_global_drawer,
    open_global_modal,
  }: any = useSelector((state: RootState) => state?.contentState);
  const [folderType, setFolderType] = useState<any>("simple");
  const [folderName, setFolderName] = useState<any>("")
  const dispatch = useDispatch();
  useEffect(() => { 
    if (open_global_modal) {
      setFolderName("");
    }
  }, [open_global_modal]);

  // const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;
  const handleCreateFolder = () => {
    console.log({folderName});
    
    if (!folderName?.trim()) {
      return;
    }
    parentCallback(folderName?.trim());
  }

  const handleCancel = () => {
    dispatch(setOpenGlobalModal(null));
  };

  return (
    <React.Fragment>
      <div className="create-folder-form">
        <h3>Create Folder</h3>

        <div className="radio-action-container">
          <div className="radio-action">
            <img
                onClick={() => setFolderType("simple")}
                src={
                    getImage(
                        folderType === "simple"
                        ? "active-radio-button"
                        : "inactive-radio-button"
                    )
                }
                alt="tab-icon"
            />
            <div onClick={() => setFolderType("simple")}>Simple</div>
          </div>
          <div className="radio-action">
            <img
              style={{cursor: 'not-allowed'}}
              onClick={() => {
                // setFolderType("Advance");
              }}
              src={
                  getImage(
                      folderType === "Advance"
                      ? "active-radio-button"
                      : "inactive-radio-button"
                  )
              }
              alt="tab-icon"
            />
            <div style={{cursor: 'not-allowed'}}>Advance</div>
          </div>
        </div>
    
        <div className="requirement-description">
          {"If you don’t need any custom fields/metadata, You can choose simple directory structure. If you need custom fields/metadata for this directory and files inside, you need to choose advanced directory option."} 
        </div>
        
        <div className="form-item">
          <DebounceInput
            type="text" 
            placeholder={"Enter the name of folder"}
            minLength={0}
            value={folderName}
            autoFocus={true}
            debounceTimeout={300}
            onChange={(e: any) => {
              const { value } = e.target;
              if (value?.length > 45) {
                  dispatch(setErrorMessage("Folder name should be less than 25 characters"));
                  return;
              }
              
              const name_value = takingCharacterAndUnderScoreAndNumberOnlyOneSpace(value);
              setFolderName(name_value);
              // (e.target.value)
            }} 
          />
        </div>

        <div className="action-buttons">
          <div
            className="text-button"
            onClick={() => handleCancel()}
          >
            Cancel
          </div>
          <div
            className="text-button"
            onClick={() => handleCreateFolder()}
          >
            Create
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateFolderForm;

