import React, { useState } from 'react';
import './ConversationComponent.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';
// import CloseModalForm from './ConversationHeaderModalForms/CloseModalForm';
// import MoveModalForm from './ConversationHeaderModalForms/MoveModalForm';
// import ShareModalForm from './ConversationHeaderModalForms/ShareModalForm';
import CommonModalCustomWidth from '../../../../CommonModal/CommonModalCustomWidth';
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, setSuccessMessage } from '../../../../../unified/reducer';
import { generateAccessToken } from '../../../../../profile/reducer';
import axios from 'axios';
import { inbox_api_url } from '../../../../../utils/server';
import CloseModalForm from '../../../../Dialogs/ConversationHeaderModalForms/CloseModalForm';
import MoveModalForm from '../../../../Dialogs/ConversationHeaderModalForms/MoveModalForm';
import ShareModalForm from '../../../../Dialogs/ConversationHeaderModalForms/ShareModalForm';

const ConversationCommonHeader = ({data_fields, conversation, type, handleParentCallback}: any) => {
  const {
    username_1, username_2,
    user_profile_1, user_profile_2,
    star_ratings, tag_text,  // optional props fields
    action_buttons, // optional props fields
    provider,
  } = data_fields || {};
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  const {
    all_messages_details_assignee_users,
    assigned_to_me_details_assignee_users,
    shared_with_me_details_assignee_users,
    shared_copies_details_assignee_users,
    archive_details_assignee_users
  } = useSelector((state: any) => state?.unified);
  const details_assignee_users = type === "archive-messages"
                                ? archive_details_assignee_users
                                : type === "shared-copies-messages"
                                ? shared_copies_details_assignee_users
                                : type === "shared-with-me-messages"
                                ? shared_with_me_details_assignee_users
                                : type === "assigned-to-me-messages"
                                ? assigned_to_me_details_assignee_users
                                : all_messages_details_assignee_users;
                                
  const dispatch = useDispatch();
  const { setting_teams } = useSelector((state: any) => state?.teamState);
  const [closeAction, setCloseAction] = useState<any>(null);
  const [moveAction, setMoveAction] = useState<any>(null);
  const [shareAction, setShareAction] = useState<any>(null);

  const handleCancel = () => {
    setCloseAction(null);
    setMoveAction(null);
    setShareAction(null);
  }

  {/* close modal form */}
  return (
    <React.Fragment>
      {/* close modal form */}
      <CloseModalForm
        showCloseForm={closeAction}
        data_fields={conversation}
        handleCancel={() => setCloseAction(false)}
        handleParentCallback={(data: any) => {
          // handleContinueEmailCommunication(data);
        }}
      />
      

      {/* Move modal form */}
      <CommonModalCustomWidth 
        modal_visible={moveAction} 
        setModalVisible={(flag: any) => setMoveAction(flag)}
        centered={true}
        // okText="Send"
        // cancelText="Cancel"
        padding={"0"}
        class_name="move-modal-form-wrap"
        footer={null}
      >
        <MoveModalForm
          data_fields={moveAction}
          conversation={conversation}
          setting_teams={setting_teams}
          handleCancel={handleCancel}
          handleParentCallback={(data: any) => console.log({data})}
        />
      </CommonModalCustomWidth>
      
      {/* share modal form */}
      <CommonModalCustomWidth 
        modal_visible={shareAction} 
        setModalVisible={(flag: any) => setShareAction(flag)}
        centered={true}
        // okText="Send"
        // cancelText="Cancel"
        padding={"0"}
        class_name="share-modal-form-wrap "
        footer={null}
      >
        <ShareModalForm
          conversation={conversation}
          details_assignee_users={details_assignee_users}
          setting_teams={setting_teams}
          data_fields={shareAction}
          handleCancel={handleCancel}
          handleParentCallback={(data: any) => console.log({data})}
        />
      </CommonModalCustomWidth>

      <div className="conversation-header">
        <div className="user-info">
            <img className="user-profile-1" src={user_profile_1 || getGenerateApiImageByName(username_1)} alt="Profile" />
            <div className="user-details">
              <div className="username-tag-stars">
                <div className="username-1">{username_1 || ""}</div>
                {
                  tag_text ? <div className="tag">{tag_text || "asked to workspace-name"}</div> : null
                }
                
                {
                  Number(star_ratings)
                  ?   
                    <div className="stars-rating-container">
                      <div className="stars">
                        {
                          Array.from(Array(Math.floor(parseFloat(star_ratings))).keys()).map((_: any, index: number) => {
                          return (
                              <img
                                  key={index}
                                  src={getImage("star")}
                                  alt="star"
                              />
                          );
                          })
                        } 
                      </div>
                      {/* <span className="stars">★★★★☆</span> */}
                      {/* <div className="rating">{star_ratings}/5</div> */}
                    </div>
                  : null
                }
              </div>
              <img className="user-profile-2" src={user_profile_2 || getGenerateApiImageByName(username_2 || "Username 2")} alt="user-2" />
              <div className="user-name-2">{username_2 || ""}</div>
            </div>
        </div>

        <div className="actions">
          {
            action_buttons?.map((btn: any, idx: any) => (
              <button
                key={idx}
                className={`action-btn ${type === "archive-message" ? "disabled" : ""}`}
                onClick={() => {
                  if (type === "archive-message") {
                    return;
                  }
                  if (btn?.text === "Close") {
                    setCloseAction(true);
                  }
                  else if (btn?.text === "Move"){
                    setMoveAction(true);
                  }
                  else if (btn?.text === "Share"){
                    setShareAction(true);
                  }
                }}
              >
                {btn?.text || btn?.icon}
              </button>
            ))
          }
            {/* <button className="action-btn">Close</button>
            <button className="action-btn">Share</button>
            <button className="action-btn">Move</button> */}
            <img className="provider-logo" src={getImage(provider || "google")} alt="provider" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConversationCommonHeader;
