import React, { useEffect, useState } from "react";
// import { Tooltip } from 'antd';
import FeedLeftBar from "../FeedLeftBar/FeedLeftBar";
import { Col, Row, Spinner } from "react-bootstrap";
// import Button from "../../button/button-container";
import '../AllFeeds.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import search from '../../../assets/icons/search-icon.svg';
import emptyBox from '../../../assets/img/Empty.svg';
// import user_avatar from '../../assets/icons/user-avatar.svg';
// import google_logo from '../../assets/icons/google-logo.svg';
// import cross_icon from '../../../assets/icons/cross-svgrepo.svg';
import Search from "../../Search/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import { changePage, toggleBotInboxUpdated, toggleIsAiBotInboxEmpty, setBoxId, toggleReplyingOn, triggerDeleteWallUnifiedData, triggerSearchWallData, triggerWallUnified, twitterMentions } from "../../../unified/reducer";
import { RootState } from "../../../store";
// import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import CommonModal from "../../CommonModal/CommonModal";
// import RequestReview from "../../RequestReview/RequestReview";
// import { bio_url } from "../../../utils/server";
// import EmbedCode from "../../EmbedCode/EmbedCode";
// import { DebounceInput } from "react-debounce-input";
import Dialog from "../../Dialogs/Dialog";
import BotMessagesConversations from "../ConversationDetails/AllConversations/BotMessagesConversations";
import MessagesDetails from "../ConversationDetails/AllDetails/MessagesDetails";
import MessagesInboxCard from "../InboxCard/MessagesInboxCard";
import FeedMessageTabs from "./FeedMessageTabs";
import SkeletonLoader from "../../Loaders/SkelitonLoader/SkeletonLoader";
// const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

interface Props {
  feedsEmpty?: boolean;
  feedsEmptyContent?: string;
  buttonContent?: string;
  imagePath?: any;
  handleTriggerData?: any;
  triggerData?: any;
  feedsContent?: any;
  type?: any;
  isTagChanged?: any;
  handleSearchData?: any;
}

const BotMessageFeeds: React.FC<Props> = ({ type, handleSearchData, handleTriggerData, triggerData }) => {
  const route_path = window.location.pathname;
  // const path = window.location.pathname;
  const query_string = window.location.search;
  const tag_type = query_string?.includes("open") ? "open"
  : query_string?.includes("inProgress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("starred") ? "starred" 
  : "all";
  const navigate = useNavigate();
  const [sText, setSearchText] = useState('');
  const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
  
  // useEffect(() => {
  //   if(!sText) {
  //     dispatch({type: "REMOVE_SEARCH_TEXT"});
  //     dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
  //     dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
  //   }
  // }, [sText])
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const {integrationConnections}: any = useSelector((state: RootState) => state?.login);
  const {
    botInboxData: bot_inbox_data, is_main_bot_inbox_updated, is_empty_ai_bot_inbox,
    searchText, searchPayload, searchStartDate, searchEndDate, searchFilterStatus,
    isReplyingOn, pageRoute
  }: any = useSelector((state: RootState) => state?.unified);
  const dispatch = useDispatch();

  // const {}: any = bot_inbox_data || {};

  // const wallUnifiedData: any[] = CFWall || [];

  const {
    main, statDetails, chatDetails,
  }: any = bot_inbox_data || {};
    
    
  const botInboxData:
    any[] = tag_type === "all"
      ? main || []
      // : tag_type === "reviews"
      // ? CFReviews_CFReviews
      : main || [];
  
  const [isBotLoading, setIsBotLoading] = useState<boolean>(false);
  const {params} = useParams();
  useEffect(() => {
    if (botInboxData?.length) {
      setIsBotLoading(false);
    } else {
      setIsBotLoading(true);
      setTimeout(() => setIsBotLoading(false), 9000);
    }
  }, [botInboxData?.length, params]);
    
    
  
  useEffect(() => {
    if (main?.length && tag_type !== "all") {
      const main_is_not_empty_data_by_tag_type = main?.map((item: any) => {
        const userWiseChatDetailsSubs = item?.userWiseChatDetailsSubs?.filter((sub: any) => sub.chatStatus === tag_type);
        return userWiseChatDetailsSubs?.length ? { notEmpty: true } : { notEmpty: false };
      }).find((item: any) => item.notEmpty === true);
      
      // console.log({ main_is_not_empty_data_by_tag_type: main_is_not_empty_data_by_tag_type });
      if (main_is_not_empty_data_by_tag_type) {
        dispatch(toggleIsAiBotInboxEmpty(false));
      } else {
        dispatch(toggleIsAiBotInboxEmpty(true));
      }
    } else {
      dispatch(toggleIsAiBotInboxEmpty(false));
    }
  }, [query_string]);

  const nextDate: any[] = tag_type === "all"
    ? [new Date().toISOString().replace('Z', '')]
    // : tag_type === "reviews"
    // ? CFReviews_CFReviewsNextDate
    : [];
      
            
  const [stat_details, setStatDetails] = useState<any>(null);
  const [conversation, setConversation] = useState<any>(null);
  const [conversationId, setConversationId] = useState<any>(null);
  // const [wallData, setWallData] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<any>("conversations");
  const [activeTab, setActiveTab] = useState<any>("all");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  // const [isWallLoading, setIsWallLoading] = useState<boolean>(false);
  useEffect(() => {
    console.log( "Check type ======> ",{tag_type, type, botInboxData, bot_inbox_data});
    // console.log("Embed Object ======> ", embedObject);
    setData(botInboxData || []);
    dispatch(toggleBotInboxUpdated(false));
    if(botInboxData?.length && nextDate?.length){
      setHasMore(true);
      // setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      setHasMore(false);
    }
    
    
    const flag = nextDate?.filter((nd: any) => nd?.createdOn);
    console.log({flag, nextDate, botInboxData});
    
    if(botInboxData?.length && (nextDate === null || !flag?.length)){
      setIsLoading(true);
      setHasMore(false);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    
    // setData(dummyData);
  }, [
    query_string, botInboxData?.length, nextDate?.length,
    searchText, searchStartDate, searchEndDate, searchFilterStatus,
    // if signalR updated bot inbox data
    is_main_bot_inbox_updated,
  ]);
  
  useEffect(() => {
    const path = query_string.replace('?', '')?.replaceAll('&', "=");
    const path_array = path.split("=");
    if (path_array?.length > 5) {
      const [_0, _1, _2, conversation_id, _4, user] = path_array;
      if (botInboxData?.length && conversation_id) {
        const conversation = botInboxData?.find((conversation: any) => conversation?.userName === user);
        console.log({ path_array, conversation, botInboxData: botInboxData?.length });
        // console.log({conversation});
        if (!conversation) {
          navigate(`${route_path}?all=conversations`);
        }
      }
    }
  }, [botInboxData?.length]);

  const fetchMoreData = () => {
    // if (nextDate === null || !nextDate?.length) {
    //   setHasMore(false);
    //   return;
    // }
    setHasMore(true);
    // a fake async api call like which sends
    console.log({type_more: true});
    
    setTimeout(() => {
      setHasMore(false);
      handleTriggerData(triggerData, false);
      // setData(data.concat(botInboxData.slice(data?.length, data?.length + 7)));
    }, 500);
  };
  
  const filterBySearchData = (value?: any, key?: any) => {
    // dispatch
    // console.log("key, value ======> ", {key, value});

    handleSearchData(key, value);
    // setIsLoading(true);
    // if(key === "readStatus"){
    //   dispatch({type: "FILTER_BY_READ_STATUS", payload: value});
    // } else if(key === "searchText"){
    //   dispatch({type: "FILTER_BY_SEARCH_TEXT", payload: value});
    // } else{
    //   dispatch({type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value});
    // }

  };

  
  useEffect(() => {
    if (query_string) {
      setIsLoading(true);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 3000);
      const path = query_string.replace('?', '')?.replaceAll('&', "=");
      const path_array = path.split("=");
      console.log({ path_array });
      setHasMore(true);
      if ( query_string?.includes("all=conversations") || query_string?.includes("open=conversations") || query_string?.includes("closed=conversations") || query_string?.includes("inProgress=conversations") || query_string?.includes("spam=conversations")) {
        // setActiveTab("all");
        // setActivePage("conversations");
        // console.log({ path_array });
        // console.log({ query_string });
        if (path_array?.length > 5) {
          setActiveTab(path_array[0]);
          setActivePage(path_array[1]);
          const [_0, _1, _2, conversation_id, _4, user] = path_array;
          // console.log("Conversation Id ======> ", { conversation_id, user });
          setConversationId(conversation_id);
          // console.log("Conversation Id wallUnifiedData", botInboxData);
          // setConversation(null);
          const main_item = botInboxData?.find((bid: any) => bid?.userName === user);
          const current_conversations = chatDetails?.filter((conversation: any) => conversation?.ticketNo === conversation_id)?.reverse();
          // console.log("conversation", conversation);
          let stat_details = statDetails?.find((sd: any) => sd?.userName === user);
          const ticketWisedData = main_item?.userWiseChatDetailsSubs?.find((chat: any) => chat?.ticketNo === conversation_id);
          
          // const route = `${route_path}?${activeTab}=${activePage}&cov=${conversation_id}&user=${user}`;
          // console.log("Conversation Id ======> ", { route, stat_details });
          console.log("bot_stat_details", {bot_inbox_data, stat_details, current_conversations, main_item, conversation_id, user, global_data: {chatDetails, statDetails}});
          // if (main_item && current_conversations?.length && stat_details) {
          if (main_item) {
            const stat_details_data: any = {
              ...stat_details,
              ...main_item,
              ...ticketWisedData,
            };
            delete stat_details_data.userWiseChatDetailsSubs;
            setStatDetails(stat_details_data);
            const conversation_data: any = {
              ...main_item,
              ...ticketWisedData,
              messages: current_conversations
            };
            delete conversation_data.userWiseChatDetailsSubs;
            
            console.log("bot_stat_filtered_details", {stat_details, conversation_data});
            setConversation(conversation_data);
          } else {
            setStatDetails(null);
            setConversation(null);
            // navigate(route_path+`?all=conversations`);
          }
          
        } else {
          setIsLoading(true);
          // setTimeout(() => {
            //   setIsLoading(false);
            // }, 2000);
          setStatDetails(null);
          setConversation(null);
          setConversationId(null);
        }
      } else {
        setStatDetails(null);
        setConversation(null);
        setConversationId(null);
        // console.log({ path_array });
        // console.log({ query_string });
        // navigate(route_path+`?all=conversations`);
      }

    }
  }, [query_string, botInboxData?.length]);

  const [currentPage, setCurrentPage] = useState<any>(null);
  useEffect(() => {
    dispatch(toggleReplyingOn(false));
    setCurrentPage(null);
  }, [query_string]);

  useEffect(() => {
    if (!pageRoute && !isReplyingOn) {
      setSearchText("");
      dispatch({ type: "REMOVE_SEARCH_TEXT" });
      dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
      dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
      // dispatch(resetUnifiedInbox());
      // dispatch(resetWallUnifiedInbox());
      setCurrentPage(null);
    }
  }, [pageRoute, isReplyingOn]);
  
  const handlePageChange = (page?: any) => {
    console.log("Page change", page);
    if (!page) {
      // dispatch(toggleReplyingOn(false));
      setCurrentPage(null);
      return;
    }
    if(page?.isFromReply){
      navigate(page?.value);
      setCurrentPage(null);
    } else {
      if (isReplyingOn) {
        dispatch(changePage(`${route_path}?${page}`));
      } else {
        setSearchText("");
        dispatch({ type: "REMOVE_SEARCH_TEXT" });
        dispatch({ type: "REMOVE_FILTER_BY_READ_STATUS" });
        dispatch({ type: "REMOVE_SEARCH_FROM_TO_DATE" });
        // dispatch(resetUnifiedInbox());
        // dispatch(resetWallUnifiedInbox());  
        // dispatch(resetUnifiedInbox());
        navigate(`${route_path}?${page}`);
        setCurrentPage(null);
      }
    }
  };


  // const handleDeleteWallConversation = (id: any) => {
  //   console.log({id});
    
  //   // const newWallData = wallUnifiedData?.filter((wd: any) => wd?.reviewId !== id);
  //   // const deleteWallRecord = wallUnifiedData?.find((wd: any) => wd?.reviewId === id);
  //   // // console.log({deleteWallRecord, newWallData});
  //   // if (deleteWallRecord) {
  //   //   const deleteWallPayload = {
  //   //     id: deleteWallRecord?.reviewId,
  //   //     clapupId: logged_in_user_secret_data?.cuid,
  //   //     workSpaceId: logged_in_user_secret_data?.wuid,
  //   //     roleId: logged_in_user_secret_data?.roleId,
  //   //     // provider: capitalize(deleteWallRecord?.provider),
  //   //     providerType: deleteWallRecord?.tag === "mentions" && deleteWallRecord?.provider === "twitter" ? "twitterMentions"
  //   //                 : deleteWallRecord?.tag === "mentions" && deleteWallRecord?.provider?.includes("facebook") ? "facebookMentions"  
  //   //                 : deleteWallRecord?.tag === "comments" ? "facebookComments" 
  //   //                 : deleteWallRecord?.provider === "videoTestimonial" ? "videoTestimonial" 
  //   //                 : deleteWallRecord?.provider === "customerFeedBack" ? "customerFeedback" 
  //   //                 : deleteWallRecord?.tag === "googleQandA" ? "googleQandA" 
  //   //                 : (deleteWallRecord?.provider === "google" && deleteWallRecord?.tag === "reviews") ? "googleReviews" 
  //   //                 : (deleteWallRecord?.provider === "facebook" && deleteWallRecord?.tag === "reviews") ? "facebookReviews" 
  //   //                 : "",
  //   //     wallType: route_path?.includes("unified") ? "inbox" : "general",
  //   //     actionFlag: 0,
  //   //   };
      
  //   //   dispatch(triggerDeleteWallUnifiedData({deleteWallPayload, data: deleteWallRecord, key: wallDataKey}));
  //   // }
  // };

  // const handleFindWallUnifiedData = () => {
  //   // dispatch wall unified data
  //   // handleTriggerUnified();
  //   console.log("active tab ======> ", activeTab);
  //   // setConversationId(null);
  //   // handlePageChange(`all=wall`);
  //   dispatch(changePage(`${route_path}?all=wall`));
  //   // handlePageChange(conversationId ? `${activeTab}=wall&conv=${conversationId}` : `${activeTab}=wall`);
  // };


  // console.log({activePage, activeTab, conversationId});
  // const [feedHover, setFeedHover] = useState<any>(false);
  // const handleFeedsHover = () => {
  //   setFeedHover(true);
  // }
  // const [isRequestedReview, setIsRequestedReview] = useState<boolean>(false);
  // const [isEmbedCode, setIsEmbedCode] = useState<any>(false);

  // wall unified data for embed code
  // const handleEmbedCodeGenerateAndCopy = () => {
  //   // const embedCode = `<iframe src="https://www.clapup.com/embed/${logged_in_user_secret_data?.cuid}" width="100%" height="100%" style="border: none;"></iframe>`;
  //   // navigator.clipboard.writeText(embedCode);
  //   // toast.success("Embed code copied to clipboard");
  //   console.log("Clicked Embed Code");
  //   // embed code generate and copy by embedObject
  //   const encoded_embed_code = btoa(JSON.stringify(embedObject));
  //   // console.log({ encoded_embed_code });
  //   const decoded_embed_code = JSON.parse(atob(encoded_embed_code));
  //   console.log({ decoded_embed_code });
  //   const api_url = bio_url + "embed_code=" + encoded_embed_code;
    
  //   // navigator?.clipboard?.writeText(api_url);
  //   setIsEmbedCode(api_url);
  // }

  

  // console.log({activePage: activePage});
  
  // const [wallBoxData, setWallBoxData] = useState<any>(null);
  // useEffect(() => {
  //   const curWallBoxData = botInboxData?.find((wd: any) => wd?.SlNo === wallBoxId);
  //   // console.log({botInboxData, curWallBoxData, wallBoxId});
  //   setWallBoxData(curWallBoxData);
  // }, [wallBoxId]);
  
  const left_top_tabs: any[] = [
    // { title: "Q & A", key: "qa" },
    // { title: "Mentions", key: "mentions" },
    // { title: "Comments", key: "comments" },
    // { title: "Reviews", key: "reviews" },
    { title: "All", key: "all" },
    { title: "Starred", key: "starred" },
    { title: "Closed", key: "closed" },
    { title: "In Progress", key: "inProgress" },
    { title: "Open", key: "open" },
  ];

  const handleSearchBotData = (searchText?: any, isSearchingWall?: any) => {
    // if (isSearchingWall === "wall") {
    //   console.log("Wall Search Text ======> ", { searchText, isSearchingWall, searchPayload });
    //   // const { readStatus, fromDate, toDate } = searchPayload;
    //   // delete searchPayload?.isFirstTime;
    //   dispatch({ type: "FILTER_BY_WALL_SEARCH_TEXT", payload: searchText });
    //   const wallSearchObject: any = {
    //     // ...searchPayload,
    //     clapupUserId: logged_in_user_secret_data?.cuid,
    //     roleId: logged_in_user_secret_data?.roleId,
    //     workspaceId: logged_in_user_secret_data?.wuid,
    //     searchText,
    //     isFirstTime: true, // Only for wall search
    //     key: 'CFWall',
    //     nextKey: 'CFWall_nextDate',
    //     nextDate: [],
    //     providerType: 'customerFeedback',
    //     tag: 'all',
    //     readStatus: "All",
    //     fromDate: "2000-01-01T00:00:00.00000",
    //     toDate: new Date().toISOString().replace("Z", ""),
    //     lastSerialNo: 0,
    //   };
    //   console.log({ WallSearchObject: wallSearchObject });
    //   dispatch(triggerWallUnified(wallSearchObject));
    //   // dispatch(triggerSearchWallData(wallSearchObject));
    // } 
  };

  const handleSeeOtherConversation = (item?: any) => {
    let stat_details = statDetails?.find((sd: any) => sd?.userName === item?.userName);
    const route = `${route_path}?${activeTab}=${activePage}&cov=${item?.ticketNo}&user=${item?.userName}`;
    // console.log({route });
    // stat_details = {
    //   ...stat_details,
    //   ...item
    // };
    // console.log("bot_", stat_details);
    // setStatDetails(stat_details);
    navigate(route);
    // if (query_string?.includes("conversations") && !query_string?.includes(item?.reviewId)) {
    //   // dispatch({type: "REMOVE_SEARCH_TEXT"});
    //   // dispatch({type: "REMOVE_FILTER_BY_READ_STATUS"});
    //   // dispatch({type: "REMOVE_SEARCH_FROM_TO_DATE"});
      
    //   // mmhk-working
    //   if (isReplyingOn) {
    //     setCurrentPage({ isFromReply: true, value: `${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}` });
    //   } else {
    //     navigate(`${route_path}?${activeTab}=${activePage}&cov=${item?.reviewId}`);
    //   }
    // }
  }

  // console.log("con-length => ", {conversation});
  
  return (
    <React.Fragment>
      {
        currentPage?.value ?
          <Dialog
            open={currentPage}
            parentCallback={(flag?: any) => handlePageChange(flag)}
            classes='dialog-container-content'
            title='Continue?'
            warning_dialog_content={"If you confirm, all your edits will be lost. Locked message will be unlocked and editor will be accessible to others."}
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
            // hover={true}
          />
        : null
        
      }
      <div
        className={`
          feeds-container w-100 position-relative
          ${
            data?.length && conversationId
              ? "feeds-conversations-details"
              : "feeds-row-mobile"
          }
        `}
        // style={{
        //   padding: data?.length && conversationId ? "0" : "2.1% 4% 0% 2%",
        // }}
      >
        <Row className={`mobile-conversations mobile-feed`}>
          <Col
            md={3}
            className={data?.length && conversationId ? "" : "feeds-section"}
          >
            {
              data?.length && conversation?.userName ? (
              <div className="conversations h-100">
                <BotMessagesConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                  conversation={conversation}
                  statDetails={stat_details}
                />
              </div>
              ) : null
            }

            {/* Search Inbox Data */}
            {!conversationId ? (
              <div>
                <Search
                  parentCallback={(value: any, key: any) =>
                    filterBySearchData(value, key)
                  }
                  input_height={32}
                  searchText={sText}
                  setSearchText={setSearchText}
                  setConversationId={setConversationId}
                  activeTab={activeTab}
                  activePage={activePage}
                />

                <FeedMessageTabs 
                  left_top_tabs={left_top_tabs}
                  activeTab={activeTab}
                  activePage={activePage}
                  handlePageChange={handlePageChange}
                />
              </div>
            ) : null}
  
            {
              // inbox feeds
              !conversationId ? (
                <div className="feeds-section-hove mt-2">
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    className="feed-scrollin"
                    scrollThreshold={0.9}
                    style={{ overflow: "scroll" }}
                    next={() => {
                      console.log(
                        `prev -> ${data?.length}, next -> ${data?.length + 20}`
                      );
                      fetchMoreData();
                    }}
                    inverse={false}
                    hasMore={hasMore}
                    loader={
                      !isLoading && hasMore && data?.length ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        </div>
                      ) : null
                    }
                    scrollableTarget="scrollableDiv"
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }

                    // height={"80vh"}
                    // height={"86vh"}
                    height={"76vh"}
                  >

                    {(!data?.length || is_empty_ai_bot_inbox) ? (
                      <div className="empty-feeds-section">
                      {isBotLoading ? (
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_: any, idx: any) => (
                          <div key={idx} className="loader-section">
                            <div className="feed-loader-container">
                              <div className="loaders-container">
                                <SkeletonLoader borderRadius="50%" width="28px" height="26px" />
                                <div className="loaders-body">
                                  <div className="line-loaders">
                                    <SkeletonLoader width="190px" height="14px"/>
                                    <SkeletonLoader width="150px" height="14px"/>
                                    <SkeletonLoader width="120px" height="14px"/>
                                  </div>
                                  <SkeletonLoader borderRadius="50%" width="15px" height="15px" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        ) : (
                          <div className="empty-section">
                            <img src={emptyBox} alt="" />
                            <h4>Empty Feeds</h4>
                          </div>
                        )}
                      </div>
                    ) : (
                      data.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <MessagesInboxCard
                              type={type}
                              handleSeeOtherConversation={handleSeeOtherConversation}
                              inbox={item}
                              activePage={activePage}
                              activeTab={activeTab}
                              routePath={route_path}
                              // wallData={wallData}
                              // setWallData={setWallData}
                            />
                          </div>
                        );
                      })
                    )}

                  </InfiniteScroll>
                </div>
              ) : null
            }

            {/* mobile bottom tab */}
            {/* {data?.length && conversationId ? null
            : !conversationId ? (
              <div className="conversation-wall-mobile ms-0 flex-row justify-content-around align-items-center mt-2">
                <div>
                  <button
                    // onClick={() =>
                    //   `${activePage}` === "wall" &&
                    //   handlePageChange(
                    //     conversationId
                    //       ? `all=conversations`
                    //       : `all=conversations`
                    //   )
                    // }
                    className={`inbox-btn${
                      query_string?.includes("conversations") ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-wall ${
                      query_string?.includes("conversations") && "inbox-wall-active"
                    } p-0 m-0`}
                  >
                    Inbox
                  </p>
                </div>
                <div>
                  <button
                    // onClick={() =>
                    //   `${activePage}` !== "wall" && handleFindWallUnifiedData()
                    // }
                    className={`coder-wall-btn${
                      activePage === "wall" ? "-active" : ""
                    }`}
                    type="button"
                  ></button>
                  <p
                    className={`mobile-inbox-wall ${
                      activePage === "wall" && "inbox-wall-active"
                    } p-0 m-0`}
                  >
                    Wall
                  </p>
                </div>
              </div>
            ) : null} */}
          </Col>
        </Row>
      </div>

      <div className="feeds-row-lg feeds-container">
        <div
          style={{
            // width: `${query_string?.includes("conversations") ? "99%" : "98%"}`,
            // height: "92vh",
            overflow: "hidden",
            // display: "flex",
            // gap: '0.4%',
          }}
          className="feed-row-lg-container"
        >
          <div className="conversation-left-side">
            <FeedLeftBar/>
          </div>
          <div className="feeds-sections">
            <FeedMessageTabs 
              left_top_tabs={left_top_tabs}
              activeTab={activeTab}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />

            <div className="feeds-section-hover">
              <InfiniteScroll
                dataLength={data?.length || 0}
                className="scrollin"
                scrollThreshold={0.9}
                style={{ overflow: "scroll", paddingRight: 2 }}
                next={() => {
                  console.log(
                    `prev -> ${data?.length}, next -> ${data?.length + 20}`
                  );
                  fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={
                  !isLoading && hasMore && data?.length ? (
                    <div className="d-flex justify-content-center align-items-center mt-1">
                      <Spinner
                        size="sm"
                        animation="border"
                        variant="primary"
                      />
                    </div>
                  ) : null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"85vh"}
                height={"88vh"}
              >
                <div className="search-bar-container">
                  <Search
                    parentCallback={(value: any, key: any) =>
                      filterBySearchData(value, key)
                    }
                    input_height={32}
                    searchText={sText}
                    setSearchText={setSearchText}
                    setConversationId={setConversationId}
                    activeTab={activeTab}
                    activePage={activePage}
                  />
                </div>

                {/* Infinity Scrolling */}

                {(!data?.length || is_empty_ai_bot_inbox) ? (
                // {(data?.length && !isLoading) || conversationId ? (
                  <div className="empty-feeds-section">
                    <div
                      className="empty-wall-section position-absolute"
                      style={{ top: "35%" }}
                    >
                      {/* {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        </div>
                      ) : (
                        <div>
                          <img src={emptyBox} alt="" />
                          <h4>Empty Feeds</h4>
                        </div>
                      )} */}
                      <div>
                        <img src={emptyBox} alt="" />
                        <h4>Empty Feeds</h4>
                      </div>
                    </div>

                    {/* <img src={imagePath} alt="" />
                      <h4>{feedsEmptyContent}</h4>
                      <Button classes="secondary" children={buttonContent} /> */}
                  </div>
                ) : (
                  data?.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <MessagesInboxCard
                          type={type}
                          inbox={item}
                          handleSeeOtherConversation={handleSeeOtherConversation}
                          activePage={activePage}
                          activeTab={activeTab}
                          routePath={route_path}
                          // wallData={wallData}
                          // setWallData={setWallData}
                        />
                      </div>
                    );
                  })
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div
            className={"conversation-sections"}
            // className={query_string?.includes("conversations") ? "wall-section col-xl-6 col-xxl-6" : "wall-section col-xl-9 col-xxl-9"}
            // md={query_string?.includes("conversations") ? 6 : 9}
            // style={{width: query_string?.includes("conversations") ? '47.3%' : "71.9%"}}
            // style={{width: '47.3%'}}
          >
            {/* {data?.length && conversationId ? ( */}
            {data?.length && conversation?.userName ? (
              <div className="conversations h-100">
                <BotMessagesConversations
                  type={type}
                  activePage={activePage}
                  activeTab={activeTab}
                  routePath={route_path}
                  conversationId={conversationId}
                  conversation={conversation}
                  statDetails={stat_details}
                />
              </div>
            ) : (
              <div className="empty-wall-section">
                <img src={emptyBox} alt="" />
                {/* <h4>Your Wall is Empty.</h4> */}
                <p>Select an item from inbox to display!</p>
              </div>
            )}
          </div>
          {
            data?.length && stat_details ? (
              <div className="conversation-details-right-side">
                <div className="bot-details-container">
                  {
                  // !isLoading &&
                    // conversation && conversationId ? (
                      <MessagesDetails type={type} statDetails={stat_details} />
                    }
                </div>
              </div>
            ) : null
          }
        </div>


        {/* <div className="conversation-wall">
          <button
            onClick={() => {
              `${activePage}` !== "conversations"
                && dispatch(changePage("/unified?all=conversations"));
                // && handlePageChange(conversationId ? `all=conversations` : `all=conversations`)
            }}
            className={`inbox-button${
              (query_string?.includes("conversations")) ? "-active" : ""
            }`}
            type="button"
          >Conversation</button>
          
          <button
            onClick={() => {
              `${activePage}` !== "wall" && handleFindWallUnifiedData()
            }}
            className={`inbox-button${
              (query_string?.includes("wall")) ? "-active" : ""
            }`}
              type="button"
          >Wall</button>

        </div> */}

        {/* old version */}
        {/* <button
          onClick={() => handlePageChange(conversationId ? `${activeTab}=conversations&conv=${conversationId}` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button"></button>
        <button
          onClick={() => (`${activePage}` === "wall") && handlePageChange(conversationId ? `${activeTab}=conversations` : `${activeTab}=conversations`)} 
          className={`inbox-btn${activePage === 'conversations' ? '-active' : ""} mt-2`} type="button">
        </button> */}
        {/* <div className="conversation-wall ms-0 d-flex flex-column justify-content-start align-items-start mt-1">
          <Tooltip title="Inbox" color="white" style={{ color: 'black !important' }} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` === "wall" &&
                handlePageChange(
                  conversationId ? `all=conversations` : `all=conversations`
                )
              }
              className={`inbox-btn${
                query_string?.includes("conversations") ? "-active" : ""
              }`}
              type="button"
            ></button>
          </Tooltip>
          <Tooltip title="Wall" color="white" style={{color: 'black !important'}} placement="leftBottom">
            <button
              onClick={() =>
                `${activePage}` !== "wall" && handleFindWallUnifiedData()
              }
              className={`coder-wall-btn${
                activePage === "wall" ? "-active" : ""
              }`}
              type="button"
              // title="Wall"
            ></button>
          </Tooltip>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default BotMessageFeeds;
