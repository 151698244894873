import React, { useState } from "react";
import "./ModalForm.scss";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_version, inbox_api_url } from "../../../utils/server";
import { setErrorMessage, setSuccessMessage } from "../../../unified/reducer";
import { generateAccessToken } from "../../../profile/reducer";
import { getImage } from "../../../unified/image_service";
// import { getImage } from "../../../../../../unified/image_service";
// import { inbox_api_url } from '../../../../../../utils/server';
// import { setErrorMessage, setSuccessMessage } from '../../../../../../unified/reducer';
// import { generateAccessToken } from '../../../../../../profile/reducer';

const getImageIconByColorTag = (color = "#000000") => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1" width="18" height="18" viewBox="0 0 18 18"
        >
            <g>
                <g>
                    <ellipse
                        cx="9" cy="9" rx="9" ry="9"
                        fill={color}
                        fillOpacity="1"
                    />
                </g>
            </g>
        </svg>
    )
};

// const dummy_teams = [
//     {
//         id: 1,
//         teamColor: "#1354EC",
//         teamName: "Primary",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 2,
//         teamColor: "#F79009",
//         teamName: "Marketing",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 3,
//         teamColor: "#F04438",
//         teamName: "Human Resource",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 4,
//         teamColor: "#039855",
//         teamName: "Developer Team",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 5,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 6,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
// ];

const MoveModalForm = ({ data_fields, conversation, setting_teams, handleCancel, handleParentCallback }: any) => {
    const [teams, setTeams] = useState<any>(setting_teams);
    const [selectedTeam, setSelectedTeam] = useState<any>(null);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    // const [selectedTeams, setSelectedTeams] = useState<any>([]);
    const dispatch = useDispatch();
    // const handleSelectTeams = (id: any) => {
    //     const already_selected = selectedTeams?.find((member_id: any) => member_id === id);
    //     // console.log({ already_selected, selectedTeams, id });
    //     if (already_selected) {
    //         const filteredSelectedTeams = selectedTeams?.filter((member_id: any) => member_id !== id);
    //         setSelectedTeams(filteredSelectedTeams);
    //     } else {
    //         const selected_teams = [...selectedTeams, id];
    //         setSelectedTeams(selected_teams);
    //     }
    // };

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        const query_data = setting_teams;
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setTeams(data);
            
        } else{
            const data = query_data?.filter((item: any) => {
                return item?.teamName?.toLowerCase().includes(value?.toLowerCase());
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                setTeams(data);
            }else{
                setTeams(data);
            }
        }
    }

    const handleMoveNow = async () => {
        try {
            console.log({ conversation, selectedTeam });
            const clapupUserId = logged_in_user_secret_data?.cuid;
            const workspaceId = logged_in_user_secret_data?.wuid;
            if (!clapupUserId || !workspaceId) {
                return;
            }
            const data_object = {
                clapupUserId, workspaceId,
                ticketNo: conversation?.ticketNo,
                channelId: conversation?.channelId,
                teamIdFrom: conversation?.teamId,
                teamIdTo: selectedTeam,
            }
            const url = inbox_api_url + `/api/${api_version}/teams/MoveEmailToTeam`;
            const { status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                dispatch(setSuccessMessage("Moved Successfully"));
                setTimeout(() => setSelectedTeam(null), 2000);
                handleCancel();
            }

        } catch (error) {
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };

    // const handleMoveNow = () => {
    //     console.log({ data_fields, conversation, selectedTeams });
    //     // handleParentCallback();
        
    // }

    return (
        <React.Fragment>
            <div className="move-table-form">
                <h3>Move the message to another teams</h3>
                <div className="form-item">
                    <DebounceInput
                        type="text" 
                        placeholder={"Search teams to move your conversation"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => handleSearchForTableColumn(event.target.value)} 
                    />
                </div>

                <h4>Teams</h4>
                <div className="team-table">
                    {
                        teams?.length
                        ?
                            <div className='table-responsive'>
                                <table className="table m-0">
                                    <thead className="d-none"><tr><th>Icon-Name</th><th></th></tr></thead>
                                    <tbody>
                                        {teams?.map((team: any, idx: number) => (
                                            <tr key={idx} className={`${team?.teamId === conversation?.teamId ? "d-none" : ""}`}>
                                                <td>
                                                    {getImageIconByColorTag(team?.teamColor)}
                                                    <div>{team?.teamName || "Team Name"}</div>
                                                </td>
                                                {/* <td>{user?.email}</td> */}
                                                <td>
                                                    <img
                                                        src={getImage(
                                                            selectedTeam === team?.teamId 
                                                            ? "active-radio-button" 
                                                            : "inactive-radio-button"
                                                            // selectedTeams?.find((team_id: any) => team_id === team?.teamId )
                                                            // ? "active-radio-button" 
                                                            // : "inactive-radio-button"
                                                        )}
                                                        alt="check-uncheck-icon"
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            setSelectedTeam(team?.teamId);
                                                            // handleSelectTeams(team?.teamId);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        :   <div className="no-data">
                                No Data Found
                            </div>
                    }
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleMoveNow()}
                    >
                        Move Now
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default MoveModalForm;