import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_url, api_version, inbox_api_url } from '../../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
import { generateAccessToken } from '../../../profile/reducer';
import { getGenerateApiImageByName, getImage } from '../../../unified/image_service';
import { setOpenGlobalModal } from "../reducer";

const getImageIconByColorTag = (color = "#000000") => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1" width="18" height="18" viewBox="0 0 18 18"
        >
            <g>
                <g>
                    <ellipse
                        cx="9" cy="9" rx="9" ry="9"
                        fill={color}
                        fillOpacity="1"
                    />
                </g>
            </g>
        </svg>
    )
};

// const dummy_people = [
//     {
//         userId: 3,
//         userProfile: "",
//         userName: "Kuber Mannur",
//         emailId: "ku@gmail.com",
//     },
//     {
//         userId: 1,
//         userProfile: "",
//         userName: "Sreekanth PM",
//         emailId: "sr@gmail.com",
//     },
//     {
//         userId: 2,
//         userProfile: "",
//         userName: "Mehedi Hasan",
//         emailId: "mm@gmail.com",
//     },
//     {
//         userId: 4,
//         userProfile: "",
//         userName: "MMHK",
//         emailId: "mmhk@gmail.com",
//     },
// ];

// const dummy_teams = [
//     {
//         id: 1,
//         teamColor: "#1354EC",
//         teamName: "Primary",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 2,
//         teamColor: "#F79009",
//         teamName: "Marketing",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 3,
//         teamColor: "#F04438",
//         teamName: "Human Resource",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 4,
//         teamColor: "#039855",
//         teamName: "Developer Team",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 5,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
//     {
//         id: 6,
//         teamColor: "#039855",
//         teamName: "Team Developer",
//         userEmail: "ex@gmail.com",
//     },
// ];

const ContentShareForm = ({ data_fields, handleParentCallback }: any) => {
    const { workspaceTeamList, workspaceUserList: ws_user_list } = useSelector((state: any) => state?.workspaceManagementState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const workspaceUserList = ws_user_list?.filter((item: any) => item?.userId !== logged_in_user_secret_data?.cuid);
    // const details_assignee_users = dummy_people;
    const [shareTab, setShareTab] = useState<any>("People");
    const [accessType, setAccessType] = useState<any>(null);
    // ==== already selected teams and users will be hidden =====
    const [hiddenUserList, setHiddenUserList] = useState<any>([]);
    const [hiddenTeamList, setHiddenTeamList] = useState<any>([]);

    // ==== already selected teams and users will be hidden =====
    const [teams, setTeams] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);
    // ====== editing time ======
    const [editableUserList, setEditableUserList] = useState<any>([]);
    const [editableTeamList, setEditableTeamList] = useState<any>([]);
    const [removableTeams, setRemovableTeams] = useState<any>([]);
    const [removableUsers, setRemovableUsers] = useState<any>([]);
    // ====== editing time ======
    const [selectedTeamIds, setSelectedTeamIds] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]); // // id-1,id-2,...,id-n
    const dispatch = useDispatch();

    useEffect(() => { 
        // console.log({ workspaceTeamList, workspaceUserList });
        (async () => {
            try {
                // console.log({ data });
                const { content, header_title } = data_fields || {};
                
                if (!content?.isShared) {
                // if (!header_title?.toLowerCase()?.includes("edit")) {
                    setTeams(workspaceTeamList);
                    setUsers(workspaceUserList);
                    setAccessType(content?.accessLevel || "full");
                    // setHiddenTeamList(selected_teams);
                    // setHiddenUserList(selected_users);
                    return;
                }

                const url = api_url + `/api/${api_version}/content/GetContentShareDetails?contentId=${content?.contentId}`;
                const { data, status } = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (status === 200) {
                    const { shareDetails } = data || {};
                    console.log({ data, shareDetails });
                    const selected_teams: any = shareDetails?.filter((item: any) => item?.mode === "team")?.map((item: any) => item?.id);
                    const selected_users: any[] = shareDetails?.filter((item: any) => item?.mode === "user")?.map((item: any) => item?.id);
                    if (!header_title?.toLowerCase()?.includes("edit")) {
                        // if (!content?.isShared) {
                        setTeams(workspaceTeamList);
                        setUsers(workspaceUserList);
                        setAccessType(content?.accessLevel || "full");
                        // for showing
                        // setSelectedTeamIds(selected_teams);
                        // setSelectedUsers(selected_users);
                        // for hiding
                        setHiddenTeamList(selected_teams);
                        setHiddenUserList(selected_users);
                        return;
                    }
                    
                    setSelectedTeamIds(selected_teams);
                    setSelectedUsers(selected_users);

                    const teams: any[] = shareDetails?.filter((item: any) => item?.mode === "team")?.map((item: any) => {
                        return {
                            ...item, 
                            teamName: item?.name,
                            teamId: item?.id
                        }
                    });;
                    const users: any[] = shareDetails?.filter((item: any) => item?.mode === "user")?.map((item: any) => {
                        return {
                            ...item,
                            userName: item?.name,
                            userId: item?.id
                        }
                    });
                    setAccessType(content?.accessLevel || "full")
                    setTeams(teams);
                    setUsers(users);
                    setEditableTeamList(users);
                    setEditableUserList(teams);
                }

            } catch (error) {
                const { response }: any = error;
                console.log('error: ', error);
                const { status } = response || {};
                if (status === 403 || status === 401) {
                    dispatch(generateAccessToken());
                }
                // else {
                //     // dispatch(setErrorMessage("Something went wrong"));
                // }
            }
        })()
    }, [workspaceTeamList, workspaceUserList]);

    const handleRemoveUsers = (id: any) => {
        const filteredSelectedUsers = selectedUsers?.filter((item_id: any) => item_id !== id);
        setSelectedUsers(filteredSelectedUsers);
        const removableSelectedUserId = selectedUsers?.find((item_id: any) => item_id === id);
        if (removableSelectedUserId) {
            setRemovableUsers([...removableUsers, removableSelectedUserId]);
        }
    };

    const handleSelectUsers = (id: any) => {
        const already_selected = selectedUsers?.find((item_id: any) => item_id === id);
        if (already_selected) {
            const filteredSelectedUsers = selectedUsers?.filter((item_id: any) => item_id !== id);
            setSelectedUsers(filteredSelectedUsers);
            // console.log({ already_selected, selectedUsers, id });
        } else {
            const selected_users = [...selectedUsers, id];
            // console.log({ selected_users, selectedUsers, id });
            setSelectedUsers(selected_users);
        }
    };
    
    const handleRemoveTeamIds = (id?: any) => {
        // console.log({ already_selected, selectedTeamIds, id });
        const filteredSelectedTeams = selectedTeamIds?.filter((item_id: any) => item_id !== id);
        setSelectedTeamIds(filteredSelectedTeams);
        const removableSelectedTeamId = selectedTeamIds?.find((item_id: any) => item_id === id);
        if (removableSelectedTeamId) {
            setRemovableTeams([...removableTeams, removableSelectedTeamId]);
        }
        // const already_selected = selectedTeamIds?.find((item_id: any) => item_id === id);
        // if (already_selected) {
        //     const filteredSelectedTeams = selectedTeamIds?.filter((item_id: any) => item_id !== id);
        //     setSelectedTeamIds(filteredSelectedTeams);
        // } else {
        //     const selected_teams = [...selectedTeamIds, id];
        //     setSelectedTeamIds(selected_teams);
        // }
    };

    // const handleSelectTeams = (id: any) => {
    const handleSelectTeamId = (id: any) => {
        // setSelectedTeamId(selected_team_id);
        const already_selected = selectedTeamIds?.find((item_id: any) => item_id === id);
        // console.log({ already_selected, selectedTeamIds, id });
        if (already_selected) {
            const filteredSelectedTeams = selectedTeamIds?.filter((item_id: any) => item_id !== id);
            setSelectedTeamIds(filteredSelectedTeams);
        } else {
            const selected_teams = [...selectedTeamIds, id];
            setSelectedTeamIds(selected_teams);
        }
    };

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})

        let query_data: any[] = shareTab === "People" ? workspaceUserList : workspaceTeamList;
        const { header_title, } = data_fields || {};
        if (header_title?.toLowerCase()?.includes("edit")) {
            // if (!content?.isShared) {
            query_data = shareTab === "People" ? (editableUserList || []) : (editableTeamList || []);
            return;
        }
        if(isEmailCheck){
            const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
            setTeams(data);
            
        } else {
            const data = query_data?.filter((item: any) => {
                if (shareTab === "People") {
                    return item?.userName?.toLowerCase().includes(value?.toLowerCase());
                } else {
                    return item?.teamName?.toLowerCase().includes(value?.toLowerCase());
                }
            })
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = query_data?.filter((item: any) => item?.emailId?.includes(value));
                shareTab === "People" ? setUsers(data) : setTeams(data);
            } else{
                shareTab === "People" ? setUsers(data) : setTeams(data);
            }

            if (!value) {
                if (header_title?.toLowerCase()?.includes("edit")) {
                    // if (!content?.isShared) {
                    setUsers(editableUserList)
                    setTeams(editableTeamList);
                    return;
                }
                setUsers(workspaceUserList);
                setTeams(workspaceTeamList);
            }
        }
    }

    const handleCancel = () => {
        console.log({ data_fields, selectedTeamIds, selectedUsers });
        // handleParentCallback();
        dispatch(setOpenGlobalModal(null));
        
    }

    const handleShareNow = async () => {
        const { payload, api_url, content, header_title } = data_fields || {};
        try {
            // const data = data_fields;
            // console.log({ data });
            
            // setSelectedUsers([]);
            // setSelectedTeamId(null);
            // setSelectedTeamIds([]);
            // handleCancel();
            let selected_team_ids = selectedTeamIds?.join(",");
            let selected_users = selectedUsers?.join(",");
            if (!header_title?.toLowerCase()?.includes("edit")) { 
                if (!selected_team_ids && !selected_users) {
                    dispatch(setErrorMessage("Please select at least a user or a team"));
                    return;
                }
            }
            let data_object = {
                ...payload,
                sharedToTeamList: selected_team_ids,
                sharedToUserList:  selected_users,
                accessLevel: accessType,
                
            }

            // console.log({ data_object, api_url, selectedTeamId, selectedUsers });
            // return;
            let url = api_url;
            if (header_title?.toLowerCase()?.includes("edit")) {
                let removable_selected_team_ids = removableTeams?.join(",");
                let removable_selected_users = removableUsers?.join(",");
                if (!removable_selected_team_ids && !removable_selected_users) {
                    dispatch(setErrorMessage("Please remove at least a user or a team"));
                    return;
                }
                data_object = {
                    clapupUserId: data_object?.clapupUserId,
                    workspaceId: data_object?.workspaceId,
                    category: data_object?.category,
                    sharedToUserList: removable_selected_team_ids,
                    sharedToTeamList: removable_selected_users,
                    contentId: data_object?.contentId,
                    isFolder: data_object?.isFolder
                }
            }
            const { data, status } = await axios.post(url, data_object, {
                headers: {
                    'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                console.log({ data });
                const updated_content = {
                    ...content,
                    isShared: (!selected_team_ids && !selected_users) ? false : true,
                }
                let success_message = "Shared Successfully";
                if (header_title?.toLowerCase()?.includes("edit")) {
                    success_message = "Saved successfully";
                }
                handleParentCallback({
                    key: "isShared",
                    value: (!selected_team_ids && !selected_users) ? false : true,
                    response_data: null,
                    content: updated_content,
                    success_message: success_message,
                })

                
                // dispatch(setSuccessMessage("Shared Successfully"));
                // handleCancel();
                // setTimeout(() => setSelectedTeamIds(null), 2000);
                // setTimeout(() => setSelectedUsers(null), 2000);
                // setTimeout(() => setShareTab("People"), 2000);
            }

        } catch (error) {
            // removable codes
            // const updated_content = {
            //     ...content,
            //     isShared: true,
            // }
            // handleParentCallback({ 
            //     key: "isShared",
            //     value: true,
            //     response_data: null,
            //     content: updated_content,
            //     // success_message
            // })
            
            const { response }: any = error;
            console.log('error', response);
            const { status } = response || {};
            if (status === 403 || status === 401) {
                dispatch(generateAccessToken());
            } else {
                dispatch(setErrorMessage("Something went wrong"));
            }
        }
    };

    return (
        <React.Fragment>
            <div className="share-content-table-form">
                <h3>{data_fields?.header_title}</h3>

                <div className="share-radio-actions">
                    <div className="radio-action">
                        <img
                            onClick={() => setAccessType("full")}
                            src={
                                getImage(
                                    accessType === "full"
                                    ? "active-radio-button"
                                    : "inactive-radio-button"
                                )
                            }
                            alt="tab-icon"
                        />
                        <div onClick={() => setAccessType("full")}>Full Access</div>
                    </div>
                    <div className="radio-action">
                        <img
                            onClick={() => setAccessType("partial")}
                            src={
                                getImage(
                                    accessType === "partial"
                                    ? "active-radio-button"
                                    : "inactive-radio-button"
                                )
                            }
                            alt="tab-icon"
                        />
                        <div onClick={() => setAccessType("partial")}>Partial Access</div>
                    </div>
                </div>

                <div className="form-item">
                    <DebounceInput
                        type="text" 
                        placeholder={"Search users or teams to share your item"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => handleSearchForTableColumn(event.target.value)} 
                    />
                </div>

                <div className="share-tabs">
                    <div 
                        className={`share-tab-container${shareTab === "People" ? "-active" : ""}`}
                        onClick={() => {
                            setShareTab("People")
                        }} 
                    >
                        People
                    </div>
                    <div 
                        className={`share-tab-container${shareTab === "Teams" ? "-active" : ""}`}
                        onClick={() => {
                            setShareTab("Teams")
                        }} 
                    >
                        Team
                    </div>
                </div>
                <div className="people-teams-table">
                    {
                        (teams?.length && shareTab === "Teams") || (users?.length && shareTab === "People")
                        ?   shareTab === "Teams" ?
                            <div className='table-responsive'>
                                <table className="table m-0">
                                    <thead className="d-none"><tr><th>Icon-Name</th><th></th></tr></thead>
                                    <tbody>
                                        {teams?.map((team: any, idx: number) => (
                                            <tr key={idx}
                                                className={`
                                                    ${team?.teamId === data_fields?.teamId ? "d-none" : ""}
                                                    ${hiddenTeamList?.find((team_id: any) => team_id === team?.teamId ) ? "d-none" : ""}
                                                `}
                                            >
                                                <td style={{paddingLeft: 0}}>
                                                    {getImageIconByColorTag(team?.teamColor)}
                                                    <div>{team?.teamName || "Team Name"}</div>
                                                </td>
                                                <td>{null}</td>
                                                <td style={{paddingRight: 0}}>
                                                    <img
                                                        src={getImage(
                                                            selectedTeamIds?.find((team_id: any) => team_id === team?.teamId )
                                                            ? "checked-icon" 
                                                            : "un-checked-icon"
                                                        )}
                                                        alt="active-inactive-radio-icon"
                                                        style={{ cursor: 'pointer', padding: 0, borderRadius: 0 }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            // handleSelectTeams(team?.teamId);
                                                            if (data_fields?.header_title?.toLowerCase()?.includes("edit")) {
                                                                handleRemoveTeamIds(team?.teamId);
                                                                return;
                                                            }
                                                            handleSelectTeamId(team?.teamId);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : 
                            <div className='table-responsive'>
                                <table className="table m-0">
                                    <thead className="d-none"><tr><th>User-Name</th><th></th></tr></thead>
                                    <tbody>
                                        {users?.map((user: any, idx: number) => (
                                            <tr key={idx}
                                                className={`
                                                    ${user?.userId === data_fields?.logged_in_user_id ? "d-none" : ""}
                                                    ${hiddenUserList?.find((member_id: any) => member_id === user?.userId ) ? "d-none" : ""}
                                                `}
                                            >
                                                <td style={{paddingLeft: 0}}>
                                                    <img src={getGenerateApiImageByName(user?.userName)} alt="user" />
                                                    <div>{user?.userName || "User Name"}</div>
                                                </td>
                                                <td>{user?.emailId}</td>
                                                <td style={{paddingRight: 0}}>
                                                    <img
                                                        src={getImage(
                                                            selectedUsers?.find((member_id: any) => member_id === user?.userId )
                                                            ? "checked-icon" 
                                                            : "un-checked-icon"
                                                        )}
                                                        alt="checked-unchecked-icon"
                                                        style={{ cursor: 'pointer', padding: 0, borderRadius: 0 }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            if (data_fields?.header_title?.toLowerCase()?.includes("edit")) {
                                                                handleRemoveUsers(user?.userId);
                                                                return;
                                                            }
                                                            handleSelectUsers(user?.userId);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        :   <div className="no-data">
                                {
                                    !accessType
                                    ? ""
                                    : "No Data Found"
                                }
                            </div>
                    }
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleShareNow()}
                    >
                        {
                            data_fields?.header_title?.toLowerCase()?.includes("edit")
                            ? "Save Now"
                            : "Share Now"
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ContentShareForm;