import { Fragment, useEffect } from "react";
import './SettingsMenu.scss';
// import cross_icon from "../../../assets/img/close-circle.svg";
// import PermissionCheck from "../../PermissionCheck";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import {
  changePage,
  // resetUnifiedInbox, resetWallUnifiedInbox
} from "../../../unified/reducer";
import { settings_menu_routes } from "./menu.config";
import SkeletonLoader from "../../Loaders/SkelitonLoader/SkeletonLoader";

const SettingsMenuLoader = (props: any) => {
  const menu_data = settings_menu_routes;
  // console.log({menu_data});
  return (
    <Fragment>
      <div className="settings-nav-wrapper">
        <div className="setting-title">Settings</div>
        <ul>
        {[1,2,3,4,5,6,7,8]?.map((_: any, idx: any) => {
            return (
            // <PermissionCheck permissionName={item.permissionName} key={item?.id}>
                <li className={`li-loader`} key={idx}>
                  <button
                      type="button"
                      className={`button`}
                  >
                    <SkeletonLoader borderRadius="9px" width="150px" height="20px"/>
                  </button>
                </li>
            // </PermissionCheck>
            )
        })}
        </ul>
      </div>
    </Fragment>
  );
}

export default SettingsMenuLoader;