import React, { useEffect, useState, useRef } from 'react';
// pdf license
// import '../../../../utils/configs/syncfusion-license';
import {
    PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject,
    PdfViewer
} from '@syncfusion/ej2-react-pdfviewer';
import './ContentViewer.scss';
import '@syncfusion/ej2-react-pdfviewer/styles/material.css';
// import '@syncfusion/ej2-react-pdfviewer/styles/material.css'; // Optional: Style for the PdfViewer component


const PdfComponentViewer = ({ content }: any) => {
    useEffect(() => {
        console.log({ content });
        // if (content?.contentId) {
        //     PdfViewer.Inject( Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, ThumbnailView, 
        //         BookmarkView, TextSelection, TextSearch, FormFields, FormDesigner );

        //     let pdfviewer: PdfViewer = new PdfViewer();
        //     pdfviewer.documentPath = "https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf";
        //     pdfviewer.resourceUrl="https://cdn.syncfusion.com/ej2/28.1.39/dist/ej2-pdfviewer-lib";
        //     pdfviewer.appendTo('#PdfViewer');
        // }
     }, [content?.contentId]);
    
    // old code
    const inject_services: any = [
        Toolbar, Magnification,
        Navigation, Annotation,
        LinkAnnotation, BookmarkView,
        ThumbnailView, Print,
        TextSelection, TextSearch,
        FormFields, FormDesigner
    ];

    const toolbarSettings: any = {
        toolbarItems: [
            // 'OpenOption',          // File Open: Allows the user to open a PDF file.
            'PageNavigationTool',  // Page Navigation: Navigate between pages.
            'MagnificationTool',   // Magnification: Zoom in, zoom out, fit-to-page.
            'PanTool',             // Pan Tool: Drag document to navigate.
            'SelectionTool',       // Text Selection: Select text from the document.
            'SearchOption',        // Search: Text search within the PDF.
            'PrintOption',         // Print: Print the document.
            'UndoRedoTool',        // Undo/Redo: Undo and redo annotations or form edits.
            'AnnotationEditTool',  // Annotation: Add or edit annotations (highlight, note, etc.).
            'FormDesignerEditTool',// Form Designer: Add or edit form fields.
            'CommentTool',         // Comments: Add and manage comments.
            // 'SubmitForm'           // Submit Form: Submit form data to a ser
        ]
    };

    return (
        <React.Fragment>
            <div className="pdf-viewer-container">
                <div className="pdf-view">
                    {/* <div id="PdfViewer" style={{ height: '100%', width: "100%" }}></div> */}
                    {/* old codes */}
                    <PdfViewerComponent
                        id="container"
                        documentPath={content?.presignedUrl}
                        // documentPath="https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"
                        resourceUrl="https://cdn.syncfusion.com/ej2/28.1.39/dist/ej2-pdfviewer-lib"
                        style={{ height: '100%', width: "100%" }}
                        toolbarSettings={toolbarSettings}
                    >
                            <Inject
                                services={inject_services}
                            />
                    </PdfViewerComponent>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PdfComponentViewer;