import React, { useEffect, useState, useRef } from 'react';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
import emptyBox from '../../../../assets/img/Empty.svg';
import clapup_bot_icon from '../../../../assets/icons/bot-icons/clapup-bot-icon.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
// import { setSuccessMessage } from '../../../../dashboard/reducer';
import CustomCountdown from '../../../CustomCountdown/CustomCountdown';
// import BlinkingOneDot from '../../../Loaders/BlinkingOneDot/BlinkingOneDot';
import MessagesDetails from '../AllDetails/MessagesDetails';
import { convertISODate } from '../../../../utils/convertingFunction';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import ConversationCommonHeader from './ConversationComponents/ConversationCommonHeader';
import ConversationCommonBody from './ConversationComponents/ConversationCommonBody';
import ConversationCommonReply from './ConversationComponents/ConversationCommonReply';

const BotMemberConversation = (props: any) => {
  const dispatch = useDispatch();
  const { logged_in_user_secret_data, accessed_user_details } = useSelector((state: any) => state?.login);
  const [messages, setMessages] = useState<any[]>([]);
  const [isCountDownOn, setIsCountDownOn] = useState(false);
  // const query_string = window.location.search;
  const {
    conversation, type, statDetails,
    // handleReplaceConversation,
    getImage, handlePrevPath,
    isReply, setReplyText, textRef, sender,
    // isReply, setIsReply,  handleReply, conversationId, 
    isGoForDetails, handleDetailChange } = props;
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
  )?.workSpaceName || "";
  const { reviewerPhoteUrl } = conversation || {};
  // console.log("bot_stat_Conversations Data ===> ", {conversation, workspaceSettings, workspaces, workSpaceName, id: logged_in_user_secret_data?.wuid});
  const [inputValue, setInputValue] = useState('');

  // const handleKeyPress = (e: any) => {
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     e.preventDefault(); // Prevent the default behavior (form submission)
  //     handleSubmit();
  //   }
  // };

  // const handleInputChange = (e: any) => {
  //   setInputValue(e.target.value);
  // };

  // const handleSubmit = () => {
  //   // Perform your submit logic here
  //   console.log('Submitted:', inputValue);
  //   // Reset the input value or update your state as needed
  //   setReplyText(inputValue);
  //   setInputValue('');
  // };

  useEffect(() => {
    if (conversation?.ticketNo && conversation?.messages?.length) {
      // const bot_messages: any[] = conversation?.messages?.reduce((uniqueArray: any, currentItem: any) => {
      //   if (!uniqueArray.some((item: any) => item.createdOn === currentItem.createdOn)) {
      //       uniqueArray.push(currentItem);
      //   }
      //   return uniqueArray;
      // }, []);
      
      // console.log({ messages_all: conversation?.messages });
      // setMessages(bot_messages?.reverse());
      setMessages(conversation?.messages?.reverse());
    } else {
      setMessages([]);
    }
  }, [conversation?.ticketNo, conversation?.userName]);

  
  const [isForReplyingUser, _] = useState<any>()
  // Automatically scroll to the bottom of the conversation when a new message is added
  const scrollRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState<any>(false);

  // Check if the content is overflowing
  useEffect(() => {
    console.log({ client_height: scrollRef?.current?.clientHeight, scrollHeight: scrollRef.current.scrollHeight });
    if (scrollRef.current) {
      const isContentOverflowing = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
      setIsOverflowing(isContentOverflowing);
      
      // Scroll to the bottom only if content is overflowing
      if (isContentOverflowing) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [messages?.length, scrollRef?.current?.clientHeight]);

  return (
    <React.Fragment>
      <ConversationCommonHeader
        data_fields={{
          username_1: conversation?.userName || "User Name",
          username_2: "Biopage Bot",
          // username_2: workspaceSettings?.workspaceName,
          user_profile_1: null, 
          user_profile_2:  getImage("bot-avatar") || workspaceSettings?.logo,
          // star_ratings, tag_text,  // optional props fields
          // action_buttons: null,
          action_buttons: [{ text: "Close", },{ text: "Share",},{text: "Move"}], // optional props fields
          provider: conversation?.provider || "bot-messages",
        }}
        handleParentCallback={(data: any) => console.log({ data })}
      />
      <div className="conversation-data">
        <div
          className="conversation-container"
          ref={scrollRef}
          style={{justifyContent: isOverflowing ||  messages?.length > 2 ? "flex-start" : "flex-end"}}
        >
        {
          messages.map((item: any, idx: any) => (
            item?.userName === "assistant"
              ? <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: "Bot Replied",
                    user_profile: getImage("bot-avatar"),
                    star_ratings: null, // optional props fields
                    tag_text: `To ${conversation?.userName}`,  // optional props fields
                    date: item?.createdOn,
                    message_subject: null, // optional props fields
                    message: item?.message,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={false}
                />
              : <ConversationCommonBody
                  key={idx}
                  data_fields={{
                    username: item?.userName,
                    user_profile: reviewerPhoteUrl,
                    star_ratings: null, // optional props fields
                    tag_text: `Asked a question to ${conversation?.userName}`,  // optional props fields
                    date: item?.createdOn,
                    message_subject: null, // optional props fields
                    message: item?.message,
                  }}
                  handleParentCallback={(data: any) => console.log({ data })}
                  isReply={false}
                />
          ))
        }
        </div>
      </div>

      <ConversationCommonReply
        data_fields={null}
        handleParentCallback={(data: any) => console.log({data})}
        isReply={isReply}
        isCountDownOn={isCountDownOn}
        setReplyText={setReplyText}
        isForReplyingUser={false}
        textRef={textRef}
        showingLeftContent={false}
        sender={sender}
        showFileInputIcon={true} 
        showEmojiInputIcon={true}
        showLockIcon={true}
      />
      {/* <div className="conversation-section-container-lg h-100">
        <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                  ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                  :
                  <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(conversation?.userName)}
                    alt="avatar"
                  />
              }
              <span className="description">
                {conversation?.userName?.substr(0, 10) || "User Name"}{" "}
              </span>
            </div>
            <div className="person-2">
              {
                workspaceSettings?.logo
                  ? <img
                    src={workspaceSettings?.logo || user_avatar_2}
                    alt="user-2"
                  />
                  : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(workspaceSettings?.workspaceName || workSpaceName)}
                    alt="avatar"
                  />
              }
              <span className="user-2">
                {workspaceSettings?.workspaceName || workSpaceName || "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media"
            style={{ justifyContent: 'flex-end', width: '40%' }}
          >
            <div className="features">
              <div className='count-down-container'>
                <BlinkingOneDot
                  isStarted={isCountDownOn}
                />
                <CustomCountdown
                  customClass={"countdown"}
                  onChange={(val: string) => {
                    // console.log(val);
                  }}
                  isStarted={isCountDownOn}
                />
              </div>
              
            </div>
            <div className="media">
              <img onClick={() => setIsCountDownOn(!isCountDownOn)} src={getImage(type)} alt="type-icon" />
            </div>
          </div>
        </div>
        {
          messages?.length
            ?
            <div className="conversation-section-video-feedback">
              <Scrollbars
                style={{
                  paddingRight: 12,
                  display: "flex",
                  flexDirection: "column-reverse",
                  overflow: 'auto'
                }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"62vh"}
                thumbMinSize={10}
                universal={true}
                id="scrollableDiv"
              >
                <div className="ai-bot-conversation-body" >
                  {
                    messages.map((item: any, idx: any) => (
                      item?.userName === "assistant"
                        ?
                        <div className="conversation-person-2" key={idx}>
                          <div className="details">
                            <img
                              className="user-avatar"
                              src={clapup_bot_icon}
                              alt="bot-icon"
                            />
                            <div className="description">
                              <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                            </div>
                          </div>
                          <p className="text-end mt-2 date">{"Replied - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A") + " by AI"}</p>
                        </div>
                        :
                        <div className="conversation-person-1" key={idx}>
                          <div className="details">
                            {
                              reviewerPhoteUrl
                                ? <img
                                  className="user-avatar"
                                  src={reviewerPhoteUrl || user_avatar}
                                  alt="avatar"
                                />
                                : <img
                                  className="user-avatar"
                                  src={getGenerateApiImageByName(item?.userName)}
                                  alt="avatar"
                                />
                            }
                            <div className="description">
                              <div
                                className={`d-flex align-items-center user-ratings-container`}
                              >
                                <div className="user_name me-2 ">
                                  {item?.userName || "User Name"}
                                </div>
                              </div>
                              <p style={{ whiteSpace: 'pre-line' }}>{item?.message}</p>
                            </div>
                          </div>
                          <p className="text-start mt-2 date">
                            {"Messaged - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A")}
                          </p>
                        </div>
                    ))
                  }
                </div>
              </Scrollbars>
            </div>
            :
            <div className="empty-feeds-section">
              <div
                className="empty-wall-section position-absolute"
                style={{ top: "45%" }}
              >
                <div>
                  <img src={emptyBox} alt="" />
                  <h4>Empty Feeds</h4>
                </div>
              </div>

            </div>
        }
        <div className="conversation-footer">
          <div className="message-box mb-3">
            <div className="message">
              <div className="text-container click-reply-des"
                style={{ display: 'flex', flexDirection: 'row', alignItems: "center", padding: '15px' }}
              >
                <span>This conversation is managed by Bot</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      {/* <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(type)}
              alt="type-messages"
            />
          </div>
          <div className="header-body">
            <div className="bot-body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                    ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                    : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center p-1' style={{ fontSize: "0.75rem", background: '#14B0BF' }}>
                      {
                        (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.length === 2
                          ? (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                          : (conversation?.userName || "User Name")?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {(conversation?.userName)?.substr(0, 6) || "User Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                {
                  workspaceSettings?.logo
                    ? <img
                      className="image-logo"
                      src={workspaceSettings?.logo || user_avatar_2}
                      alt="user-2"
                    />
                    : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center' style={{ fontSize: "0.75rem", background: '#14B0BF' }}>
                      {
                        workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                          ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                          : workSpaceName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <p className="ws-name">
                  {workSpaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="conversation-features-media"
              style={{ justifyContent: 'center', width: '40%' }}
            >
              <div className="features">
                <div className='count-down-container'>
                  <BlinkingOneDot
                    isStarted={isCountDownOn}
                  />
                  <CustomCountdown
                    customClass={"countdown"}
                    onChange={(val: string) => {
                      // console.log(val);
                    }}
                    isStarted={isCountDownOn}
                  />
                </div>
              </div>
              <div className="media">
                <img onClick={() => setIsCountDownOn(!isCountDownOn)} src={getImage(type)} alt="type-icon" />
              </div>
            </div>
          </div>
        
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <MessagesDetails type={type} statDetails={statDetails} />
          </div>
        ) : (
          <>
            <Scrollbars
              style={{
                paddingRight: 10,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              // style={{ paddingRight: 10 }}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              // autoHeightMin={250}
              // autoHeightMax={250}
              // autoHeightMin={480}
              autoHeightMax={"auto"}
              thumbMinSize={10}
              universal={true}
              id="scrollableDiv"
            >
              <div className="conversation-body" style={{ height: "60vh", paddingRight: '1%', overflow: 'auto' }}>
                {
                  messages?.map((item: any, idx: any) => (
                    item?.userName === "assistant"
                      ?
                      <div className="conversation-person-2" key={idx}>
                        <div className="details">
                          <img
                            className="user-avatar"
                            src={clapup_bot_icon}
                            alt="bot-icon"
                          />
                          <div className="description">
                            <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A") + " by AI"}</p>
                      </div>
                      :
                      <div className="conversation-person-1" key={idx}>
                        <div className="details">
                          {
                            reviewerPhoteUrl
                              ? <img
                                className="user-avatar"
                                src={reviewerPhoteUrl || user_avatar}
                                alt="avatar"
                              />
                              : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(item?.userName)}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            <div
                              className={`d-flex align-items-center user-ratings-container`}
                            >
                              <div className="user_name me-2 ">
                                {item?.userName || "User Name"}
                              </div>
                            </div>
                            <p style={{ whiteSpace: 'pre-line' }}>{item?.message}</p>
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                          {"Messaged - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A")}
                        </p>
                      </div>
                    
                  ))
                }
                conversation
                
              </div>
            </Scrollbars>
            <div className="bot-conversation-footer">
              <div className="message-box mb-3">
                <div className="message">
                  <div className="text-container click-reply-des">
                    <span>This conversation is managed by Bot</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}
    </React.Fragment>
  )
};

export default BotMemberConversation;
