import React from 'react';
import { Input } from 'antd';
import { RootState } from '../../../../store';
import { updateContentBeforeSaving } from '../../../ContentComponents/reducer';
import { useDispatch, useSelector } from 'react-redux';

export default function BiopageTab() {
  const dispatch = useDispatch();
  const { updatable_content }: any = useSelector((state: RootState) => state?.contentState);
  
  return (
    <div className='biopage-seo-container scroll-content'>
      <div className='form-group'>
        <label className="form-label">Title of the Video</label>
        <Input
          className='form-input'
          placeholder='Add the Title of the Video'
          onChange={(e: any) => { 
            const value = e.target.value;
            dispatch(updateContentBeforeSaving({
              key: "bioPage_Title",
              value,
            }))
          }}
          value={updatable_content?.bioPage_Title}
        />
      </div>
      <div className='form-group'>
        <label className="form-label">Video Caption</label>
        <Input
          className='form-input'
          placeholder='Add the Caption'
          onChange={(e: any) => {
            const value = e.target.value;
            dispatch(updateContentBeforeSaving({
              key: "bioPage_Caption",
              value,
            }))
          }}
          value={updatable_content?.bioPage_Caption}
        />
      </div>
      <div className='form-group'>
        <label className="form-label">Your Story</label>
        <Input.TextArea
            className='form-textarea'
            rows={6}
            placeholder='Add your story about the video'
            onChange={(e: any) => {
              const value = e.target.value;
              dispatch(updateContentBeforeSaving({
                key: "bioPage_Story",
                value,
              }))
            }}
            value={updatable_content?.bioPage_Story}
        />
      </div>
    </div>
  )
}
