import React, { useEffect, useState } from 'react';
// import moment from 'moment';
// import user_avatar from '../../../../assets/icons/user-avatar.svg';
// import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../../../../store';
// import { modifyString } from '../../../../utils/mediaService';
import {
  setErrorMessage,
  triggerUpdateWhatsappPhoneOrEmail,
  // setUpdatedWhatsappPhoneOrEmail,
} from '../../../../unified/reducer';
// import { getGenerateApiImageByName } from '../../../../unified/image_service';
import MessagesSummaryList from './AllMessagesReviewSummary/MessagesSummaryList';
import MessagesReviewSummary from './AllMessagesReviewSummary/MessagesReviewSummary';
import MessagesAttachmentSummary from './AllMessagesReviewSummary/MessagesAttachmentSummary';
import ReviewHeader from './ReviewHeader/ReviewHeader';

const AllMessagesConversationDetails = ({ type, conversation }: any) => {
  // 1
  // const {
  //   whatsAppInboxData: whatsApp_inbox_data
  // }: any = useSelector((state: RootState) => state?.unified);
  const {
    all_messages_details_assignee_users,
    assigned_to_me_details_assignee_users,
    shared_with_me_details_assignee_users,
    shared_copies_details_assignee_users,
    archive_details_assignee_users
  } = useSelector((state: any) => state?.unified);
  const details_assignee_users = type === "archive-messages"
                                ? archive_details_assignee_users
                                : type === "shared-copies-messages"
                                ? shared_copies_details_assignee_users
                                : type === "shared-with-me-messages"
                                ? shared_with_me_details_assignee_users
                                : type === "assigned-to-me-messages"
                                ? assigned_to_me_details_assignee_users
                                : all_messages_details_assignee_users;
  const dispatch = useDispatch();
  const {
    // userName, fromPhoneNumber, userEmail, toPhoneNumber, ticketNoWiseDetails, // old data
    ticket_serial_no, ticket_no: ticketNo, messageId, statDetails: stat_details,
    // createdOn, provider
  } = conversation || {};
  // const summary_data: any = whatsApp_inbox_data?.chatSummaryList?.find((item: any) => item?.ticketNo === ticketNo);
  // const { summary }: any = summary_data || {};
  const [statDetails, setStatDetails] = useState<any>(null);
  // const query_string = window.location.search;
  useEffect(() => {
    // console.log("Check email and phone => ", { conversation, ticket_serial_no, ticketNoWiseDetails, ticketNo })
    // const stat_data = ticketNoWiseDetails?.find((item: any) => item?.serialNo === ticket_serial_no);
    // const stat_data = ticketNoWiseDetails?.find((item: any) => item?.ticketNo === ticketNo);
    const currentStatDetails = {
      userName: conversation?.messageFromDisplayName,
      provider: conversation?.provider,
      ticketNo: ticketNo,
      // channelEmail: conversation?.channelEmail,
      channelId: conversation?.channelId,
      messageFromEmail: conversation?.messageFromEmail,
      chatStatus: conversation?.messageStatus,
      // statDetails
      phoneNumber: stat_details?.phoneNumber,
      days: stat_details?.days || 0,
      threads: stat_details?.threads || 0,
      score: stat_details?.score || 0,
      // attachment: attachment, // should be worked
      // summary: summary, // should be worked
    }; 
    setStatDetails(currentStatDetails);
    // if(stat_data){
    //   // setEmail(stat_data?.messageFromEmail || "");
    //   // setPhoneNumber(stat_data?.messageFromPhoneNumbe || "");
    // }
  }, [messageId, ticket_serial_no, ticketNo]);
  // const summary = "Lorem Ipsum is simply dummy text of year printing and typesetting industry. Lorem and ipsum has been the industry's standard with text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it  to make a type specimen book. when an unknown  printer took a galley of type and scrambled it  to make a type specimen book";
  const handleSaveEmailOrPhoneNumber = (updateField: string, updateValue: string) => {
    if (!updateValue?.trim()) {
      dispatch(setErrorMessage(`${updateField === 'email' ? 'Email' : 'Phone number'} is required`));
    }
    const stat_details_object = {
      updateField: updateField || "",
      updateValue: updateValue || "",
      // toPhoneNumber
    };
    const updated_state: any = {
      // userName: userName,
    }
    if (updateField === 'email') {
      updated_state.userEmail = updateValue;
    } else {
      updated_state.fromPhoneNumber = updateValue;
    }
    console.log({ stat_details_object });
    // dispatch(setUpdatedWhatsappPhoneOrEmail(updated_state));
    dispatch(triggerUpdateWhatsappPhoneOrEmail({ updated_state, stat_details_object }));
  }

  return (
    <React.Fragment>
      <div className="conversation-details">
        <ReviewHeader
          tabs={["General", "Notes & Reminders", "Discussions"]}
          handleChangeTab={(data: any) => console.log(data)}
          value={"General"}
        />

        <div className="details-body">
          <MessagesReviewSummary
            userName={statDetails?.userName || statDetails?.messageFromEmail}
            userImage={ null} // conversation?.reviewerPhoteUrl
          // averageRating={unifiedDetailsData?.AverageRating}
            contact={statDetails?.messageFromEmail}
            provider={statDetails?.provider}
            value_1={Number(statDetails?.days || 0)}
            value_2={Number(statDetails?.threads || 0)}
            value_3={Number(statDetails?.score || 0)}
            title_1={"Days"}
            title_2={"Threads"}
            title_3={"Score"}
          />
          
          {
            conversation?.messageId
            ? <MessagesSummaryList
                list_data={{
                  provider: statDetails?.provider,
                  tag: statDetails?.tag,
                  email: statDetails?.messageFromEmail,
                  telephone: statDetails?.phoneNumber,
                  ticketNo: statDetails?.ticketNo,
                  channel: statDetails?.channelId,
                  chatStatus: statDetails?.chatStatus,
                  // to: workspaceSettings?.workspaceName,
                  // createdOn: statDetails?.messageCreatedOn,
                  // repliedBy: statDetails?.messageFromDisplayName || statDetails?.messageFromEmail || "Unknown User",
                  // repliedOn: statDetails?.messageRepliedOn,
                }}
                main_data={conversation}
                details_assignee_users={details_assignee_users}
                disable_email={!conversation?.messageStatus || conversation?.messageStatus === "closed" || type === "shared-copies-messages" ? true : false}
                disable_telephone={!conversation?.messageStatus || conversation?.messageStatus === "closed" || type === "shared-copies-messages" ? true : false}
                disable_tag={!conversation?.messageStatus || conversation?.messageStatus === "closed" || type === "shared-copies-messages" ? true : false}
                // only messageStatus === "inProgress"
                disable_assignee={!conversation?.messageStatus || conversation?.assignedUserId !== conversation?.logged_in_user_id || conversation?.messageStatus === "open" || conversation?.messageStatus === "closed" || type === "shared-copies-messages" ? true : false}
                type={type}
              />
            : null
          }

          <MessagesAttachmentSummary
            attachments={statDetails?.attachments}
            summary={statDetails?.summary}
          />
            
        </div>
      </div>
      {/* Laptop/ Mobile */}
      {/* <div className="w-100 h-100 whats-app-conversation-details">
        <div className='whats-app-details-header'>
          <p>General</p>
        </div>
        <div className="whats-app-inbox-wall w-100">
          <div className="right-feed-card rounded">
            <div className="d-flex align-items-start" style={{ padding: '3%' }}>
              {
                reviewerPhoteUrl
                  ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                  : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(userName)}
                    alt="avatar"
                  />
              }
              <div className="user-name-provider-container">
                <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                  <div style={{ marginTop: `0` }}>
                    <div className="user_name">
                      {userName || "User Name"}
                    </div>
                    <div className="phone-number">
                      {fromPhoneNumber || "N/A"}
                    </div>
                  </div>
                </div>
                <img
                  className="social-logo"
                  src={getImage(type)}
                  alt={type || "type-icon"}
                />
              </div>
            </div>
            <div className="footer w-100">
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Days</span>
                <span className="value">{statDetails?.days}</span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Threads</span>
                <span className="value">{statDetails?.threads}</span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="title">Messages</span>
                <span className="value">{statDetails?.totalMessages}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="whats-app-properties-wall w-100">
          <div className='properties-titles'>
            <div className="titles">Ticket No:</div>
            <div className="titles">Email:</div>
            <div className="titles">Telephone:</div>
            <div className="titles">Channel:</div>
            <div className="titles">Assigned To:</div>
            <div className="titles">Status:</div>
          </div>
          <div className='properties-values'>
            <div className="values">
              <div className='value'>{modifyString(statDetails?.ticketNo, 10, 10) || "N/A"}</div>
            </div>
            <div className="values d-flex" style={{ gap: '0.5rem' }}>
              <div className='value email-value'>
                <input
                  type="email"
                  required
                  placeholder='email'
                  value={modifyString(whatsAppEmail, whatsAppEmail?.length > 20 ? 5 : 5, 10, whatsAppEmail?.length > 20 ? "..." : "")}
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    // console.log({ whatsapp_stat_email_value: value });
                    setWhatsAppEmail(value);
                  }}
                />
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log({ whatsapp_stat_email_value: whatsAppEmail });
                  // validate the value of the email
                  const is_email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(whatsAppEmail);
                  if (!is_email || whatsAppEmail?.length < 5 || whatsAppEmail?.length > 30) {
                    dispatch(setErrorMessage("Email is not valid"));
                    return;
                  }
                  handleSaveEmailOrPhoneNumber("email", whatsAppEmail);
                }}
                src={getImage("save-tik")}
                alt="save-tik"
              />
            </div>
            <div className="values d-flex" style={{ gap: '0.5rem' }}>
              <div className='value telephone-value'>
                <input
                  type="text"
                  required
                  placeholder='telephone'
                  value={whatsAppFromPhoneNumber || ""}
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    // console.log({ whatsapp_stat_from_phone_value: value });
                    const inputValue = value;
                    let formattedValue = '';
                    
                    // Check if the first character is '+'
                    if (inputValue[0] === '+') {
                      formattedValue = '+' + inputValue.substring(1).replace(/\D/g, ''); // Remove non-digit characters
                    } else {
                      formattedValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
                    }
                    setWhatsAppFromPhoneNumber(formattedValue);
                  }}
                />
              </div>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log({ whatsapp_stat_from_phone_value: whatsAppFromPhoneNumber });
                  if (whatsAppFromPhoneNumber?.length < 10 || whatsAppFromPhoneNumber?.length > 13) {
                    dispatch(setErrorMessage("Phone number is not valid"));
                    return;
                  }
                  handleSaveEmailOrPhoneNumber("phoneNumber", whatsAppFromPhoneNumber);
                }}
                src={getImage("save-tik")}
                alt="save-tik"
              />
            </div>
            <div className="values">
              <div className='value'>
                <div className='channel-color'>
                  <img style={{ height: '100%', width: '100%' }} src={workspaceSettings?.logo || user_avatar_2} alt="channel" />
                </div>
                <span>{statDetails?.channel || "Whatsapp"}</span>
              </div>
            </div>
            <div className="values">
              <div className='value'>
                <div className='assigned-color'>
                  <img style={{ height: '100%', width: '100%' }} src={getImage(type)} alt="type-icon" />
                </div>
                <span>{"A I Bot"}</span>
              </div>
            </div>
            <div className="values">
              <div className='value'>
                <div className='status-color'></div>
                <span>In Progress</span>
              </div>
            </div>
          </div>
        </div>
        <div className="whats-app-details-wall w-100">
          <div className='details-container'>
            <div className='details-title'>Conversation Summary</div>
            <div className='details-value'>
              {
                
                summary?.length < 120 ?
                  <div>{summary || ""}</div>
                  : !showMore && summary?.length > 120 ?
                    <div>
                      {summary?.substring(0, 120) + "..."}
                      <span style={{ cursor: 'pointer', fontWeight: 600, color: '#000000' }} onClick={() => setShowMore(!showMore)}>More</span>
                    </div>
                    : summary?.length ?
                      <div>
                        {summary + "..."}
                        <span style={{ cursor: 'pointer', fontWeight: 600, color: '#000000' }} onClick={() => setShowMore(!showMore)}>Less</span>
                      </div>
                      : null
              }
            </div>
          </div>
        </div>
      </div> */}

    </React.Fragment>
  )
};

export default AllMessagesConversationDetails;
