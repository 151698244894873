import React from 'react';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import start from '../../../../assets/icons/star-icon.svg';
import prev from '../../../../assets/icons/prev-box.svg';
import next from '../../../../assets/icons/next-box.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setBoxId } from '../../../../unified/reducer';
import Button from '../../../CustomButton/ButtonContainer';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
import './AllFeedCard.scss';

export default function TwitterCard(props: any) {
  const {getImage, handleAddToWall, wallUnifiedData, query_string, inbox, type, activePage, isFromModalBox, last } = props;
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className={`feeds-card-header ${!query_string?.includes("wall") ? "feed-card-header-conversation" : ""} d-flex justify-content-between align-items-start`}>
        {
          inbox?.mentionedbyUserImageUrl || inbox?.imageUrl
          ? <img
              className="user-avatar"
              src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
              alt="avatar"
            />
          : 
            <img
              className="user-avatar"
              src={getGenerateApiImageByName(inbox?.mentionedbyUserName)}
              alt="avatar"
            />
        }
        <div
          className="feeds-card-header-content d-flex justify-content-between align-items-start"
          style={{ width: "100%" }}
        >
          <div>
            <div className="name-rating d-flex m-0 align-items-center">
              <h6 className="user-name">
                {inbox?.mentionedbyUserName?.substr(0, 8) || "Client Name"}
              </h6>
              <p className="my-0 ms-2">
                {"mentioned by " + inbox?.mentionedbyUserName}
              </p>
            </div>
            <p className="text-start date">
              {inbox?.provider === "twitter" && (
                <a
                  className="me-1"
                  href={inbox?.twitter_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {inbox?.twitter_link_title || "@kuber_mannur"}
                </a>
              )}
              {
                // new Date(inbox?.createdOn).toDateString() + ' '
                // + (new Date(inbox?.createdOn).getHours() < 10 ? '0' + new Date(inbox?.createdOn).getHours() : new Date(inbox?.createdOn).getHours())
                // + ':' + (new Date(inbox?.createdOn).getMinutes() < 10 ? '0' + new Date(inbox?.createdOn).getMinutes() : new Date(inbox?.createdOn).getMinutes())
                // + ':' + (new Date(inbox?.createdOn).getSeconds() < 10 ? '0' + new Date(inbox?.createdOn).getSeconds() : new Date(inbox?.createdOn).getSeconds())
                moment(inbox?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
              }
            </p>
            {
              isFromModalBox
              ? <p
                  className="description mb-2"
                >
                  {inbox?.reviewComment}
                  {/* Lorem Ipsum is simply dummy text of the printing and also typesetting industry. Lorem Ipsum has been into the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                </p>
              : <>
                <p
                  className="text-start description mb-2 normal-description"
                  onClick={() => {
                    if (activePage !== "conversations") {
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                  style={{cursor: 'pointer'}}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
                <p
                  className="text-start description mb-2 lg-description"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (activePage !== "conversations"){
                      dispatch(setBoxId(inbox?.SlNo));
                    }
                  }}
                >
                  {inbox?.reviewComment?.length < 31 ? inbox?.reviewComment : inbox?.reviewComment?.substring(0, 27) + "..."}
                </p>
              </>
            }
          </div>

          <img
            className='provider-icon'
            src={getImage(inbox?.provider)}
            alt="provider"
          />
        </div>
      </div>
      {
        query_string?.includes("wall")
          ?
          <div className="feeds-card-footer w-100">
            {
              isFromModalBox
                ? <div className='prev-next-add-wall-container'>
                  <div className='prev-next-container'>
                    <div className='prev-next'
                      onClick={() => {
                        const prevIndex = inbox?.SlNo - 1;
                        console.log({ wallBoxId: prevIndex });
                          
                        if (prevIndex > 0) {
                          dispatch(setBoxId(prevIndex));
                        }
                      }}
                    >
                      <img className='prev' src={prev} alt="prev" />
                    </div>
                    <div className='prev-next'
                      onClick={() => {
                        const nextIndex = inbox?.SlNo + 1;
                        console.log({ wallBoxId: nextIndex });
                          
                        if (nextIndex <= last) {
                          dispatch(setBoxId(nextIndex));
                        }
                      }}
                    >
                      <img className='next' src={next} alt="next" />
                    </div>
                  </div>
                  <Button
                    classes='add-to-wall-btn'
                    onClick={() => {
                      if (!wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId) {
                        handleAddToWall(inbox);
                        // dispatch(setBoxId(null));
                      }
                    }}
                    disabled={wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId}
                  >
                    {wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId)?.reviewId ? "Added" : "Add to Wall"}
                  </Button>
                </div>
                : <div className="d-flex align-items-center justify-content-between w-100">
                  {inbox?.hasOwnProperty("likeCount") &&
                    inbox?.likeCount !== null ? (
                    <div className="like-container">
                      <img
                        className="d-block"
                        src={getImage("like")}
                        alt="like"
                      />
                      <div className="likes">
                        {inbox?.likeCount === 0 || inbox?.likeCount === 1
                          ? inbox?.likeCount + " Like"
                          : inbox?.likeCount + " Likes"}
                      </div>
                    </div>
                  ) : null}
                  <button
                    className={`${(wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId) && activePage !== "conversations"
                      ? ""
                      : query_string?.includes("wall")) &&
                      "add-to-wall-btn-hover"
                      } add-to-wall-btn`}
                    type="button"
                    disabled={wallUnifiedData?.find(
                      (wd: any) =>
                        wd?.reviewId === inbox?.reviewId &&
                        activePage !== "conversations"
                    )}
                    onClick={() => {
                      if (activePage !== "conversations") {
                        handleAddToWall(inbox);
                      }
                    }}
                  >
                    {wallUnifiedData?.find(
                      (wd: any) => wd?.reviewId === inbox?.reviewId
                    ) && activePage !== "conversations"
                      ? "Added"
                      : "Add to Wall"}
                  </button>
                </div>
            }

            {/* <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                {inbox?.provider === "twitter" ? (
                  <span className="d-flex align-items-center">
                    <img
                      style={{ height: 15, width: 15 }}
                      className="d-block me-1"
                      src={getImage("retweet")}
                      alt="retweet"
                    />
                    <span className="d-flex align-items-center">
                      <img
                        style={{ height: 10, width: 10 }}
                        className="d-block ms-2 me-1"
                        src={getImage("heart")}
                        alt="heart"
                      />
                      {inbox?.retweetCount !== null ? (
                        <span className="">
                          {inbox?.retweetCount === 0
                            ? 0
                            : inbox?.retweetCount === 1
                            ? `${inbox?.retweetCount} like`
                            : `${inbox?.retweetCount} likes`}
                        </span>
                      ) : null}
                    </span>
                  </span>
                ) : null}
              </div>
              <button
                className={`${
                  (wallUnifiedData?.find((wd: any) => wd?.reviewId === inbox?.reviewId) && activePage !== "conversations"
                  ? ""
                  : query_string?.includes("wall")) &&
                  "add-to-wall-btn-hover"
                } add-to-wall-btn`}
                type="button"
                disabled={wallUnifiedData?.find(
                  (wd: any) =>
                    wd?.reviewId === inbox?.reviewId &&
                    activePage !== "conversations"
                )}
                onClick={() => {
                  if (activePage !== "conversations") {
                    handleAddToWall(inbox);
                  }
                }}
              >
                {wallUnifiedData?.find(
                  (wd: any) => wd?.reviewId === inbox?.reviewId
                ) && activePage !== "conversations"
                  ? "Added"
                  : "Add to Wall"}
              </button>
            </div> */}
            
          </div>
          : null
      }
    </React.Fragment>
  )
}
