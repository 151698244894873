import React, { useEffect, useState } from "react";
import "./menu.scss";
// import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import {
  Drawer,
  // Avatar
} from "antd";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { closeMobileDrawer } from "../../../profile/reducer";
// import type { DrawerProps } from "antd/es/drawer";
import clap_up_logo from "../../../assets/icons/ClapUp-Icon.svg";
import cross_icon from "../../../assets/icons/close-circle.svg";
import PermissionCheck from "../../PermissionCheck";
import {
  changePage,
  // resetUnifiedInbox, resetWallUnifiedInbox
} from "../../../unified/reducer";
import { useNavigate } from "react-router-dom";
import { menu_routes, wall_menu_routes } from "./menu.config";

interface Props {
  children?: any;
}


const MobileMenu: React.FC<Props> = ({ children }) => {
  const { isReplyingOn } = useSelector((state: any) => state?.unified);
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { isOpenMobileDrawer} = useSelector((state: RootState) => state?.profile);
  const [open, setOpen] = useState(false);
  const [path_name, setPathName] = useState("");
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const query = window.location.search;
  useEffect(() => {
    setTimeout(() => {setPathName(path)}, 2000);
  }, [path]);
  // console.log({ path_name, query })
  // const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  useEffect(() => {
    setOpen(isOpenMobileDrawer);
  }, [isOpenMobileDrawer]);

  const onClose = () => {
    dispatch(closeMobileDrawer());
  };

  const navigate = useNavigate();
  const handleChangePage = (page?: any) => {
    // console.log({ page });
    let url = page + query;
    if (url?.includes("wall") || url?.includes("conversations")) {
      url = url.split("&")[0];
      // console.log({ url });
      if (isReplyingOn) {
        dispatch(changePage(url));
      } else {
        // dispatch(resetUnifiedInbox());
        // dispatch(resetWallUnifiedInbox());
        onClose();
        navigate(url);
      }
    } else {
      if (isReplyingOn) {
        dispatch(changePage(page));
      } else {
        onClose();
        navigate(page);
      }
    }
  };

  // const menu_data = menu_routes;
  const menu_data = query?.includes('wall') ?  wall_menu_routes : menu_routes;
  return (
    <React.Fragment>
      <style type="text/css">
        {`
          .ant-drawer-body {
              padding: 24px 0 !important;
          }

          .ant-drawer-header {
              display: block !important;
              flex: 0;
              align-items: center;
              padding: 5px 15px !important;
              font-size: 16px;
              line-height: 0 !important;
              border: none !important;
          }

          .ant-drawer-header-title {
              display: block !important;
              flex: 1;
              align-items: center;
              min-width: 0;
              min-height: 0;
          }

          .ant-drawer-mask {
              background: rgba(0, 0, 0, 0.005) !important;
              // pointer-events: auto;
          }

          .ant-drawer-content-wrapper {
              z-index: 9999;
              border-right: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              box-shadow: none !important;
              position: relative;
              height: ${
                path_name === "/" || path_name === "/dashboard" 
                ? "100%"
                : "calc(100% - 100px)"
              };
          }
              
        `}  
      </style>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
              padding: "10px 10px",
            }}
            className="mobile-drawer-header"
          >
            <div className="logo-title">
              <img src={clap_up_logo} alt="logo" />
              <p>Clapup</p>
            </div>
            <img
              onClick={onClose}
              className="mobile-cross-icon"
              src={cross_icon}
              alt="cross"
            />
            {/* <CloseOutlined onClick={onClose} style={{ cursor: "pointer" }} /> */}
          </div>
        }
        placement={"left"}
        width={"80%"}
        onClose={onClose}
        open={open}
        closeIcon={false}
        
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button type="primary" onClick={onClose}>
        //       OK
        //     </Button>
        //   </Space>
        // }
      >
        <div className="mobile-drawer-body position-relative">
          <ul>
            {/* {
              top_menu_routes?.map((item: any) => {
                if (item?.id !== 8) {
                  return (
                      <li key={item?.id} title={item?.title} className={`li-${item?.id}`}>
                        <button
                          type="button"
                          className={`${(path_name === item?.url || (path_name === "/" && item?.root)) && "active"}`}
                          onClick={() => path_name !== item?.url && handleChangePage(item?.url)}
                        >
                          <img src={item?.icon} alt="" />
                        </button>
                      </li>
                  )
                } else {
                  return null
                }
            })} */}
            
            {menu_data?.map((item: any) => {
              return (
                // <PermissionCheck key={item?.key} permissionName={item?.permissionName}>
                  <li
                    key={item?.key}
                    className={`${(path_name?.includes(item?.key) || (path_name === "/" && item?.root)) && "mobile-page-active"}`}
                    onClick={() => handleChangePage(item?.url)}
                  >
                    <img src={item?.mobile_icon} alt={item?.title} />
                    <p>{item?.mobile_title || item?.title}</p>
                  </li>
                // </PermissionCheck>
              );
            })}
          </ul>
        </div>

        <PermissionCheck permissionName="profile">
          <div
            className={`mobile-drawer-footer`}
            onClick={() => handleChangePage("/user/personal-info")}
          >
            <div className={`${
              path_name?.includes("personal-info") && "mobile-drawer-footer-active"
            } mobile-drawer-footer-container`}>
              {
                workspaceSettings?.logo ? (
                  <img
                    // className="rounded rounded-circle me-2"
                    // style={{ height: 18, width: 18 }}
                    src={workspaceSettings?.logo}
                    alt=""
                  />
                ) : (
                  <span className='border rounded-circle p-1 text-light' style={{fontSize: "0.6rem", background: '#14B0BF'}}>
                    {
                      workspaceSettings?.workspaceName?.split(" ")?.slice(0, 2)?.length === 2
                      ? workspaceSettings?.workspaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                      : workspaceSettings?.workspaceName?.substr(0, 2).toUpperCase()
                    }
                  </span>
                )
              }
              {/* <img src={workspaceSettings?.logo} alt="WS Name" /> */}
              <p>{workspaceSettings?.workspaceName}</p>
            </div>
          </div>
        </PermissionCheck>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileMenu;


