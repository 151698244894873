// Messages & emails
import all_messages_icon from "../../../assets/icons/feed-left/message-email.svg";
import assign_to_me_icon from "../../../assets/icons/feed-left/assign-icon.svg";
import share_with_me_icon from "../../../assets/icons/feed-left/share-with-me-icon.svg";
import share_copy_icon from "../../../assets/icons/feed-left/share-copy-icon.svg";
import archive_icon from "../../../assets/icons/feed-left/archive-icon.svg";
import bot_icon from "../../../assets/icons/feed-left/bot-icon.svg";
import message_email_title_icon from "../../../assets/icons/feed-left/circle-plus.svg";

// Reviews & comments
import all_items_icon from "../../../assets/icons/feed-left/reviews-comments-all-items.svg";

// Filter By Teams
import team_primary_icon from "../../../assets/icons/feed-left/team-primary.svg";
import team_marketing_icon from "../../../assets/icons/feed-left/team-marketing.svg";
import team_human_resource_icon from "../../../assets/icons/feed-left/team-human-resource.svg";
import team_developers_icon from "../../../assets/icons/feed-left/team-developers.svg";

// Filter By Labels
import label_billing_icon from "../../../assets/icons/feed-left/label-billings.svg";
import label_lead_icon from "../../../assets/icons/feed-left/label-lead.svg";
import label_human_resource_icon from "../../../assets/icons/feed-left/label-human-resource.svg";
import label_developers_icon from "../../../assets/icons/feed-left/label-developers.svg";

const messages_n_emails = {
    title: "Messages & Emails",
    title_icon: message_email_title_icon,
    tabs: [
        {
            id: 1,
            icon: all_messages_icon,
            route: "/all-messages",
            title: "All Messages",
            key: "All Messages",
        },
        {
            id: 2,
            icon: assign_to_me_icon,
            route: "/all-messages/assigned-to-me",
            title: "Assigned to Me",
            key: "Assigned to Me",
        },
        {
            id: 3,
            icon: share_with_me_icon,
            route: "/all-messages/shared-with-me",
            title: "Shared with me",
            key: "Shared with me",
        },
        {
            id: 4,
            icon: share_copy_icon,
            route: "/all-messages/shared-copies",
            title: "Shared Copies",
            key: "Shared Copies",
        },
        {
            id: 5,
            icon: archive_icon,
            route: "/all-messages/archives",
            title: "Archives",
            key: "Archives",
        },
        // // hidden section - new requirement
        // {
        //     id: 6,
        //     icon: bot_icon,
        //     route: "/bot-messages",
        //     title: "Bots",
        //     key: "Bots",
        // },
    ]
};

const reviews_n_comments = {
    title: "Reviews & Comments",
    tabs: [
        {
            id: 1,
            icon: all_items_icon,
            route: "/unified?all=conversations",
            sub_route: "/unified",
            title: "All Items",
            key: "All Items",
        },
        // {
        //     id: ,
        //     icon: ,
        //     title: "",
        //     tab_key: "",
        // },
    ]
};

const filter_by_teams = {
    title: "Team",
    tabs: [
        {
            id: 1,
            icon: team_primary_icon,
            key: "team-primary",
            title: "Primary"
        },
        {
            id: 2,
            icon: team_marketing_icon,
            key: "team-marketing",
            title: "Marketing"
        },
        {
            id: 3,
            icon: team_human_resource_icon,
            key: "team-human-resource",
            title: "Human Resource"
        },
        {
            id: 4,
            icon: team_developers_icon,
            key: "team-developers",
            title: "Developers"
        },
    ]
};

const filter_by_labels = {
    title: "Labels",
    tabs: [
        {
            id: 1,
            icon: label_billing_icon,
            key: "label-billing",
            title: "Billing"
        },
        {
            id: 2,
            icon: label_lead_icon,
            key: "label-lead",
            title: "Lead"
        },
        {
            id: 3,
            icon: label_human_resource_icon,
            key: "label-human-resource",
            title: "Human Resource"
        },
        {
            id: 4,
            icon: label_developers_icon,
            key: "label-developers",
            title: "Developers"
        },
    ]
};

export { messages_n_emails, reviews_n_comments, filter_by_teams, filter_by_labels };
