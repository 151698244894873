import React from 'react';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { getImage } from '../../unified/image_service';
import { resetEmbedRecords } from '../Settings/BioPageSettings/bioPageReducer';

interface DropdownModalFormProps {
    setOpenDropdown?: any;
    openDropdown?: any;
    menu?: any;
}

const DropdownModalForm: React.FC<DropdownModalFormProps> = ({menu, setOpenDropdown, openDropdown}) => {
    const dispatch = useDispatch();
    
    return (
        <React.Fragment>
            <Dropdown
                onOpenChange={(e: any) => {
                    setOpenDropdown(!openDropdown);
                }}
                open={openDropdown}
                trigger={["click"]}
                overlay={menu}
                placement="bottomRight"
            >
                <button
                    type="button"
                    onClick={() => {
                        setOpenDropdown(!openDropdown);
                        dispatch(resetEmbedRecords());
                    }}
                    className="add-button"
                >
                    <span>Add New</span> <img src={getImage("add-plus")} alt="arrow-down-icon" />
                </button>
            </Dropdown>
        </React.Fragment>
    );
};

export default DropdownModalForm;
