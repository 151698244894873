import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LocationSelectionTable from "./Components/LocationSelectionTable";
import "./IntegrationsSettings.scss";
import { setIntegrationTab } from "./reducer";
import CommonModalCustomWidth from "../../../components/CommonModal/CommonModalCustomWidth";
import ConnectedAccounts from "./Components/ConnectedAccounts";
import IntegrationManagement from "./Components/IntegrationManagement";
// import { ChildPageTabs, MobilePageTabs, ParentPageTabs } from "../Page.Tabs";

const IntegrationsSettings: React.FC = () => {
    const dispatch = useDispatch();
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const { integration_tab } = useSelector((state: any) => state?.integrationState);
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);

    const handleTabChange = (tab?: any) => {
        dispatch(setIntegrationTab(tab));
    }
    return (
        <React.Fragment>
            <div className="body-container">
                <div className="integration-setting-list-container">
                    <div className="settings-header-title">
                        <h3>Integrations</h3>
                    </div>
                    <div className="settings-header-tab">
                        <div className={`tab ${integration_tab === "Integrations" ?  "active" : ""}`} onClick={() => handleTabChange("Integrations")}>Integrations</div>
                        <div className={`tab ${integration_tab === "Connected Accounts" ?  "active" : ""}`} onClick={() => handleTabChange("Connected Accounts")}>Connected Accounts</div>
                    </div>

                    {
                        integration_tab === "Integrations"
                        ? <IntegrationManagement/>
                        : <ConnectedAccounts/>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationsSettings;