import React, { useEffect } from 'react';
import './UnPermission.scss';
import { getImage } from '../../unified/image_service';
const UnPermission = () => {

  return (
    <React.Fragment>
      <div className="un-permission-section">
        <img style={{pointerEvents: 'none'}} className='un-permission-icon' src={getImage("un-permissible-cross-icon")} alt="un-permission-cross-icon" />
        <p style={{pointerEvents: 'none'}} className='un-permission-description'>You don't have permission to view this page</p>
      </div>
    </React.Fragment>
  )
}

export default UnPermission;
