import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { setEditableAgent } from "../agentReducer";
import { toggleShowMobileSettingsNavBar } from "../../../../signup-login/loginReducer";
import { getBotDetailsSettings, updateBotDetailsSettings } from "../agentReducer";
import { setErrorMessage, setSuccessMessage } from "../../../../unified/reducer";
import { updateBotFileData, uploadBotFileData } from "../agentSaga";
import { api_url, api_version } from "../../../../utils/server";
import axios from "axios";

const AgentList: React.FC = () => {
    const { is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const dispatch = useDispatch();
    // const history = useNavigate();
    const { all_bot_details } = useSelector((state: any) => state?.agentState);
    const { success_message } = useSelector((state: any) => state?.unified);
    // const [isReadyToAddAgent, setIsReadyToAddAgent] = useState<any>(false);
    const [agents, setAgents] = useState<any>([]);
    // const [agents, setAgents] = useState<any>([
    //     { agent_id: 1, color: '#0061f2', name: 'Biopage Bot', isEnableOnBioPage: false },
    //     { agent_id: 2, color: '#f29900', name: 'Clapup Bot', isEnableOnBioPage: true },
    //     // { agent_id: 3, color: '#21a573', name: 'Biopage Bot', isEnableOnBioPage: false }
    // ]);

    useEffect(() => {
        if (all_bot_details?.length) {
            setAgents(all_bot_details);
        }
    },[all_bot_details?.length, success_message]);

    const handleEnableAndDisableAgentOnBioPage = async (fieldName: any, fieldValue: any, agentId: any) => {
        // console.log({columnValue});
        try {
            if(!fieldValue && !agentId){
                return;
            }
            const dataObject: any = {
                // clapupId: logged_in_user_secret_data?.cuid,
                // workSpaceId: logged_in_user_secret_data?.wuid,
                // roleId: logged_in_user_secret_data?.roleId,
                clapupUserId: logged_in_user_secret_data?.cuid || null,
                aiChatBotId: agentId,
                fieldName: fieldName,
                fieldValue: fieldValue,
                // linktype: "BiopageclapUpBotDetails",
            }
            console.log({dataObject});
            // dispatch(updateBotDetailsSettings(dataObject));
            const url = api_url + `/api/${api_version}/AIChatBot/UpdateAIChatBotSettings`;
            const { status }: any = await axios.post(
                url,
                dataObject,
                {
                    headers: {
                        'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // const status = 200;
            if (status === 200) {
                const accessed_data = {
                    clapupUserId: logged_in_user_secret_data?.cuid,
                    workspaceId: logged_in_user_secret_data?.wuid,
                    roleId: logged_in_user_secret_data?.roleId,
                }
                dispatch(getBotDetailsSettings("From AgentList", accessed_data));
                setTimeout(() => {
                    dispatch(setSuccessMessage("Saved Successfully"));
                }, 2000);
            }
        } catch (err: any) {
            console.log({ err });
            dispatch(setErrorMessage("Server Error"));
        }

    }

    // const handleEnableAndDisableAgentOnBioPage = (id: any) => {
    //     const agent_list = agents?.map((agent: any) => { 
    //         if (id === agent?.agent_id) {
    //             return {
    //                 ...agent,
    //                 isEnableOnBioPage: !agent?.isEnableOnBioPage,
    //             }
    //         }
    //         return agent;
    //     });
    //     setAgents(agent_list);

    // }
    
    const handleEditableAgent = (team_id: any) => {
        console.log({team_id_for_editing: team_id})
        dispatch(setEditableAgent(team_id));
    }

    const handleRowClick = (team_id: number) => {
        handleEditableAgent(team_id);
    };

    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button
                    // onClick={() => setIsReadyToAddAgent(true)}
                >
                    <div>Add Agent</div>
                    <img src={getImage("add-plus")} alt="invite-icon" />
                </button>
            </div>
            <div className="body-container">
                <div className="agent-list-settings-container">
                    <div className="settings-header-title">
                        <h3>Agents</h3>
                    </div>
                    <div className="agent-list-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead><tr><th></th><th></th><th></th></tr></thead>
                                <tbody>
                                    {agents?.map((agent: any, idx: number) => (
                                        <tr key={idx}>
                                            <td>
                                                <div
                                                    className="table-row-content"
                                                    // onClick={() => handleRowClick(agent?.agent_id)}
                                                >
                                                    <div className="agent-title-container">
                                                        <div className="agent-icon" style={{ backgroundColor: agent?.primaryColor }}></div>
                                                        <div className="agent-name">{agent?.aiChatBotName}</div>
                                                    </div>
                                                    <div>
                                                        <img
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevents triggering the row click
                                                                handleEnableAndDisableAgentOnBioPage("enableInBioPage", agent?.isEnabledInBioPage ? 0 : 1, agent?.aiChatBotId);
                                                            }}
                                                            className="switch-icon"
                                                            // src={getImage(agent?.isActive ? "active-notification" : "in-active-notification")} 
                                                            src={getImage(agent?.isEnabledInBioPage ? "active-notification" : "in-active-notification")} 
                                                            alt="tik"
                                                        />
                                                    </div>
                                                    <div
                                                        className="open-setting"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            handleRowClick(agent?.aiChatBotId)
                                                        }}
                                                    >
                                                        Open Settings
                                                    </div>
                                                    {/* <img
                                                        className="delete-icon"
                                                        src={getImage("delete-icon")}
                                                        alt="Open"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            handleOpenAgent(team.team_id);
                                                        }}
                                                    /> */}
                                                </div>
                                            </td>
                                      </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default AgentList;