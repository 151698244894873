import React, {useState, useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import "./TeamsManagement.scss";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import AddUser from "./AddUser";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import { setReadyToTeamMemberUser, toggleUpdatedTeamContent, triggerRemoveTeamMember } from "../teamsReducer";
// import Dialog from "../../../../components/Dialogs/Dialog";
import { setShowGlobalWarningDialog } from "../../../../components/ContentComponents/reducer";
import WarningModalForm from "../../../../components/Dialogs/GlobalModalForms/WarningModalForm";

const TeamMembers: React.FC = () => {
    const dispatch = useDispatch();
    const { is_ready_to_add_team_member_user, is_updated_team_content, editable_team, team_users: teamMembers } = useSelector((state: any) => state?.teamState);
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const {
        // open_global_drawer, showEditorFieldDrawer,
        showGlobalWarningDialog,
    }: any = useSelector((state: any) => state?.contentState);

    const deleteTeamMember = (member_delete_payload: any) => {
        const { teamId } = member_delete_payload || {};
        // console.log({ team_id_for_deleting: teamId, data });
        if (!teamId) {
            return;
        }
        dispatch(triggerRemoveTeamMember(member_delete_payload));
    }

    const handleDeleteTeamMember = (member_id: any) => {
        console.log("Came for deleting member...", { member_id });
        if (!member_id) {
            return;
        }
        const member_delete_payload = {
            teamId: editable_team?.teamId,
            clapupUserIds: member_id
        }
        const delete_object = {
            api_url:"",
            header_title: "Delete Member",
            warning_message: "If you click Yes, the member will be deleted forever. This action is irreversible.",
            left_button_title: "Back",
            right_button_title: "Continue",
            success_message: "Member Deleted successfully",
            member_delete_payload,
        }
      
        dispatch(setShowGlobalWarningDialog(delete_object));
    }

    
    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);


    const handleCancel = () => {
        dispatch(setReadyToTeamMemberUser(false));
    }

    // console.log({ setting_membered_users: teamMembers });
    return (
        <React.Fragment>
            {/* Add User */}
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_add_team_member_user} 
                setModalVisible={(flag: any) => dispatch(setReadyToTeamMemberUser(flag))}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="add-user-setting-table-container "
                footer={null}
            >
                <AddUser handleCancel={handleCancel} />
            </CommonModalCustomWidth>
            {/* Delete Team Member */}
            {
                showGlobalWarningDialog?.header_title === "Delete Member"
                ?   <WarningModalForm
                        showWarningDialog={showGlobalWarningDialog}
                        data_fields={showGlobalWarningDialog}
                        header_title={showGlobalWarningDialog?.header_title}
                        warning_message={showGlobalWarningDialog?.warning_message}
                        left_button_title={showGlobalWarningDialog?.left_button_title || "Back"}
                        right_button_title={showGlobalWarningDialog?.right_button_title || "Continue"}
                        handleCancel={() => dispatch(setShowGlobalWarningDialog(null))}
                        parentCallback={(data: any) => {
                            console.log({data});
                            deleteTeamMember(data?.member_delete_payload);
                        }}
                    />
                : null
            }

            {/* <Dialog
                open={isDeleteTeamMember}
                parentCallback={(flag: any) => handleDeleteTeamMember(flag)}
                classes='dialog-container-content'
                title='Delete Member?'
                warning_dialog_content={`If you click Yes,  the member will be deleted forever. This action is irreversible.` }
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Delete'
            /> */}
            <div className="team-member-table">
                <div className='table-responsive'>
                    <table className="table">
                        <thead className="d-none"><tr><th>Email</th><th>Role</th><th></th></tr></thead>
                        <tbody>
                            {teamMembers?.map((member: any, idx: number) => (
                            <tr key={idx}>
                                <td>{member?.email}</td>
                                <td>{member?.roleName}</td>
                                <td>
                                <img
                                    src={getImage("delete-icon")}
                                    alt="Delete"
                                    style={{ cursor: 'pointer' }} 
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the row click
                                        handleDeleteTeamMember(member?.id);
                                    }}
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default TeamMembers;