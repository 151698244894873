import React from 'react';
import './ConversationComponent.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';
import moment from 'moment';
import { convertISODate } from '../../../../../utils/convertingFunction';

const ConversationCommonBody = ({ data_fields, message_data, handleParentCallback, isReply, isEditable }: any) => {
    const {
        username,
        user_profile,
        star_ratings, inVisibleRatingNumber, tag_text,  // optional props fields
        date,
        message_subject, // optional props fields
        attachment,
        message,
        show_fb_comments,
        fb_comments,
        show_fb_likes,
        fb_likes,
        // mention special
        show_fb_mention_comments,
        fb_mention_comments,
    } = data_fields || {};
    return (
        <React.Fragment>
            <div className="conversation-body">
                <div className="header-affiliate-info-container">
                    <div className="header-container">
                        <div className="user-info">
                            <img className="profile-pic" src={user_profile || getGenerateApiImageByName(username)} alt="Profile" />
                            <div className="user-details">
                                <div className="username">{username || "Unknown User"}</div>
                                {
                                    tag_text ? <div className="tag">{tag_text || "asked to workspace-name"}</div> : null
                                }
                                
                                {
                                    !tag_text && Number(star_ratings)
                                    ?   
                                        <div className="rating-stars">
                                            <div className="stars">
                                                {
                                                Array.from(Array(Math.floor(parseFloat(star_ratings))).keys()).map((_: any, index: number) => {
                                                return (
                                                    <img
                                                        key={index}
                                                        src={getImage("star")}
                                                        alt="star"
                                                    />
                                                );
                                                })
                                                } 
                                            </div>
                                            {/* <span className="stars">★★★★☆</span> */}
                                            {
                                                inVisibleRatingNumber
                                                ? null
                                                : <div className="rating">{star_ratings}/5</div>
                                            }
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="date-time">
                            {
                                moment(convertISODate(date)).utc().format("ll") + " " + moment(convertISODate(date)).utc().format("LT")
                            }
                        </div>
                    </div>
                    {
                        message_subject
                        ?   <div className="conversation-affiliate-info">
                                {message_subject || "Reward and Affiliate program for our platform"}
                            </div>
                        : null
                    }
                </div>

                <div className="conversation-message" style={{ whiteSpace: 'pre-line', margin: 0 }}>
                    {
                        attachment || message || "As the brand, so is the service. They are extremely well verse with in quality.?"
                    }
                </div>
                
                <div className='reply-edit-comment-like-container'>
                    {
                        isReply
                        ?  <div className="review-reply"
                                onClick={() => {
                                    // console.log({message_data})
                                    message_data && handleParentCallback(message_data);
                                }}
                            >
                                Reply
                            </div>
                        : null
                    }
                    
                    {
                        isEditable
                        ?  <div className="edit-button"
                                onClick={() => {
                                    handleParentCallback && handleParentCallback();
                                }}
                            >
                                Edit
                            </div>
                        : null
                    }

                    {
                        show_fb_comments
                        ?
                            <div className="comment-container">
                                {fb_comments === 0 || fb_comments === 1
                                ? fb_comments + " comment"
                                : fb_comments + " comments"}
                            </div>
                        : null
                    }

                    <div className='like-comment-container'>
                        {
                            show_fb_mention_comments
                            ?
                                <div className="comment-container">
                                    {fb_mention_comments === 0 || fb_mention_comments === 1
                                    ? fb_mention_comments + " comment"
                                    : fb_mention_comments + " comments"}
                                </div>
                            : null
                        }

                        {
                            show_fb_likes
                            ?
                                <div className="like-container">
                                    <img
                                        src={getImage("like")}
                                        alt="like"
                                    />
                                    <div className="likes">
                                        {fb_likes === 0 || fb_likes === 1
                                        ? fb_likes + " Like"
                                        : fb_likes + " Likes"}
                                    </div>
                                </div>
                            : null
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default ConversationCommonBody;
