import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../unified/reducer";
import "./Notifications.scss";
import error_circle_cross from '../../assets/icons/pro-settings/error-circle-icon-1.33.svg';


// type Props = {
//   message?: string;
//   isShow?: boolean;
//   removeDialog?: any;
// };

export default function Error() {
  const dispatch = useDispatch();
  const { error_message } = useSelector(
    (state: any) => state?.unified
  );
  // const [show, setShow] = useState<any>(true);
  useEffect(() => {
    if (error_message) {
      setTimeout(() => {
        dispatch(setErrorMessage(null));
      }, 3000);
    }
  }, [dispatch, error_message]);

  return error_message ? 
    <div className={"dialog"}>
      <div className={`dialog-container`}>
        <div
          // onClick={() => dispatch(setSuccessMessage(null))}
          className={`error-dialog`}
        >
          <img src={error_circle_cross} alt={"error-icon"}/>
          <p className="error-message">
            {
              error_message || "Error message here!"
            }
          </p>
        </div>
      </div>
    </div>
  : null

}
