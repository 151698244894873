import * as signalR from "@microsoft/signalr";
import { signalR_team_api_url } from "./server";

const hubUrl = signalR_team_api_url || "https://signalrapi-dev.clapup.me/TeamHub";

let TeamConnection = new signalR.HubConnectionBuilder()
    .withUrl(
        hubUrl, // + "/TeamHub",
        {
            withCredentials: true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }
    )
    .configureLogging(signalR.LogLevel.Information)
    .build();

TeamConnection.start()
    .then(() => {
        console.log("SignalR_Inbox_Team: connected");
    })
    .catch((error) => {
        console.log("SignalR_Inbox_TeamConnection error:", error);
    });

export default TeamConnection;