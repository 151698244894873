import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  setFolderPathContents,
  // // new requirements
  // setFolderPathsToIndexDB,
  setOpenGlobalModal,
} from '../reducer';
import { getImage } from '../../../unified/image_service';
import { DebounceInput } from 'react-debounce-input';
import { api_url, api_version } from '../../../utils/server';
import axios from 'axios';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
import { managingKeyValueData } from '../saga';
import { deleteKeyValue, getKeyValue } from '../../../utils/indexedDBManagementService';

const MoveOrCopyForm = ({content, category, team_category, category_type, isOnlyForCoping, parent_folder_paths, parent_folder_paths_check_index, folder_data, active_content, parentCallback }: any) => {
  const {
    // open_global_drawer,
    path_contents: parent_path_contents,
    contentNextDate, // usable for scrolling
    recentFiles,
    open_global_modal,
  }: any = useSelector((state: RootState) => state?.contentState);
  const {logged_in_user_secret_data}: any = useSelector((state: RootState) => state?.login);

  const [folderPaths, setFolderPaths] = useState<any>([]);
  const [folders, setFolders] = useState<any>([]);

  const [searchText, setSearchText] = useState<any>("");
  const [searchFolders, setSearchFolders] = useState<any>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [folderType, setFolderType] = useState<any>("simple");
  const dispatch = useDispatch();
  
  // const navigate = useNavigate();
  // const route_path = window.location.pathname;
  // const query_string = window.location.search;

  // ========== only first time come ======
  useEffect(() => {
    (async () => {
      if (folder_data?.length) {
        setFolders(folder_data);
        setFolderPaths([]);
        setSearchText("");
        setSearchFolders([]);
      }
      await deleteKeyValue("all_child_folders");
    })();
  }, [open_global_modal]);
  // ========== only first time come ======
  
  // search event from front end
  useEffect(() => {
    if (searchText) {
      handleSearch(searchText);
    } else {
      setSearchText("");
      setSearchFolders([]);
    }
  }, [searchText]);
  
  // get folders by changing folder path bread-crumb
  useEffect(() => {
    if (folderPaths?.length) {
      getFolderContents();
    }
  }, [folderPaths?.length]);

  // search folders
  const handleSearch = (searched_text: any) => {
    console.log({ searched_text });
    const filter_searched_data = folders?.filter((item: any) => {
      return (item?.contentFileName || item?.folderName || item?.teamName)?.toLowerCase().includes(searched_text?.toLowerCase());
    });
    setSearchFolders(filter_searched_data);
  };

  const handleCancel = async() => {
    await deleteKeyValue("all_child_folders");
    dispatch(setOpenGlobalModal(null));
  };

  const moveToIndexDB = async(source_key?: any, destination_key?: any, moving_content?: any) => { 
    try { 
      // const source_data = await getKeyValue(source_key);
      // const destination_data = await getKeyValue(destination_key);
      const all_folder_key_content_details: any = await getKeyValue("all_path_contents");
      const source_data: any = all_folder_key_content_details?.find((item: any) => item.hasOwnProperty(source_key)) || null;
      const destination_data: any = all_folder_key_content_details?.find((item: any) => item.hasOwnProperty(destination_key)) || null;
      const except_source_destination_data: any[] = all_folder_key_content_details?.filter(
        (item: any) => !item.hasOwnProperty(source_key) && !item.hasOwnProperty(destination_key)
      ) || [];
      console.log({ source_key, source_data, destination_key, destination_data, moving_content });
      // return;
      // source data
      const current_folder_path_contents: any[] = parent_path_contents?.filter((item: any) => item?.contentId !== moving_content?.contentId);
      
      const updated_content_data: any = {
        contentDetails: current_folder_path_contents,
        nextDate: contentNextDate,
        recentFiles: recentFiles
      };
      const updated_all_path_contents: any[] = [...except_source_destination_data];
      if (source_data) {
        // await managingKeyValueData({
        //   // <isForInserting: false>, this is for testing...
        //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
        //   isForInserting: false,
        //   key: source_key,
        //   value: updated_content_data
        // });
        updated_all_path_contents.push({[source_key]: updated_content_data})
      }
      
      // destination data
      if (destination_data) {
        const { contentDetails: destination_contentDetails, nextDate: destination_nextDate, recentFiles: destination_recentFiles } = destination_data[destination_key] || {};
        const folder_contents: any[] = destination_contentDetails?.filter((item: any) => item?.isFolder)
                                      ?.filter((item: any) => item?.contentId !== moving_content?.contentId) || [];
        const files: any[] = destination_contentDetails?.filter((item: any) => !item?.isFolder)
                                      ?.filter((item: any) => item?.contentId !== moving_content?.contentId) || [];
        // return;
        const destination_current_folder_path_contents: any[] = [...folder_contents, moving_content, ...files];
        
        const destination_updated_content_data: any = {
          contentDetails: destination_current_folder_path_contents,
          nextDate: destination_nextDate,
          recentFiles: destination_recentFiles
        }
        console.log({ destination_contentDetails, files, folder_contents, destination_current_folder_path_contents, destination_key });
        if (moving_content && !moving_content?.isFolder) {
          destination_updated_content_data["recentFiles"] = recentFiles?.length ? [moving_content, ...recentFiles?.slice(0, 5)] : [moving_content];
        }

        updated_all_path_contents.push({[destination_key]: destination_updated_content_data})

        // await managingKeyValueData({
        //   // <isForInserting: false>, this is for testing...
        //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
        //   isForInserting: false,
        //   key: destination_key,
        //   value: destination_updated_content_data
        // });
      }

      console.log({except_source_destination_data, updated_all_path_contents, source_key, destination_key, source_data, destination_data, moving_content });

      
      await managingKeyValueData({
        // <isForInserting: false>, this is for testing...
        // when it is<isForInserting: true>, api will never called and take data from indexedDB
        isForInserting: true,
        key: "all_path_contents",
        value: updated_all_path_contents
      });
      dispatch(setFolderPathContents(updated_content_data));
      dispatch(setSuccessMessage(`${moving_content?.isFolder ? "Folder" : "File "} Moved Successfully`));
      handleCancel();
    } catch (err: any) {
      console.log({ err });
    }
  };

  const copyingIndexDB = async(destination_key?: any, coping_content?: any) => { 
    try { 
      const all_folder_key_content_details: any = await getKeyValue("all_path_contents");
      const destination_data: any = all_folder_key_content_details?.find((item: any) => item.hasOwnProperty(destination_key)) || null;
      const except_destination_data: any[] = all_folder_key_content_details?.filter((item: any) => !item.hasOwnProperty(destination_key)) || [];

      const updated_all_path_contents: any[] = [...except_destination_data];
      
      // destination data
      if (destination_data) {
        const {
          contentDetails: destination_contentDetails,
          nextDate: destination_nextDate,
          recentFiles: destination_recentFiles
        } = destination_data[destination_key] || {};
        const folder_contents: any[] = destination_contentDetails?.filter((item: any) => item?.isFolder)
                                      ?.filter((item: any) => item?.contentId !== coping_content?.contentId)  || [];
        const files: any[] = destination_contentDetails?.filter((item: any) => !item?.isFolder)
                            ?.filter((item: any) => item?.contentId !== coping_content?.contentId) || [];
        // console.log({ data, files, folder_contents, current_archives_data, folder_key_name: "archives" });

        const destination_current_folder_path_contents: any[] = [...folder_contents, coping_content, ...files];
        
        const destination_updated_content_data: any = {
          contentDetails: destination_current_folder_path_contents,
          nextDate: destination_nextDate,
          recentFiles: destination_recentFiles
        }
        if (coping_content && !coping_content?.isFolder) {
          destination_updated_content_data["recentFiles"] = recentFiles?.length ? [coping_content, ...recentFiles?.slice(0, 5)] : [coping_content];
        }

        updated_all_path_contents.push({ [destination_key]: destination_updated_content_data });
        // // except_destination_data
        // await managingKeyValueData({
        //   // <isForInserting: false>, this is for testing...
        //   // when it is<isForInserting: true>, api will never called and take data from indexedDB
        //   isForInserting: false,
        //   key: destination_key,
        //   value: destination_updated_content_data
        // });
      }
      console.log({ updated_all_path_contents, destination_key, destination_data, coping_content });
      await managingKeyValueData({
        // <isForInserting: false>, this is for testing...
        // when it is<isForInserting: true>, api will never called and take data from indexedDB
        isForInserting: true,
        key: "all_path_contents",
        value: updated_all_path_contents
      });
      handleCancel();
      dispatch(setSuccessMessage(`${coping_content?.isFolder ? "Folder" : "File "} Copied Successfully`));
    } catch (err: any) {
      console.log({ err });
    }
  };

  const getPayloadAndSourceDestinationPath = () => {
    try {
      const workspaceId = logged_in_user_secret_data?.wuid;
      const clapupUserId = logged_in_user_secret_data?.cuid;
      if (!workspaceId || !clapupUserId) {
        return null;
      }
      // const child_folder_paths = folderPaths?.slice(1)?.map((item: any) => item.folderPath);
      // const check_from_root = folders?.find((item: any) => item?.folderPath === selectedFolder?.contentFolderPath || item?.internalName === selectedFolder?.contentFolderPath);
      // const last_path = folderPaths?.length ? folderPaths?.at(-1) : "";
      // const check_path_is_available = last_path?.contentFolderPath === selectedFolder?.contentFolderPath;
      // const destinationFolderPath = child_folder_paths?.join("/");
      // const child_folder_category = folderPaths?.length ? folderPaths[0] : {};
      // console.log({ folderPaths, check_from_root, child_folder_paths, last_path, check_path_is_available, selectedFolder, content });
      // if (!check_path_is_available) {
      // if (!check_from_root) {
        //   dispatch(setErrorMessage("Please select a folder"));
        //   return;
        // }
        // }
      if (!selectedFolder) {
        dispatch(setErrorMessage("Please select a folder"));
        return null;
      }
      const teamFolder: any = folderPaths?.find((item: any) => item.teamId) || selectedFolder;
      let child_category = "";
      let child_folder_root = "";
      if (selectedFolder?.destination_path?.includes("shared")) {
        child_category = "shared"; // shared or personal for sure
        // child_folder_root = "sharedfiles"; // requirement is changed again
      } else if (selectedFolder?.destination_path?.includes("personal")) {
        child_category = "personal"; // shared or personal for sure
        // child_folder_root = "mypersonalfiles"; // requirement is changed again
      } else {
        child_category = selectedFolder?.child_category;
        child_folder_root = selectedFolder?.child_category;
      }
      const categoryFrom = category === "shared" || category === "personal" ? category : (content?.category || "main");
      // const source_path_payload = content?.cloudFlareR2Path || ""; // requirement is changed again
      let source_path_indexDB = selectedFolder?.source_path; // work with index db
      if (source_path_indexDB?.includes("main")) {
        source_path_indexDB = selectedFolder?.source_path?.replaceAll(`main`, team_category?.internalName);
      }
      if (source_path_indexDB?.includes("sharedWithMe")) {
        source_path_indexDB = selectedFolder?.source_path?.replaceAll(`sharedWithMe`, "");
      }
      let destination_path_indexDB = selectedFolder?.destination_path; // work with index db
      if (destination_path_indexDB?.includes("main")) {
        destination_path_indexDB = selectedFolder?.destination_path?.replaceAll(`main`, teamFolder?.internalName);
      }
      // const source_path_payload = source_path_indexDB?.replaceAll(`${category}-`, "") + content?.cloudFlareR2Path;
      let source_path = "";
      if (selectedFolder?.source_path?.includes(`${category}-`)) {
        // source_path = source_path_indexDB?.replaceAll(`${category}-`, `${category}`);
        source_path = selectedFolder?.source_path?.replaceAll(`${category}-`, ``);
      } else {
        // source_path = source_path_indexDB?.replaceAll(`${category}`, category);
        source_path = selectedFolder?.source_path?.replaceAll(`${category}`, "");
        if (source_path?.includes("sharedWithMe")) {
          source_path = source_path?.replaceAll(`sharedWithMe`, "");
        }
        if (source_path?.includes("sharedWithMe-")) {
          source_path = source_path?.replaceAll(`sharedWithMe-`, "");
        }
      }
      let destination = "";
      if (selectedFolder?.destination_path?.includes(`${child_category}-`)) {
        // destination = destination_path_indexDB?.replaceAll(`${child_category}-`, `${child_folder_root}`);
        destination = selectedFolder?.destination_path?.replaceAll(`${child_category}-`, ``);
      } else {
        // destination = destination_path_indexDB?.replaceAll(`${child_category}`, child_folder_root);
        destination = selectedFolder?.destination_path?.replaceAll(`${child_category}`, "");
      }
      // console.log("needed: ", { selectedFolder, category, source_path, destination, categoryFrom });
      const source_path_payload = source_path;
      const destination_path_payload = destination;
        // + (content?.internalFileName ? `/${content?.internalFileName}` : ""); // -> requirement is changed again
      const payload: any = {
        workspaceId: workspaceId,
        clapupUserId: clapupUserId,
        fromFolderPath: source_path_payload, // requirement is changed again
        toFolderPath: destination_path_payload, // "destination-path" // -> requirement is changed again
        categoryFrom: categoryFrom,
        categoryTo: child_category === "shared" || child_category === "personal" ? child_category : "main",
        contentId: content?.contentId,
        contentFileType: content?.contentFileType,
        contentFileName: content?.contentFileName,
        internalFileName: content?.internalFileName,
        fromTeamFolderId: "",
        fromTeamFolderName: "",
        toTeamFolderId: "",
        toTeamFolderName: "",
        // changing 2nd time
        // "sourceFilePath      ---->  fromFolderPath": "string",
        // "destinationFilePath ---->  toFolderPath": "string",
        // "fileContentType     ---->  contentFileType": "string",
        // "internalFileName": "string", // newly come
        // "teamFolderId": "string", // newly come
        // "teamFolderName": "string" // newly come
        
        // // changing 3rd time
        // "teamFolderId   ----> toTeamFolderId": "string",
        // "teamFolderName ----> toTeamFolderName": "string"
      };

      if (payload?.categoryFrom === "main" && payload?.categoryTo === "main") {
        payload["fromTeamFolderId"] = team_category?.teamId || "";
        payload["fromTeamFolderName"] = team_category?.internalName || "";
      }

      // selected for moving and coping to team folder
      if (teamFolder?.teamId && teamFolder?.internalName) {
        payload["toTeamFolderId"] = teamFolder?.teamId || "";
        payload["toTeamFolderName"] = teamFolder?.internalName || "";
      }
      // if (payload?.destinationFilePath === payload?.categoryTo) {
      //   payload["destinationFilePath"] = "";
      // }
      // console.log({ teamFolder, payload, category_type });
      // console.log({ child_folder_root, parent_folder_paths, payload, selectedFolder, content });
      // return;
      // moveToIndexDB(source_path_indexDB, destination_path_indexDB, content);
      return { source_path_indexDB, destination_path_indexDB, payload };
      
    } catch (err: any) {
      console.log({ err });
      dispatch(setErrorMessage("Something is wrong"));
    }
  };

  const handleMoveContentToSelectedFolder = async() => {
    try {
      const needed_data: any = await getPayloadAndSourceDestinationPath();
      if (!needed_data) {
        return;
      }
      // console.log({ needed_data });
      // return;
      const { source_path_indexDB, destination_path_indexDB, payload } = needed_data || {};
      const { categoryTo: category } = payload || {};
      const url = api_url + `/api/${api_version}/content/MoveFiles`;
      const { data, status } = await axios.post(url, payload, {
        headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
          }
        });
      if (status === 200) {
        const updated_content_data = data?.length ? data[0] : (data || {});
        console.log({ updated_content_data });
        // remove from redux by contentId and get indexDB value by category & remove 
        moveToIndexDB(source_path_indexDB, destination_path_indexDB, {...content, ...updated_content_data, category});
      }
    } catch (err: any) {
      console.log({ err });
      dispatch(setErrorMessage("Something is wrong"));
    }
  }
  
  const handleCopyContentToSelectedFolder = async() => {
    try {
      const needed_data: any = await getPayloadAndSourceDestinationPath();
      // console.log({ needed_data });
      // return
      if (!needed_data) {
        return;
      }
      const { destination_path_indexDB, payload } = needed_data || {};
      const { categoryTo: category } = payload || {};
      const url = api_url + `/api/${api_version}/content/CopyFiles`;
      const { data, status } = await axios.post(url, payload, {
        headers: {
            'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
            'Content-Type': 'application/json'
          }
        });
      if (status === 200) {
        const updated_content_data = data?.length ? data[0] : (data || {});
        console.log({ updated_content_data });
        // copy into redux by contentId and get indexDB value by category & store new value in indexDB 
        copyingIndexDB(destination_path_indexDB, {...content, ...updated_content_data, category});
      }
    } catch (err: any) {
      console.log({ err });
      dispatch(setErrorMessage("Something is wrong"));
    }
  }

  // managing folder paths
  const getFolderContents = async () => { 
    if (folderPaths?.length) {
      try {
        const workspaceId = logged_in_user_secret_data?.wuid;
        const clapupUserId = logged_in_user_secret_data?.cuid;
        if (!workspaceId || !clapupUserId) {
          return;
        }
  
        const teamFolder = folderPaths?.find((item: any) => item.teamId)
        const folders = folderPaths?.slice(1)?.map((item: any) => item.folderPath);
        const folderPath = folders?.join("/");
        const child_folder_category = folderPaths?.length ? folderPaths[0] : {};
        let child_category = "main";
        if (child_folder_category?.folderPath === "shared") {
          child_category = "shared";
        } else if (child_folder_category?.folderPath === "personal") {
          child_category = "personal";
        }
        const all_child_folders: any = await getKeyValue("all_child_folders");
        // console.log({ child_folder_category, category, child_category, all_child_folders });
        if (all_child_folders?.length) {
          const result = all_child_folders?.find((folder: any) => folder.hasOwnProperty(child_category+folderPath)) || null;
          if (result) {
            const data = result[child_category + folderPath];
            setFolders(data);
            return;
          }
        }
        const get_content_payload: any = {
          workspaceId: workspaceId,
          clapupUserId: clapupUserId,
          teamFolderId: teamFolder?.teamId || "",
          category: child_category,
          folderPath: folderPath,
        };
  
        // console.log({ get_content_payload, folders, folderPaths });
        const url = api_url + `/api/${api_version}/content/GetFolders`;
        const { data, status } = await axios.post(url, get_content_payload, {
            headers: {
                'Authorization': `Bearer ${logged_in_user_secret_data?.access_token}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
          const { folders } = data || {};
          if (!folders?.length) {
            setFolders([]);
            return;
          }
          const new_folder_path = {
            [child_category+folderPath]: folders
          };

          let storable_folders_index_db: any[] = [
            new_folder_path,
          ];
          if (all_child_folders?.length) { 
            storable_folders_index_db = [
              ...all_child_folders,
              new_folder_path
            ];
          }
          // console.log({ all_child_folders, storable_folders_index_db });
          await managingKeyValueData({
            // <isForInserting: false>, this is for testing...
            // when it is<isForInserting: true>, api will never called and take data from indexedDB
            isForInserting: true,
            key: "all_child_folders",
            value: storable_folders_index_db
          });
          setFolders(folders);
        }
        
      } catch(err: any){
        console.log({ err });
      }
    }

  };
  // changing folder routes 
  const handleManageCurrentFolderContent = (data: any) => {
    if (data === "root") {
      setFolderPaths([]);
      if (folderPaths?.length) {
        setSelectedFolder(null);
      }
      setFolders(folder_data);
      return;
    }
    const findCurrentFolderIndex = folderPaths?.findIndex((item: any) => item?.id === data?.id);
    if ((findCurrentFolderIndex + 1) === folderPaths?.length) {
      return;
    }
    const current_folder_path_data = folderPaths?.slice(0, (findCurrentFolderIndex + 1));
    setFolderPaths(current_folder_path_data);

    // const folder_object = {
    //   isForInserting: true,
    //   isNotRefreshing: true,
    //   key: "folder_paths",
    //   value: current_folder_data
    // };
    // // console.log({ findCurrentFolderIndex, current_folder_data, folder_object });
    // dispatch(setFolderPathsToIndexDB(folder_object));
  }

  const handleGetCurrentFolders = (folder: any) => {
    console.log({ folder });
    const { folderName: personal_or_shared_folder_name, contentFileName, folderPath: folder_path, teamId, teamName, internalName: team_folder_path } = folder;
    const folderPath = `${(contentFileName || folder_path || team_folder_path || folder?.internalFileName || folder?.InternalName || "")?.toLowerCase()}`; // 
    const new_folder_path: any = {
      id: folderPath?.replaceAll(" ", "") + "-" + Number(new Date()),
      folderPath: folderPath || contentFileName?.replaceAll(" ", "")?.toLowerCase() || "",
      folderName: contentFileName || personal_or_shared_folder_name || teamName,
      internalName: team_folder_path
    };

    if (teamName) {
      new_folder_path["teamId"] = teamId;
    }
    const current_folder_paths = [
      ...folderPaths,
      new_folder_path
    ];
    setSelectedFolder(null);
    setFolderPaths(current_folder_paths);
  }

  return (
    <React.Fragment>
      <div className="move-copy-folder-form">
        <h3>{isOnlyForCoping ? "" : "Move or "} Copy</h3>

        <div className="form-item">
          <DebounceInput
            type="text"
            value={searchText}
            placeholder={"Search folders to move or copy"}
            minLength={0}
            debounceTimeout={300}
            onChange={(event: any) => {
              const { value } = event.target;
              setSearchText(value);
            }} 
          />
        </div>
    
        <div className="bread-crumb-container">
          <div onClick={() => handleManageCurrentFolderContent("root")} className='folder-title' style={{cursor: "pointer"}}>Folders</div>
          {
            folderPaths?.map((item: any, idx: any) => (
              // idx === 0
              // ? <div key={idx} onClick={() => handleManageCurrentFolderContent(item)} className='folder-title' style={{cursor: "pointer"}}>{item?.folderName}</div>
              //   :
            <div key={idx} className='folder-title'>
              <img style={{cursor: 'auto'}} src={getImage("big-back-arrow")} alt="content-path-icon" />
              <span onClick={() => handleManageCurrentFolderContent(item)} style={{cursor: "pointer"}}>{item?.folderName}</span>
            </div>
          ))
          }
        </div>
        
        <div className="move-copy-folder-table">
            {
              searchFolders?.length || folders?.length
              ? <div className='table-responsive'>
                  <table className="table m-0">
                    <thead className="d-none"><tr><th>folder-Name</th><th></th></tr></thead>
                    <tbody>
                    {(searchFolders?.length ? searchFolders : folders)?.map((item: any, idx: number) => {
                        const item_name_path = (item?.contentFileName || item?.internalName || item?.teamName || item?.folderPath || item?.internalFileName)?.replaceAll(" ", "")?.toLowerCase();
                        const child_folder_category = folderPaths?.length ? folderPaths[0] : {};
                        let child_category = child_folder_category?.internalName || item?.internalFileName || item?.source_path || "";
                        if (child_folder_category?.folderPath === "shared") {
                          child_category = "shared";
                        } else if (child_folder_category?.folderPath === "personal") {
                          child_category = "personal";
                        }
                        const item_middle_paths = folderPaths?.slice(1)?.map((item: any) => item.folderPath)?.join("/");
                        const item_full_paths = (child_category ? `${child_category}` : "") + (item_middle_paths === child_category ? "" : item_middle_paths ? ("-"+item_middle_paths) : "") + (item_middle_paths ? `/${item_name_path}` : item_name_path ? `${item_name_path === child_category ? "" : ("-"+item_name_path)}` : "");
                        return (
                          <tr key={idx}
                            // className={`${team?.teamId === data_fields?.teamId ? "d-none" : ""}`}
                            onClick={() => handleGetCurrentFolders(item)}
                          >
                            <td style={{paddingLeft: 0}}>
                              <img src={getImage("folder-icon")} alt="folder-icon" />
                              <div>{item?.contentFileName || item?.folderName || item?.teamName || "Folder Name"}</div>
                            </td>
                            {/* <td>{user?.email}</td> */}
                            <td style={{paddingRight: 0}}>
                                <img
                                  src={getImage(
                                      selectedFolder?.folderPath === (item?.contentFileName || item?.folderPath || item?.internalName || item?.internalFileName)?.replaceAll(" ", "")?.toLowerCase()
                                      ? "active-radio-button" 
                                      : "inactive-radio-button"
                                      // selectedFolders?.find((team_id: any) => team_id === team?.teamId )
                                      // ? "active-radio-button" 
                                      // : "inactive-radio-button"
                                  )}
                                  alt="check-uncheck-icon"
                                  style={{ 
                                    cursor: item_full_paths === parent_folder_paths_check_index ? "not-allowed" : "pointer",
                                    padding: 0, borderRadius: 0
                                  }} 
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents triggering the row click
                                    // const item_path = (item?.folderPath || item?.contentFileName || item?.internalName || item?.teamName)?.toLowerCase();
                                    // console.log({
                                    //   folderPaths, item_full_paths,
                                    //   // item_name_path,
                                    //   parent_folder_paths
                                    // });
                                    if (item_full_paths === parent_folder_paths_check_index) {
                                      dispatch(setErrorMessage("Same folder can't be used"));
                                      return;
                                    }
                                    const selected_folder = {
                                      contentId: item?.contentId,
                                      folderName: item?.contentFileName || item?.folderName || item?.teamName,
                                      folderPath: item_name_path, // frontend
                                      contentFolderPath: item?.folderPath || item?.internalName,
                                      destination_path: item_full_paths,
                                      source_path: parent_folder_paths,
                                      child_category: child_category,
                                      internalFileName: item?.internalFileName || item?.internalName,
                                      internalName: item?.internalFileName || item?.internalName,
                                      teamId: item?.teamId || "",
                                    };
                                    console.log({ selected_folder, item, folderPaths, item_full_paths, parent_folder_paths_check_index });
                                    setSelectedFolder(selected_folder);
                                  }}
                                />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
            :   <div className="no-data">
                  No Data Found
                </div>
            }
        </div>
        

        <div className="action-buttons">
          <div
            className="text-button"
            onClick={() => handleCancel()}
          >
            Cancel
          </div>
          {
            isOnlyForCoping ? null :
            <div
              className="text-button"
              onClick={() => handleMoveContentToSelectedFolder()}
            >
              Move
            </div>
          }
          <div
            className="text-button"
            onClick={() => handleCopyContentToSelectedFolder()}
          >
            Copy
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MoveOrCopyForm;

