import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
// import { inbox_api_url } from '../../../utils/server';
// import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';
// import { generateAccessToken } from '../../../profile/reducer';
// import { getImage } from '../../../unified/image_service';
import "./GlobalModal.scss";
import { setOpenGlobalDrawer } from '../../ContentComponents/reducer';

const GlobalModal = ({ children, parentCallback, handleCancel }: any) => {
    const dispatch = useDispatch();
    const {
        open_global_modal: showModal,
    }: any = useSelector((state: any) => state?.contentState);
    const [openModal, setOpenModal] = useState<any>(false);
    const [cancelingOn, setCancelingOn] = useState<any>(false);
    useEffect(() => {
        if (showModal) {
            setOpenModal(true);
        } else {
            if (openModal) {
                handleBack();
            }
        }
    }, [showModal]);
    
    const handleBack = async () => {
        setCancelingOn(true);
        // setOpenGlobalDrawer - have to dispatch
        setTimeout(() => { 
            setOpenModal(false);
            setCancelingOn(false);
            handleCancel();
        }, 500);
    }

    // const handleContinue = async () => {
    //     console.log({ data_fields });
    //     // parentCallback();
    // }
    
    return (
        <React.Fragment>
            <div className={` ${cancelingOn ? "closing-time-global-modal" : openModal ? "global-modal-wrap" : "d-none"}`}
                onClick={() => handleBack()}
            >
                <div className={`global-modal-container`}
                    onClick={(e: any) => e.stopPropagation()}
                >
                    {
                        children
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default GlobalModal;
