import { Fragment, useEffect } from "react";
import './SettingsMenu.scss';
// import cross_icon from "../../../assets/img/close-circle.svg";
// import PermissionCheck from "../../PermissionCheck";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import {
  changePage,
  // resetUnifiedInbox, resetWallUnifiedInbox
} from "../../../unified/reducer";
import { settings_menu_routes } from "./menu.config";

const SettingsMenu = (props: any) => {
  const path = window.location.pathname;
  const query = window.location.search;
  // const { isReplyingOn } = useSelector((state: any) => state?.unified);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log({ path });
  }, [path]);

  const handleChangePage = (page?: any) => {
    // console.log({ page });
    let url = page + query;
    url = url.split('&')[0];
    console.log({ url });
    dispatch(changePage(page))
  }

  const menu_data = settings_menu_routes;
  // console.log({menu_data});
  return (
    <Fragment>
      <div className="settings-nav-wrapper">
        <div className="setting-title">Settings</div>
        <ul>
        {menu_data?.map((item: any) => {
            return (
            // <PermissionCheck permissionName={item.permissionName} key={item?.id}>
                <li title={item?.title} className={`li-${item?.id}`} key={item?.id}>
                  <button
                      type="button"
                      className={`button ${(path === item?.url) && "active"}`}
                      onClick={() => path !== item?.url && handleChangePage(item?.url)}
                  >
                          <img src={item?.icon} alt="" />
                          <div className="title">{item?.title}</div>
                  </button>
                </li>
            // </PermissionCheck>
            )
        })}
        </ul>
      </div>
    </Fragment>
  );
}

export default SettingsMenu;