import React, {useRef, useState, useEffect} from 'react';
import prev_icon from '../../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../../assets/icons/back.svg';
import reply_icon from '../../../../../assets/icons/reply-icon.svg';
import edit_icon from '../../../../../assets/icons/edit-icon.svg';
import send_icon from '../../../../../assets/icons/send-icon.svg';
import moment from 'moment';
// import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage, toggleReplyingOn, toggleIsInReplyingMode, likeUnlikeFacebookPost, toggleNewConversationDetails } from '../../../../../unified/reducer';
import { RootState } from '../../../../../store';
import ConversationHeaderSD from '../ConversationHeaderSD';
import ConversationHeader from '../ConversationHeader';
import ShowFiles from './ShowFiles/ShowFiles';
import { getGenerateApiImageByName } from '../../../../../unified/image_service';
import ConversationCommonReply from '../ConversationComponents/ConversationCommonReply';
import ConversationCommonBody from '../ConversationComponents/ConversationCommonBody';
import ConversationCommonHeader from '../ConversationComponents/ConversationCommonHeader';
const FacebookComments = (props: any) => {
    const dispatch = useDispatch();
    const { logged_in_user_secret_data } = useSelector((state: any) => state?.login);
    const [messages, setMessages] = useState<any[]>([{}, {}]);
    const [isCountDownOn, setIsCountDownOn] = useState(false);
    const chatContainerRef = useRef<any>(null);
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };
    
    const query_string = window.location.search;
    const { workspaces } = useSelector((state: any) => state?.headerState);
    const workSpaceName: any = workspaces?.find(
        (w: any) => w?.workSpaceId === logged_in_user_secret_data?.wuid
    )?.workSpaceName || "";

    // const { unifiedData: unified_data }: any = useSelector((state: RootState) => state?.unified);
    // const {
    //     InboxComments_FbCommentsConversation,
    //     // InboxMentions_FbMentionsConversation,
    //     // InboxReviews_FbReviewsConversation,
    //     // InboxReviews_InboxReviews,
    //     // InboxComments_InboxComments,
    //     // InboxMentions_InboxMentions,
    //     // InboxQandA_InboxQandA
    //   }: any = unified_data || {};
    const { conversationDetailsData, isNewConversationDetails, isReplyingOn, isInReplyingMode }: any = useSelector((state: RootState) => state?.unified);
    const {
        post: post_item,
        comments: commentData
    }: any = conversationDetailsData || {};
    const post: any = post_item?.length ? post_item[0] : (post_item || null);
    const { sender, conversation, handleReplaceConversation, workspaceSettings, getImage, handlePrevPath, setReplyText, isReply, setIsReply, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange } = props;
    // const post: any = (InboxComments_FbCommentsConversation || [])?.find((item: any) => item?.postId === conversation?.PostId) || {};
    // const { properties, details, from, tag} = conversation || {};
    const { reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, recieverName, mentionedbyUserName, mentionedbyUserImageUrl, reviewtype, createdOn, reviewReplyComment, reviewReplyUpdatedon, reviewComment, provider, starRating, ratingStars, likeCount: likes, retweetCount, locationName, pagename, recieverCompany, mentionedbyUserEmailId, reviewComment2 } = conversation || {};
    // console.log({post_item});
    const [replyText, setCurrentReplyText] = useState<any>("");
    const [likeCount, setLikeCount] = useState({ isLike: false, likes: likes || 0 });
    const [comments, setComments] = useState(commentData?.length ? commentData : []);
    const [currentRecord, setCurrentRecord] = useState<any>({});
    useEffect(() => {
        setComments([]);
        setCurrentReplyText("");
        setReplyText("");
    }, [query_string]);

    useEffect(() => {
        // Scroll to bottom on initial render and when messages change
        scrollToBottom();
    }, [comments?.length]);

    useEffect(() => {
        if (isNewConversationDetails) {
            // console.log({ commentData, conversation, post });
            setComments(commentData?.length ? commentData : []);
            dispatch(toggleNewConversationDetails(false));
        }
    }, [isNewConversationDetails]);

    const handleReplyRecord = (replyText: string, currentRecord: any) => {
        if (!replyText?.trim()) {
            return;
        }
        const commentRecord = {
            // commentId: currentRecord?.commentId,
            commentText: replyText,
            // commentedBy: conversation?.pagename,
            commentedBy: workspaceSettings?.workspaceName,
            // commentedUserImageURL: workspaceSettings?.logo,
            commentedOn: new Date().toISOString().replaceAll("Z", ""),
            isLike: false,
            likeCount: 0,
        };
        if (replyText?.trim()) {
            handleReply(replyText, currentRecord);
            const newComments = [commentRecord, ...comments];
            setCurrentReplyText("");
            // console.log({ newComments, workspaceSettings });
            setComments(newComments);
            // Scroll to bottom after sending a message
            setTimeout(() => {
                scrollToBottom();
            }, 100); // Timeout to ensure the message is rendered before scrolling
        }
    };

    const handleMainLikeClick = (likeObj: any) => {
        console.log({ likeObj });
        const { reviewId, reviewPageId, tag, companyId } = conversation;
        const { postId } = post;
        const likePayload = {
            // commentId: reviewId,
            commentId: postId,
            pageId: reviewPageId || companyId || null,
            isLiked: likeObj?.isLike,
            pageModule: tag,
        }
        console.log({ likePayload, conversation });
        setLikeCount(likeObj);
        dispatch(likeUnlikeFacebookPost(likePayload));
    };

    const handleLike = (curData: any, isLike?: any) => {
        // console.log({curData});
        
        const { reviewPageId, tag, companyId } = conversation;
        const { likeCount, commentId } = curData;
        const likeFlag = isLike ? false : true;
        const likeObj = {
            ...curData,
            likeCount: isLike ? (likeCount - 1) : (likeCount + 1),
            isLike: likeFlag,
        };
        const likePayload = {
            commentId: commentId || null,
            pageId: reviewPageId || companyId || null,
            isLiked: likeFlag,
            pageModule: tag,
        }
        console.log({ likePayload, conversation });
        dispatch(likeUnlikeFacebookPost(likePayload));
        const index = comments.findIndex((item: any) => item?.commentId === curData?.commentId);
        const newComments = [...comments];
        newComments[index] = likeObj;
        setComments(newComments);
    };

    const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: Parameters<F>) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     setCurrentReplyText(event.target.value);
    // };
    const handleTextChange = (value = "") => {
        setReplyText(value);
    };

    const debouncedTextChange = debounce(handleTextChange, 500);
    const [isForReplyingUser, _] = useState<any>()
    // Automatically scroll to the bottom of the conversation when a new message is added
    const scrollRef = useRef<any>(null);
    const [isOverflowing, setIsOverflowing] = useState<any>(false);

    // Check if the content is overflowing
    useEffect(() => {
        console.log({ client_height: scrollRef?.current?.clientHeight, scrollHeight: scrollRef.current.scrollHeight });
        if (scrollRef.current) {
        const isContentOverflowing = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
        setIsOverflowing(isContentOverflowing);
        
        // Scroll to the bottom only if content is overflowing
        if (isContentOverflowing) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        }
    }, [messages?.length, scrollRef?.current?.clientHeight]);
    return (
        <React.Fragment>
            <ConversationCommonHeader
                data_fields={{
                    username_1: conversation?.reviewerDisplayName || "User Name",
                    username_2: workspaceSettings?.workspaceName,
                    user_profile_1: null, // conversation?.reviewerPhoteUrl, 
                    user_profile_2: workspaceSettings?.logo,
                    // star_ratings, 
                    tag_text: ` commented to a post in ${workspaceSettings?.workspaceName}`,  // optional props fields
                    action_buttons: null,
                    // action_buttons: [{ text: "Close", },{ text: "Share",},{text: "Move"}], // optional props fields
                    provider: conversation?.provider || "",
                }}
                handleParentCallback={(data: any) => console.log({ data })}
            />

            <div className="conversation-data">
                <div
                    className="conversation-container"
                    ref={scrollRef}
                    style={{justifyContent: isOverflowing ||  messages?.length > 0 ? "flex-start" : "flex-end"}}
                >
                {
                    comments.map((item: any, idx: any) => (
                        // idx === 0
                        // ? 
                        <ConversationCommonBody
                            key={idx}
                            data_fields={{
                                username: item?.commentedBy || conversation?.reviewerDisplayName,
                                user_profile: null, // conversation?.reviewerPhoteUrl,
                                tag_text: `Commented to a post in ${workspaceSettings?.workspaceName}`,  // optional props fields
                                star_ratings: null, // optional props fields
                                date: item?.createdOn || new Date(),
                                message_subject: null, // optional props fields
                                show_fb_likes: true, //commentedBy
                                fb_likes: item?.likeCount || 100,
                                message: item?.commentText || conversation?.reviewComment,
                            }}
                            handleParentCallback={(data: any) => console.log({ data })}
                            isReply={true}
                            handleReply={(data: any) => console.log({data})}//handleReply
                        />  
                        // :   
                        // <ConversationCommonBody
                        //     key={idx}
                        //     data_fields={{
                        //         // username: workspaceSettings?.workspaceName || "WS Name",
                        //         username: conversation?.tag?.includes("googleQandA") ? "You Answered" : "You Replied",
                        //         user_profile: workspaceSettings?.logo || getGenerateApiImageByName(workspaceSettings?.workspaceName),
                        //         star_ratings: 5, // optional props fields
                        //         inVisibleRatingNumber: true, // optional props fields
                        //         tag_text: null,  // optional props fields
                        //         date: item?.createdOn || new Date(),
                        //         message_subject: null, // optional props fields
                        //         message: item?.reviewComment,
                        //     }}
                        //     handleParentCallback={(data: any) => console.log({ data })}
                        //     isReply={false}
                        // />
                        
                    ))
                }

                {
                    post?.message
                    ?   <>
                            <div className='fb-conversation-title'>Comments</div>
                            <ConversationCommonBody
                                data_fields={{
                                    username: post?.userName || workspaceSettings?.workspaceName || "WS Name",
                                    user_profile: workspaceSettings?.logo, // conversation?.reviewerPhoteUrl,
                                    tag_text: `Shared a Post`,  // optional props fields
                                    star_ratings: null, // optional props fields
                                    date: post?.postCreatedDate || new Date(),
                                    message_subject: null, // optional props fields
                                    message: post?.message || "@Tezla Inc As the brand, so is the service. They are extremely well verse with in quality.?",
                                    show_fb_comments: true,
                                    fb_comments: comments?.length || 100,
                                    show_fb_likes: true, //commentedBy
                                    fb_likes: likeCount?.likes || 100
                                }}
                                handleParentCallback={(data: any) => console.log({ data })}
                                isReply={false}

                                handleReply={(data: any) => console.log({data})}//handleReply
                            />
                        </>
                    : null
                }
                </div>
            </div>

            <ConversationCommonReply
                data_fields={null}
                handleParentCallback={(data: any) => console.log({data})}
                isReply={isReply}
                isCountDownOn={isCountDownOn}
                setReplyText={setReplyText}
                isForReplyingUser={true}
                textRef={textRef}
                sender={sender}
                showingLeftContent={false}
                showFileInputIcon={false} 
                showEmojiInputIcon={false}
                showLockIcon={true}
            />
            {/* <div className="conversation-section-container-lg h-100">
                <ConversationHeader
                    header_title={" commented "}
                    workSpaceName={workSpaceName}
                    workspaceSettings={workspaceSettings}
                    conversation={conversation}
                    handleReplaceConversation={handleReplaceConversation}
                    prev_icon={prev_icon}
                    next_icon={next_icon}
                    refresh_icon={refresh_icon}
                    user_avatar_2={user_avatar_2}
                    user_avatar={user_avatar}
                />
                <div className="conversation-section">
                    <Scrollbars
                        ref={chatContainerRef}
                        style={{
                            paddingRight: 10,
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                        // style={{ paddingRight: 10 }}
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={500}
                        autoHeight
                        // autoHeightMin={250}
                        // autoHeightMax={250}
                        // autoHeightMin={480}
                        autoHeightMax={"100%"}
                        thumbMinSize={10}
                        universal={true}
                    >
                        <div className="post">
                            {
                                post?.message
                                    ? <div className='post-container' style={{ margin: '10px 0' }}>
                                        <div className="post-header">
                                            {
                                                post?.userImageUrl
                                                    ? <img
                                                        className="user-avatar"
                                                        style={{
                                                            borderRadius: '50%',
                                                            height: 22,
                                                            width: "auto"
                                                        }}
                                                        src={post?.userImageUrl || user_avatar}
                                                        alt="avatar"
                                                    />
                                                    :
                                                    <div className='user-avatar'
                                                        style={{
                                                            fontSize: "0.75rem",
                                                            background: '#14B0BF',
                                                            height: 22,
                                                            width: 26
                                                        }}
                                                    >
                                                        {
                                                            post?.userName?.split(" ")?.slice(0, 2)?.length === 2
                                                                ? post?.userName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                                : post?.userName?.substr(0, 2).toUpperCase()
                                                        }
                                                    </div>
                                            }
                                            <div className='content'>
                                                <h2 className='user-name'>{post?.userName}</h2>
                                                <p>
                                                    {
                                                        moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                                        // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            {
                                                post?.attachment
                                                    ? <ShowFiles data={post?.attachment} />
                                                    : null
                                            }
                                            
                                            <p>
                                                {post?.message}
                                            </p>
                                        </div>
                                        <div className="post-footer">
                                            <div className="post-footer-left w-50">
                                                <div className="like-edit-reply">
                                                    <div className='image-likes'>
                                                        <img
                                                            className="image-like"
                                                            src={getImage("like")}
                                                            alt="like"
                                                            onClick={() => {
                                                                handleMainLikeClick({
                                                                    isLike: likeCount?.isLike ? false : true,
                                                                    likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                                                });
                                                            }}
                                                        />
                                                        <div
                                                            className="likes"
                                                            style={{ color: "inherit" }}
                                                        >
                                                            {
                                                                (likeCount?.likes === 0 || likeCount?.likes === 1)
                                                                    ? likeCount?.likes + " Like"
                                                                    : likeCount?.likes + " Likes"
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-footer-right w-50">
                                                {
                                                    comments?.length > 0
                                                        ? comments?.length === 1
                                                            ? <p className='text-end'>1 Comment</p>
                                                            : <p className='text-end'>{comments?.length} Comments</p>
                                                        : <p className='text-end'>0 Comment</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <p>Comment</p>
                        </div>
                        <div className="conversation-body" id="scrollableDiv">
                            
                            {
                                comments?.map((comment: any, idx: number) => (
                                    workspaceSettings?.workspaceName === comment?.commentedBy
                                        ? <div className="conversation-person-2">
                                            <div className="details">
                                                {
                                                    workspaceSettings?.logo
                                                        ? <img
                                                            className="user-avatar"
                                                            src={workspaceSettings?.logo || user_avatar_2}
                                                            alt="user_avatar"
                                                        />
                                                        : <div className='user-avatar'>
                                                            {
                                                                comment?.commentedBy?.split(" ")?.slice(0, 2)?.length === 2
                                                                    ? comment?.commentedBy?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                                    : comment?.commentedBy?.substr(0, 2).toUpperCase()
                                                            }
                                                        </div>
                                                }
                                                <div className="description">
                                                    <p>{comment?.commentText}</p>
                                                </div>
                                            </div>
                                            <p className="text-end mt-2 date">
                                                {"Replied"} - {" "}
                                                <span>
                                                    {
                                                        moment(comment?.commentedOn).format("DD MMM YYYY, hh:mm:ss A") +
                                                        " by " +
                                                        (comment?.commentedBy || workspaceSettings?.workspaceName || "WS Name")
                                                        // moment(details?.requested_time).format('Y-m-d')
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                        : <div key={idx} className="conversation-person-1">
                                            <div className="details">
                                                {
                                                    reviewerPhoteUrl
                                                        ? <img
                                                            className="user-avatar"
                                                            src={comment?.commentedUserImageURL || user_avatar}
                                                            alt="avatar"
                                                        />
                                                        : <div className='user-avatar'>
                                                            {
                                                                comment?.commentedBy?.split(" ")?.slice(0, 2)?.length === 2
                                                                    ? comment?.commentedBy?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                                    : comment?.commentedBy?.substr(0, 2).toUpperCase()
                                                            }
                                                        </div>
                                                }
                                                <span className="description">
                                                    <div className={`align-items-center user-ratings-container`}>
                                                        <span className="d-block user_name me-2 ">
                                                            {comment?.commentedBy || "User Name"}
                                                        </span>
                                                    </div>
                                                    {
                                                        comment?.attachment
                                                            ? <ShowFiles data={comment?.attachment} />
                                                            : null
                                                    }
                                                    <p>{comment?.commentText}</p>
                                                    <div className="like-edit-reply">
                                                        <div className='image-likes'>
                                                            <img
                                                                className={`image-like ${!comment?.commentId ? "disable-hover" : ""}`}
                                                                src={getImage("like")}
                                                                alt="like"
                                                                onClick={() => {
                                                                    if (!comment?.commentId) {
                                                                        return;
                                                                    } else {
                                                                        handleLike(comment, comment?.isLike);
                                                                    }
                                                                }}
                                                            />
                                                            <div
                                                                className="likes"
                                                                style={{ color: "inherit" }}
                                                            >
                                                                {(comment?.likeCount === 0 || comment?.likeCount === 1)
                                                                    ? comment?.likeCount + " Like"
                                                                    : comment?.likeCount + " Likes"}
                                                            </div>
                                                        </div>
                                                        <div className={`reply-edit ${!comment?.commentId ? "disable-hover" : ""}`}
                                                            onClick={() => {
                                                                if (!comment?.commentId) {
                                                                    return;
                                                                } else {
                                                                    setCurrentRecord(comment);
                                                                    setIsReply(true);
                                                                    dispatch(toggleReplyingOn(true));
                                                                    dispatch(toggleIsInReplyingMode(true));
                                                                }
                                                            }}
                                                        >Reply</div>
                                                    </div>
                                                </span>
                                            </div>
                                            <p className="text-start mt-2 date">
                                                Comment -  {" "}
                                                <span>
                                                    {
                                                        // new Date(createdOn).toDateString() +
                                                        moment(comment?.commentedOn).format("DD MMM YYYY, hh:mm:ss A")
                                                        // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                                                        // moment(details?.requested_time).format('Y-m-d')
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                ))
                            }
                        </div>
                    </Scrollbars>
                </div>
                <div className="conversation-footer">
                    <p className="text-start my-2 px-3 sender">
                        {
                            sender
                                ? `${sender} is typing...`
                                : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
                        }
                    </p>
                    <div className="message-box">
                        <div className="message">
                            <div className="text-container">
                                <textarea
                                    ref={textRef}
                                    className=""
                                    value={replyText || ""}
                                    placeholder="Write here..."
                                    // onChange={debouncedTextChange}
                                    onChange={(event: any) => {
                                        setCurrentReplyText(event.target.value);
                                        debouncedTextChange(event.target.value);
                                    }}
                                    style={{ resize: "none" }}
                                    autoFocus={isReply}
                                />
                            </div>
                            <div className="reply-btn">
                                <button
                                    style={{
                                        background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                                    }}
                                    onClick={() => {
                                        if (isReply) {
                                            handleReplyRecord(replyText, currentRecord);
                                        } else {
                                            !currentRecord && setCurrentRecord(conversation);
                                            setIsReply(true);
                                            dispatch(toggleReplyingOn(true));
                                            dispatch(toggleIsInReplyingMode(true));
                                        }
                                    }}
                                    type="button"
                                    disabled={sender ? true : false}
                                >
                                    {
                                        // isReply && replyText
                                        // ? "Save" : 
                                        isReply
                                            ? "Send"
                                            : replyText
                                                ? "Edit"
                                                : "Reply"}
                                </button>
                                <img
                                    onClick={() => {
                                        setIsReply(false);
                                        dispatch(toggleReplyingOn(false));
                                        dispatch(toggleIsInReplyingMode(false));
                                    }}
                                    src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                                    alt="lock-unlock"
                                />
                            </div>
                            <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}>
                                {
                                    sender
                                        ? "You can not reply now. Editor is locked"
                                        : "Click reply and start adding your reply."
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="conversation-container-mobile">
                <div className="conversation-header">
                    <div className="header-top">
                        <img
                            onClick={handlePrevPath}
                            className="back"
                            src={back_btn}
                            alt="back"
                        />
                        <img
                            className="provider"
                            src={getImage(provider)}
                            alt="provider"
                        />
                    </div>
                    <ConversationHeaderSD
                        header_title={" commented "}
                        workSpaceName={workSpaceName}
                        workspaceSettings={workspaceSettings}
                        conversation={conversation}
                        handleReplaceConversation={handleReplaceConversation}
                        prev_icon={prev_icon}
                        next_icon={next_icon}
                        refresh_icon={refresh_icon}
                        user_avatar_2={user_avatar_2}
                        user_avatar={user_avatar}
                    />
                    <div className="header-bottom">
                        <span
                            onClick={() => isGoForDetails && handleDetailChange()}
                            style={{ color: !isGoForDetails ? "#1570EF" : "" }}
                        >
                            Messages
                        </span>
                        <span
                            onClick={() => !isGoForDetails && handleDetailChange()}
                            style={{ color: isGoForDetails ? "#1570EF" : "" }}
                        >
                            Details
                        </span>
                    </div>
                </div>
                {isGoForDetails ? (
                    <div className="conversation-details-mobile-container">
                        <ConversationDetails conversationId={conversationId} />
                    </div>
                ) : (
                    <>
                        <Scrollbars
                            style={{
                                paddingRight: 10,
                                display: "flex",
                                flexDirection: "column-reverse",
                                overflow: 'auto'
                            }}
                            // style={{ paddingRight: 10 }}
                            autoHide
                            autoHideTimeout={500}
                            autoHideDuration={500}
                            autoHeight
                            // autoHeightMax={250}
                            // autoHeightMin={480}
                            autoHeightMin={"60vh"}
                            autoHeightMax={"60vh"}
                            thumbMinSize={10}
                            universal={true}
                        >
                            <div className="post">
                                {
                                    post?.message
                                        ? <div className='post-container mb-3' style={{ width: '90%' }}>
                                            <div className="post-header">
                                                {
                                                    post?.userImageUrl
                                                        ? <img
                                                            className="user-avatar"
                                                            src={post?.userImageUrl || user_avatar}
                                                            alt="avatar"
                                                        />
                                                        : <img
                                                            className="user-avatar"
                                                            src={getGenerateApiImageByName(post?.userName)}
                                                            alt="avatar"
                                                        />
                                                }
                                                <div className='content'>
                                                    <h2 className='user-name'>{post?.userName}</h2>
                                                    <p>
                                                        {
                                                            moment(post?.postCreatedDate).format("DD MMM YYYY, hh:mm:ss A")
                                                            // moment(createdOn).format("MMMM Do YYYY, hh:mm:ss A")
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="post-body">
                                                {
                                                    post?.attachment
                                                        ? <ShowFiles data={post?.attachment} />
                                                        : null
                                                }
                                                <p>
                                                    {post?.message}
                                                </p>
                                            </div>
                                            <div className="post-footer">
                                                <div className="post-footer-left w-50">
                                                    <div className="like-edit-reply">
                                                        <div className='image-likes'>
                                                            <img
                                                                className="image-like"
                                                                src={getImage("like")}
                                                                alt="like"
                                                                onClick={() => {
                                                                    handleMainLikeClick({
                                                                        isLike: likeCount?.isLike ? false : true,
                                                                        likes: likeCount?.isLike ? (likeCount?.likes - 1) : (likeCount?.likes + 1)
                                                                    });
                                                                }}
                                                            />
                                                            <div
                                                                className="likes"
                                                                style={{ color: "inherit" }}
                                                            >
                                                                {
                                                                    (likeCount?.likes === 0 || likeCount?.likes === 1)
                                                                        ? likeCount?.likes + " Like"
                                                                        : likeCount?.likes + " Likes"
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="post-footer-right w-50">
                                                    {
                                                        comments?.length > 0
                                                            ? comments?.length === 1
                                                                ? <p className='text-end'>1 Comment</p>
                                                                : <p className='text-end'>{comments?.length} Comments</p>
                                                            : <p className='text-end'>0 Comment</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                <p>Comment</p>
                            </div>
                            <div className="conversation-body" id="scrollableDiv">
                                {
                                    comments?.map((comment: any, idx: number) => (
                                        workspaceSettings?.workspaceName === comment?.commentedBy
                                            ? <div className="conversation-person-2">
                                                <div className="details">
                                                
                                                    {
                                                        workspaceSettings?.logo
                                                            ? <img
                                                                className="user-avatar"
                                                                src={workspaceSettings?.logo || user_avatar_2}
                                                                alt="user_avatar"
                                                            />
                                                            : <div className='user-avatar'>
                                                                {
                                                                    comment?.commentedBy?.split(" ")?.slice(0, 2)?.length === 2
                                                                        ? comment?.commentedBy?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                                        : comment?.commentedBy?.substr(0, 2).toUpperCase()
                                                                }
                                                            </div>
                                                    }
                                                    <div className="description">
                                                        <p>{comment?.commentText}</p>
                                                    </div>
                                                </div>
                                                <p className="text-end mt-2 date">
                                                    {"Replied"} - {" "}
                                                    <span>
                                                        {
                                                            moment(comment?.commentedOn).format("DD MMM YYYY, hh:mm:ss A") +
                                                            " by " +
                                                            (comment?.commentedBy || workspaceSettings?.workspaceName || "WS Name")
                                                            // moment(details?.requested_time).format('Y-m-d')
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            : <div key={idx} className="conversation-person-1">
                                                <div className="details">
                                                    {
                                                        reviewerPhoteUrl
                                                            ? <img
                                                                className="user-avatar"
                                                                src={comment?.commentedUserImageURL || user_avatar}
                                                                alt="avatar"
                                                            />
                                                            : <div className='user-avatar'>
                                                                {
                                                                    comment?.commentedBy?.split(" ")?.slice(0, 2)?.length === 2
                                                                        ? comment?.commentedBy?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                                                                        : comment?.commentedBy?.substr(0, 2).toUpperCase()
                                                                }
                                                            </div>
                                                    }
                                                    <span className="description">
                                                        <div className={`align-items-center user-ratings-container`}>
                                                            <span className="d-block user_name me-2 ">
                                                                {comment?.commentedBy || "User Name"}
                                                            </span>
                                                        </div>
                                                        {
                                                            comment?.attachment
                                                                ? <ShowFiles data={comment?.attachment} />
                                                                : null
                                                        }
                                                        <p>{comment?.commentText}</p>
                                                        <div className="like-edit-reply">
                                                            <div className='image-likes'>
                                                                <img
                                                                    className={`image-like ${!comment?.commentId ? "disable-hover" : ""}`}
                                                                    src={getImage("like")}
                                                                    alt="like"
                                                                    onClick={() => {
                                                                        if (!comment?.commentId) {
                                                                            return;
                                                                        } else {
                                                                            handleLike(comment, comment?.isLike);
                                                                        }
                                                                    }}
                                                                />
                                                                <div
                                                                    className="likes"
                                                                    style={{ color: "inherit" }}
                                                                >
                                                                    {(comment?.likeCount === 0 || comment?.likeCount === 1)
                                                                        ? comment?.likeCount + " Like"
                                                                        : comment?.likeCount + " Likes"}
                                                                </div>
                                                            </div>
                                                            <div className={`reply-edit ${!comment?.commentId ? "disable-hover" : ""}`}
                                                                onClick={() => {
                                                                    if (!comment?.commentId) {
                                                                        return;
                                                                    } else {
                                                                        setCurrentRecord(comment);
                                                                        setIsReply(true);
                                                                        dispatch(toggleReplyingOn(true));
                                                                        dispatch(toggleIsInReplyingMode(true));
                                                                    }
                                                                }}
                                                            >Reply</div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <p className="text-start mt-2 date">
                                                    Comment -  {" "}
                                                    <span>
                                                        {
                                                            // new Date(createdOn).toDateString() +
                                                            moment(comment?.commentedOn).format("DD MMM YYYY, hh:mm:ss A")
                                                            // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                                                            // moment(details?.requested_time).format('Y-m-d')
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                    
                                    ))
                                }
                            </div>
                        </Scrollbars>
                    
                        <div className="conversation-footer">
                            <div
                                style={{ padding: "0 10px" }}
                                className="d-flex align-items-center justify-content-between my-2"
                            >
                                <p className="text-start sender">
                                    {
                                        sender
                                            ? `${sender} is typing...`
                                            : `Sending as ${workspaceSettings?.workspaceName || "WS Name"}`
                                    }
                                </p>
                                <img
                                    onClick={() => {
                                        setIsReply(false);
                                        dispatch(toggleReplyingOn(false));
                                        dispatch(toggleIsInReplyingMode(false));
                                    }}
                                    src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                                    alt="lock-unlock"
                                />
                            </div>
                            <div className="message-box">
                                <div className="message">
                                    <div className="text-container">
                                        <textarea
                                            ref={textRef}
                                            className=""
                                            value={replyText || ""}
                                            placeholder="Write here..."
                                            // onChange={debouncedTextChange}
                                            onChange={(event: any) => {
                                                setCurrentReplyText(event.target.value);
                                                debouncedTextChange(event.target.value);
                                            }}
                                            style={{ resize: "none" }}
                                            autoFocus={isReply}
                                        />
                                    </div>
                                    <div className="send-reply-action">
                                        <div
                                            onClick={() => {
                                                if (!sender) {
                                                    if (isReply) {
                                                        handleReplyRecord(replyText, currentRecord);
                                                    } else {
                                                        !currentRecord && setCurrentRecord(conversation);
                                                        setIsReply(true);
                                                        dispatch(toggleReplyingOn(true));
                                                        dispatch(toggleIsInReplyingMode(true));
                                                    }
                                                }
                                            }}
                                        // type="button"
                                        >
                                            {isReply ? (
                                                <img src={send_icon} alt="save-account" />
                                            ) : replyText ? (
                                                <img src={edit_icon} alt="edit" />
                                            ) : (
                                                <img src={reply_icon} alt="reply" />
                                            )}
                                        </div>
                                    </div>
                                    <p
                                        className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}
                                    >
                                        {
                                            sender
                                                ? "You can not reply now. Editor is locked"
                                                : "Click reply and start adding your reply."
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div> */}
        </React.Fragment>
    )
};
export default FacebookComments;

