import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function PermissionCheck({ permissionName, children, isRedirect }: any) {
    // const history = useNavigate();
    const { userPermissions } = useSelector((state: any) => state?.login);
    // console.log({userPermissions, permissionName, isRedirect });
    
    const permission = userPermissions?.length ? userPermissions?.find(
        (item: any) => item?.name === permissionName
    ) : null;

    return permission?.value === 1 ? children : null;
}