import { combineReducers } from "redux";
import loginReducer from '../../signup-login/loginReducer'
import profileReducer from "../../profile/reducer";
// mmhk-region
import headerReducer from "../../components/Layout/header/headerReducer";
import unifiedReducer from "../../unified/reducer";
import profileManageSettingReducer from "../../pages/Settings/UserProfile/userProfileManageReducer";
import workspaceManagementSettingReducer from "../../pages/Settings/WorkspaceSettings/workspaceReducer";
import bioPageManagementReducer from "../../pages/Settings/BioPageSettings/bioPageReducer";
import userManagementReducer from "../../pages/Settings/UserManagementSettings/userManagementReducer";
import contentManagementReducer from "../../components/ContentComponents/reducer";
import promotionsReducer from "../../components/Promotions/reducer";
import teamsReducer from "../../pages/Settings/Teams/teamsReducer";
import integrationReducer from "../../pages/Settings/Integrations/reducer";
import agentReducer from "../../pages/Settings/AgentSettings/agentReducer";

const rootReducer = combineReducers({
    login: loginReducer,
    unified: unifiedReducer,
    profile: profileReducer,
    // mmhk-region
    headerState: headerReducer,
    profileManageSettingState: profileManageSettingReducer,
    userManagementState: userManagementReducer,
    workspaceManagementState: workspaceManagementSettingReducer,
    bioPageSettingState: bioPageManagementReducer,
    integrationState: integrationReducer,
    agentState: agentReducer,
    teamState: teamsReducer,
    contentState: contentManagementReducer,
    promotionsState: promotionsReducer,
});

export default rootReducer;