import { content_menu_routes } from "../Layout/global-menu/menu.config";

const common_folders = {
    title: "Common Folders",
    tabs: content_menu_routes,
};

const content_top_dropdown_list = [
    {
        id: 1,
        title: "Create Folder",
        icon_title: 'circle-plus',
    },
    {
        id: 2,
        title: "Upload Folder",
        icon_title: 'circle-top-arrow',
    },
    {
        id: 3,
        title: "Upload file",
        icon_title: 'circle-top-arrow',
    },
];

export { common_folders, content_top_dropdown_list };
