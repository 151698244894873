import React from 'react';

const FeedTabs = ({ activePage, handlePageChange }: any) => {
    const query_string = window.location.search;
    const tabs = [
        {
            name: "Q & A",
            query: "qa",
        },
        {
            name: "Mentions",
            query: "mentions",
        },
        {
            name: "Comments",
            query: "comments",
        },
        {
            name: "Reviews",
            query: "reviews",
        },
        {
            name: "All",
            query: "all",
        }
    ];
    return (
        <React.Fragment>
            <div className="unified-btn d-flex justify-content-between align-items-center">
                {
                    tabs.map((tab, index) => (
                        <button
                            key={index}
                            className={`${query_string?.includes(tab.query) ? "active" : ""}`}
                            onClick={() =>
                                !query_string?.includes(tab.query) &&
                                handlePageChange(`${tab.query}=${activePage}`)
                            }
                            type="button"
                        >
                            {tab.name}
                        </button>
                    ))
                }

                {/* <button
                  className={`${query_string?.includes("qa") ? "active" : ""}`}
                  onClick={() =>
                    !query_string?.includes("qa") && handlePageChange(`qa=${activePage}`)
                  }
                  type="button"
                >
                  Q {"&"} A
                </button>
                <button
                  className={`${query_string?.includes("mentions") ? "active" : ""}`}
                  onClick={() =>
                    !query_string?.includes("mentions") &&
                    handlePageChange(`mentions=${activePage}`)
                  }
                  type="button"
                >
                  Mentions
                </button>
                <button
                  className={`${query_string?.includes("comments") ? "active" : ""}`}
                  onClick={() =>
                    !query_string?.includes("comments") &&
                    handlePageChange(`comments=${activePage}`)
                  }
                  type="button"
                >
                  Comments
                </button>
                <button
                  className={`${query_string?.includes("reviews") ? "active" : ""}`}
                  onClick={() =>
                    !query_string?.includes("reviews") &&
                    handlePageChange(`reviews=${activePage}`)
                  }
                  type="button"
                >
                  Reviews
                </button>
                <button
                  className={`${query_string?.includes("all") ? "active" : ""}`}
                  onClick={() =>
                    !query_string?.includes("all") && handlePageChange(`all=${activePage}`)
                  }
                  type="button"
                >
                  All
                </button> */}
            </div>
        </React.Fragment>
    );
};

export default FeedTabs;