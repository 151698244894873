import React, { useState } from 'react';

const ReviewHeader = ({ tabs, handleChangeTab, value }: any) => {
    const [tab_value, setTabValue] = useState<any>(tabs?.length ? tabs[0] : "");
    return (
    <React.Fragment>
        <div className='details-tab-header'>
            {
                tabs?.map((tab: any) => (
                    <div
                        key={tab} 
                        className={`tab ${ (tab_value === tab || tab === value) ? "tab-active" : ""}`}
                        onClick={() => {
                            handleChangeTab(tab);
                            setTabValue(tab);
                        }}
                    >
                        {tab}
                    </div>
                ))
            }
            
        </div>
    </React.Fragment>
  )
}

export default ReviewHeader;
