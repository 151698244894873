import React from 'react';
import './ReviewSummary.scss';
import { getImage, getGenerateApiImageByName } from '../../../../../unified/image_service';
import moment from 'moment';
import { convertISODate } from '../../../../../utils/convertingFunction';

const SummaryList = (props: any) => {
    const { list_data }: any = props || {};
    const { provider, type, to, tag_label, createdOn, repliedBy, repliedOn }: any = list_data || {};

    function getDateDifference(date1: any, date2: any) {
        if(!date1 || !date2) return " --- ";
        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
        console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
        
        // Calculate the number of days
        let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Calculate the remaining hours
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        // Calculate the remaining minutes
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        let res = "";
        let years: any = 0;
        if (days > 365) {
            years = Math.floor(days / 365);
            res += years + " Years, ";
            days -= years * 365;
            res += days + " Days ";
            days = 0;
        }
        if (days > 0) {
            res += days + " Days, ";
        }
        if (!years) {
            res += hours + " Hours, " + minutes + " Minutes";
        }
        return res;
    }
    return (
        <React.Fragment>
            <div className="review-table">
                <div className="table-row">
                    <div className="label">Type:</div>
                    <div className="value">
                        <div className="value-details">
                            <img src={getImage(provider)} alt="provider-icon" />
                            <div>
                                {type}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">To:</div>
                    <div className="value">
                        <div className="value-details">
                            {to || "Workspace Name"}
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">
                        {tag_label}:
                    </div>
                    <div className="value">
                        <div className="value-details">
                            {
                                // "QuestionCreatedOn" in (conversation || {})
                                // ? moment(convertISODate(conversation?.QuestionCreatedOn)).utc().format("ll") + " " + moment(conversation?.QuestionCreatedOn).format("LT")
                                // : 
                                createdOn
                                ? moment(convertISODate(createdOn)).utc().format("ll") + " " + moment(convertISODate(createdOn)).utc().format("LT")
                                : // new Date(MentionedOn).toDateString() + " " + // new Date(MentionedOn).toLocaleTimeString()
                                " --- "
                            }
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Replied on:</div>
                    <div className="value">
                        <div className="value-details">
                            {
                                repliedOn
                                ? moment(convertISODate(repliedOn)).utc().format("ll") + " " + moment(convertISODate(repliedOn)).utc().format("LT")
                                : " --- "
                            }
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Replied by:</div>
                    <div className="value">
                        <div className="value-details">
                            <img src={getGenerateApiImageByName(repliedBy)} alt="reviewer-name" />
                            <div>{repliedBy}</div>
                        </div>
                    </div>
                </div>
                <div className="table-row">
                    <div className="label">Resp. Time:</div>
                    <div className="value">
                        <div className="value-details">
                            {getDateDifference(createdOn, repliedOn)}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SummaryList;
