import React, { useRef } from 'react';

const VideoDurationCheck = ({ videoFile, handleUploadFile }: any) => {
    const videoRef: any = useRef(null);

    const handleLoadedMetadata = () => {
        // Access the duration property once metadata is loaded
        const durationInSeconds = videoRef.current.duration;
        // console.log('video duration:', durationInSeconds);
        // // 10 minutes timeout
        // if (durationInSeconds > 600) {
        //     handleUploadFile(null, "Video duration is very high");
        //     return;
        // }
        handleUploadFile(videoFile, durationInSeconds);
        // handleUploadFile(null, "Video duration is very high");
    };

    return (
        <div style={{ display: 'none' }}>
            {/* Hidden video element to load the metadata */}
            <video ref={videoRef} src={URL.createObjectURL(videoFile)} onLoadedMetadata={handleLoadedMetadata} />
        </div>
    );
};

export default VideoDurationCheck;